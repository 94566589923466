import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AccountAPI } from './../assets/api';
import tools from './../assets/tools';

import { notifySuccess, notifyFailed, notifyWarning } from './../assets/toaster';
import { ToastContainer } from 'react-toastify';
import Pagination from './../assets/Pagination';

const SignetManage = () => {

  const [totalPages, setTotalPages] = useState(1); // 要到的資料總頁數
  const [currentPage, setCurrentPage] = useState(1); // 目前所在頁

  const [stampList, setStampList] = useState([]);
  const [userName, setUserName] = useState('');
  const GetUserStampList = async () => {
    try {
      const data = await AccountAPI.GetUserStampList({ page: 1 });
      if (data) setStampList(data.userStampList);
      // setStampList(data.userStampList);
      setTotalPages(data.pageInfo.pages);
      setCurrentPage(1);
    } catch (error) {
      console.log(error);
    }
  };

  async function GetUserInfo() {
    try {
      let data = await AccountAPI.GetUserInfo();
      setUserName(data.userName);
    } catch (error) {
      notifyFailed({ Msg: `發生錯誤`, isReload: false });
    }
  }

  useEffect(() => {
    GetUserStampList();
    GetUserInfo();
  } ,[]);

  const handleDeleteStamp = async (stampid) => {
    notifyWarning({ Msg: <DeleteConfirm stampid={stampid}/> });
  };

  const DeleteConfirm = ({ closeToast, stampid }) => (
    <div>
      刪除影像章？
      <button className="btnS btnRed" onClick={() => DeleteStamp(stampid)}>確定刪除</button>
      <button className="btnS btnWhite" onClick={closeToast}>取消</button>
    </div>
  );

  const DeleteStamp = async (stampid) => {
    let formData = new FormData();
    formData.append('Id', stampid);
    formData.append('UserName', userName);
    formData.append('Status', 3);
    try {
      await AccountAPI.ModifyUserStamp({ userStamp: formData });
      notifySuccess({Msg: '刪除成功', cbfunc: ''});
      setStampList(stampList.filter(ele => ele.userStampID !== stampid));
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: true});
    }
  };

  return (
    <>
      <div className="bumper_rail"></div>
      <div className="commontitle">
        <h1>影像章管理</h1>
      </div>

      <div className="table" style={{ maxWidth: '1200px', }}>
        <table>
          <tbody>
            <tr>
              <td>別名</td>
              <td>狀態</td>
              {/* <td><button className="btnL btnWhite">新增</button></td> */}
              <td><Link to="signet-edit/create" className="btnL btnWhite" style={{ borderRadius: '4px', color: '#000', }}>新增</Link></td>
            </tr>
            {
              stampList.map((ele) => {
                return (
                  <tr key={tools._UUID()}>
                    <td>{ele.stampName}</td>
                    <td>{ele.status}</td>
                    <td>
                      <Link to={{ pathname: `signet-edit/${ele.userStampID}` }} className="a_fakebtn btnM btnBlue">編輯</Link>
                      <button className="btnM btnRed" onClick={() =>handleDeleteStamp(ele.userStampID)}>刪除</button>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
      <ToastContainer />

      <Pagination
        setTotalPages={setTotalPages}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        setList={setStampList}
        apiFunc={'GetUserStampList'}
      />
    </>
  );
};

export default SignetManage;