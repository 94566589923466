// import React from 'react';
import '../../style/Header.scss';
import React, { useState, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { useLoginStateContext } from './../routes/LoginStateProvider';
import { ProjectContext } from './../contexts/ProjectContext';
import { AuthAPI } from "./../assets/api";

function Header() {
  const { onLogout, loginUser } = useLoginStateContext();
  const { projectInfo, dispatch } = useContext(ProjectContext);
  let localData = window.localStorage.getItem('projectInfo');
  if(!localData){
    dispatch({ type: 'RESET' });
  }
  //console.log(projectInfo);
  let projectId = projectInfo.id;
  const isSysAdmin = loginUser.projectRoles && loginUser.projectRoles.some(ele => ele.roleName === '系統管理者');
  const isProjectManager = loginUser.projectRoles && loginUser.projectRoles.some(ele => ele.roleName === '管理者' && ele.projectID === projectId);
  const isCMGMgt = loginUser.projectRoles && loginUser.projectRoles.some(ele => ele.roleName === 'CMG管理階層');
  const isExternal = loginUser.isExternal;

  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

  const style = {
    left: isHamburgerOpen ? 0 : -240,
    transition: "1s ease"
  };

  const routerList = [
    { id: 1, title: '專案', ulheight: 170, description: [
      { id: 1, content: '專案表單執行管理', addr: 'project/form-list', auth: projectId > 0 },
      { id: 2, content: '專案表單建置管理', addr: 'project/project-material-manage', auth: projectId > 0 && (isSysAdmin || isProjectManager) },
      { id: 3, content: '圖面管理', addr: 'project/drawing-manage', auth: projectId > 0 && (isSysAdmin || isProjectManager) },
      { id: 4, content: '專案簽核流程管理', addr: 'project/approval-procedures-manage', auth: projectId > 0 && (isSysAdmin || isProjectManager) },
      { id: 5, content: '工種與機具資料管理', addr: 'project/implements-manage', auth: projectId > 0 && (isSysAdmin || isProjectManager) },
    ], fontawesome: 'fas fa-folder' },
    { id: 5, title: '報表總覽', ulheight: 75, description: [
      { id: 1, content: '月報總覽', addr: 'cmgmgt/monthly-report', auth: (isCMGMgt || isSysAdmin) },
      { id: 2, content: '工程美照總覽', addr: 'cmgmgt/project-photo', auth: (isCMGMgt || isSysAdmin) },
      { id: 3, content: '專案表單合格一覽', addr: 'report/dashboard-main', auth: (isCMGMgt || isSysAdmin) },
      { id: 4, content: '不合格缺失改善辦理情形', addr: 'report/dashboard-abnormal', auth: (isCMGMgt || isSysAdmin) },
    ], fontawesome: 'fas fa-list-alt' },
    { id: 2, title: '系統設定', ulheight: 140, description: [
      { id: 1, content: '表單樣本管理', addr: 'manage/project-material-manage', auth: isSysAdmin },
      { id: 2, content: '專案建置管理', addr: 'manage/project-manage', auth: isSysAdmin },
      { id: 3, content: '專案群組管理', addr: 'manage/project-group-manage', auth: isSysAdmin },
      { id: 4, content: '使用者設定', addr: 'manage/user-manage', auth: isSysAdmin },
      { id: 5, content: '晴雨表資料管理', addr: 'manage/weather-manage', auth: isSysAdmin },
    ], fontawesome: 'fas fa-cog' },
    { id: 3, title: '統計報表', ulheight: 110, description: [
      { id: 1, content: '首頁', addr: 'report/dashboard-new-index', auth: true },
      { id: 2, content: '工程概述', addr: 'report/dashboard-new-about', auth: true },
      { id: 3, content: '工程進度', addr: 'report/dashboard-new-progress', auth: true },
      { id: 4, content: '施工品質查驗紀錄', addr: 'report/dashboard-new-construction', auth: true },
      { id: 5, content: '材料設備品質查驗', addr: 'report/dashboard-new-material', auth: true },
      { id: 6, content: '品質缺失改善追蹤', addr: 'report/dashboard-new-abnormal', auth: true },
      { id: 7, content: '職業安全衛生', addr: 'report/dashboard-new-healthsafe', auth: true },
      { id: 8, content: '職安衛缺失改善追蹤', addr: 'report/dashboard-new-hsabnormal', auth: true },
      { id: 9, content: '專案人員', addr: 'report/dashboard-new-personnel', auth: !isExternal },
    ], fontawesome: 'fas fa-file-alt' },
    { id: 4, title: '使用者', ulheight: 75, description: [
      { id: 1, content: '帳號管理', addr: 'account/account-manage', auth: true },
      { id: 2, content: '影像章管理', addr: 'account/signet-manage', auth: true },
    ], fontawesome: 'fas fa-cog' },
  ];

  const SidebarItem = ({ navtitle, content, fontawesome, authCount}) => {
    const [isLiOpen, setisLiOpen] = useState(false);

    const li_style = {
      overflow: "hidden",
      height: isLiOpen ?authCount * 35 : 0,
      transition: "1.5s ease"
    };

    return (
      <li className="li_router_box">
        <a href="#!" className="a_routerDad" onClick={() => setisLiOpen(prev => !prev)}>
          <p>{navtitle}</p>
        </a>
        <ul className="ul_routerSon" style={li_style}>
          {
            content.map(ele => (
              ele.auth && <li key={ele.id}><NavLink to={"/" + ele.addr} activeClassName="active" exact={true}>{ele.content}</NavLink></li>
            ))
          }
        </ul>

        <div className="iconbox_ham">
          <i className={fontawesome}></i>
        </div>
      </li>
    );
  };

  async function Logout() {
    try {
      let result = await AuthAPI.Logout();
      if (result) {
        onLogout();
        dispatch({ type: 'RESET' });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleLogout = () => {
    Logout();
  };

  return (
    <div>
      <div className="account">

        <div id="hamburgerIcon" data-testid="hamburgerIcon" onClick={() => setIsHamburgerOpen(prev => !prev)}>
          <i className="fas fa-bars"></i>
        </div>

        <div className="float">
          <div className="showname">
            <p>{loginUser.name_ZHTW}</p>
          </div>

          <div className="membericon">
            <div className="membericon_frame">
              <i className="far fa-user"></i>
            </div>
            <div className="member_submenu">
              <ul>
                <li>
                  <NavLink to='/account/account-manage' exact={true}>設定帳戶</NavLink>
                  {/* <a href="/account/account-manage">設定帳戶</a> */}
                </li>
                {/* <li><a href="#!">報表下載</a></li> */}
                <li><a href="#!" onClick={handleLogout}>登出</a></li>
              </ul>
            </div>
          </div>
        </div>

      </div>


      <div className="sideBar_ham" data-testid="sideBar_ham" style={style}>
        <div className="LOGO">
          {/* <img src="image/Stantec_White.PNG"> */}
        </div>
        <div className="routerMenu">
          <Link to="/projects-list" className="a_toPjList_ham" >專案列表</Link>
          <ul className="ul_routerMenu">
            {routerList.map(ele => (
              ele.description.filter(ele1 => ele1.auth).length > 0 &&
              <SidebarItem key={ele.id} navtitle={ele.title} content={ele.description} fontawesome={ele.fontawesome} ulheight={ele.ulheight} authCount={ele.description.filter(ele1 => ele1.auth).length} />
            ))}
          </ul>

          <div className="showname_ham">
            <p>{loginUser.name_ZHTW}</p>
          </div>
          <div className="member_submenu_ham">
            <ul>
              <li>
                <NavLink to='/account/account-manage' exact={true}>設定帳戶</NavLink>
                {/* <a href="/account/account-manage"></a> */}
              </li>
              {/* <li><a href="#!">報表下載</a></li> */}
              <li><a href="#!" onClick={handleLogout}>登出</a></li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Header;
