import { useContext } from 'react';
import { ThemeContext } from './../contexts/ThemeContext';
import styled from '@emotion/styled';
import html2canvas from 'html2canvas';

const DownloadBox = styled.div`
  text-align: end;
  display: flex;
  justify-content: end;
  align-items: end;
  position: absolute;
  bottom: 10px;
  right: 10px;
  &::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 20px;
  background-color: transparent;
  z-index: -1;
}
  .roleBtn{
    font-size: 12px;
    padding: 0px 4px;
    text-decoration: none;
    background-color: #AAA;
    &:hover{
        background-color: #BBB;
    }
  }
`;

export const ChartDownloadButton = (prop) => {
  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark' ? true : false;

  const chartDownload = () => {
    const d3Div = document.getElementById(prop.chartId);
    const downloadDiv = document.getElementById(prop.downloadAreaId);
    const swatchesDiv = document.getElementById("swatchesId"); //色塊圖
    const d3XLine = document.getElementById('d3XLine'); //橫條圖
    const StackedBarChartSvgElem = document.getElementById('stackedBarChartSvg'); //直條圖
    const contentHeight = 70 * (prop.dataLength) + 56;
    const StackedBarChartWidth = swatchesDiv.offsetWidth;

    d3Div.style.maxHeight = '';
    d3Div.style.overflow = '';
    d3Div.style.backgroundColor = isDarkMode ? "#444" : "#fff";

    //橫條圖 
    if(d3XLine && prop.dataLength ) {
      d3XLine.setAttribute("transform",`translate(0, ${ contentHeight })`);
    }

    //直條圖 
    if (StackedBarChartSvgElem && prop.stackedBarChartOption && prop.stackedBarChartOption.chartId === prop.chartId) {
      // d3Div.style.maxWidth = "";
      downloadDiv.style.width =`${StackedBarChartSvgElem.getBoundingClientRect().width}px`;
      downloadDiv.style.backgroundColor = isDarkMode ? "#444" : "#fff";
    }
    
    const newWindow = window.open();
    newWindow.document.write(`<div id="loading">Loading...</div>`);
    const loadingDiv = newWindow.document.getElementById('loading');
    loadingDiv.style.position = 'fixed';
    loadingDiv.style.top = '50%';
    loadingDiv.style.left = '50%';
    loadingDiv.style.transform = 'translate(-50%, -50%)';
  
    html2canvas(downloadDiv)
      .then(canvas => {
        // 當Canvas生成時，執行以下
        loadingDiv.remove();
        const url = canvas.toDataURL();
        newWindow.document.write(`<img src=${url} alt=${prop.chartName} />`);
        newWindow.document.title = prop.chartName;

        //橫條圖
        if( document.getElementById("d3XLine") && prop.dataLength ){
          d3Div.style.maxHeight = "500px";
          d3Div.style.overflow = "auto";
        }

        //直條圖
        if (StackedBarChartSvgElem) {
          d3Div.style.overflowY= "hidden";
          d3Div.style.overflowX = "auto";
          swatchesDiv.style.width = `${StackedBarChartWidth}px`;
          d3Div.style.backgroundColor = "transparent";
          downloadDiv.style.backgroundColor = "transparent";
        }

        const a = document.createElement('a');
        a.download = prop.chartName;
        document.body.appendChild(a);
        a.href = url;
        a.click();
        a.remove();
      });
  };

  const fromDownload = () => {
    const fromDiv = document.getElementById(prop.downloadAreaId);
    fromDiv.style.cssText = 'max-height: none !important;';
    if(prop.hsTitleTableId) {
      const hsTitleTableDiv = document.getElementById(prop.hsTitleTableId);
      hsTitleTableDiv.style.cssText = 'max-height: none !important;';
    }

    const newWindow = window.open();
    newWindow.document.write(`<div id="loading">Loading...</div>`);
    const loadingDiv = newWindow.document.getElementById('loading');
    loadingDiv.style.position = 'fixed';
    loadingDiv.style.top = '50%';
    loadingDiv.style.left = '50%';
    loadingDiv.style.transform = 'translate(-50%, -50%)';

    html2canvas(fromDiv)
      .then(canvas => {
      // 當Canvas生成時，執行以下
        loadingDiv.remove();
        const url = canvas.toDataURL();
        newWindow.document.write(`<img src=${url} alt=${prop.chartName} />`);
        newWindow.document.title = prop.chartName;

        if(prop.downloadAreaId === "constructionInspectionTable"){
          fromDiv.style.maxHeight = "463px";
        }
        if(prop.downloadAreaId === "inspectionTable"){
          fromDiv.style.maxHeight = "450px";
        }
        if(prop.downloadAreaId === "materialInspectionTable"){
          fromDiv.style.maxHeight = "463px";
        }
        if(prop.hsTitleTableId === "formStatisticsTableTableTitleId"){
          fromDiv.style.maxHeight = "280px";
        }
        if(prop.hsTitleTableId === "abnormalStatusTitleTableId"){
          fromDiv.style.maxHeight = "645px";
        }
        if(prop.hsTitleTableId === "hsabnormalStatusTableTitleId"){
          fromDiv.style.maxHeight = "570px";
        }
        if(prop.hsTitleTableId === "omitStatusTableTitleId"){
          fromDiv.style.maxHeight = "305px";
        }
        fromDiv.style.overflow = "auto";

        const a = document.createElement('a');
        a.download = prop.chartName;
        document.body.appendChild(a);
        a.href = url;
        a.click();
        a.remove();
      });
  };
  
  return (
    <DownloadBox>
      {
        prop.chartId ? 
          <button className="roleBtn chartDownloadBtn" onClick={chartDownload}>Download</button>
          :
          <button className="roleBtn fromDownloadBtn" onClick={fromDownload}>Download</button>
      }
    </DownloadBox>
  );
};