import React, { useEffect, useState, useContext } from "react";
import styled from '@emotion/styled';
import "./../../style/Form.scss";
import "./../../style/FormDetail.scss";
import moment from 'moment';
import { notifyFailed, notifySuccess, notifyWarning } from './../assets/toaster';

import tools from './../assets/tools';
// import { AccountAPI, FormAPI, DocumentAPI } from "../assets/api";
import { ProjectAPI, DocumentAPI } from "../assets/api";
import { ProjectContext } from './../contexts/ProjectContext';


const FormTitle = styled.h1`
  font-size: 1.4rem;
  font-weight: 700;
  margin: 8px;
`;

const DateInput = styled.input`
  padding: 0 4px;
  box-sizing: border-box;
  height: 32px;
  border-radius: 3px;
  border: 1px solid #999;
  // min-width: 5em;
  max-width: 18em;
  margin: 0 3px;
  @media (max-width: 450px) {
    width: 100%;
  }
`;

// const FakeHr = styled.div`
//   width: 92%;
//   height: 2px;
//   background-color: #DDD;
//   margin: 40px 4% 40px 4%;
// `;

const SubTitle = styled.h2`
  font-size: 1.2rem;
  font-weight: 700;
  margin: 8px;
`;

const TableBox = styled.div`
  padding: 0 10px;
`;

const WorkerSelect = styled.select`
  border: 1px solid #888;
  padding: 2px 4px;
  border-radius: 4px;
  width: 100%;
  max-width: 25em;
`;

const CreateWorkerSelect = (props) => {
  return (
    <tr>
      <td>
        <WorkerSelect onChange={props.handleWorkerSelect} value={props.item.positionToolsID}>
          {
            props.helper.workersList.map(oele => {
              return (
                <option key={oele.id} value={oele.id}>{oele.name}</option>
              );
            })
          }
        </WorkerSelect>
      </td>
      <td><input type="text" onChange={props.handleTotalChange} value={props.item.total} /></td>
      <td><button className="btnS btnRed" onClick={props.handleWorkerDelete}><i className="far fa-trash-alt"></i></button></td>
    </tr>
  );
};
const CreateToolSelect = (props) => {
  return (
    <tr>
      <td>
        <WorkerSelect onChange={props.handleToolSelect} value={props.item.positionToolsID}>
          {
            props.helper.toolsList.map(oele => {
              return (
                <option key={oele.id} value={oele.id}>{oele.name}</option>
              );
            })
          }
        </WorkerSelect>
      </td>
      <td><input type="text" onChange={props.handleToolTotalChange} value={props.item.total} /></td>
      <td><button className="btnS btnRed" onClick={props.handleToolDelete}><i className="far fa-trash-alt"></i></button></td>
    </tr>
  );
};


// 表單
const WorkerAndImplements = (props) => {

  const { projectInfo } = useContext(ProjectContext);
  let projectId = projectInfo.id;

  const [isSaving, setIsSaving] = useState(false);
  const [isEXCELExporting, setIsEXCELExporting] = useState(false);

  const [formContent, setFormContent] = useState({
    ...props.formContent,
    dailyPersonnelList: props.formContent.dailyPersonnelList.map(ele => {
      return ele.id > 0 ?
        ({
          ...ele,
          id: ele.id,
          _uuid: tools._UUID(),
          positionToolsID: ele.positionToolsID,
          total: ele.total,
          fillinDate: ele.fillinDate,
          status: 2,
          isExist: true
        }) :
        ({
          ...ele,
          id: 0,
          _uuid: tools._UUID(),
          positionToolsID: ele.positionToolsID || 0,
          total: ele.total || 0,
          fillinDate: ele.fillinDate || '',
          status: 1,
          isExist: false
        });
    }),
    dailyImplementsList: props.formContent.dailyImplementsList.map(ele => {
      return ele.id > 0 ?
        ({
          ...ele,
          id: ele.id,
          _uuid: tools._UUID(),
          positionToolsID: ele.positionToolsID,
          total: ele.total,
          fillinDate: ele.fillinDate,
          status: 2,
          isExist: true
        }) :
        ({
          ...ele,
          id: 0,
          _uuid: tools._UUID(),
          positionToolsID: ele.positionToolsID || 0,
          total: ele.total || 0,
          fillinDate: ele.fillinDate || '',
          status: 1,
          isExist: false
        });
    }),
    _uuid: tools._UUID(),
    fillinDate: moment(props.formContent.fillinDate).format('YYYY-MM-DD'),
    // formStatus: props.formContent.id > 0 ? props.formContent.formStatus : 0, // 表單狀態,0:草稿,1:審核中,2:已結案,3:已刪除,4:真刪除
    // status: props.formContent.id > 0 ? 2 : 1,
    editable: props.formContent.id > 0 ? props.formContent.formStatus === 0 : true,
  });

  const [helper, setHelper] = useState({
    workersList: [],
    toolsList: []
  });

  const handleInputChange = (e) => {
    setFormContent({
      ...formContent,
      [e.target.name]: e.target.value
    });
  };

  const handleSave = async ({ isSubmit }) => {
    // 檢查網路狀態
    if (!window.navigator.onLine) {
      notifyFailed({ Msg: '網路未連線', isReload: false });
      return;
    }
    if (!formContent.fillinDate) {
      notifyFailed({ Msg: '請填寫「填報日期」', isReload: false });
      return;
    }

    let formValidate = FormValidate();
    if (!formValidate) {
      notifyFailed({ Msg: '出工列表 或 機具列表請至少填一筆項目', isReload: false });
      return;
    }

    setIsSaving(true);
    let formData = new FormData();
    // formData.append(`id`, formContent.id || 0);
    formData.append(`projectId`, projectId);
    formData.append(`fillinDate`, formContent.fillinDate);
    // formData.append(`formStatus`, formContent.formStatus);
    // formData.append(`status`, formContent.status);
    // 出工人項目
    for (let i = 0; i < formContent.dailyPersonnelList.length; i++) {
      let ele = formContent.dailyPersonnelList[i];
      formData.append(`dailyPersonnelList[${i}].id`, ele.id || 0);
      formData.append(`dailyPersonnelList[${i}].projectID`, ele.projectID || projectId);
      formData.append(`dailyPersonnelList[${i}].positionToolsID`, ele.positionToolsID || helper.workersList[0].id);
      formData.append(`dailyPersonnelList[${i}].total`, ele.total);
      formData.append(`dailyPersonnelList[${i}].fillinDate`, formContent.fillinDate);
      formData.append(`dailyPersonnelList[${i}].status`, ele.status);
    }
    // 機具項目
    for (let i = 0; i < formContent.dailyImplementsList.length; i++) {
      let ele = formContent.dailyImplementsList[i];
      formData.append(`dailyImplementsList[${i}].id`, ele.id || 0);
      formData.append(`dailyImplementsList[${i}].projectID`, ele.projectID || projectId);
      formData.append(`dailyImplementsList[${i}].positionToolsID`, ele.positionToolsID || helper.toolsList[0].id);
      formData.append(`dailyImplementsList[${i}].total`, ele.total);
      formData.append(`dailyImplementsList[${i}].fillinDate`, formContent.fillinDate);
      formData.append(`dailyImplementsList[${i}].status`, ele.status);
    }
    try {
      // console.log(formData);
      // let mainId = await ProjectAPI.ModifyDailyPersonnelImplements({projectId, formData});
      let toolsInfodata = await ProjectAPI.ModifyDailyPersonnelImplements({ projectId, implementsModify: formData });
      let toolsInfo = {
        "date": toolsInfodata.fillinDate,
        "userName": toolsInfodata.userName,
        "formStatus": 0
      };
      notifySuccess({ Msg: '儲存成功', cbfunc: '' });
      setIsSaving(false);
      if (!isSubmit) {
        // 儲存完關閉頁籤，並再打開
        let newFormList = await setFormTabListPromise(props.formTabList.filter(ele => ele._uuid !== props.uuid));

        let newFormContent = await GetDailyPersonnelImplements(projectId, toolsInfo);
        let editForm = {
          id: 1, // just for italic
          _uuid: tools._UUID(),
          formName: `每日出工機具 - ${moment(toolsInfodata.fillinDate).format('YYYY-MM-DD')}`,
          moduleId: 7, // 出工機具: 7
          formContent: { ...newFormContent, toolsReadStatus: 0 },
          fillinDate: toolsInfodata.fillinDate,
          userName: toolsInfodata.userName,
          formStatus: 4
        };
        let data = await setFormTabListPromise([...newFormList, editForm]);
        openFormTab(data, editForm._uuid);
      }
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤，表單儲存失敗 (出工機具表單)', isReload: false });
      // if (error.response.data === "此表單編號已存在。") { notifyFailed({ Msg: error.response.data, isReload: false }); }
      setIsSaving(false);
    }
  };

  // 檢查欄位必填
  const FormValidate = () => {
    let isValid = true;

    // 欄位檢查
    if (
      formContent.dailyPersonnelList.filter(ele => ele.status !== 3).length === 0 &&
      formContent.dailyImplementsList.filter(ele => ele.status !== 3).length === 0
    ) {
      isValid = false;
    }

    return isValid;
  };

  const openFormTab = (list, uuid) => {
    let tabToOpen = list.find(ele => ele._uuid === uuid);
    if (tabToOpen) props.setFormTabSelected(tabToOpen);
  };

  const setFormTabListPromise = (formList) => {
    return new Promise(resolve => {
      props.setFormTabList(formList);
      resolve(formList);
    });
  };


  // async function GetHSExamineInfo(hsExamineId) {
  //   try {
  //     let data = await FormAPI.GetHSExamineInfo(hsExamineId);
  //     return data;
  //   } catch (error) {
  //     notifyFailed({ Msg: '發生錯誤', isReload: false });
  //   }
  // }

  async function GetDailyPersonnelImplements(projectId, toolsInfo) {
    try {
      let data = await ProjectAPI.GetDailyPersonnelImplements({ projectId, toolsInfo });
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }


  const handleWorkersCreate = () => {
    setFormContent({ ...formContent, dailyPersonnelList: [...formContent.dailyPersonnelList, new Worker({ _uuid: tools._UUID(), status: 1 })] });
    // setFormContent([...formContent.dailyPersonnelList, new Worker({ _uuid: tools._UUID(), status: 1 })]);
  };
  const handleToolsCreate = () => {
    setFormContent({ ...formContent, dailyImplementsList: [...formContent.dailyImplementsList, new Tool({ _uuid: tools._UUID(), status: 1 })] });
  };

  function Worker({ _uuid = 0, id = 0, positionToolsID = 0, total = 0, fillinDate = '', status = 0, isExist = false }) {
    this._uuid = _uuid;
    this.id = id;
    this.projectID = projectId;
    this.positionToolsID = positionToolsID;
    this.total = total;
    this.fillinDate = fillinDate;
    this.status = status;
    this.isExist = isExist;
    // this.isEditing = isEditing;
  }
  function Tool({ _uuid = 0, id = 0, positionToolsID = 0, total = 0, fillinDate = '', status = 0, isExist = false }) {
    this._uuid = _uuid;
    this.id = id;
    this.projectID = projectId;
    this.positionToolsID = positionToolsID;
    this.total = total;
    this.fillinDate = fillinDate;
    this.status = status;
    this.isExist = isExist;
    // this.isEditing = isEditing;
  }

  const handleWorkerSelect = (e, el) => {
    // console.log(e.target.value);
    setFormContent({
      ...formContent,
      dailyPersonnelList: formContent.dailyPersonnelList.map(ele => (ele._uuid === el._uuid ? { ...ele, positionToolsID: e.target.value, status: el.id ? 2 : 1 } : ele))
    });
  };
  const handleToolSelect = (e, el) => {
    setFormContent({
      ...formContent,
      dailyImplementsList: formContent.dailyImplementsList.map(ele => (ele._uuid === el._uuid ? { ...ele, positionToolsID: e.target.value, status: el.id ? 2 : 1 } : ele))
    });
  };

  const handleTotalChange = (e, el) => {
    // console.log(e+","+el);
    // setProjectSignOffList(projectSignOffList.map(ele => (ele._uuid === el._uuid ? { ...ele, sort: e.target.value, status: el.id ? 2 : 1 } : ele)));
    setFormContent({
      ...formContent,
      dailyPersonnelList: formContent.dailyPersonnelList.map(ele => (ele._uuid === el._uuid ? { ...ele, total: e.target.value } : ele))
    });
  };
  const handleToolTotalChange = (e, el) => {
    setFormContent({
      ...formContent,
      dailyImplementsList: formContent.dailyImplementsList.map(ele => (ele._uuid === el._uuid ? { ...ele, total: e.target.value } : ele))
    });
  };

  // ===
  const handleWorkerDelete = (deleteItem) => {
    handleWorkerDeleteSure(deleteItem);
  };
  const handleWorkerDeleteSure = (deleteItem) => {
    notifyWarning({ Msg: <DeleteWorkerConfirm deleteItem={deleteItem} /> });
  };
  const DeleteWorkerConfirm = ({ closeToast, deleteItem }) => (
    <div>
      刪除出工人？
      <button className="btnS btnRed" onClick={() => DeleteWorkerItem(deleteItem)}>確定刪除</button>
      <button className="btnS btnWhite" onClick={closeToast}>取消</button>
    </div>
  );
  const DeleteWorkerItem = (deleteItem) => {
    if (deleteItem.isExist) {
      // setProjectSignOffList(projectSignOffList.map(ele => (ele._uuid === deleteItem._uuid ? { ...ele, status: 3 } : ele)));
      setFormContent({ ...formContent, dailyPersonnelList: formContent.dailyPersonnelList.map(ele => (ele._uuid === deleteItem._uuid ? { ...ele, status: 3 } : ele)) });
    } else {
      // setProjectSignOffList(projectSignOffList.filter(ele => ele._uuid !== deleteItem._uuid));
      setFormContent({ ...formContent, dailyPersonnelList: formContent.dailyPersonnelList.filter(ele => ele._uuid !== deleteItem._uuid) });

    }
  };
  // ===
  const handleToolDelete = (deleteItem) => {
    handleToolDeleteSure(deleteItem);
  };
  const handleToolDeleteSure = (deleteItem) => {
    notifyWarning({ Msg: <DeleteToolConfirm deleteItem={deleteItem} /> });
  };
  const DeleteToolConfirm = ({ closeToast, deleteItem }) => (
    <div>
      刪除機具？
      <button className="btnS btnRed" onClick={() => DeleteToolItem(deleteItem)}>確定刪除</button>
      <button className="btnS btnWhite" onClick={closeToast}>取消</button>
    </div>
  );
  const DeleteToolItem = (deleteItem) => {
    if (deleteItem.isExist) {
      setFormContent({ ...formContent, dailyImplementsList: formContent.dailyImplementsList.map(ele => (ele._uuid === deleteItem._uuid ? { ...ele, status: 3 } : ele)) });
    } else {
      setFormContent({ ...formContent, dailyImplementsList: formContent.dailyImplementsList.filter(ele => ele._uuid !== deleteItem._uuid) });

    }
  };
  // ===

  async function GetPositionToolsManageInfo(projectId, type) {
    try {
      let data = await ProjectAPI.GetPositionToolsManageInfo(projectId, type);
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  // 還原表單
  const handleRestoreForm = async() => {
    try {
      await ProjectAPI.RestoreDailyPersonnelImplements({
        projectId: projectId,
        ItemRestore:{
          projectId: projectId,
          fillinDate: moment(props.formContent.fillinDate).format('YYYY-MM-DD'),
          userName: formContent.userName
        }
      });
      notifySuccess({Msg: '表單還原成功', cbfunc: ''});
      // 還原後關閉頁籤
      props.setFormTabList(props.formTabList.filter(ele => ele._uuid !== props.uuid));
      props.setFormStatus(0);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤，表單還原失敗', isReload: false});
    }
  };

  useEffect(async () => {
    // GetUserInfo();
    let dataworker = await GetPositionToolsManageInfo(projectId, 1);
    let datatool = await GetPositionToolsManageInfo(projectId, 2);
    setHelper({ workersList: dataworker.positionToolsManageList, toolsList: datatool.positionToolsManageList });
    // setFormContent({ ...formContent, dailyPersonnelList: formContent.dailyPersonnelList.map(ele => (ele.positionToolsID === helper.workersList.id ? { ...ele, name: 3 } : ele)) });
    // setFormContent({
    //   ...formContent,
    //   dailyPersonnelList: formContent.dailyPersonnelList.map(ele => (
    //     ele.positionToolsID === helper.workersList.id ? { ...ele, name: ??? } : ele
    //   ))
    // });
    // helper.workersList.map(wele =>(
    //   setFormContent({
    //     ...formContent,
    //     dailyPersonnelList: formContent.dailyPersonnelList.map(ele => (
    //       ele.positionToolsID === wele.id ? { ...ele, name: wele.name } : ele
    //     ))
    //   })
    // ));
  }, []);

  // 還原表單
  // const handleRestoreForm = async () => {
  //   try {
  //     await FormAPI.RestoreForm({
  //       id: formContent.id,
  //       module: 4
  //     });
  //     notifySuccess({ Msg: '表單還原成功', cbfunc: '' });
  //     // 還原後關閉頁籤
  //     props.setFormTabList(props.formTabList.filter(ele => ele._uuid !== props.uuid));
  //     props.setFormStatus(0);
  //   } catch (error) {
  //     notifyFailed({ Msg: '發生錯誤，表單還原失敗', isReload: false });
  //   }
  // };

  // 匯出EXCEL
  const ExportEXCEL = async () => {
    setIsEXCELExporting(true);
    await CreateEXCEL();
    setIsEXCELExporting(false);
  };
  const CreateEXCEL = async () => {
    try {
      let data = await DocumentAPI.CreateDPIExcel({ projectId, fillinDate: formContent.fillinDate});
      tools.AjaxFileDownload({ rs: data, filename: `每日出工機具統計 - ${formContent.fillinDate}`, fileType: 'excel' });
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤，匯出文件失敗', isReload: false });
    }
  };

  return (
    <>
      <div className="sharebox">
        {
          formContent.toolsReadStatus === 2 && (<div className="btn_box"><button className="btnL btnBlue" onClick={handleRestoreForm}>還原此表單</button></div>)
        }
        <FormTitle>{formContent.projectFormName}</FormTitle>

        <div className="form form_loose" style={{ paddingBottom: '10px', }}>
          {/* 一行 */}
          <div className="form_half">
            <div className="form_title">
              填報日期：
            </div>
            { formContent.toolsReadStatus === 0 ?
              <div className="form_input">
                <DateInput type="date" name="fillinDate" value={formContent.fillinDate || ''} onChange={handleInputChange} />
              </div>
              :
              <div className="form_input">
                <p>{moment(formContent.fillinDate).format('YYYY-MM-DD')}</p>
              </div>
            }
          </div>
        </div>

        {formContent.toolsReadStatus === 1 &&
          <div className="btn_box">
            <button className="btnL btnWhite" onClick={ExportEXCEL} disabled={isEXCELExporting}>
              {
                isEXCELExporting ? <i className="fas fa-circle-notch fa-spin"></i> : '匯出EXCEL'
              }
            </button>
          </div>
        }
        <SubTitle>出工填報</SubTitle>

        <TableBox>
          <div className="table">
            <table>
              {formContent.toolsReadStatus === 0 ?
                <tbody>
                  <tr>
                    <td>工別</td>
                    <td>人數</td>
                    <td><button className="btnM btnWhite" onClick={handleWorkersCreate}>新增</button></td>
                  </tr>
                  {
                    formContent.dailyPersonnelList && formContent.dailyPersonnelList.length > 0 ? formContent.dailyPersonnelList.filter(el => el.status !== 3).map((ele) => {
                      return (
                        ele.isExist ?
                          <tr key={ele.id}>
                            <td>
                              {/* {ele.positionToolsID} */}
                              <WorkerSelect onChange={(event) => handleWorkerSelect(event, ele)} value={ele.positionToolsID}>
                                {
                                  helper.workersList.map(oele => {
                                    return (
                                      <option key={oele.id} value={oele.id}>{oele.name}</option>
                                    );
                                  })
                                }
                              </WorkerSelect>
                            </td>
                            <td>
                              {<input type="text" value={ele.total} onChange={(event) => handleTotalChange(event, ele)} />}
                            </td>
                            <td>
                              <button className="btnS btnRed" onClick={() => handleWorkerDelete(ele)}><i className="far fa-trash-alt"></i></button>
                            </td>
                          </tr> :
                          <CreateWorkerSelect
                            helper={helper}
                            item={ele}
                            key={ele._uuid}
                            handleTotalChange={(event) => handleTotalChange(event, ele)}
                            handleWorkerSelect={(event) => handleWorkerSelect(event, ele)}
                            handleWorkerDelete={() => handleWorkerDelete(ele)}
                          />
                      );
                    })
                      : <tr></tr>
                  }
                </tbody>
                :
                <tbody>
                  <tr>
                    <td>工別</td>
                    <td>人數</td>
                  </tr>
                  {
                    formContent.dailyPersonnelList && formContent.dailyPersonnelList.map((ele, idx) =>
                      <tr key={idx}>
                        <td>
                          {ele.name}
                        </td>
                        <td>
                          {ele.total}
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              }
            </table>
          </div>
        </TableBox>

        <SubTitle>機具填報</SubTitle>

        <TableBox>
          <div className="table">
            <table>
              {formContent.toolsReadStatus === 0 ?
                <tbody>
                  <tr>
                    <td>工別</td>
                    <td>數量</td>
                    <td><button className="btnM btnWhite" onClick={handleToolsCreate}>新增</button></td>
                  </tr>
                  {
                    formContent.dailyImplementsList && formContent.dailyImplementsList.length > 0 ? formContent.dailyImplementsList.filter(el => el.status !== 3).map((ele) => {
                      return (
                        ele.isExist ?
                          <tr key={ele.id}>
                            <td>
                              {/* {ele.positionToolsID} */}
                              <WorkerSelect onChange={(event) => handleToolSelect(event, ele)} value={ele.positionToolsID}>
                                {
                                  helper.toolsList.map(oele => {
                                    return (
                                      <option key={oele.id} value={oele.id}>{oele.name}</option>
                                    );
                                  })
                                }
                              </WorkerSelect>
                            </td>
                            <td>
                              {/* {ele.total} */}
                              <input value={ele.total || 0} onChange={(event) => handleToolTotalChange(event, ele)} />
                            </td>
                            <td>
                              <button className="btnS btnRed" onClick={() => handleToolDelete(ele)}><i className="far fa-trash-alt"></i></button>
                            </td>
                          </tr> :
                          <CreateToolSelect
                            helper={helper}
                            item={ele}
                            key={ele._uuid}
                            handleToolTotalChange={(event) => handleToolTotalChange(event, ele)}
                            handleToolSelect={(event) => handleToolSelect(event, ele)}
                            handleToolDelete={() => handleToolDelete(ele)}
                          />
                      );
                    })
                      : <tr></tr>
                  }
                </tbody>
                :
                <tbody>
                  <tr>
                    <td>工別</td>
                    <td>數量</td>
                  </tr>
                  {
                    formContent.dailyImplementsList && formContent.dailyImplementsList.map((ele, idx) =>
                      <tr key={idx}>
                        <td>
                          {ele.name}
                        </td>
                        <td>
                          {ele.total}
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              }
            </table>
          </div>
        </TableBox>

        {formContent.toolsReadStatus === 0 &&
          <div className="btn_box">
            <button className="btnL btnBlue" onClick={() => handleSave({ isSubmit: false })} disabled={isSaving}>
              {
                isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '存檔'
              }
            </button>
          </div>
        }

      </div>
    </>
  );

};

export default WorkerAndImplements;