import API from "./ApiConfig";

const ProjectsGroupAPI = {
  // 取得專案管理列表
  GetProjectGroupList: ({ page, keyword }) => {
    return new Promise((resolve, reject) => {
      API.get(`/ProjectsGroup/GetProjectGroupList/${page}/${keyword}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 刪除專案
  DeleteProjectGroup: (Id) => {
    return new Promise((resolve, reject) => {
      API.post(`/ProjectsGroup/DeleteProjectGroup?Id=${Id}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // 取得專案群組中專案列表
  GetProjectGroupRelatedList: ( projectGroupId ) => {
    return new Promise((resolve, reject) => {
      API.get(`/ProjectsGroup/GetProjectGroupRelatedList/${projectGroupId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  //新增/修改專案群組
  ModifyProject: (projectListData ) => {
    return new Promise((resolve, reject) => {
      API.post(`/ProjectsGroup/ModifyProjectGroup`, projectListData)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};




export default ProjectsGroupAPI;