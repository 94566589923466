import React from 'react';
import Header from './Header';
import SideBar from './SideBar';
import { useLoginStateContext } from './../routes/LoginStateProvider';

const Layout = () => {
  const loginStateContext = useLoginStateContext();
  const loginState = loginStateContext.loginState;
  return (
    <>
      {
        loginState ?
          <section data-testid="loginSection">
            <Header />
            <SideBar />
          </section> :
          <div></div>
      }
    </>
  );
};

export default Layout;