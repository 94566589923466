import styled from '@emotion/styled';

const DownloadBox = styled.div`
  text-align: end;
  display: flex;
  justify-content: end;
  align-items: end;
  position: absolute;
  bottom: 10px;
  right: 10px;
  &::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 25px;
  background-color: transparent;
  z-index: -1;
}
  .roleBtn{
    font-size: 12px;
    padding: 0px 4px;
    text-decoration: none;
    background-color: #AAA;
    &:hover{
        background-color: #BBB;
    }
  }
`;

export const ExportToExcelButton = (prop) => {

  const tableExportToExcel = () => {
    const table = document.getElementById(prop.tableId);
    const rows = table.querySelectorAll("tr");
    
    let csvContent = "data:text/csv;charset=utf-8,\uFEFF"; // 在開頭加上BOM字元

    rows.forEach(function(row) {
      const rowContent = row.querySelectorAll("td, th");
      const rowData = [];
      for (let i = 0; i < rowContent.length; i++) {
        rowData.push(rowContent[i].innerText);
      }
      csvContent += rowData.join(",") + "\r\n";
    });

    //職業安全衛生表格
    if(prop.tableId === "hsExamineTableId") {
      const rowsTh = table.querySelectorAll(".itemTitle");
      const rowsTd = table.querySelectorAll(".itemContent");
      const headerRowData = ["檢查結果"];
      const headerColData = ["合格", "不合格", "已完成", "列管中"];
      
      //表格第一列標題
      rowsTh.forEach(function(row) {
        headerRowData.push(row.querySelector("span").innerText);
      });
      csvContent += headerRowData.join(",") + "\n";
      
      //表格內容
      const dataRows = [];
      for (let i = 0; i < rowsTd.length; i += rowsTh.length) {
        const rowData = [headerColData[i / rowsTh.length]];
        for (let j = i; j < i + rowsTh.length; j++) {
          rowData.push(rowsTd[j].innerText);
        }    
        dataRows.push(rowData);
      }
      for (let i = 0; i < dataRows.length; i++) {
        csvContent += dataRows[i].join(",") + "\n";
      }
    }

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", prop.tableName || "download.csv"); 
    document.body.appendChild(link);
    link.click();
  };
  
  return (
    <DownloadBox>
      <button className="roleBtn" onClick={ () => tableExportToExcel() }>Download</button>
    </DownloadBox>
  );
};