// import TableauDashboard from './TableauDashboard';
// import tools from '../assets/tools';
import styled from '@emotion/styled';
import Map from './Map';
import { useTable, useSortBy } from 'react-table';
import React from 'react';
import { useState, useContext } from 'react';
import { StatisticAPI } from './../assets/api';
import makeData from './makeData';
import { notifyFailed } from './../assets/toaster';

import { SwitchMode } from './SwitchMode';
import { ThemeContext } from './../contexts/ThemeContext';
import { Link } from "react-router-dom";

const TBcontainer = styled.div`
    background-color: #F1F1F1;
    margin: 1rem 0 1rem 0;
    padding: 20px;
`;
const MapBlock = styled.div`
  background-color: #FFF;
  width: 35%;
  margin-right:3%;
  display: inline-block;
  vertical-align: top;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;
const RightArea = styled.div`
  width: 62%;
  display: inline-block;
  text-align: center;
  @media (max-width: 1200px) {
    width: 100%;
    margin-top:1rem;
  }
`;
const PosterBlock = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`;
const Poster = styled.div`
  background-color: #FFF;
  width: 45%;
  padding: 1rem;
`;
const PosterTitle = styled.div`
  border-bottom: 1px solid #CCC;
  padding: 0 0 1rem 0;
  font-size: 18px;
`;
const PosterContent = styled.div`
  padding: 2rem 0 1rem 0;
  font-size: 36px;
`;

const TableBlock = styled.div`
  // background-color: #FFF;
  overflow: auto;
  div.table{
    margin: 1.5rem 0 ;
    table{
      font-size: 14px;
      min-width: 875px;
      margin: 0 ;
      thead{
        tr{
          &:first-of-type{
            background-color: #E3E3E3;
            box-shadow: 0 1px 2px 0px #BBBB;
            font-size: 1.1rem;
            position: sticky;
            top: 0;
          }
          th{
            padding: 0.5rem 6px;
            &:first-of-type{
              width: 7rem;
            }
            &:last-of-type{
              display: none;
            }
          }
        }
      }
    }

    tr{
      &:first-of-type{
        background-color: transparent;
        box-shadow: 0 1px 2px 0px #cccc;
        font-size: 14px;
      }
      &:hover{
        background-color: #FDF1E633;
      }
      td a {
        color: #209DC3;
      }
      td{
        &:last-of-type{
          display: none;
        }
        &:nth-of-type(9){
          white-space: nowrap;
        }
        &:nth-of-type(10){
          white-space: nowrap;
        }
      }
    }

    max-height: 650px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #DDD;
    }
  }
`;
// const ProjectNumLink = styled(Link)`
//     color: #000;
//   `;

function Table({ columns, data, isDarkMode }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  // 不限制數量用rows就行
  // const firstPageRows = rows.slice(0, 8);
  const firstPageRows = rows;

  return (
    <>
      <table {...getTableProps()}
        style={{ backgroundColor: isDarkMode ? '#333' : '#FFF', color: isDarkMode ? '#FFF' : '#000',
          tableLayout: 'fixed' }}
      >
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i} style={{ backgroundColor: isDarkMode ? '#222' : '#E3E3E3' }}>
              {headerGroup.headers.map((column, ic) => (
                // Add the sorting props to control sorting. For this example we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={ic}>
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {firstPageRows.map(
            (row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {/* {row.cells.map((cell, icell) => {
                    return (
                      <td {...cell.getCellProps()} key={icell}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })} */}
                  {row.cells.map((cell, i2) => {
                    if (cell.column.Header === '專案案號 / 簡稱') {
                      return (
                        <td key = {`${i}-${i2}`} {...cell.getCellProps()}>
                          <Link to={`dashboard-new-about/${cell.row.values.projectID}`}>{cell.value}</Link>
                        </td>
                      );
                    // } else if (cell.column.Header === 'projectID') {
                    //   return (
                    //     null
                    //   );
                    } else {
                      return (
                        <td key = {`${i}-${i2}`}>
                          {cell.render('Cell')}
                        </td>
                      );
                    }
                  })}

                </tr>
              );
            }
          )}
        </tbody>
      </table>
      <br />
      {/* <div>Showing the first 12 results of {rows.length} rows</div> */}
    </>
  );
}

const DashboardMain = () => {

  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark' ? true : false;

  const [frontPage, setFrontPage] = useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: '專案案號 / 簡稱',
        accessor: 'projectShortName',
      },
      {
        Header: '主辦機關',
        accessor: 'titleAgency',
      },
      {
        Header: '工程階段',
        accessor: 'stage',
      },
      {
        Header: '累計預定進度',
        accessor: 'scheduledProgress',
      },
      {
        Header: '累計實際進度',
        accessor: 'actualProgress',
      },
      {
        Header: '超前 / 落後',
        accessor: 'progress',
      },
      {
        Header: '廠商估驗進度',
        accessor: 'companyEstimating',
      },
      {
        Header: '監造費請款進度',
        accessor: 'amountProgress',
      },
      {
        Header: '合約開工日期',
        accessor: 'contractStartDate',
      },
      {
        Header: '完工日期',
        accessor: 'completedDate',
      },
      {
        Header: 'projectID',
        accessor: 'projectID',
      },
    ],
    []
  );


  //不管資料總數多少 一頁顯示的最大筆數
  // const data = React.useMemo(() => makeData(10), []);
  // const data = React.useMemo(() => makeData(frontPage, frontPage.length), []);
  // const data = React.useMemo(() => makeData(frontPage), []);
  // <makeData
  //   setFrontPage={setFrontPage}
  //   frontPage={frontPage}
  // />;
  // const data = React.useMemo(() => makeData(frontPage, frontPage.length), []);


  const data = React.useMemo(() => makeData(frontPage, frontPage.length), [frontPage]);


  async function GetFrontPage() {
    try {
      let data = await StatisticAPI.GetFrontPage();
      let dataX = data.map(ele => ({
        ...ele,
        ApplyName: ele.projectNum + '／' + ele.projectShortName,
        progressTXT: ele.progress > 1 ? '落後' : ele.progress > 0 ? '超前' : '及時',
        Longitude: ele.lng,
        Latitude: ele.lat,
      }));
      setFrontPage(dataX);
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  React.useEffect(() => {
    GetFrontPage();
    // const data = React.useMemo(() => makeData(frontPage, frontPage.length), []);
    // RerenderData();
  }, []);

  // const url = tools.CheckEnv() === 'prod' ?
  //   'https://bi3.stantec.com.tw/views/DashboardProd/sheet6?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link' :
  //   'https://bi3.stantec.com.tw/views/DashboardNew/sheet5?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link';
  return (
    <>
      <SwitchMode />
      <TBcontainer style={{ backgroundColor: isDarkMode ? '#444' : '#F1F1F1' }}>
        <MapBlock>
          {/* <div id="map"></div> */}
          <Map frontPage={frontPage}></Map>
        </MapBlock>
        <RightArea>
          <PosterBlock style={{ color: isDarkMode ? '#FFF' : '#000' }}>
            <Poster style={{ backgroundColor: isDarkMode ? '#333' : '#FFF' }}>
              <PosterTitle>在建工程案件數</PosterTitle>
              <PosterContent>{frontPage.filter(ele => ele.stage === "進行中").length}</PosterContent>
            </Poster>
            <Poster style={{ backgroundColor: isDarkMode ? '#333' : '#FFF' }}>
              <PosterTitle>驗收作業中案件數</PosterTitle>
              <PosterContent>{frontPage.filter(ele => ele.stage === "驗收作業中").length}</PosterContent>
            </Poster>
          </PosterBlock>
          <TableBlock>
            <div className="table">
              {/* <div className="table" style={{ maxHeight: '200px', overflow: 'auto' }} ></div> */}
              {/* <table>
                <tbody>
                  <tr>
                    <td>專案案號 / 簡稱</td>
                    <td>主辦機關</td>
                    <td>工程階段</td>
                    <td>累計預定進度</td>
                    <td>累計實際進度</td>
                    <td>超前 / 落後</td>
                    <td>廠商估驗進度</td>
                    <td>監造費請款進度</td>
                    <td>合約開工日期</td>
                    <td>完工日期</td>
                  </tr>
                  <tr>
                    <td>專案案號 / 簡稱</td>
                    <td>主辦機關</td>
                    <td>工程階段</td>
                    <td>累計預定進度</td>
                    <td>累計實際進度</td>
                    <td>超前 / 落後</td>
                    <td>廠商估驗進度</td>
                    <td>監造費請款進度</td>
                    <td>合約開工日期</td>
                    <td>完工日期</td>
                  </tr>
                  <tr>
                    <td>專案案號 / 簡稱</td>
                    <td>主辦機關</td>
                    <td>工程階段</td>
                    <td>累計預定進度</td>
                    <td>累計實際進度</td>
                    <td>超前 / 落後</td>
                    <td>廠商估驗進度</td>
                    <td>監造費請款進度</td>
                    <td>合約開工日期</td>
                    <td>完工日期</td>
                  </tr>
                  <tr>
                    <td>專案案號 / 簡稱</td>
                    <td>主辦機關</td>
                    <td>工程階段</td>
                    <td>累計預定進度</td>
                    <td>累計實際進度</td>
                    <td>超前 / 落後</td>
                    <td>廠商估驗進度</td>
                    <td>監造費請款進度</td>
                    <td>合約開工日期</td>
                    <td>完工日期</td>
                  </tr>
                </tbody>
              </table><br /><br /> */}
              <Table columns={columns} data={data} isDarkMode={isDarkMode} />
            </div>
          </TableBlock>
        </RightArea>
      </TBcontainer>
      <br /><br /><br /><br /><br />

      {/* <TableauDashboard url={url} /> */}
    </>
  );
};
export default DashboardMain;