import API from "./ApiConfig";

const EkphrasisAPI = {
  // 圖說大類列表
  GetEkphrasisCategoryList: (projectId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Ekphrasis/GetEkphrasisCategoryList/${projectId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 圖說版次列表
  GetEkphrasisEditionList: (ekphrasisCategoryId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Ekphrasis/GetEkphrasisEditionList/${ekphrasisCategoryId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得圖說項目列表
  GetEkphrasisList: ({ekphrasisEditionId, page}) => {
    return new Promise((resolve, reject) => {
      API.get(`/Ekphrasis/GetEkphrasisList/${ekphrasisEditionId}/${page}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 新增/修改圖說大類API
  ModifyEkphrasisCategory: (categoryModify) => {
    return new Promise((resolve, reject) => {
      API.post(`/Ekphrasis/ModifyEkphrasisCategory`, categoryModify)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 新增/修改圖說版次API
  ModifyEkphrasisEdition: (editionModify) => {
    return new Promise((resolve, reject) => {
      API.post(`/Ekphrasis/ModifyEkphrasisEdition`, editionModify)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 新增/修改圖說項目API
  ModifyEkphrasis: (ekphrasisModify) => {
    return new Promise((resolve, reject) => {
      API.post(`/Ekphrasis/ModifyEkphrasis`, ekphrasisModify)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default EkphrasisAPI;