import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useHistory, useParams} from 'react-router-dom';
import { notifyFailed, notifySuccess } from './../assets/toaster';
import tools from './../assets/tools';
import ProjectsGroupAPI from "./../assets/api/ProjectsGroupAPI";
import HelperAPI from "./../assets/api/HelperAPI";
import Select from 'react-select';
import { ToastContainer } from 'react-toastify';

const Table = styled.div`
table {
  tbody {
      td {
          &:nth-of-type(2) {
            width: 60%;
          }
          input[type="radio"] {
            &:first-of-type{
              
            }
        }
      }
  }
}
`;


const ProjectGroupEdit = () => {
  const { projectid } = useParams();
  const history = useHistory();
  const [projectListAll, setProjectListAll] = useState([]); //全部選單
  const [projectList, setProjectList] = useState(
    {
      id: 0,
      projectGroupName: "",
      projectGroupItemList: [],
    }
  );
  const [pageStatus, setPageStatus] = useState(0);
  const [isSaving, setIsSaving] = useState(false);

  const GetProjectList = async () => {
    try {
      let data = await HelperAPI.GetProjectList();
      setProjectListAll(data);
    } catch (error) {
      console.log(error);
    }
  };

  const GetProjectGroupRelatedList = async (projectGroupId) => {
    try {
      let data = await ProjectsGroupAPI.GetProjectGroupRelatedList(projectGroupId);
      const projectListItem = data.projectList.map((projectList) => (
        {
          projectID: projectList.id,
          projectName: projectList.projectName, 
          isMainProject: projectList.isMainProject,
          isExist: true,
          _uuid: tools._UUID(),
        }
      ));
      const newData = {
        id: projectGroupId,
        projectGroupName: data.projectGroupName, 
        projectGroupItemList: projectListItem,
      };
      setProjectList(newData);

    } catch (error) {
      console.log(error);
    }
  };
  
  const RoleOptions =[];
  const RoleOptionsFilter = projectListAll.filter((option) => !projectList.projectGroupItemList.some((ele) => ele.projectID === option.id));
  RoleOptionsFilter.map((ele) => {
    RoleOptions.push(
      {
        "key": ele.id,
        "value": ele.projectName,
        "label": ele.projectName,
      }
    );
  });

  function ProjectGroupItemListRole ({
    _uuid = 0, 
    projectID = Number(RoleOptions.key) ? Number(RoleOptions.key) : 0, 
    isMainProject= false,
  }) {
    {
      this. _uuid = _uuid;
      this.projectID = projectID;
      this.isMainProject = isMainProject;
    }
  }

  const handleGroupNameChange = (e) => {
    setProjectList({
      ...projectList,
      projectGroupName: e.target.value,
    });
  };

  const handleCreateList = () => {
    const newProjectList = {...projectList};
    const newItem = new ProjectGroupItemListRole({_uuid: tools._UUID()});
    if (!Array.isArray(newProjectList.projectGroupItemList)) {
      newProjectList.projectGroupItemList = [];
    }
    newProjectList.projectGroupItemList.push(newItem);
    if (newProjectList.projectGroupItemList.length === 1) {
      newItem.isMainProject = true;
    }
    setProjectList(newProjectList);
  };

  const handleProjectSelect = (selectedOption, uuid) => {
    const newProjectList = projectList.projectGroupItemList.map((ele) => {
      if (ele._uuid === uuid) {
        if (projectList.projectGroupItemList.length === 1) {
          const projectListRole = new ProjectGroupItemListRole({_uuid: uuid, projectID: selectedOption.key, isMainProject: true});
          return { ...ele, ...projectListRole };
        } else {
          const projectListRole = new ProjectGroupItemListRole({_uuid: uuid, projectID: selectedOption.key,});
          return { ...ele, ...projectListRole };
        }
      }
      return ele;
    });
    setProjectList({...projectList, projectGroupItemList: newProjectList });
  };

  const handleRadioChange = (e, item, ) => {
    const newProjectList = projectList.projectGroupItemList.map((ele) => {
      if (ele._uuid === item._uuid) {
        return { 
          ...ele,
          isMainProject: e.target.checked === true,
        };
      }
      if (ele._uuid != item._uuid) {
        return { 
          ...ele,
          isMainProject: e.target.checked === false,
        };
      }
      return ele;
    });
    setProjectList({ ...projectList, projectGroupItemList: newProjectList });
  };
  

  const handleDeleteProjectList = (delItem) => {
    if (delItem.isExist) {
      const newProjectGroupItemList = projectList.projectGroupItemList.map((ele) =>
        ele._uuid === delItem._uuid ? { ...ele, isExist: false } : ele
      );
      const filteredItemList = newProjectGroupItemList.filter(
        (ele) => ele._uuid !== delItem._uuid
      );
      setProjectList({ ...projectList, projectGroupItemList: filteredItemList, });
    } 
    else {
      const newProjectGroupItemList = projectList.projectGroupItemList.filter(
        (ele) => ele._uuid !== delItem._uuid
      );
      setProjectList({ ...projectList,projectGroupItemList: newProjectGroupItemList, });
    }
  };

  const SaveValidate = (form) => {
    if (!form.projectGroupName) {
      notifyFailed({Msg: '請填寫專案群組名稱', isReload: false});
      return false;
    }
    if (form.projectGroupItemList.length === 0) {
      notifyFailed({Msg: '請新增專案', isReload: false});
      return false;
    }
    if (!form.projectGroupItemList.filter(ele => ele.projectID === 0)) {
      notifyFailed({Msg: '請選擇專案', isReload: false});
      return false;
    }
    if (!form.projectGroupItemList.some(ele => ele.isMainProject)) {
      notifyFailed({Msg: '請選擇一個主要專案', isReload: false});
      return false;
    }
    return true;
  };

  const handleSaveClick = async () => {
    setIsSaving(true);
    const filteredProjectGroupItemList = projectList.projectGroupItemList.map((ele) => (
      {
        projectID: ele.projectID,
        isMainProject: ele.isMainProject,
      }
    ));
    const projectListData = {
      id: projectList.id,
      projectGroupName: projectList.projectGroupName,
      projectGroupItemList: filteredProjectGroupItemList,
    };

    let validate = SaveValidate(projectListData);
    if (validate) {
      try {
        await ProjectsGroupAPI.ModifyProject( projectListData );  
        if (pageStatus) {
          notifySuccess({Msg: '儲存成功', cbfunc: () => history.push("/manage/project-group-manage")});
          setIsSaving(false);
        }  
        // if (pageStatus === 2) {
        //   notifySuccess({Msg: '儲存成功', cbfunc: () => window.location.reload()});
        //   setIsSaving(false);
        // }  

      } catch (error) {
        notifyFailed({Msg: '發生錯誤', isReload: false});
        setIsSaving(false);
      }
    } else {
      setIsSaving(false);
    }  
  };

  useEffect(async () => {
    if (projectid !== 'create') {
      GetProjectGroupRelatedList(projectid);
      setPageStatus(2);
    } else if (projectid === 'create') {
      setPageStatus(1);
    }
    GetProjectList();
  }, []);

  const ProjectGroupEdit = (props) => {
    return (
      <tr>
        <td>{props.index+1}</td>
        <td>{props.item.projectName}</td>
        <td>                        
          <label>
            <input 
              type = "radio" 
              name = "mainProject"
              value = {props.item.isMainProject} 
              checked = {props.item.isMainProject === true} 
              onChange = {(e) =>handleRadioChange(e, props.item)}
              readOnly
            />
          </label></td>
        <td>
          <button className="btnM btnRed" onClick={() => handleDeleteProjectList(props.item)}>刪除</button>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="bumper_rail"></div>
      <div className="commontitle">
        <h1>專案群組管理</h1>
      </div>
      <div className="filter_table">
        <div className="filter_table_input">
          <div className="">專案群組名稱：</div>
          <div className="form_input">
            <input type="text" value={projectList.projectGroupName} onChange={handleGroupNameChange}/>
          </div>
        </div>
      </div>
      <Table className="table">
        <table>
          <tbody>
            <tr>
              <td>項次</td>
              <td>專案</td>
              <td>主要專案</td>
              <td><button className="btnM btnBlue" onClick={handleCreateList}>新增</button></td>
            </tr>
            {
              projectList.projectGroupItemList.length > 0 ? 
                projectList.projectGroupItemList.map((ele, index) => (
                  ele.isExist ?
                    <ProjectGroupEdit item={ele} index={index} key={ele._uuid}/>
                    : 
                    <tr key={ele._uuid}>
                      <td>{index+1}</td>
                      <td className="forReactSelect">
                        <Select
                          placeholder="選擇專案"
                          onChange={(selectedOption) => handleProjectSelect(selectedOption, ele._uuid)}
                          value={RoleOptions.value}
                          options={RoleOptions} 
                        />
                      </td>
                      <td>
                        <label>
                          <input 
                            type = "radio" 
                            name = "mainProject"
                            value = {ele.isMainProject} 
                            checked = {ele.isMainProject === true} 
                            onChange = {(e) =>handleRadioChange(e, ele)}
                            readOnly
                          />
                        </label>
                      </td>
                      <td>
                        <button className="btnM btnRed" onClick={() => handleDeleteProjectList(ele)}>刪除</button>
                      </td>
                    </tr>
                ))
                :""
            }
          </tbody>
        </table>
      </Table>
      <div className="btn_box">
        <button className="btnL btnBlue" onClick={handleSaveClick}> 
          {
            isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '存檔'
          }
        </button>
      </div>
      <ToastContainer />
    </>
  );
};
export default ProjectGroupEdit;