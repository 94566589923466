import { useState } from 'react';
import QueryPanel from './QueryPanel';
import FormItemManage from './FormItemManage';
// import { Link } from "react-router-dom";
// import { TemplateAPI } from './../assets/api';

// import { notifyFailed } from './../assets/toaster';
import { ToastContainer } from 'react-toastify';
import Pagination from './../assets/Pagination';

const FormList = () => {
  const [formList, setFormList] = useState([]);
  const [totalPages, setTotalPages] = useState(1); // 要到的資料總頁數
  const [currentPage, setCurrentPage] = useState(1); // 目前所在頁

  const [moduleSelected, setModuleSelected] = useState({
    id: '',
    moduleName: ''
  });
  const [updateForm, setUpdateForm] = useState({
    moduleId: 0,
    formId: 0
  });
  const [isListOn, setIsListOn] = useState(true);

  const [pagesModuleId, setPagesModuleId] = useState('');

  const handleFormToUpdate = (form) => {
    setUpdateForm({
      moduleId: form.moduleId,
      formId: form.id
    });
    setIsListOn(false);
  };

  return (
    <>
      <QueryPanel
        formList={formList}
        setFormList={setFormList}
        moduleSelected={moduleSelected}
        setModuleSelected={setModuleSelected}
        setIsListOn={setIsListOn}
        setUpdateForm={setUpdateForm}
        setTotalPages={setTotalPages}
        setCurrentPage={setCurrentPage}
        pagesModuleId={pagesModuleId}
        setPagesModuleId={setPagesModuleId}
      />
      <div className="main" style={{ display: isListOn ? "block" : "none" }}>
        <div className="commontitle">
          <h1>表單樣本管理</h1>
        </div>
        <div className="statusbar">
        </div>

        <div className="table">
          <table>
            <tbody>
              <tr>
                <td>表單分類</td>
                <td>表單名稱</td>
                <td>狀態</td>
              </tr>
              {
                formList.map((ele) => {
                  return (
                    <tr key={ele.id}>
                      <td>{ele.moduleName}</td>
                      <td>
                        {/* <Link to={{ pathname: `form-item-manage/${ele.moduleId}/${ele.id}` }} className="nowrap">{ele.formName}</Link> */}
                        <a onClick={() => handleFormToUpdate(ele)} href='#!'>{ele.formName}</a>
                      </td>
                      <td>{ele.enable ? `啟用` : `停用`}</td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>

        {/* <div className="pagesbar">
        </div> */}
        <Pagination
          setTotalPages={setTotalPages}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setList={setFormList}
          apiFunc={'GetTemplateFormList'}
          moduleId={pagesModuleId}
        />
      </div>
      <div style={{ display: isListOn ? "none" : "block" }}>
        <FormItemManage
          moduleId={updateForm.moduleId}
          formId={updateForm.formId}
          moduleSelected={moduleSelected}
          setIsListOn={setIsListOn}
          setFormList={setFormList}
        />
      </div>
      <ToastContainer />
    </>
  );
};
export default FormList;