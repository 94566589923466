import API from "./ApiConfig";

const HelperAPI = {
  // 取得專案人員下拉選單角色資料
  GetRoleList: () => {
    return new Promise((resolve, reject) => {
      API.get(`/Component/GetRoleList`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 模組列表
  GetModuleList: (hasItem) => {
    return new Promise((resolve, reject) => {
      API.get(`/Component/GetModuleList/${hasItem === null ? '' : hasItem}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得專案表單列表
  GetProjectFormList: ({ projectId, moduleId }) => {
    return new Promise((resolve, reject) => {
      API.get(`/Component/GetProjectFormList/${projectId}/${moduleId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得系統範本表單列表(專案表單用,enable=true)
  GetTemplateFormList: ({ moduleId }) => {
    return new Promise((resolve, reject) => {
      API.get(`/Component/GetTemplateFormList/${moduleId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得系統範本資料(enable=true)
  GetTemplateForm: ({ templateFormId }) => {
    return new Promise((resolve, reject) => {
      API.get(`/Component/GetTemplateForm/${templateFormId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  //
  GetHSItemCategoryList: (publicAll) => {
    return new Promise((resolve, reject) => {
      API.get(`/Component/GetHSItemCategoryList/${publicAll}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得專案人員下拉選單
  GetUserList: (projectId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Component/GetUserList/${projectId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得BI TOKEN
  GetBIToken: () => {
    return new Promise((resolve, reject) => {
      API.get(`/Component/GetBIToken`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得安衛單位
  GetUnitTypeList: () => {
    return new Promise((resolve, reject) => {
      API.get(`/Component/GetUnitTypeList`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得統計報表專案的下拉選單
  // 取得工程項目清單
  GetStaticProjectList: () => {
    return new Promise((resolve, reject) => {
      API.get(`/Component/GetStaticProjectList/`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // 取得專案下拉選單
  GetProjectList: ( ) => {
    return new Promise((resolve, reject) => {
      API.get(`/Component/GetProjectList`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

};

export default HelperAPI;