import React from "react";
import styled from '@emotion/styled';
import moment from 'moment';

import tools from './../../assets/tools';
import PictureUpload from './../../components/PictureUpload';

import "./../../../style/Form.scss";
import "./../../../style/FormDetail.scss";

const FormTitle = styled.h1`
  font-size: 1.4rem;
  font-weight: 700;
  margin: 8px;
`;

const DateInput = styled.input`
  padding: 0 4px;
  box-sizing: border-box;
  height: 32px;
  border-radius: 3px;
  border: 1px solid #999;
  // min-width: 5em;
  max-width: 18em;
  margin: 0 3px;
  @media (max-width: 450px) {
    width: 100%;
  }
`;

const FakeHr = styled.div`
  width: 92%;
  height: 2px;
  background-color: #DDD;
  margin: 40px 4% 40px 4%;
`;

const FaultDescTextarea = styled.textarea`
  border: 1px solid #999;
  width: 100%;
  max-width: 1064px;
`;

const ImprovePhotoDiv = styled.div`
  background-color: #E1E1E1;
  padding: 25px 5px 5px 5px;
  margin: 0 10px;
`;

const AttachErrorNotify = (props) => {
  class Photo {
    constructor({ _uuid = 0, id = 0, projectFormId, mainId, content, status = 0 }) {
      this._uuid = _uuid;
      this.id = id;
      this.projectFormId = projectFormId;
      this.mainId = mainId;
      this.file = null;
      this.photoUrl = null;
      this.content = content;
      this.status = status;
    }
  }

  const handleAbnormalFormInputChange = (e) => {
    props.setAbnormalForm({
      ...props.abnormalForm,
      [e.target.name]: e.target.value
    });
  };

  const handleCreateAbnormalPhoto = () => {
    props.setAbnormalForm({
      ...props.abnormalForm,
      photoList: [...props.abnormalForm.photoList, new Photo({ _uuid: tools._UUID(), id: 0, projectFormId: props.formContent.projectFormId, mainId: props.formContent.id || 0, content: '', status: 1})]
    });
  };

  const handleAbnormalImgUpload = (el) => {
    props.setAbnormalForm({
      ...props.abnormalForm,
      photoList: props.abnormalForm.photoList.map(ele => (ele._uuid === el._uuid ? el : ele))
    });
  };

  const handleAbnormalImgDelete = (el) => {
    if (el.isExist) {
      props.setAbnormalForm({
        ...props.abnormalForm,
        photoList: props.abnormalForm.photoList.map(ele => (ele._uuid === el._uuid ? {...el, status: 3} : ele))
      });
    } else {
      props.setAbnormalForm({
        ...props.abnormalForm,
        photoList: props.abnormalForm.photoList.filter(ele => ele._uuid !== el._uuid)
      });
    }
  };

  return (
    <div style={{display: props.formContent.itemList.filter(ele => Number(ele.result) === 2).length > 0 ? 'block' : 'none'}}>
      <FormTitle>異常(不合格)通知暨改善表</FormTitle>
      {/* <button className="btnL btnWhite" style={{ margin: '0 10px', }}>新增異常(不合格)通知暨改善表</button> */}

      <div className="form form_loose">
        {/* 一行 */}
        <div className="form_half">
          <div className="form_title">工程名稱：</div>
          <div className="form_input">
            <p>{props.abnormalForm.projectName}</p>
          </div>
        </div>
        <div className="form_half">
          <div className="form_title">監造單位：</div>
          <div className="form_input">
            <p>{props.abnormalForm.supervision}</p>
          </div>
        </div>
        {/* 一行 */}
        <div className="form_half">
          <div className="form_title">文件編號：</div>
          <div className="form_input">
            {
              props.formContent.editable && props.abnormalForm.editable ?
                <input type="text" name="docId" value={props.abnormalForm.docId} onChange={handleAbnormalFormInputChange}/> :
                <p>{props.abnormalForm.docId}</p>
            }
          </div>
        </div>
        <div className="form_half">
          <div className="form_title">查驗日期：</div>
          <div className="form_input">
            {
              props.formContent.editable && props.abnormalForm.editable ?
                <DateInput type="date" name="ExamineDate" value={props.abnormalForm.ExamineDate} onChange={handleAbnormalFormInputChange}/> :
                <p>{moment(props.abnormalForm.ExamineDate).format('YYYY-MM-DD')}</p>
            }
          </div>
        </div>
        {/* 一行 */}
        <div className="form_half">
          <div className="form_title">承攬廠商：</div>
          <div className="form_input">
            {
              props.formContent.editable && props.abnormalForm.editable ?
                <input type="text" name="vendor" value={props.abnormalForm.vendor} onChange={handleAbnormalFormInputChange}/> :
                <p>{props.abnormalForm.vendor}</p>
            }
          </div>
        </div>
        <div className="form_half">
          <div className="form_title">改善完成期限：</div>
          <div className="form_input">
            {
              props.formContent.editable && props.abnormalForm.editable ?
                <DateInput type="date" name="improveDeadline" value={props.abnormalForm.improveDeadline} onChange={handleAbnormalFormInputChange}/> :
                <p>{moment(props.abnormalForm.improveDeadline).format('YYYY-MM-DD')}</p>
            }

          </div>
        </div>
        {/* 一行 */}
        <div className="form_13quarters">
          <div className="form_title">說明缺失具體情形：</div>
          <div className="form_input">
            {
              props.formContent.editable && props.abnormalForm.editable ?
                <FaultDescTextarea name="flawNote" id="" cols="" rows="6" value={props.abnormalForm.flawNote} onChange={handleAbnormalFormInputChange}></FaultDescTextarea> :
                <p>{props.abnormalForm.flawNote}</p>
            }
          </div>
        </div>
      </div>

      <ImprovePhotoDiv className="uploadfiles">
        {
          (props.formContent.editable && props.abnormalForm.editable) && (<button className="btnL btnWhite" style={{ margin: '0 10px', }} onClick={handleCreateAbnormalPhoto}>新增異常(不合格)改善紀錄照片</button>)
        }
        <br />
        {
          props.abnormalForm.photoList.filter(el => el.status !== 3).map(ele => (
            <PictureUpload key={ele._uuid} img={ele} setImg={handleAbnormalImgUpload} deleteImg={handleAbnormalImgDelete} editable={props.formContent.editable && props.abnormalForm.editable}/>
          ))
        }
      </ImprovePhotoDiv>

      <FakeHr />
    </div>
  );
};
export default AttachErrorNotify;