import { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { Link } from "react-router-dom";

import { D3BarChart } from '../assets/d3components/BarChart';
import { D3PieChart } from '../assets/d3components/PieChart';
import { D3Swatches } from '../assets/d3components';
// import colorSchemes from './../assets/d3components/config/scale-chromatic';
// import alphabat from "../assets/d3components/data/alphabat.json";
// import population from "../assets/d3components/data/population.json";
// import states from "../assets/d3components/data/states.json";
import { SwitchMode } from './SwitchMode';
import { ThemeContext } from '../contexts/ThemeContext';
import { StatisticAPI, HelperAPI } from './../assets/api';
import { useLocalStorage } from './useLocalStorage';
import { ChartDownloadButton } from './DownloadImageButton';
import { ExportToExcelButton } from './ExportToExcel';
import { useIsFirstRender } from './../assets/customHooks/useIsFirstRender';

const HalfRight = styled.div`
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  .btnBox{
    display: flex;
    justify-content: space-between;
    margin: 10px 0 0 0;
    .fakeBtn{
      text-decoration: none;
      width:49.5%;
      text-align: center;
      padding: 10px 0;
      color: ${props => props.isDarkMode ? '#e6e6e6' : '#0c0c0c'};
      background-color: ${props => props.isDarkMode ? '#444' : '#fff'};
      display: flex;
      justify-content: center;
      align-items: center;
      &.fakeActive {
        border: 1px solid #F28E2B;
        color: ${props => props.isDarkMode ? '#F28E2B' : '#fff'};
        background-color: ${props => props.isDarkMode ? '#444' : '#F28E2B'};
        cursor: unset;
      }
    }
  }
`;
const Bigboard = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
div{
  box-sizing: border-box;
}
.halfLeft{
  // border:1px solid #F00;
  padding: 10px 10px 0px 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  .divFlow{
    // border:1px solid #000;
    margin: 0 0 10px 0;
    padding: 10px;
    &.conTable{
      width: calc(60% - 5px);
      .ScrollbarDiv{
        margin-bottom: 20px;
        max-height: 305px;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background-color: #DDD;
        }
      }
      .topScrollbarDiv{
        max-height: 565px;
      }
      .belowScrollbarDiv{
        max-height: 305px;
      }
      div{
        .table_fisrtdshorten{
          table-Layout: fixed;
          tbody{
            tr{
              &:first-of-type{
                position: sticky;
                top: 0;
              }
              td{
                &:first-of-type{
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 170px;
                }
              }
            }
          }
        }
      }

      @media (max-width: 1024px) {
        width: 100%;
      }
    }
    &.conHorbar{
      width: calc(40% - 5px);
      @media (max-width: 1024px) {
        width: 100%;
      }
    }
    &.conPie{
      width: calc(40% - 5px);
      @media (max-width: 1024px) {
        width: 100%;
      }
    }
  }
}
.halfRight{
  // border:1px solid #00F;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  .btnBox{
    display: flex;
    justify-content: space-between;
    margin: 10px 0 0 0;
    .fakeBtn{
      text-decoration: none;
      width:49.5%;
      text-align: center;
      padding: 10px 0;
      color:#FFF;
      display: flex;
      justify-content: center;
      align-items: center;
      &.fakeActive{
        border: 1px solid #F28E2B;
        color:#F28E2B;
        cursor: unset;
      }
    }
  }
}
`;
const FromArea = styled.div`
background-color: ${props => props.isDarkMode ? '#444' : '#FFF'};
  table {
    width: 100%;
    color:  ${props => props.isDarkMode ? '#fff' : '#000'};
    text-align: center;
    margin: 1rem 0;
    font-size: 14px;
    table-Layout: fixed;
    tr {
      /* box-shadow: 0 0 2px 0 #CCC; */
      background-color: ${props => props.isDarkMode ? '#444' : '#FFF'};
      border-top: 2px solid #cccccc86;
      &:not(:first-of-type):hover {
        background-color: ${props => props.isDarkMode ? '#555' : '#ECECEC'};
      }
      &:first-of-type {
        position: sticky;
        top: 0;
        background-color: ${props => props.isDarkMode ? '#444' : '#FFF'};
      }
    }
    td {
      padding: 1rem 4px;
      overflow-wrap: anywhere;
      vertical-align: middle;
      max-width: 360px;
      a {
        text-decoration: none;
        color: #007DA3;
      }
    }
  }
`;

const ChartTitle = styled.div`
  color: ${props => props.isDarkMode ? '#E6E6E6 ' : '#000'};
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background-color: ${props => props.isDarkMode ? '#444' : '#fff'};
`;

const HSAChartTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  display: block;
  width: 100%;
  text-align: center;
  padding: 3rem 0 1rem 0 ;
  position: absolute;
  top: 0;
  left: 0;
`;

const colorTheme = {
  light: {
    titleTextColor: "black",
    xValueColor: "black",
    yValueColor: "black",
    backgroundColor: "#FFF",
    color: "#65BAAA",
    // titleAltColor: "white",
  },
  dark: {
    titleTextColor: "white",
    xValueColor: "white",
    yValueColor: "white",
    backgroundColor: "#444",
    color: "#222",
    // titleAltColor: "#222",
  }
};

const DashboardMain = () => {
  const isFirstRender = useIsFirstRender();
  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark' ? true : false;
  const tableClass = isDarkMode ? "table_dark" : "table_light";

  const [projectList, setProjectList] = useState([]); //專案清單
  const [projectId, setProjectId] = useLocalStorage("projectId", projectList.length ? projectList[0].id : 0);
  // const [projectName, setProjectName] = useState();

  const [abnormalImproveDataList, setAbnormalImproveDataList] = useState([]);
  const [abnormalModuleImproveDataList, setAbnormalModuleImproveDataList] = useState([]);
  const [piedata, setPiedata] = useState([]);
  const [bardata, setBardata] = useState([]);

  //取得專案下拉清單
  const GetStaticProjectList = async () => {
    try {
      let data = await HelperAPI.GetStaticProjectList();
      setProjectList(data.projectList);
      return data.projectList;
    } catch (error) {
      console.log(error);
    }
  };

  const [barChartOption, setBarChartOption] = useState({
    chartId: 'barChartSvg',
    downloadAreaId: "hsAbnormalImproveBarChart",
    titleText: "缺失樣態",
    titleTextColor: isDarkMode ? "white" : "black",
    xAttr: 'frequency',
    yAttr: 'letter',
    yLabel: '↑ Daily close ($)',
    xFormat: '',
    xLabel: "Frequency →",
    color: isDarkMode ? "#222" : "#65BAAA",
    titleAltColor: isDarkMode ? "#222" : "white",
    xValueColor: isDarkMode ? "white" : "black",
    yValueColor: isDarkMode ? "white" : "black",
    backgroundColor: isDarkMode ? "#444" : "white",
    marginLeft: 145,
    marginRight: 5,
    height: 310,
    // maxHeight: 310,
    // colorScheme: colorSchemes.sequentialMulti.interpolateWarm
    colorCustom: ["#4e79a7"],
    // xAxisUnit: '%',
  });
  const [pieChartOption, setPieChartOption] = useState({
    chartId: 'pieChartSvg',
    downloadAreaId: "hsAbnormalCategoryNumPieChart",
    titleText: "",
    name: 'formName',
    value: 'num',
    percentage: 'rate',
    backgroundColor: isDarkMode ? "#444" : "white",
    // colorInterpolate: colorSchemes.diverging.interpolateSpectral,
    colorCustom: ["#4e79a7", "#f28e2c", "#e15759", "#76b7b2", "#59a14f", "#edc949", "#af7aa1", "#ff9da7", "#9c755f", "#bab0ab",],

  });
  const PieLegendOption = {
    // colorInterpolate: colorSchemes.diverging.interpolateSpectral,
    colorCustom: ["#4e79a7", "#f28e2c", "#e15759", "#76b7b2", "#59a14f", "#edc949", "#af7aa1", "#ff9da7", "#9c755f", "#bab0ab",],
    titleText: "",
    titleTextColor: "blue",
    backgroundColor: isDarkMode ? "#444" : "white",
  };

  const handleProjectSelect = (e) => {
    let value = projectList.find(ele => ele.id === Number(e.target.value));
    setProjectId(value.id);
    // setProjectName(value.projectName);
  };
  const GetAbnormalImproveRecord = async (projectId) => {
    try {
      let data = await StatisticAPI.GetAbnormalImproveRecord(projectId, true);
      setAbnormalImproveDataList(data.abnormalImproveDataList);
      setAbnormalModuleImproveDataList(data.abnormalModuleImproveDataList);
      // 圓餅圖不要總和
      setPiedata(data.abnormalImproveDataList.slice(0, data.abnormalImproveDataList.length - 1));
      const bdata = data.abnormalCategoryNumList.map(ele => ({ "letter": ele.abnormalCategoryName, "frequency": ele.num }));
      setBardata(bdata);

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setBarChartOption({
      ...barChartOption,
      ...colorTheme[isDarkMode ? "dark" : "light"]
    });
    setPieChartOption({
      ...pieChartOption,
      ...colorTheme[isDarkMode ? "dark" : "light"]
    });
  }, [isDarkMode]);

  useEffect(async() => {
    let staticProjectList = await GetStaticProjectList();
    if (projectId === 0) {
      setProjectId(staticProjectList[0].id);
    } else {
      GetAbnormalImproveRecord(projectId);
    }
  }, []);

  useEffect(() => {
    if (!isFirstRender) {
      GetAbnormalImproveRecord(projectId);
    }
  }, [projectId]);

  return (
    <>
      <SwitchMode />
      <div className="containerBox" style={{ backgroundColor: isDarkMode ? '#666' : '#F1F1F1' }}>
        <section className="dashboardContent">
          <HalfRight isDarkMode={isDarkMode}>
            <div className="btnBox">
              <Link to={{ pathname: `dashboard-new-healthsafe` }} className="fakeBtn">職業安全衛生</Link>
              <Link to={{ pathname: `dashboard-new-hsabnormal` }} className="fakeBtn fakeActive">職安衛缺失改善追蹤</Link>
            </div>
          </HalfRight>

          <div className="dashboardUpArea">
            {/* <div className="dashboardUpArea_left">
              <div className="dashboardTitle" style={{ backgroundColor: isDarkMode ? '' : '#F1F1F1' }}>
                <h1>{projectName}</h1>
              </div>
              <div className="subTitle" style={{ color: isDarkMode ? '#E6E6E6' : '#999' }}>
                <h1>職安衛缺失改善追蹤</h1>
              </div>
            </div> */}
            <div className="projectWrap" style={{ backgroundColor: isDarkMode ? '#555' : '#DDD' }}>
              <h3 style={{ color: isDarkMode ? '#e6e6e6' : '#000' }}>專案名稱</h3>
              <div className="dropdownBox">
                <select
                  className="dropdownMenu" style={{ backgroundColor: isDarkMode ? '#BCBCBC' : '#FFF' }}
                  onChange={(e) => handleProjectSelect(e)}
                  value={projectId}
                >
                  {
                    projectList.map(ele => (
                      <option key={ele.id} value={ele.id}>{ele.projectName}</option>
                    ))
                  }
                </select>
              </div>
            </div>
          </div>

          <Bigboard>
            <div className="halfLeft">
              <div className="divFlow conTable" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF', position: 'relative' }}>
                <div id="hsabnormalStatusTable" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF', marginBottom: "10px" }}>
                  <ChartTitle isDarkMode={isDarkMode}>案件狀態</ChartTitle>
                  <div className={`${tableClass} ScrollbarDiv topScrollbarDiv`} id='hsabnormalStatusTableTitleId'>
                    <table className="table_fisrtdshorten">
                      <tbody>
                        <tr>
                          <td>工項</td>
                          <td>缺失件數</td>
                          <td>已完成改善(數量)</td>
                          <td>列管中</td>
                          <td>已完成改善(率)</td>
                        </tr>
                        {
                          abnormalImproveDataList.map((ele, idx) => {
                            return (
                              <tr key={idx}>
                                <td style={{ textAlign: 'left', paddingLeft: '10px', }}>{ele.formName}</td>
                                <td>{ele.num}</td>
                                <td>{ele.hasImprovedNum}</td>
                                <td>{ele.hasNotImprovedNum}</td>
                                <td>{ele.hasImprovedRate}%</td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                <ExportToExcelButton tableId ="hsabnormalStatusTableTitleId" tableName="職安衛缺失改善追蹤_案件狀態"/>
              </div>

              <div className="divFlow conPie" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF', position: 'relative' }}>
                <div id={pieChartOption.downloadAreaId} className="chartBlock" style={{ color: isDarkMode ? '#FFF' : '#000' }}>
                  {
                    piedata.length > 0 ? <D3PieChart data={piedata} option={pieChartOption} /> : <p style={{ textAlign: 'center' }}>當前專案無資料</p>
                  }
                  {
                    piedata.length > 0 ? <D3Swatches group={piedata.map(p => p.formName)} option={PieLegendOption} /> : ""
                  }
                </div>
                <ChartDownloadButton
                  chartId={pieChartOption.chartId}
                  downloadAreaId={pieChartOption.downloadAreaId}
                  chartName={pieChartOption.titleText}
                />
                {/* <DownloadBtnBox>
                  <button className="chartDownloadBtn" onClick={() => downloadImage(pieChartOption.chartId, pieChartOption.downloadAreaId, pieChartOption.titleText)}>Download</button>
                </DownloadBtnBox> */}
              </div>

              <div className="divFlow conHorbar" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF', position: 'relative' }}>
                <HSAChartTitle style={{ backgroundColor: isDarkMode ? '#444' : '#FFF', color: isDarkMode ? '#FFF' : '#000' }}>缺失樣態</HSAChartTitle>
                <div id={barChartOption.downloadAreaId} className="chartBlock" style={{ color: isDarkMode ? '#FFF' : '#000' }}>
                  {/* <D3BarChart data={bardata} option={barChartOption} /> */}
                  {
                    bardata.length > 0 ? <D3BarChart data={bardata} option={barChartOption} /> : <p style={{ textAlign: 'center' }}>當前專案無資料</p>
                  }
                </div>
                <ChartDownloadButton
                  chartId={barChartOption.chartId}
                  downloadAreaId={barChartOption.downloadAreaId}
                  chartName={barChartOption.titleText}
                  dataLength={bardata.length}
                />
                {/* <DownloadBtnBox>
                  <button className="chartDownloadBtn" onClick={() => downloadImage(barChartOption.chartId, barChartOption.downloadAreaId, barChartOption.titleText, bardata.length)}>Download</button>
                </DownloadBtnBox> */}
              </div>

              <div className="divFlow conTable" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF', position: 'relative' }}>
                <div id="hsabnormalomitStatusTable" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF' }}>
                  <ChartTitle isDarkMode={isDarkMode} >缺失樣態統計</ChartTitle>
                  <FromArea id="omitStatusTableTitleId" className="ScrollbarDiv belowScrollbarDiv" isDarkMode={isDarkMode}>
                    <table>
                      <tbody>
                        <tr>
                          <td>缺失樣態</td>
                          <td>缺失件數</td>
                          <td>已完成改善(數量)</td>
                          <td>列管中</td>
                          <td>已完成改善(率)</td>
                        </tr>
                        {
                          abnormalModuleImproveDataList.map((ele, idx) => {
                            return (
                              <tr key={idx}>
                                <td style={{ textAlign: 'left', paddingLeft: '10px', }}>{ele.moduleName}</td>
                                <td>{ele.num}</td>
                                <td>{ele.hasImprovedNum}</td>
                                <td>{ele.hasNotImprovedNum}</td>
                                <td>{ele.hasImprovedRate} %</td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                    <ExportToExcelButton tableId ="omitStatusTableTitleId" tableName="職安衛缺失改善追蹤_缺失樣態統計"/>
                  </FromArea>
                </div>
              </div>

            </div>
            {/* <div className="halfRight">
              <div className="btnBox">
                <Link to={{ pathname: `dashboard-new-hsabnormal` }} className="fakeBtn fakeActive" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF' }}>職安衛缺失改善追蹤</Link>
                <Link to={{ pathname: `dashboard-new-healthsafe` }} className="fakeBtn" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF', color: isDarkMode ? '#FFF' : '#666' }}>職業安全衛生</Link>
              </div>
            </div> */}
          </Bigboard>
        </section>
      </div>
      <br/><br/><br/>
    </>
  );
};
export default DashboardMain;