import { useParams, useHistory } from 'react-router-dom';
import tools from './../assets/tools';
import { useEffect, useState } from 'react';
import { SupervisionAPI } from '../assets/api';
import { notifyFailed } from '../assets/toaster';
import styled from '@emotion/styled';
import PictureUpload from './../components/PictureUpload';
import moment from "moment";

const FormTitle = styled.h1`
  font-size: 1.4rem;
  font-weight: 700;
  margin: 8px;
`;

const DateInput = styled.input`
  padding: 0 4px;
  box-sizing: border-box;
  height: 32px;
  border-radius: 3px;
  border: 1px solid #999;
  // min-width: 5em;
  max-width: 18em;
  margin: 0 3px;
  @media (max-width: 450px) {
    width: 100%;
  }
`;

const FakeHr = styled.div`
  width: 92%;
  height: 2px;
  background-color: #DDD;
  margin: 40px 4% 40px 4%;
`;

const NoteDiv = styled.div`
  div{
    display: inline-block;
    padding: 3px;
  }
  .form_title{
    width: 11rem;
    text-align: right;
    vertical-align: top;
    padding: 5px 0px 5px 10px;
    @media (max-width: 1025px) {
      width: 100%;
      text-align: left;
    }
  }
  .form_input{
    width: calc(100% - 18rem);
    text-align: left;
    vertical-align: middle;
    overflow: auto;
    input[type="text"] {
      height: 28px;
      border-radius: 3px;
      border: 1px solid #999;
    }
    input[type="number"] {
      height: 28px;
      border-radius: 3px;
      border: 1px solid #999;
    }
    input[type="radio"] {
      margin: 0px 3px 8px 0px;
    }
    padding: 5px 0px 8px 12px;
  }
  .form_input_table{
    width: calc(100%);
    text-align: left;
    vertical-align: middle;
    overflow: auto;
    input[type="text"] {
      height: 28px;
      border-radius: 3px;
      border: 1px solid #999;
    }
    input[type="number"] {
      height: 28px;
      border-radius: 3px;
      border: 1px solid #999;
    }
    input[type="radio"] {
      margin: 0px 3px 8px 0px;
    }
    padding: 5px 0px 8px 12px;
  }
  table{
      width: 99%;
      text-align: center;
      box-shadow: 0 1px 3px 0px #aaaa;
      tr{
        box-shadow: 0 1px 2px 0px #CCCC;
        &:first-of-type{
          background-color: #E3E3E3;
          box-shadow: 0 1px 2px 0px #BBBB;
          font-size: 1.1rem;
        }
        &:not(:first-of-type):hover{
          background-color: #CAC19025;
        }
      }
      td{
        padding: 1rem 10px;
        overflow-wrap: anywhere;
        vertical-align: middle;
        input[type="text"] {
          height: 28px;
          width: 100px;
          border-radius: 3px;
          border: 1px solid #999;
        }
        input[type="number"] {
          height: 28px;
          width: 80px;
          border-radius: 3px;
          border: 1px solid #999;
        }
        input[type="radio"] {
          margin: 0px 3px 8px 0px;
        }
      }
    }
    @media (max-width: 1025px) {
      width: calc(100% - 20px);
    }
`;
const HistoricalRecord = styled.div`
  background-color: #EEE;
  padding: 10px 15px;
  margin: 10px;
`;

const FullTextarea = styled.textarea`
  width: calc(100% - 40px);
  margin: 0 10px;
  border: 1px solid #CCC;
`;

const FloatPointCoat = styled.a`
    color:#333;
`;

const FloatPoint = styled.div`
    border: 1px solid #AAA;
    background: #EEE8;
    position: fixed;
    bottom: 2em;
    right: 2em;
    border-radius: 50%;
    width: 3.5em;
    height: 3.5em;
    text-align: center;
    span{
      position: relative;
      top: 0.35em;
    }
`;


const MonthlyReportDetail = () => {
  const { projectId, mainId } = useParams();
  const history = useHistory();
  const [formContent, setFormContent] = useState({
    _uuid: tools._UUID(),
    id:0,
    projectId,
    fillInMonth: '', //填報月份
    scheduledProgress: '無資料', //累計至本月預定進度(%)
    actualProgress:'無資料', //累計至本月實際進度 (%)
    schedule: 0, //進度
    projectContractList: [],
    projectExecutionNote:'', //工程執行現況概述
    supervisionMonthlyFineList: [],
    supervisionMonthlyInspectionList: [],
    designChangeNote: '', //變更設計情形說明
    supervisionContractAmount: '', //監造合約金額
    contractRequestPaymentCondition: '', //監造費契約請款條件
    contractPaymentWork: 0, //監造費用請款作業 0:異常 1:正常
    lastMonthRequestPaymentTotal: 0, //上月累計請款金額
    requestPaymentAmount: 0, //本月請款金額
    requestPaymentTotal:0, //本月累計請款金額
    requestPaymentSchedule: 0, //監造費請款進度
    companyEstimating: 0, //廠商估驗進度
    normalOrAbnormal: '', //正常或異常原因
    supervisionMonthlyPersonnelList: [],
    contract: '', //契約規定
    extraService: '', //業主要求額外服務
    awardCategory: '', //欲參獎類別(含時程說明)
    labourInspection: 0, //是否有勞檢
    supervisionMonthlyLaborInspectionList: [],
    occupationalAccident: 0, //本月是否有職災事件
    occupationalAccidentNote: '', //職災事件說明
    supervisionMonthlyPhotoList: [],
    formStatus: 0,
    status: 0,
    historyRecordList: []
  });

  const GetMonthlyInfo = async(mainId) => {
    try {
      let data = await SupervisionAPI.GetSupervisionMonthlyInfo(projectId,mainId);
      setFormContent({
        ...data,
        schedule: data.schedule== true ? 1 : 0,
        projectContractList: data.projectContractList.map(ele=>{ //工程專案資訊 唯讀
          return ({
            ...ele,
            _uuid: tools._UUID(),
            stageContract: ele.stageContract ? ele.stageContract :'',
            contractStartDate: ele.contractStartDate ? moment(ele.contractStartDate).format('YYYY-MM-DD'):'',
            completedDate: ele.completedDate ? moment(ele.completedDate).format('YYYY-MM-DD'):'',
          });
        }),
        supervisionMonthlyFineList: data.supervisionMonthlyFineList.map(ele =>{ //監造罰款情形說明
          return ({
            ...ele,
            _uuid:tools._UUID(),
            id:ele.id,
            date: ele.date ? moment(ele.date).format('YYYY-MM-DD'):'',
            reason: ele.reason,
            amount: ele.amount,
            status: 2,
            isExist: true
          });
        }),
        supervisionMonthlyInspectionList: data.supervisionMonthlyInspectionList.map(ele =>{ //上級督導查核說明
          return ({
            ...ele,
            _uuid: tools._UUID(),
            id:ele.id,
            date: ele.date ? moment(ele.date).format('YYYY-MM-DD') : '',
            organizer: ele.organizer || '',
            item: ele.item || '',
            grade: ele.grade || '',
            score: ele.score || '',
            isDeduct: ele.isDeduct == true ? 1 : 0,
            deducePoint: ele.deducePoint || '',
            amout: ele.amount || '',
            status: 2,
            isExist: true
          });
        }),
        contractPaymentWork: data.contractPaymentWork==true ? 1 : 0, //監造費用請款作業 0:異常 1:正常
        supervisionMonthlyPersonnelList: data.supervisionMonthlyPersonnelList.map(ele=>{ //人員異動情形
          return ({
            ...ele,
            _uuid: tools._UUID(),
            id:ele.id,
            jobTitle: ele.jobTitle,
            name: ele.name,
            report: ele.report == true ? 1 : 0,
            execution: ele.execution == true ? 1 : 0,
            cooperation: ele.cooperation,
            isSupport: ele.isSupport == true ? 1 : 0,
            note: ele.note,
            status: 2
          });
        }),
        labourInspection: data.labourInspection == true ? 1 : 0, //是否有勞檢
        supervisionMonthlyLaborInspectionList: data.supervisionMonthlyLaborInspectionList.map(ele=>{ //勞檢清單
          return ({
            ...ele,
            _uuid: tools._UUID(),
            id:ele.id,
            date: ele.date ? moment(ele.date).format('YYYY-MM-DD') : '',
            isFine: ele.isFine == true ? 1: 0,
            amount: ele.amount,
            status: 2,
            isExist: true
          });
        }),
        occupationalAccident: data.occupationalAccident == true ? 1: 0, //本月是否有職災事件
        supervisionMonthlyPhotoList: data.supervisionMonthlyPhotoList.map(ele => { //照片
          return ({
            ...ele,
            _uuid: tools._UUID(),
            id:ele.id,
            projectId: ele.projectId,
            monthlyID: ele.monthlyID,
            isiOS: ele.isiOS,
            status: 2,
            isExist: true
          });
        }),
      });
    } catch (error) {
      notifyFailed(`發生錯誤`, false);
    }
  };

  useEffect(async() => {
    if(formContent.id===0) {
      GetMonthlyInfo(mainId);
    }
  }, []);

  const goBack = () => {
    history.goBack();
    // history.push(`/cmgmgt/monthly-report`);
  };

  return (
    <>
      <div className="sharebox">
        <div className="" id="Top"></div>
        <FormTitle> 專案工作月報表 </FormTitle>
        <div className="queryMenu_btnbox" style={{width: '100%', textAlign: 'right', }}>
          <button className="btnL btnBlue" onClick={goBack}>回到列表</button>
        </div>
        <div className="form form_loose" style={{paddingBottom: '10px',}}>
          <div className="form_half">
            <div className="form_title">
              工程名稱：
            </div>
            <div className="form_input">
              <p>{formContent.projectName}</p>
            </div>
          </div>
          <div className="form_half">
            <div className="form_title">
              填報月份：
            </div>
            <div className="form_input">
              <DateInput type="month" name="fillInMonth" value={formContent.fillInMonth} readOnly />
            </div>
          </div>
        </div>
        <FakeHr />
        <FormTitle>壹、工程專案進度</FormTitle>
        <NoteDiv>
          <div className="form_title">
            工程專案資訊：
          </div>
          <div className="form_input">
            <table>
              <tbody>
                <tr>
                  <td>分段履約</td>
                  <td>工程開工日期</td>
                  <td>預計完工日期</td>
                  <td>總工期</td>
                </tr>
                {
                  formContent.projectContractList.map(ele=>(
                    <tr key={ele._uuid}>
                      <td><span>{ele.stageContract}</span></td>
                      <td><span>{ele.contractStartDate}</span></td>
                      <td><span>{ele.completedDate}</span></td>
                      <td><span>{ele.contractDuration}</span></td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          <div className="form_title">
            累計至本月預定進度(%)：
          </div>
          <div className="form_input">
            <p>{formContent.scheduledProgress}</p>
          </div>
          <div className="form_title">
            累計至本月實際進度 (%)：
          </div>
          <div className="form_input">
            <p>{formContent.actualProgress}</p>
          </div>
          <div className="form_title">
            進度(%)：
          </div>
          <div className="form_input">
            <label><input type="radio" name={`schedule${formContent._uuid}`} value={1} checked={formContent.schedule===1} readOnly />超前</label>
            <label><input type="radio" name={`schedule${formContent._uuid}`} value={0} checked={formContent.schedule===0} readOnly />落後</label>
          </div>
          <div className="form_title">
            超前或落後原因：
          </div>
          <div className="form_input">
            <FullTextarea name="scheduleNote" value={formContent.scheduleNote || ''} rows="8" readOnly ></FullTextarea>
          </div>
        </NoteDiv>

        <FakeHr />

        <FormTitle>貳、工程現況重點說明</FormTitle>

        <NoteDiv>
          <div className="form_title">
            工程執行現況概述：
          </div>
          <div className="form_input">
            <FullTextarea rows="8" name="projectExecutionNote" value={formContent.projectExecutionNote || ''} readOnly ></FullTextarea>
          </div>
          <div className="form_title">
            監造罰款情形說明：
          </div>
          <div className="form_input">
            <table>
              <tbody>
                <tr>
                  <td>日期</td>
                  <td>原因</td>
                  <td>罰款金額</td>
                </tr>
                {
                  formContent.supervisionMonthlyFineList.map(ele=>(
                    <tr key={ele._uuid}>
                      <td>
                        {
                          <DateInput type="date" name="date" value={ele.date} readOnly />
                        }
                      </td>
                      <td>
                        {
                          <input type="text" name="reason" value={ele.reason} readOnly />
                        }
                      </td>
                      <td>
                        {
                          <input type="number" name="amount" value={ele.amount} readOnly />
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          <div className="form_title">
            上級督導查核說明：
          </div>
          <div className="form_input_table">
            <table>
              <tbody>
                <tr>
                  <td>日期</td>
                  <td>主管單位</td>
                  <td>查核項目</td>
                  <td>查核級等</td>
                  <td>查核分數</td>
                  <td>是否扣點</td>
                  <td>罰款金額</td>
                </tr>
                {
                  formContent.supervisionMonthlyInspectionList.map(ele=>(
                    <tr key={ele._uuid}>
                      <td>
                        {
                          <DateInput type="date" name="date" value={ele.date} readOnly />
                        }
                      </td>
                      <td>
                        {
                          <input type="text" name="organizer" value={ele.organizer} readOnly />
                        }
                      </td>
                      <td>
                        {
                          <input type="text" name="item" value={ele.item} readOnly />
                        }
                      </td>
                      <td>
                        {
                          <input type="text" name="grade" value={ele.grade} readOnly />
                        }
                      </td>
                      <td>
                        {
                          <input type="text" name="score" value={ele.score} readOnly />
                        }
                      </td>
                      <td style={{ textAlign:'left',}} className="nowrap">
                        <label><input type="radio" name={`isDeduct${formContent._uuid+ele._uuid}`} value={0} checked={ele.isDeduct===0} readOnly />否</label>
                        <label><input type="radio" name={`isDeduct${formContent._uuid+ele._uuid}`} value={1} checked={ele.isDeduct===1} readOnly/>是</label>
                        {
                          <input type="number" name="deductPoint" value={ele.isDeduct===0? '' : ele.deductPoint} disabled={ele.isDeduct===0} readOnly />
                        }
                      </td>
                      <td>
                        {
                          <input type="number" name="amount" value={ele.amount} readOnly />
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          <div className="form_title">
            變更設計情形說明：
          </div>
          <div className="form_input">
            <FullTextarea name="designChangeNote" value={formContent.designChangeNote || ''} rows="8" readOnly ></FullTextarea>
          </div>
        </NoteDiv>

        <FakeHr />

        <FormTitle>參、監造費用請款說明(含稅)</FormTitle>

        <NoteDiv>
          <div className="form_title">
            監造合約金額：
          </div>
          <div className="form_input">
            <p>{formContent.supervisionContractAmount}</p>
          </div>
          <div className="form_title">
            監造費契約請款條件：
          </div>
          <div className="form_input">
            {
              <input type="text" name="contractRequestPaymentCondition" value={formContent.contractRequestPaymentCondition} readOnly />
            }
          </div>
          <div className="form_title">
            監造費用請款作業：
          </div>
          <div className="form_input">
            <label><input type="radio" name="contractPaymentWork" value={1} checked={formContent.contractPaymentWork===1} readOnly />正常</label>
            <label><input type="radio" name="contractPaymentWork" value={0} checked={formContent.contractPaymentWork===0} readOnly />異常</label>
          </div>
          <div className="form_title">
            上月累計請款金額：
          </div>
          <div className="form_input">
            <p>{formContent.lastMonthRequestPaymentTotal}</p>
          </div>
          <div className="form_title">
            本月請款金額：
          </div>
          <div className="form_input">
            {
              <input type="number" name="requestPaymentAmount" value={formContent.requestPaymentAmount || 0} readOnly />
            }
          </div>
          <div className="form_title">
            本月累計請款金額：
          </div>
          <div className="form_input">
            <p>{formContent.requestPaymentTotal}</p>
          </div>
          <div className="form_title">
            監造費請款進度(%)：
          </div>
          <div className="form_input">
            <p>{formContent.supervisionContractAmount!==0 ? Math.round(formContent.requestPaymentTotal/formContent.supervisionContractAmount * 10000) / 100 : '未填寫監造合約金額無法計算'}</p>
          </div>
          <div className="form_title">
            廠商估驗進度(%)：
          </div>
          <div className="form_input">
            {
              <input type="number" name="companyEstimating" value={formContent.companyEstimating} readOnly />
            }
          </div>
          <div className="form_title">
            正常或異常原因：
          </div>
          <div className="form_input">
            <FullTextarea name="normalOrAbnormal" value={formContent.normalOrAbnormal} rows="8" readOnly ></FullTextarea>
          </div>
        </NoteDiv>

        <FakeHr />

        <FormTitle>肆、人員異動情形</FormTitle>
        <NoteDiv>
          <div className="form_title">
            人員異動情形 ：
          </div>
          <div className="form_input_table">
            <table>
              <tbody>
                <tr>
                  <td>職稱</td>
                  <td>姓名</td>
                  <td>機關報核</td>
                  <td>現場執行</td>
                  <td>協力商</td>
                  <td>增派</td>
                  <td>備註</td>
                </tr>
                {
                  formContent.supervisionMonthlyPersonnelList.map(ele => (
                    <tr key={ele._uuid}>
                      <td>
                        {
                          <input type="text" value={ele.jobTitle} name="jobTitle" readOnly />
                        }
                      </td>
                      <td>
                        {
                          <input type="text" value={ele.name} name="name" readOnly />
                        }
                      </td>
                      <td>
                        <label><input type="radio" name={`report${ele._uuid}`} value={1} checked={ele.report===1} readOnly />是</label>
                        <label><input type="radio" name={`report${ele._uuid}`} value={0} checked={ele.report===0} readOnly />否</label>
                      </td>
                      <td>
                        <label><input type="radio" name={`execution${ele._uuid}`} value={1} checked={ele.execution===1} readOnly />是</label>
                        <label><input type="radio" name={`execution${ele._uuid}`} value={0} checked={ele.execution===0} readOnly />否</label>
                      </td>
                      <td>
                        {
                          <input type="text" value={ele.cooperation} name="cooperation" readOnly />
                        }
                      </td>
                      <td>
                        <label><input type="radio" name={`isSupport${ele._uuid}`} value={1} checked={ele.isSupport===1} readOnly />是</label>
                        <label><input type="radio" name={`isSupport${ele._uuid}`} value={0} checked={ele.isSupport===0} readOnly />否</label>
                      </td>
                      <td>
                        {
                          <input type="text" value={ele.note} name="note" readOnly />
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          <div className="form_title">
            契約規定：
          </div>
          <div className="form_input">
            <FullTextarea name="contract" value={formContent.contract} rows="8" readOnly ></FullTextarea>
          </div>
        </NoteDiv>

        <FakeHr />

        <FormTitle>伍、業主要求額外服務及欲參獎類別</FormTitle>

        <NoteDiv>
          <div className="form_title">
            業主要求額外服務 :
          </div>
          <div className="form_input">
            <FullTextarea name="extraService" value={formContent.extraService} rows="8" readOnly ></FullTextarea>
          </div>
          <div className="form_title">
            欲參獎類別(含時程說明):
          </div>
          <div className="form_input">
            <FullTextarea name="awardCategory" value={formContent.awardCategory} rows="8" readOnly ></FullTextarea>
          </div>
        </NoteDiv>

        <FakeHr />

        <FormTitle>陸、職業安全衛生</FormTitle>

        <NoteDiv>
          <div className="form_title">
            本月份是否有勞動檢查單位勞動檢查:
          </div>
          <div className="form_input">
            <label><input type="radio" name="labourInspection" value={1} checked={formContent.labourInspection===1} readOnly />是</label>
            <label><input type="radio" name="labourInspection" value={0} checked={formContent.labourInspection===0} readOnly />否</label>
            {
              (formContent.labourInspection===1)
              && (
                <table>
                  <tbody>
                    <tr>
                      <td>勞檢日期</td>
                      <td>是否罰款</td>
                      <td>金額</td>
                    </tr>
                    {
                      formContent.supervisionMonthlyLaborInspectionList && formContent.supervisionMonthlyLaborInspectionList.filter(el => el.status!==3).map(ele => (
                        <tr key={ele._uuid}>
                          <td><DateInput type="date" name="date" value={ele.date} readOnly ></DateInput></td>
                          <td>
                            <label><input type="radio" name={`isFine${ele._uuid}`} value={1} checked={ele.isFine===1} readOnly />是</label>
                            <label><input type="radio" name={`isFine${ele._uuid}`} value={0} checked={ele.isFine===0} readOnly />否</label>
                          </td>
                          <td><input type="number" name="amount" value={ele.amount} readOnly /></td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              )
            }

          </div>
          <div className="form_title">
            本月是否有職災事件:
          </div>
          <div className="form_input">
            <label><input type="radio" name="occupationalAccident" value={1} checked={formContent.occupationalAccident===1} readOnly />是</label>
            <label><input type="radio" name="occupationalAccident" value={0} checked={formContent.occupationalAccident===0} readOnly />否</label>
          </div>
          <div className="form_title">
            職災事件說明:
          </div>
          <div className="form_input">
            <FullTextarea name="occupationalAccidentNote" value={formContent.occupationalAccidentNote} rows="8" readOnly ></FullTextarea>
          </div>
        </NoteDiv>
        <FakeHr />
        <FormTitle>柒、工程美照上傳</FormTitle>
        <div className="uploadfiles">
          {
            formContent.supervisionMonthlyPhotoList.filter(el => el.status !== 3).map(ele => (
              <PictureUpload key={ele._uuid} img={ele} />
            ))
          }
        </div>
        {
          <>
            <FormTitle>歷程紀錄</FormTitle> <br />

            <HistoricalRecord>
              {formContent.historyRecordList?.map(ele => (
                <p key={tools._UUID()}>
                  <span> {moment(ele.createDate).format('YYYY-MM-DD HH:mm:ss')} </span>由<span> {ele.createID} </span>編輯。
                </p>
              ))}
            </HistoricalRecord>
          </>
        }
        <FloatPointCoat href="#Top"><FloatPoint><span>回到<br />頂部</span></FloatPoint></FloatPointCoat>
      </div>
    </>
  );
};
export default MonthlyReportDetail;