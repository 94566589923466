import API from "./ApiConfig";

const SupervisionAPI = {

  // 取得 監造日報表(main)
  PostSupervisionFormList: (supervisionSearch) => {
    return new Promise((resolve, reject) => {
      API.post(`/Supervision/PostSupervisionFormList/`, supervisionSearch)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得 監造日報表 各狀態數量
  PostDailyNumList: (supervisionSearch) => {
    return new Promise((resolve, reject) => {
      API.post(`/Supervision/PostDailyNumList/`, supervisionSearch)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得 監造日報表 表單資料
  GetSupervisionDailyInfo: (projectId, supervisionId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Supervision/GetSupervisionDailyInfo/${projectId}/${supervisionId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 新增/修改監造日報表
  ModifySupervisionDaily: (modifySupervisionDaily) => {
    return new Promise((resolve, reject) => {
      API.post(`/Supervision/ModifySupervisionDaily/`, modifySupervisionDaily)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 輸入日期取得當天預定進度及其他資料
  GetProgressWithItemList: (projectId, fillinDate) => {
    return new Promise((resolve, reject) => {
      API.get(`/Supervision/GetProgressWithItemList/${projectId}/${fillinDate}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  //取得 監造月報表各狀態數量
  PostMonthlyNumList: (monthlySearch) =>{
    return new Promise((resolve, reject) =>{
      API.post(`/Supervision/PostMonthlyNumList/`, monthlySearch)
        .then(rs=>{
          resolve(rs.data);
        })
        .catch(error=>{
          reject(error);
        });
    });
  },
  //  取得監造月報表(main)
  PostMonthlyFormList: (monthlySearch) =>{
    return new Promise((resolve, reject) =>{
      API.post(`/Supervision/PostMonthlyFormList/`, monthlySearch)
        .then(rs=>{
          resolve(rs.data);
        })
        .catch(error=>{
          reject(error);
        });
    });
  },
  // /api/Supervision/PostProjectMonthlyFormList 取得監造月報表總覽(main)
  PostProjectMonthlyFormList: (projectMonthlySearch) =>{
    return new Promise((resolve, reject) =>{
      API.post(`/Supervision/PostProjectMonthlyFormList`, projectMonthlySearch)
        .then(rs=>{
          resolve(rs.data);
        })
        .catch(error=>{
          reject(error);
        });
    });
  },
  //監造月報表表
  GetSupervisionMonthlyInfo: (projectId, supervisionId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Supervision/GetSupervisionMonthlyInfo/${projectId}/${supervisionId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 輸入日期取得當月最後一天日報資料
  GetLastDailyData: (projectId, fillinDate) => {
    return new Promise((resolve, reject) => {
      API.get(`/Supervision/GetLastDailyData/${projectId}/${fillinDate}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 更新月報CRUD
  ModifySupervisionMonthly: (modifySupervisionMonthly) => {
    return new Promise((resolve, reject) => {
      API.post(`/Supervision/ModifySupervisionMonthly`, modifySupervisionMonthly)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default SupervisionAPI;