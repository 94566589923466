import { useContext, useEffect, useState } from 'react';
import tools from '../assets/tools';
import styled from '@emotion/styled';
import { SwitchMode } from './SwitchMode';
import { ThemeContext } from './../contexts/ThemeContext';
import { StatisticAPI, HelperAPI } from './../assets/api';
import { useLocalStorage } from './useLocalStorage';
import { useIsFirstRender } from './../assets/customHooks/useIsFirstRender';

const UpdatedDate = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
font-size: 14px;
.dateText {
  display: flex;
  justify-content: flex-end;
  color: ${props => props.isDarkMode ? '#e6e6e6' : '#0c0c0c'};
  h3 {
  color: ${props => props.isDarkMode ? '#e6e6e6' : '#0c0c0c'};
  }
}
`;

const BlockBox = styled.div`
margin: 0px 10px;
background-color: ${props => props.isDarkMode ? '#444' : '#ffffff'};
@media (max-width: 768px) {
  margin-left: 5px;
  margin-right: 5px;
  }
`;

const TableBlock = styled.div`
margin: 10px 10px 15px;
background-color: ${props => props.isDarkMode ? '#444' : '#ffffff'};
border: 1px solid ${props => props.isDarkMode ? 'none' : '#7b7979'};
.table {
  width: 100%;
  .tableTitle {
    width: 100%;
    color: #0c0c0c;
    background-color: #ccc;
    span {
      display: inline-block;
      width: 10%;
      padding: 10px;
      vertical-align: middle;
    }
  }
  .scrollbar {
    height: 300px;
    overflow-y: auto;
  .tableContent {
      width: 100%;
      padding: 10px 0;
      box-shadow: 0 0 2px 0 #CCC;
      border-bottom: 1px solid #e6e6e6;
      span {
        display: inline-block;
        width: 10%;
        margin: 10px;
        vertical-align: middle;
        color: ${props => props.isDarkMode ? '#e6e6e6' : '#0c0c0c'};
      .checkBtn {
        width: 20px;
        height: 20px;
        margin: 0 10px;
        border-radius: 0;
        background-color: ${props => props.isDarkMode ? '#555555' : '#ffffff'};
        border: 1px solid ${props => props.isDarkMode ? '#e6e6e6' : '#555555'};
      }
      .active {
        background-color: ${props => props.isDarkMode ? '#e6e6e6' : '#555555'};
      }
      }
      &:hover {
        cursor: pointer;
        background-color: ${props => props.isDarkMode ? '#555555' : '#ccc'};
      }
  }
  }
}
@media (max-width: 970px) {
  overflow-x: auto;
  .table {
    min-width: 960px;
  }
}
@media (max-width: 768px) {
  margin-left: 5px;
  margin-right: 5px;
}
`;

const ContractContent = styled.div`
  margin: 10px 0;
  border: 1px solid ${props => props.isDarkMode ? 'none' : '#7b7979'};
  .title {
    color: #0c0c0c;
    background-color: #E3E3E3;
    padding: 5px;
  }
  >div {
    color: ${props => props.isDarkMode ? '#e6e6e6' : '#0c0c0c'};
    padding: 5px;
    line-height: 1.6rem;
    white-space: pre-line;
  }
`;

const NoDataText = styled.div`
width: 100%;
font-size: 20px;
display: flex;
align-items: center;
justify-content: center;
color: ${props => props.isDarkMode ? '#e6e6e6' : '#55555'};
`;

const DashboardMain = () => {
  const isFirstRender = useIsFirstRender();

  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark' ? true : false;

  const [projectList, setProjectList] = useState([]); //工程項目清單
  const [projectId, setProjectId] = useLocalStorage("projectId", projectList.length ? projectList[0].id : 0);
  const [contract, setContract] = useState("");
  const [fillInMonth, setFillInMonth] = useState("");
  const [latestPersonnelList, setLatestPersonnelList] = useState([]);
  
  //取得工程項目清單
  const GetStaticProjectList = async () => {
    try {
      let data = await HelperAPI.GetStaticProjectList();
      setProjectList(data.projectList);
      return data.projectList;
    } catch (error) {
      console.log(error);
    }
  };

  //取得專案人員
  const GetLatestPersonnelRecord = async (projectId) => {
    try {
      let data = await StatisticAPI.GetLatestPersonnelRecord(projectId);
      setFillInMonth(data.fillInMonth);
      setLatestPersonnelList(data.latestPersonnelList);
      setContract(data.contract);

    } catch (error) {
      console.log(error);
    }
  };

  const handleProjectSelect = (e) => {
    let value = projectList.find(ele => ele.id === Number(e.target.value));
    setProjectId(value.id);
  };

  useEffect(async() => {
    let staticProjectList = await GetStaticProjectList();
    if (projectId === 0) {
      setProjectId(staticProjectList[0].id);
    } else {
      GetLatestPersonnelRecord(projectId);
    }
  },[]);

  useEffect(() => {
    if (!isFirstRender) {
      GetLatestPersonnelRecord(projectId);
    }
  },[projectId]);
  
  return (
    <>
      {/* 深色模式切換按鈕 */}
      <SwitchMode/>
      <div className="containerBox" style={{backgroundColor: isDarkMode ? '#666666' : '#F1F1F1' }}>
        <section className="dashboardContent">
          <div className="dashboardUpArea">
            <div className="dashboardUpArea_left">
              {/* <div className="dashboardTitle" style={{ backgroundColor: isDarkMode ? '' : '#ffffff' }}>
                <h1>連江縣自來水供水智慧化系統工程第二期計畫總顧問(含監造)委託專案服務案</h1>
              </div> */}
              <UpdatedDate isDarkMode={isDarkMode}>
                <div className="dateText">
                  <h3>最近更新：</h3>
                  <span>{fillInMonth}</span>
                </div>
                <div className="subTitle">
                  <h1 style={{ color: isDarkMode ? '#e6e6e6' : '#0c0c0c'}}>專案人員</h1>
                </div>
              </UpdatedDate>
            </div>
            <div className="projectWrap" style={{backgroundColor: isDarkMode ? '#555555' : '#ffffff'}}>
              <h3 style={{color: isDarkMode ? '#e6e6e6' : '#0c0c0c'}}>專案名稱</h3>
              <div className="dropdownBox">
                <select 
                  className="dropdownMenu" 
                  onChange={(e) => handleProjectSelect(e)} 
                  value={projectId}
                >
                  {
                    projectList.map(ele => (
                      <option key={ele.id} value={ele.id}>{ele.projectName}</option>
                    ))
                  }
                </select>
              </div>
            </div>
          </div>
          <TableBlock isDarkMode={isDarkMode}>
            <div className="table">
              <div className="tableTitle">
                <span>職稱</span>
                <span>姓名</span>
                <span>機關報核</span>
                <span>現場執行</span>
                <span>協力商</span>
                <span>增派</span>
                <span>備註</span>
              </div>
              <div className="scrollbar">
                {
                  latestPersonnelList.length > 0 ?
                    latestPersonnelList.map( ele => (
                      <div className="tableContent" key={tools._UUID()}>
                        <span>{ele.jobTitle}</span>
                        <span>{ele.name}</span>
                        <span><button className={ele.report ? "checkBtn active" : "checkBtn"}></button></span>
                        <span><button className={ele.execution ? "checkBtn active" : "checkBtn"}></button></span>
                        <span>{ele.cooperation === null ? "-" : ele.cooperation}</span>
                        <span><button className={ele.isSupport ? "checkBtn active" : "checkBtn"}></button></span>
                        <span>{ele.note}</span>
                      </div>
                    ))
                    : <NoDataText isDarkMode={isDarkMode}>當前無專案資料</NoDataText>
                }
              </div>
            </div>
          </TableBlock>
          <BlockBox isDarkMode={isDarkMode}>
            <ContractContent isDarkMode={isDarkMode}>
              <div className="title">契約規定</div>
              <div >
                {
                  contract ? contract : <NoDataText isDarkMode={isDarkMode}>當前無專案資料</NoDataText>
                }
              </div>             
            </ContractContent>
          </BlockBox>
        </section>
      </div> 
    </>
  );
};
export default DashboardMain;