// import React, { useEffect, useRef, useState, useContext } from "react";
import React, { useEffect, useState, useContext } from "react";
import styled from '@emotion/styled';
import "./../../style/Form.scss";
import "./../../style/FormDetail.scss";
import moment from 'moment';
import { notifyFailed, notifySuccess, notifyWarning } from '../assets/toaster';
import tools from '../assets/tools';
import { AccountAPI, FormAPI, DocumentAPI, SupervisionAPI, ProjectAPI } from "../assets/api";
import { ProjectContext } from '../contexts/ProjectContext';

const FormTitle = styled.h1`
  font-size: 1.4rem;
  font-weight: 700;
  margin: 8px;
`;

const DateInput = styled.input`
  padding: 0 4px;
  box-sizing: border-box;
  height: 32px;
  border-radius: 3px;
  border: 1px solid #999;
  // min-width: 5em;
  max-width: 18em;
  margin: 0 3px;
  @media (max-width: 450px) {
    width: 100%;
  }
`;

const FakeHr = styled.div`
  width: 92%;
  height: 2px;
  background-color: #DDD;
  margin: 40px 4% 40px 4%;
`;

const TableBox = styled.div`
  padding: 0 10px;
  input{
    border-radius: 3px;
    border: 1px solid #999;
  }
`;

const NoteDiv = styled.div`
  div{
    display: inline-block;
    padding: 3px;
  }
  .form_title{
    width: 13rem;
    text-align: right;
    vertical-align: top;
    padding: 5px 0px 5px 10px;
    @media (max-width: 1025px) {
      width: 100%;
      text-align: left;
    }
  }
  .form_input{
    width: calc(100% - 18rem);
    text-align: left;
    vertical-align: middle;
    overflow: auto;
    input[type="text"] {
      height: 28px;
      border-radius: 3px;
      border: 1px solid #999;
    }
    input[type="radio"] {
      margin: 0px 3px 8px 0px;
    }
    padding: 5px 0px 8px 12px;
    table{
      width: 99%;
      text-align: center;
      box-shadow: 0 1px 3px 0px #aaaa;
      tr{
        box-shadow: 0 1px 2px 0px #CCCC;
        &:first-of-type{
          background-color: #E3E3E3;
          box-shadow: 0 1px 2px 0px #BBBB;
          font-size: 1.1rem;
        }
        &:not(:first-of-type):hover{
          background-color: #CAC19025;
        }
      }
      td{
        padding: 1rem 10px;
        overflow-wrap: anywhere;
        vertical-align: middle;
      }
    }
    @media (max-width: 1025px) {
      width: calc(100% - 20px);
    }
  }
`;

const HistoricalRecord = styled.div`
  background-color: #EEE;
  padding: 10px 15px;
  margin: 10px;
`;

const InputReturnReason = styled.div`
  background-color: #EEE;
  padding: 20px ;
  margin: 10px;
  textarea{
    margin: 10px 0;
    width: 100%;
    border: 1px solid #BBB;
    padding: 3px 6px;
  }
  .btnbox{
    text-align: right;
    margin: 10px 0 0 0;
  }
`;

const SignetSelect = styled.select`
  border: 1px solid #999;
  border-radius: 3px;
  padding: 3px;
  margin: 0 3px;
  height: 30px;
  max-width: 87%;
  option{
    padding: 2px;
  }
`;

const FullTextarea = styled.textarea`
  width: calc(100% - 20px);
  margin: 0 10px;
  border: 1px solid #CCC;
`;

const WeatherSelect = styled.select`
  border: 1px solid #888;
  padding: 2px 4px;
  border-radius: 4px;
  width: 8em;
  margin: 0 8px 0 5px;
`;

//
const SuperviseDailyReport = (props) => {

  const { projectInfo } = useContext(ProjectContext);
  let projectId = projectInfo.id;

  const [isSaving, setIsSaving] = useState(false);
  const [isPDFExporting, setIsPDFExporting] = useState(false);

  const [helper, setHelper] = useState({
    weatherList:[],
  });

  const [formContent, setFormContent] = useState({
    ...props.formContent,
    _uuid: tools._UUID(),
    projectId,
    moduleId: 6,
    formID: props.formContent.id > 0 ? props.formContent.formID : '', //表單編號
    scheduledProgress: props.formContent.id > 0 ? (props.formContent.scheduledProgress || 0) : 0, //預定進度
    actualProgress: props.formContent.id > 0 ? (props.formContent.actualProgress || 0) : 0, //實際進度
    fillInDate: props.formContent.id > 0 ? (props.formContent.fillInDate ? moment(props.formContent.fillInDate).format('YYYY-MM-DD') : '') : '', //填報日期
    weatherAM: props.formContent.id > 0 ? props.formContent.weatherAM : '', //天氣上午
    weatherPM: props.formContent.id > 0 ? props.formContent.weatherPM : '', //天氣下午
    weatherAMName: props.formContent.id > 0 ? props.formContent.weatherAMName : '',
    weatherPMName: props.formContent.id > 0 ? props.formContent.weatherPMName : '',
    userStamp: {},
    status: props.formContent.id > 0 ? 2 : 1,
    formStatus: props.formContent.id > 0 ? props.formContent.formStatus : 0, // 表單狀態,0:草稿,1:審核中,2:已結案,3:已刪除,4:真刪除
    editable: props.formContent.id > 0 ? props.formContent.formStatus === 0 : true,
    returnNote: props.formContent.id > 0 ? props.formContent.returnNote : '',
    // supervisvionDailyCEList
    supervisvionDailyCEList: props.formContent.supervisvionDailyCEList.map(ele => {
      return props.formContent.id > 0 ?
        ({
          ...ele,
          id: ele.id,
          _uuid: tools._UUID(),
          mainID: props.formContent.id,
          subProjectName: ele.subProjectName,
          location: ele.location,
          isRandom: ele.isRandom,
          formID: ele.formID,
          status: 2,
          isExist: true
        }) :
        ({
          ...ele,
          id: 0,
          _uuid: tools._UUID(),
          mainID: 0,
          subProjectName: ele.subProjectName,
          location: ele.location,
          isRandom: ele.isRandom,
          formID: ele.formID,
          status: 1,
          isExist: false
        });
    }),
    // supervisvionDailyEFList
    supervisvionDailyEFList: props.formContent.supervisvionDailyEFList.map(ele => {
      return props.formContent.id > 0 ?
        ({
          ...ele,
          id: ele.id,
          _uuid: tools._UUID(),
          mainID: props.formContent.id,
          subProjectName: ele.subProjectName,
          location: ele.location,
          procedure: ele.procedure,
          formID: ele.formID,
          status: 2,
          isExist: true
        }) :
        ({
          ...ele,
          id: 0,
          _uuid: tools._UUID(),
          mainID: 0,
          subProjectName: ele.subProjectName,
          location: ele.location,
          procedure: ele.procedure,
          formID: ele.formID,
          status: 1,
          isExist: false
        });
    }),
    // supervisvionDailyEQList
    supervisvionDailyEQList: props.formContent.supervisvionDailyEQList.map(ele => {
      return props.formContent.id > 0 ?
        ({
          ...ele,
          id: ele.id,
          _uuid: tools._UUID(),
          mainID: props.formContent.id,
          eqipName: ele.eqipName,
          location: ele.location,
          formID: ele.formID,
          status: 2,
          isExist: true
        }) :
        ({
          ...ele,
          id: 0,
          _uuid: tools._UUID(),
          mainID: 0,
          eqipName: ele.eqipName,
          location: ele.location,
          formID: ele.formID,
          status: 1,
          isExist: false
        });
    }),
    // supervisvionDailyHSList
    supervisvionDailyHSList: props.formContent.supervisvionDailyHSList.map(ele => {
      return props.formContent.id > 0 ?
        ({
          ...ele,
          id: ele.id,
          _uuid: tools._UUID(),
          mainID: props.formContent.id,
          formID: ele.formID,
          location: ele.location,
          status: 2,
          isExist: true
        }) :
        ({
          ...ele,
          id: 0,
          _uuid: tools._UUID(),
          mainID: 0,
          formID: ele.formID,
          location: ele.location,
          status: 1,
          isExist: false
        });
    }),
  });

  // 施工抽查紀錄表
  const handleCEItemCreate = () => {
    setFormContent({ ...formContent, supervisvionDailyCEList: [...formContent.supervisvionDailyCEList, new CEItem({ _uuid: tools._UUID(), status: 1 })] });
  };

  function CEItem({ _uuid = 0, id = 0, subProjectName = '', location = '', status = 0, isExist = false, isRandom = false, formID = ''}) {
    this._uuid = _uuid;
    this.id = id;
    this.projectID = projectId;
    this.subProjectName = subProjectName;
    this.location = location;
    this.isRandom = isRandom;
    this.formID = formID;
    this.status = status;
    this.isExist = isExist;
    // this.isEditing = isEditing;
  }

  const handleCEItemInputChange = (e, el) => {
    setFormContent({
      ...formContent,
      supervisvionDailyCEList: formContent.supervisvionDailyCEList.map(ele => (
        ele._uuid === el._uuid ? { ...ele, [e.target.name]: e.target.value } : ele
      ))
    });
  };
  const handleCEisRandomInputChange = (e, el) => {
    setFormContent({
      ...formContent,
      supervisvionDailyCEList: formContent.supervisvionDailyCEList.map(ele => (
        ele._uuid === el._uuid ? { ...ele, isRandom: e.target.value } : ele
      ))
    });
  };

  const handleCEItemDelete = (deleteItem) => {
    notifyWarning({ Msg: <DeleteCEConfirm deleteItem={deleteItem} /> });
  };

  const DeleteCEConfirm = ({ closeToast, deleteItem }) => (
    <div>
      刪除施工抽查紀錄？
      <button className="btnS btnRed" onClick={() => DeleteCEItem(deleteItem)}>確定刪除</button>
      <button className="btnS btnWhite" onClick={closeToast}>取消</button>
    </div>
  );

  const DeleteCEItem = (deleteItem) => {
    if (deleteItem.isExist) {
      setFormContent({ ...formContent, supervisvionDailyCEList: formContent.supervisvionDailyCEList.map(ele => (ele._uuid === deleteItem._uuid ? { ...ele, status: 3 } : ele)) });
    } else {
      setFormContent({ ...formContent, supervisvionDailyCEList: formContent.supervisvionDailyCEList.filter(ele => ele._uuid !== deleteItem._uuid) });
    }
  };

  // 設備功能運轉測試紀錄表
  const handleEFItemCreate = () => {
    setFormContent({ ...formContent, supervisvionDailyEFList: [...formContent.supervisvionDailyEFList, new EFItem({ _uuid: tools._UUID(), status: 1 })] });
  };

  function EFItem({ _uuid = 0, id = 0, subProjectName = '', location = '', status = 0, isExist = false, procedure = 0, formID = ''}) {
    this._uuid = _uuid;
    this.id = id;
    this.projectID = projectId;
    this.subProjectName = subProjectName;
    this.location = location;
    this.procedure = procedure,
    this.formID = formID,
    this.status = status;
    this.isExist = isExist;
    // this.isEditing = isEditing;
  }

  const handleEFItemInputChange = (e, el) => {
    setFormContent({
      ...formContent,
      supervisvionDailyEFList: formContent.supervisvionDailyEFList.map(ele => (
        ele._uuid === el._uuid ? { ...ele, [e.target.name]: e.target.value } : ele
      ))
    });
  };
  const handleEFprocedureInputChange = (e, el) => {
    setFormContent({
      ...formContent,
      supervisvionDailyEFList: formContent.supervisvionDailyEFList.map(ele => (
        ele._uuid === el._uuid ? { ...ele, procedure: e.target.value } : ele
      ))
    });
  };

  const handleEFItemDelete = (deleteItem) => {
    notifyWarning({ Msg: <DeleteEFConfirm deleteItem={deleteItem} /> });
  };

  const DeleteEFConfirm = ({ closeToast, deleteItem }) => (
    <div>
      刪除設備功能運轉測試紀錄？
      <button className="btnS btnRed" onClick={() => DeleteEFItem(deleteItem)}>確定刪除</button>
      <button className="btnS btnWhite" onClick={closeToast}>取消</button>
    </div>
  );

  const DeleteEFItem = (deleteItem) => {
    if (deleteItem.isExist) {
      setFormContent({ ...formContent, supervisvionDailyEFList: formContent.supervisvionDailyEFList.map(ele => (ele._uuid === deleteItem._uuid ? { ...ele, status: 3 } : ele)) });
    } else {
      setFormContent({ ...formContent, supervisvionDailyEFList: formContent.supervisvionDailyEFList.filter(ele => ele._uuid !== deleteItem._uuid) });
    }
  };

  // 材料設備品質抽驗紀錄表
  const handleEQItemCreate = () => {
    setFormContent({ ...formContent, supervisvionDailyEQList: [...formContent.supervisvionDailyEQList, new EQItem({ _uuid: tools._UUID(), status: 1 })] });
  };

  function EQItem({ _uuid = 0, id = 0, eqipName = '', location = '', formID = '', status = 0, isExist = false }) {
    this._uuid = _uuid;
    this.id = id;
    this.projectID = projectId;
    this.eqipName = eqipName;
    this.location = location;
    this.formID = formID,
    this.status = status;
    this.isExist = isExist;
    // this.isEditing = isEditing;
  }

  const handleEQItemInputChange = (e, el) => {
    setFormContent({
      ...formContent,
      supervisvionDailyEQList: formContent.supervisvionDailyEQList.map(ele => (
        ele._uuid === el._uuid ? { ...ele, [e.target.name]: e.target.value } : ele
      ))
    });
  };

  const handleEQItemDelete = (deleteItem) => {
    notifyWarning({ Msg: <DeleteEQConfirm deleteItem={deleteItem} /> });
  };

  const DeleteEQConfirm = ({ closeToast, deleteItem }) => (
    <div>
      刪除材料設備品質抽驗紀錄？
      <button className="btnS btnRed" onClick={() => DeleteEQItem(deleteItem)}>確定刪除</button>
      <button className="btnS btnWhite" onClick={closeToast}>取消</button>
    </div>
  );

  const DeleteEQItem = (deleteItem) => {
    if (deleteItem.isExist) {
      setFormContent({ ...formContent, supervisvionDailyEQList: formContent.supervisvionDailyEQList.map(ele => (ele._uuid === deleteItem._uuid ? { ...ele, status: 3 } : ele)) });
    } else {
      setFormContent({ ...formContent, supervisvionDailyEQList: formContent.supervisvionDailyEQList.filter(ele => ele._uuid !== deleteItem._uuid) });
    }
  };

  // 其他工地安全衛生督導事項
  const handleHSItemCreate = () => {
    setFormContent({ ...formContent, supervisvionDailyHSList: [...formContent.supervisvionDailyHSList, new HSItem({ _uuid: tools._UUID(), status: 1 })] });
  };

  function HSItem({ _uuid = 0, id = 0, formID = '', location = '', status = 0, isExist = false }) {
    this._uuid = _uuid;
    this.id = id;
    this.projectID = projectId;
    this.formID = formID;
    this.location = location;
    this.status = status;
    this.isExist = isExist;
    // this.isEditing = isEditing;
  }

  const handleHSItemInputChange = (e, el) => {
    setFormContent({
      ...formContent,
      supervisvionDailyHSList: formContent.supervisvionDailyHSList.map(ele => (
        ele._uuid === el._uuid ? { ...ele, [e.target.name]: e.target.value } : ele
      ))
    });
  };

  const handleHSItemDelete = (deleteItem) => {
    notifyWarning({ Msg: <DeleteHSConfirm deleteItem={deleteItem} /> });
  };

  const DeleteHSConfirm = ({ closeToast, deleteItem }) => (
    <div>
      刪除其他工地安全衛生督導事項？
      <button className="btnS btnRed" onClick={() => DeleteHSItem(deleteItem)}>確定刪除</button>
      <button className="btnS btnWhite" onClick={closeToast}>取消</button>
    </div>
  );

  const DeleteHSItem = (deleteItem) => {
    if (deleteItem.isExist) {
      setFormContent({ ...formContent, supervisvionDailyHSList: formContent.supervisvionDailyHSList.map(ele => (ele._uuid === deleteItem._uuid ? { ...ele, status: 3 } : ele)) });
    } else {
      setFormContent({ ...formContent, supervisvionDailyHSList: formContent.supervisvionDailyHSList.filter(ele => ele._uuid !== deleteItem._uuid) });
    }
  };


  //大表單
  const handleInputChange = async (e) => {
    await setFormContent({
      ...formContent,
      [e.target.name]: e.target.value
    });
    // if (e.target.name === 'fillInDate') {
    // // console.log('fillInDate');
    // // let newData = await GetProgressWithItemList(projectId, formContent.fillInDate);
    // let newData = await GetProgressWithItemList(projectId, e.target.value);
    // setFormContent({ ...formContent, supervisvionDailyCEList: [...formContent.supervisvionDailyCEList, ...newData.supervisvionDailyCEList],
    //   supervisvionDailyEFList: [...formContent.supervisvionDailyEFList, ...newData.supervisvionDailyEFList],
    //   supervisvionDailyEQList: [...formContent.supervisvionDailyEQList, ...newData.supervisvionDailyEQList],
    //   supervisvionDailyHSList: [...formContent.supervisvionDailyHSList, ...newData.supervisvionDailyHSList],
    //   scheduledProgress: newData.scheduledProgress,
    //   fillInDate: e.target.value });
    // }
  };

  const handleFillInDateChange = async () => {
    if (formContent.fillInDate=='') {
      notifyFailed({ Msg: '請先填寫填報日期', isReload: false });
      return;
    }
    const handleReplaceAll = () => {
      notifyWarning({ Msg: <ReplaceConfirm theDate={formContent.fillInDate} /> });
    };

    const ReplaceConfirm = ({ theDate, closeToast }) => (
      <div>
        選取日期( {theDate} )表單項目將取代目前表單項目，確定繼續？
        <button className="btnS btnRed" onClick={() => ReplaceItemAll(theDate)}>確定取代</button>
        <button className="btnS btnWhite" onClick={closeToast}>取消</button>
      </div>
    );

    const ReplaceItemAll = async (theDate) => {
      let newData = await GetProgressWithItemList(projectId, theDate);
      setFormContent({
        ...formContent, supervisvionDailyCEList: newData.supervisvionDailyCEList,
        supervisvionDailyEFList: newData.supervisvionDailyEFList,
        supervisvionDailyEQList: newData.supervisvionDailyEQList,
        supervisvionDailyHSList: newData.supervisvionDailyHSList,
        scheduledProgress: newData.scheduledProgress,
        fillInDate: theDate
      });
    };

    handleReplaceAll();
  };

  const handleWeatherSelect = (e) => {
    setFormContent({
      ...formContent, [e.target.name]: e.target.value
    });
  };

  const handleRadioInputChange = (e) => {
    setFormContent({
      ...formContent,
      [e.target.name]: Number(e.target.value)
    });
  };

  const handleSave = async ({ isSubmit }) => {
    // 檢查網路狀態
    if (!window.navigator.onLine) {
      notifyFailed({ Msg: '網路未連線', isReload: false });
      return;
    }

    if (!formContent.formID || !formContent.fillInDate) {
      notifyFailed({ Msg: '請填寫「公共工程監造報表」表單編號及填報日期', isReload: false });
      return;
    }

    setIsSaving(true);
    let formData = new FormData();
    formData.append(`id`, formContent.id || 0);
    formData.append(`projectId`, formContent.projectId);
    formData.append(`formStatus`, formContent.formStatus);
    formData.append(`status`, formContent.status);
    //大表格
    formData.append(`formID`, formContent.formID);
    //沒放 Supervision 整治廠商 不知道會不會壞掉
    formData.append(`scheduledProgress`, formContent.scheduledProgress || 0);
    formData.append(`actualProgress`, formContent.actualProgress);
    formData.append(`fillInDate`, formContent.fillInDate);
    formData.append(`weatherAM`, formContent.weatherAM || helper.weatherList[0].id);
    // ↓ 如果之後要改成送出儲存id的話
    // formData.append(`weatherAM`, helper.weatherList.find(ele => ele.weather === formContent.weatherAM).id);
    formData.append(`weatherPM`, formContent.weatherPM || helper.weatherList[0].id);
    // 工程進行情況
    formData.append(`note`, formContent.note || '');
    // 整治廠商施工前檢查事項辦理情形
    formData.append(`preConstructionInspection`, Number(formContent.preConstructionInspection));
    formData.append(`PreConstructionInspectionNote`, formContent.preConstructionInspectionNote || '');
    // 安衛 其他約定監造事項
    formData.append(`hsOtherNote`, formContent.hsOtherNote || '');
    // 其他約定監造事項(其他約定督導事項)(重要事項紀錄、主辦機關指示及通知廠商辦理事項等)
    formData.append(`SupervisionMatters`, formContent.supervisionMatters || '');
    // 施工抽查紀錄表 supervisvionDailyCEList
    let SuperCEList = formContent.supervisvionDailyCEList.filter(ele => (ele.id > 0 || ele.status === 1) );
    for (let i = 0; i < SuperCEList.length; i++) {
      let ele = SuperCEList[i];
      formData.append(`supervisvionDailyCEList[${i}].id`, props.formContent.id > 0 ? ele.id : 0);
      formData.append(`supervisvionDailyCEList[${i}].subProjectName`, ele.subProjectName || '');
      formData.append(`supervisvionDailyCEList[${i}].location`, ele.location || '');
      formData.append(`supervisvionDailyCEList[${i}].isRandom`, Number(ele.isRandom) === 1 ? true : false);
      formData.append(`supervisvionDailyCEList[${i}].formID`, ele.formID || '');
      formData.append(`supervisvionDailyCEList[${i}].status`, ele.status);
    }
    // for (let i = 0; i < formContent.supervisvionDailyCEList.length; i++) {
    //   let ele = formContent.supervisvionDailyCEList[i];
    //   formData.append(`supervisvionDailyCEList[${i}].id`, props.formContent.id > 0 ? ele.id : 0);
    //   formData.append(`supervisvionDailyCEList[${i}].subProjectName`, ele.subProjectName || '');
    //   formData.append(`supervisvionDailyCEList[${i}].location`, ele.location || '');
    //   formData.append(`supervisvionDailyCEList[${i}].status`, ele.status);
    //   // formData.append(`supervisvionDailyCEList[${i}].mainId`, ele.mainId || 0);
    // }

    // 設備功能運轉測試紀錄表 supervisvionDailyEFList
    let SuperEFList = formContent.supervisvionDailyEFList.filter(ele => (ele.id > 0 || ele.status === 1));
    for (let i = 0; i < SuperEFList.length; i++) {
      let ele = SuperEFList[i];
      formData.append(`supervisvionDailyEFList[${i}].id`, props.formContent.id > 0 ? ele.id : 0);
      formData.append(`supervisvionDailyEFList[${i}].subProjectName`, ele.subProjectName || '');
      formData.append(`supervisvionDailyEFList[${i}].location`, ele.location || '');
      formData.append(`supervisvionDailyEFList[${i}].procedure`, ele.procedure || 0);
      formData.append(`supervisvionDailyEFList[${i}].formID`, ele.formID || '');
      formData.append(`supervisvionDailyEFList[${i}].status`, ele.status);
    }
    // 材料設備品質抽驗紀錄表 supervisvionDailyEQList
    let SuperEQList = formContent.supervisvionDailyEQList.filter(ele => (ele.id > 0 || ele.status === 1));
    for (let i = 0; i < SuperEQList.length; i++) {
      let ele = SuperEQList[i];
      formData.append(`supervisvionDailyEQList[${i}].id`, props.formContent.id > 0 ? ele.id : 0);
      formData.append(`supervisvionDailyEQList[${i}].eqipName`, ele.eqipName || '');
      formData.append(`supervisvionDailyEQList[${i}].location`, ele.location || '');
      formData.append(`supervisvionDailyEQList[${i}].formID`, ele.formID || '');
      formData.append(`supervisvionDailyEQList[${i}].status`, ele.status);
    }
    // 其他工地安全衛生督導事項 supervisvionDailyHSList
    let SuperHSList = formContent.supervisvionDailyHSList.filter(ele => (ele.id > 0 || ele.status === 1));
    for (let i = 0; i < SuperHSList.length; i++) {
      let ele = SuperHSList[i];
      formData.append(`supervisvionDailyHSList[${i}].id`, props.formContent.id > 0 ? ele.id : 0);
      formData.append(`supervisvionDailyHSList[${i}].formID`, ele.formID || '');
      formData.append(`supervisvionDailyHSList[${i}].location`, ele.location || '');
      formData.append(`supervisvionDailyHSList[${i}].status`, ele.status);
    }

    try {
      let res = await SupervisionAPI.ModifySupervisionDaily(formData);
      notifySuccess({ Msg: '儲存成功 (公共工程監造報表)', cbfunc: '' });
      setIsSaving(false);

      if (!isSubmit) {
        // 儲存完關閉頁籤，並再打開
        let newFormList = await setFormTabListPromise(props.formTabList.filter(ele => ele._uuid !== props.uuid));
        let newFormContent = await GetSupervisionDailyInfo(projectId, res.id);
        let editForm = {
          id: newFormContent.id,
          _uuid: tools._UUID(),
          formName: newFormContent.formID,
          moduleId: 6, // 監督日報表: 6
          formContent: newFormContent,
        };
        let data = await setFormTabListPromise([...newFormList, editForm]);
        openFormTab(data, editForm._uuid);
      }
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤，表單儲存失敗 (公共工程監造報表)', isReload: false });
      if (error.response.data === "此表單編號已存在。") { notifyFailed({ Msg: error.response.data, isReload: false }); }
      setIsSaving(false);
    }
  };

  const openFormTab = (list, uuid) => {
    let tabToOpen = list.find(ele => ele._uuid === uuid);
    if (tabToOpen) props.setFormTabSelected(tabToOpen);
  };

  const setFormTabListPromise = (formList) => {
    return new Promise(resolve => {
      props.setFormTabList(formList);
      resolve(formList);
    });
  };

  // 檢查欄位必填
  const FormValidate = () => {
    let isValid = true;

    // 欄位檢查
    if (
      !formContent.formID
      // !formContent.scheduledProgress ||
      // !formContent.actualProgress ||
      // !formContent.fillInDate ||
      // !formContent.weatherAM ||
      // !formContent.weatherPM
      // 本來要驗證 後來可以為0 改成傳回去時沒填則為0
    ) {
      isValid = false;
    }

    return isValid;
  };

  async function GetSupervisionDailyInfo(projectId, supervisionId) {
    try {
      let data = await SupervisionAPI.GetSupervisionDailyInfo(projectId, supervisionId);
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  async function GetProgressWithItemList(projectId, fillinDate) {
    try {
      let data = await SupervisionAPI.GetProgressWithItemList(projectId, fillinDate);
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  const handleStampChange = (e) => {
    setFormContent({
      ...formContent,
      userStamp: formContent.userStampList.find(ele => ele.userStampID === Number(e.target.value)) || {}
    });
  };

  const handleSignOff = async (action) => {
    // 檢查網路狀態
    if (!window.navigator.onLine) {
      notifyFailed({ Msg: '網路未連線', isReload: false });
      return;
    }

    // 檢查必填欄位
    let formValidate = FormValidate();
    if (!formValidate && action!=2) {
      notifyFailed({ Msg: '請檢查「公共工程監造報表」是否有未填項目', isReload: false });
      return;
    }

    // 要送出簽核需有影像章
    if (!formContent.userStamp.userStampID) {
      if (action === 1 || action === 0) {
        notifyFailed({ Msg: '請選擇影像章', isReload: false });
        return;
      }
    }

    // 只有在帳號為填寫表單本人，且表單狀態為草稿時時，可以送出審核同時儲存表單
    if (formContent.signOffList[0].userName === userInfo.userName && formContent.formStatus === 0) {
      await handleSave({ isSubmit: true });
    }

    setIsSaving(true);
    let modifyFormSignOff = {
      id: formContent.signOffList.find(ele => ele.signOffTag === true).id,
      userStampID: formContent.userStamp.userStampID,
      action,
      module: 6
    };
    try {
      await FormAPI.PostSignOff(modifyFormSignOff);
      notifySuccess({ Msg: '送出成功', cbfunc: '' });
      setIsSaving(false);
      await setFormTabListPromise(props.formTabList.filter(ele => ele._uuid !== props.uuid));
      // 開啟列表
      props.setIsListOn(true);
      PostSupervisionFormList();
      PostDailyNumList();
      props.GetModuleList({ isModuleId: true });
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤，簽核送出失敗', isReload: false });
      setIsSaving(false);
    }
  };

  // 取得監督日報表 清單(取得送出表單模組及表單類型，並顯示狀態為審核中)
  async function PostSupervisionFormList() {
    try {
      let searchVM = {
        projectId: projectId,
        formID: "",
        examineSDate: "",
        examineEDate: "",
        formStatus: 1,
        page: 1
      };
      let data = await SupervisionAPI.PostSupervisionFormList(searchVM);
      data.supervisionDailyList.forEach(ele => {
        ele._uuid = tools._UUID();
      });
      props.setSupervisionList(data.supervisionDailyList);
      props.setTotalPages(data.pageInfo.pages);
      props.setFormStatus(1);
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  // 取得監督日報表 各狀態筆數清單
  const PostDailyNumList = async () => {
    try {
      let searchVM = {
        projectId: projectId,
        formID: "",
        examineSDate: "",
        examineEDate: "",
        formStatus: 1,
        page: 1
      };
      let data = await SupervisionAPI.PostDailyNumList(searchVM);
      props.setFormStatusNum(data);
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  };

  // user info to check sigon off process
  const [userInfo, setUserInfo] = useState({
    'userName': '',
    'name_ZHTW': '',
    'name_EN': '',
    'email': '',
    'phoneNumber': '',
    'projectRoles': []
  });

  async function GetUserInfo() {
    try {
      let data = await AccountAPI.GetUserInfo();
      setUserInfo(data);
    } catch (error) {
      notifyFailed({ Msg: '無法取得使用者資訊', isReload: false });
    }
  }

  async function GetWeatherForm() {
    try {
      let data = await ProjectAPI.GetWeatherForm();
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  useEffect(async () => {
    GetUserInfo();

    let weatherList = await GetWeatherForm();
    setHelper({ ...helper, weatherList });
  }, []);

  // 還原表單
  const handleRestoreForm = async () => {
    try {
      await FormAPI.RestoreForm({
        id: formContent.id,
        module: 6
      });
      notifySuccess({ Msg: '表單還原成功', cbfunc: '' });
      // 還原後關閉頁籤
      props.setFormTabList(props.formTabList.filter(ele => ele._uuid !== props.uuid));
      props.setFormStatus(0);
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤，表單還原失敗', isReload: false });
    }
  };

  const handleReturnSignOff = async () => {
    notifyWarning({ Msg: <ReturnConfirm /> });
  };

  const ReturnConfirm = ({ closeToast }) => (
    <div>
      確定退回表單？若有【異常(不合格)通知暨改善表】將會同時退回，並刪除【異常追蹤】內容
      <button className="btnS btnRed" onClick={() => ReturnSignOff()}>確定退回</button>
      <button className="btnS btnWhite" onClick={closeToast}>取消</button>
    </div>
  );

  // 退回表單
  const ReturnSignOff = async () => {

    if (!formContent.returnNote) {
      notifyFailed({ Msg: '請確認是否有填寫退回理由。', isReload: false });
      return;
    }
    setIsSaving(true);
    try {
      await FormAPI.ReturnSignOff({
        projectId: projectId,
        moduleId: 6,
        // formId: formContent.projectFormId,
        mainId: formContent.id,
        note: formContent.returnNote || '',
        action: 3,
      });

      notifySuccess({ Msg: '表單退回成功', cbfunc: '' });
      setIsSaving(false);

      // 退回後關閉頁籤
      props.setFormTabList(props.formTabList.filter(ele => ele._uuid !== props.uuid));
      props.setFormStatus(0);
    } catch (error) {
      setIsSaving(false);
      notifyFailed({ Msg: '發生錯誤，表單退回失敗，請確認是否有填寫退回理由。', isReload: false });
    }
  };

  // 匯出PDF
  const ExportPDF = async () => {
    setIsPDFExporting(true);
    await CreateSDPdf();
    setIsPDFExporting(false);
  };
  const CreateSDPdf = async () => {
    try {
      let data = await DocumentAPI.CreateSDPdf(formContent.id, true);
      tools.AjaxFileDownload({ rs: data, filename: `公共工程監造報表 - ${formContent.formID}`, fileType: 'pdf' });
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤，匯出文件失敗', isReload: false });
    }
  };

  return (
    <>
      <div className="sharebox">
        {
          formContent.formStatus === 2 && userInfo.projectRoles.find(pr => pr.projectID === projectId)?.roleName === '管理者' && (
            <div className="btn_box"><a className="btnL btnBlue" href="#ReturnReason">退回重新審核</a></div>
          )
        }
        {
          formContent.formStatus === 3 && (<div className="btn_box"><button className="btnL btnBlue" onClick={handleRestoreForm}>還原此表單</button></div>)
        }

        <FormTitle> 公共工程監造報表 </FormTitle>

        <div className="form form_loose" style={{ paddingBottom: '10px', }}>

          {/* 一行 */}
          <div className="form_half">
            <div className="form_title">
              表單編號：
            </div>
            <div className="form_input">
              {
                formContent.editable ?
                  <input type="text" name="formID" value={formContent.formID || ''} onChange={handleInputChange} /> :
                  <p>{formContent.formID}</p>
              }
            </div>
          </div>
          <div className="form_half">
            <div className="form_title">
              工程名稱：
            </div>
            <div className="form_input">
              <p>{formContent.projectName}</p>
            </div>
          </div>

          {/* 一行 */}
          <div className="form_half">
            <div className="form_title">
              預定進度(%)：
            </div>
            <div className="form_input">
              {
                formContent.editable ?
                  <input type="text" name="scheduledProgress" value={formContent.scheduledProgress} onChange={handleInputChange} /> :
                  <p>{formContent.scheduledProgress}</p>
              }
            </div>
          </div>
          <div className="form_half">
            <div className="form_title">
              實際進度(%)：
            </div>
            <div className="form_input">
              {
                formContent.editable ?
                  <input type="text" name="actualProgress" value={formContent.actualProgress} onChange={handleInputChange} /> :
                  <p>{formContent.actualProgress}</p>
              }
            </div>
          </div>

          {/* 一行 */}
          <div className="form_13quarters">
            <div className="form_title">
              填報日期：
            </div>
            {
              formContent.editable ?
                <div className="form_input">
                  <DateInput type="date" name="fillInDate" value={formContent.fillInDate || ''} onChange={handleInputChange} />
                  <button className="btnS btnWhite" onClick={handleFillInDateChange} style={{ marginLeft: '1rem' }}>帶入當日資料</button>
                </div> :
                <div className="form_input">
                  <span>{moment(formContent.fillInDate).format('YYYY-MM-DD')}</span>
                </div>
            }
          </div>
          {/* <div className="form_half">
            <div className="form_input">
              <button className="btnS btnWhite" onClick={handleFillInDateChange}>帶入當日資料</button>
            </div>
          </div> */}

          {/* 一行 */}
          <div className="form_13quarters">
            <div className="form_title">
              本日天氣：
            </div>
            <div className="form_input">
              {
                formContent.editable ?
                  <p>
                    {/* 上午 <input type="text" name="weatherAM" value={formContent.weatherAM || ''} onChange={handleInputChange} style={{ margin: '3px 3px' }} /><br />
                    下午 <input type="text" name="weatherPM" value={formContent.weatherPM || ''} onChange={handleInputChange} /> */}
                    上午
                    <WeatherSelect name="weatherAM" onChange={(event) => handleWeatherSelect(event)} value={formContent.weatherAM}>
                      {
                        helper.weatherList.map(oele => {
                          return (
                            <option key={oele.id} value={oele.id}>{oele.weather}</option>
                          );
                        })
                      }
                    </WeatherSelect>
                    {/* <br /> */}
                    下午
                    <WeatherSelect name="weatherPM" onChange={(event) => handleWeatherSelect(event)} value={formContent.weatherPM}>
                      {
                        helper.weatherList.map(oele => {
                          return (
                            <option key={oele.id} value={oele.id}>{oele.weather}</option>
                          );
                        })
                      }
                    </WeatherSelect>
                  </p>
                  :
                  <p>
                    上午 { formContent.weatherAMName }<br />
                    下午 { formContent.weatherPMName }
                  </p>
              }
            </div>
          </div>

        </div>


        <FormTitle>一、工程進行情況（含約定之重要施工項目及數量）</FormTitle>
        <FullTextarea name="note" value={formContent.note || ''} onChange={handleInputChange} rows="8" disabled={!formContent.editable}></FullTextarea>

        <FormTitle>二、監督依照設計圖說及核定施工圖說施工（含約定之檢驗停留點及施工抽查等情形）</FormTitle>
        <NoteDiv>
          <div className="form_title">
            施工抽查紀錄表：
          </div>
          <div className="form_input">
            <table>
              <tbody>
                <tr>
                  <td>分項工程名稱</td>
                  <td>檢查位置</td>
                  <td>檢查時機</td>
                  <td>表單編號</td>
                  {formContent.editable && <td><button className="btnM btnWhite" onClick={handleCEItemCreate}>新增</button></td>}
                </tr>
                {
                  formContent.supervisvionDailyCEList && formContent.supervisvionDailyCEList.filter(el => el.status !== 3).map((ele) => {
                    return (
                      // ele.isExist ?
                      //   <tr key={ele._uuid}>
                      //     <td><input type="text" name="subProjectName" value={ele.subProjectName} onChange={(evt) => handleCEPjNameChange(evt, ele)} /></td>
                      //     <td><input type="text" name="location" value={ele.location} onChange={(evt) => handleCElocationChange(evt, ele)} /></td>
                      //     <td><button className="btnS btnRed" onClick={() => handleCEItemDelete(ele)}><i className="far fa-trash-alt"></i></button></td>
                      //   </tr> : ''
                      // <CreateCEItem
                      //   item={ele}
                      //   key={ele._uuid}
                      //   handleCEPjNameChange={(event) => handleCEPjNameChange(event, ele)}
                      //   handleCElocationChange={(event) => handleCElocationChange(event, ele)}
                      //   handleItemDelete={() => handleItemDelete(ele)}
                      // />
                      <tr key={ele._uuid}>
                        <td>
                          {
                            ele.isExist ? (ele.subProjectName) :
                              <input type="text" name="subProjectName" value={ele.subProjectName} onChange={(evt) => handleCEItemInputChange(evt, ele)} />
                          }
                        </td>
                        <td>
                          {
                            ele.isExist ? (ele.location) :
                              <input type="text" name="location" value={ele.location} onChange={(evt) => handleCEItemInputChange(evt, ele)} />
                          }
                        </td>
                        <td>
                          {
                            <div style={{ textAlign: 'left', }}>
                              <label><input type="radio" name={"isRandom" + ele._uuid} value={0} checked={Number(ele.isRandom) === 0} onChange={(evt) => handleCEisRandomInputChange(evt, ele)} disabled={!formContent.editable}/> 檢驗停留點</label> <br />
                              <label><input type="radio" name={"isRandom" + ele._uuid} value={1} checked={Number(ele.isRandom) === 1} onChange={(evt) => handleCEisRandomInputChange(evt, ele)} disabled={!formContent.editable}/> 不定期</label> <br/>
                            </div>
                          }
                        </td>
                        <td>
                          {
                            ele.isExist ? (ele.formID) :
                              <input type="text" name="formID" value={ele.formID} onChange={(evt) => handleCEItemInputChange(evt, ele)} />
                          }
                        </td>

                        {formContent.editable && <td>{(ele.id !== 0 || ele.isExist === false) && <button className="btnS btnRed" onClick={() => handleCEItemDelete(ele)}><i className="far fa-trash-alt"></i></button>}</td>}
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </NoteDiv>
        <NoteDiv>
          <div className="form_title">
            設備功能運轉測試紀錄表：
          </div>
          <div className="form_input">
            <table>
              <tbody>
                <tr>
                  <td>分項工程名稱</td>
                  <td>抽驗位置</td>
                  <td>測試流程</td>
                  <td>表單編號</td>
                  {formContent.editable && <td><button className="btnM btnWhite" onClick={handleEFItemCreate}>新增</button></td>}
                </tr>
                {
                  formContent.supervisvionDailyEFList && formContent.supervisvionDailyEFList.filter(el => el.status !== 3).map((ele) => {
                    return (
                      <tr key={ele._uuid}>
                        <td>
                          {
                            ele.isExist ? <span>{ele.subProjectName}</span> :
                              <input type="text" name="subProjectName" value={ele.subProjectName} onChange={(evt) => handleEFItemInputChange(evt, ele)} />
                          }
                        </td>
                        <td>
                          {
                            ele.isExist ? <span>{ele.location}</span> :
                              <input type="text" name="location" value={ele.location} onChange={(evt) => handleEFItemInputChange(evt, ele)} />
                          }
                        </td>
                        <td>
                          {
                            // ele.isExist ? <td>{ele.procedure}</td> :
                            <div style={{ textAlign: 'left', }}>
                              <label><input type="radio" name={"procedure" + ele._uuid} value={0} checked={Number(ele.procedure) === 0} onChange={(evt) => handleEFprocedureInputChange(evt, ele)} disabled={!formContent.editable}/> 單機測試</label> <br />
                              <label><input type="radio" name={"procedure" + ele._uuid} value={1} checked={Number(ele.procedure) === 1} onChange={(evt) => handleEFprocedureInputChange(evt, ele)} disabled={!formContent.editable}/> 系統測試</label> <br/>
                              <label><input type="radio" name={"procedure" + ele._uuid} value={2} checked={Number(ele.procedure) === 2} onChange={(evt) => handleEFprocedureInputChange(evt, ele)} disabled={!formContent.editable}/> 整體功能運轉測試</label>
                            </div>
                          }
                        </td>
                        <td>
                          {
                            ele.isExist ? <span>{ele.formID}</span> :
                              <input type="text" name="formID" value={ele.formID} onChange={(evt) => handleEFItemInputChange(evt, ele)} />
                          }
                        </td>
                        {formContent.editable && <td>{(ele.id !== 0 || ele.isExist === false) && <button className="btnS btnRed" onClick={() => handleEFItemDelete(ele)}><i className="far fa-trash-alt"></i></button>}</td>}
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </NoteDiv>

        <FormTitle>三、查核材料規格及品質（含約定之檢驗停留點、材料設備管制及檢（試）驗等抽驗情形）</FormTitle>
        <NoteDiv>
          <div className="form_title">
            材料設備品質抽驗紀錄表：
          </div>
          <div className="form_input">
            <table>
              <tbody>
                <tr>
                  <td>材料設備名稱</td>
                  <td>地點</td>
                  <td>表單編號</td>
                  {formContent.editable && <td><button className="btnM btnWhite" onClick={handleEQItemCreate}>新增</button></td>}
                </tr>
                {
                  formContent.supervisvionDailyEQList && formContent.supervisvionDailyEQList.filter(el => el.status !== 3).map((ele) => {
                    return (
                      <tr key={ele._uuid}>
                        <td>
                          {
                            ele.isExist ? <span>{ele.eqipName}</span> :
                              <input type="text" name="eqipName" value={ele.eqipName} onChange={(evt) => handleEQItemInputChange(evt, ele)} />
                          }
                        </td>
                        <td>
                          {
                            ele.isExist ? <span>{ele.location}</span> :
                              <input type="text" name="location" value={ele.location} onChange={(evt) => handleEQItemInputChange(evt, ele)} />
                          }
                        </td>
                        <td>
                          {
                            ele.isExist ? <span>{ele.formID}</span> :
                              <input type="text" name="formID" value={ele.formID} onChange={(evt) => handleEQItemInputChange(evt, ele)} />
                          }
                        </td>
                        {formContent.editable && <td>{(ele.id !== 0 || ele.isExist === false) && <button className="btnS btnRed" onClick={() => handleEQItemDelete(ele)}><i className="far fa-trash-alt"></i></button>}</td>}
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </NoteDiv>


        <FormTitle>四、督導工地職業安全衛生事項</FormTitle>
        <NoteDiv>
          <div className="form_title">
            施工廠商施工前檢查事項辦理情形：
          </div>
          <div className="form_input">
            <label><input type="radio" name="preConstructionInspection" value={1} checked={Number(formContent.preConstructionInspection) === 1} onChange={handleRadioInputChange} disabled={!formContent.editable} />完成</label> <br />
            <label><input type="radio" name="preConstructionInspection" value={2} checked={Number(formContent.preConstructionInspection) === 2} onChange={handleRadioInputChange} disabled={!formContent.editable} />未完成</label> <br />
            <label><input type="radio" name="preConstructionInspection" value={0} checked={Number(formContent.preConstructionInspection) === 0} onChange={handleRadioInputChange} disabled={!formContent.editable} />本日無施作</label>
          </div>
          <FullTextarea name="preConstructionInspectionNote" value={formContent.preConstructionInspectionNote || ''} onChange={handleInputChange} rows="8" disabled={!formContent.editable}></FullTextarea>
        </NoteDiv>

        <NoteDiv>
          <div className="form_title">
            其他工地安全衛生督導事項：
          </div>
          <div className="form_input">
            <table>
              <tbody>
                <tr>
                  <td>安衛表名</td>
                  <td>檢查地點</td>
                  {formContent.editable && <td><button className="btnM btnWhite" onClick={handleHSItemCreate}>新增</button></td>}
                </tr>
                {
                  formContent.supervisvionDailyHSList && formContent.supervisvionDailyHSList.filter(el => el.status !== 3).map((ele) => {
                    return (
                      <tr key={ele._uuid}>
                        <td>
                          {
                            ele.isExist ? <span>{ele.formID}</span> :
                              <input type="text" name="formID" value={ele.formID} onChange={(evt) => handleHSItemInputChange(evt, ele)} />
                          }
                        </td>
                        <td>
                          {
                            ele.isExist ? <span>{ele.location}</span> :
                              <input type="text" name="location" value={ele.location} onChange={(evt) => handleHSItemInputChange(evt, ele)} />
                          }
                        </td>
                        {formContent.editable && <td>{(ele.id !== 0 || ele.isExist === false) && <button className="btnS btnRed" onClick={() => handleHSItemDelete(ele)}><i className="far fa-trash-alt"></i></button>}</td>}
                      </tr>
                    );
                  })

                }
              </tbody>
            </table>
          </div>
        </NoteDiv>

        <NoteDiv>
          <div className="form_title">
            其他約定監造事項：
          </div>
          {/* <div className="form_input">
          </div> */}
          <FullTextarea name="hsOtherNote" value={formContent.hsOtherNote || ''} onChange={handleInputChange} rows="8" disabled={!formContent.editable}></FullTextarea>
        </NoteDiv>

        <FormTitle>五、其他約定監造事項（含重要事項紀錄、主辦機關指示及通知廠商辦理事項等）</FormTitle>
        <FullTextarea name="supervisionMatters" value={formContent.supervisionMatters || ''} onChange={handleInputChange} rows="8" disabled={!formContent.editable}></FullTextarea>

        <FakeHr />


        {
          ((formContent.signOffList && formContent.signOffList.length > 0 && formContent.signOffList[0].userName === userInfo.userName && formContent.editable)
            || formContent.status === 1)
          && (
            <div className="btn_box">
              <button className="btnL btnBlue" onClick={() => handleSave({ isSubmit: false })} disabled={isSaving}>
                {
                  isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '儲存表單'
                }
              </button>
            </div>
          )
        }

        <FakeHr />

        {
          props.formContent.id > 0 && formContent.formStatus !== 3 ?
            (
              <>
                <FakeHr />
                <FormTitle>表單簽核</FormTitle>

                <TableBox>
                  <div className="signArea">
                    {
                      formContent.signOffList.map(ele => (
                        <div className="approver" key={tools._UUID()}>
                          <div className="approverTitle">
                            <div className="approverTitleInner">
                              <p>{ele.jobTitle} - {ele.name_zhtw}</p>
                              {
                                userInfo.userName === ele.userName && formContent.userStampList && ele.signOffTag ?
                                  (
                                    <SignetSelect onChange={handleStampChange} value={formContent.userStamp.userStampID}>
                                      <option value=''>選擇影像章</option>
                                      {
                                        formContent.userStampList.map(ele1 => (
                                          <option value={ele1.userStampID} key={ele1.userStampID}>{ele1.stampName}</option>
                                        ))
                                      }
                                    </SignetSelect>
                                  ) :
                                  null
                              }
                            </div>
                          </div>
                          <div className="approverSign">
                            {
                              ele.sign ? <img src={ele.sign} key={ele.id} /> : (
                                userInfo.userName === ele.userName && formContent.userStampList && ele.signOffTag ? (formContent.userStamp.userStampID ? <img src={formContent.userStamp.stampUrl} key={formContent.userStamp.userStampID} /> : <div>請選擇影像章</div>) :
                                  <div>尚未簽核</div>
                              )
                            }
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </TableBox>
                <div className="btn_box">
                  {/* { // 當有影像章列表(表示目前流程到此帳號)才可以編輯及送出簽核 } */}
                  {
                    (formContent.signOffList.findIndex(ele => ele.signOffTag) === 0 && formContent.userStampList) && (
                      <button className="btnL btnOrange" onClick={() => handleSignOff(1)} disabled={isSaving}>
                        {
                          isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '儲存並送出審核'
                        }
                      </button>
                    )
                  }
                  {
                    (formContent.signOffList.findIndex(ele => ele.signOffTag) !== 0 &&
                      formContent.signOffList.findIndex(ele => ele.signOffTag) !== formContent.signOffList.length - 1 &&
                      formContent.userStampList) && (
                      <>
                        <button className="btnL btnBlue" onClick={() => handleSignOff(1)} disabled={isSaving}>
                          {
                            isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '核准'
                          }
                        </button>
                        <button className="btnL btnRed" onClick={() => handleSignOff(2)} disabled={isSaving}>
                          {
                            isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '退回'
                          }
                        </button>
                      </>
                    )
                  }
                  {
                    (formContent.signOffList.findIndex(ele => ele.signOffTag) === formContent.signOffList.length - 1 && formContent.userStampList) && (
                      <>
                        <button className="btnL btnOrange" onClick={() => handleSignOff(0)} disabled={isSaving}>
                          {
                            isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '決行'
                          }
                        </button>
                        <button className="btnL btnRed" onClick={() => handleSignOff(2)} disabled={isSaving}>
                          {
                            isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '退回'
                          }
                        </button>
                      </>
                    )
                  }
                </div>
              </>
            ) : null
        }

        {
          formContent.formStatus === 2 && (<div className="btn_box">
            <FakeHr />
            {
              <button className="btnL btnBlue" onClick={ExportPDF}>
                {
                  isPDFExporting ?
                    <div>
                      匯出中，請稍後...
                      <i className="fas fa-circle-notch fa-spin"></i>
                    </div> : '匯出PDF'
                }
              </button>
            }
          </div>)
        }
        {
          <>
            <FormTitle>歷程紀錄</FormTitle> <br />

            <HistoricalRecord>
              {formContent.historyRecordList?.map(ele => (
                <p key={tools._UUID()}>
                  <span> {moment(ele.createDate).format('YYYY-MM-DD HH:mm:ss')} </span>由<span> {ele.createID} </span>退件，理由：<span> {ele.note} </span>
                </p>
              ))}
            </HistoricalRecord>
          </>
        }

        {
          formContent.formStatus === 2 && userInfo.projectRoles.find(pr => pr.projectID === projectId)?.roleName === '管理者' && (
            <>
              <FakeHr />

              <InputReturnReason>
                <a name="ReturnReason" id="ReturnReason"></a>
                <p>請輸入退回理由</p>
                <textarea name="returnNote" id="" cols="" rows="6" onChange={handleInputChange}></textarea>

                <div className="btnbox">
                  <div className="btn_box"><button className="btnL btnBlue" onClick={handleReturnSignOff}>確認退回</button></div>
                </div>
              </InputReturnReason>
            </>
          )
        }



      </div>
    </>
  );

};
export default SuperviseDailyReport;