import moment from 'moment';

let tools = {
  // Get format now date 
  GetFormatNowDate(format) {
    let nowDate = new Date();
    return moment(nowDate).format(format);
  },

  // Get uuid
  _UUID() {
    var d = Date.now();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
      d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  },

  // dataURL to Blob
  dataURLToBlob(dataURL) {
    // Code taken from https://github.com/ebidel/filer.js
    var parts = dataURL.split(';base64,');
    var contentType = parts[0].split(":")[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;
    var uInt8Array = new Uint8Array(rawLength);
  
    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
  
    return new Blob([uInt8Array], { type: contentType });
  },

  // Blob to File
  BlobToFile(blob, fileName) {
    return new File([blob], fileName, {lastModified: new Date(), type: blob.type});
  },

  // Password Validation
  CheckPassword(str)
  {
    // at least one number, one lowercase and one uppercase letter
    // at least six characters
    var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
    return re.test(str);
  },

  /* 全形轉半形 */
  FullWidthToHalfWidth(string) {
    let result = "";
    for (let i = 0; i < string.length; i++) {
      if (string.charCodeAt(i) === 12288) {
        result += " ";
      } else {
        if (string.charCodeAt(i) > 65280 && string.charCodeAt(i) < 65375) {
          result += String.fromCharCode(string.charCodeAt(i) - 65248);
        } else {
          result += String.fromCharCode(string.charCodeAt(i));
        }
      }
    }
    return result;
  },
  
  getSitePath(Url) {
    return Url.split("#")[0];
  },
  
  /* AJAx檔案下載轉換 */
  AjaxFileDownload({rs, filename, fileType}) {
    let blob;
    if (fileType === "pdf") {
      blob = new Blob([rs.data], { type: "application/pdf" });
    } else if (fileType === "excel") {
      blob = new Blob([rs.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    } else {
      blob = new Blob([rs.data]);
    }
    // filename = decodeURI(
    //   rs.headers["content-disposition"].substr(21).replace(/"/g, "")
    // );
    if (navigator.appVersion.toString().indexOf(".NET") > 0) {
      // IE瀏覽器
      window.navigator.msSaveBlob(blob, filename);
    } else {
      var a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  },
  /**
     * 解析 localStorage 的 SourceInfo
     */
  parseSourceInfo() {
    let SourceInfoVM = function() {
      this.Type = null;
      this.Url = null;
      this.ShortFilingSN = null;
    };
    let sourceInfo = localStorage.getItem("Source")
      ? decodeURI(localStorage.getItem("Source")).split(",")
      : "";
    let result = new SourceInfoVM();
    if (sourceInfo !== "") {
      result.Type = sourceInfo[0];
      result.Url = sourceInfo[1].substring(1);
      result.ShortFilingSN = sourceInfo[2];
    }
    return result;
  },
  
  /**
     * 產生 PDF Canvas，需要 PDF.js 依賴。
     * 請於 html 準備 < div id = "pdf-container" > < /div>
     *
     * @param data AJAX 取回來的Blob，不用預處理，rs.data 直接塞進來即可
     */
  GeneratePDFCanvas(data) {
    let generate = function(pdfDataUri) {
      pdfDataUri = pdfDataUri.replace("data:application/pdf;base64,", ""); // 去掉表頭
      window.PDFJS.getDocument({
        data: atob(pdfDataUri)
      }).then(function(pdf) {
        for (var pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
          pdf.getPage(pageNum).then(function(page) {
            var viewport = page.getViewport(
              (window.innerWidth / page.getViewport(1.0).width) * 0.79
            );
            var canvas = document.createElement("canvas");
            var context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;
  
            var renderContext = {
              canvasContext: context,
              viewport: viewport
            };
            page.render(renderContext);
            document.getElementById("pdf-container").appendChild(canvas);
          });
        }
      });
    };
    let pdfData = new FileReader();
    pdfData.onload = function(event) {
      let contents = event.target.result;
      generate(contents);
    };
    pdfData.readAsDataURL(
      new Blob([data], {
        type: "application/pdf"
      })
    );
  },
  
  CheckID(id) {
    // 身分證字號或外籍人士居留証驗證
    /*
       * 第一個字元代表地區，轉換方式為：A轉換成1,0兩個字元，B轉換成1,1……但是Z、I、O分別轉換為33、34、35
       * 第二個字元代表性別，1代表男性，2代表女性
       * 第三個字元到第九個字元為流水號碼。
       * 第十個字元為檢查號碼。
       * 每個相對應的數字相乘，如A123456789代表1、0、1、2、3、4、5、6、7、8，相對應乘上1987654321，再相加。
       * 相加後的值除以模數，也就是10，取餘數再以模數10減去餘數，若等於檢查碼，則驗證通過
       */
    let studIdNumber = id.toUpperCase();
  
    if (studIdNumber.length !== 10) return false;
  
    // 本國人
    // 格式，用正則表示式比對第一個字母是否為英文字母、後面九個字是否為數字
    if (
      !isNaN(studIdNumber.substr(1, 9)) &&
        /^[A-Z]$/.test(studIdNumber.substr(0, 1))
    ) {
      let idHeader = "ABCDEFGHJKLMNPQRSTUVXYWZIO"; // 按照轉換後權數的大小進行排序
      // 這邊把身分證字號轉換成準備要對應的
      studIdNumber =
          idHeader.indexOf(studIdNumber.substring(0, 1)) +
          10 +
          "" +
          studIdNumber.substr(1, 9);
      // 開始進行身分證數字的相乘與累加，依照順序乘上1987654321
      let s =
          parseInt(studIdNumber.substr(0, 1)) +
          parseInt(studIdNumber.substr(1, 1)) * 9 +
          parseInt(studIdNumber.substr(2, 1)) * 8 +
          parseInt(studIdNumber.substr(3, 1)) * 7 +
          parseInt(studIdNumber.substr(4, 1)) * 6 +
          parseInt(studIdNumber.substr(5, 1)) * 5 +
          parseInt(studIdNumber.substr(6, 1)) * 4 +
          parseInt(studIdNumber.substr(7, 1)) * 3 +
          parseInt(studIdNumber.substr(8, 1)) * 2 +
          parseInt(studIdNumber.substr(9, 1));
  
      let checkNum = parseInt(studIdNumber.substr(10, 1));
      // 模數 - 總和/模數(10)之餘數若等於第九碼的檢查碼，則驗證成功
      // 若餘數為0，檢查碼就是0
      if (s % 10 === 0 || 10 - (s % 10) === checkNum) {
        return true;
      } else {
        return false;
      }
    }
    // 外籍生，居留證號規則跟身分證號差不多，只是第二碼也是英文字母代表性別，跟第一碼轉換二位數字規則相同，但只取餘數
    // 驗證填入身分證字號長度及格式
    // 格式，用正則表示式比對第一個字母是否為英文字母
    if (
      !isNaN(studIdNumber.substr(2, 8)) &&
        /^[A-Z]$/.test(studIdNumber.substr(0, 1)) &&
        /^[A-Z]$/.test(studIdNumber.substr(1, 1))
    ) {
      let idHeader = "ABCDEFGHJKLMNPQRSTUVXYWZIO"; // 按照轉換後權數的大小進行排序
      // 這邊把身分證字號轉換成準備要對應的
      studIdNumber =
          idHeader.indexOf(studIdNumber.substring(0, 1)) +
          10 +
          "" +
          ((idHeader.indexOf(studIdNumber.substr(1, 1)) + 10) % 10) +
          "" +
          studIdNumber.substr(2, 8);
      // 開始進行身分證數字的相乘與累加，依照順序乘上1987654321
      let s =
          parseInt(studIdNumber.substr(0, 1)) +
          parseInt(studIdNumber.substr(1, 1)) * 9 +
          parseInt(studIdNumber.substr(2, 1)) * 8 +
          parseInt(studIdNumber.substr(3, 1)) * 7 +
          parseInt(studIdNumber.substr(4, 1)) * 6 +
          parseInt(studIdNumber.substr(5, 1)) * 5 +
          parseInt(studIdNumber.substr(6, 1)) * 4 +
          parseInt(studIdNumber.substr(7, 1)) * 3 +
          parseInt(studIdNumber.substr(8, 1)) * 2 +
          parseInt(studIdNumber.substr(9, 1));
  
      // 檢查號碼 = 10 - 相乘後個位數相加總和之尾數。
      let checkNum = parseInt(studIdNumber.substr(10, 1));
      // 模數 - 總和/模數(10)之餘數若等於第九碼的檢查碼，則驗證成功
      // 若餘數為0，檢查碼就是0
      if (s % 10 === 0 || 10 - (s % 10) === checkNum) {
        return true;
      } else {
        return false;
      }
    }
  },
  CheckBussinessID(taxId) {
    let invalidList = "00000000,11111111";
    if (/^\d{8}$/.test(taxId) === false || invalidList.indexOf(taxId) !== -1) {
      return false;
    }
    let validateOperator = [1, 2, 1, 2, 1, 2, 4, 1];
    let sum = 0;
    let calculate = function(product) {
      // 個位數 + 十位數
      let ones = product % 10;
      let tens = (product - ones) / 10;
      return ones + tens;
    };
    for (let i = 0; i < validateOperator.length; i++) {
      sum += calculate(taxId[i] * validateOperator[i]);
    }
    return sum % 10 === 0 || (parseInt(taxId[6]) === 7 && (sum + 1) % 10 === 0);
  },
  /**
     * 等待載入
     * @param {function} check 一個 function 要回傳檢查結果 true / flase
     * @param {function} proc 若檢查為true 要執行什麼
     * @param {number} chkInterval 檢查頻率(預設500ms)
     */
  DelayExecute(check, proc, chkInterval) {
    // default interval = 500ms
    const x = chkInterval || 500;
    let hnd = window.setInterval(function() {
      // if check() return true
      // stop timer and execute proc()
      if (check()) {
        console.log("check!");
        window.clearInterval(hnd);
        proc();
      }
    }, x);
  },
  /**
     * 浮點數相加處理
     * @param {number} num1
     * @param {number} num2
     */
  FloatAdd(num1, num2) {
    num1 = num1 || 0;
    num2 = num2 || 0;
    const num1Digits = (num1.toString().split(".")[1] || "").length;
    const num2Digits = (num2.toString().split(".")[1] || "").length;
    const baseNum = Math.pow(10, Math.max(num1Digits, num2Digits));
    return (num1 * baseNum + num2 * baseNum) / baseNum;
  },
  // 浮點數相乘
  FloatMul(num1, num2) {
    num1 = num1 || 0;
    num2 = num2 || 0;
    let s1 = num1.toString();
    let s2 = num2.toString();
    // console.log(s1, s2)
    let m1 = s1.split(".")[1];
    m1 ? (m1 = m1.length) : (m1 = 0);
    let m2 = s2.split(".")[1];
    m2 ? (m2 = m2.length) : (m2 = 0);
    return (
      (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m1 + m2)
    );
  },
  // 取得圖片尺寸
  GetImageSize(imgSrc) {
    return new Promise(resolve => {
      let newImg = new Image();
      newImg.src = imgSrc;
      let size = {};
      newImg.onload = () => {
        size = {
          height: newImg.height,
          width: newImg.width
        };
        return resolve(size);
      };
    });
  },
  // 裁切成特定大小圖片(從中心往外)
  async TrimImageSize(file, setWidth, setHeight) {
    //判斷檔案類型是不是圖片
    if (!file.type.includes(`image`)) {
      console.log(`Not Image.`);
      return null;
    }
  
    return new Promise(function(resolve) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async function() {
        let resultFile = await trimImageSize(this.result, setWidth, setHeight);
        return resolve(resultFile);
      };
    });
  
    async function trimImageSize(imgSrc, setWidth, setHeight) {
      return new Promise(function(resolve) {
        let newImg = new Image();
        newImg.src = imgSrc;
        newImg.onload = function() {
          let w = this.width;
          let h = this.height;
          let sx = (w - setWidth) / 2;
          let sy = (h - setHeight) / 2;
  
          //生成canvas
          let canvas = document.createElement(`canvas`);
          let ctx = canvas.getContext(`2d`);
          // 建立屬性節點，設定canvas大小
          let anw = document.createAttribute(`width`);
          anw.nodeValue = setWidth.toString();
          let anh = document.createAttribute(`height`);
          anh.nodeValue = setHeight.toString();
          canvas.setAttributeNode(anw);
          canvas.setAttributeNode(anh);
          // 裁切圖片至設定尺寸: drawImage(img, sourcex, sourcey, sWidth, sHeight, destix, destiy, dWidth, dHeight)
          ctx.drawImage(
            this,
            sx,
            sy,
            setWidth,
            setHeight,
            0,
            0,
            setWidth,
            setHeight
          );
  
          // 將canvas存成file
          let base64 = canvas.toDataURL(`image/jpeg`);
          let resultFile = dataURLtoFile(base64, file.name);
  
          return resolve(resultFile);
        };
      });
    }
  
    function dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }
  },
  // 取得副檔名
  GetFileExtension(filename) {
    // Method 1
    // return filename.substring(filename.lastIndexOf('.')+1, filename.length) || filename;
      
    // Method 2
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
  },
  /**檢查是否為允許上傳之副檔名
     * @param {string} fileExt
     * @param {array} legalExts
     */
  CheckLegalExtension({ fileExt, legalExts}) {
    return legalExts.includes(fileExt);
  },
  // 檢查是否為iOS設備
  // ref: https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
  CheckIsIOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  },
  // 分別正式/測試/本機
  CheckEnv() {
    if (window.location.href.indexOf("localhost") > 0) {
      return "localhost";
    } else if (window.location.href.indexOf("stanteccms") > 0) {
      return "prod";
    } else if (window.location.href.indexOf("cmstest") > 0) {
      return "dev";
    }
  }
};
  
export default tools;
  