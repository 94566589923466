import React,{ useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import tools from '../assets/tools';


const ProjectImage = styled.div`
background-color: ${props => props.isDarkMode ? '#444' : '#fff'};
.sliderContainer{
  width: 100%;
  position: relative;
  .sliderArrowIcon {
    width: 45px;
    height: 45px;
    font-size: 40px;
    color: #e6e6e685;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    transition: .5s;
    z-index: 1;
    &:hover {
      color: #e6e6e6;
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
  .sliderArrowIconLeft {
      left: 5px;
      .fa-angle-left {
        vertical-align: middle;
        margin: -20px 0 0 ;
      }
  }
  .sliderArrowIconRight {
    right: 5px;
    .fa-angle-right {
      vertical-align: middle;
      margin: -20px 0 0 5px;
    }
  }
  .sliderBox {
    width: 100%;
    height: 470px;
    overflow: hidden;
    position: relative;
    .slideWrap {
      width: ${(props) => props.slideWidth * props.totalImages}px;
      display: flex;
      position: absolute;
      h1 {
        width: ${(props) => props.slideWidth}px;
        color: ${props => props.isDarkMode ? '#e6e6e6' : '#555555'};
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        position: absolute;
        top: 5px;
      }
      .slideCard {
        width: 100%;
        height: ${(props) => props.slideHeight}px;
        display: flex;
        overflow: hidden;
        .projectImg {
          width: ${(props) => props.slideWidth}px;
          height: ${(props) => props.slideHeight}px ;
          padding: 37px 0 0;
          img {
            width: 100%;
            height: 100%;
            max-width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
  .sliderDotsBox {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    .sliderDot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #e6e6e6;
      margin: 8px 5px;
      cursor: pointer;
    }
    .active {
      background: orange;
    }
  }
}
@media (max-width: 1480px) {
  .sliderContainer{
    .sliderBox {
      height: 420px;
    }
  }
}
@media (max-width: 1280px) {
  .sliderContainer{
    .sliderBox {
      height: 580px;
    }
  }
}
@media (max-width: 960px) {
  .sliderContainer{
    .sliderBox {
      height: 480px;
    }
  }
}
@media (max-width: 580px) {
  .sliderContainer{
    .sliderBox {
      height: 390px;
    }
  }
}
@media (max-width: 480px) {
  .sliderContainer{
    .sliderBox {
      height: 220px;
    }
  }
}
`;

const DashboardMain = (props) => {

  const [currentImage, setCurrentImage] = useState(0);
  const [slideWidth, setSlideWidth] = useState();
  const [slideHeight, setSlideHeight] = useState();
  const [imgList, setImgList] = useState(props.supervisionPhotoList);
  const sliderBoxRef = useRef();
  const imgListRef = useRef([]);
  const imgRef = imgListRef.current;
  const totalImages = imgList.length;
   
  const scrollToImage = i => {
    setCurrentImage(i);
    
    if (imgRef[i] && imgRef[i].current) {
      imgRef[i].current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      // inline: 'end',
      });
    }
  };

  const nextImage = () => {
    if (currentImage >= totalImages - 1) {
      scrollToImage(0);
    } else {
      scrollToImage(currentImage + 1);
    }
  };

  const previousImage = () => {
    if (currentImage === 0) {
      scrollToImage(totalImages - 1);
    } else {
      scrollToImage(currentImage - 1);
    }
  };

  const dotImage = i => {
    scrollToImage(i);
  };
  
  useEffect(() => {
    setImgList(props.supervisionPhotoList);
  }, [props.supervisionPhotoList]);

  const observer = useRef(
    new ResizeObserver(entries => {
      const { width: containerWidth, height: containerHeight } = entries[0].contentRect;
      setSlideWidth(containerWidth);
      setSlideHeight(containerHeight);
    })
  );
  useEffect(() => {
    if (sliderBoxRef.current) {
      setSlideWidth(sliderBoxRef.current.offsetWidth);
      setSlideHeight(sliderBoxRef.current.offsetHeight);
      scrollToImage(currentImage);
    }
  },[slideWidth, slideHeight]);

  useEffect(() => {
    let isUnmount = false;
    if (sliderBoxRef.current && !isUnmount ) {
      observer.current.observe(sliderBoxRef.current);
    }
    return () => isUnmount = true;
  }, [observer]);

  return (
    <ProjectImage slideWidth={slideWidth} slideHeight={slideHeight} isDarkMode={props.isDarkMode} totalImages={totalImages}>
      <div className="sliderContainer">
        <button 
          className="sliderArrowIcon sliderArrowIconLeft"
          onClick={previousImage}
        >
          <i className="fas fa-regular fa-angle-left"></i>
        </button>
        <button 
          className="sliderArrowIcon sliderArrowIconRight"
          onClick={nextImage}
        >
          <i className="fas fa-regular fa-angle-right"></i>
        </button>
        <div className="sliderBox" ref={sliderBoxRef} >
          <div className="slideWrap">
            {
              imgList.length > 0 ?
                imgList.map((ele,i) => {
                  if (!imgRef[i]) { imgRef[i] = React.createRef(); }
                  return (
                    <div className="slideCard" key={tools._UUID()}>
                      <h1 className='captionText'>{ele.content}</h1>
                      <div className="projectImg" ref={imgRef[i]}>
                        <img src={ele.photoUrl} alt={ele.content}></img>
                      </div>
                    </div>
                  );
                }) : ""
            }
          </div>
        </div>
        <div className='sliderDotsBox'>
          {imgList.map((ele, i) => (
            <button 
              className={`sliderDot ${ currentImage === i && 'active'}`} 
              key={tools._UUID()}
              onClick={() => dotImage(i)}
            ></button>
          ))}
        </div>
      </div>
 
    </ProjectImage>
  );
};
export default DashboardMain;