import TableauDashboard from './TableauDashboard';

const DashboardAbnormal = () => {
  const url = 'https://bi3.stantec.com.tw/views/1/sheet0?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link';

  return (
    <>
      <TableauDashboard url={url}/>
    </>
  );
};
export default DashboardAbnormal;