// import "./../../style/Form.scss";
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import styled from '@emotion/styled';
import { ProjectAPI } from "./../assets/api";
import tools from './../assets/tools';
import { notifyFailed, notifySuccess, notifyWarning } from './../assets/toaster';
import { ToastContainer } from 'react-toastify';
import Pagination from './../assets/Pagination';

const ProjectManage = () => {

  const CreateLink = styled(Link)`
    padding: 10px 12px;
    font-size: 16px;
    min-width: 4rem;
    color: #000 !important;
    @media (max-width: 450px) {
      padding: 6px 8px;
      font-size: 14px;
      min-width: 2rem;
    }
  `;

  const [totalPages, setTotalPages] = useState(1); // 要到的資料總頁數
  const [currentPage, setCurrentPage] = useState(1); // 目前所在頁

  const [projectList, setProjectList] = useState([]);
  const [keyword, setKeyword] = useState('');
  async function GetProjectManageList({ isClear }) {
    let kword = isClear ? '' : keyword;
    try {
      let data = await ProjectAPI.GetProjectManageList({ page: 1, keyword: kword });
      setProjectList(data.projectLsit);
      setTotalPages(data.pageInfo.pages);
      setCurrentPage(1);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: true});
    }
  }

  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleSearchClick = () => {
    GetProjectManageList({ isClear: false });
  };

  const handleCancelClick = () => {
    setKeyword('');
    GetProjectManageList({ isClear: true });
  };

  const handleDeleteProject = async (projectid) => {
    notifyWarning({ Msg: <DeleteConfirm projectid={projectid}/> });
  };

  const DeleteConfirm = ({ closeToast, projectid }) => (
    <div>
      刪除專案？
      <button className="btnS btnRed" onClick={() => DeleteItem(projectid)}>確定刪除</button>
      <button className="btnS btnWhite" onClick={closeToast}>取消</button>
    </div>
  );

  const DeleteItem = async (projectid) => {
    try {
      await ProjectAPI.DeleteProject({ id: projectid});
      notifySuccess({Msg: '刪除成功', cbfunc: ''});
      setProjectList(projectList.filter(ele => ele.id !== projectid));
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: true});
    }
  };

  useEffect(() => {
    GetProjectManageList({ isClear: false });
  }, []);

  return (
    <>
      <div className="bumper_rail"></div>
      <div className="commontitle">
        <h1>專案建置管理</h1>
      </div>
      <div className="filter_table">
        <div className="filter_table_input">
          <div className="">專案名稱：</div>
          <div className=""><input type="text" name="" value={keyword} onChange={handleKeywordChange} /></div>
        </div>
        <div className="filter_table_btn">
          <button className="btnM btnBlue" onClick={handleSearchClick}>搜尋</button>
          <button className="btnM btnGrey" onClick={handleCancelClick}>重整</button>
        </div>
      </div>

      <div className="table">
        <table>
          <tbody>
            <tr>
              <td>項次</td>
              <td>專案名稱</td>
              <td>狀態</td>
              <td><CreateLink to="project-edit/create" className="a_fakebtn btnWhite">新增</CreateLink></td>
            </tr>
            {
              projectList.map((ele, idx) => {
                return (
                  <tr key={tools._UUID()}>
                    <td>{idx+1}</td>
                    <td>{ele.projectName}</td>
                    <td>{ele.status}</td>
                    <td>
                      <Link to={{ pathname: `project-edit/${ele.id}` }} className="a_fakebtn btnM btnBlue">編輯</Link>
                      <button className="btnM btnRed" onClick={() =>handleDeleteProject(ele.id)}>刪除</button>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
      <ToastContainer />

      <Pagination
        setTotalPages={setTotalPages}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        keyword={keyword}
        setList={setProjectList}
        apiFunc={'GetProjectManageList'}
      />
    </>
  );
};
export default ProjectManage;