import styled from '@emotion/styled';
import { useState, useEffect, useContext } from 'react';
import { ProjectContext } from './../contexts/ProjectContext';
// import { useParams } from 'react-router-dom';
import { ProjectAPI, HelperAPI } from './../assets/api';
import tools from './../assets/tools';
import { notifyFailed, notifySuccess, notifyWarning } from './../assets/toaster';
import { ToastContainer } from 'react-toastify';

const TxtInput = styled.input`
  width: 100%;
  max-width: 25em;
  height: 28px;
  border-radius: 3px;
  border: 1px solid #999;
  padding: 0 5px;
  box-sizing: border-box;
`;

const SortTxtInput = styled.input`
  width: 50px;
  text-align: center;
  height: 28px;
  border-radius: 3px;
  border: 1px solid #999;
  padding: 0 5px;
  box-sizing: border-box;
`;

const NormalSelect = styled.select`
  border: 1px solid #888;
  padding: 2px 4px;
  border-radius: 4px;
  width: 100%;
  max-width: 25em;
`;

const CreateProcedureSelect = (props) => {
  return (
    <tr>
      <td><SortTxtInput onChange={props.handleSortChange} value={props.item.sort} /></td>
      <td><TxtInput onChange={props.handleJobTitleChange} value={props.item.jobTitle} /></td>
      <td>
        <NormalSelect onChange={props.handleUserSelect} value={props.item.userName}>
          {
            props.helper.userList.map(oele => {
              return (
                <option key={oele.userName} value={oele.userName}>{oele.name_zhtw}</option>
              );
            })
          }
        </NormalSelect>
      </td>
      <td><button className="btnS btnRed" onClick={props.handleItemDelete}><i className="far fa-trash-alt"></i></button></td>
    </tr>
  );
};

const ApprovalProceduresManage = () => {

  // const { projectid } = useParams();
  const { projectInfo } = useContext(ProjectContext);
  let projectid = projectInfo.id;
  // console.log(projectid);

  const [projectSignOffList, setProjectSignOffList] = useState([]);
  // const [pageStatus, setPageStatus] = useState(0);
  const [helper, setHelper] = useState({
    userList: [],
  });

  async function GetProjectSignOffList(projectId) {
    try {
      let data = await ProjectAPI.GetProjectSignOffList(projectId);
      data.forEach(ele => {
        ele._uuid = tools._UUID();
        ele.status = 0;
        ele.isExist = true; // Is this item already existed in database
      });
      setProjectSignOffList(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function GetUserList(projectId) {
    try {
      let data = await HelperAPI.GetUserList(projectId);
      data.unshift({id:'0', userName:'請選擇', name_zhtw:'請選擇', name_ne:'' });
      // return data.filter(ele => ele.enable);
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  const handleCreateProcedure = () => {
    setProjectSignOffList([...projectSignOffList, new Procedure({ _uuid: tools._UUID(), status: 1 })]);
  };


  // function Procedure({ _uuid = 0, id = 0, projectId = Number(projectid) ? Number(projectid) : 0, sort = 0, userName = helper.userList[0].userName, jobTitle = '', status = 0 }) {
  function Procedure({ _uuid = 0, id = 0, projectId = Number(projectid) ? Number(projectid) : 0, sort = 0, userName = (helper.userList.length === 0) ? '' : helper.userList[0].userName, jobTitle = '', status = 0 }) {
    this._uuid = _uuid;
    this.id = id;
    this.projectId = projectId;
    this.sort = sort;
    this.userName = userName;
    this.jobTitle = jobTitle;
    this.status = status;
  }

  const handleUserSelect = (e, el) => {
    // console.log(e.target.value);
    setProjectSignOffList(projectSignOffList.map(ele => (ele._uuid === el._uuid ? { ...ele, userName: e.target.value, status: el.id ? 2 : 1 } : ele)));
    // console.log(projectSignOffList);
  };

  const handleSortChange = (e, el) => {
    setProjectSignOffList(projectSignOffList.map(ele => (ele._uuid === el._uuid ? { ...ele, sort: e.target.value, status: el.id ? 2 : 1 } : ele)));
  };

  const handleJobTitleChange = (e, el) => {
    setProjectSignOffList(projectSignOffList.map(ele => (ele._uuid === el._uuid ? { ...ele, jobTitle: e.target.value, status: el.id ? 2 : 1 } : ele)));
  };

  const handleItemDelete = (deleteItem) => {
    handleUploadEkphrasisDelete(deleteItem);
  };

  const handleUploadEkphrasisDelete = (deleteItem) => {
    notifyWarning({ Msg: <DeleteConfirm deleteItem={deleteItem}/> });
  };

  const DeleteConfirm = ({ closeToast, deleteItem }) => (
    <div>
      刪除簽核流程？
      <button className="btnS btnRed" onClick={() => DeleteItem(deleteItem)}>確定刪除</button>
      <button className="btnS btnWhite" onClick={closeToast}>取消</button>
    </div>
  );

  const DeleteItem = (deleteItem) => {
    if (deleteItem.isExist) {
      setProjectSignOffList(projectSignOffList.map(ele => (ele._uuid === deleteItem._uuid ? { ...ele, status: 3 } : ele)));
    } else {
      setProjectSignOffList(projectSignOffList.filter(ele => ele._uuid !== deleteItem._uuid));
    }
  };

  // 檢查欄位-Sort、
  const FormValidate = () => {
    for (let i = 1; i < projectSignOffList.length; i++) {
      if ( projectSignOffList[i].sort == 0 || projectSignOffList[i].sort == 1 || projectSignOffList[i].sort == '' ) {
        return false;
      }
    }
    return true;
  };

  const handleSaveClick = async () => {
    // console.log(projectSignOffList);
    let formValidate = FormValidate();
    if (projectSignOffList.length >= 2 && formValidate) {
      let projectSignoffModify = [];
      for (let i = 0; i < projectSignOffList.length; i++) {
        projectSignoffModify.push(
          {
            "id": projectSignOffList[i].id || 0,
            "projectid": projectSignOffList[i].projectId || Number(projectid),
            "sort": Number(projectSignOffList[i].sort),
            "userName": projectSignOffList[i].userName || null,
            "jobtitle": projectSignOffList[i].jobTitle || "",
            "status": projectSignOffList[i].status || 0,
          }
        );
      }
      try {
        await ProjectAPI.ModifyProjectSignoff({ projectSignoffModify });
        // console.log(notifySuccess);
        notifySuccess({ Msg: '儲存成功', cbfunc: () => window.location.reload() });
      } catch (error) {
        notifyFailed({ Msg: '發生錯誤', isReload: false });
      }
    } else {
      // console.log('請至少輸入2筆');
      notifyFailed({ Msg: '請至少輸入2筆，且第二筆以上順序不得為 0、1，或空值。', isReload: false });
    }
  };

  useEffect(async () => {
    GetProjectSignOffList(projectid);

    let userList = await GetUserList(projectid);
    setHelper({ ...helper, userList });
  }, []);



  return (
    <>
      <div className="bumper_rail"></div>
      <div className="commontitle">
        <h1>專案簽核流程管理</h1>
      </div>

      <div className="table" style={{ maxWidth: '1400px', }}>
        <table>
          <tbody>
            <tr>
              <td>順序</td>
              <td>職稱</td>
              <td>人員</td>
              <td></td>
            </tr>
            {
              projectSignOffList.filter(el => el.status !== 3).map((ele, idx) => {
                return (
                  ele.isExist ?
                    <tr key={ele._uuid}>
                      <td>
                        {idx > 0 ?
                          <SortTxtInput value={ele.sort || ''} onChange={(event) => handleSortChange(event, ele)} />
                          : '1'}
                      </td>
                      <td><TxtInput value={ele.jobTitle || ''} onChange={(event) => handleJobTitleChange(event, ele)} /></td>
                      <td>
                        {/* ↓ 第一項"承辦人員"固定不可修改 */}
                        {idx > 0 ?
                          <NormalSelect onChange={(event) => handleUserSelect(event, ele)} value={ele.userName}>
                            {
                              helper.userList.map(oele => {
                                return (
                                  <option key={oele.userName} value={oele.userName}>{oele.name_zhtw}</option>
                                );
                              })
                            }
                          </NormalSelect> : '承辦人員'}
                      </td>
                      {/* 第三項以後才會有刪除按鈕 */}
                      <td>{idx > 1 ? <button className="btnS btnRed" onClick={() => handleItemDelete(ele)}><i className="far fa-trash-alt"></i></button> : ''}</td>
                    </tr> :
                    <CreateProcedureSelect
                      helper={helper}
                      item={ele}
                      key={ele._uuid}
                      handleSortChange={(event) => handleSortChange(event, ele)}
                      handleJobTitleChange={(event) => handleJobTitleChange(event, ele)}
                      handleUserSelect={(event) => handleUserSelect(event, ele)}
                      handleItemDelete={() => handleItemDelete(ele)}
                    />
                );
              })
            }
            <tr>
              <td colSpan="4"><button className="btnM btnOrange" onClick={handleCreateProcedure}><i className="fas fa-plus"></i></button></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="btn_box" style={{ maxWidth: '1400px', }}>
        {/* 如果沒設定2的人員會無法存檔 */}
        <button className="btnL btnBlue" onClick={handleSaveClick}>儲存</button>
      </div>
      <ToastContainer />
    </>
  );
};
export default ApprovalProceduresManage;