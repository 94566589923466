import React, { useState, useEffect, useContext } from "react";
import { ProjectAPI } from "../assets/api";
import "./../../style/Project.scss";

import { notifyFailed } from './../assets/toaster';
import { ToastContainer } from 'react-toastify';

import { ProjectContext } from './../contexts/ProjectContext';
import { useHistory } from "react-router";

const ProjectsList = () => {
  const [projectList, setProjectList] = useState([]);
  const { dispatch } = useContext(ProjectContext);
  let history = useHistory();

  async function GetProjectList() {
    try {
      let data = await ProjectAPI.GetProjectList();
      setProjectList(data);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }

  const handleProjectSelect = (project) => {
    dispatch({ type: 'SELECT_PROJECT', project });
    history.push('/project/form-list');
  };

  useEffect(() => {
    GetProjectList();
  }, []);
  return (
    <>
      <div className="main">
        <div className="project_box">
          {projectList.map(ele => (
            <span key={ele.id} onClick={() => handleProjectSelect(ele)}>
              <div className="pj_item">
                <div className="pj_img">
                  <img src={ele.coverImage} alt={`${ele.projectName} 封面圖片`} />
                </div>
                <div className="pj_title">
                  <div>
                    {ele.prjectStatus >= 2 ? <p className="stamp_jun">竣</p> : null }
                    {/* {ele.projectName} */}
                    {ele.projectNum} {ele.projectShortName}
                  </div>
                </div>
                <div className="pj_hover">
                  <p>{ele.titleAgency}</p>
                  <p className="fa_arrow"><i className="far fa-arrow-alt-circle-right"></i></p>
                </div>
              </div>
            </span>
          ))}
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ProjectsList;