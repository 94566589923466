import styled from '@emotion/styled';
import '../../style/Dashboard.scss';
import { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import tools from '../assets/tools';
import { D3MultiLineChart, D3Swatches, D3PieChart } from '../assets/d3components';
import { SwitchMode } from './SwitchMode';
import { ThemeContext } from './../contexts/ThemeContext';
import { StatisticAPI, HelperAPI } from './../assets/api';
// import colorSchemes from './../assets/d3components/config/scale-chromatic';
import { notifyFailed } from './../assets/toaster';
import { ToastContainer } from 'react-toastify';
import { useLocalStorage } from './useLocalStorage';
import { ChartDownloadButton } from './DownloadImageButton';
import { useIsFirstRender } from './../assets/customHooks/useIsFirstRender';


const ChartBg = styled.div`
  width: 60%;
  margin: 10px 10px 0px 0px;
  position: relative;
  border: 1px solid ${props => props.isDarkMode ? 'none' : '#7b7979'};
  background-color: ${props => props.isDarkMode ? '#444' : '#ffffff'};
  @media (max-width: 1280px) {
  width: 100%;
}
  @media (max-width: 768px) {
  width: 100%;
}
 @media (max-width: 630px) {
  margin-bottom: 0px;
}
`;
const DailyDate = styled.div`
  width: 100%;
  color: #e6e6e6;
  font-size: 12px;
  border: 1px solid ${props => props.isDarkMode ? 'none' : '#7b7979'};
  background-color: ${props => props.isDarkMode ? '#444' : '#ffffff'};
  input[type="date"] {
    width: 99%;
  }
  @media (max-width: 900px) {
    width: 100%;
    input[type="date"] {
    width: 100%;
    }
  }
  @media (max-width: 630px) {
    h3 {
      padding: 5px 5px;
    }
    input[type="date"] {
    width: 100%;
  }
}
`;
const IntervalDate = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
color: #e6e6e6;
font-size: 12px;
>div {
  width: 49%;
  border: 1px solid ${props => props.isDarkMode ? 'none' : '#7b7979'};
  background-color: ${props => props.isDarkMode ? '#444' : '#ffffff'};
  input[type="date"] {
    width: 99%;
  }
}
`;
const TabClipLeft = styled.div`
width: 50%;
display: flex;
flex-direction: column;
box-sizing: border-box;
margin: 10px 10px 0 0;
@media (max-width: 1280px) {
  width: 100%;
}
@media (max-width: 768px) {
  margin-right: 10px;
}
@media (max-width: 630px) {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  h3 {
    padding: 5px 5px;
  }
}
@media (max-width: 375px) {
  width: 375px;
}
`;
const TabClipRight = styled.div`
width: 50%;
display: flex;
flex-direction: column;
box-sizing: border-box;
margin: 10px 10px 0 0;
@media (max-width: 1280px) {
  width: 100%;
}
@media (max-width: 768px) {
  margin-right: 0px;
}
@media (max-width: 630px) {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  h3 {
    padding: 5px 5px;
  }
}
@media (max-width: 375px) {
  width: 375px;
}
`;
const TabClipItem = styled.div`
width: 100%;
height: 100%;
min-height: 155px;
max-height: 245px;
overflow-y: auto;
overflow-x: auto;
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 20px grey;
}
::-webkit-scrollbar-thumb {
  background: #bcbcbc;
}
color: #e6e6e6;
background-color: ${props => props.isDarkMode ? '#444' : '#ffffff'};
border-bottom: 1px solid ${props => props.isDarkMode ? '#e6e6e6' : '#7b7979'};
border-right: 1px solid ${props => props.isDarkMode ? '#e6e6e6' : '#7b7979'};
border: 1px solid ${props => props.isDarkMode ? 'none' : '#7b7979'};
&:nth-of-type(2) {
  margin: 10px 0;
}
`;
const TabClipTable = styled.div`
width: 100%;
height: 100%;
font-size: 14px;
line-height: 35px;
table {
  width: 100%;
  height: 100%;
  min-height: 155px;
  position: relative;
  thead {
    tr {
      position: sticky;
      top: 0;
      background-color: ${props => props.isDarkMode ? '#444' : '#ffffff'};
      border-bottom: 1px solid${props => props.isDarkMode ? '#e6e6e6' : '#7b7979'};
      box-shadow: 0 0 1px 1px ${props => props.isDarkMode ? '#ffffff5e' : '#44444461'};
      th {
        padding: 0 5px;
        text-align: start;
        color: ${props => props.isDarkMode ? '#e6e6e6' : '#0c0c0c'};
      }
    }
  }
  tbody tr {
    &:nth-last-of-type(2) {
      height: 100%;
    }
    &:last-of-type {
    border-top: 1px solid ${props => props.isDarkMode ? '#e6e6e6' : '#7b7979'};
    }
    td {
      width: 100%;
      padding: 0 5px;
      color: ${props => props.isDarkMode ? '#e6e6e6' : '#0c0c0c'};
      :nth-of-type(1) {
      width: 50%;
      border-right: 1px solid ${props => props.isDarkMode ? '#e6e6e6' : '#7b7979'};
      }
      :nth-of-type(2) {
      width: 100%;
      margin-right: 5px;
      text-align: end;
      }
    }
  }
}
`;
const Display = styled.div`
display: flex;
flex-direction: row;
margin-left: 10px;
margin-bottom: 5px;
position: relative;
@media (max-width: 768px) {
  flex-direction: column;
  margin: 0 10px;
}
@media (max-width: 630px) {
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: center;
  margin: 0 10px 0;
}
`;
const DisplayBottom = styled.div`
display: flex;
flex-direction: row;
margin-left: 10px;
position: relative;
@media (max-width: 960px) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-right: 10px;
}
`;
const D3Div = styled.div`
width: 100%;
background-color: ${props => props.isDarkMode ? '#555555' : '#ffffff'};
`;
const TabClipRWD = styled.div`
width: 40%;
display: flex;
@media (max-width: 1280px) {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  margin-right: 0px;
}
@media (max-width: 768px) {
  width: 100%;
  flex-direction: row;
}
@media (max-width: 630px) {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  order: -1;
}
`;

const NoDataText = styled.tr`
text-align: center;
font-size: 20px;
color: ${props => props.isDarkMode ? '#e6e6e6' : '#7b7979'};
`;

const NoDataTextDiv = styled.div`
text-align: center;
font-size: 20px;
color: ${props => props.isDarkMode ? '#e6e6e6' : '#7b7979'};
`;

const colorTheme = {
  light: {
    titleAltColor:  "#2f2f2f",
    titleTextColor: "black",
    xValueColor: "black",
    yValueColor: "black",
    backgroundColor: "#ffffff",
    color: "#2f2f2f",
  },
  dark: {
    titleAltColor:  "white",
    titleTextColor: "white",
    xValueColor: "white",
    yValueColor: "white",
    backgroundColor: "#444",
    color: "white",
  }
};

const DashboardMain = () => {
  const isFirstRender = useIsFirstRender();
  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark' ? true : false;

  const [projectList, setProjectList] = useState([]); //工程項目清單
  const [projectId, setProjectId] = useLocalStorage("projectId", projectList.length ? projectList[0].id : 0); 
  const [dailyDate, setDailyDate] = useState(moment().format('YYYY-MM-DD'));
  const [rangeStartDate, setRangeStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [rangeEndDate, setRangeEndtDate] = useState(moment().format('YYYY-MM-DD'));
  const [personnelImplements, setPersonnelImplements] = useState({
    daily: {
      personnelList: [], //每日工別
      implementsList: [], //每日機具
    },
    overall: {
      personnelList: [], //範圍日期人工別
      implementsList: [], //範圍日期機具
    },
  });
  //圖表
  const [dailyProgress, setDailyProgress] = useState([]); //工程進度
  const [dailyPersonnelImplementsChart, setDailyPersonnelImplementsChart] = useState([]); //每日出工機具圖表
  const [weatherListTotal, setWeatherListTotal] = useState([]); //晴雨統計
  const [dailyProgressChartOption, setDailyProgressChartOption] = useState({
    chartId: "dailyProgressChartSvg",
    downloadAreaId: "dailyProgressChartChart",
    titleText: "工程進度趨勢圖表",
    xAttr: 'progressDate',
    yAttr: 'progress',
    zAttr: 'name',
    strokeWidth: 2.5,
    marginLeft: 10,
    colorCustom: ['#4e79a7', '#f28e2c',],
    ...colorTheme[isDarkMode ? "dark" : "light"]
  });
  const [weatherChartOption, setWeatherChartOption] = useState({
    chartId: "weatherListChartSvg",
    downloadAreaId: "weatherListChartChart",
    titleText: "晴雨表統計",
    name: 'name',
    value: 'total',
    percentage: 'rate',
    marginLeft: 10,
    colorCustom: ["#4e79a7", "#f28e2c", "#e15759", "#76b7b2",],
    ...colorTheme[isDarkMode ? "dark" : "light"]
  });
  const [personnelImplementsChartOption, setPersonnelImplementsOption] = useState({
    chartId: "dailyPersonnelImplementsChartSvg",
    downloadAreaId: "dailyPersonnelImplementsChart",
    titleText: "每日出工機具趨勢圖",
    xAttr: 'date',
    yAttr: 'totalValue',
    zAttr: 'name',
    strokeWidth: 2.5,
    marginLeft: 10,
    colorCustom: ['#4e79a7', '#f28e2c',],
    ...colorTheme[isDarkMode ? "dark" : "light"]
  });
  //取得工程項目清單
  const GetStaticProjectList = async () => {
    try {
      let data = await HelperAPI.GetStaticProjectList();
      setProjectList(data.projectList);
      return data.projectList;
    } catch (error) {
      console.log(error);
    }
  };

  function sumTotals(sumList) {
    if(sumList) {
      const SumTotal = Object.values(sumList).reduce((acc, cur) => acc + cur.total, 0);
      const newList = [ ...sumList, { name: "總和", total: SumTotal} ];
      return newList;
    } else {
      return [];
    }
  }

  function chartRole(data, type) {
    if(data) {
      const newData = data.reduce((acc, cur) => {
        const { fillInDate, total } = cur;
        if (fillInDate in acc) {
          acc[fillInDate] += total;
        } else {
          acc[fillInDate] = total;
        }
        return acc;
      },[]);
      return Object.entries(newData).map(([key, value]) => {
        return {
          name: type,
          date: moment(key).format('YYYY-MM-DD'),
          totalValue: value,
        };
      });
    } else {
      return [];
    }
  }

  //工程進度(總)
  const GetProgress = async (projectId) => {
    try {
      let data = await StatisticAPI.GetProgress(projectId);
      const scheduledProgressData = data.getDailyProgress.progressDate.map((date, index) => ({
        name: `預定進度`,
        progressDate: moment(date).format('YYYY-MM-DD'),
        progress: data.getDailyProgress.scheduledProgress[index],
      }));
      const actualProgressData = data.getDailyProgress.progressDate.map((date, index) => ({
        name: `實際進度`,
        progressDate: moment(date).format('YYYY-MM-DD'),
        progress: data.getDailyProgress.actualProgress[index],
      }));
      const filterActualProgressData = actualProgressData.filter(item => item.progress !== null);
      setDailyProgress([...scheduledProgressData, ...filterActualProgressData]);
      
      //每日出工機具
      setDailyDate(moment(data.lastDataDate ).format('YYYY-MM-DD'));
      //範圍工別機具
      setRangeStartDate(moment(data.contractSDate).format('YYYY-MM-DD'));
      setRangeEndtDate(moment(data.lastDataDate).format('YYYY-MM-DD'));
      setPersonnelImplements({
        daily: {
          personnelList: sumTotals(data.getDailyPersonnelImplements.personnelList),
          implementsList: sumTotals(data.getDailyPersonnelImplements.implementsList),
        },
        overall: {
          personnelList: sumTotals(data.getRangePersonnelImplements.personnelListTotal),
          implementsList: sumTotals(data.getRangePersonnelImplements.implementsListTotal),
        },
      });

      //每日出工機具圖表
      const newPersonnelData = chartRole(data.getRangePersonnelImplements.personnelList, '出工');
      const newImplementsData = chartRole(data.getRangePersonnelImplements.implementsList, '機具');
      setDailyPersonnelImplementsChart([...newPersonnelData, ...newImplementsData]);
  
      //情雨統計
      setWeatherListTotal(data.getRangePersonnelImplements.weatherListTotal);

    } catch (error) {
      console.log(error);
    }
  };
  //每日出工機具資料
  const GetDailyPersonnelImplements = async ( projectId, date) => {
    try {
      const { personnelList, implementsList } = await StatisticAPI.GetDailyPersonnelImplements(projectId, date);
      setPersonnelImplements(prevState => ({
        ...prevState,
        daily: {
          personnelList: sumTotals(personnelList),
          implementsList: sumTotals(implementsList),
        },
      }));

    } catch (error) {
      console.log(error);
    }
  };

  //範圍出工機具
  const GetRangePersonnelImplements = async ( projectId, startDate, endDate) => {
    try {
      let data = await StatisticAPI.GetRangePersonnelImplements( projectId, startDate, endDate );
      setPersonnelImplements(prevState => ({
        ...prevState,
        overall: {
          personnelList: sumTotals(data.personnelListTotal), //人員數量
          implementsList: sumTotals(data.implementsListTotal), //機具數量
        },
      }));

      //每日出工機具圖表
      const newPersonnelData = chartRole(data.personnelList, '出工');
      const newImplementsData = chartRole(data.implementsList, '機具');
      setDailyPersonnelImplementsChart([...newPersonnelData, ...newImplementsData]);

      //情雨統計
      setWeatherListTotal(data.weatherListTotal);
    } catch (error) {
      console.log(error);
    }
  };

  const handleProjectSelect = (e) => {
    let value = projectList.find(ele => ele.id === Number(e.target.value));
    setProjectId(value.id);
  };

  const handleDailyDate = (e) => {
    const data = moment(e.target.value).format('YYYY-MM-DD');
    setDailyDate(data);
    GetDailyPersonnelImplements(projectId, data);
  };

  const handleStartDate = (e) => {
    const selectDate = moment(e.target.value).format('YYYY-MM-DD');
    setRangeStartDate(selectDate); 
    if(moment(e.target.value,'YYYY-MM-DD').isAfter(moment(rangeEndDate))) {
      notifyFailed({Msg: '開始日期大於結束日期！', isReload: false});
    }
    GetRangePersonnelImplements(projectId, selectDate, rangeEndDate);
  };

  const handleEndDate = (e) => {
    const selectDate = moment(e.target.value).format('YYYY-MM-DD');
    setRangeEndtDate(selectDate); 
    if(moment(e.target.value,'YYYY-MM-DD').isBefore(moment(rangeStartDate))) {
      notifyFailed({Msg: '結束日期大於開始日期！', isReload: false});
    }
    GetRangePersonnelImplements(projectId, rangeStartDate, selectDate);
  };

  useEffect(async() => {
    let staticProjectList = await GetStaticProjectList();
    if (projectId === 0) {
      setProjectId(staticProjectList[0].id);
    } else {
      GetProgress(projectId);
    }
  },[]);

  useEffect(() => {
    if (!isFirstRender) {
      GetProgress(projectId);
    }
  },[projectId]);

  useEffect(() => {
    setPersonnelImplementsOption({
      ...personnelImplementsChartOption,
      ...colorTheme[isDarkMode ? "dark" : "light"]
    });
    
    setWeatherChartOption({
      ...weatherChartOption,
      ...colorTheme[isDarkMode ? "dark" : "light"]
    });
    
    setDailyProgressChartOption({
      ...dailyProgressChartOption,
      ...colorTheme[isDarkMode ? "dark" : "light"]
    });
  }, [isDarkMode]);
  

  return (
    <>
      {/* 深色模式切換按鈕 */}
      <SwitchMode/>
      {/* 報表內容 */}
      <div className="containerBox" style={{backgroundColor: isDarkMode ? '#666666' : '#F1F1F1' }}>
        <section className="dashboardContent">
          <div className="dashboardUpArea">
            <div className="dashboardUpArea_left">
              <div className="subTitle">
                <h1 style={{ color: isDarkMode ? '#e6e6e6' : '#0c0c0c'}}>工程進度</h1>
              </div>
            </div>
            <div className="projectWrap" style={{backgroundColor: isDarkMode ? '#555555' : '#ffffff'}}>
              <h3 style={{color: isDarkMode ? '#e6e6e6' : '#0c0c0c'}}>專案名稱</h3>
              <div className="dropdownBox">
                <select 
                  className="dropdownMenu" 
                  onChange={(e) => handleProjectSelect(e)} 
                  value={projectId}
                >
                  {
                    projectList.map(ele => (
                      <option key={ele.id} value={ele.id}>{ele.projectName}</option>
                    ))
                  }
                </select>
              </div>
            </div>
          </div>
          <Display>
            {/* 工程進度趨勢圖表 */}
            <ChartBg isDarkMode={isDarkMode} >
              { 
                dailyProgress && dailyProgress.length > 0 ?
                  <D3Div id={dailyProgressChartOption.downloadAreaId}>
                    <D3MultiLineChart data={dailyProgress} option={dailyProgressChartOption} />
                    <D3Swatches group={dailyProgress.map(p => p.name)} option={dailyProgressChartOption}/> 
                  </D3Div>
                  : <NoDataTextDiv isDarkMode={isDarkMode}>當前專案無資料</NoDataTextDiv>
              }      
              <ChartDownloadButton
                chartId={dailyProgressChartOption.chartId}
                downloadAreaId={dailyProgressChartOption.downloadAreaId}
                chartName={dailyProgressChartOption.titleText}
              />    
              {/* <ChartDownloadBox>
                <button className="chartDownloadBtn" onClick={() => downloadImage(dailyProgressChartOption.chartId, dailyProgressChartOption.downloadAreaId, dailyProgressChartOption.titleText)}>Download</button>
              </ChartDownloadBox> */}
            </ChartBg>
            <TabClipRWD>
              {/* 每日出工機具 */}
              <TabClipLeft>
                <DailyDate isDarkMode={isDarkMode} >
                  <h3 style={{color: isDarkMode ? '#e6e6e6' : '#0c0c0c'}}>篩選日期</h3>
                  <input type="date" value={dailyDate} onChange={ (e)=> handleDailyDate(e)}/>
                </DailyDate>
                <TabClipItem isDarkMode={isDarkMode} >
                  <TabClipTable isDarkMode={isDarkMode}> 
                    <table>
                      <thead>
                        <tr>
                          <th>工別</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          personnelImplements.daily.personnelList.length > 1 ? 
                            personnelImplements.daily.personnelList.map((item) => (
                              <tr key={tools._UUID()}>
                                <td>{item.name}</td>
                                <td>{item.total.toLocaleString()}</td>
                              </tr>
                            ))                          
                            : <NoDataText isDarkMode={isDarkMode}><td colSpan="2">當前專案無資料</td></NoDataText>
                        }
                      </tbody>
                    </table>
                  </TabClipTable>
                </TabClipItem>
                <TabClipItem isDarkMode={isDarkMode} >
                  <TabClipTable isDarkMode={isDarkMode} >
                    <table>
                      <thead>
                        <tr>
                          <th>機具</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          personnelImplements.daily.implementsList.length > 1 ?
                            personnelImplements.daily.implementsList.map((item) => (
                              <tr key={tools._UUID()}>
                                <td>{item.name}</td>
                                <td>{item.total.toLocaleString()}</td>
                              </tr>
                            ))                          
                            : <NoDataText isDarkMode={isDarkMode}><td colSpan="2">當前專案無資料</td></NoDataText>
                        }
                      </tbody>
                    </table>
                  </TabClipTable>
                </TabClipItem>
              </TabClipLeft>
              {/* 範圍出工機具 */}
              <TabClipRight>
                <IntervalDate isDarkMode={isDarkMode} >
                  <div>
                    <h3 style={{color: isDarkMode ? '#e6e6e6' : '#0c0c0c'}}>篩選開始日期</h3>
                    <input type="date" value={rangeStartDate} onChange={ (e)=> handleStartDate(e) }/>
                  </div>
                  <div>
                    <h3 style={{color: isDarkMode ? '#e6e6e6' : '#0c0c0c'}}>篩選結束日期</h3>
                    <input type="date" value={rangeEndDate} onChange={ (e)=> handleEndDate(e) }/>
                  </div>
                </IntervalDate>
                <TabClipItem isDarkMode={isDarkMode} >
                  <TabClipTable isDarkMode={isDarkMode} >
                    <table>
                      <thead>
                        <tr>
                          <th>工別</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody >
                        {
                          personnelImplements.overall.personnelList.length > 1 ?            
                            personnelImplements.overall.personnelList.map((item) => (
                              <tr key={tools._UUID()}>
                                <td>{item.name}</td>
                                <td>{item.total.toLocaleString()}</td>
                              </tr>
                            ))                          
                            : <NoDataText isDarkMode={isDarkMode}><td colSpan="2">當前專案無資料</td></NoDataText>
                        }
                      </tbody>
                    </table>
                  </TabClipTable>
                </TabClipItem>
                <TabClipItem isDarkMode={isDarkMode} >
                  <TabClipTable isDarkMode={isDarkMode} >
                    <table>
                      <thead>
                        <tr>
                          <th>機具</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        { 
                          personnelImplements.overall.implementsList.length > 1 ?
                            personnelImplements.overall.implementsList.map((item) => (
                              <tr key={tools._UUID()}>
                                <td>{item.name}</td>
                                <td>{item.total.toLocaleString() }</td>
                              </tr>
                            ))
                            : <NoDataText isDarkMode={isDarkMode}><td colSpan="2">當前專案無資料</td></NoDataText>
                        }
                      </tbody>
                    </table>
                  </TabClipTable>
                </TabClipItem>
              </TabClipRight>
            </TabClipRWD>
          </Display>
          <DisplayBottom>
            {/* 晴雨統計表 */}
            <ChartBg isDarkMode={isDarkMode}>
              {
                weatherListTotal.length > 0 ? 
                  <D3Div id={weatherChartOption.downloadAreaId} isDarkMode={isDarkMode}>
                    <D3PieChart data={weatherListTotal} option={weatherChartOption} /> 
                    <D3Swatches group={weatherListTotal.map(p => p.name)} option={weatherChartOption}/>
                  </D3Div>         
                  : <NoDataTextDiv isDarkMode={isDarkMode}>當前專案無資料</NoDataTextDiv>
              }
              <ChartDownloadButton
                chartId={weatherChartOption.chartId}
                downloadAreaId={weatherChartOption.downloadAreaId}
                chartName={weatherChartOption.titleText}
              /> 
            </ChartBg>
            {/* 每日出工機具趨勢圖 */}
            <ChartBg isDarkMode={isDarkMode} >
              { 
                dailyPersonnelImplementsChart.length > 0 ? 
                  <D3Div id={personnelImplementsChartOption.downloadAreaId}>
                    <D3MultiLineChart data={dailyPersonnelImplementsChart} option={personnelImplementsChartOption}/>
                    <D3Swatches group={dailyPersonnelImplementsChart.map(p => p.name)} option={personnelImplementsChartOption}/>  
                  </D3Div>
                  : <NoDataTextDiv isDarkMode={isDarkMode}>當前專案無資料</NoDataTextDiv>
              }
              <ChartDownloadButton
                chartId={personnelImplementsChartOption.chartId}
                downloadAreaId={personnelImplementsChartOption.downloadAreaId}
                chartName={personnelImplementsChartOption.titleText}
              /> 
            </ChartBg>
          </DisplayBottom>
        </section>
      </div>
      <ToastContainer />
    </>
  );
};
export default DashboardMain;

// import TableauDashboard from './TableauDashboard';
// import tools from '../assets/tools';

// const DashboardMain = () => {
//   const url = tools.CheckEnv() === 'prod' ?
//     'https://bi3.stantec.com.tw/views/DashboardProd/sheet30?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link' :
//     'https://bi3.stantec.com.tw/views/DashboardNew/sheet17?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link';
//   return (
//     <>
//       <TableauDashboard url={url}/>
//     </>
//   );
// };
// export default DashboardMain;