import { Route, useRouteMatch, Switch } from "react-router-dom";
import AccountManage from './AccountManage';
import SignetManage from './SignetManage';
import SignetEdit from './SignetEdit';
import SignetCreate from './SignetCreate';


const Account = () => {

  let { path } = useRouteMatch();

  return (
    <>
      <div className="main">
        <Switch>
          <Route path={`${path}/account-manage`} component={AccountManage}></Route>
          <Route path={`${path}/signet-manage`} component={SignetManage}></Route>
          <Route path={`${path}/signet-edit/:stampid`} component={SignetEdit}></Route>
          <Route path={`${path}/signet-create`} component={SignetCreate}></Route>
        </Switch>
      </div>
    </>
  );

};

export default Account;