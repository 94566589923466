// import "./../../style/Form.scss";
import styled from '@emotion/styled';
import { useState, useEffect } from 'react';
import { ProjectAPI } from "../assets/api";
import tools from '../assets/tools';
import { notifyFailed, notifySuccess, notifyWarning } from '../assets/toaster';
import { ToastContainer } from 'react-toastify';

const TxtInput = styled.textarea`
  width: 100%;
  max-width: 22em;
  // height: 28px;
  border-radius: 3px;
  border: 1px solid #AAA;
  padding: 5px;
  box-sizing: border-box;
`;

const CreateWeatherItem = (props) => {
  return (
    <tr>
      <td></td>
      <td><TxtInput value={props.item.weather || ''} onChange={props.handleItemNameChange} /></td>
      <td>
        <button className="btnM btnRed" onClick={props.handleItemDelete}>刪除</button>
      </td>
    </tr>
  );
};

const WeatherManage = () => {

  const [weatherList, setWeatherList] = useState([]);
  const [originalWeatherList, setOriginalWeatherList] = useState({});

  const [isSaving, setIsSaving] = useState(false);


  function WeatherItem({ _uuid = 0, id = 0, weather = '', status = 0, isExist = false, isEditing = true }) {
    this._uuid = _uuid;
    this.id = id;
    this.weather = weather;
    this.status = status;
    this.isExist = isExist;
    this.isEditing = isEditing;
  }

  async function GetWeatherForm() {
    try {
      let data = await ProjectAPI.GetWeatherForm();
      data.forEach(ele => {
        ele._uuid = tools._UUID();
        ele.status = 0;
        ele.isExist = true; // Is this item already existed in database
        ele.isEditing = false; // Is this item being editted
      });
      setWeatherList(data);
      setOriginalWeatherList(data);
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: true });
    }
  }

  const handleItemCreate = () => {
    // setWeatherList({ weatherList: [...weatherList, new WeatherItem({ _uuid: tools._UUID(), status: 1 })] });
    setWeatherList([...weatherList, new WeatherItem({ _uuid: tools._UUID(), status: 1 })]);
  };

  const handleItemUpdate = (updateItem) => {
    // setWeatherList(weatherList.map(ele => {ele._uuid === updateItem._uuid ? { ...ele, status: 2, isEditing: true } : ele;}));
    setWeatherList(weatherList.map(ele => (ele._uuid === updateItem._uuid ? { ...ele, status: 2, isEditing: true } : ele)));
  };

  const handleCancelItemUpdate = (updateItem) => {
    setWeatherList(weatherList.map(ele => (ele._uuid === updateItem._uuid ? originalWeatherList.find(ele1 => ele1._uuid === updateItem._uuid) : ele)));
  };

  const handleItemNameChange = (e, el) => {
    setWeatherList(weatherList.map(ele => (ele._uuid === el._uuid ? { ...ele, weather: e.target.value } : ele)));
  };

  const handleItemDelete = (deleteItem) => {
    notifyWarning({ Msg: <DeleteConfirm deleteItem={deleteItem} /> });
  };

  const DeleteConfirm = ({ closeToast, deleteItem }) => (
    <div>
      刪除專案？
      <button className="btnS btnRed" onClick={() => DeleteItem(deleteItem)}>確定刪除</button>
      <button className="btnS btnWhite" onClick={closeToast}>取消</button>
    </div>
  );

  const DeleteItem = async (deleteItem) => {
    if (deleteItem.isExist) {
      // setWeatherList({ weatherList: weatherList.map(ele => (ele._uuid === deleteItem._uuid ? { ...ele, status: 3 } : ele)) });
      // setWeatherList(weatherList.map(ele =>{
      //   ele._uuid === deleteItem._uuid ? { ...ele, status: 3 } : ele;
      // }));
      setWeatherList(weatherList.map(ele => (ele._uuid === deleteItem._uuid ? { ...ele, status: 3 } : ele)));

    } else {
      setWeatherList({ weatherList: weatherList.filter(ele => ele._uuid !== deleteItem._uuid) });
    }
  };

  const ModifyWeatherForm = async () => {
    setIsSaving(true);
    if (weatherList.length >= 0) {
      let weatherModify = [];
      for (let i = 0; i < weatherList.length; i++) {
        weatherModify.push(
          {
            "id": weatherList[i].id || 0,
            "weather": weatherList[i].weather || "",
            "status": weatherList[i].status || 0,
          }
        );
      }
      try {
        await ProjectAPI.ModifyWeatherForm( weatherModify );
        notifySuccess({ Msg: '儲存成功', cbfunc: () => window.location.reload() });
        setIsSaving(false);
      } catch (error) {
        notifyFailed({ Msg: '發生錯誤', isReload: false });
        setIsSaving(false);
      }
    } else {
      notifyFailed({ Msg: '請至少輸入N筆。', isReload: false });
    }
  };

  useEffect(() => {
    GetWeatherForm();
  }, []);

  return (
    <>
      <div className="bumper_rail"></div>
      <div className="commontitle">
        <h1>晴雨表資料管理</h1>
      </div>
      <div className="table">
        <table>
          <tbody>
            <tr>
              <td>項次</td>
              <td>名稱</td>
              <td><button className="btnM btnWhite" onClick={handleItemCreate}>新增</button></td>
            </tr>
            {
              weatherList.length > 0 ? weatherList.filter(ele => ele.status !== 3).map((ele, idx) => {
                return (
                  ele.isExist ?
                    <tr key={ele._uuid}>
                      {ele.isEditing ?
                        (
                          <>
                            <td>{idx + 1}</td>
                            <td><TxtInput value={ele.weather || ''} onChange={(evt) => handleItemNameChange(evt, ele)} /></td>
                          </>
                        ) :
                        (
                          <>
                            <td>{idx + 1}</td>
                            <td>{ele.weather}</td>
                          </>
                        )
                      }
                      <td>
                        {
                          ele.isEditing ?
                            <button className="btnM btnBlue" onClick={() => handleCancelItemUpdate(ele)}>取消</button> :
                            <button className="btnM btnBlue" onClick={() => handleItemUpdate(ele)}>修改</button>
                        }
                        <button className="btnM btnRed" onClick={() => handleItemDelete(ele)}>刪除</button>
                      </td>
                    </tr> :
                    <CreateWeatherItem
                      weatherList={weatherList}
                      item={ele}
                      key={ele._uuid}
                      handleItemNameChange={(evt) => handleItemNameChange(evt, ele)}
                      handleItemDelete={() => handleItemDelete(ele)}
                    />
                );
              })
                : <tr></tr>
              // weatherList.filter(el => el.status !== 3).map((ele, idx) => {
              //   return (
              //     ele.isExist ?
              //       <tr key={ele._uuid}>
              //         {ele.isEditing ?
              //           (
              //             <>
              //               <td>{idx + 1}</td>
              //               <td><TxtInput value={ele.weather || ''} onChange={(evt) => handleItemNameChange(evt, ele)} /></td>
              //             </>
              //           ) :
              //           (
              //             <>
              //               <td>{idx + 1}</td>
              //               <td>{ele.weather}</td>
              //             </>
              //           )
              //         }
              //         <td>
              //           {
              //             ele.isEditing ?
              //               <button className="btnM btnBlue" onClick={() => handleCancelItemUpdate(ele)}>取消</button> :
              //               <button className="btnM btnBlue" onClick={() => handleItemUpdate(ele)}>修改</button>
              //           }
              //           <button className="btnM btnRed" onClick={() => handleItemDelete(ele)}>刪除</button>
              //         </td>
              //       </tr> :
              //       <CreateWeatherItem
              //         weatherList={weatherList}
              //         item={ele}
              //         key={ele._uuid}
              //         handleItemNameChange={(evt) => handleItemNameChange(evt, ele)}
              //         handleItemDelete={() => handleItemDelete(ele)}
              //       />
              //   );
              // })
            }
          </tbody>
        </table>
      </div>
      <ToastContainer />

      <div className="btn_box">
        <button className="btnL btnBlue" onClick={ModifyWeatherForm} disabled={isSaving}>
          {
            isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '存檔'
          }
        </button>
      </div>

    </>
  );
};
export default WeatherManage;