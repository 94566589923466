export const projectReducer = (state, action) => {
  let initProjectInfo = {
    id: 0,
    projectName: '',
    titleAgency: '',
    supervision: '',
    contractSDate: '',
    contractEDate: '',
    executionSDate: '',
    executionEDate: '',
    coverImage: '',
    completedFile: '',
    roleList: [],
    prjectStatus: 0 //專案狀態:1:進行中,2:已竣工
  };
  switch (action.type) {
  case 'SELECT_PROJECT':
    return {...action.project};
  case 'RESET':
    return {...initProjectInfo};
  default:
    return state;
  }
};