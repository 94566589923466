import * as d3 from 'd3';
// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/stacked-bar-chart
export default function StackedHorizontalBarChart(data, {
  x = d => d, // given d in data, returns the (quantitative) x-value
  y = (d, i) => i, // given d in data, returns the (ordinal) y-value
  z = () => 1, // given d in data, returns the (categorical) z-value
  title, // given d in data, returns the title text
  marginTop = 50, // top margin, in pixels
  marginRight = 5, // right margin, in pixels
  marginBottom = 30, // bottom margin, in pixels
  marginLeft = 40, // left margin, in pixels
  width = 640, // outer width, in pixels
  height, // outer height, in pixels
  xType = d3.scaleLinear, // type of x-scale
  xDomain, // [xmin, xmax]
  xRange = [marginLeft, width - marginRight], // [left, right]
  yDomain, // array of y-values
  yRange, // [bottom, top]
  yPadding = 0.1, // amount of y-range to reserve to separate bars
  zDomain, // array of z-values
  offset = d3.stackOffsetDiverging, // stack offset method
  order = d3.stackOrderNone, // stack order method
  xFormat, // a format specifier string for the x-axis
  xLabel, // a label for the x-axis
  colors = d3.schemeTableau10, // array of colors
  stringTranslator,
  xValueColor,
  yValueColor,
  titleText,
  titleTextColor,
  backgroundColor,
  maxHeight = 500,
  textLengthY
} = {}) {
  // Compute values.
  const X = d3.map(data, x);
  const Y = d3.map(data, y);
  const Z = d3.map(data, z);

  // Compute default y- and z-domains, and unique them.
  if (yDomain === undefined) yDomain = Y;
  if (zDomain === undefined) zDomain = Z;
  yDomain = new d3.InternSet(yDomain);
  zDomain = new d3.InternSet(zDomain);

  // Omit any data not present in the y- and z-domains.
  const I = d3.range(X.length).filter(i => yDomain.has(Y[i]) && zDomain.has(Z[i]));

  // If the height is not specified, derive it from the y-domain.
  if (height === undefined) height = yDomain.size * 25 + marginTop + marginBottom;
  if (yRange === undefined) yRange = [height, marginTop];

  // Compute a nested array of series where each series is [[x1, x2], [x1, x2],
  // [x1, x2], …] representing the x-extent of each stacked rect. In addition,
  // each tuple has an i (index) property so that we can refer back to the
  // original data point (data[i]). This code assumes that there is only one
  // data point for a given unique y- and z-value.
  const series = d3.stack()
    .keys(zDomain)
    .value(([, I], z) => X[I.get(z)])
    .order(order)
    .offset(offset)(d3.rollup(I, ([i]) => i, i => Y[i], i => Z[i]))
    .map(s => s.map(d => Object.assign(d, { i: d.data[1].get(s.key) })));

  // Compute the default x-domain. Note: diverging stacks can be negative.
  if (xDomain === undefined) xDomain = d3.extent(series.flat(2));

  // Construct scales, axes, and formats.
  const xScale = xType(xDomain, xRange);
  const yScale = d3.scaleBand(yDomain, yRange).paddingInner(yPadding);
  const color = d3.scaleOrdinal(zDomain, colors);
  const xAxis = d3.axisBottom(xScale).ticks(width / 80, xFormat);
  // const yAxis = d3.axisLeft(yScale).tickSizeOuter(0);
  const yAxis = d3.axisLeft(yScale)
    .tickFormat(d => {
      return d.length > textLengthY ? `${d.slice(0, textLengthY)}...` : d;
    });

  // Compute titles.
  if (title === undefined) {
    const formatValue = xScale.tickFormat(1, xFormat);
    title = i => `${Y[i]}\n${stringTranslator ? stringTranslator[Z[i]] : Z[i]}\n${formatValue(X[i])}`;
  } else {
    const O = d3.map(data, d => d);
    const T = title;
    title = i => T(O[i], i, data);
  }

  const svg = d3.create("svg")
    .attr("width", width + 130)
    .attr("height", height + 80)
    .attr("viewBox", [0, 0, width, height])
    .attr("style", "max-width: 100%;")
    .style("color", xValueColor)
    .style("color", yValueColor)
    .style("background-color", backgroundColor);

  const titleY = 23;
  svg.append("g")
    .call(g => g.append('text')
      .attr("x", "50%")
      .attr("y", titleY)
      .attr("fill", titleTextColor)
      .style("font-size", "20")
      .style("font-weight", "bold")
      .style("text-anchor", "middle")
      .text(titleText));

  const bar = svg.append("g")
    .selectAll("g")
    .data(series)
    .join("g")
    .attr("fill", ([{ i }]) => color(Z[i]))
    .selectAll("rect")
    .data(d => d)
    .join("rect")
    .attr("x", ([x1, x2]) => Math.min(xScale(x1), xScale(x2)))
    .attr("y", ({ i }) => yScale(Y[i])+ 10)
    .attr("width", ([x1, x2]) => Math.abs(xScale(x1) - xScale(x2)))
    .attr("height", 40)
    .style("cursor", "pointer")
    .on("pointerenter", function() {
      d3.select(this).style("opacity", 0.5);
    })
    .on("pointerleave", function() {
      d3.select(this).style("opacity", 1);
    });

  if (title) bar.append("title")
    .text(({ i }) => title(i));

  svg.append("g")
    .selectAll('text')
    .data(series.flat(1))
    .join("text")
    .attr("x", ([x1, x2]) => Math.max(xScale(x1), xScale(x2)))
    .attr("y", ({ i }) => yScale(Y[i])+ 10)
    .attr("dx", -5)
    .attr("dy", 25)
    .style('fill', '#FFF')
    .style('font-size', '1rem')
    .style("text-anchor", 'end')
    .text(function ([x1, x2]) {
      return Math.abs(xScale(x1) - xScale(x2)) > xScale(0)*0.05 ? x2-x1 : '';
    });

  svg.append("g")
    .attr("transform", `translate(${xScale(0)},0)`)
    .call(yAxis)
    .style("font-size", "14")
    .attr("text-anchor", "start")
    .selectAll("text")
    .attr("x", -145)
    .append("g");
    
  let YTooltipText;
  if (YTooltipText === undefined) {
    YTooltipText = i => Y[i];
  } else {
    const O = d3.map(data, d => d);
    YTooltipText = i => title(O[i]);
  }

  svg.append("g")
    .selectAll("g")
    .data(series)
    .join("g")
    .attr("fill", "white")
    .style("opacity", 0)
    .selectAll("rect")
    .data(d => d)
    .join("rect")
    .attr("x", 10)
    .attr("y", ({ i }) => yScale(Y[i]))
    .attr("width", xScale(0) - 27)
    .attr("height", yScale.bandwidth())
    .style("cursor", "pointer")
    .append("title")
    .text(({ i }) => YTooltipText(i));
    
  let x_axis_svg = svg.append("g")
    .attr("id", "d3XLine")
    .attr("transform", `translate(0,${maxHeight - titleY - marginTop})`);
  x_axis_svg.append("rect")
    .attr("fill", backgroundColor)
    .attr("x", 0)
    .attr("y", 0)
    .attr("width", width)
    .attr("height", marginTop);

  let x_axis = x_axis_svg.call(xAxis);
  // x_axis.call(g => g.select(".domain").remove())
  x_axis.call(g => g.selectAll(".tick line").clone()
    .attr("y2", height - marginTop - marginBottom)
    .attr("stroke-opacity", 0))
    .call(g => g.append("text")
      .attr("x", width - marginRight)
      .attr("y", -22)
      .attr("fill", "currentColor")
      .attr("text-anchor", "end")
      .text(xLabel));

  return {
    chart: Object.assign(svg.node(), { scales: { color } }),
    x_axis
  };
}