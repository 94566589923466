// import TableauDashboard from './TableauDashboard';
// import tools from '../assets/tools';
import { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import ConImg from '../../images/材料.png';
import { Link } from "react-router-dom";

// import { D3BarChart } from '../assets/d3components/BarChart';
import { D3StackedBarChart } from '../assets/d3components/StackedBarChart';
import { D3Swatches, D3StackedHorizontalBarChart } from '../assets/d3components';
// import colorSchemes from './../assets/d3components/config/scale-chromatic';
// import alphabat from "../assets/d3components/data/alphabat.json";
// import population from "../assets/d3components/data/population.json";
// import states from "../assets/d3components/data/states.json";

import { SwitchMode } from './SwitchMode';
import { ThemeContext } from './../contexts/ThemeContext';
import { StatisticAPI, HelperAPI } from './../assets/api';
// import html2canvas from 'html2canvas';
import { useLocalStorage } from './useLocalStorage';
import { ChartDownloadButton } from './DownloadImageButton';
import { ExportToExcelButton } from './ExportToExcel';
import { useIsFirstRender } from './../assets/customHooks/useIsFirstRender';


const HalfRight = styled.div`
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  .btnBox{
    display: flex;
    justify-content: space-between;
    margin: 5px 0 0 0;
    .fakeBtn{
      text-decoration: none;
      width:32%;
      text-align: center;
      padding: 10px 0;
      color: ${props => props.isDarkMode ? '#e6e6e6' : '#0c0c0c'};
      background-color: ${props => props.isDarkMode ? '#444' : '#fff'};
      display: flex;
      justify-content: center;
      align-items: center;
      &.fakeActive {
        border: 1px solid #F28E2B;
        color: ${props => props.isDarkMode ? '#F28E2B' : '#fff'};
        background-color: ${props => props.isDarkMode ? '#444' : '#F28E2B'};
        cursor: unset;
      }
    }
  }
`;
const Bigboard = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
div{
  box-sizing: border-box;
}
.halfLeft{
  // border:1px solid #F00;
  padding: 10px 10px 0px 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 1300px) {
    width: 100%;
  }
  .divFlow{
    margin: 0 0 10px 0;
    padding: 10px;
    &.conTable{
      width: calc(65% - 5px);
      @media (max-width: 960px) {
        width: 100%;
      }
      .ScrollbarDiv{
        margin-bottom: 20px;
        max-height: 463px;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background-color: #DDD;
        }
        /* table{
          tbody{
            tr{
              max-height: 52px;
            }
          }
        } */
      }
    }
    &.conVerbar{
      width: calc(35% - 5px);
      /* overflow: hidden; */
      @media (max-width: 960px) {
        width: 100%;
      }
    }
    &.conHorbar{
      width: 100%;
    }
  }
}
.halfRight{
  // border:1px solid #00F;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  @media (max-width: 1300px) {
    width: 100%;
  }
  .imgBox{
    padding: 10px;
    img{
      width: 100%;
    }
  }
  .btnBox{
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px 0;
    .fakeBtn{
      text-decoration: none;
      width:32%;
      text-align: center;
      padding: 10px 0;
      color:#FFF;
      display: flex;
      justify-content: center;
      align-items: center;
      &.fakeActive{
        border: 1px solid #F28E2B;
        color:#F28E2B !important;
        cursor: unset;
      }
    }
  }
}
`;
const NoDataText = styled.div`
text-align: center;
font-size: 20px;
color: ${props => props.isDarkMode ? '#e6e6e6' : '#7b7979'};
`;

const colorTheme = {
  light: {
    titleTextColor: "black",
    xValueColor: "black",
    yValueColor: "black",
    backgroundColor: "#FFF",
    color: "#65BAAA",
    // titleAltColor: "white",
  },
  dark: {
    titleTextColor: "white",
    xValueColor: "white",
    yValueColor: "white",
    backgroundColor: "#444",
    color: "#222",
    // titleAltColor: "#222",
  }
};

const DashboardMain = () => {
  const isFirstRender = useIsFirstRender();
  // const url = tools.CheckEnv() === 'prod' ?
  //   'https://bi3.stantec.com.tw/views/DashboardProd/sheet43?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link' :
  //   'https://bi3.stantec.com.tw/views/DashboardNew/sheet25?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link';

  // const [isDarkMode, setIsDarkMode] = useState(true);
  // const [isDarkModeScheme, setIsDarkModeScheme] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches);
  // console.log(setIsDarkMode + setIsDarkModeScheme);

  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark' ? true : false;
  const tableClass = isDarkMode ? "table_dark" : "table_light";

  const [projectList, setProjectList] = useState([]); //專案清單
  const [projectId, setProjectId] = useLocalStorage("projectId", projectList.length ? projectList[0].id : 0);

  const [eqFormDataList, setEqFormDataList] = useState([]); //左上表格
  const [stackBarData, setStackBarData] = useState([]); //
  const [stackHorBarData, setStackHorBarData] = useState([]); //


  //取得專案下拉清單
  const GetStaticProjectList = async () => {
    try {
      let data = await HelperAPI.GetStaticProjectList();
      setProjectList(data.projectList);
      return data.projectList;
    } catch (error) {
      console.log(error);
    }
  };

  const [stackedBarChartOption, setStackedBarChartOption] = useState({
    chartId: "stackedBarChartId",
    downloadAreaId: "stackedBarChart",
    titleText: "",
    xAttr: 'state',
    yAttr: 'population',
    zAttr: 'age',
    yLabel: '',
    colorCustom: ['#4e79a7', '#f28e2c',],
    // colorInterpolate: 'interpolateTurbo',
    // colorInterpolate: colorSchemes.sequentialMulti.interpolateCividis,
    color: isDarkMode ? "#FFF" : "#65BAAA",
    titleAltColor: isDarkMode ? "#2f2f2f" : "white",
    xValueColor: isDarkMode ? "white" : "black",
    yValueColor: isDarkMode ? "white" : "black",
    backgroundColor: isDarkMode ? "#444" : "#FFF",
    stringTranslator: { 'qualified': '合格', 'unqualified': '不合格' }
  });
  const StackedHOrizontalBarChartOption = {
    chartId: "stackedHorBarChartId",
    downloadAreaId: "stackedHorBarChart",
    titleText: "",
    titleTextColor: "blue",
    xAttr: 'population',
    yAttr: 'state',
    zAttr: 'age',
    yLabel: 'Population (millions) →',
    // maxHeight: 500,
    xValueColor: isDarkMode ? "white" : "black",
    yValueColor: isDarkMode ? "white" : "black",
    colorCustom: ['#4e79a7', '#f28e2c',],
    // colorInterpolate: 'interpolateTurbo',
    // colorScheme: colorSchemes.categorical.schemeAccent,
    // colorInterpolate: colorSchemes.sequentialMulti.interpolateCividis,
    stringTranslator: { 'qualified': '合格', 'unqualified': '不合格' },
    marginLeft: 150,
    marginRight: 10,
    backgroundColor: isDarkMode ? "#444" : "#FFF",
    color: isDarkMode ? "#FFF" : "#65BAAA",
    titleAltColor: isDarkMode ? "#2f2f2f" : "white",
  };

  const StackedBarLegendOption = {
    colorCustom: ['#4e79a7', '#f28e2c',],
    // colorInterpolate: 'interpolateTurbo',
    // colorInterpolate: colorSchemes.sequentialMulti.interpolateCividis,
    titleText: "標題標題",
    titleTextColor: "blue",
    backgroundColor: isDarkMode ? "#444" : "#FFF",

  };
  const StackedHOrizontalBarLegendOption = {
    colorCustom: ['#4e79a7', '#f28e2c',],
    // colorInterpolate: colorSchemes.sequentialMulti.interpolateCividis,
    titleText: "標題標題",
    titleTextColor: "blue",
    backgroundColor: isDarkMode ? "#444" : "#FFF",
  };

  const isQualified = ["qualified", "unqualified",];

  const cubeTranslator = { qualified: '合格', unqualified: '不合格' };
  const isQualifiedcube = isQualified.map(ele => cubeTranslator[ele]);

  const handleProjectSelect = (e) => {
    let value = projectList.find(ele => ele.id === Number(e.target.value));
    setProjectId(value.id);
  };
  const GetEQFormRecord = async (projectId) => {
    try {
      let data = await StatisticAPI.GetEQFormRecord(projectId);
      setEqFormDataList(data.eqFormDataList);

      // console.log(eqFormDataList);

      const sdata = isQualified.flatMap(qualified => data.eqFormDataList.map(d => ({ state: d.projectFormCategoryName, age: qualified, population: d[qualified] })));
      const filteredData = sdata.filter((d)=> ( d.state!="總抽查驗次數"));
      setStackBarData(filteredData);

      const stackhordata = isQualified.flatMap(isqualified => data.eqFormNameDataList.map(d => ({ state: d.formName, age: isqualified, population: d[isqualified] })));
      setStackHorBarData(stackhordata);

    } catch (error) {
      console.log(error);
    }
  };

  // const ages = ["<10", "10-19", "20-29", "30-39", "40-49", "50-59", "60-69", "70-79", "≥80"];
  // const stateages = ages.flatMap(age => states.map(d => ({ state: d.name, age, population: d[age] })));


  useEffect(() => {
    setStackedBarChartOption({
      ...stackedBarChartOption,
      ...colorTheme[isDarkMode ? "dark" : "light"]
    });
  }, [isDarkMode]);

  useEffect(async() => {
    let staticProjectList = await GetStaticProjectList();
    if (projectId === 0) {
      setProjectId(staticProjectList[0].id);
    } else {
      GetEQFormRecord(projectId);
    }
  }, []);

  useEffect(() => {
    if (!isFirstRender) {
      GetEQFormRecord(projectId);
    }
  }, [projectId]);

  return (
    <>
      <SwitchMode />
      <div className="containerBox" style={{ backgroundColor: isDarkMode ? '#666' : '#F1F1F1' }}>
        <section className="dashboardContent">
          <HalfRight isDarkMode={isDarkMode}>
            <div className="btnBox">
              <Link to={{ pathname: `dashboard-new-construction` }} className="fakeBtn">施工品質查驗紀錄</Link>
              <Link to={{ pathname: `dashboard-new-material` }} className="fakeBtn fakeActive">材料設備品質查驗</Link>
              <Link to={{ pathname: `dashboard-new-abnormal` }} className="fakeBtn">品質缺失改善追蹤</Link>
            </div>
          </HalfRight>
          <div className="dashboardUpArea">
            {/* <div className="dashboardUpArea_left">
              <div className="dashboardTitle" style={{ backgroundColor: isDarkMode ? '' : '#F1F1F1' }}>
                <h1>大漢溪埔頂排水水環境改善計畫</h1>
              </div>
              <div className="subTitle" style={{ color: isDarkMode ? '#E6E6E6' : '#999' }}>
                <h1>品質查驗紀錄</h1>
              </div>
            </div> */}
            <div className="projectWrap" style={{ backgroundColor: isDarkMode ? '#555' : '#DDD' }}>
              <h3 style={{ color: isDarkMode ? '#e6e6e6' : '#000' }}>專案名稱</h3>
              <div className="dropdownBox">
                <select
                  className="dropdownMenu" style={{ backgroundColor: isDarkMode ? '#BCBCBC' : '#FFF' }}
                  onChange={(e) => handleProjectSelect(e)}
                  value={projectId}
                >
                  {
                    projectList.map(ele => (
                      <option key={ele.id} value={ele.id}>{ele.projectName}</option>
                    ))
                  }
                </select>
              </div>
            </div>
          </div>

          <Bigboard>
            <div className="halfLeft">
              <div className="divFlow conTable" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF', position: 'relative' }}>
                <div className={`${tableClass} ScrollbarDiv`} id="materialInspectionTable">
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ minWidth: '2em', }}>分類</td>
                        <td>累計查驗</td>
                        <td>不合格(數量)</td>
                        <td>合格(數量)</td>
                        <td>不合格(率)</td>
                        <td>合格(率)</td>
                        {/* <td>不定期</td> */}
                      </tr>
                      {
                        eqFormDataList.map((ele, idx) => {
                          return (
                            <tr key={idx} id="tableRow">
                              <td>{ele.projectFormCategoryName}</td>
                              <td>{ele.num}</td>
                              <td>{ele.unqualified}</td>
                              <td>{ele.qualified}</td>
                              <td>{ele.unQualifiedRate}%</td>
                              <td>{ele.qualifiedRate}%</td>
                              {/* <td>{ele.irregular}</td> */}
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </div>
                <ExportToExcelButton tableId ="materialInspectionTable" tableName="材料設備品質查驗"/>
              </div>

              <div className="divFlow conVerbar" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF', position: 'relative' }}>
                {
                  stackBarData.length > 0 ? 
                    <div id={stackedBarChartOption.downloadAreaId} className="chartBlock" style={{ color: isDarkMode ? '#FFF' : '#000' }}>
                      <D3StackedBarChart data={stackBarData} group={isQualified} option={stackedBarChartOption} /> 
                      <D3Swatches group={isQualifiedcube} option={StackedBarLegendOption} />
                    </div>
                    : <NoDataText isDarkMode={isDarkMode}>當前專案無資料</NoDataText>
                }
                {/* <DownloadBtnBox>
                  <button className="chartDownloadBtn" onClick={() => downloadImage(stackedBarChartOption.chartId, stackedBarChartOption.downloadAreaId, stackedBarChartOption.titleText)}>Download</button>
                </DownloadBtnBox> */}
                <ChartDownloadButton
                  chartId={stackedBarChartOption.chartId}
                  downloadAreaId={stackedBarChartOption.downloadAreaId}
                  chartName={stackedBarChartOption.titleText}
                  stackedBarChartOption= {stackedBarChartOption}
                  dataLength={stackBarData.length}
                />
              </div>

              <div className="divFlow conHorbar" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF', position: 'relative' }}>
                {
                  stackHorBarData.length > 0 ?
                    <div id={StackedHOrizontalBarChartOption.downloadAreaId} className="chartBlock" style={{ color: isDarkMode ? '#FFF' : '#000' }}>
                      <D3StackedHorizontalBarChart data={stackHorBarData} group={isQualified} option={StackedHOrizontalBarChartOption} />
                      <D3Swatches group={isQualifiedcube} option={StackedHOrizontalBarLegendOption} />
                    </div>
                    : <NoDataText isDarkMode={isDarkMode}>當前專案無資料</NoDataText>
                }
                {/* <DownloadBtnBox>
                  <button className="chartDownloadBtn" onClick={() => downloadImage(StackedHOrizontalBarChartOption.chartId, StackedHOrizontalBarChartOption.downloadAreaId, StackedHOrizontalBarChartOption.titleText, stackBarData.length)}>Download</button>
                </DownloadBtnBox> */}
                <ChartDownloadButton
                  chartId={StackedHOrizontalBarChartOption.chartId}
                  downloadAreaId={StackedHOrizontalBarChartOption.downloadAreaId}
                  chartName={StackedHOrizontalBarChartOption.titleText}
                  dataLength={stackBarData.length/2}
                />
              </div>

            </div>
            <div className="halfRight">
              {/* <div className="btnBox">
                <Link to={{ pathname: `dashboard-new-construction` }} className="fakeBtn" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF', color: isDarkMode ? '#FFF' : '#666' }}>施工品質查驗</Link>
                <Link to={{ pathname: `dashboard-new-material` }} className="fakeBtn fakeActive" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF'}}>材料設備品質查驗</Link>
                <Link to={{ pathname: `dashboard-new-abnormal` }} className="fakeBtn" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF', color: isDarkMode ? '#FFF' : '#666' }}>品質缺失改善追蹤</Link>
              </div> */}
              <div className="imgBox" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF' }}>
                <img src={ConImg} />
              </div>
            </div>
          </Bigboard>
        </section>
      </div>

      <br /><br /><br />
      {/* <TableauDashboard url={url}/> */}
    </>
  );
};
export default DashboardMain;