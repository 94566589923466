import { useEffect } from 'react';
import styled from '@emotion/styled';

const DotCon = styled.div`
  background-color: #FFFA;
  padding: 10px;
  position: absolute;
  bottom: 25px;
  left: 10px;
  .ColorItem{
    font-size: 14px;
    div{
      display: inline-block;
    }
    .ColorDot{
      width: 12px;
      height: 12px;
      background-color: #CCC;
      margin: 2px 5px 2px 0;
      position: relative;
      top: 3px;
      border-radius: 50%;
    }
    .dotgreen{background-color: #398949;}
    .dotred{background-color: #FFBEB2;}
    .dotblue{background-color: #4E79A7;}
    :hover{
      cursor: pointer;
    }
  }
`;

let gMap;
let gMapEX;
let DataMarkerList = [];
let LocationMarker;
let CurrData = null;
let TotalCoordinateList = [];
let MarkerClusterLayer = null;

function Map(props) {

  // const projectBasicAddressList = [
  //   { ApplyName: "testUser2", Longitude: 121.497032, Latitude: 25.0262852, progress: 0 },
  //   { ApplyName: "", Longitude: 121.549942, Latitude: 25.0575256, progress: 1 },
  //   { ApplyName: "testUser2", Longitude: 121.497032, Latitude: 25.0262852, progress: 2 },
  //   { ApplyName: "", Longitude: 121.549942, Latitude: 25.0575256, progress: 0 },
  //   { ApplyName: "綠綠", Longitude: 121.54407739557887, Latitude: 25.052504433039132, progress: 0 },
  //   { ApplyName: "紅紅", Longitude: 121.52042322967792, Latitude: 25.052721261307617, progress: 1 },
  //   { ApplyName: "藍藍", Longitude: 121.55035975762551, Latitude: 25.041491476199198, progress: 2 },
  //   { ApplyName: "墾丁", Longitude: 120.79748932123259, Latitude: 21.942506532279154, progress: 2 },
  // ];

  const projectBasicAddressList = props.frontPage;

  // const [projectBasicAddressList, setProjectBasicAddressList] = useState([]);

  // const SetList = async () => {
  //   // console.log(props.frontPage);
  //   let res = props.frontPage.map(ele => ({
  //     ...ele,
  //     ApplyName: ele.projectShortName,
  //     progress: ele.projectShortName,
  //     Longitude: ele.lng,
  //     Latitude: ele.lat,
  //   }));
  //   setProjectBasicAddressList(res);
  // };
  //專案進度(0:維持;1:超前;2:落後)


  const InitMap = async () => {

    gMap = new window.google.maps.Map(document.getElementById(`map`), {
      center: {
        lat: 24.085,
        lng: 120.418
      },
      zoom: 6.8,
      maxZoom: 16,
      zoomControl: false,// >>> 後續若一定要到最新版，則隱藏zoom 控制項 讓街景控制項 下來
      scaleControl: true,
      streetViewControl: true,
      mapTypeControl: false,
      fullscreenControl: false,
      clickableIcons: false,//google 內建POI 設為無法點擊
      gestureHandling: `greedy`//取消 按 ctrl 才能縮放,
    });

    //初始化 gMapEX 強化模組
    gMapEX = new window.GMapEX(gMap);

    window.google.maps.event.addListenerOnce(gMap, 'idle', function () {

      console.log(props.frontPage);
      ShowDataListOnMap(props.frontPage);
    });

    // document.querySelector(`#pink`).addEventListener(`click`, () => {
    //   ShowDelayDotOnMap(projectBasicAddressList);
    // });
  };


  //顯示資料到地圖
  function ShowDataListOnMap(dataList) {

    //清除所有點位
    ClearDataMarkerList();

    dataList.forEach(dataObj => {
      const marker = DrawDataMarker(dataObj);

      DataMarkerList.push(marker);
      TotalCoordinateList.push(new window.google.maps.LatLng(dataObj.Latitude, dataObj.Longitude));
    });

    MarkerClusterLayer = gMapEX.ShowMarkerClusterLayer({
      Markers: DataMarkerList,
      GridSize: 50,
      MaxZoom: 13
    });

    // console.log(gMapEX.Map.getMapTypeId().toString());
    // console.log(gMapEX.Map.mapTypes.get(gMapEX.Map.getMapTypeId().toString()));

    // 設定檢視區包含指定的界限。
    gMapEX.FitBounds(TotalCoordinateList);
  }

  //畫 資料 Marker
  function DrawDataMarker(dataObj) {
    const Position = new window.google.maps.LatLng(dataObj.Latitude, dataObj.Longitude);

    let color;
    switch (dataObj.progress) {
    case 1:
      color = '#398949';
      break;
    case 2:
      color = '#FFBEB2';
      break;
    case 0:
      color = '#4E79A7';
    }

    const marker = gMapEX.DrawMarker({
      Position,
      IconOptions: {
        path: window.google.maps.SymbolPath.CIRCLE,
        scale: 6,
        strokeColor: color,
        // fillColor: color,
        // strokeColor: `#00695c`,
        // url: 'https://cdn-icons-png.flaticon.com/512/660/660623.png',
        scaledSize: new window.google.maps.Size(30, 30)
      }
    });
    marker[`dataObj`] = dataObj;

    window.google.maps.event.addListener(marker, `click`, () => {
      LocateData(dataObj);
    });

    return marker;
  }

  const ShowSPDotOnMap = async (num) => {

    //清除所有點位
    ClearDataMarkerList();

    // console.log(num);

    projectBasicAddressList.filter(d => d.progress === num).forEach(dataObj => {
      const marker = DrawDataMarker(dataObj);

      DataMarkerList.push(marker);
      TotalCoordinateList.push(new window.google.maps.LatLng(dataObj.Latitude, dataObj.Longitude));
    });

    MarkerClusterLayer = gMapEX.ShowMarkerClusterLayer({
      Markers: DataMarkerList,
      GridSize: 50,
      MaxZoom: 13
    });

    // 設定檢視區包含指定的界限。
    gMapEX.FitBounds(TotalCoordinateList);
  };

  //定位
  function LocateData(dataObj) {
    //把上一個定位的點位復原顯示
    if (CurrData !== null) {
      const prevMarker = DataMarkerList.filter(x => x.dataObj === CurrData)[0];
      prevMarker.setMap(gMap);
    }

    CurrData = dataObj;

    //把定位道的點位隱藏
    const currMarker = DataMarkerList.filter(x => x.dataObj === CurrData)[0];
    currMarker.setMap(null);

    //定位 至 點位
    DrawLocationMarker(dataObj.Longitude, dataObj.Latitude);

    //顯示 Marker Infowindow
    gMapEX.ShowInfowindow({
      Content: `
          <table>
            <tr style="border-bottom:1px solid #CCC;">
              <th>專案案號 / 簡稱</th>
              <td style="padding: 0 3px 0 5px;">${dataObj.ApplyName}</td>
            </tr>
            <tr style="border-bottom:1px solid #CCC;">
              <th>超前落後顯示文字</th>
              <td style="padding: 0 3px 0 5px;">${dataObj.progressTXT}</td>
            </tr>
            <tr style="border-bottom:1px solid #CCC;">
              <th>Lat</th>
              <td style="padding: 0 3px 0 5px;">${dataObj.Latitude}</td>
            </tr>
            <tr style="border-bottom:1px solid #CCC;">
              <th>Lng</th>
              <td style="padding: 0 3px 0 5px;">${dataObj.Longitude}</td>
            </tr>
          </table>
        `,
      Anchor: currMarker
    });
  }

  //畫定位點
  function DrawLocationMarker(lng, lat) {
    if (typeof LocationMarker !== `undefined` && LocationMarker !== null)
      LocationMarker.setMap(null);

    const Position = new window.google.maps.LatLng(lat, lng);

    //設立定位點
    LocationMarker = gMapEX.DrawMarker({
      Position,
      IconOptions: {
        url: `https://cdn-icons-png.flaticon.com/512/6747/6747693.png`,
        scaledSize: new window.google.maps.Size(40, 40)
      }
    });

    gMap.panTo(Position);
  }

  //清除 所有 資料 Marker
  const ClearDataMarkerList = () => {
    if (MarkerClusterLayer !== null) {
      MarkerClusterLayer.clearMarkers();
      MarkerClusterLayer = null;
    }

    gMapEX.ClearAllMarker();

    DataMarkerList = [];
    TotalCoordinateList = [];
    CurrData = null;
  };

  useEffect(() => {
    // SetList();
    InitMap();
    // SetList().then(() => InitMap());
  }, [props.frontPage]);

  return (
    <div className="mapWrap" style={{ position: 'relative' }}>
      <div id="map" style={{ height: '860px', }}>123</div>
      <DotCon>
        <div className="ColorItem" onClick={() => ShowSPDotOnMap(0)}>
          <div className="ColorDot dotblue"></div>
          <div className="">及時</div>
        </div>
        <div className="ColorItem" onClick={() => ShowSPDotOnMap(1)}>
          <div className="ColorDot dotgreen"></div>
          <div className="">超前</div>
        </div>
        {/* <div className="ColorItem" id="pink"> */}
        <div className="ColorItem" onClick={() => ShowSPDotOnMap(2)}>
          <div className="ColorDot dotred"></div>
          <div className="">落後</div>
        </div>
        <div className="ColorItem" onClick={() => InitMap()}>
          <div className="ColorDot"></div>
          <div className="">全部</div>
        </div>
      </DotCon>
    </div>
  );
}

export default Map;
