import { createContext, useEffect, useReducer } from 'react';
import { projectReducer } from './../reducers/projectReducer';
import { encode, decode } from 'js-base64';

export const ProjectContext = createContext();

const ProjectContextProvider = (props) => {
  const [projectInfo, dispatch] = useReducer(projectReducer, {
    id: 0,
    projectName: '',
    titleAgency: '',
    supervision: '',
    contractSDate: '',
    contractEDate: '',
    executionSDate: '',
    executionEDate: '',
    coverImage: '',
    completedFile: '',
    roleList: [],
    prjectStatus: 0 //專案狀態:1:進行中,2:已竣工
  }, () => {
    const localData = window.localStorage.getItem('projectInfo');
    return localData ? JSON.parse(decode(localData)) : {
    // return localData ? JSON.parse(localData) : {
      id: 0,
      projectName: '',
      titleAgency: '',
      supervision: '',
      contractSDate: '',
      contractEDate: '',
      executionSDate: '',
      executionEDate: '',
      coverImage: '',
      completedFile: '',
      roleList: [],
      prjectStatus: 0 //專案狀態:1:進行中,2:已竣工
    };
  });

  useEffect(() => {
    window.localStorage.setItem('projectInfo', encode(JSON.stringify(projectInfo)));
    // window.localStorage.setItem('projectInfo', JSON.stringify(projectInfo));
  }, [projectInfo]);

  return (
    <ProjectContext.Provider value={{ projectInfo, dispatch }}>
      {props.children}
    </ProjectContext.Provider>
  );
};

export default ProjectContextProvider;