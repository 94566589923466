import { useEffect, useState } from "react";
import styled from '@emotion/styled';
import "./../../style/Form.scss";
import Pagination from './../assets/Pagination';
import { SupervisionAPI, DocumentAPI } from './../assets/api';
import { notifyFailed } from './../assets/toaster';
import tools from "../assets/tools";
import moment from "moment";
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

const TxtInput = styled.input`
    padding: 0 4px;
    box-sizing: border-box;
    height: 34px;
    border-radius: 3px;
    border: 1px solid #999;
    min-width: 15em;
  `;
const FilterTable = styled.div`
    margin: 32px 0;
    .queryMenu_btnbox{
      margin: 16px 0 8px 0;
    }
  `;

const NoteDiv = styled.div`
  div{
    display: inline-block;
    padding: 3px;
  }
  .form_title{
    width: 11rem;
    text-align: right;
    vertical-align: top;
    padding: 5px 0px 5px 10px;
    @media (max-width: 1025px) {
      width: 100%;
      text-align: left;
    }
  }
  .form_input{
    width: calc(100% - 18rem);
    text-align: left;
    vertical-align: middle;
    overflow: auto;
    input[type="text"] {
      height: 28px;
      border-radius: 3px;
      border: 1px solid #999;
    }
    input[type="number"] {
      height: 28px;
      border-radius: 3px;
      border: 1px solid #999;
    }
    input[type="radio"] {
      margin: 0px 3px 8px 0px;
    }
    padding: 5px 0px 8px 12px;
  }
  .form_input_table{
    width: calc(100%);
    text-align: left;
    vertical-align: middle;
    overflow: auto;
    input[type="text"] {
      height: 28px;
      border-radius: 3px;
      border: 1px solid #999;
    }
    input[type="number"] {
      height: 28px;
      border-radius: 3px;
      border: 1px solid #999;
    }
    input[type="radio"] {
      margin: 0px 3px 8px 0px;
    }
    padding: 5px 0px 8px 12px;
  }
  table{
      width: 99%;
      text-align: center;
      box-shadow: 0 1px 3px 0px #aaaa;
      tr{
        box-shadow: 0 1px 2px 0px #CCCC;
        &:first-of-type{
          background-color: #E3E3E3;
          box-shadow: 0 1px 2px 0px #BBBB;
          font-size: 1.1rem;
        }
        &:not(:first-of-type):hover{
          background-color: #CAC19025;
        }
      }
      td{
        padding: 1rem 10px;
        overflow-wrap: anywhere;
        vertical-align: middle;
        input[type="text"] {
          height: 28px;
          width: 100px;
          border-radius: 3px;
          border: 1px solid #999;
        }
        input[type="number"] {
          height: 28px;
          width: 80px;
          border-radius: 3px;
          border: 1px solid #999;
        }
        input[type="radio"] {
          margin: 0px 3px 8px 0px;
        }
      }
    }
    @media (max-width: 1025px) {
      width: calc(100% - 20px);
    }
`;

const MonthlyReport = () => {

  const [totalPages, setTotalPages] = useState(1); // 要到的資料總頁數
  const [currentPage, setCurrentPage] = useState(1); // 目前所在頁

  const [projectMonthlyList, setProjectMonthlyList] = useState([]);
  // 取得月報清單
  const [projectMonthlySearch, setProjectMonthlySearch] = useState({
    projectNum: "",
    projectName: "",
    titleAgency: "",
    examineSDate: "",
    examineEDate: "",
    formStatus: 0,
    page: 1
  });

  const PostProjectMonthlyFormList = async(projectMonthlySearch) => {
    try {
      let data = await SupervisionAPI.PostProjectMonthlyFormList(projectMonthlySearch);
      if (data) setProjectMonthlyList(data.projectMonthlyList);
      setTotalPages(data.pageInfo.pages);
      setCurrentPage(1);
    } catch (error) {
      if (error.response.data === "查無專案資料。") { notifyFailed({ Msg: error.response.data, isReload: false }); }
      else {
        notifyFailed({Msg: '發生錯誤', isReload: false});
      }
    }
  };

  useEffect(async ()=>{
    await PostProjectMonthlyFormList(projectMonthlySearch);
  }, []);

  const handleProjectMonthlySearchChange = (e) => {
    setProjectMonthlySearch({
      ...projectMonthlySearch,
      [e.target.name]: e.target.value
    });
  };

  const handleSearch = async() => {
    await PostProjectMonthlyFormList(projectMonthlySearch);
  };

  // 匯出PDF
  const ExportPDF = async (expoForm, module) => {
    const setListItemTrue = async (setList, list) => {
      setList(list.map(ele => {
        return (
          ele._uuid === expoForm._uuid ? {
            ...ele,
            IsPDFExporting: true
          } : ele
        );
      }));
    };
    const setListItemFalse = async (setList, list) => {
      setList(list.map(ele => {
        return (
          ele._uuid === expoForm._uuid ? {
            ...ele,
            IsPDFExporting: false
          } : ele
        );
      }));
    };
    switch (module) {
    case 'MonthlyForm':
      setListItemTrue(setProjectMonthlyList, projectMonthlyList);
      await CreatePdf(expoForm, module);
      setListItemFalse(setProjectMonthlyList, projectMonthlyList);
      break;
    default:
      break;
    }
  };
  const CreatePdf = async (expoForm, module) => {
    switch (module) {
    case 'MonthlyForm':
      try {
        let data = await DocumentAPI.CreateSMPdf(expoForm.id);
        tools.AjaxFileDownload({ rs: data, filename: `專案工作月報表 - ${expoForm.formID}`, fileType: 'pdf'});
      } catch (error) {
        notifyFailed({ Msg: '發生錯誤，匯出文件失敗', isReload: false });
      }
      break;
    default:
      break;
    }
  };
  return (
    <>
      <div className="bumper_rail"></div>
      <div className="commontitle">
        <h1>月報總覽列表</h1>
      </div>
      <FilterTable className="filter_table">
        <div className="">
          報表月份：
          <TxtInput type="month" style={{ minWidth: '5em', margin: '4px 0' }} name='examineSDate' onChange={handleProjectMonthlySearchChange} />
          <span style={{ margin: '0 3px', }}>-</span>
          <TxtInput type="month" style={{ minWidth: '5em', margin: '4px 0' }} name='examineEDate' onChange={handleProjectMonthlySearchChange} />
        </div>
        <br />
        <div className="">
          專案案號：
          <TxtInput type="text" name="projectNum" onChange={handleProjectMonthlySearchChange} />
        </div>
        <br />
        <div className="">
          專案名稱：
          <TxtInput type="text" name="projectName" onChange={handleProjectMonthlySearchChange} />
        </div>
        <br />
        <div className="">
          主辦機關：
          <TxtInput type="text" name="titleAgency" onChange={handleProjectMonthlySearchChange} />
        </div>
        <div className="queryMenu_btnbox" style={{width: '100%', textAlign: 'right', }}>
          <button className="btnL btnBlue" onClick={handleSearch} >搜尋</button>
        </div>
      </FilterTable>
      <NoteDiv>
        <table className='table'>
          <tbody>
            <tr>
              <td>專案案號</td>
              <td>專案簡稱</td>
              <td>報表編號</td>
              <td>新增日期</td>
              <td>最後更新日期</td>
              <td></td>
            </tr>
            {
              projectMonthlyList.map((ele) => (
                <tr key={tools._UUID()}>
                  <td>{ele.projectNum}</td>
                  <td>{ele.projectName}</td>
                  <td><Link to={{ pathname: `monthly-detail/${ele.projectID}/${ele.id}` }}>{ele.formID}</Link></td>
                  <td>{moment(ele.createDate).format('YYYY-MM-DD')}</td>
                  <td>{ ele.modifyDate!= null ? moment(ele.modifyDate).format('YYYY-MM-DD') : moment(ele.createDate).format('YYYY-MM-DD')}</td>
                  <td>
                    <button className="btnS btnBlue" onClick={() => ExportPDF(ele, 'MonthlyForm')}>
                      {
                        ele.IsPDFExporting === true ?
                          <div>
                            匯出中，請稍後...
                            <i className="fas fa-circle-notch fa-spin"></i>
                          </div> : '匯出PDF'
                      }
                    </button>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </NoteDiv>
      <Pagination
        setTotalPages={setTotalPages}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        setList={setProjectMonthlyList}
        projectMonthlySearch={projectMonthlySearch}
        apiFunc={'PostProjectMonthlyFormList'}
      />
      <ToastContainer />
    </>
  );
};

export default MonthlyReport;