import API from "./ApiConfig";

const DocumentAPI = {
  // 建立施工抽查紀錄表PDF
  CreateCEPdf: (mainId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Document/CreateCEPdf/${mainId}`, {
        responseType: 'arraybuffer',
        headers: {
          'Accept': 'application/pdf'
        }
      })
        .then(rs => {
          resolve(rs);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 建立施工查驗照片紀錄表PDF
  CreateCEPhotoPdf: (mainId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Document/CreateCEPhotoPdf/${mainId}`, {
        responseType: 'arraybuffer',
        headers: {
          'Accept': 'application/pdf'
        }
      })
        .then(rs => {
          resolve(rs);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 建立設備功能運轉測試紀錄表PDF
  CreateEFPdf: (mainId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Document/CreateEFPdf/${mainId}`, {
        responseType: 'arraybuffer',
        headers: {
          'Accept': 'application/pdf'
        }
      })
        .then(rs => {
          resolve(rs);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 建立材料設備品質抽驗紀錄表PDF
  CreateEQPdf: (mainId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Document/CreateEQPdf/${mainId}`, {
        responseType: 'arraybuffer',
        headers: {
          'Accept': 'application/pdf'
        }
      })
        .then(rs => {
          resolve(rs);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 建立一般安全衛生查驗表PDF
  CreateHSPdf: (mainId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Document/CreateHSPdf/${mainId}`, {
        responseType: 'arraybuffer',
        headers: {
          'Accept': 'application/pdf'
        }
      })
        .then(rs => {
          resolve(rs);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 建立異常不合格通知改善表PDF
  CreateANPdf: (mainId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Document/CreateANPdf/${mainId}`, {
        responseType: 'arraybuffer',
        headers: {
          'Accept': 'application/pdf'
        }
      })
        .then(rs => {
          resolve(rs);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 建立監造日報表PDF
  CreateSDPdf: (mainId, hasSign) => {
    return new Promise((resolve, reject) => {
      API.get(`/Document/CreateSDPdf/${mainId}/${hasSign}`, {
        responseType: 'arraybuffer',
        headers: {
          'Accept': 'application/pdf'
        }
      })
        .then(rs => {
          resolve(rs);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 建立照片紀錄表PDF
  CreatePhotoPdf: ({projectFormId, mainId}) => {
    return new Promise((resolve, reject) => {
      API.get(`/Document/CreatePhotoPdf/${projectFormId}/${mainId}`, {
        responseType: 'arraybuffer',
        headers: {
          'Accept': 'application/pdf'
        }
      })
        .then(rs => {
          resolve(rs);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 建立異常照片紀錄表PDF
  CreateANPhotoPdf: ({mainId}) => {
    return new Promise((resolve, reject) => {
      API.get(`/Document/CreateANPhotoPdf/${mainId}`, {
        responseType: 'arraybuffer',
        headers: {
          'Accept': 'application/pdf'
        }
      })
        .then(rs => {
          resolve(rs);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 材料設備品質抽驗紀錄表Excel
  CreateEQExcel: ({projectId}) => {
    return new Promise((resolve, reject) => {
      API.get(`/Document/CreateEQExcel/${projectId}`, {
        responseType: 'arraybuffer',
      })
        .then(rs => {
          resolve(rs);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 施工抽查紀錄表Excel
  CreateCEExcel: ({projectId}) => {
    return new Promise((resolve, reject) => {
      API.get(`/Document/CreateCEExcel/${projectId}`, {
        responseType: 'arraybuffer',
      })
        .then(rs => {
          resolve(rs);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 設備功能運轉測試紀錄表Excel
  CreateEFExcel: ({projectId}) => {
    return new Promise((resolve, reject) => {
      API.get(`/Document/CreateEFExcel/${projectId}`, {
        responseType: 'arraybuffer',
      })
        .then(rs => {
          resolve(rs);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 一般安全衛生查驗表Excel
  CreateHSExcel: ({projectId}) => {
    return new Promise((resolve, reject) => {
      API.get(`/Document/CreateHSExcel/${projectId}`, {
        responseType: 'arraybuffer',
      })
        .then(rs => {
          resolve(rs);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 異常(不合格)通知暨改善追蹤列表Excel
  CreateANExcel: ({projectId}) => {
    return new Promise((resolve, reject) => {
      API.get(`/Document/CreateANExcel/${projectId}`, {
        responseType: 'arraybuffer',
      })
        .then(rs => {
          resolve(rs);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 每日出工機具 Excel
  CreateDPIExcel: ({ projectId, fillinDate }) => {
    return new Promise((resolve, reject) => {
      API.get(`/Document/CreateDPIExcel/${projectId}/${fillinDate}`, {
        responseType: 'arraybuffer',
      })
        .then(rs => {
          resolve(rs);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  ///api/Document/CreateDPIListExcel/{projectId}/{fillinSDate}/{fillinEDate}
  CreateDPIListExcel : ({ projectId, fillinSDate, fillinEDate }) => {
    return new Promise((resolve, reject) => {
      API.get(`/Document/CreateDPIListExcel/${projectId}/${fillinSDate}/${fillinEDate}`, {
        responseType: 'arraybuffer',
      })
        .then(rs => {
          resolve(rs);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  CreateSMPdf: (projetcId) => {
    console.log(projetcId);
    return new Promise((resolve, reject) => {
      API.get(`/Document/CreateSMPdf/${projetcId}`, {
        responseType: 'arraybuffer',
      })
        .then(rs => {
          resolve(rs);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default DocumentAPI;