// import TableauDashboard from './TableauDashboard';
// import tools from '../assets/tools';
import { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import ConImg from '../../images/施工01.png';
import { Link } from "react-router-dom";

// import { D3BarChart } from '../assets/d3components/BarChart';
import { D3StackedBarChart } from '../assets/d3components/StackedBarChart';
import { D3PieChart } from '../assets/d3components/PieChart';
import { D3Swatches, D3StackedHorizontalBarChart } from '../assets/d3components';
// import colorSchemes from './../assets/d3components/config/scale-chromatic';
// import alphabat from "../assets/d3components/data/alphabat.json";
// import population from "../assets/d3components/data/population.json";
// import states from "../assets/d3components/data/states.json";

import { SwitchMode } from './SwitchMode';
import { ThemeContext } from './../contexts/ThemeContext';
import { StatisticAPI, HelperAPI } from './../assets/api';
import { useLocalStorage } from './useLocalStorage';
import { ChartDownloadButton } from './DownloadImageButton';
import { ExportToExcelButton } from './ExportToExcel';
import { useIsFirstRender } from './../assets/customHooks/useIsFirstRender';

const ScrollDiv = styled.div`
/* max-height: 500px;
overflow: auto;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #A7A5A6;
  }
  ::-webkit-scrollbar-track {
    background-color: #555;
  } */
`;
const HalfRight = styled.div`
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  .btnBox{
    display: flex;
    justify-content: space-between;
    margin: 5px 0 0 0;
    .fakeBtn{
      text-decoration: none;
      width:32%;
      text-align: center;
      padding: 10px 0;
      color: ${props => props.isDarkMode ? '#e6e6e6' : '#0c0c0c'};
      background-color: ${props => props.isDarkMode ? '#444' : '#fff'};
      display: flex;
      justify-content: center;
      align-items: center;
      &.fakeActive {
        border: 1px solid #F28E2B;
        color: ${props => props.isDarkMode ? '#F28E2B' : '#fff'};
        background-color: ${props => props.isDarkMode ? '#444' : '#F28E2B'};
        cursor: unset;
      }
    }
  }
`;
const Bigboard = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
div{
  box-sizing: border-box;
}
.halfLeft{
  // border:1px solid #F00;
  padding: 10px 10px 0px 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 1300px) {
    width: 100%;
  }
  .divFlow{
    // border:1px solid #000;
    // background-color: ${props => props.isDarkMode ? '#444' : '#FFF'};
    margin: 0 0 10px 0;
    padding: 10px;
    height: 600px;
    &.conTable{
      width: calc(65% - 5px);
      @media (max-width: 960px) {
        width: 100%;
      }
      div{
        max-height: 450px;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background-color: #DDD;
        }
        // table{
        //   tbody{
        //     tr{}
        //   }
        // }
      }
    }
    &.conVerbar{
      width: calc(35% - 5px);
      @media (max-width: 960px) {
        width: 100%;
      }
    }
    &.conHorbar{
      width: calc(60% - 5px);
      @media (max-width: 960px) {
        width: 100%;
      }
    }
    &.conPie{
      width: calc(40% - 5px);
      @media (max-width: 960px) {
        width: 100%;
      }
    }
  }
}
.halfRight{
  // border:1px solid #00F;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  @media (max-width: 1300px) {
    width: 100%;
  }
  .imgBox{
    // background-color: ${props => props.isDarkMode ? '#444' : '#FFF'};
    img{
      width: 100%;
    }
  }
  .btnBox{
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px 0;
    .fakeBtn{
      text-decoration: none;
      width:32%;
      text-align: center;
      padding: 10px 0;
      // background-color: ${props => props.isDarkMode ? '#444' : '#FFF'};
      color:#FFF;
      display: flex;
      justify-content: center;
      align-items: center;
      &.fakeActive{
        border: 1px solid #F28E2B;
        color:#F28E2B;
        cursor: unset;
      }
    }
  }
}
`;
const NoDataText = styled.div`
text-align: center;
font-size: 20px;
color: ${props => props.isDarkMode ? '#e6e6e6' : '#7b7979'};
`;

const colorTheme = {
  light: {
    titleTextColor: "black",
    xValueColor: "black",
    yValueColor: "black",
    backgroundColor: "#FFF",
    // color: "#65BAAA",
    // color: "#000",
    // titleAltColor: "white",
  },
  dark: {
    titleTextColor: "white",
    xValueColor: "white",
    yValueColor: "white",
    backgroundColor: "#444",
    // color: "#FFF",
    // titleAltColor: "#222",
  }
};

const DashboardMain = () => {
  const isFirstRender = useIsFirstRender();
  // const url = tools.CheckEnv() === 'prod' ?
  //   'https://bi3.stantec.com.tw/views/DashboardProd/sheet37?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link' :
  //   'https://bi3.stantec.com.tw/views/DashboardNew/sheet21?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link';

  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark' ? true : false;
  // const [isDarkMode, setIsDarkMode] = useState(false);
  // const [isDarkModeScheme, setIsDarkModeScheme] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches);
  const tableClass = isDarkMode ? "table_dark" : "table_light";

  const [projectList, setProjectList] = useState([]); //專案清單
  const [projectId, setProjectId] = useLocalStorage("projectId", projectList.length ? projectList[0].id : 0);
  const [ceFormDataList, setCeFormDataList] = useState([]); //左上表格
  const [piedata, setPiedata] = useState([]); //
  const [stackBarData, setStackBarData] = useState([]); //
  const [stackHorBarData, setStackHorBarData] = useState([]); //

  // const [bardata, setBardata] = useState([]); //



  //取得專案下拉清單
  const GetStaticProjectList = async () => {
    try {
      let data = await HelperAPI.GetStaticProjectList();
      setProjectList(data.projectList);
      return data.projectList;
    } catch (error) {
      console.log(error);
    }
  };

  const [stackedBarChartOption, setStackedBarChartOption] = useState({
    chartId: "stackedBarChartSvg",
    downloadAreaId: "stackedBarChart",
    titleText: "",
    xAttr: 'state',
    yAttr: 'population',
    zAttr: 'age',
    yLabel: '',
    // colorScheme: 'schemeSpectral',
    // colorInterpolate: 'interpolateTurbo',
    // colorInterpolate: colorSchemes.sequentialMulti.interpolateCividis,
    colorCustom: ['#4e79a7', '#f28e2c',],
    color: isDarkMode ? "#FFF" : "#65BAAA",
    titleAltColor: isDarkMode ? "#2f2f2f" : "white",
    xValueColor: isDarkMode ? "white" : "black",
    yValueColor: isDarkMode ? "white" : "black",
    backgroundColor: isDarkMode ? "#444" : "#FFF",
    stringTranslator: { 'qualified': '合格', 'unqualified': '不合格' },
  });
  const StackedHOrizontalBarChartOption = {
    chartId: "stackedHorBarChartSvg",
    downloadAreaId: "stackedHorBarChart",
    titleText: "",
    titleTextColor: "blue",
    xAttr: 'population',
    yAttr: 'state',
    zAttr: 'age',
    yLabel: 'Population (millions) →',
    // colorCustom: ['#666', '#000'],
    // colorInterpolate: 'interpolateTurbo',
    // colorInterpolate: colorSchemes.sequentialMulti.interpolateCividis,
    colorCustom: ['#4e79a7', '#f28e2c',],
    xValueColor: isDarkMode ? "white" : "black",
    yValueColor: isDarkMode ? "white" : "black",
    stringTranslator: { 'qualified': '合格', 'unqualified': '不合格' },
    marginLeft: 150,
    marginRight: 10,
    marginTop: 70,
    height: 350,
    backgroundColor: isDarkMode ? "#444" : "#FFF",
    color: isDarkMode ? "#FFF" : "#65BAAA",
    titleAltColor: isDarkMode ? "#2f2f2f" : "white",
  };
  const [pieChartOption, setPieChartOption] = useState({
    chartId: "pieChartSvg",
    downloadAreaId: "pieChart",
    titleText: "",
    name: 'checkType',
    value: 'num',
    percentage: 'rate',
    backgroundColor: isDarkMode ? "#444" : "white",
    // colorCustom: ['#EAD156', '#0A326A',],
    colorCustom: ['#4e79a7', '#f28e2c',],
  });

  const StackedBarLegendOption = {
    // colorScheme: 'schemeSpectral',
    // colorInterpolate: 'interpolateTurbo',
    // colorInterpolate: colorSchemes.sequentialMulti.interpolateCividis,
    colorCustom: ['#4e79a7', '#f28e2c',],
    titleText: "標題標題",
    titleTextColor: "blue",
    backgroundColor: isDarkMode ? "#444" : "#FFF",
  };
  const StackedHOrizontalBarLegendOption = {
    // colorCustom: ['#666', '#000'],
    // colorInterpolate: colorSchemes.sequentialMulti.interpolateCividis,
    colorCustom: ['#4e79a7', '#f28e2c',],
    titleText: "標題標題",
    titleTextColor: "blue",
    backgroundColor: isDarkMode ? "#444" : "#FFF",
  };
  // PieLegendOption




  const isQualified = ["qualified", "unqualified",];
  const cubeTranslator = { qualified: '合格', unqualified: '不合格' };
  const isQualifiedcube = isQualified.map(ele => cubeTranslator[ele]);

  const handleProjectSelect = (e) => {
    let value = projectList.find(ele => ele.id === Number(e.target.value));
    setProjectId(value.id);
  };
  const GetCEFormRecord = async (projectId) => {
    try {
      let data = await StatisticAPI.GetCEFormRecord(projectId);
      setCeFormDataList(data.ceFormDataList);

      setPiedata(data.ceFormCheckDataList);

      const sdata = isQualified.flatMap(qualified => data.ceFormCheckDataList.map(d => ({ state: d.checkType, age: qualified, population: d[qualified] })));
      setStackBarData(sdata);

      const stackhordata = isQualified.flatMap(isqualified => data.ceFormNameDataList.map(d => ({ state: d.formName, age: isqualified, population: d[isqualified] })));
      setStackHorBarData(stackhordata);

      // const bdata = data.ceFormNameDataList.map(ele => ({ "letter": ele.formName, "frequency": ele.qualified }) );
      // const bdata = isQualified.flatMap( qualified => data.ceFormNameDataList.map(ele => ({ "letter": ele.formName, "frequency": ele.qualified })));
      // setBardata(bdata);
      // console.log(bardata);

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setStackedBarChartOption({
      ...stackedBarChartOption,
      ...colorTheme[isDarkMode ? "dark" : "light"]
    });
    setPieChartOption({
      ...pieChartOption,
      ...colorTheme[isDarkMode ? "dark" : "light"]
    });
  }, [isDarkMode]);

  useEffect(async() => {
    let staticProjectList = await GetStaticProjectList();
    if (projectId === 0) {
      setProjectId(staticProjectList[0].id);
    } else {
      GetCEFormRecord(projectId);
    }
  }, []);

  useEffect(() => {
    if (!isFirstRender) {
      GetCEFormRecord(projectId);
    }
    // GetStackeddata();
    // console.log(stateages);
  }, [projectId]);

  return (
    <>
      <SwitchMode />
      <div className="containerBox" style={{ backgroundColor: isDarkMode ? '#666' : '#F1F1F1' }}>
        <section className="dashboardContent">
          <HalfRight isDarkMode={isDarkMode}>
            <div className="btnBox">
              <Link to={{ pathname: `dashboard-new-construction` }} className="fakeBtn fakeActive">施工品質查驗紀錄</Link>
              <Link to={{ pathname: `dashboard-new-material` }} className="fakeBtn">材料設備品質查驗</Link>
              <Link to={{ pathname: `dashboard-new-abnormal` }} className="fakeBtn">品質缺失改善追蹤</Link>
            </div>
          </HalfRight>

          <div className="dashboardUpArea">
            {/* <div className="dashboardUpArea_left">
              <div className="dashboardTitle" style={{ backgroundColor: isDarkMode ? '' : '#F1F1F1' }}>
                <h1>大漢溪埔頂排水水環境改善計畫</h1>
              </div>
              <div className="subTitle" style={{ color: isDarkMode ? '#E6E6E6' : '#999' }}>
                <h1>品質查驗紀錄</h1>
              </div>
            </div> */}
            <div className="projectWrap" style={{ backgroundColor: isDarkMode ? '#555' : '#DDD' }}>
              <h3 style={{ color: isDarkMode ? '#e6e6e6' : '#000' }}>專案名稱</h3>
              <div className="dropdownBox">
                {/* <select className="dropdownMenu" style={{ backgroundColor: isDarkMode ? '#BCBCBC' : '#FFF' }}>
                  <option>「金門縣觀光景點修繕及零星工程」委託規劃設計監造(開口契約)案-金門縣莒光樓內部展示工程</option>
                  <option>test1</option>
                  <option>test2</option>
                </select> */}
                <select
                  className="dropdownMenu" style={{ backgroundColor: isDarkMode ? '#BCBCBC' : '#FFF' }}
                  onChange={(e) => handleProjectSelect(e)}
                  value={projectId}
                >
                  {
                    projectList.map(ele => (
                      <option key={ele.id} value={ele.id}>{ele.projectName}</option>
                    ))
                  }
                </select>
              </div>
            </div>
          </div>

          <Bigboard>
            <div className="halfLeft">
              <div className="divFlow conTable" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF', position: 'relative' }}>
                <div className={tableClass} id='constructionInspectionTable'>
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ minWidth: '2em', }}>分類</td>
                        <td>累計查驗</td>
                        <td>不合格(數量)</td>
                        <td>合格(數量)</td>
                        {/* <td>不合格(率)</td> */}
                        <td>合格(率)</td>
                        <td>不定期</td>
                      </tr>
                      {
                        ceFormDataList.map((ele, idx) => {
                          return (
                            <tr key={idx}>
                              <td>{ele.projectFormCategoryName}</td>
                              <td>{ele.num}</td>
                              <td>{ele.unqualified}</td>
                              <td>{ele.qualified}</td>
                              {/* <td>{ele.unqualifiedRate}</td> */}
                              <td>{ele.qualifiedRate} %</td>
                              <td>{ele.irregular}</td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </div>
                <ExportToExcelButton tableId ="constructionInspectionTable" tableName="施工品質查驗紀錄"/>
              </div>
              <div className="divFlow conVerbar" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF', position: 'relative' }}>
                {
                  stackBarData.length > 0 ?
                    <div id={stackedBarChartOption.downloadAreaId} className="chartBlock" style={{ color: isDarkMode ? '#FFF' : '#000' }} >
                      <D3StackedBarChart data={stackBarData} group={isQualified} option={stackedBarChartOption} />
                      <D3Swatches group={isQualifiedcube} option={StackedBarLegendOption} /> 
                    </div>
                    : <NoDataText isDarkMode={isDarkMode}>當前專案無資料</NoDataText>
                }
                <ChartDownloadButton
                  chartId={stackedBarChartOption.chartId}
                  downloadAreaId={stackedBarChartOption.downloadAreaId}
                  chartName={stackedBarChartOption.titleText}
                />
                {/* <DownloadBtnBox>
                  <button className="chartDownloadBtn" onClick={() => downloadImage(stackedBarChartOption.chartId, stackedBarChartOption.downloadAreaId, stackedBarChartOption.titleText)}>Download</button>
                </DownloadBtnBox> */}
              </div>

              <div className="divFlow conHorbar" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF', position: 'relative'}}>
                {/* <div id={StackedHOrizontalBarChartOption.chartId} className="chartBlock" style={{ color: isDarkMode ? '#FFF' : '#000', maxHeight: '600px', overflow: 'auto' } }> */}
                {
                  stackHorBarData.length > 0 ?
                    <ScrollDiv id={StackedHOrizontalBarChartOption.downloadAreaId} className="chartBlock" style={{ color: isDarkMode ? '#FFF' : '#000'}}>
                      {/* <D3BarChart data={bardata} option={barChartOption} /> */}
                      <D3StackedHorizontalBarChart data={stackHorBarData} group={isQualified} option={StackedHOrizontalBarChartOption} />
                      <D3Swatches group={isQualifiedcube} option={StackedHOrizontalBarLegendOption} />
                    </ScrollDiv>
                    : <NoDataText isDarkMode={isDarkMode}>當前專案無資料</NoDataText>
                }
                <ChartDownloadButton
                  chartId={StackedHOrizontalBarChartOption.chartId}
                  downloadAreaId={StackedHOrizontalBarChartOption.downloadAreaId}
                  chartName={StackedHOrizontalBarChartOption.titleText}
                  dataLength={stackHorBarData.length/2}
                />
                {/* <DownloadBtnBox>
                  <button className="chartDownloadBtn" onClick={() => downloadImage(StackedHOrizontalBarChartOption.chartId, StackedHOrizontalBarChartOption.downloadAreaId, StackedHOrizontalBarChartOption.titleText, stackHorBarData.length)}>Download</button>
                </DownloadBtnBox> */}
              </div>
              <div className="divFlow conPie" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF', position: 'relative' }}>
                {
                  piedata.length > 0 ? 
                    <div id={pieChartOption.downloadAreaId} className="chartBlock" style={{ color: isDarkMode ? '#FFF' : '#000' }}>
                      <D3PieChart data={piedata} option={pieChartOption} />
                      <D3Swatches group={piedata.map(p => p.checkType)} option={pieChartOption} /> 
                    </div>
                    : <NoDataText isDarkMode={isDarkMode}>當前專案無資料</NoDataText>
                }
                <ChartDownloadButton
                  chartId={pieChartOption.chartId}
                  downloadAreaId={pieChartOption.downloadAreaId}
                  chartName={pieChartOption.titleText}
                />
                {/* <DownloadBtnBox>
                  <button className="chartDownloadBtn" onClick={() => downloadImage(pieChartOption.chartId, pieChartOption.downloadAreaId, pieChartOption.titleText)}>Download</button>
                </DownloadBtnBox> */}
              </div>
            </div>
            <div className="halfRight">
              {/* <div className="btnBox">
                <Link to={{ pathname: `dashboard-new-construction` }} className="fakeBtn fakeActive" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF' }}>施工品質查驗</Link>
                <Link to={{ pathname: `dashboard-new-material` }} className="fakeBtn" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF', color: isDarkMode ? '#FFF' : '#666' }}>材料設備品質查驗</Link>
                <Link to={{ pathname: `dashboard-new-abnormal` }} className="fakeBtn" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF', color: isDarkMode ? '#FFF' : '#666' }}>品質缺失改善追蹤</Link>
              </div> */}
              <div className="imgBox" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF' }}>
                <img src={ConImg} />
              </div>
            </div>
          </Bigboard>
        </section>
      </div>

      <br /><br /><br />
      {/* <TableauDashboard url={url} /> */}
    </>
  );
};
export default DashboardMain;