import React, { createContext, useState, useEffect, useMemo, useContext } from 'react';
import { AccountAPI } from '../assets/api';

export const LoginStateContext = createContext(null);

const initialLoginState = false;

const LoginStateProvider = props => {
  const [loginState, setLoginState] = useState(initialLoginState);
  const [isLoading, setIsLoading] = useState(true);
  const [loginUser, setLoginUser] = useState({});
  /* The first time the component is rendered, it tries to
   * fetch the auth data from a source, like a cookie or
   * the localStorage.
   */
  async function IsLogin() {
    try {
      let result = await AccountAPI.GetUserInfo();
      if (result) {
        setLoginState(true);
        setIsLoading(false);
        setLoginUser(result);
      }
    } catch (error) {
      setLoginState(false);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    IsLogin();
  }, []);

  const onLogout = () => {
    setLoginState(initialLoginState);
    setLoginUser({});
    window.localStorage.removeItem('projectInfo');
    window.localStorage.removeItem('projectId');
  };

  const onLogin = (newLoginState, userInfo) => {
    window.localStorage.removeItem('projectInfo');
    window.localStorage.removeItem('projectId');
    setLoginState(newLoginState);
    setLoginUser(userInfo);
  };

  const LoginStateValue = useMemo(() => ({ loginState, isLoading, loginUser, onLogin, onLogout }), [loginState, isLoading, loginUser]);

  return <LoginStateContext.Provider value={LoginStateValue} {...props} />;
};

export const useLoginStateContext = () => useContext(LoginStateContext);

export default LoginStateProvider;