// import QualityCheckRecord from './QualityCheckRecord';
// import OperationCheckRecord from './OperationCheckRecord';
// import ErrorNotifyAndImprove from './ErrorNotifyAndImprove';
import SafetyHealthCheckRecord from './SafetyHealthCheckRecord';
import styled from '@emotion/styled';
// import { useEffect, useState } from 'react';
// import { ReactComponent as NullValueIcon } from "./../../images/NullValue_Icon.svg";

const IconCancel = styled.span`
  margin-left: 10px;
  font-size: 14px;
  position: relative;
  top: -2px;
  color: #999;
  &:hover{
    // color: #f55753CC;
    color: #666;
  }
`;

const FormTest = () => {

  return (
    <>
      <div className="main">
        {/* 分頁頁籤區 */}
        <div className="tabbox">
          <div
            className="liDiv">
            <IconCancel><i className="fas fa-times"></i></IconCancel>
          </div>
        </div>

        {/* 表單顯示區 */}
        <div>
          {/* <OperationCheckRecord /> */}
          {/* <ErrorNotifyAndImprove /> */}
          <SafetyHealthCheckRecord />
        </div>

      </div>
    </>
  );

};
export default FormTest;