import { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';

import { HelperAPI, ProjectAPI } from './../assets/api';
import { notifyFailed } from './../assets/toaster';

const QueryPanelPJMaterial = (props) => {
  const [moduleList, setModuleList] = useState([]);
  async function GetModuleList() {
    try {
      let data = await HelperAPI.GetModuleList(true);
      setModuleList(data);
      // props.setModuleSelected(data[0]);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: true});
    }
  }

  async function GetProjectFormList({ isClear }) {
    let moduleId = isClear ? '' : props.moduleSelected.id;
    try {
      let data = await ProjectAPI.GetProjectFormList({ projectId: props.projectId, moduleId, page: 1 });
      if (data) props.setFormList(data.formManageList);
      props.setPagesModuleId(moduleId);
      props.setTotalPages(data.pageInfo.pages);
      props.setCurrentPage(1);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: true});
    }
  }

  const handleTemplateSelect = (e) => {
    props.setModuleSelected(moduleList.find(ele => ele.id === Number(e.target.value)));
  };

  const handleSearchClick = () => {
    GetProjectFormList({ isClear: false });
    props.setIsListOn(true);
  };

  const handleCancelClick = async() => {
    props.setModuleSelected({
      id: '',
      moduleName: ''
    });
    props.setIsListOn(true);
    await GetProjectFormList({ isClear: true });
  };

  const handleCreateClick = () => {
    props.setUpdateForm({
      moduleId: props.moduleSelected.id,
      formId: 'create'
    });
    props.setIsListOn(false);
  };

  useEffect(async() => {
    await GetModuleList();
    await GetProjectFormList({ isClear: true });
  }, []);

  return (
    <>
      <div className="nav">
        <div className="queryMenu">
          <select size="4" onChange={(event) => handleTemplateSelect(event)} value={props.moduleSelected.id}>
            <option defaultValue disabled hidden></option>
            {
              moduleList.map(ele => (
                <option key={ele.id} value={ele.id}>{ele.moduleName}</option>
              ))
            }
          </select>
          <div className="queryMenu_btnbox">
            <button className="btnM btnBlue" onClick={handleSearchClick}>查看</button>
            <button className="btnM btnGrey" onClick={handleCancelClick}>清除</button>
            <button className="btnM btnWhite" onClick={handleCreateClick}>新增</button>
            {/* <Link to="form-item-manage" className="a_fakebtn btnM btnWhite" style={{ color: '#000', }}>新增</Link> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default QueryPanelPJMaterial;