// Drag and drop component
// ref: https://medium.com/@650egor/simple-drag-and-drop-file-upload-in-react-2cb409d88929
// revised from class component to functional component

import React, { useEffect, useRef, useState } from 'react';
const DragAndDrop = (props) => {
  const [dragging, setDragging] = useState(false);
  const dropRef = useRef(null);
  let dragCounter = 0;

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };
  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter--;
    if (dragCounter === 0) {
      setDragging(false);
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      props.handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      dragCounter = 0;
    }
  };

  useEffect(() => {
    let div = dropRef.current;
    div.addEventListener('dragenter', handleDragIn);
    div.addEventListener('dragleave', handleDragOut);
    div.addEventListener('dragover', handleDrag);
    div.addEventListener('drop', handleDrop);
    // return (() => {
    //   let div = dropRef.current;
    //   div.removeEventListener('dragenter', handleDragIn);
    //   div.removeEventListener('dragleave', handleDragOut);
    //   div.removeEventListener('dragover', handleDrag);
    //   div.removeEventListener('drop', handleDrop);
    // });
  }, []);

  // componentDidMount() {
  //   let div = dropRef.current
  //   div.addEventListener('dragenter', handleDragIn)
  //   div.addEventListener('dragleave', handleDragOut)
  //   div.addEventListener('dragover', handleDrag)
  //   div.addEventListener('drop', handleDrop)
  // }
  // componentWillUnmount() {
  //   let div = dropRef.current
  //   div.removeEventListener('dragenter', handleDragIn)
  //   div.removeEventListener('dragleave', handleDragOut)
  //   div.removeEventListener('dragover', handleDrag)
  //   div.removeEventListener('drop', handleDrop)
  // }

  return (
    <div
      style={{display: 'inline-block', position: 'relative', width: '100%'}}
      ref={dropRef}
    >
      {dragging &&
        <div 
          style={{
            border: 'dashed #808080 2px',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0, 
            right: 0,
            zIndex: 9999
          }}
        >
          <div 
            style={{
              textAlign: 'center',
              color: '#808080',
              fontSize: '1.6em',
              marginTop: '103px'
            }}
          >
            拖曳至此處
          </div>
        </div>
      }
      {props.children}
    </div>
  );
};

export default DragAndDrop;