import '../../style/Dashboard.scss';
import { useContext, useEffect } from "react";
import { ThemeContext } from './../contexts/ThemeContext';


const lightTheme = "light";
const darkTheme = "dark";

export const SwitchMode = () => {
  const { theme, dispatch } = useContext(ThemeContext);
  const isDarkMode = theme === darkTheme ? true : false;
  
  useEffect(()=> {
    const main = document.querySelector('.main');
    if (main.classList.contains(darkTheme)) {
      main.classList.remove(darkTheme);
      main.style.backgroundColor = "";
    }
    if (main.classList.contains(lightTheme)) {
      main.classList.remove(lightTheme);
      main.style.backgroundColor = "#555";
    }
    main.classList.add(theme);
  },[theme]); 

  useEffect(()=> {
    const main = document.querySelector('.main');
    if (main.classList.contains(darkTheme)) {
      main.style.backgroundColor = "#555";
    }
    if (main.classList.contains(lightTheme)) {
      main.style.backgroundColor = "";
    }
    main.classList.add(theme);
  },[]); 

  const toggleBtn = () => {
    dispatch({ type: 'SET_THEME', theme: theme === darkTheme ? lightTheme : darkTheme });
  };

  return(
    <>
      <div className="toggleDarkMode" id="theme">
        <div className="switchBox" onClick={() => toggleBtn()}>
          <span className="switchBg" style={{backgroundColor: isDarkMode ? '#0c0c0c' : '#ccc' }}></span>
          <button className="switchBtn "
            id="darkModeToggle" style={{ transform: isDarkMode ? 'translateX(0px)' : 'translateX(30px)' }}/>
        </div>
        <span style={{color: isDarkMode ? '#e6e6e6' : '#0c0c0c', fontSize: '14px'}}>{isDarkMode ? '深色模式' : '淺色模式'} </span>
      </div>
    </>
  );
};