import * as d3 from 'd3';
// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/stacked-bar-chart
export default function StackedBarChart(data, {
  x = (d, i) => i, // given d in data, returns the (ordinal) x-value
  y = d => d, // given d in data, returns the (quantitative) y-value
  z = () => 1, // given d in data, returns the (categorical) z-value
  title, // given d in data, returns the title text
  marginTop = 50, // top margin, in pixels
  marginRight = 0, // right margin, in pixels
  marginBottom = 80, // bottom margin, in pixels
  marginLeft = 20, // left margin, in pixels
  width = 640, // outer width, in pixels
  height = 400, // outer height, in pixels
  xDomain, // array of x-values
  xRange = [marginLeft, width - marginRight], // [left, right]
  xPadding = 0.1, // amount of x-range to reserve to separate bars
  yType = d3.scaleLinear, // type of y-scale
  yDomain, // [ymin, ymax]
  yRange = [height - marginBottom, marginTop], // [bottom, top]
  zDomain, // array of z-values
  offset = d3.stackOffsetDiverging, // stack offset method
  order = d3.stackOrderNone, // stack order method
  yFormat, // a format specifier string for the y-axis
  yLabel, // a label for the y-axis
  colors = d3.schemeTableau10, // array of colors
  xValueColor,
  yValueColor,
  backgroundColor,
  titleText,
  titleTextColor,
  stringTranslator,
  textLengthX,
} = {}) {
  // Compute values.
  const X = d3.map(data, x);
  const Y = d3.map(data, y);
  const Z = d3.map(data, z);
  // console.log(Z);
  // console.log(chartId);
  // console.log(data);
  // console.log(Y);
  // console.log(x);


  // Compute default x- and z-domains, and unique them.
  if (xDomain === undefined) xDomain = X;
  if (zDomain === undefined) zDomain = Z;
  xDomain = new d3.InternSet(xDomain);
  zDomain = new d3.InternSet(zDomain);
  // Omit any data not present in the x- and z-domains.
  const I = d3.range(X.length).filter(i => xDomain.has(X[i]) && zDomain.has(Z[i]));

  // Compute a nested array of series where each series is [[y1, y2], [y1, y2],
  // [y1, y2], …] representing the y-extent of each stacked rect. In addition,
  // each tuple has an i (index) property so that we can refer back to the
  // original data point (data[i]). This code assumes that there is only one
  // data point for a given unique x- and z-value.
  const series = d3.stack()
    .keys(zDomain)
    .value(([, I], z) => Y[I.get(z)])
    .order(order)
    .offset(offset)(d3.rollup(I, ([i]) => i, i => X[i], i => Z[i])).map(s => s.map(d => Object.assign(d, { i: d.data[1].get(s.key) })));

  // Compute the default y-domain. Note: diverging stacks can be negative.
  if (yDomain === undefined) yDomain = d3.extent(series.flat(2));

  // Construct scales, axes, and formats.
  const xScale = d3.scaleBand(xDomain, xRange).paddingInner(xPadding);
  const yScale = yType(yDomain, yRange);
  const color = d3.scaleOrdinal(zDomain, colors);
  const xAxis = d3.axisBottom(xScale)
    .tickSizeOuter(0)
    .tickFormat(d => {
      return d.length > textLengthX ? `${d.slice(0, textLengthX)}...` : d;
    });
  const yAxis = d3.axisLeft(yScale).ticks(height / 100, yFormat);

  // Compute titles.
  if (title === undefined) {
    const formatValue = yScale.tickFormat(1, yFormat);
    title = i => `${X[i]}\n${stringTranslator ? stringTranslator[Z[i]] : Z[i]}\n${formatValue(Y[i])}`;
  } else {
    const O = d3.map(data, d => d);
    const T = title;
    title = i => T(O[i], i, data);
  }

  const svg = d3.create("svg")
    .attr("id", "stackedBarChartSvg")
    .attr("width", width )
    .attr("height", height)
    .attr("viewBox", [0, 0, width, height + 50])
    .attr("style", "height: auto; height: intrinsic;")
    .style("color", xValueColor)
    .style("color", yValueColor)
    .style("background-color", backgroundColor);

  svg.append("g")
    .call(g => g.append('text')
      .attr("x", "50%")
      .attr("y", 23)
      .attr("fill", titleTextColor)
      .style("font-size", "20")
      .style("font-weight", "bold")
      .style("text-anchor", "middle")
      .text(titleText));

  svg.append("g")
    .attr("transform", `translate(${marginLeft},0)`)
    .call(yAxis)
    .call(g => g.select(".domain").remove())
    .call(g => g.selectAll(".tick line").clone()
      .attr("x2", width - marginLeft - marginRight)
      .attr("stroke-opacity", 0.1))
    .call(g => g.append("text")
      .attr("x", -marginLeft)
      .attr("y", 30)
      .attr("fill", "currentColor")
      .attr("text-anchor", "start")
      .text(yLabel));

  const bar = svg.append("g")
    .selectAll("g")
    .data(series)
    .join("g")
    .attr("fill", ([{ i }]) => color(Z[i]))
    .selectAll("rect")
    .data(d => d)
    .join("rect")
    .attr("x", ({ i }) => (xScale(X[i])+((xScale.bandwidth())/2-22.5)))
    .attr("y", ([y1, y2]) => Math.min(yScale(y1), yScale(y2)))
    .attr("height", ([y1, y2]) => Math.abs(yScale(y1) - yScale(y2)))
    .attr("width", 45)
    .style("cursor", "pointer")
    // .text(d => (d.data))
    .on("pointerenter", function() {
      d3.select(this).style("opacity", 0.5);
    })
    .on("pointerleave", function() {
      d3.select(this).style("opacity", 1);
    });

  if (title) bar.append("title")
    .text(({ i }) => title(i));

  svg.append("g")
    .selectAll('text')
    .data(series.flat(1))
    .join("text")
    .attr("x", ({ i }) => {
      return xScale(X[i]);
    })
    .attr("y", ([y1, y2]) => {
      return Math.min(yScale(y1), yScale(y2));
    })
    .attr("dx", xScale.bandwidth()/2)
    .attr("dy", "1rem")
    .style('fill', '#FFF')
    .style('font-size', '1rem')
    .style('font-weight', 'bold')
    .style("text-anchor", 'middle')
    .text(function ([y1, y2]) {
      return Math.abs(yScale(y1) - yScale(y2)) > yScale(0)*0.05 ? y2-y1 : '';
    });

  svg.append("g")
    .attr("transform", `translate(0,${yScale(0)})`)
    .call(xAxis)
    .selectAll('text')
    .attr('x', 12)
    .attr('y', 0)
    .attr('dy', '0.3em')
    .attr('transform', 'rotate(90)')
    .attr("dominant-baseline", "middle")
    .attr('text-anchor', 'start');

  let YTooltipText;
  if (YTooltipText === undefined) {
    YTooltipText = i => X[i];
  } else {
    const O = d3.map(data, d => d);
    YTooltipText = i => title(O[i]);
  }

  svg.append("g")
    .selectAll("g")
    .data(series)
    .join("g")
    .attr("fill", "white")
    .style("opacity", 0)
    .selectAll("rect")
    .data(d => d)
    .join("rect")
    .attr("x", ({ i }) => xScale(X[i]))
    .attr("y", yScale(0) + 15)
    .attr("height", 100)
    .attr("width", xScale.bandwidth())
    .style("cursor", "pointer")
    .append("title")
    .text(({ i }) => YTooltipText(i));

  return Object.assign(svg.node(), { scales: { color } });
}