import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Skeleton from 'react-loading-skeleton';
import styled from '@emotion/styled';
import { DocumentAPI, FormAPI, SupervisionAPI, ProjectAPI } from './../assets/api';
import { useIsFirstRender } from './../assets/customHooks/useIsFirstRender';
import tools from './../assets/tools';

import { notifyFailed } from './../assets/toaster';
import { ProjectContext } from './../contexts/ProjectContext';

const SelectSkeleton = styled.div`
  margin: 5px 15px 5px 0;
  width: 400px;
  overflow: auto;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 3px;
  background-color: #ffffff;
  display: inline-block;
`;

const QueryPanel = (props) => {
  let history = useHistory();
  const isFirstRender = useIsFirstRender();

  // let projectId = 1; //TODO: 動態帶projectId
  const { projectInfo } = useContext(ProjectContext);
  let projectId = projectInfo.id;

  // 取得module列表
  // async function GetModuleList() {
  //   try {
  //     let data = await ProjectAPI.GetModuleListWithNum({ projectId });
  //     setModuleList(data);
  //     props.setModuleSelected(data[0]);
  //   } catch (error) {
  //     notifyFailed({Msg: '發生錯誤', isReload: false});
  //   }
  // }


  // isProjectFormListLoading
  const [isProjectFormListLoading, setIsProjectFormListLoading] = useState(null);

  // 是否選擇不同module
  const [isSelectedOtherModule, setIsSelectedOtherModule] = useState(false);

  // 取得預設材料設備品質
  async function GetDefaultEQForm(projectFromId) {
    try {
      let data = await FormAPI.GetDefaultEQForm(projectFromId);
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  // 取得預設施工抽查表
  async function GetDefaultCEForm(projectFromId) {
    try {
      let data = await FormAPI.GetDefaultCEForm(projectFromId);
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  // 取得預設設備功能運轉測試表
  async function GetDefaultEFForm(projectFromId) {
    try {
      let data = await FormAPI.GetDefaultEFForm(projectFromId);
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  // 取得預設安衛表單
  async function GetDefaultHSForm(projectFromId) {
    try {
      let data = await FormAPI.GetDefaultHSForm(projectFromId);
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  // 取得異常表
  async function GetAbnormalInfo({ projectId, abnormalId }) {
    try {
      let data = await FormAPI.GetAbnormalInfo({ projectId, abnormalId });
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  // 取得 監督日報表
  async function GetSupervisionDailyInfo(projectId, supervisionId) {
    try {
      let data = await SupervisionAPI.GetSupervisionDailyInfo(projectId, supervisionId);
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  // 取得 每日出工機具
  async function GetDailyPersonnelImplements(projectId) {
    try {
      let data = await ProjectAPI.GetDailyPersonnelImplements({
        projectId: projectId,
        toolsInfo: {
          date: '',
          userName: ''
        }
      });
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  //取得月報
  async function GetSupervisionMonthlyInfo(projectId, supervisionId) {
    try {
      let data = await SupervisionAPI.GetSupervisionMonthlyInfo(projectId, supervisionId);
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  // 取得專案特定模組表單
  // async function GetProjectFormList() {
  //   try {
  //     // let data = await HelperAPI.GetProjectFormList({ projectId, moduleId: props.moduleSelected.id });
  //     let data = await ProjectAPI.GetProjectFormListWithNum({ projectId, moduleId: props.moduleSelected.id });
  //     setProjectFormList(data);
  //   } catch (error) {
  //     notifyFailed({Msg: '發生錯誤', isReload: false});
  //   }
  // }

  const handleModuleSelect = async (e) => {
    if (props.moduleSelected.id !== Number(e.target.value)) setIsSelectedOtherModule(true);
    props.setModuleSelected(props.moduleList.find(ele => ele.id === Number(e.target.value)));
    props.setProjectFormSelected({});
  };

  const handleProjectFormSelect = (e) => {
    props.setProjectFormSelected(props.projectFormList.find(ele => ele.id === Number(e.target.value)));
  };

  async function handleGetFormList() {
    // if (props.moduleSelected.id !== 5 && !props.projectFormSelected.id && props.moduleSelected.id !== 6) {
    if (props.moduleSelected.hasItem === true && !props.projectFormSelected.id) {
      notifyFailed({ Msg: '請選擇專案表單，或至專案資料管理建立專案表單', isReload: false });
      return;
    }
    history.push("/project/form-list");
    switch (props.moduleSelected.id) {
    case 1: // 1: 材料設備品質表
      props.GetProjectEQFormList({ status: isSelectedOtherModule ? 0 : null });
      props.GetProjectEQFormNumList();
      if (isSelectedOtherModule) props.setFormStatus(0);
      break;
    case 2: // 2: 施工抽查表
      props.GetProjectCEFormList({ status: isSelectedOtherModule ? 0 : null });
      props.GetProjectCEFormNumList();
      if (isSelectedOtherModule) props.setFormStatus(0);
      break;
    case 3: // 3: 設備功能運轉測試紀錄列表
      props.GetProjectEFFormList({ status: isSelectedOtherModule ? 0 : null });
      props.GetProjectEFFormNumList();
      if (isSelectedOtherModule) props.setFormStatus(0);
      break;
    case 4: // 4: 安衛表單
      props.PostProjectHSFormList(isSelectedOtherModule ? 0 : null);
      props.PostProjectHSFormNumList(0);
      if (isSelectedOtherModule) props.setFormStatus(0);
      break;
    case 5: // 5: 異常表單
      props.PostAbnormalList(isSelectedOtherModule ? 0 : null);
      props.PostAbnormalNumList(0);
      if (isSelectedOtherModule) props.setFormStatus(0);
      break;
    case 6: // 6: 監造日報表
      props.PostSupervisionFormList(isSelectedOtherModule ? 0 : null);
      props.PostDailyNumList(0);
      if (isSelectedOtherModule) props.setFormStatus(0);
      break;
    case 7: // 7: 每日出工機具
      props.GetDPIList(0);
      props.PostDPINumList();
      props.setFormStatus(4);
      // props.setToolsReadStatus(0);
      break;
    case 8: // 8:專案工作月報表
      props.PostMonthlyFormList(isSelectedOtherModule ? 0 : null);
      props.PostMonthlyNumList(0);
      if (isSelectedOtherModule) props.setFormStatus(0);
      break;
    default:
      break;
    }
    props.setIsListClick(true);
    props.setIsListOn(true);
    setIsSelectedOtherModule(false);
  }

  async function handleCreateForm() {
    if (props.moduleSelected.hasItem === true && !props.projectFormSelected.enable) {
      notifyFailed({ Msg: '請選擇/建立專案表單', isReload: false });
      return;
    }

    props.setIsListOn(false);

    let formContent;
    switch (props.moduleSelected.id) {
    case 1: // 1: 材料設備品質表
      formContent = await GetDefaultEQForm(props.projectFormSelected.id);
      break;
    case 2: // 2: 施工抽查表
      formContent = await GetDefaultCEForm(props.projectFormSelected.id);
      break;
    case 3: // 3: 設備功能運轉測試紀錄列表
      formContent = await GetDefaultEFForm(props.projectFormSelected.id);
      break;
    case 4: // 4: 安衛表單
      formContent = await GetDefaultHSForm(props.projectFormSelected.id);
      break;
    case 6: // 6: 整治工作監督日報表
      formContent = await GetSupervisionDailyInfo(projectId, 0);
      break;
    case 7: // 7: 每日出工機具
      formContent = await GetDailyPersonnelImplements(projectId);
      break;
    case 8: // 8: 月報
      formContent = await GetSupervisionMonthlyInfo(projectId, 0);
      break;
    default:
      break;
    }

    let createForm = {
      id: 0,
      _uuid: tools._UUID(),
      formName: props.projectFormSelected.formName || props.moduleSelected.moduleName,
      moduleId: props.moduleSelected.id,
      projectFromId: props.projectFormSelected.id,
      formContent: { ...formContent, toolsReadStatus: 0 },
      abnormalForm: await GetAbnormalInfo({ projectId, abnormalId: 0 })
    };
    let data = await setFormTabListPromise([...props.formTabList, createForm]);
    openFormTab(data, createForm._uuid);
  }

  const setFormTabListPromise = (formList) => {
    return new Promise(resolve => {
      props.setFormTabList(formList);
      resolve(formList);
    });
  };

  const openFormTab = (list, uuid) => {
    let tabToOpen = list.find(ele => ele._uuid === uuid);
    if (tabToOpen) props.setFormTabSelected(tabToOpen);
  };

  const handleCreateExcel = async () => {
    switch (props.moduleSelected.id) {
    case 1: // 1: 材料設備品質表
      try {
        let data = await DocumentAPI.CreateEQExcel({ projectId });
        tools.AjaxFileDownload({ rs: data, filename: `材料設備品質表 - ${projectInfo.projectName}`, fileType: 'excel' });
      } catch (error) {
        notifyFailed({ Msg: '發生錯誤', isReload: false });
      }
      break;
    case 2: // 2: 施工抽查表
      try {
        let data = await DocumentAPI.CreateCEExcel({ projectId });
        tools.AjaxFileDownload({ rs: data, filename: `施工抽查紀錄表 - ${projectInfo.projectName}`, fileType: 'excel' });
      } catch (error) {
        notifyFailed({ Msg: '發生錯誤', isReload: false });
      }
      break;
    case 3: // 3: 設備功能運轉測試紀錄列表
      try {
        let data = await DocumentAPI.CreateEFExcel({ projectId });
        tools.AjaxFileDownload({ rs: data, filename: `設備功能運轉測試紀錄列表 - ${projectInfo.projectName}`, fileType: 'excel' });
      } catch (error) {
        notifyFailed({ Msg: '發生錯誤', isReload: false });
      }
      break;
    case 4: // 4: 安衛表單
      try {
        let data = await DocumentAPI.CreateHSExcel({ projectId });
        tools.AjaxFileDownload({ rs: data, filename: `安衛表單 - ${projectInfo.projectName}`, fileType: 'excel' });
      } catch (error) {
        notifyFailed({ Msg: '發生錯誤', isReload: false });
      }
      break;
    case 5: // 5: 異常(不合格)通知暨改善追蹤列表
      try {
        let data = await DocumentAPI.CreateANExcel({ projectId });
        tools.AjaxFileDownload({ rs: data, filename: `異常(不合格)通知暨改善追蹤列表 - ${projectInfo.projectName}`, fileType: 'excel' });
      } catch (error) {
        notifyFailed({ Msg: '發生錯誤', isReload: false });
      }
      break;
    case 7: // 7: 每日出工機具
      try {
        if (props.toolsSearch.fillinSDate==='' || props.toolsSearch.fillinEDate==='') {
          notifyFailed({ Msg: '請選擇日期區間', isReload: false });
        } else {
          let data = await DocumentAPI.CreateDPIListExcel({ projectId, fillinSDate: props.toolsSearch.fillinSDate, fillinEDate: props.toolsSearch.fillinEDate});
          tools.AjaxFileDownload({ rs: data, filename: `每日出工機具`, fileType: 'excel' });
        }
      } catch (error) {
        notifyFailed({ Msg: '發生錯誤', isReload: false });
      }
      break;
    default:
      break;
    }
  };

  useEffect(async () => {
    await props.GetModuleList({ isModuleId: false });
  }, []);

  useEffect(async () => {
    if (!isFirstRender) {
      setIsProjectFormListLoading(true);
      await props.GetProjectFormList();
      setIsProjectFormListLoading(false);
    }
    props.setIsListClick(false);
  }, [props.moduleSelected]);

  // useEffect(() => {
  //   if (!isFirstRender) props.setCurrentPage(1);
  // }, [props.projectFormSelected.id]);

  return (
    <>
      <div className="headtitle_pjname">
        <h1>{projectInfo.projectName}</h1>
        <button className="btnS btnWhite" style={{ 'margin': '6px 0px -2px 0px' }} onClick={handleCreateExcel}>報表下載</button>
      </div>
      <div className="nav">
        <div className="queryMenu">
          <select size="4" onChange={(event) => handleModuleSelect(event)} value={props.moduleSelected.id}>
            {
              props.moduleList.map(ele => (
                // <option key={ele.id} value={ele.id}>{ele.moduleName} {ele.numNeedSign > 0 && '🖉'}</option>
                <option key={ele.id} value={ele.id}>{ele.moduleName} {ele.numNeedSign > 0 && '(' + ele.numNeedSign + ')'}</option>
              ))
            }
          </select>
          {

            isProjectFormListLoading ?
              <SelectSkeleton>
                <Skeleton count={5}/>
              </SelectSkeleton> :
              props.projectFormList.length > 0 ?
                (<select size="4" onChange={(evt) => handleProjectFormSelect(evt)} value={props.projectFormSelected.id}>
                  {
                    props.projectFormList.map(ele => (
                      // <option key={ele.id} value={ele.id}>{ele.formName} ({ele.enable ? '啟用' : '停用'}) {ele.numNeedSign > 0 && '🖉'}</option>
                      <option key={ele.id} value={ele.id}>{ele.formName} ({ele.enable ? '啟用' : '停用'}) {ele.numNeedSign > 0 && '(' + ele.numNeedSign + ')'}</option>
                    ))
                  }
                </select>) :
                null
          }
          <div className="queryMenu_btnbox">
            <button className="btnM btnGrey" onClick={handleGetFormList}>查看列表</button>
            <button className="btnM btnWhite" disabled={props.moduleSelected.hasItem === true && (!props.projectFormSelected.enable)} onClick={handleCreateForm}>新增表單</button>
          </div>
        </div>
      </div>
    </>
  );
};
export default QueryPanel;