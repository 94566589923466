
import tools from './tools';
import { notifyFailed } from './toaster';
import { FormAPI } from './api';
import { ProjectAPI } from './api';
import { ProjectsGroupAPI } from './api';
import { AccountAPI } from './api';
import { EkphrasisAPI } from './api';
import { TemplateAPI } from './api';
import { SupervisionAPI } from './api';

// 材料設備品質表單
async function GetProjectEQFormList(projectFormId, formStatus, page, setList, setTotalPages) {
  try {
    let data = await FormAPI.GetProjectEQFormList({ projectFormId: projectFormId, formStatus: formStatus, page: page });
    data.formMainEQSimpleList.forEach(ele => {
      ele._uuid = tools._UUID();
    });
    setList(data.formMainEQSimpleList);
    setTotalPages(data.pageInfo.pages);
  } catch (error) {
    notifyFailed({ Msg: '發生錯誤', isReload: false });
  }
}

// 施工抽查表單
async function GetProjectCEFormList(projectFormId, formStatus, page, setList, setTotalPages) {
  try {
    let data = await FormAPI.GetProjectCEFormList({ projectFormId: projectFormId, formStatus: formStatus, page: page });
    data.formMainCESimpleList.forEach(ele => {
      ele._uuid = tools._UUID();
    });
    setList(data.formMainCESimpleList);
    setTotalPages(data.pages);
  } catch (error) {
    notifyFailed({ Msg: '發生錯誤', isReload: false });
  }
}

// 設備功能運轉測試表
async function GetProjectEFFormList(projectFormId, formStatus, page, setList, setTotalPages) {
  try {
    let data = await FormAPI.GetProjectEFFormList({ projectFormId: projectFormId, formStatus: formStatus, page: page });
    data.formMainEFSimpleList.forEach(ele => {
      ele._uuid = tools._UUID();
    });
    setList(data.formMainEFSimpleList);
    setTotalPages(data.pageInfo.pages);
  } catch (error) {
    notifyFailed({ Msg: '發生錯誤', isReload: false });
  }
}

// 一般安全衛生查驗表列表
async function PostProjectHSFormList(hsprojectFormSelectedId, formStatus, page, setList, setTotalPages, searchHS, setProjectHSIsNCO) {
  try {
    let searchVM = {
      ...searchHS,
      projectFormId: hsprojectFormSelectedId,
      formStatus: formStatus,
      page: page
    };
    let data = await FormAPI.PostProjectHSFormList(searchVM);
    data.formMainHSSimpleList.forEach(ele => {
      ele._uuid = tools._UUID();
    });
    setList(data.formMainHSSimpleList);
    setProjectHSIsNCO(data.isNCO);
    setTotalPages(data.pageInfo.pages);
  } catch (error) {
    notifyFailed({Msg: '發生錯誤', isReload: false});
  }
}
// 異常(不合格)通知暨改善表
async function PostAbnormalList(formStatus, page, setList, setTotalPages, abnormalSearch) {
  try {
    let data = await FormAPI.PostAbnormalList({
      ...abnormalSearch,
      formStatus: formStatus,
      caseStatus: abnormalSearch.caseStatus !== '' ? Number(abnormalSearch.caseStatus) : '',
      page: page
    });
    data.abnormalList.forEach(ele => {
      ele._uuid = tools._UUID();
    });
    setList(data.abnormalList);
    setTotalPages(data.pageInfo.pages);
  } catch (error) {
    notifyFailed({Msg: '發生錯誤', isReload: false});
  }
}
// 監督日報表 清單
async function PostSupervisionFormList(formStatus, page, setList, setTotalPages, supervisionSearch) {
  try {
    let data = await SupervisionAPI.PostSupervisionFormList({
      ...supervisionSearch,
      formStatus: formStatus,
      page: page
    });
    data.supervisionDailyList.forEach(ele => {
      ele._uuid = tools._UUID();
    });
    setList(data.supervisionDailyList);
    setTotalPages(data.pageInfo.pages);
  } catch (error) {
    notifyFailed({ Msg: '發生錯誤1', isReload: false });
  }
}
//月報清單
async function PostMonthlyFormList(formStatus, page, setList, setTotalPages, monthlySearch) {
  try{
    let data = await SupervisionAPI.PostMonthlyFormList({
      ...monthlySearch,
      formStatus:formStatus,
      page: page
    });
    data.supervisionMonthlyList.forEach(ele => {
      ele._uuid = tools._UUID();
    });
    setList(data.supervisionMonthlyList);
    setTotalPages(data.pageInfo.pages);
  } catch (error) {
    notifyFailed({ Msg: '發生錯誤', isReload: false});
  }
}
//月報總覽清單
async function PostProjectMonthlyFormList(formStatus, page, setList, setTotalPages, projectMonthlySearch) {
  try{
    let data = await SupervisionAPI.PostProjectMonthlyFormList({
      ...projectMonthlySearch,
      formStatus: formStatus,
      page: page
    });
    data.projectMonthlyList.forEach(ele => {
      ele._uuid = tools._UUID();
    });
    setList(data.projectMonthlyList);
    setTotalPages(data.pageInfo.pages);
  } catch (error) {
    notifyFailed({ Msg: '發生錯誤', isReload: false});
  }
}

// 專案管理
async function GetProjectManageList(page, keyword, setList, setTotalPages) {
  // let kword = keyword ? keyword : '';
  try {
    let data = await ProjectAPI.GetProjectManageList({ page: page, keyword: keyword });
    setList(data.projectLsit);
    setTotalPages(data.pageInfo.pages);
  } catch (error) {
    notifyFailed({ Msg: '發生錯誤', isReload: true });
  }
}

// 專案群組管理
async function GetProjectGroupList(page, keyword, setList, setTotalPages) {
  try {
    let data = await ProjectsGroupAPI.GetProjectGroupList({ page: page, keyword: keyword });
    setList(data.projectGroupLsit);
    setTotalPages(data.pageInfo.pages);
  } catch (error) {
    notifyFailed({ Msg: '發生錯誤', isReload: true });
  }
}

// 系統設定-使用者設定
async function GetUserListBypage(page, keyword, setList, setTotalPages) {
  try {
    let data = await AccountAPI.GetUserListBypage({ page: page, keyword: keyword });
    setList(data.manageUserList);
    setTotalPages(data.pageInfo.pages);
  } catch (error) {
    notifyFailed({ Msg: '發生錯誤', isReload: true });
  }
}

// 使用者-影像章管理
async function GetUserStampList(page, setList, setTotalPages) {
  try {
    let data = await AccountAPI.GetUserStampList({ page: page });
    setList(data.userStampList);
    setTotalPages(data.pageInfo.pages);
  } catch (error) {
    notifyFailed({ Msg: '發生錯誤', isReload: true });
  }
}

// 使用者-帳號管理
async function GetUserInfoByPage(page, username, setList, setTotalPages) {
  try {
    let data = await AccountAPI.GetUserInfoByPage({ page: page, username: username });
    setList(data.projectRoles.projectRolesList);
    setTotalPages(data.projectRoles.pageInfo.pages);
  } catch (error) {
    notifyFailed({ Msg: '發生錯誤', isReload: true });
  }
}

// 取得專案表單列表
async function GetProjectFormList(page, projectId, moduleId, setList, setTotalPages) {
  try {
    let data = await ProjectAPI.GetProjectFormList({ page: page, projectId: projectId, moduleId: moduleId });
    setList(data.formManageList);
    setTotalPages(data.pageInfo.pages);
  } catch (error) {
    notifyFailed({ Msg: '發生錯誤', isReload: true });
  }
}

// 取得圖說項目列表 GetEkphrasisList
async function GetEkphrasisList(page, ekphrasisEditionId, setList, setTotalPages) {
  try {
    let data = await EkphrasisAPI.GetEkphrasisList({ ekphrasisEditionId: ekphrasisEditionId, page: page });
    data.ekphrasisList.forEach(ele => {
      ele._uuid = tools._UUID();
      ele.enable = true;
      ele.status = 0;
      ele.isExist = true;
      ele.isEditing = false;
    });
    setList(data.ekphrasisList);
    setTotalPages(data.pageInfo.pages);
  } catch (error) {
    notifyFailed({ Msg: '發生錯誤', isReload: true });
  }
}

// 取得系統範本表單列表
async function GetTemplateFormList(page, moduleId, setList, setTotalPages) {
  try {
    console.log('page:'+ page);
    console.log('moduleId:'+ moduleId);
    let data = await TemplateAPI.GetTemplateFormList({ moduleId: moduleId, page: page });
    console.log('page:' + page);
    setList(data.formManageList);
    setTotalPages(data.pageInfo.pages);
  } catch (error) {
    notifyFailed({ Msg: '發生錯誤111', isReload: true });
  }
}


function FetchList({ apiFunc, projectFormSelectedId, formStatus, targetPage, setList, setTotalPages, keyword, searchHS, setProjectHSIsNCO, abnormalSearch, supervisionSearch, monthlySearch, projectMonthlySearch, username, projectId, moduleId, ekphrasisEditionId, hsprojectFormSelectedId }) {
  switch (apiFunc) {
  case 'GetProjectEQFormList':
    GetProjectEQFormList(projectFormSelectedId, formStatus, targetPage, setList, setTotalPages);
    break;
  case 'GetProjectCEFormList':
    GetProjectCEFormList(projectFormSelectedId, formStatus, targetPage, setList, setTotalPages);
    break;
  case 'GetProjectEFFormList':
    GetProjectEFFormList(projectFormSelectedId, formStatus, targetPage, setList, setTotalPages);
    break;
  case 'PostProjectHSFormList':
    PostProjectHSFormList(hsprojectFormSelectedId, formStatus, targetPage, setList, setTotalPages, searchHS, setProjectHSIsNCO);
    break;
  case 'PostAbnormalList':
    PostAbnormalList(formStatus, targetPage, setList, setTotalPages, abnormalSearch);
    break;
  case 'PostSupervisionFormList':
    PostSupervisionFormList(formStatus, targetPage, setList, setTotalPages, supervisionSearch);
    break;
  case 'PostMonthlyFormList':
    PostMonthlyFormList(formStatus, targetPage, setList, setTotalPages, monthlySearch);
    break;
  case 'PostProjectMonthlyFormList':
    PostProjectMonthlyFormList(formStatus, targetPage, setList, setTotalPages,projectMonthlySearch);
    break;
  case 'GetProjectManageList':
    GetProjectManageList( targetPage, keyword, setList, setTotalPages);
    break;
  case 'GetProjectGroupList':
    GetProjectGroupList( targetPage, keyword, setList, setTotalPages);
    break;
  case 'GetUserStampList':
    GetUserStampList( targetPage, setList, setTotalPages);
    break;
  case 'GetUserList':
    GetUserListBypage(targetPage, keyword, setList, setTotalPages);
    break;
  case 'GetUserInfoByPage':
    GetUserInfoByPage(targetPage, username, setList, setTotalPages);
    break;
  case 'GetProjectFormList':
    GetProjectFormList(targetPage, projectId, moduleId, setList, setTotalPages);
    break;
  case 'GetEkphrasisList':
    GetEkphrasisList(targetPage, ekphrasisEditionId, setList, setTotalPages);
    break;
  case 'GetTemplateFormList':
    GetTemplateFormList(targetPage, moduleId, setList, setTotalPages);
    break;
  case 'xxx':
    console.log('xxx');
  }
}

// 到第一頁
export const goFirstPage = ({ setCurrentPage, apiFunc, projectFormSelectedId, formStatus, setList, setTotalPages, keyword, searchHS, setProjectHSIsNCO, abnormalSearch, supervisionSearch, monthlySearch, projectMonthlySearch, username, projectId, moduleId, ekphrasisEditionId, hsprojectFormSelectedId }) => {
  let targetPage = 1;
  setCurrentPage(targetPage);
  FetchList({ apiFunc, projectFormSelectedId, formStatus, targetPage, setList, setTotalPages, keyword, searchHS, setProjectHSIsNCO, abnormalSearch, supervisionSearch, monthlySearch, projectMonthlySearch, username, projectId, moduleId, ekphrasisEditionId, hsprojectFormSelectedId });
};

// 至前一頁
export const goPreviousPage = ({ currentPage, setCurrentPage, apiFunc, projectFormSelectedId, formStatus, setList, setTotalPages, keyword, searchHS, setProjectHSIsNCO, abnormalSearch, supervisionSearch, monthlySearch, projectMonthlySearch, username, projectId, moduleId, ekphrasisEditionId, hsprojectFormSelectedId }) => {
  let targetPage = currentPage - 1;
  setCurrentPage(targetPage);
  FetchList({ apiFunc, projectFormSelectedId, formStatus, targetPage, setList, setTotalPages, keyword, searchHS, setProjectHSIsNCO, abnormalSearch, supervisionSearch, monthlySearch, projectMonthlySearch, username, projectId, moduleId, ekphrasisEditionId, hsprojectFormSelectedId });
};

// 至下一頁
export const goNextPage = ({ currentPage, setCurrentPage, apiFunc, projectFormSelectedId, formStatus, setList, setTotalPages, keyword, searchHS, setProjectHSIsNCO, abnormalSearch, supervisionSearch, monthlySearch, projectMonthlySearch, username, projectId, moduleId, ekphrasisEditionId, hsprojectFormSelectedId }) => {
  let targetPage = currentPage + 1;
  setCurrentPage(targetPage);
  FetchList({ apiFunc, projectFormSelectedId, formStatus, targetPage, setList, setTotalPages, keyword, searchHS, setProjectHSIsNCO, abnormalSearch, supervisionSearch, monthlySearch, projectMonthlySearch, username, projectId, moduleId, ekphrasisEditionId, hsprojectFormSelectedId });
};

// 到最後頁
export const goLastPage = ({ totalPages, setCurrentPage, apiFunc, projectFormSelectedId, formStatus, setList, setTotalPages, keyword, searchHS, setProjectHSIsNCO, abnormalSearch, supervisionSearch, monthlySearch, projectMonthlySearch, username, projectId, moduleId, ekphrasisEditionId, hsprojectFormSelectedId }) => {
  let targetPage = totalPages;
  setCurrentPage(targetPage);
  FetchList({ apiFunc, projectFormSelectedId, formStatus, targetPage, setList, setTotalPages, keyword, searchHS, setProjectHSIsNCO, abnormalSearch, supervisionSearch, monthlySearch, projectMonthlySearch, username, projectId, moduleId, ekphrasisEditionId, hsprojectFormSelectedId });
};

const Pagination = (props) => {

  // const goFirstPage = () => {
  //   props.setCurrentPage(1);
  //   switch (props.apiFunc) {
  //   case 'GetProjectCEFormList':
  //     GetProjectCEFormList(props.projectFormSelected.id, props.formStatus, 1);
  //     break;
  //   case 'xxx':
  //     console.log('xxx');
  //   }
  // };

  // const goPreviousPage = () => {
  //   props.setCurrentPage(props.currentPage - 1);
  //   switch (props.apiFunc) {
  //   case 'GetProjectCEFormList':
  //     // API 會打 props.currentPage - 1 是因為 setCurrentPage 有時差
  //     GetProjectCEFormList(props.projectFormSelected.id, props.formStatus, props.currentPage - 1);
  //     break;
  //   case 'GetProjectManageList':
  //     GetProjectManageList(props.currentPage - 1, props.keyword);
  //     break;
  //   case 'xxx':
  //     console.log('xxx');
  //   }
  // };

  // async function GetProjectCEFormList(projectFormId, formStatus, page) {
  //   try {
  //     let data = await FormAPI.GetProjectCEFormList({ projectFormId: projectFormId, formStatus: formStatus, page: page });
  //     data.formMainCESimpleList.forEach(ele => {
  //       ele._uuid = tools._UUID();
  //     });
  //     props.setList(data.formMainCESimpleList);
  //   } catch (error) {
  //     notifyFailed({ Msg: '發生錯誤', isReload: false });
  //   }
  // }

  return (
    <>
      <div className="pagesbar">
        <ol className="page">

          {props.currentPage >= 3 ?
            (
              <li className="">
                <button title="到第一頁" onClick={() => goFirstPage({
                  setCurrentPage: props.setCurrentPage,
                  apiFunc: props.apiFunc,
                  // projectFormSelectedId: props.projectFormSelected && props.projectFormSelected.id,
                  projectFormSelectedId: props.projectFormSelected && props.pagesModuleId,
                  hsprojectFormSelectedId: props.projectFormSelected && (props.pagesModuleId || props.projectFormSelected.id),
                  formStatus: props.formStatus,
                  setList: props.setList,
                  setTotalPages: props.setTotalPages,
                  keyword: props.keyword,
                  searchHS: props.searchHS,
                  setProjectHSIsNCO: props.setProjectHSIsNCO,
                  abnormalSearch: props.abnormalSearch,
                  supervisionSearch: props.supervisionSearch,
                  monthlySearch: props.monthlySearch,
                  projectMonthlySearch: props.projectMonthlySearch,
                  username: props.username,
                  projectId: props.projectId,
                  moduleId: props.moduleId,
                  ekphrasisEditionId: props.ekphrasisEditionId
                })}>
                  {1}
                </button>
              </li>
            ) : null
          }
          {/* ===== */}
          {props.currentPage >= 4 ?
            (
              <span className="PageMore"> ... </span>
            ) : null
          }
          {/* ===== */}
          {props.currentPage > 1 ?
            (
              <li className="">
                <button title="往前一頁" onClick={() => goPreviousPage({
                  currentPage: props.currentPage,
                  setCurrentPage: props.setCurrentPage,
                  apiFunc: props.apiFunc,
                  // projectFormSelectedId: props.projectFormSelected && props.projectFormSelected.id,
                  projectFormSelectedId: props.projectFormSelected && props.pagesModuleId,
                  hsprojectFormSelectedId: props.projectFormSelected && (props.pagesModuleId || props.projectFormSelected.id),
                  formStatus: props.formStatus,
                  setList: props.setList,
                  setTotalPages: props.setTotalPages,
                  keyword: props.keyword,
                  searchHS: props.searchHS,
                  setProjectHSIsNCO: props.setProjectHSIsNCO,
                  abnormalSearch: props.abnormalSearch,
                  supervisionSearch: props.supervisionSearch,
                  monthlySearch: props.monthlySearch,
                  projectMonthlySearch: props.projectMonthlySearch,
                  username: props.username,
                  projectId: props.projectId,
                  moduleId: props.moduleId,
                  ekphrasisEditionId: props.ekphrasisEditionId
                })}>
                  {props.currentPage - 1}
                </button>
              </li>
            ) : null
          }
          {/* ===== */}
          {props.currentPage === props.currentPage && props.totalPages >= 1 ?
            (
              <li className="li_PageFocus">
                <button title="當前頁" className="PageFocus">{props.currentPage}
                </button>
              </li>
            ) : null
          }
          {/* ===== */}
          {(props.currentPage + 1) !== props.totalPages && props.currentPage < props.totalPages ?
            (
              <li className="" >
                <button title="往後一頁" onClick={() => goNextPage({
                  currentPage: props.currentPage,
                  setCurrentPage: props.setCurrentPage,
                  apiFunc: props.apiFunc,
                  // projectFormSelectedId: props.projectFormSelected && props.projectFormSelected.id,
                  projectFormSelectedId: props.projectFormSelected && props.pagesModuleId,
                  hsprojectFormSelectedId: props.projectFormSelected && (props.pagesModuleId || props.projectFormSelected.id),
                  formStatus: props.formStatus,
                  setList: props.setList,
                  setTotalPages: props.setTotalPages,
                  keyword: props.keyword,
                  searchHS: props.searchHS,
                  setProjectHSIsNCO: props.setProjectHSIsNCO,
                  abnormalSearch: props.abnormalSearch,
                  supervisionSearch: props.supervisionSearch,
                  monthlySearch: props.monthlySearch,
                  projectMonthlySearch: props.projectMonthlySearch,
                  username: props.username,
                  projectId: props.projectId,
                  moduleId: props.moduleId,
                  ekphrasisEditionId: props.ekphrasisEditionId
                })}>
                  {props.currentPage + 1}
                </button>
              </li>
            ) : null
          }
          {/* ===== */}
          {
            props.currentPage !== props.totalPages && (props.currentPage + 2) < props.totalPages ?
              (
                <span className="PageMore"> ... </span>
              ) : null
          }
          {/* ===== */}
          {
            props.currentPage !== props.totalPages && props.currentPage < props.totalPages ?
              (
                <li className="" >

                  <button title="到最後頁" onClick={() => goLastPage({
                    totalPages: props.totalPages,
                    setCurrentPage: props.setCurrentPage,
                    apiFunc: props.apiFunc,
                    // projectFormSelectedId: props.projectFormSelected && props.projectFormSelected.id,
                    projectFormSelectedId: props.projectFormSelected && props.pagesModuleId,
                    hsprojectFormSelectedId: props.projectFormSelected && (props.pagesModuleId || props.projectFormSelected.id),
                    formStatus: props.formStatus,
                    setList: props.setList,
                    setTotalPages: props.setTotalPages,
                    keyword: props.keyword,
                    searchHS: props.searchHS,
                    setProjectHSIsNCO: props.setProjectHSIsNCO,
                    abnormalSearch: props.abnormalSearch,
                    supervisionSearch: props.supervisionSearch,
                    monthlySearch: props.monthlySearch,
                    projectMonthlySearch: props.projectMonthlySearch,
                    username: props.username,
                    projectId: props.projectId,
                    moduleId: props.moduleId,
                    ekphrasisEditionId: props.ekphrasisEditionId
                  })}>
                    {props.totalPages}
                  </button>
                </li >
              ) : null
          }

        </ol >
      </div>
    </>
  );
};
export default Pagination;
