// import "./../../style/Form.scss";
import styled from '@emotion/styled';
import { useState, useEffect, useContext } from 'react';
import { ProjectContext } from '../contexts/ProjectContext';
import { ProjectAPI } from "../assets/api";
import tools from '../assets/tools';
import { notifyFailed, notifySuccess, notifyWarning } from '../assets/toaster';
import { ToastContainer } from 'react-toastify';

const TxtInput = styled.textarea`
  width: 100%;
  max-width: 22em;
  // height: 28px;
  border-radius: 3px;
  border: 1px solid #AAA;
  padding: 5px;
  box-sizing: border-box;
`;

const CreateToolItem = (props) => {
  return (
    <tr>
      <td></td>
      <td><TxtInput value={props.item.name || ''} onChange={props.handleItemNameChange} /></td>
      <td>
        <button className="btnM btnRed" onClick={props.handleItemDelete}>刪除</button>
      </td>
    </tr>
  );
};

const ImplementsManage = () => {

  const { projectInfo } = useContext(ProjectContext);
  let projectid = projectInfo.id;

  const [projectName, setProjectName] = useState([]);
  const [toolsList, setToolsList] = useState([]);
  const [originalToolsList, setOriginalToolsList] = useState({});
  const [type, setType] = useState(1);

  const [isSaving, setIsSaving] = useState(false);


  function ToolItem({ _uuid = 0, id = 0, name = '', type = 0, status = 0, isExist = false, isEditing = true }) {
    this._uuid = _uuid;
    this.id = id;
    this.projectID = projectid;
    this.name = name;
    this.type = type;
    this.status = status;
    this.isExist = isExist;
    this.isEditing = isEditing;
  }

  async function GetPositionToolsManageInfo(projectId, type) {
    try {
      let data = await ProjectAPI.GetPositionToolsManageInfo(projectId, type || 1);
      data.positionToolsManageList.forEach(ele => {
        ele._uuid = tools._UUID();
        ele.status = 0;
        ele.isExist = true; // Is this item already existed in database
        ele.isEditing = false; // Is this item being editted
      });
      setToolsList(data.positionToolsManageList);
      setOriginalToolsList(data.positionToolsManageList);
      setProjectName(data.projectName);
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: true });
    }
  }

  const handleTypeSelect = (e) => {
    setType(e.target.value);
  };

  const handleItemCreate = () => {
    // setToolsList({ toolsList: [...toolsList, new WeatherItem({ _uuid: tools._UUID(), status: 1 })] });
    setToolsList([...toolsList, new ToolItem({ _uuid: tools._UUID(), status: 1 })]);
  };

  const handleItemUpdate = (updateItem) => {
    // setToolsList(toolsList.map(ele => {ele._uuid === updateItem._uuid ? { ...ele, status: 2, isEditing: true } : ele;}));
    setToolsList(toolsList.map(ele => (ele._uuid === updateItem._uuid ? { ...ele, status: 2, isEditing: true } : ele)));
  };

  const handleCancelItemUpdate = (updateItem) => {
    setToolsList(toolsList.map(ele => (ele._uuid === updateItem._uuid ? originalToolsList.find(ele1 => ele1._uuid === updateItem._uuid) : ele)));
  };

  const handleItemNameChange = (e, el) => {
    setToolsList(toolsList.map(ele => (ele._uuid === el._uuid ? { ...ele, name: e.target.value } : ele)));
  };

  const handleItemDelete = (deleteItem) => {
    notifyWarning({ Msg: <DeleteConfirm deleteItem={deleteItem} /> });
  };

  const DeleteConfirm = ({ closeToast, deleteItem }) => (
    <div>
      刪除機具項目？
      <button className="btnS btnRed" onClick={() => DeleteItem(deleteItem)}>確定刪除</button>
      <button className="btnS btnWhite" onClick={closeToast}>取消</button>
    </div>
  );

  const DeleteItem = async (deleteItem) => {
    if (deleteItem.isExist) {
      // setToolsList({ toolsList: toolsList.map(ele => (ele._uuid === deleteItem._uuid ? { ...ele, status: 3 } : ele)) });
      // setToolsList(toolsList.map(ele =>{
      //   ele._uuid === deleteItem._uuid ? { ...ele, status: 3 } : ele;
      // }));
      setToolsList(toolsList.map(ele => (ele._uuid === deleteItem._uuid ? { ...ele, status: 3 } : ele)));

    } else {
      setToolsList({ toolsList: toolsList.filter(ele => ele._uuid !== deleteItem._uuid) });
    }
  };

  const ModifyToolsForm = async () => {
    setIsSaving(true);
    // if (toolsList.length >= 0) {
    let ToolsModify = [];
    for (let i = 0; i < toolsList.length; i++) {
      ToolsModify.push(
        {
          "id": toolsList[i].id || 0,
          "projectid": Number(projectid),
          "name": toolsList[i].name || "",
          "type": type,
          "status": toolsList[i].status || 0,
        }
      );
    }
    try {
      // console.log(projectid);
      await ProjectAPI.ModifyPositionToolsManage({ projectId: projectid, ToolsModify });
      notifySuccess({ Msg: '儲存成功', cbfunc: () => window.location.reload() });
      setIsSaving(false);
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
      setIsSaving(false);
    }
  //   } else {
  //     notifyFailed({ Msg: '請至少輸入N筆。', isReload: false });
  //   }
  };

  const handleSearch = async () => {
    await GetPositionToolsManageInfo(projectid, type);
  };

  useEffect(() => {
    GetPositionToolsManageInfo(projectid);
  }, []);

  return (
    <>
      <div className="bumper_rail"></div>
      <div className="commontitle">
        <h1>工種與機具資料管理</h1>
      </div>

      <div className="filter_table">
        <div className="filter_table_input">
          <div className="">專案名稱：</div>
          <div className="">{projectName}</div>
        </div>
      </div>
      <div className="filter_table">
        <div className="filter_table_input">
          <div className="">類型：</div>
          <div className="">
            <select name="type" onChange={(event) => handleTypeSelect(event)} value={type}>
              <option value={1}>工種別</option>
              <option value={2}>機具別</option>
            </select>
          </div>
        </div>
        <div className="filter_table_btn"><button className="btnM btnBlue" onClick={handleSearch}>切換</button></div>
      </div>

      <div className="table">
        <table>
          <tbody>
            <tr>
              <td>項次</td>
              <td>名稱</td>
              <td><button className="btnM btnWhite" onClick={handleItemCreate}>新增</button></td>
            </tr>
            {
              toolsList.length > 0 ? toolsList.filter(ele => ele.status !== 3).map((ele, idx) => {
                return (
                  ele.isExist ?
                    <tr key={ele._uuid}>
                      {ele.isEditing ?
                        (
                          <>
                            <td>{idx + 1}</td>
                            <td><TxtInput value={ele.name || ''} onChange={(evt) => handleItemNameChange(evt, ele)} /></td>
                          </>
                        ) :
                        (
                          <>
                            <td>{idx + 1}</td>
                            <td>{ele.name}</td>
                          </>
                        )
                      }
                      <td>
                        {
                          ele.isEditing ?
                            <button className="btnM btnBlue" onClick={() => handleCancelItemUpdate(ele)}>取消</button> :
                            <button className="btnM btnBlue" onClick={() => handleItemUpdate(ele)}>修改</button>
                        }
                        <button className="btnM btnRed" onClick={() => handleItemDelete(ele)}>刪除</button>
                      </td>
                    </tr> :
                    <CreateToolItem
                      toolsList={toolsList}
                      item={ele}
                      key={ele._uuid}
                      handleItemNameChange={(evt) => handleItemNameChange(evt, ele)}
                      handleItemDelete={() => handleItemDelete(ele)}
                    />
                );
              })
                : <tr></tr>
            }
          </tbody>
        </table>
      </div>
      <ToastContainer />

      <div className="btn_box">
        <button className="btnL btnBlue" onClick={ModifyToolsForm} disabled={isSaving}>
          {
            isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '存檔'
          }
        </button>
      </div>

    </>
  );
};
export default ImplementsManage;