import API from "./ApiConfig";

const FormAPI = {
  // 取得施工抽查預設表單內容
  GetDefaultCEForm: (projectFormId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Form/GetDefaultCEForm/${projectFormId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得表單預設項目
  GetDefaultItemList: (projectFormId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Form/GetDefaultItemList/${projectFormId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得專案所有施工抽查紀錄列表各狀態數量
  GetProjectCEFormNumList: ({ projectFormId }) => {
    return new Promise((resolve, reject) => {
      API.get(`/Form/GetProjectCEFormNumList/${projectFormId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得專案所有施工抽查紀錄列表(main) 表單狀態,0:草稿,1:審核中,2:已結案,3:已刪除,4:真刪除
  GetProjectCEFormList: ({ projectFormId, formStatus, page }) => {
    return new Promise((resolve, reject) => {
      API.get(`/Form/GetProjectCEFormList/${projectFormId}/${formStatus}/${page}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 新增/修改施工抽查紀錄表
  ModifyCEForm: (modifyCEForm) => {
    return new Promise((resolve, reject) => {
      API.post(`/Form/ModifyCEForm`, modifyCEForm)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得施工抽查紀錄列表資料(main)
  GetConstructExamineInfo: (constructExamineId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Form/GetConstructExamineInfo/${constructExamineId }`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 表單簽核
  PostSignOff: (modifyFormSignOff) => {
    return new Promise((resolve, reject) => {
      API.post(`/Form/PostSignOff/`, modifyFormSignOff)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得預設異常表單內容 (停用)
  GetDefaultAbnormalForm: (projectId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Form/GetDefaultAbnormalForm/${projectId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得異常紀錄表資料(main) 預設異常表abnormalId傳0
  GetAbnormalInfo: ({ projectId, abnormalId }) => {
    return new Promise((resolve, reject) => {
      API.get(`/Form/GetAbnormalInfo/${projectId}/${abnormalId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 新增/修改異常表
  ModifyANForm: (modifyAbnormalForm) => {
    return new Promise((resolve, reject) => {
      API.post(`/Form/ModifyANForm/`, modifyAbnormalForm)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 退回表單
  ReturnSignOff: (returnForm) => {
    return new Promise((resolve, reject) => {
      API.post(`/Form/ReturnSignOff/`, returnForm)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 刪除施工抽查紀錄表
  DeleteCEForm: (deleteCEForm) => {
    return new Promise((resolve, reject) => {
      API.post(`/Form/DeleteCEForm/`, deleteCEForm)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 搜尋異常列表
  PostAbnormalList: (abnormalSearch) => {
    return new Promise((resolve, reject) => {
      API.post(`/Form/PostAbnormalList/`, abnormalSearch)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得專案所有異常列表各狀態數量
  PostAbnormalNumList: (abnormalSearch) => {
    return new Promise((resolve, reject) => {
      API.post(`/Form/PostAbnormalNumList/`, abnormalSearch)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 異常不合格樣態下拉清單(依project)
  GetAbnormalCategoryList: ({ projectId }) => {
    return new Promise((resolve, reject) => {
      API.get(`/Form/GetAbnormalCategoryList/${projectId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 新增/修改異常不合格樣態
  ModifyAbnormalCategory: ({ modifyAbnormalCategory }) => {
    return new Promise((resolve, reject) => {
      API.post(`/Form/ModifyAbnormalCategory`, modifyAbnormalCategory)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 修改異常追蹤
  ModifyAbnormalTrack: ({ projectId, modifyAbnormalTrack }) => {
    return new Promise((resolve, reject) => {
      API.post(`/Form/ModifyAbnormalTrack/${projectId}`, modifyAbnormalTrack)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 刪除表單(通用)
  DeleteForm: (deleteForm) => {
    return new Promise((resolve, reject) => {
      API.post(`/Form/DeleteForm/`, deleteForm)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 還原表單(通用)
  RestoreForm: (deleteForm) => {
    return new Promise((resolve, reject) => {
      API.post(`/Form/RestoreForm/`, deleteForm)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得專案所有安衛表單列表(main)
  PostProjectHSFormList: (formMainHSListSearch) => {
    return new Promise((resolve, reject) => {
      API.post(`/Form/PostProjectHSFormList/`, formMainHSListSearch)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得預設安衛表單內容
  GetDefaultHSForm: (projectFormId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Form/GetDefaultHSForm/${projectFormId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 新增/修改一般安全衛生查驗表
  ModifyHSForm: (modifyHSForm) => {
    return new Promise((resolve, reject) => {
      API.post(`/Form/ModifyHSForm/`, modifyHSForm)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得安衛表單資料(main)
  GetHSExamineInfo: (hsExamineId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Form/GetHSExamineInfo/${hsExamineId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得專案所有安衛查驗列表各狀態數量
  PostProjectHSFormNumList: (formMainHSListSearch) => {
    return new Promise((resolve, reject) => {
      API.post(`/Form/PostProjectHSFormNumList`, formMainHSListSearch)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得設備功能運轉測試預設表單內容
  GetDefaultEFForm: (projectFormId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Form/GetDefaultEFForm/${projectFormId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得專案所有設備功能運轉測試表列表(main)
  GetProjectEFFormList: ({ projectFormId, formStatus, page }) => {
    return new Promise((resolve, reject) => {
      API.get(`/Form/GetProjectEFFormList/${projectFormId}/${formStatus}/${page}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得專案所有設備功能運轉測列表各狀態數量
  GetProjectEFFormNumList: ({ projectFormId }) => {
    return new Promise((resolve, reject) => {
      API.get(`/Form/GetProjectEFFormNumList/${projectFormId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 新增/修改設備功能運轉測表
  ModifyEFForm: (modifyEFForm) => {
    return new Promise((resolve, reject) => {
      API.post(`/Form/ModifyEFForm/`, modifyEFForm)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得設備功能運轉測資料(main)
  GetEFFormInfo: (equipFuncId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Form/GetEFFormInfo/${equipFuncId }`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得材料設備品質預設表單內容
  GetDefaultEQForm: (projectFormId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Form/GetDefaultEQForm/${projectFormId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得專案所有材料設備品質列表(main)
  GetProjectEQFormList: ({ projectFormId, formStatus, page }) => {
    return new Promise((resolve, reject) => {
      API.get(`/Form/GetProjectEQFormList/${projectFormId}/${formStatus}/${page}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得專案所有材料設備品質列表各狀態數量
  GetProjectEQFormNumList: ({ projectFormId }) => {
    return new Promise((resolve, reject) => {
      API.get(`/Form/GetProjectEQFormNumList/${projectFormId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 新增/修改材料設備品質測試表
  ModifyEQForm: (modifyEQForm) => {
    return new Promise((resolve, reject) => {
      API.post(`/Form/ModifyEQForm/`, modifyEQForm)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得材料設備品質資料(main)
  GetEQFormInfo: (eqipQId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Form/GetEQFormInfo/${eqipQId }`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetProjectFormListForAN: (projectId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Form/GetProjectFormListForAN/${projectId}`)
        .then(rs=> {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export default FormAPI;