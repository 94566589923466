// import "./../../style/Form.scss";
import styled from '@emotion/styled';
import { useParams, useHistory } from 'react-router-dom';

import { AccountAPI } from './../assets/api';
import tools from './../assets/tools';

import { notifyFailed, notifySuccess } from './../assets/toaster';
import { ToastContainer } from 'react-toastify';
import { useEffect, useRef, useState } from 'react';

const TxtInput = styled.input`
  width: 100%;
  max-width: 25em;
  height: 28px;
  border-radius: 3px;
  border: 1px solid #999;
  padding: 0 5px;
  box-sizing: border-box;
`;

const Demarcation = styled.div`
  width: 100%;
  height: 1px;
  background-color: #CCC;
  margin: 40px 0;
`;

const SubTitle = styled.p`
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 1rem;
`;

const PwdEye = styled.span`
    color: #676464;
    position: absolute;
    right: 6px;
    top: 6px;
`;

const EyeBox = styled.div`
    width: 100%;
    max-width: 25em;
    text-align: right;
    position: relative;
    top: -28px;
    height: 0;
`;

const PwdValid = styled.span`
  color: red;
`;

const UserDetail = () => {
  const [pageStatus, setPageStatus] = useState(0);
  const { username } = useParams();
  const history = useHistory();
  const [userInfo, setUSerInfo] = useState({
    userName: '',
    name_ZHTW: '',
    name_EN: '',
    email: '',
    phoneNumber: '',
    enable: '',
    projectRoles: []
  });

  const [pwd, setPwd] = useState({
    'newPassword': '',
    'newPasswordCheck': ''
  });

  const [pwdValid, setPwdValid] = useState(null);
  const [pwdCheckValid, setPwdCheckValid] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  const handlePwdChange = (e) => {
    setPwd({...pwd, newPassword: e.target.value});
    setPwdValid(tools.CheckPassword(e.target.value));
  };

  const handlePwdCheckChange = (e) => {
    setPwd({...pwd, newPasswordCheck: e.target.value});
    setPwdCheckValid(tools.CheckPassword(e.target.value));
  };

  const GetUserInfo = async() => {
    try {
      let data = await AccountAPI.GetUserInfo(username);
      setUSerInfo(data);
    } catch (error) {
      notifyFailed(`發生錯誤`, false);
    }
  };

  const Modify = async() => {
    // console.log(pageStatus);
    setIsSaving(true);
    // let accountModify = {
    //   'userName': userInfo.userName,
    //   'name_ZHTW': userInfo.name_ZHTW,
    //   'name_EN': userInfo.name_EN,
    //   'email': userInfo.email,
    //   'phoneNumber': userInfo.phoneNumber,
    //   'newPassword': pwd.newPassword,
    //   'newPasswordCheck': pwd.newPasswordCheck,
    //   'enable': userInfo.enable
    // };
    // if (pwd.newPassword !== pwd.newPasswordCheck) {
    //   notifyFailed({Msg: '變更密碼需與確認密碼相同', isReload: false});
    //   return;
    // }
    // try {
    //   await AccountAPI.Modify(accountModify);
    //   notifySuccess({Msg: '儲存成功', cbfunc: async() => await GetUserInfo()});
    //   setIsSaving(false);
    // } catch (error) {
    //   notifyFailed({Msg: '儲存失敗', isReload: false});
    //   setIsSaving(false);
    // }
    if (pageStatus === 1) {
      let accountRegister = {
        'userName': userInfo.userName,
        'name_ZHTW': userInfo.name_ZHTW,
        'name_EN': userInfo.name_EN,
        'email': userInfo.email,
        'phoneNumber': userInfo.phoneNumber,
        'userPassword': pwd.newPassword,
        'userPasswordCheck': pwd.newPasswordCheck,
        'enable': userInfo.enable
      };
      if (pwd.newPassword !== pwd.newPasswordCheck) {
        notifyFailed({ Msg: '變更密碼需與確認密碼相同', isReload: false });
        return;
      }
      try {
        // console.log(accountRegister);
        await AccountAPI.Register(accountRegister);
        notifySuccess({ Msg: '儲存成功', cbfunc: () => history.push("/manage/user-manage") });
        setIsSaving(false);
      } catch (error) {
        notifyFailed({ Msg: '儲存失敗', isReload: false });
        setIsSaving(false);
      }
    } else if (pageStatus === 2) {
      let accountModify = {
        'userName': userInfo.userName,
        'name_ZHTW': userInfo.name_ZHTW,
        'name_EN': userInfo.name_EN,
        'email': userInfo.email,
        'phoneNumber': userInfo.phoneNumber,
        'newPassword': pwd.newPassword,
        'newPasswordCheck': pwd.newPasswordCheck,
        'enable': userInfo.enable
      };
      if (pwd.newPassword !== pwd.newPasswordCheck) {
        notifyFailed({Msg: '變更密碼需與確認密碼相同', isReload: false});
        return;
      }
      try {
        await AccountAPI.Modify(accountModify);
        notifySuccess({ Msg: '儲存成功', cbfunc: async() => await GetUserInfo() });
        setIsSaving(false);
      } catch (error) {
        notifyFailed({Msg: '儲存失敗', isReload: false});
        setIsSaving(false);
      }
    }
  };

  const pwdInput = useRef(null);
  const pwdInputConfirm = useRef(null);
  function togglePWD (e) {
    let toggleRef;
    if (e.target.id === 'PWDeye') {
      toggleRef = pwdInput;
    } else if (e.target.id === 'PWDeyeConfirm') {
      toggleRef = pwdInputConfirm;
    }

    if (toggleRef.current.type === 'password') {
      toggleRef.current.type = "text";
      e.target.classList.remove('fa-eye-slash');
      e.target.className += ' fa-eye';
    } else {
      toggleRef.current.type = "password";
      e.target.classList.remove('fa-eye');
      e.target.className += ' fa-eye-slash';
    }
  }

  useEffect(async() => {
    // await GetUserInfo();
    if (username !== 'create') {
      GetUserInfo();
      setPageStatus(2);
    } else if (username === 'create') {
      setPageStatus(1);
    }
  }, []);

  const handleInputChange = (e) => {
    setUSerInfo({
      ...userInfo,
      [e.target.name]: e.target.value
    });
  };

  const handleEnableChange = (e) => {
    setUSerInfo({
      ...userInfo,
      enable: e.target.checked
    });
  };

  return (
    <>
      <div className="bumper_rail"></div>
      <div className="commontitle">
        <h1>使用者明細</h1>
      </div>

      <div className="sharebox">
        <div className="form">

          <div className="form_13quarters">
            <div className="form_title">帳號：</div>
            <div className="form_input">
              {username === 'create' ? <label><TxtInput value={userInfo.userName} name='userName' onChange={handleInputChange} /></label> : <p>{userInfo.userName}</p> }
            </div>
          </div>
          <div className="form_13quarters">
            <div className="form_title">中文姓名：</div>
            <div className="form_input">
              <label>
                <TxtInput value={userInfo.name_ZHTW} name='name_ZHTW' onChange={handleInputChange}/>
              </label>
            </div>
          </div>
          <div className="form_13quarters">
            <div className="form_title">英文姓名：</div>
            <div className="form_input">
              <label>
                <TxtInput value={userInfo.name_EN} name='name_EN' onChange={handleInputChange}/>
              </label>
            </div>
          </div>
          <div className="form_13quarters">
            <div className="form_title">Email：</div>
            <div className="form_input">
              <label>
                <TxtInput value={userInfo.email} name='email' onChange={handleInputChange}/>
              </label>
            </div>
          </div>
          <div className="form_13quarters">
            <div className="form_title">電話：</div>
            <div className="form_input">
              <label>
                <TxtInput value={userInfo.phoneNumber} name='phoneNumber' onChange={handleInputChange}/>
              </label>
            </div>
          </div>
          <div className="form_13quarters">
            <div className="form_title">變更密碼：</div>
            <div className="form_input">
              <label>
                <TxtInput type='password' value={pwd.newPassword} onChange={handlePwdChange} ref={pwdInput} autocomplete="off"/>
                <EyeBox>
                  <PwdEye id="PWDeye" className="fa fa-eye-slash" onClick={togglePWD} />
                </EyeBox>
              </label>
              {pwdValid === false ? <PwdValid>密碼需至少6個字元，且包含大小寫字母及數字</PwdValid> : null}
            </div>
          </div>
          <div className="form_13quarters">
            <div className="form_title">確認密碼：</div>
            <div className="form_input">
              <label>
                <TxtInput type='password' value={pwd.newPasswordCheck} onChange={handlePwdCheckChange} ref={pwdInputConfirm}/>
                <EyeBox>
                  <PwdEye id="PWDeyeConfirm" className="fa fa-eye-slash" onClick={togglePWD}/>
                </EyeBox>
              </label>
              {pwdCheckValid === false ? <PwdValid>密碼需至少6個字元，且包含大小寫字母及數字</PwdValid> : null}
            </div>
          </div>
          <div className="form_13quarters">
            <div className="form_title"></div>
            <div className="form_input">
              <label>
                <input type="checkbox" name="enable" checked={userInfo.enable} onChange={handleEnableChange}/> 啟用
              </label>
            </div>
          </div>

          <div className="btn_box">
            <button className="btnL btnBlue" onClick={Modify} disabled={isSaving}>
              {
                isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '儲存'
              }
            </button>
          </div>

          <Demarcation />

          <SubTitle>專案列表</SubTitle>

          <div className="table">
            <table>
              <tbody>
                <tr>
                  <td style={{ minWidth:'2em', }}>項次</td>
                  <td>專案名稱</td>
                </tr>
                {
                  userInfo.projectRoles.map((ele, idx) => (
                    <tr key={tools._UUID()}>
                      <td>{idx+1}</td>
                      <td>{ele.projectName} ({ele.roleName})</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>

        </div>
      </div>
      <ToastContainer />
    </>
  );
};
export default UserDetail;