
import tools from './tools';
import { notifyFailed } from './toaster';
import { ProjectAPI } from './api';
// import { SupervisionAPI } from './api';


// 監督日報表 清單
// async function PostSupervisionFormList(formStatus, page, setList, setTotalPages, supervisionSearch) {
//   try {
//     let data = await SupervisionAPI.PostSupervisionFormList({
//       ...supervisionSearch,
//       formStatus: formStatus,
//       page: page
//     });
//     data.supervisionDailyList.forEach(ele => {
//       ele._uuid = tools._UUID();
//     });
//     setList(data.supervisionDailyList);
//     setTotalPages(data.pageInfo.pages);
//   } catch (error) {
//     notifyFailed({ Msg: '發生錯誤1', isReload: false });
//   }
// }

// 取得每日出工機具 清單
async function GetDPIList({ targetPage, setList, setTotalPages, toolsSearch, toolsFormStatus}) {
  try {
    let data = await ProjectAPI.GetDPIList({
      ...toolsSearch,
      formStatus: toolsFormStatus === 6 ? 1 : 0,
      page: targetPage
    });
    data.formMainDPISimpleList.forEach(ele => {
      ele._uuid = tools._UUID();
    });
    setList(data.formMainDPISimpleList);
    setTotalPages(data.pages);
  } catch (error) {
    // console.log(error);
    notifyFailed({ Msg: '發生錯誤?', isReload: false });
  }
}

// 取得每日出工機具 日統計 清單
async function GetDPITotalList({ targetPage, setList, setTotalPages, toolsSearch }) {
  try {
    let data = await ProjectAPI.GetDPITotalList({
      ...toolsSearch,
      formStatus: 0,
      page: targetPage
    });
    data.formMainDPITotalListSimpleList.forEach(ele => {
      ele._uuid = tools._UUID();
    });
    setList(data.formMainDPITotalListSimpleList);
    setTotalPages(data.pages);
  } catch (error) {
    notifyFailed({ Msg: '發生錯誤33', isReload: false });
  }
}

function FetchList({ formStatus, targetPage, setTotalPages, toolsSearch, setToolsList, setToolsTotalList, toolsFormStatus }) {
  if (toolsFormStatus === 4 || toolsFormStatus === 6) {
    GetDPIList({ formStatus, targetPage, setList: setToolsList, setTotalPages, toolsSearch, toolsFormStatus});
  }
  if (toolsFormStatus === 5) {
    GetDPITotalList({ formStatus, targetPage, setList: setToolsTotalList, setTotalPages, toolsSearch, toolsFormStatus });
  }
}

// 到第一頁
export const goFirstPage = ({ setCurrentPage, apiFunc, formStatus, setList, setTotalPages, supervisionSearch, toolsSearch, setToolsList, setToolsTotalList, toolsFormStatus }) => {
  let targetPage = 1;
  setCurrentPage(targetPage);
  FetchList({ apiFunc, formStatus, targetPage, setList, setTotalPages, supervisionSearch, toolsSearch, setToolsList, setToolsTotalList, toolsFormStatus });
};

// 至前一頁
export const goPreviousPage = ({ currentPage, setCurrentPage, apiFunc, formStatus, setList, setTotalPages, supervisionSearch, toolsSearch, setToolsList, setToolsTotalList, toolsFormStatus }) => {
  let targetPage = currentPage - 1;
  setCurrentPage(targetPage);
  FetchList({ apiFunc, formStatus, targetPage, setList, setTotalPages, supervisionSearch, toolsSearch, setToolsList, setToolsTotalList, toolsFormStatus });
};

// 至下一頁
export const goNextPage = ({ currentPage, setCurrentPage, apiFunc, formStatus, setList, setTotalPages, supervisionSearch, toolsSearch, setToolsList, setToolsTotalList, toolsFormStatus }) => {
  let targetPage = currentPage + 1;
  setCurrentPage(targetPage);
  FetchList({ apiFunc, formStatus, targetPage, setList, setTotalPages, supervisionSearch, toolsSearch, setToolsList, setToolsTotalList, toolsFormStatus });
};

// 到最後頁
export const goLastPage = ({ totalPages, setCurrentPage, apiFunc, formStatus, setList, setTotalPages, supervisionSearch, toolsSearch, setToolsList, setToolsTotalList, toolsFormStatus }) => {
  let targetPage = totalPages;
  setCurrentPage(targetPage);
  FetchList({ apiFunc, formStatus, targetPage, setList, setTotalPages, supervisionSearch, toolsSearch, setToolsList, setToolsTotalList, toolsFormStatus });
};

const Pagination = (props) => {
  return (
    <>
      <div className="pagesbar">
        <ol className="page">

          {props.currentPage >= 3 ?
            (
              <li className="">
                <button title="到第一頁" onClick={() => goFirstPage({
                  setCurrentPage: props.setCurrentPage,
                  apiFunc: props.apiFunc,
                  formStatus: props.formStatus,
                  setList: props.setList,
                  setTotalPages: props.setTotalPages,
                  supervisionSearch: props.supervisionSearch,
                  toolsSearch: props.toolsSearch,
                  setToolsList: props.setToolsList,
                  setToolsTotalList: props.setToolsTotalList,
                  projectId: props.projectId,
                  moduleId: props.moduleId,
                  toolsFormStatus: props.toolsFormStatus
                })}>
                  {1}
                </button>
              </li>
            ) : null
          }
          {/* ===== */}
          {props.currentPage >= 4 ?
            (
              <span className="PageMore"> ... </span>
            ) : null
          }
          {/* ===== */}
          {props.currentPage > 1 ?
            (
              <li className="">
                <button title="往前一頁" onClick={() => goPreviousPage({
                  currentPage: props.currentPage,
                  setCurrentPage: props.setCurrentPage,
                  apiFunc: props.apiFunc,
                  formStatus: props.formStatus,
                  setList: props.setList,
                  setTotalPages: props.setTotalPages,
                  supervisionSearch: props.supervisionSearch,
                  toolsSearch: props.toolsSearch,
                  setToolsList: props.setToolsList,
                  setToolsTotalList: props.setToolsTotalList,
                  projectId: props.projectId,
                  moduleId: props.moduleId,
                  toolsFormStatus: props.toolsFormStatus
                })}>
                  {props.currentPage - 1}
                </button>
              </li>
            ) : null
          }
          {/* ===== */}
          {props.currentPage === props.currentPage && props.totalPages >= 1 ?
            (
              <li className="li_PageFocus">
                <button title="當前頁" className="PageFocus">{props.currentPage}
                </button>
              </li>
            ) : null
          }
          {/* ===== */}
          {(props.currentPage + 1) !== props.totalPages && props.currentPage < props.totalPages ?
            (
              <li className="" >
                <button title="往後一頁" onClick={() => goNextPage({
                  currentPage: props.currentPage,
                  setCurrentPage: props.setCurrentPage,
                  apiFunc: props.apiFunc,
                  formStatus: props.formStatus,
                  setList: props.setList,
                  setTotalPages: props.setTotalPages,
                  supervisionSearch: props.supervisionSearch,
                  toolsSearch: props.toolsSearch,
                  setToolsList: props.setToolsList,
                  setToolsTotalList: props.setToolsTotalList,
                  projectId: props.projectId,
                  moduleId: props.moduleId,
                  toolsFormStatus: props.toolsFormStatus
                })}>
                  {props.currentPage + 1}
                </button>
              </li>
            ) : null
          }
          {/* ===== */}
          {
            props.currentPage !== props.totalPages && (props.currentPage + 2) < props.totalPages ?
              (
                <span className="PageMore"> ... </span>
              ) : null
          }
          {/* ===== */}
          {
            props.currentPage !== props.totalPages && props.currentPage < props.totalPages ?
              (
                <li className="" >

                  <button title="到最後頁" onClick={() => goLastPage({
                    totalPages: props.totalPages,
                    setCurrentPage: props.setCurrentPage,
                    apiFunc: props.apiFunc,
                    formStatus: props.formStatus,
                    setList: props.setList,
                    setTotalPages: props.setTotalPages,
                    supervisionSearch: props.supervisionSearch,
                    toolsSearch: props.toolsSearch,
                    setToolsList: props.setToolsList,
                    setToolsTotalList: props.setToolsTotalList,
                    projectId: props.projectId,
                    moduleId: props.moduleId,
                    toolsFormStatus: props.toolsFormStatus
                  })}>
                    {props.totalPages}
                  </button>
                </li >
              ) : null
          }

        </ol >
      </div>
    </>
  );
};
export default Pagination;
