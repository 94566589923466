import * as d3 from 'd3';
// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/pie-chart
export default function PieChart(data, {
  name = ([x]) => x, // given d in data, returns the (ordinal) label
  value = ([, y]) => y, // given d in data, returns the (quantitative) value
  percentage = ([z]) => z,
  title, // given d in data, returns the title text
  width = 640, // outer width, in pixels
  height = 400, // outer height, in pixels
  innerRadius = 0, // inner radius of pie, in pixels (non-zero for donut)
  outerRadius = Math.min(width, height) / 2, // outer radius of pie, in pixels
  labelRadius = (innerRadius * 0.2 + outerRadius * 0.8), // center radius of labels
  format = ",", // a format specifier for values (in the label)
  names, // array of names (the domain of the color scale)
  color, // array of colors for names
  stroke = innerRadius > 0 ? "none" : "white", // stroke separating widths
  strokeWidth = 1, // width of stroke separating wedges
  strokeLinejoin = "round", // line join of stroke separating wedges
  padAngle = stroke === "none" ? 1 / outerRadius : 0, // angular separation between wedges
  backgroundColor,
  titleText,
  titleTextColor,
} = {}) {
  // Compute values.
  const N = d3.map(data, name);
  const V = d3.map(data, value);
  const P = d3.map(data, percentage);
  const I = d3.range(N.length).filter(i => !isNaN(V[i]));
  // Unique the names.
  if (names === undefined) names = N;
  names = new d3.InternSet(names);

  // Chose a default color scheme based on cardinality.
  let colorArr = [];
  if (color === undefined) color = d3.schemeSpectral[names.size];
  if (color === undefined) color = d3.quantize(t => d3.interpolateSpectral(t * 0.8 + 0.1), names.size);
  if (color) {
    if (color.length === 0) {
      console.error('No color array detected');
      return;
    }
    colorArr = color;
  }

  // Construct scales.
  const colors = d3.scaleOrdinal(names, colorArr);
  // Compute titles.
  if (title === undefined) {
    const formatValue = d3.format(format);
    title = i => `${N[i]}\n${formatValue(V[i])}${P[i] ? '\n' + P[i] + '%' : ''}`;
  } else {
    const O = d3.map(data, d => d);
    const T = title;
    title = i => T(O[i], i, data);
  }

  // Construct arcs.
  const arcs = d3.pie().padAngle(padAngle).sort(null).value(i => V[i])(I);
  const arc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);
  const arcLabel = d3.arc().innerRadius(labelRadius).outerRadius(labelRadius);

  const svg = d3.create("svg")
    .attr("width", width)
    .attr("height", height)
    .attr("viewBox", [-width / 2, -(height / 2) -55 , width, height + 75])
    .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
    .style("background-color", backgroundColor);

  svg.append("g")
    .call(g => g.append('text')
      .attr("x", "0%")
      .attr("y", "-48%")
      .attr("fill", titleTextColor)
      .style("font-size", "24")
      .style("font-weight", "bold")
      .style("text-anchor", "middle")
      .text(titleText));

  svg.append("g")
    .attr("stroke", stroke)
    .attr("stroke-width", strokeWidth)
    .attr("stroke-linejoin", strokeLinejoin)
    .selectAll("path")
    .data(arcs)
    .join("path")
    .attr("fill", d => colors(N[d.data]))
    .attr("d", arc)
    .on("pointerenter", function() {
      d3.select(this).style("opacity", 0.5);
    })
    .on("pointerleave", function() {
      d3.select(this).style("opacity", 1);
    })
    .style("cursor", "pointer")
    .append("title")
    .text(d => title(d.data));

  svg.append("g")
    .attr("fill", titleTextColor)
    .attr("font-family", "sans-serif")
    .attr("font-size", 10)
    .attr("text-anchor", "middle")
    .selectAll("text")
    .data(arcs)
    .join("text")
    .attr("transform", d => `translate(${arcLabel.centroid(d)})`)
    .selectAll("tspan")
    .data(d => {
      const lines = `${title(d.data)}`.split(/\n/);
      // console.log(lines);
      return lines.length > 2 ? parseInt(lines[2]) > 5 ? lines : "" : lines;
    })
    .join("tspan")
    .attr("x", 0)
    .attr("y", (_, i) => `${i * 1.1}em`)
    .attr("font-weight", (_, i) => i ? null : "bold")
    .text(d => d)
    .style("pointer-events", "none");

  return Object.assign(svg.node(), { scales: { colors } });
}
