import { useEffect, useRef } from 'react';
import { HelperAPI } from './../assets/api';

const TableauDashboard = (props) => {
  const containerDiv = useRef(null);


  const GetBIToken = async() => {
    let token = await HelperAPI.GetBIToken();
    return token;
  };

  const initViz = async() => {
    var token = await GetBIToken();
    let ind = props.url.indexOf('views');
    let url = props.url.substring(0, ind) + `trusted/${token}/` + props.url.substring(ind) + '&:toolbar=no';

    let iframe = document.createElement('iframe');
    iframe.src = url;
    iframe.width = '1370px';
    iframe.height = '790px';

    containerDiv.current.appendChild(iframe);
  };

  useEffect(() => {
    initViz();
  }, []);

  return (
    <>
      <div id="containerDiv" ref={containerDiv}></div>
    </>
  );
};
export default TableauDashboard;