import API from "./ApiConfig";

const AuthAPI = {
  // 登入
  Login: (loginData) => {
    return new Promise((resolve, reject) => {
      API.post(`/Account/Login`, loginData)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 登出
  Logout: () => {
    return new Promise((resolve, reject) => {
      API.post(`/Account/Logout`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 檢驗登入狀態
  IsLogin: () => {
    return new Promise((resolve, reject) => {
      API.get(`/Account/IsLogin`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export default AuthAPI;