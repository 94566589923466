import React, { useState } from "react";
import { useLoginStateContext } from './routes/LoginStateProvider';
import '../style/Auth.scss';
import '../style/Form.scss';
import StantecLogo from '../images/Stantec_Black.png';
import { Redirect } from "react-router-dom";
import { AuthAPI, AccountAPI } from "./assets/api";
import { notifySuccess, notifyFailed } from './assets/toaster';
import { ToastContainer } from 'react-toastify';

const Auth = () => {
  const { onLogin } = useLoginStateContext();
  const LoginStateContext = useLoginStateContext();
  const [userName, setUserName] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [isLogging, setIsLogging] = useState(false);

  async function Login() {
    let loginData = { UserName: userName, UserPassword: userPassword };
    // 123@mail.com Justlike1!
    // console.log(loginData, loginState);
    try {
      let result = await AuthAPI.Login(loginData);
      if (result) {
        // setLoginState(true);
        let userInfo = await AccountAPI.GetUserInfo();
        onLogin(true, userInfo);
        notifySuccess({Msg: '登入成功', cbfunc: ''});
      }
    } catch (error) {
      setIsLogging(false);
      notifyFailed({Msg: `登入失敗`, isReload: false});
    }
  }

  const handleUserName = (e) => {
    setUserName(e.target.value);
  };

  const handleUserPassword = (e) => {
    setUserPassword(e.target.value);
  };

  const handleLogin = () => {
    setIsLogging(true);
    Login();
  };
  return (
    LoginStateContext.loginState ?
      <Redirect to="/projects-list" /> :
      <div className="wholepage">
        <div className="sharebox_float">
          <div className="auth">
            <div className="LOGO">
              <img src={StantecLogo} />
            </div>
            <h1>登入</h1>

            <div className="form">
              <div className="form_13quarters">
                <div className="form_title">帳號：
                </div>
                <div className="form_input">
                  <label>
                    <input type="text" name="" value={userName} onChange={handleUserName}/>
                  </label>
                </div>
              </div>
              <div className="form_13quarters">
                <div className="form_title">密碼：
                </div>
                <div className="form_input">
                  <label>
                    <input type="password" value={userPassword} onChange={handleUserPassword}/>
                  </label>
                </div>
              </div>

              {/* <div className="auth_hint">
                <div className="auth_hint_remember">
                  <label>
                    <input type="checkbox" name="" />記住我
                  </label>
                </div>
                <div className="auth_hint_forget">
                  <Link to="/forgetpassword" className="a_forgetpassword" >忘記密碼</Link>
                </div>
              </div> */}
              <div className="auth_loginbtn">
                <button className="btnL btnWhite" onClick={handleLogin}>
                  {
                    isLogging ? <i className="fas fa-circle-notch fa-spin"></i> : '登入'
                  }
                </button>

              </div>
            </div>

          </div>
        </div>
        <ToastContainer/>
      </div>
  );
};

export default Auth;