import tools from '../assets/tools';
import styled from '@emotion/styled';
import moment from 'moment';
import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { SwitchMode } from './SwitchMode';
import { ThemeContext } from './../contexts/ThemeContext';
import { StatisticAPI, HelperAPI } from './../assets/api';
import CarouselImage from './CarouselImage';
import { useLocalStorage } from './useLocalStorage';
import { useIsFirstRender } from './../assets/customHooks/useIsFirstRender';
import { useLoginStateContext } from './../routes/LoginStateProvider';

const Selection = styled.div`
width: 50%;
margin-top: 10px;
display: flex;
flex-direction: column;
flex-wrap: nowrap;
@media (max-width: 1280px) {
  width: 100%;
}

`;

const Display = styled.div`
display: flex;
margin: 0 10px;
@media (max-width: 1280px) {
  flex-direction: column;
}
@media (max-width: 768px) {
  margin: 0 20px;
}
`;

const DisplayRow = styled.div`
display: flex;
@media (max-width: 1360px) {
  flex-direction: column;
  margin-right: 10px;
}
@media (max-width: 1280px) {
  margin-right: 0px;
}
@media (max-width: 575px) {
  flex-direction: column;
}
`;

const UpdatedDate = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
font-size: 14px;
.dateText {
  display: flex;
  justify-content: flex-end;
  color: ${props => props.isDarkMode ? '#e6e6e6' : '#0c0c0c'};
  h3 {
  color: ${props => props.isDarkMode ? '#e6e6e6' : '#0c0c0c'};
  }
}
`;

const ExecutionNote = styled.div`
height: 100%;
background-color: ${props => props.isDarkMode ? '#444' : '#fff'};
border: 1px solid ${props => props.isDarkMode ? 'none' : '#7b7979'};
margin-right: 10px;
padding: 10px 0px;
h1 {
  color: ${props => props.isDarkMode ? '#e6e6e6' : '#0c0c0c'};
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
ul {
  height: 200px;
  color: ${props => props.isDarkMode ? '#e6e6e6' : '#0c0c0c'};
  padding: 5px 15px 0;
  line-height: 1.8rem;
  overflow-y: auto;
  white-space: pre-line;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 20px grey; 
  }
  ::-webkit-scrollbar-thumb {
    background: #bcbcbc; 
  }
}
@media (max-width: 1280px) {
  margin: 0;
  ul {
  height: 150px;
  overflow-y: auto;
  :-webkit-scrollbar-track {
    box-shadow: inset 0 0 20px grey; 
  }
}
}
`;

const ProjectDescription = styled.div`
width: 50%;
background-color: ${props => props.isDarkMode ? '#444' : '#fff'};
border: 1px solid ${props => props.isDarkMode ? 'none' : '#7b7979'};
margin: 10px 10px 10px 0;
padding: 0 10px;
display: flex;
flex-wrap: nowrap;
flex-direction: column;
>div {
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  h4 {
    width: 50%;
    color: ${props => props.isDarkMode ? '#e6e6e6' : '#0c0c0c'};
    font-weight: bold;
  }
  .projectInfo {
    width: 50%;
    color: #0c0c0c;
    font-size: 12px;
    padding: 5px;
    position: relative;
    background-color: ${props => props.isDarkMode ? '#e6e6e6' : '#ccc'};
    span {
      display: inline-block;
      margin: 0;
      vertical-align: middle;
    }
    .hoverBlock{
      display: none;
    }
  }
}
@media (max-width: 1360px) {
  width: 100%;
  padding: 0px;
  margin: 20px 0px;
  > div {
    h4 {
      width: 30%;
      margin: 0 10px;
    }
    .projectInfo {
      width: 80%;
      margin-right: 10px;
  } 
}
}
@media (max-width: 513px) {
  > div {
    .projectInfo {
      padding: 10px;
      &:hover {
      .hoverBlock {
        padding: 13px 3px;
      }
    }
  } 
}
}
`;

//圖說管理
const ManageItem = styled.div`
width: 50%;
margin: 10px 10px 10px 0;
background-color: ${props => props.isDarkMode ? '#444' : '#fff'};
border-bottom: 1px solid #7b7979;
border: 1px solid ${props => props.isDarkMode ? 'none' : '#7b7979'};
h3 {
  color: ${props => props.isDarkMode ? '#e6e6e6' : '#0c0c0c'};
  font-weight: bold;
  text-align: center;
  margin: 5px 0;
}
.table {
  width: 100%;
  display: flex;
  cursor: pointer;
  .tbody {
    width: 100%;
    color: ${props => props.isDarkMode ? '#e6e6e6' : '#0c0c0c'};
    border-top: .5px solid ${props => props.isDarkMode ? '#e6e6e6' : '#7b7979'};
    .tr {
      height: 100%;
      max-height: 235px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      padding: 0 0 20px;
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 20px grey; 
      }
      &::-webkit-scrollbar-thumb {
        background: #bcbcbc; 
      }
      .rows {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        .row {
          width: 100%;
          font-size: 14px;
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          .rowCaption {
            width: 30%;
            min-width: 30%;
            padding: 10px;
          }
          .nameHidden {
            visibility: hidden;
            
          }
          .rowContent {
            width: 70%;
            padding: 10px 0;
            margin-right: 5px;
            position: relative;
            h4 {
              width: 100%;
              min-width: 30px;
              /* margin: 0; */
              /* overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis; */
            }
          }
          .rowLink{
            width: 5%;
            padding: 10px 0;
            a {
              color: ${props => props.isDarkMode ? '#e6e6e6' : '#ccc'};
              &:hover {
                color: #f28e2b;
              }
            }
          }
        }
      }
      .hoverColor {
        .hoverBlock {
          display: none;
        }
        &:hover {
          .hoverBlock {
            display: block;
            width: 100%;
            position: absolute;
            top: 0px;
            left: 0;
            background-color: ${props => props.isDarkMode ? '#555555' : '#ccc'};
            box-shadow: 0 0 2px ${props => props.isDarkMode ? '#444' : '#ccc'};
            padding: 8px 0;
            margin-right: 10px;
            z-index: 1;
            white-space: pre-line;
            word-break: break-all;
          }
        }
      }
    }
  }
}
@media (max-width: 1360px) {
  width: 100%;
  margin: 0px 0;
}
@media (max-width: 1280px) {
  margin-right: 0;
}
@media (max-width: 575px) {
  width: 100%;
}
@media (max-width: 455px) {
  .table {
    .tbody {
    height: 280px;
  }
  }
}
`;

//工程階段
const ProjectPhase = styled.div`
margin: 0 10px 0 0;
background-color: ${props => props.isDarkMode ? '#444' : '#fff'};
border: 1px solid ${props => props.isDarkMode ? 'none' : '#7b7979'};
word-break: break-all;
.table {
  width: 100%;
  .thead {
    padding: 0 10px;
    background-color: ${props => props.isDarkMode ? '#e6e6e6' : '#ccc'};
    line-height: 1.8rem;
    .th {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      span {
        width: 100%;
        color: #0c0c0c;
        margin: 0 5px;
      }
    }
  }
  .tbody {
    max-height: 216px;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 20px grey; 
    }
    ::-webkit-scrollbar-thumb {
      background: #bcbcbc; 
    }
    .tr {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        width: 100%;
        color: ${props => props.isDarkMode ? '#e6e6e6' : '#0c0c0c'};
        padding: 15px;
        word-wrap: break-word;
      }
      &:hover {
        cursor: pointer;
        background-color: ${props => props.isDarkMode ? '#555555' : '#e6e6e6'};
        box-shadow: 0 0 2px #cccc;
      }
    }
  }
}
@media (max-width: 1360px) {
  margin: 20px 10px 0 0;
}
@media (max-width: 1280px) {
  margin: 20px 0 10px;
}
`;

// 督導查核
const Projectcheck = styled.div`
height: 100%;
margin: 10px 0 0;
padding: 10px 0 0;
background-color: ${props => props.isDarkMode ? '#444' : '#fff'};
border: 1px solid ${props => props.isDarkMode ? 'none' : '#7b7979'};
h1 {
  color: ${props => props.isDarkMode ? '#e6e6e6' : '#0c0c0c'};
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.table {
  margin: 10px 0 0;
  width: 100%;
  .thead {
    background-color: ${props => props.isDarkMode ? '#e6e6e6' : '#ccc'};
    .tr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .th {
      width: 20%;
      padding: 5px 10px;
      }
    }
  }
  .tbody {
    color: ${props => props.isDarkMode ? '#e6e6e6' : '#0c0c0c'};
      max-height: 212px;
      overflow-y: auto;
      ::-webkit-scrollbar {
        width: 10px;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 20px grey; 
      }
      ::-webkit-scrollbar-thumb {
        background: #bcbcbc; 
      }
      .tr {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: .5px solid #7b7979;
        &:last-of-type {
          border-bottom: none;
        }
        .td {
          width: 20%;
          padding: 5px 10px;
        }
        &:hover {
        cursor: pointer;
        background-color: ${props => props.isDarkMode ? '#555555' : '#e6e6e6'};
        box-shadow: 0 0 2px #cccc;
        }
      }    
  }
}
@media (max-width: 1360px) {
  margin-top: 20px;
}
@media (max-width: 768px) {
  margin-bottom: 10px;
}
`;

const NoDataText = styled.div`
width: 100%;
height: 100%;
font-size: 20px;
display: flex;
justify-content: center;
align-items: center;
color: ${props => props.isDarkMode ? '#e6e6e6' : '#55555'};
`;


const DashboardMain = () => {
  const isFirstRender = useIsFirstRender();
  const { loginUser } = useLoginStateContext();
  const isExternal = loginUser.isExternal;

  const location = useLocation();
  const indexId = location.pathname.split('/').pop();
  const isIndexId = Number.isInteger(Number(indexId));
  // const isMountedRef = useRef(true);

  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark' ? true : false;

  const [projectList, setProjectList] = useState([]); //工程項目清單
  const [projectId, setProjectId] = useLocalStorage("projectId", projectList.length ? projectList[0].id : 0);
  const [fillInMonth, setFillInMonth] = useState("");
  const [executionNote, setExecutionNote] = useState(""); //當月內容
  const [projectInfo, setProjectInfo] = useState([]); 
  const [ekphrasisInfoList, setEkphrasisInfoList] = useState([]); //圖說管理
  const [projectContractInfoList, setProjectContractInfoList] = useState([]); //工程階段
  const [supervisionPhotoList, setSupervisionPhotoList] = useState([]); 
  const [inspectionList, setInspectionList] = useState([]); //督導查核
  let prevCategoryName = ''; //圖說管理標題篩選

  //取得工程項目清單
  const GetStaticProjectList = async () => {
    try {
      let data = await HelperAPI.GetStaticProjectList();
      setProjectList(data.projectList);
      return data.projectList;
    } catch (error) {
      console.log(error);
    }
  };
  
  const GetOverView = async (projectId) => {
    try {
      let data = await StatisticAPI.GetOverView(projectId);
      return data;
    } catch (error) {
      console.log(error);
    }
  };
  const SetDashboardView = (data) => {
    setFillInMonth(moment(data.lastUpdateDate).format('YYYY-MM'));
    if(data.lastUpdateDate===null) {
      setFillInMonth("無資料");
    }
    setExecutionNote(data.lastExecutionNote);
    const originContractAmount = data.projectInfo.originContractAmount.toLocaleString();
    const changedContractAmount = data.projectInfo.changedContractAmount === null ? originContractAmount: data.projectInfo.changedContractAmount.toLocaleString();
    setProjectInfo({...data.projectInfo ,"originContractAmount": originContractAmount, "changedContractAmount": changedContractAmount});
    setEkphrasisInfoList(data.ekphrasisInfoList);
    const projectContractInfoListData = data.projectContractInfoList;
    const newProjectContractInfoList = projectContractInfoListData.map((ele) => {
      return {
        ...ele,
        contractStartDate: moment(ele.contractStartDate).format('YYYY-MM-DD'),
        completedDate: moment(ele.completedDate).format('YYYY-MM-DD')
      };
    });
    setProjectContractInfoList(newProjectContractInfoList);
    setSupervisionPhotoList(data.supervisionPhotoLList);
    const newInspectionList = data.inspectionList.map((ele) => {
      return {
        ...ele,
        date: moment(ele.date).format('YYYY-MM-DD'),
      };
    });
    setInspectionList(newInspectionList);
  };
  const handleProjectSelect = (e) => {
    let value = projectList.find(ele => ele.id === Number(e.target.value));
    setProjectId(value.id);
  };

  useEffect(async ()=> {
    let staticProjectList = await GetStaticProjectList();
    if(isIndexId) {
      setProjectId(Number(indexId));
    } else {
      if (projectId === 0) {
        setProjectId(staticProjectList[0].id);
      } else {
        let data = await GetOverView(projectId);
        SetDashboardView(data);
      }
    }
  },[]);

  useEffect(async()=> {
    if (!isFirstRender) {
      let data = await GetOverView(projectId);
      SetDashboardView(data);
    }
  },[projectId]);

  // useEffect(()=> {
  //   if(Number.isInteger(Number(indexId))) {
  //     setProjectId(Number(indexId));
  //   } 
  //   // return () => {
  //   //   isMountedRef.current = false;
  //   // };
  // },[indexId]);

  return (
    <>
      <SwitchMode/>
      <div className="containerBox" style={{backgroundColor: isDarkMode ? '#666666' : '#F1F1F1' }}>
        <div className="dashboardContent">
          <div className="dashboardUpArea">
            <div className="dashboardUpArea_left">
              {/* <div className="dashboardTitle" style={{ backgroundColor: isDarkMode ? '' : '#ffffff' }}>
                <h1>連江縣自來水供水智慧化系統工程第二期計畫總顧問(含監造)委託專案服務案</h1>
              </div> */}
              <UpdatedDate isDarkMode={isDarkMode}>
                <div className="dateText">
                  <h3>最近更新：</h3>
                  <span>{fillInMonth}</span>
                </div>
                <div className="subTitle">
                  <h1 style={{ color: isDarkMode ? '#e6e6e6' : '#0c0c0c'}}>工程概述</h1>
                </div>
              </UpdatedDate>
            </div>
            <div className="projectWrap" style={{backgroundColor: isDarkMode ? '#555555' : '#ccc'}}>
              <h3 style={{color: isDarkMode ? '#e6e6e6' : '#0c0c0c'}}>專案名稱</h3>
              <div className="dropdownBox">
                <select 
                  className="dropdownMenu" 
                  style={{ backgroundColor: isDarkMode ? '#bcbcbc' : '#fff' }}
                  onChange={(e) => handleProjectSelect(e)} 
                  value={projectId}
                >
                  {
                    projectList.map(ele => (
                      <option key={ele.id} value={ele.id}>{ele.projectName}</option>
                    ))
                  }
                </select>
              </div>
            </div>
          </div>
          <Display>
            <Selection>
              <ExecutionNote isDarkMode={isDarkMode}>
                <h1>當月主要內容</h1>
                <ul>
                  <li>
                    {
                      executionNote && executionNote.length > 0 ? executionNote : <NoDataText isDarkMode={isDarkMode}>當前專案無資料</NoDataText>
                    }
                  </li>
                </ul>
              </ExecutionNote>
              <DisplayRow>
                <ProjectDescription isDarkMode={isDarkMode}>
                  <div>
                    <h4>專案代號</h4>
                    <div className="projectInfo">
                      <span>{projectInfo.projectNum}</span>
                    </div>
                  </div>
                  <div>
                    <h4>主辦機關</h4>
                    <div className="projectInfo">
                      <span>{projectInfo.titleAgency}</span>
                    </div>
                  </div>
                  <div>
                    <h4>承攬廠商</h4>
                    <div className="projectInfo">
                      <span>{projectInfo.contract}</span>
                    </div>
                  </div>
                  <div>
                    <h4>契約變更次數</h4>
                    <div className="projectInfo">
                      <span>{projectInfo.contractChangeNum}</span>
                    </div>
                  </div>
                  <div>
                    <h4>原契約金額</h4>
                    <div className="projectInfo">
                      <span>{projectInfo.originContractAmount}</span>
                    </div>
                  </div>
                  <div>
                    <h4>變更後契約金額</h4>
                    <div className="projectInfo">
                      <span>{projectInfo.changedContractAmount}</span>
                    </div>
                  </div>

                </ProjectDescription>
                <ManageItem isDarkMode={isDarkMode}>
                  <h3>圖說管理</h3>
                  <div className="table">
                    <div className="tbody">
                      <div className="tr">
                        {/* <h4 scope="col" className="ItemCaption hoverColor">施工規範</h4> */}
                        <div className="rows">
                          {
                            ekphrasisInfoList.length > 0 ?
                              ekphrasisInfoList.map((ele) => {
                                const showCategoryName = ele.categoryName !== prevCategoryName;
                                prevCategoryName = ele.categoryName;
                                return (
                                  <div className="row" key={tools._UUID()}>
                                    <div className={`rowCaption hoverColor ${showCategoryName ? "" : "nameHidden"}`}>
                                      <h4>{ele.categoryName}</h4>
                                    </div>
                                    <div className="rowContent hoverColor">
                                      <h4>{ele.ekphrasisName}</h4>
                                      <span className="hoverBlock">{ele.ekphrasisName}</span>
                                    </div>
                                    {
                                      isExternal ? `` : (
                                        <div className="rowLink">
                                          <a href={ele.fileUrl} target="_blank" rel="noreferrer noopener">
                                            <i className="fas fa-solid fa-download"></i>
                                          </a>
                                        </div>
                                      )
                                    }
                                  </div>
                                );
                              })
                              : <NoDataText isDarkMode={isDarkMode}>當前專案無資料</NoDataText>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </ManageItem>
              </DisplayRow>
              <ProjectPhase isDarkMode={isDarkMode}>
                <div className="table">
                  <div className="thead">
                    <div className="th">
                      <span>工程階段</span>
                      <span>工程開工日</span>
                      <span>預定竣工日</span>
                      <span>工期</span>
                      <span>展延天數</span>
                    </div>
                  </div>
                  <div className="tbody">
                    {
                      projectContractInfoList.length > 0 ?
                        projectContractInfoList.map(ele => (
                          <div className="tr" key={tools._UUID()}>
                            <span>{ele.stageContract}</span>
                            <span>{ele.contractStartDate}</span>
                            <span>{ele.completedDate}</span>
                            <span>{ele.contractDuration}</span>
                            <span>{ele.extensionDays}</span>
                          </div>
                        ))
                        : <NoDataText isDarkMode={isDarkMode}>當前專案無資料</NoDataText>
                    }
                  </div>
                </div>
              </ProjectPhase>
            </Selection>
            <Selection>
              {
                supervisionPhotoList.length > 0 ? 
                  <CarouselImage supervisionPhotoList={supervisionPhotoList} isDarkMode={isDarkMode}/>
                  : ""
              }
              <Projectcheck isDarkMode={isDarkMode}>
                <h1>督導查核</h1>
                <div className="table">
                  <div className="thead">
                    <div className= "tr">
                      <div className= "th">督導查核日期</div>
                      <div className= "th">主管單位</div>
                      <div className= "th">項目</div>
                      <div className= "th">查核級等</div>
                      <div className= "th">查核分數</div>
                    </div>
                  </div>
                  <div className="tbody">
                    {
                      inspectionList.length > 0 ?
                        inspectionList.map(ele => (
                          <div className= "tr" key={tools._UUID()}>
                            <div className= "td">{ele.date}</div>
                            <div className= "td">{ele.organizer}</div>
                            <div className= "td">{ele.item}</div>
                            <div className= "td">{ele.grade}</div>
                            <div className= "td">{ele.score}</div>
                          </div>
                        ))
                        :<NoDataText isDarkMode={isDarkMode}>當前專案無資料</NoDataText>
                    }
                  </div>
                </div>
              </Projectcheck>
            </Selection>
          </Display>
        </div>
      </div>
    </>
  );
};
export default DashboardMain;

// const url = tools.CheckEnv() === 'prod' ?
//   'https://bi3.stantec.com.tw/views/DashboardProd/sheet22?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link' :
//   'https://bi3.stantec.com.tw/views/DashboardNew/sheet11?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link';