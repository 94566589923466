import styled from '@emotion/styled';
import SignaturePad from 'signature_pad';
import {useRef, useEffect, useState} from 'react';
import { AccountAPI } from './../assets/api';
import tools from './../assets/tools';
import { useParams, useHistory } from 'react-router-dom';

import { notifySuccess, notifyFailed } from './../assets/toaster';
import { ToastContainer } from 'react-toastify';

const TxtInput = styled.input`
  width: 100%;
  max-width: 390px;
  height: 28px;
  border-radius: 3px;
  border: 1px solid #999;
  padding: 0 5px;
  box-sizing: border-box;
`;
const InlineLabel = styled.label`
  display: inline-block !important;
  margin: 0 30px 0 0 !important;
`;
const SignetPreview = styled.div`
  display: block;
  width: 380px;
  height: 160px;
  background-color: #F1F1F1;
  border: 5px solid #F1F1F1;
  margin: 15px 0;
  @media (max-width: 450px) {
    width: 100%;
    background-color: #FFF;
    border: 1px solid #CCC;
  }
`;

const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 100%
`;

const SignPad = styled.canvas`
  display: block;
  // border: 1px solid #999;
  border: 5px solid #F1F1F1;
  margin: 15px 0;
  background-color: #FFF;
  @media (max-width: 450px) {
    border: 1px solid #FFF;
  }
`;

const HintSpan = styled.span`
  font-size: 14px;
  color: #333;
  position: relative;
  top: -2px;
  span{
    color: #ED7000;
  }
`;



const SignetEdit = () => {
  const { stampid } = useParams();
  const history = useHistory();
  const [ pageStatus, setPageStatus] = useState(0);
  const [sigOption, setSigOption] = useState("signet");
  const signetInput = useRef(null);

  const signPad = useRef(null);
  const signPadPreview = useRef(null);

  const [ userStamp, setUserStamp ] = useState({
    category: 1, // default: upload photo
    createDate: null,
    createID: 0,
    fileExt: '',
    fileSize: 0,
    id: 0,
    modifyDate: null,
    modifyID: 0,
    stampName: '',
    stampURL: '',
    userName: '',
    status: 1
  });

  const [fileUpload, setFileUpload] = useState({
    formDataId: 0,
    file: null,
    sizeHeight: null
  });

  const [isSaving, setIsSaving] = useState(false);

  const GetUserStamp = async () => {
    try {
      const data = await AccountAPI.GetUserStamp(stampid);
      setUserStamp(data);
      setSigOption(`${data.category === 1 ? 'signet' : 'signature'}`);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  };

  async function GetUserInfo() {
    try {
      let data = await AccountAPI.GetUserInfo();
      setUserStamp({...userStamp, userName: data.userName});
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }

  const handleStampName = (e) => {
    setUserStamp({...userStamp, stampName: e.target.value});
  };

  const ModifyUserStamp = async () => {
    let formData = new FormData();
    formData.append('Id', userStamp.id);
    formData.append('UserName', userStamp.userName);
    formData.append('Category', userStamp.category);
    formData.append('StampName', userStamp.stampName || '');
    formData.append('File', fileUpload.file);
    formData.append('UserstampStatus', userStamp.status);
    formData.append('Status', pageStatus);
    try {
      // console.log(fileUpload.sizeHeight);
      if (fileUpload.sizeHeight > 220) {
        notifyFailed({ Msg: '此檔案超過建議高度', isReload: false });
      }
      await AccountAPI.ModifyUserStamp({ userStamp: formData });
      if (pageStatus === 1) {
        notifySuccess({Msg: '儲存成功', cbfunc: () => history.push("/account/signet-manage")});
      } else if (pageStatus === 2) {
        notifySuccess({Msg: '儲存成功', cbfunc: () => window.location.reload()});
      }
      setIsSaving(false);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
      setIsSaving(false);
    }
  };
  useEffect(() => {
    if (stampid !== 'create') {
      GetUserStamp();
      setPageStatus(2);
    } else if (stampid === 'create') {
      GetUserInfo();
      setPageStatus(1);
    }
  }, []);


  let signaturePad;
  useEffect(() => {
    signaturePad = new SignaturePad(signPad.current);
    signaturePad.clear();
  });

  const handleSignatureClear = () => {
    signaturePad.clear();
    // signPadPreview.current.src = "";
  };

  const handleSignaturePreview = () => {
    if (signaturePad.isEmpty()) {
      notifyFailed({Msg: '請建立簽名', isReload: false});
    } else {
      let dataURL = signaturePad.toDataURL();
      signPadPreview.current.src = dataURL;
      let blob = tools.dataURLToBlob(dataURL);
      let fmtNowDate = tools.GetFormatNowDate('yyyyMMDDHHmmss');
      let file = tools.BlobToFile(blob, `${userStamp.stampName}_signature_${fmtNowDate}.png`);
      setFileUpload({
        formDataId: stampid,
        file: file
      });
    }
    signetInput.current.value = null;
  };

  const handleSigOption = (e) => {
    let sigOpt = e.target.value === 'signet' ? 1 : 2;
    setSigOption(e.target.value);
    setUserStamp({...userStamp, category: sigOpt});
  };

  const handleEnableOption = (e) => {
    setUserStamp({...userStamp, status: Number(e.target.value)});
  };

  // preview image
  // const handleImageUpload = (e) => {
  //   if (e.target.files && e.target.files[0]){
  //     let reader = new FileReader();
  //     reader.onload = function(e) {
  //       setUserStamp({...userStamp, stampURL: e.target.result});
  //     };
  //     reader.readAsDataURL(e.target.files[0]);
  //     setFileUpload({
  //       formDataId: stampid,
  //       file: e.target.files[0]
  //     });
  //   }
  // };

  // preview image
  const handleImageUpload = async (e) => {
    if (e.target.files && e.target.files[0]) {

      let LoadSize = () => {
        return new Promise(resolve => {
          let reader = new FileReader();
          reader.onload = async e => {
            setUserStamp({ ...userStamp, stampURL: e.target.result });
            let size = await tools.GetImageSize(e.target.result);
            return resolve(size.height);
          };
          reader.readAsDataURL(e.target.files[0]);
        });
      };
      let sizeHeight = await LoadSize();
      // console.log(sizeHeight);
      setFileUpload({
        formDataId: stampid,
        file: e.target.files[0],
        sizeHeight: sizeHeight
      });
    }
  };

  const handleSave = () => {
    setIsSaving(true);
    ModifyUserStamp();
  };

  return (
    <>
      <div className="bumper_rail"></div>
      <div className="commontitle">
        <h1>影像章{pageStatus === 1 ? '新增' : '編輯'}</h1>
      </div>

      <div className="sharebox">
        <div className="form">

          <div className="form_13quarters">
            <div className="form_title">別名：</div>
            <div className="form_input">
              <label>
                {
                  pageStatus === 1 ?
                    <TxtInput value={userStamp.stampName} onChange={handleStampName}/> :
                    <TxtInput value={userStamp.stampName} disabled/>
                }
              </label>
            </div>
          </div>
          <div className="form_13quarters">
            <div className="form_title">影像章：</div>
            <div className="form_input">
              <InlineLabel><input type="radio" name="SignetSource" value="signet" checked={sigOption === "signet"} onChange={handleSigOption}/> 上傳圖檔</InlineLabel>
              <InlineLabel><input type="radio" name="SignaturePad" value="signature" checked={sigOption === "signature"} onChange={handleSigOption}/> 簽名產製</InlineLabel>
              <br /><HintSpan>( 圖檔建議高度<span>小於220px</span> )</HintSpan>
              <div style={{ display: sigOption === "signature" ? "block" : "none", overflow:"hidden", }}>
                <SignPad ref={signPad} width="380px" />
                <div style={{ textAlign:"right", maxWidth:"390px", }}>
                  <button className="btnM btnGrey" onClick={handleSignatureClear}>清除簽名</button>
                  <button className="btnM btnWhite" onClick={handleSignaturePreview}>確認簽名</button>
                </div>
                {/* <SignetPreview>
                  <img ref={signPadPreview} src="" />
                </SignetPreview> */}
              </div>
              <div style={{ display: sigOption === "signet" ? "block" : "none"}}>
                <input type="file" accept="image/*" name="SignetSource" ref={signetInput} onChange={handleImageUpload}/>
              </div>
              <SignetPreview>
                <ImagePreview src={userStamp.stampURL} ref={signPadPreview} />
              </SignetPreview>
            </div>
          </div>

          <div className="form_13quarters">
            <div className="form_title">使用狀態：</div>
            <div className="form_input">
              <label>
                <input type="radio" name="isUse" value={1} checked={userStamp.status === 1} onChange={handleEnableOption}/> 使用
              </label>
              <label>
                <input type="radio" name="isUse" value={0} checked={userStamp.status === 0} onChange={handleEnableOption}/> 停用
              </label>
            </div>
          </div>
          <div className="btn_box">
            <button className="btnL btnBlue" onClick={handleSave} disabled={isSaving}>
              {
                isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '存檔'
              }
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default SignetEdit;