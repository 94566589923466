import { Link } from "react-router-dom";
import styled from '@emotion/styled';
import { FormAPI, DocumentAPI, SupervisionAPI, ProjectAPI } from './../../assets/api';
import { notifySuccess, notifyFailed, notifyWarning } from './../../assets/toaster';
import tools from './../../assets/tools';
import { useContext, useState } from "react";
import moment from "moment";

import { ProjectContext } from './../../contexts/ProjectContext';
import Pagination from '../../assets/Pagination';
import PaginationTool from '../../assets/PaginationTool';
import { ReactComponent as NullValueIcon } from "./../../../images/NullValue_Icon.svg";

const TxtInput = styled.input`
    padding: 0 4px;
    box-sizing: border-box;
    height: 34px;
    border-radius: 3px;
    border: 1px solid #999;
    min-width: 15em;
  `;

const DateInput = styled.input`
    padding: 0 4px;
    box-sizing: border-box;
    height: 34px;
    border-radius: 3px;
    border: 1px solid #999;
    min-width: 5em;
    margin: 0 3px;
  `;

const DateDiv = styled.label`
    @media (max-width: 450px) {
      display: block !important;
      margin: 0 !important;
      padding: 0 !important;
    }
  `;

const FilterTable = styled.div`
    margin: 32px 0;
    .queryMenu_btnbox{
      margin: 16px 0 8px 0;
    }
  `;

const NullValueBox = styled.div`
  text-align: center;
  padding: 48px 0 40px 0;
  p{
    color: #A1A1A1;
    margin: 8px 0;
    letter-spacing: 1px;
  }
`;

const ProjectFormListFilter = (props) => {
  // let projectId = 1; //TODO: 動態帶projectId
  const { projectInfo } = useContext(ProjectContext);
  let projectId = projectInfo.id;

  const formInfo = {
    4: { formTitle: '一般安全衛生查驗表列表', apiFunc: 'PostProjectHSFormList', setList: props.setProjectHSFormList },
    5: { formTitle: '異常(不合格)通知暨改善表', apiFunc: 'PostAbnormalList', setList: props.setAbnormalList },
    6: { formTitle: '公共工程監造報表列表', apiFunc: 'PostSupervisionFormList', setList: props.setSupervisionList },
    7: { formTitle: '每日出工與機具填寫', apiFunc: ['GetDPIList', 'GetDPITotalList'], setList: ['props.setToolsList', 'props.setToolsTotalList'] },
    8: { formTitle: '專案工作月報表列表', apiFunc: 'PostMonthlyFormList', setList: props.setSupervisionMonthlyList },
  };

  // const [toolsFormStatus, setToolsFormStatus] = useState(4); // 出工機具狀態

  const handlesearchHSChange = (e) => {
    props.setSearchHS({
      ...props.searchHS,
      [e.target.name]: e.target.value
    });
  };

  const handleHSSearch = async () => {
    await props.PostProjectHSFormList(props.formStatus);
    await props.PostProjectHSFormNumList(props.formStatus);
  };

  const handleSearchChange = (e) => {
    props.setAbnormalSearch({
      ...props.abnormalSearch,
      [e.target.name]: e.target.value
    });
  };

  const handleAbnormalSearch = async () => {
    await props.PostAbnormalList(props.formStatus);
    await props.PostAbnormalNumList(props.formStatus);
  };

  const handleSearchInputChangeDaily = (e) => {
    props.setSupervisionSearch({
      ...props.supervisionSearch,
      [e.target.name]: e.target.value
    });
  };

  const handleSearchInputChangeTools = (e) => {
    props.setToolsSearch({
      ...props.toolsSearch,
      [e.target.name]: e.target.value
    });
  };

  const handleDailySearch = async () => {
    await props.PostSupervisionFormList(props.formStatus);
    await props.PostDailyNumList(props.formStatus);
  };

  const handleToolsSearch = async () => {
    props.formStatus === 4 ? await props.GetDPIList(0)
      : await props.GetDPITotalList(0);
  };

  const handleMonthlySearch = async () => {
    await props.PostMonthlyFormList(props.formStatus);
    await props.PostMonthlyNumList(props.formStatus);
  };

  const handleMonthlyChange = (e) => {
    props.setMonthlySearch({
      ...props.monthlySearch,
      [e.target.name]:e.target.value
    });
  };

  const handleFormToUpdate = async (form, module) => {
    let editForm;
    const toolsFormStatusMap = {
      4: 0,
      5: 1,
      6: 2
    };
    switch (module) {
    case 'HSForm':
      editForm = {
        id: form.id,
        _uuid: tools._UUID(),
        formName: form.formId,
        moduleId: props.moduleSelected.id,
        formContent: await GetHSExamineInfo(form.id),
        abnormalForm: await GetAbnormalInfo({ projectId, abnormalId: form.abnormalId || 0 })
      };
      break;
    case 'abnormal':
      editForm = {
        id: form.id,
        _uuid: tools._UUID(),
        formName: form.docId,
        moduleId: props.moduleSelected.id,
        abnormalForm: await GetAbnormalInfo({ projectId, abnormalId: form.id })
      };
      break;
    case 'DailyForm':
      editForm = {
        id: form.id,
        _uuid: tools._UUID(),
        formName: form.formID,
        moduleId: props.moduleSelected.id,
        formContent: await GetSupervisionDailyInfo(projectId, form.id),
      };
      break;
    case 'ToolsForm':
      let toolsFormData = await GetDailyPersonnelImplements(projectId, {
        "date": form.fillinDate,
        "userName": form.userName,
        "formStatus": props.formStatus === 4 ? 0 : 1
      });
      editForm = {
        id: 1, // just for italic
        _uuid: tools._UUID(),
        formName: `每日出工與機具 - ${moment(form.fillinDate).format('YYYY-MM-DD')}${props.formStatus === 6 ? '[已刪除]' : ''}`,
        moduleId: 7, // 出工機具: 7
        formContent: { ...toolsFormData, toolsReadStatus: toolsFormStatusMap[props.formStatus] },
        fillinDate: form.fillinDate,
        userName: form.userName,
        formStatus: props.formStatus
      };
      break;
    case 'ToolsTotalForm':
      let toolsTotalFormData = await GetDailyPersonnelImplementsTotal(projectId, form.fillinDate);
      editForm = {
        id: 1, // just for italic
        _uuid: tools._UUID(),
        formName: `每日出工與機具 - 日統計 - ${moment(form.fillinDate).format('YYYY-MM-DD')}`,
        moduleId: 7, // 出工機具: 7
        formContent: { ...toolsTotalFormData, toolsReadStatus: toolsFormStatusMap[props.formStatus] },
        fillinDate: form.fillinDate,
        userName: form.userName,
        formStatus: props.formStatus
      };
      break;
    case 'MonthlyForm':
      editForm = {
        id: form.id,
        _uuid: tools._UUID(),
        formName: `專案工作月報表`,
        moduleId: 8,
        formContent: await GetSupervisionMonthlyInfo(projectId, form.id)
      };
      break;
    default:
      break;
    }

    let selectedTab;
    if (module === 'ToolsForm') {
      selectedTab = props.formTabList.find(ele => ele.fillinDate === form.fillinDate && ele.userName === form.userName && ele.moduleId === props.moduleSelected.id && ele.formStatus === props.formStatus);
    } else if (module === 'ToolsTotalForm') {
      selectedTab = props.formTabList.find(ele => ele.fillinDate === form.fillinDate && ele.moduleId === props.moduleSelected.id && ele.formStatus === props.formStatus);
    } else {
      selectedTab = props.formTabList.find(ele => ele.id === form.id && ele.moduleId === props.moduleSelected.id);
    }
    if (selectedTab) {
      // props.setFormTabSelected(selectedTab);
      let data = await setFormTabListPromise([...props.formTabList.filter(ele => ele._uuid !== selectedTab._uuid), editForm]);
      openFormTab(data, editForm._uuid);
    } else {
      let data = await setFormTabListPromise([...props.formTabList, editForm]);
      openFormTab(data, editForm._uuid);
    }
    props.setIsListOn(false);
  };

  const openFormTab = (list, uuid) => {
    let tabToOpen = list.find(ele => ele._uuid === uuid);
    if (tabToOpen) props.setFormTabSelected(tabToOpen);
  };

  const setFormTabListPromise = (formList) => {
    return new Promise(resolve => {
      props.setFormTabList(formList);
      resolve(formList);
    });
  };

  const setFormStatusPromise = (status) => {
    return new Promise(resolve => {
      props.setFormStatus(status);
      resolve(status);
    });
  };


  const handleFormStatusClick = async (status) => {
    let newFormStatus = await setFormStatusPromise(status);
    switch (Number(props.moduleSelected.id)) {
    case 4:
      await props.PostProjectHSFormList(newFormStatus);
      await props.PostProjectHSFormNumList(newFormStatus);
      props.setCurrentPage(1);
      break;
    case 5:
      await props.PostAbnormalList(newFormStatus);
      await props.PostAbnormalNumList(newFormStatus);
      props.setCurrentPage(1);
      break;
    case 6:
      await props.PostSupervisionFormList(newFormStatus);
      await props.PostDailyNumList(newFormStatus);
      props.setCurrentPage(1);
      break;
    case 7:
      if (newFormStatus === 4) {
        // setToolsFormStatus(4);
        await props.GetDPIList(0);
        props.setCurrentPage(1);
      }
      if (newFormStatus === 5) {
        // setToolsFormStatus(5);
        await props.GetDPITotalList(0);
        props.setCurrentPage(1);
      }
      if (newFormStatus === 6) {
        // setToolsFormStatus(6);
        await props.GetDPIList(1);
        props.setCurrentPage(1);
      }
      break;
    case 8:
      await props.PostMonthlyFormList(newFormStatus);
      await props.PostMonthlyNumList(newFormStatus);
      props.setCurrentPage(1);
      break;
    default:
      break;
    }
  };

  async function GetHSExamineInfo(hsExamineId) {
    try {
      let data = await FormAPI.GetHSExamineInfo(hsExamineId);
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  // 取得異常表資料
  async function GetAbnormalInfo({ projectId, abnormalId }) {
    try {
      let data = await FormAPI.GetAbnormalInfo({ projectId, abnormalId });
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  async function GetSupervisionDailyInfo(projectId, supervisionId) {
    try {
      let data = await SupervisionAPI.GetSupervisionDailyInfo(projectId, supervisionId);
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  async function GetDailyPersonnelImplements(projectId, toolsInfo) {
    try {
      let data = await ProjectAPI.GetDailyPersonnelImplements({projectId, toolsInfo});
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  async function GetDailyPersonnelImplementsTotal(projectId, fillinDate) {
    try {
      let data = await ProjectAPI.GetDailyPersonnelImplementsTotal(projectId, fillinDate);
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  async function GetSupervisionMonthlyInfo(projectId, supervisionId) {
    try {
      let data = await SupervisionAPI.GetSupervisionMonthlyInfo(projectId, supervisionId);
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  const handleDeleteForm = async (deleteForm, moduleId) => {
    notifyWarning({ Msg: <DeleteConfirm deleteForm={deleteForm} moduleId={moduleId}/> });
  };
  const DeleteConfirm = ({ closeToast, deleteForm, moduleId }) => (
    <div>
      刪除筆單？
      <button className="btnS btnRed" onClick={() => DeleteItem(deleteForm, moduleId)}>確定刪除</button>
      <button className="btnS btnWhite" onClick={closeToast}>取消</button>
    </div>
  );
  const DeleteItem = async (deleteForm, moduleId) => {
    try {
      await FormAPI.DeleteForm({
        id: deleteForm.id,
        module: moduleId
      });
      notifySuccess({ Msg: '刪除成功', cbfunc: '' });
      switch (moduleId) {
      case 4:
        await props.PostProjectHSFormList(0);
        await props.PostProjectHSFormNumList(0);
        break;
      case 5:
        await props.PostAbnormalList(0);
        await props.PostAbnormalNumList(0);
        break;
      case 6:
        await props.PostSupervisionFormList(0);
        await props.PostDailyNumList(0);
        break;
      case 8:
        await props.PostMonthlyFormList(0);
        await props.PostMonthlyNumList(0);
        break;
      default:
        break;
      }
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  };


  const handleDeleteTools = async (deleteForm) => {
    notifyWarning({ Msg: <DeleteToolsConfirm deleteForm={deleteForm}/> });
  };
  const DeleteToolsConfirm = ({ closeToast, deleteForm }) => (
    <div>
      刪除筆單？
      <button className="btnS btnRed" onClick={() => DeleteToolsItem(deleteForm)}>確定刪除</button>
      <button className="btnS btnWhite" onClick={closeToast}>取消</button>
    </div>
  );
  const DeleteToolsItem = async (deleteForm) => {
    try {
      await ProjectAPI.DeleteDailyPersonnelImplements({
        projectId: projectId,
        ItemDelete:{
          projectId: projectId,
          fillinDate: deleteForm.fillinDate,
          userName: deleteForm.userName
        }
      });
      notifySuccess({ Msg: '刪除成功', cbfunc: '' });
      props.GetDPIList(0);
      props.PostDPINumList();
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  };

  const handleGoAbnormalForm = async (form) => {
    let selectedTab = props.formTabList.find(ele => ele.id === form.id && ele.moduleId === 5);
    if (selectedTab) {
      props.setFormTabSelected(selectedTab);
    } else {
      let abnormalForm = await GetAbnormalInfo({ projectId, abnormalId: form.abnormalId });
      let editForm = {
        id: form.id,
        _uuid: tools._UUID(),
        formName: abnormalForm.docId,
        moduleId: 5,
        abnormalForm
      };
      let data = await setFormTabListPromise([...props.formTabList, editForm]);
      openFormTab(data, editForm._uuid);
    }
    props.setIsListOn(false);
  };

  // 匯出PDF
  const ExportPDF = async (expoForm, module) => {
    const setListItemTrue = async (setList, list) => {
      setList(list.map(ele => {
        return (
          ele._uuid === expoForm._uuid ? {
            ...ele,
            IsPDFExporting: true
          } : ele
        );
      }));
    };
    const setListItemFalse = async (setList, list) => {
      setList(list.map(ele => {
        return (
          ele._uuid === expoForm._uuid ? {
            ...ele,
            IsPDFExporting: false
          } : ele
        );
      }));
    };
    switch (module) {
    case 'HSForm':
      setListItemTrue(props.setProjectHSFormList, props.projectHSFormList);
      await CreatePdf(expoForm, module);
      setListItemFalse(props.setProjectHSFormList, props.projectHSFormList);
      break;
    case 'abnormal':
      setListItemTrue(props.setAbnormalList, props.abnormalList);
      await CreatePdf(expoForm, module);
      setListItemFalse(props.setAbnormalList, props.abnormalList);
      break;
    case 'DailyForm':
      setListItemTrue(props.setSupervisionList, props.supervisionList);
      await CreatePdf(expoForm, module);
      setListItemFalse(props.setSupervisionList, props.supervisionList);
      break;
    case 'DailyFormNoSign':
      setListItemTrue(props.setSupervisionList, props.supervisionList);
      await CreatePdf(expoForm, module);
      setListItemFalse(props.setSupervisionList, props.supervisionList);
      break;
    case 'MonthlyForm':
      setListItemTrue(props.setSupervisionMonthlyList, props.supervisionMonthlyList);
      await CreatePdf(expoForm, module);
      setListItemFalse(props.setSupervisionMonthlyList, props.supervisionMonthlyList);
      break;
    default:
      break;
    }
  };
  const CreatePdf = async (expoForm, module) => {
    switch (module) {
    case 'HSForm':
      try {
        let data = await DocumentAPI.CreateHSPdf(expoForm.id);
        tools.AjaxFileDownload({ rs: data, filename: `安衛表單 - ${expoForm.formId}`, fileType: 'pdf' });
      } catch (error) {
        notifyFailed({ Msg: '發生錯誤，匯出文件失敗', isReload: false });
      }
      break;
    case 'abnormal':
      try {
        let data = await DocumentAPI.CreateANPdf(expoForm.id);
        tools.AjaxFileDownload({ rs: data, filename: `異常(不合格)通知暨改善表 - ${expoForm.docId}`, fileType: 'pdf' });
      } catch (error) {
        notifyFailed({ Msg: '發生錯誤，匯出文件失敗', isReload: false });
      }
      break;
    case 'DailyForm':
      try {
        let data = await DocumentAPI.CreateSDPdf(expoForm.id, true);
        tools.AjaxFileDownload({ rs: data, filename: `公共工程監造報表 - ${expoForm.formID}`, fileType: 'pdf' });
      } catch (error) {
        notifyFailed({ Msg: '發生錯誤，匯出文件失敗', isReload: false });
      }
      break;
    case 'DailyFormNoSign':
      try {
        let data = await DocumentAPI.CreateSDPdf(expoForm.id, false);
        tools.AjaxFileDownload({ rs: data, filename: `公共工程監造報表 - ${expoForm.formID}`, fileType: 'pdf' });
      } catch (error) {
        notifyFailed({ Msg: '發生錯誤，匯出文件失敗', isReload: false });
      }
      break;
    case 'MonthlyForm':
      try {
        console.log(expoForm);
        let data = await DocumentAPI.CreateSMPdf(expoForm.id);
        tools.AjaxFileDownload({ rs: data, filename: `專案工作月報表 - ${expoForm.formID}`, fileType: 'pdf'});
      } catch (error) {
        notifyFailed({ Msg: '發生錯誤，匯出文件失敗', isReload: false });
      }
      break;
    default:
      break;
    }
  };

  return (
    <>
      {
        // ((![5, 6].includes(props.moduleSelected.id) && !props.projectFormSelected.id) || props.isListClick === false) ?
        ((props.moduleSelected.hasItem === true && !props.projectFormSelected.id) || props.isListClick === false) ?
          <NullValueBox>
            <NullValueIcon />
            {props.moduleSelected.hasItem === false ? <p>請點擊查看列表</p> : <p>請選擇專案表單，並點擊查看列表</p>}
          </NullValueBox> :
          <div>
            <div className="commontitle">
              <h1>{formInfo[props.moduleSelected.id].formTitle}</h1>
            </div>

            {/* 安衛的搜尋panel */}
            {
              Number(props.moduleSelected.id) === 4 &&
              (<FilterTable className="filter_table">
                <div className="">
                  文件編號：
                  <TxtInput name='formId' value={props.searchHS.formId} onChange={handlesearchHSChange} />
                </div>
                <br />
                <div className="">
                  承攬廠商：
                  <TxtInput name='vendor' value={props.searchHS.vendor} onChange={handlesearchHSChange} />
                </div>
                <br />
                <div className="">
                  檢查日期：
                  <TxtInput type="date" style={{ minWidth: '5em', margin: '4px 0' }} name='examineSDate' value={props.searchHS.examineSDate || ''} onChange={handlesearchHSChange} />
                  <span style={{ margin: '0 3px', }}>-</span>
                  <TxtInput type="date" style={{ minWidth: '5em', margin: '4px 0' }} name='examineEDate' value={props.searchHS.examineEDate || ''} onChange={handlesearchHSChange} />
                </div>
                <br />
                <div className="">
                  檢查地點：
                  <TxtInput name='position' value={props.searchHS.position} onChange={handlesearchHSChange} />
                </div>
                <br />
                <div className="">
                  檢查位置：
                  <TxtInput name='location' value={props.searchHS.location} onChange={handlesearchHSChange} />
                </div>

                <div className="queryMenu_btnbox" style={{ width: '100%', textAlign: 'right', }}>
                  <button className="btnL btnBlue" onClick={handleHSSearch}>搜尋</button>
                </div>
              </FilterTable>)
            }


            {/* 異常的搜尋panel */}
            {
              Number(props.moduleSelected.id) === 5 &&
              (<FilterTable className="filter_table">
                <div className="">
                  文件編號：
                  <TxtInput name='docId' value={props.abnormalSearch.docId} onChange={handleSearchChange} />
                </div>
                <br />
                <div className="">
                  更正單位：
                  <TxtInput name='vendor' value={props.abnormalSearch.vendor} onChange={handleSearchChange} />
                </div>
                <br />
                <div className="">
                  案件狀態：
                  <select name="caseStatus" id="" onChange={handleSearchChange} value={props.abnormalSearch.caseStatus}>
                    <option value=''>全部</option>
                    <option value='0'>列管中</option>
                    <option value='1'>已完成</option>
                  </select>
                </div>
                <br />
                <div className="">
                  通知改正日期：
                  <DateDiv>
                    <DateInput type="date" name='noticeSDate' value={props.abnormalSearch.noticeSDate || ''} onChange={handleSearchChange} />
                    <span style={{ margin: '0', }}>-</span>
                  </DateDiv>
                  <DateDiv>
                    <DateInput type="date" name='noticeEDate' value={props.abnormalSearch.noticeEDate || ''} onChange={handleSearchChange} />
                  </DateDiv>
                </div>
                <br />
                <div className="">
                  限定完成日期：
                  <DateDiv>
                    <DateInput type="date" name='improveSDeadline' value={props.abnormalSearch.improveSDeadline || ''} onChange={handleSearchChange} />
                    <span style={{ margin: '0', }}>-</span>
                  </DateDiv>
                  <DateDiv>
                    <DateInput type="date" name='improveEDeadline' value={props.abnormalSearch.improveEDeadline || ''} onChange={handleSearchChange} />
                  </DateDiv>
                </div>
                <br />
                <div className="">
                  確認完成日期：
                  <DateDiv>
                    <DateInput type="date" name='improveSDate' value={props.abnormalSearch.improveSDate || ''} onChange={handleSearchChange} />
                    <span style={{ margin: '0', }}>-</span>
                  </DateDiv>
                  <DateDiv>
                    <DateInput type="date" name='improveEDate' value={props.abnormalSearch.improveEDate || ''} onChange={handleSearchChange} />
                  </DateDiv>
                </div>

                <div className="queryMenu_btnbox" style={{ width: '100%', textAlign: 'right', }}>
                  <button className="btnL btnBlue" onClick={handleAbnormalSearch}>搜尋</button>
                </div>
              </FilterTable>)
            }

            {/* 整治工作監督日報表 panel */}
            {
              Number(props.moduleSelected.id) === 6 &&
              (<FilterTable className="filter_table">
                <div className="">
                  填報日期：
                  <TxtInput type="date" style={{ minWidth: '5em', margin: '4px 0' }} name='examineSDate' value={props.supervisionSearch.examineSDate || ''} onChange={handleSearchInputChangeDaily} />
                  <span style={{ margin: '0 3px', }}>-</span>
                  <TxtInput type="date" style={{ minWidth: '5em', margin: '4px 0' }} name='examineEDate' value={props.supervisionSearch.examineEDate || ''} onChange={handleSearchInputChangeDaily} />
                </div>

                <div className="queryMenu_btnbox" style={{ width: '100%', textAlign: 'right', }}>
                  <button className="btnL btnBlue" onClick={handleDailySearch}>搜尋</button>
                </div>
              </FilterTable>)
            }

            { }
            {
              Number(props.moduleSelected.id) === 7 &&
              (<FilterTable className="filter_table">
                <div className="">
                  填報日期：
                  <TxtInput type="date" style={{ minWidth: '5em', margin: '4px 0' }} name='fillinSDate' value={props.toolsSearch.fillinSDate || ''} onChange={handleSearchInputChangeTools} />
                  <span style={{ margin: '0 3px', }}>-</span>
                  <TxtInput type="date" style={{ minWidth: '5em', margin: '4px 0' }} name='fillinEDate' value={props.toolsSearch.fillinEDate || ''} onChange={handleSearchInputChangeTools} />
                </div>

                <div className="queryMenu_btnbox" style={{ width: '100%', textAlign: 'right', }}>
                  <button className="btnL btnBlue" onClick={handleToolsSearch}>搜尋</button>
                </div>
              </FilterTable>)
            }
            { }
            {
              Number(props.moduleSelected.id) === 8 &&
              (<FilterTable className="filter_table">
                <div className="">
                  報表月份：
                  <TxtInput type="date" style={{ minWidth: '5em', margin: '4px 0' }} name='examineSDate' value={props.monthlySearch.examineSDate || ''} onChange={handleMonthlyChange} />
                  <span style={{ margin: '0 3px', }}>-</span>
                  <TxtInput type="date" style={{ minWidth: '5em', margin: '4px 0' }} name='examineEDate' value={props.monthlySearch.examineEDate || ''} onChange={handleMonthlyChange} />
                </div>

                <div className="queryMenu_btnbox" style={{width: '100%', textAlign: 'right', }}>
                  <button className="btnL btnBlue" onClick={handleMonthlySearch}>搜尋</button>
                </div>
              </FilterTable>)
            }
            <div className="statusbar">
              {
                ![7, 8].includes(Number(props.moduleSelected.id)) && (
                  <div>
                    <button className={Number(props.formStatus) === 0 ? "btnM btnGrey" : "btnM btnWhite"} value={0} onClick={() => handleFormStatusClick(0)}>草稿 <span>({props.formStatusNum.numDraft})</span> </button>
                    <button className={Number(props.formStatus) === 1 ? "btnM btnGrey" : "btnM btnWhite"} value={1} onClick={() => handleFormStatusClick(1)}>審核中 <span>({props.formStatusNum.numAudit})</span> </button>
                    <button className={Number(props.formStatus) === 2 ? "btnM btnGrey" : "btnM btnWhite"} value={2} onClick={() => handleFormStatusClick(2)}>已結案 <span>({props.formStatusNum.numClosed})</span> </button>
                    <button className={Number(props.formStatus) === 3 ? "btnM btnGrey" : "btnM btnWhite"} value={3} onClick={() => handleFormStatusClick(3)}>已刪除 <span>({props.formStatusNum.numDelete})</span> </button>
                  </div>
                )
              }
              {
                Number(props.moduleSelected.id) === 7 && (
                  <div>
                    <button className={Number(props.formStatus) === 4 ? "btnM btnGrey" : "btnM btnWhite"} value={4} onClick={() => handleFormStatusClick(4)}>清單 <span>({props.toolsNum})</span> </button>
                    <button className={Number(props.formStatus) === 5 ? "btnM btnGrey" : "btnM btnWhite"} value={5} onClick={() => handleFormStatusClick(5)}>日統計 <span>({props.toolsTotalNum})</span> </button>
                    <button className={Number(props.formStatus) === 6 ? "btnM btnGrey" : "btnM btnWhite"} value={6} onClick={() => handleFormStatusClick(6)}>已刪除 <span>({props.toolsDeleteNum})</span> </button>
                  </div>
                )
              }
              {
                Number(props.moduleSelected.id) === 8 && (
                  <div>
                    <button className={Number(props.formStatus) === 0 ? "btnM btnGrey" : "btnM btnWhite"} value={0} onClick={() => handleFormStatusClick(0)}>清單 <span>({props.formStatusNum.numDraft})</span> </button>
                    <button className={Number(props.formStatus) === 3 ? "btnM btnGrey" : "btnM btnWhite"} value={3} onClick={() => handleFormStatusClick(3)}>已刪除 <span>({props.formStatusNum.numDelete})</span> </button>
                  </div>
                )
              }
            </div>

            {/* 一般安全衛生查驗表列表 */}
            {/* 一般版本 */}
            {
              Number(props.moduleSelected.id) === 4 && (
                <div>
                  {
                    props.projectHSFormList.length > 0 ? (<>
                      {/* 一般版本 */}
                      <div className="table">
                        <table>
                          <tbody>
                            <tr>
                              <td>檢查日期</td>
                              <td>文件編號</td>
                              <td>{props.projectHSIsNCO.isNCO ? '施工承攬廠商' : '承攬廠商'}</td>
                              <td>{props.projectHSIsNCO.isNCO ? '地點' : '檢查地點'}</td>
                              <td>{props.projectHSIsNCO.isNCO ? '位置' : '檢查位置'}</td>
                              <td>{props.projectHSIsNCO.isNCO ? '不合格件數' : '合格 / 不合格'}</td>
                              {
                                props.formStatus === 0 && <td>刪除</td>
                              }
                              {
                                props.formStatus === 2 && <td>匯出PDF</td>
                              }
                            </tr>
                            {
                              props.projectHSFormList.map(ele => (
                                // <tr key={ele._uuid} style={{ backgroundColor: ele.isNeedSign ? 'antiquewhite': '' }}>
                                <tr key={ele._uuid} className={ele.isNeedSign ? 'NeedSign' : ''}>                      <td>{ele.examineDate ? moment(ele.examineDate).format('YYYY-MM-DD') : ''}</td>
                                  <td>
                                    <Link to={{ pathname: `form-list/${props.moduleSelected.id}/${ele.id}` }} onClick={() => handleFormToUpdate(ele, 'HSForm')} className="nowrap">{ele.formId}</Link>
                                    {
                                      ele.isNeedSign && (<span className='icon_needsign'><i className="fas fa-pen-square"></i></span>)
                                    }
                                    {
                                      ele.abnormalId > 0 && (<span className="icon_abnormal" title="異常(不合格)通知暨改善表" onClick={() => handleGoAbnormalForm(ele)}><i className="fas fa-exclamation-circle"></i></span>)
                                    }
                                  </td>
                                  <td>{ele.vendor}</td>
                                  <td>{ele.position}</td>
                                  <td>{ele.location}</td>
                                  <td>{props.projectHSIsNCO.isNCO ? ele.unQualifiedNum : (ele.isQualified ? '合格' : '不合格')}</td>
                                  {
                                    props.formStatus === 0 && (<td><button className="btnS btnRed" onClick={() => handleDeleteForm(ele, 4)}><i className="far fa-trash-alt"></i></button></td>)
                                  }
                                  {
                                    props.formStatus === 2 &&
                                    (<td>
                                      <button className="btnS btnBlue" onClick={() => ExportPDF(ele, 'HSForm')}>
                                        {
                                          ele.IsPDFExporting === true ?
                                            <div>
                                              匯出中，請稍後...
                                              <i className="fas fa-circle-notch fa-spin"></i>
                                            </div> : '匯出PDF'
                                        }
                                      </button>
                                    </td>)
                                  }
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                    </>) :
                      <NullValueBox>
                        <NullValueIcon />
                        <p>暫無紀錄</p>
                      </NullValueBox>
                  }
                </div>
              )
            }


            {/* 異常(不合格)通知暨改善表 */}
            {
              Number(props.moduleSelected.id) === 5 && (
                <div>
                  {
                    props.abnormalList.length > 0 ? (<div className="table">
                      <table>
                        <tbody>
                          <tr>
                            <td>項次</td>
                            <td>文件編號</td>
                            <td>更正單位</td>
                            <td style={{ maxWidth: '10rem', }}>說明缺失具體情形</td>
                            <td>通知改正日期</td>
                            <td>限定完成日期</td>
                            <td>確認完成日期</td>
                            <td>案件狀態</td>
                            {
                              props.formStatus === 0 && <td>刪除</td>
                            }
                            {
                              props.formStatus === 2 && <td>產出改善表</td>
                            }
                          </tr>
                          {
                            props.abnormalList.map((ele, idx) => (
                            // <tr key={ele._uuid} style={{ backgroundColor: ele.isNeedSign ? 'antiquewhite': '' }}>
                              <tr key={ele._uuid} className={ele.isNeedSign ? 'NeedSign' : ''}>
                                <td className="nowrap">{idx + 1}</td>
                                <td>
                                  {/* <Link to="form-detail">{ele.docId}</Link> */}
                                  <Link to={{ pathname: `form-list/${props.moduleSelected.id}/${ele.id}` }} onClick={() => handleFormToUpdate(ele, 'abnormal')} className="nowrap">{ele.docId}</Link>
                                  {
                                    ele.isNeedSign && (<span className='icon_needsign'><i className="fas fa-pen-square"></i></span>)
                                  }
                                </td>
                                <td>{ele.vendor}</td>
                                <td>{ele.flawNote}</td>
                                <td>{ele.noticeDate ? moment(ele.noticeDate).format('YYYY-MM-DD') : ''}</td>
                                <td>{ele.improveDeadline ? moment(ele.improveDeadline).format('YYYY-MM-DD') : ''}</td>
                                <td>{ele.improveDate ? moment(ele.improveDate).format('YYYY-MM-DD') : ''}</td>
                                <td>{ele.caseStatus}</td>
                                {
                                  props.formStatus === 0 && (<td><button className="btnS btnRed" onClick={() => handleDeleteForm(ele, 5)}><i className="far fa-trash-alt"></i></button></td>)
                                }
                                {
                                  props.formStatus === 2 &&
                                  (<td>
                                    <button className="btnS btnBlue" onClick={() => ExportPDF(ele, 'abnormal')}>
                                      {
                                        ele.IsPDFExporting === true ?
                                          <div>
                                            匯出中，請稍後...
                                            <i className="fas fa-circle-notch fa-spin"></i>
                                          </div> : '產出改善表'
                                      }
                                    </button>
                                  </td>)
                                }
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>) :
                      <NullValueBox>
                        <NullValueIcon />
                        <p>暫無紀錄</p>
                      </NullValueBox>
                  }
                </div>
              )

            }

            {/* 監督日報表 */}
            {
              Number(props.moduleSelected.id) === 6 && (
                <div>
                  {
                    props.supervisionList.length > 0 ? (<>
                      <div className="table">
                        <table>
                          <tbody>
                            <tr>
                              <td>填報日期</td>
                              <td>文件編號</td>
                              {
                                props.formStatus === 0 && <td>刪除</td>
                              }
                              {
                                props.formStatus === 2 && <td>匯出PDF</td>
                              }
                              {
                                props.formStatus === 2 && <td>匯出無簽章PDF</td>
                              }
                            </tr>
                            {
                              props.supervisionList.map(ele => (
                                <tr key={ele._uuid} className={ele.isNeedSign ? 'NeedSign' : ''}>
                                  <td>{ele.fillInDate ? moment(ele.fillInDate).format('YYYY-MM-DD') : ''}</td>
                                  <td>
                                    <Link to={{ pathname: `form-list/${props.moduleSelected.id}/${ele.id}` }} onClick={() => handleFormToUpdate(ele, 'DailyForm')} className="nowrap">{ele.formID}</Link>
                                    {
                                      ele.isNeedSign && (<span className='icon_needsign'><i className="fas fa-pen-square"></i></span>)
                                    }
                                  </td>
                                  {
                                    props.formStatus === 0 && (<td><button className="btnS btnRed" onClick={() => handleDeleteForm(ele, 6)}><i className="far fa-trash-alt"></i></button></td>)
                                  }
                                  {
                                    props.formStatus === 2 &&
                                    (<td>
                                      <button className="btnS btnBlue" onClick={() => ExportPDF(ele, 'DailyForm')}>
                                        {
                                          ele.IsPDFExporting === true ?
                                            <div>
                                              匯出中，請稍後...
                                              <i className="fas fa-circle-notch fa-spin"></i>
                                            </div> : '匯出PDF'
                                        }
                                      </button>
                                    </td>)
                                  }
                                  {
                                    props.formStatus === 2 &&
                                    (<td>
                                      <button className="btnS btnBlue" onClick={() => ExportPDF(ele, 'DailyFormNoSign')}>
                                        {
                                          ele.IsPDFExporting === true ?
                                            <div>
                                              匯出中，請稍後...
                                              <i className="fas fa-circle-notch fa-spin"></i>
                                            </div> : '匯出無簽章PDF'
                                        }
                                      </button>
                                    </td>)
                                  }
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                    </>) :
                      <NullValueBox>
                        <NullValueIcon />
                        <p>暫無紀錄</p>
                      </NullValueBox>
                  }
                </div>
              )
            }

            {/* 取得每日出工機具 */}
            {
              Number(props.moduleSelected.id) === 7 && (
                <div>
                  {
                    (props.toolsList.length > 0 || props.toolsTotalList.length > 0) ? (
                      <div className="table">
                        <table>
                          <tbody>
                            <tr>
                              <td>填報日期</td>
                              <td>
                                {(props.formStatus === 4 || props.formStatus === 6) && '填報人'}
                                {props.formStatus === 5 && '出工數'}
                              </td>
                              {(props.formStatus === 4) && <td>刪除</td>}
                              {props.formStatus === 5 && <td>機具數</td>}
                            </tr>
                            {
                              ([4, 6].includes(props.formStatus)) && props.toolsList.map(ele => (
                                <tr key={ele._uuid} className={ele.isNeedSign ? 'NeedSign' : ''}>
                                  <td>
                                    <Link to={{ pathname: `form-list/${props.moduleSelected.id}/${moment(ele.fillinDate).format('YYYY-MM-DD') }` }} onClick={() => handleFormToUpdate(ele, 'ToolsForm')} className="nowrap">
                                      {ele.fillinDate ? moment(ele.fillinDate).format('YYYY-MM-DD') : ''}</Link>
                                  </td>
                                  <td>
                                    {ele.createUser}
                                  </td>
                                  {
                                    props.formStatus === 4 && (<td><button className="btnS btnRed" onClick={() => handleDeleteTools(ele)}><i className="far fa-trash-alt"></i></button></td>)
                                  }
                                </tr>
                              ))
                            }
                            {
                              (props.formStatus === 5) && props.toolsTotalList.map(ele => (
                                <tr key={ele._uuid} className={ele.isNeedSign ? 'NeedSign' : ''}>
                                  <td>
                                    <Link to={{ pathname: `form-list/${props.moduleSelected.id}/${moment(ele.fillinDate).format('YYYY-MM-DD')}` }} onClick={() => handleFormToUpdate(ele, 'ToolsTotalForm')} className="nowrap">
                                      {ele.fillinDate ? moment(ele.fillinDate).format('YYYY-MM-DD') : ''}</Link>
                                  </td>
                                  <td>
                                    {ele.personnelTotal}
                                  </td>
                                  <td>
                                    {ele.implementsTotal}
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                    ) :
                      <NullValueBox>
                        <NullValueIcon />
                        <p>暫無紀錄</p>
                      </NullValueBox>
                  }
                </div>
              )
            }
            {/* 取得月報 */}
            {
              Number(props.moduleSelected.id) === 8 && (
                <div>
                  {
                    props.supervisionMonthlyList.length > 0 ? (<>
                      <div className="table">
                        <table>
                          <tbody>
                            <tr>
                              <td>報表編號</td>
                              <td>新增日期</td>
                              <td>最後更新日期</td>
                              {
                                props.formStatus===0 && (
                                  <td>匯出PDF</td>
                                )
                              }
                              {
                                props.formStatus===0 && (
                                  <td>刪除</td>
                                )
                              }
                            </tr>
                            {
                              props.supervisionMonthlyList.map(ele => (
                                <tr key={ele._uuid}>
                                  <td>
                                    <Link to={{ pathname: `form-list/${props.moduleSelected.id}/${ele.id}` }} onClick={() => handleFormToUpdate(ele, 'MonthlyForm')} className="nowrap">{ele.formID}</Link>
                                  </td>
                                  <td>{ele.fillInMonth ? moment(ele.fillInMonth).format('YYYY-MM') : ''}</td>
                                  <td>
                                    {ele.modifyDate ? moment(ele.modifyDate).format('YYYY-MM-DD') : moment(ele.createDate).format('YYYY-MM-DD')}
                                  </td>
                                  {
                                    props.formStatus === 0 && (
                                      <td>
                                        <button className="btnS btnBlue" onClick={() => ExportPDF(ele, 'MonthlyForm')}>
                                          {
                                            ele.IsPDFExporting === true ?
                                              <div>
                                                匯出中，請稍後...
                                                <i className="fas fa-circle-notch fa-spin"></i>
                                              </div> : '匯出PDF'
                                          }
                                        </button>
                                      </td>
                                    )
                                  }
                                  {
                                    props.formStatus===0 && (
                                      <td><button className="btnS btnRed" onClick={() => handleDeleteForm(ele, 8)}><i className="far fa-trash-alt"></i></button></td>
                                    )
                                  }
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                    </>) :
                      <NullValueBox>
                        <NullValueIcon />
                        <p>暫無紀錄</p>
                      </NullValueBox>
                  }
                </div>
              )
            }
            {
              Number(props.moduleSelected.id) !== 7 &&
              <Pagination
                formStatus={props.formStatus}
                setFormStatus={props.setFormStatus}
                totalPages={props.totalPages}
                setTotalPages={props.setTotalPages}
                currentPage={props.currentPage}
                setCurrentPage={props.setCurrentPage}
                projectFormSelected={props.projectFormSelected}
                apiFunc={formInfo[props.moduleSelected.id].apiFunc}
                setList={formInfo[props.moduleSelected.id].setList}
                searchHS={props.searchHS}
                setProjectHSIsNCO={props.setProjectHSIsNCO}
                abnormalSearch={props.abnormalSearch}
                supervisionSearch={props.supervisionSearch}
                monthlySearch={props.monthlySearch}
                pagesModuleId={props.pagesModuleId}
              />
            }

            {
              Number(props.moduleSelected.id) === 7 &&
              <PaginationTool
                formStatus={props.formStatus}
                setFormStatus={props.setFormStatus}
                totalPages={props.totalPages}
                setTotalPages={props.setTotalPages}
                currentPage={props.currentPage}
                setCurrentPage={props.setCurrentPage}
                projectFormSelected={props.projectFormSelected}
                apiFunc={formInfo[props.moduleSelected.id].apiFunc}
                setList={formInfo[props.moduleSelected.id].setList}
                toolsSearch={props.toolsSearch}
                setToolsList={props.setToolsList}
                setToolsTotalList={props.setToolsTotalList}
                pagesModuleId={props.pagesModuleId}
                toolsFormStatus={props.formStatus}
              />
            }
          </div>
      }
    </>
  );
};

export default ProjectFormListFilter;