import React, { useState, useContext, useRef, useEffect } from "react";
import styled from '@emotion/styled';
import "./../../style/Form.scss";
import "./../../style/FormDetail.scss";
// import DefaultNoImage from '../../images/image-regular.svg';
// import ReverImage from '../../images/River.jpg';
// import TestPDF from '../../pdf/好多字.pdf';

import tools from './../assets/tools';
import { FormAPI, AccountAPI, DocumentAPI } from "../assets/api";
import { notifyFailed, notifySuccess, notifyWarning } from './../assets/toaster';
import { ProjectContext } from './../contexts/ProjectContext';

import PictureUpload from './../components/PictureUpload';
import AttachErrorNotify from "./AttachErrorNotify/AttachErrirNotify";
import moment from "moment";

const FormTitle = styled.h1`
  font-size: 1.4rem;
  font-weight: 700;
  margin: 8px;
`;

const VersionSelect = styled.select`
  border: 1px solid #999;
  border-radius: 3px;
  padding: 3px;
  margin: 0 3px 3px 3px;
  height: 31px;
  option{
    padding: 2px;
  }
  @media (min-width: 450px) {
    min-width: 12em;
    max-width: 100%;
  }
  @media (max-width: 450px) {
    width: 100%;
  }
`;

const BtnToViewDraw = styled.button`
  vertical-align: top;
`;

// const DateDiv = styled.label`
//   display: inline-block !important;
//   margin: 5px;
//   @media (max-width: 450px) {
//     display: block !important;
//     margin: 0 !important;
//     padding: 0 !important;
//   }
// `;

const DateInput = styled.input`
  padding: 0 4px;
  box-sizing: border-box;
  height: 32px;
  border-radius: 3px;
  border: 1px solid #999;
  // min-width: 5em;
  max-width: 18em;
  margin: 0 3px;
  @media (max-width: 450px) {
    width: 100%;
  }
`;

const FakeHr = styled.div`
  width: 92%;
  height: 2px;
  background-color: #DDD;
  margin: 40px 4% 40px 4%;
`;

const TableBox = styled.div`
  padding: 0 10px;
`;

const SpotCheckDescTextarea = styled.textarea`
  border: 1px solid #999;
  width: 100%;
  // @media (max-width: 768px) {
  //   width: 12rem;
  // }
  // @media (max-width: 450px) {
  //   width: 6rem;
  // }
`;

const MobileBr = styled.br`
  display: none;
  @media (max-width: 450px) {
    display: unset;
  }
`;

const ResultTd = styled.td`
  white-space: no-wrap;
  @media (max-width: 768px) {
    min-width: 115px;
  }
  @media (max-width: 450px) {
    min-width: 65px;
  }
`;

const Keyboard = styled.div`
  width: 50%;
  padding: 10px 6px;
  border-radius: 10px;
  border: 1px solid #AAA;
  margin: 0 10px;
  box-sizing: border-box;
  button{
    width: 36px;
    height: 32px;
    box-shadow: 0 1px 2px 0px #ccc;
    border: 1px solid #7B797999;
    margin: 3px;
    &:active {
      transform: translateY(1px);
      box-shadow: 0 0px 2px 0px #ccc;
    }
  }
  @media (max-width: 450px) {
    width: calc(100% - 20px);
  }
`;

const HistoricalRecord = styled.div`
  background-color: #EEE;
  padding: 10px 15px;
  margin: 10px;
`;

const InputReturnReason = styled.div`
  background-color: #EEE;
  padding: 20px ;
  margin: 10px;
  textarea{
    margin: 10px 0;
    width: 100%;
    border: 1px solid #BBB;
  }
  .btnbox{
    text-align: right;
    margin: 10px 0 0 0;
  }
`;

// const FaultDescTextarea = styled.textarea`
//   border: 1px solid #999;
//   width: 100%;
//   max-width: 1064px;
// `;

// const ImprovePhotoDiv = styled.div`
//   background-color: #E1E1E1;
//   padding: 25px 5px 5px 5px;
//   margin: 0 10px;
// `;

const SignetSelect = styled.select`
  border: 1px solid #999;
  border-radius: 3px;
  padding: 3px;
  margin: 0 3px;
  height: 30px;
  max-width: 87%;
  option{
    padding: 2px;
  }
`;

// const SingleFileShadow = styled.div`
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.35);
//   border-radius: 10px;
//   display: none;
// `;

// 材料/設備品質抽驗紀錄表
const QualityCheckRecord = (props) => {
  const { projectInfo } = useContext(ProjectContext);
  let projectId = projectInfo.id;

  const [isLightBoxOpen, setisLightBoxOpen] = useState(false);

  const LightBox_style = {
    display: isLightBoxOpen ? 'block' : 'none',
  };

  const [isSaving, setIsSaving] = useState(false);

  const [formContent, setFormContent] = useState({
    ...props.formContent,
    itemList: props.formContent.itemList.map(ele => {
      return props.formContent.id > 0 ?
        ({
          ...ele,
          criterion: ele.criterion || '',
          num: ele.num || '',
          value: ele.value || '',
          eqipQExamineMainId: props.formContent.id,
          itemNameCursorPos: 0,
          criterionCursorPos: 0,
          numCursorPos: 0,
          valueCursorPos: 0,
          status: 2
        }) :
        ({
          ...ele,
          eqipQExamineMainId: 0,
          result: 1,
          note: '',
          itemNameCursorPos: 0,
          criterionCursorPos: 0,
          numCursorPos: 0,
          valueCursorPos: 0,
          status: 1
        });
    }),
    multipleChoiceList: props.formContent.id > 0 ?
      [{
        id: 2,
        topicName: "應提出證明文件",
        sort: 1,
        optionList: props.formContent.multipleChoiceList.filter(el => el.multipleChoiceTopicId === 2).map(ele => ({
          ...ele,
          optionName: ele.multipleChoiceOptionName,
          content: ele.otherContent || '',
          status: 2
        }))
      },{
        id: 3,
        topicName: "查驗方式",
        sort: 1,
        optionList: props.formContent.multipleChoiceList.filter(el => el.multipleChoiceTopicId === 3).map(ele => ({
          ...ele,
          optionName: ele.multipleChoiceOptionName,
          content: ele.otherContent || '',
          status: 2
        }))
      }] :
      props.formContent.multipleChoiceList.map(ele => ({
        ...ele,
        optionList: ele.optionList.map(ele1 => ({
          ...ele1,
          content: ele.content || '',
          isSelect: false,
          status: 1
        }))
      })),
    photoList: props.formContent.id > 0 ? props.formContent.photoList.map(ele => ({...ele, _uuid: tools._UUID(), status: 2, mainId: props.formContent.id, isExist: true})) : [],
    _uuid: tools._UUID(),
    formId: props.formContent.id > 0 ? props.formContent.formId || '' : '',
    eqipName: props.formContent.id > 0 ? props.formContent.eqipName || '' : '',
    location: props.formContent.id > 0 ? props.formContent.location || '' : '',
    ekphrasisID: props.formContent.id > 0 ? props.formContent.ekphrasisID : 0,
    ekphrasis:  props.formContent.id > 0 ? [].concat.apply([], props.formContent.ekphrasisList.map(ele => ele.ekphrasisList)).find(ele1 => ele1.id === props.formContent.ekphrasisID) : {},
    examineDate: props.formContent.id > 0 ? (props.formContent.examineDate ? moment(props.formContent.examineDate).format('YYYY-MM-DD') : '') : '',
    formStatus: props.formContent.id > 0 ? props.formContent.formStatus : 0, // 表單狀態,0:草稿,1:審核中,2:已結案,3:已刪除,4:真刪除
    userStamp: props.formContent.id > 0 ? {} : {},
    status: props.formContent.id > 0 ? 2 : 1,
    editable: props.formContent.id > 0 ? props.formContent.formStatus === 0 : true,
  });

  const [abnormalForm, setAbnormalForm] = useState({
    ...props.abnormalForm,
    projectFormId: formContent.projectFormId,
    mainId:  props.formContent.id > 0 ? props.formContent.id : 0,
    docId: props.abnormalForm.docId || '',
    vendor: props.abnormalForm.vendor || '',
    ExamineDate: props.abnormalForm.examineDate ? moment(props.abnormalForm.examineDate).format('YYYY-MM-DD') : '',
    improveDeadline: props.abnormalForm.improveDeadline ? moment(props.abnormalForm.improveDeadline).format('YYYY-MM-DD') : '',
    flawNote: props.abnormalForm.flawNote || '',
    status: props.abnormalForm.id > 0 ? 2 : 1,
    photoList: props.abnormalForm.id > 0 ? props.abnormalForm.photoList.map(ele => ({...ele, _uuid: tools._UUID(), status: 2, mainId: props.formContent.id, isExist: true})) : [],
    editable: props.abnormalForm.id > 0 ? props.abnormalForm.formStatus === 0 : true
  });

  function Photo ({_uuid=0, id=0, projectFormId, mainId, content, status=0}) {
    this._uuid = _uuid;
    this.id = id;
    this.projectFormId = projectFormId;
    this.mainId = mainId;
    this.file = null;
    this.photoUrl = null;
    this.content = content;
    this.status = status;
  }

  const keyboardSignList = ['□', '■' ,'△' ,'▲' ,'○' ,'◉' ,'℃' ,'Ω' ,'φ' ,'☑', '×', '%', 'ψ', '‰', '±', '≧', 'mm', 'cm', 'cm²', 'm', 'm³'];

  const [editingCell, setEditingCell] = useState({});

  // pdf useRef
  const pdfRef = useRef('');
  const handleOpenEkphrasis = () => {
    if (window.navigator.onLine) {
      setisLightBoxOpen(prev => !prev);
      pdfRef.current.src = formContent.ekphrasis.fileUrl;
    } else {
      notifyFailed({Msg: '網路未連線', isReload: false});
    }
  };

  // insert keyboard for textareas
  const itemNameRef = useRef([]);
  const criterionRef = useRef([]);
  const numRef = useRef([]);
  const valueRef = useRef([]);

  const getCursorPos = (name, el, ix, e) => {
    setEditingCell({
      row: ix,
      col: name
    });
    switch (name) {
    case 'itemName':
      itemNameRef.current[ix].onmouseup = function () {
        setFormContent({
          ...formContent,
          itemList: formContent.itemList.map(ele => (
            ele.id === el.id ? {...ele, [e.target.name]: e.target.value, itemNameCursorPos: itemNameRef.current[ix].selectionStart} : ele
          ))
        });
      };
      break;
    case 'criterion':
      criterionRef.current[ix].onmouseup = function () {
        setFormContent({
          ...formContent,
          itemList: formContent.itemList.map(ele => (
            ele.id === el.id ? {...ele, [e.target.name]: e.target.value, criterionCursorPos: criterionRef.current[ix].selectionStart} : ele
          ))
        });
      };
      break;
    case 'num':
      numRef.current[ix].onmouseup = function () {
        setFormContent({
          ...formContent,
          itemList: formContent.itemList.map(ele => (
            ele.id === el.id ? {...ele, [e.target.name]: e.target.value, numCursorPos: numRef.current[ix].selectionStart} : ele
          ))
        });
      };
      break;
    case 'value':
      valueRef.current[ix].onmouseup = function () {
        setFormContent({
          ...formContent,
          itemList: formContent.itemList.map(ele => (
            ele.id === el.id ? {...ele, [e.target.name]: e.target.value, valueCursorPos: valueRef.current[ix].selectionStart} : ele
          ))
        });
      };
      break;
    default:
      break;
    }
  };

  const InsertMark = (e, ix, name) => {
    switch (name) {
    case 'itemName':
      if (itemNameRef.current[ix]) {
        let prefix = itemNameRef.current[ix].value.substring(0, formContent.itemList[ix].itemNameCursorPos);
        let suffix = itemNameRef.current[ix].value.substring(formContent.itemList[ix].itemNameCursorPos);
        setFormContent({
          ...formContent,
          itemList: formContent.itemList.map((ele, idx) => (
            idx === ix ? {...ele, itemName: prefix + e.target.value + suffix} : ele
          ))
        });
      }
      break;
    case 'criterion':
      if (criterionRef.current[ix]) {
        let prefix = criterionRef.current[ix].value.substring(0, formContent.itemList[ix].criterionCursorPos);
        let suffix = criterionRef.current[ix].value.substring(formContent.itemList[ix].criterionCursorPos);
        setFormContent({
          ...formContent,
          itemList: formContent.itemList.map((ele, idx) => (
            idx === ix ? {...ele, criterion: prefix + e.target.value + suffix} : ele
          ))
        });
      }
      break;
    case 'num':
      if (numRef.current[ix]) {
        let prefix = numRef.current[ix].value.substring(0, formContent.itemList[ix].numCursorPos);
        let suffix = numRef.current[ix].value.substring(formContent.itemList[ix].numCursorPos);
        setFormContent({
          ...formContent,
          itemList: formContent.itemList.map((ele, idx) => (
            idx === ix ? {...ele, num: prefix + e.target.value + suffix} : ele
          ))
        });
      }
      break;
    case 'value':
      if (valueRef.current[ix]) {
        let prefix = valueRef.current[ix].value.substring(0, formContent.itemList[ix].valueCursorPos);
        let suffix = valueRef.current[ix].value.substring(formContent.itemList[ix].valueCursorPos);
        setFormContent({
          ...formContent,
          itemList: formContent.itemList.map((ele, idx) => (
            idx === ix ? {...ele, value: prefix + e.target.value + suffix} : ele
          ))
        });
      }
      break;
    default:
      break;
    }
  };

  // user info to check sigon off process
  const [userInfo, setUserInfo] = useState({
    'userName': '',
    'name_ZHTW': '',
    'name_EN': '',
    'email': '',
    'phoneNumber': '',
    'projectRoles': []
  });

  async function GetUserInfo() {
    try {
      let data = await AccountAPI.GetUserInfo();
      setUserInfo(data);
    } catch (error) {
      notifyFailed({Msg: '無法取得使用者資訊', isReload: false});
    }
  }

  useEffect(() => {
    GetUserInfo();
  }, []);

  const handleInputChange = (e) => {
    setFormContent({
      ...formContent,
      [e.target.name]: e.target.value
    });
  };

  const handleCheckChange = (e, el) => {
    setFormContent({
      ...formContent,
      multipleChoiceList: formContent.multipleChoiceList.map(ele => ({
        ...ele,
        optionList: ele.optionList.map(ele1 => (
          ele1.id === el.id ? {...ele1, isSelect: e.target.checked, content: e.target.checked ? ele1.content : ''} : ele1
        ))
      })),
    });
  };

  const handleOtherContentChange = (e, el) => {
    setFormContent({
      ...formContent,
      multipleChoiceList: formContent.multipleChoiceList.map(ele => ({
        ...ele,
        optionList: ele.optionList.map(ele1 => (
          ele1.id === el.id ? {...ele1, content: e.target.value} : ele1
        ))
      })),
    });
  };

  const handleItemInputChange = (e, el, ix) => {
    setFormContent({
      ...formContent,
      itemList: formContent.itemList.map(ele => (
        ele.id === el.id ? {...ele, [e.target.name]: e.target.value, criterionCursorPos: criterionRef.current[ix].selectionStart, numCursorPos: numRef.current[ix].selectionStart, valueCursorPos: valueRef.current[ix].selectionStart} : ele
      ))
    });
  };

  const handleEkphrasisChange = (e) => {
    setFormContent({
      ...formContent,
      ekphrasisID: Number(e.target.value),
      ekphrasis: [].concat.apply([], formContent.ekphrasisList.map(ele => ele.ekphrasisList)).find(ele1 => ele1.id === Number(e.target.value))
    });
  };

  const handleItemCheckChange = (e, el) => {
    setFormContent({
      ...formContent,
      itemList: formContent.itemList.map(ele => (ele.id === el.id ? {...ele, result: e.target.value} : ele))
    });
  };

  const handleCreatePhoto = () => {
    setFormContent({
      ...formContent,
      photoList: [...formContent.photoList, new Photo({ _uuid: tools._UUID(), id: 0, projectFormId: formContent.projectFormId, mainId: 0, content: '', status: 1})]
    });
  };

  const handleImgUpload = (el) => {
    setFormContent({
      ...formContent,
      photoList: formContent.photoList.map(ele => (ele._uuid === el._uuid ? el : ele))
    });
  };

  const handleImgDelete = (el) => {
    if (el.isExist) {
      setFormContent({
        ...formContent,
        photoList: formContent.photoList.map(ele => (ele._uuid === el._uuid ? {...el, status: 3} : ele))
      });
    } else {
      setFormContent({
        ...formContent,
        photoList: formContent.photoList.filter(ele => ele._uuid !== el._uuid)
      });
    }
  };

  const handleReturnSignOff = async () => {
    notifyWarning({ Msg: <ReturnConfirm /> });
  };

  const ReturnConfirm = ({ closeToast }) => (
    <div>
      確定退回表單？若有【異常(不合格)通知暨改善表】將會同時退回，並刪除【異常追蹤】內容
      <button className="btnS btnRed" onClick={() => ReturnSignOff()}>確定退回</button>
      <button className="btnS btnWhite" onClick={closeToast}>取消</button>
    </div>
  );

  const ReturnSignOff = async () => {
    if (!formContent.returnNote) {
      notifyFailed({ Msg: '請確認是否有填寫退回理由。', isReload: false });
      return;
    }
    setIsSaving(true);
    try {
      // console.log({
      //   projectId: projectId,
      //   moduleId: 1,
      //   formId: formContent.projectFormId,
      //   mainId: formContent.id,
      //   note: formContent.returnNote || '',
      //   action: 3,
      // });
      await FormAPI.ReturnSignOff({
        projectId: projectId,
        moduleId: 1,
        formId: formContent.projectFormId,
        mainId: formContent.id,
        note: formContent.returnNote || '',
        action: 3,
      });

      // 若有異常表單
      if (abnormalForm.id > 0) {
        // 同時退回異常表單
        await FormAPI.ReturnSignOff({
          projectId: projectId,
          moduleId: 5,
          formId: formContent.projectFormId,
          mainId: abnormalForm.id,
          note: formContent.returnNote || '',
          action: 3,
        });

        // 退回異常同時刪除異常追蹤
        let formData = new FormData();
        formData.append(`id`, abnormalForm.id);
        formData.append(`status`, 3);
        await FormAPI.ModifyAbnormalTrack({ projectId, modifyAbnormalTrack: formData });
        notifySuccess({ Msg: '表單(包含異常表單)退回成功', cbfunc: '' });
      }
      setIsSaving(false);

      // 退回後關閉頁籤
      props.setFormTabList(props.formTabList.filter(ele => ele._uuid !== props.uuid));
      props.setFormStatus(0);
    } catch (error) {
      setIsSaving(false);
      notifyFailed({ Msg: '發生錯誤，表單退回失敗，請確認是否有填寫退回理由。', isReload: false });
    }
  };

  async function GetEQFormInfo(eqipQId) {
    try {
      let data = await FormAPI.GetEQFormInfo(eqipQId);
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  async function GetAbnormalInfo({projectId, abnormalId}) {
    try {
      let data = await FormAPI.GetAbnormalInfo({projectId, abnormalId});
      return data;
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }

  // 檢查欄位必填
  const FormValidate = () => {
    let isValid = true;
    if (!formContent.formId ||
        !formContent.eqipName ||
        !formContent.location ||
        // !formContent.ekphrasisID ||
        !formContent.examineDate ||
        [].concat.apply([], formContent.multipleChoiceList.filter(el => el.id === 2).map(ele => ele.optionList)).filter(ele => ele.isSelect === true).length === 0 ||
        [].concat.apply([], formContent.multipleChoiceList.filter(el => el.id === 3).map(ele => ele.optionList)).filter(ele => ele.isSelect === true).length === 0 ||
        [].concat.apply([], formContent.multipleChoiceList.map(ele => ele.optionList)).filter(ele => ele.isSelect === true && ele.hasContent === true && !ele.content).length > 0
    ) {
      isValid = false;
    }

    // 若抽查結果不為'無此檢查項目'，則其他欄位為必填
    formContent.itemList.forEach(ele => {
      if (Number(ele.result) !== 0 && (!ele.criterion.trim() || !ele.num.trim() || !ele.value.trim())) {
        isValid = false;
      }
    });

    // 若抽查結果沒有不合格，則檢查是否有照片；有照片也要檢查說明
    if (formContent.itemList.filter(ele => Number(ele.result) === 2).length === 0) {
      if (formContent.photoList.filter(ele => ele.status !== 3).length === 0) {
        isValid = false;
      } else {
        if (formContent.photoList.filter(ele => !ele.content).length > 0) {
          isValid = false;
          return isValid;
        }
        if (formContent.photoList.filter(ele => !ele.content.trim()).length > 0) {
          isValid = false;
        }
      }
    }
    return isValid;
  };

  // 檢查異常表單欄位必填
  const AbnormalValidate = () => {
    let isValid = true;
    if (!abnormalForm.docId ||
      !abnormalForm.ExamineDate ||
      !abnormalForm.vendor ||
      !abnormalForm.improveDeadline ||
      !abnormalForm.flawNote ||
      abnormalForm.photoList.filter(ele => ele.status !== 3).length === 0
    ) {
      isValid = false;
    }

    abnormalForm.photoList.filter(el => el.status !== 3).forEach(ele => {
      if (!ele.content) {
        isValid = false;
      }
    });
    return isValid;
  };

  const handleSave = async({ isSubmit }) => {
    // 檢查網路狀態
    if (!window.navigator.onLine) {
      notifyFailed({Msg: '網路未連線', isReload: false});
      return;
    }

    if (!formContent.formId) {
      notifyFailed({Msg: '請填寫「設備功能運轉測試表」表單編號', isReload: false});
      return;
    }
    setIsSaving(true);
    let formData = new FormData();
    formData.append(`id`, formContent.id || 0);
    formData.append(`projectFormId`, formContent.projectFormId);
    formData.append(`formId`, formContent.formId);
    formData.append(`eqipName`, formContent.eqipName);
    formData.append(`location`, formContent.location);
    formData.append(`examineDate`, formContent.examineDate);
    formData.append(`status`, formContent.status);
    if (formContent.ekphrasisID > 0) formData.append(`ekphrasisID`, formContent.ekphrasisID);
    // 多選題
    let multipleChoicePostList = [];
    for (let i = 0; i < formContent.multipleChoiceList.length; i++) {
      let ele = formContent.multipleChoiceList[i];
      for (let j = 0; j < ele.optionList.length; j++) {
        let ele1 = ele.optionList[j];
        multipleChoicePostList.push({
          id: ele1.id > 0 ? ele1.id : 0,
          mainId: 0,
          multipleChoiceTopicId: ele1.multipleChoiceTopicId || ele.id,
          multipleChoiceTopicName: ele1.multipleChoiceTopicName || ele.topicName,
          multipleChoiceOptionID: ele1.multipleChoiceOptionID || ele1.id,
          multipleChoiceOptionName: ele1.optionName,
          otherContent: ele1.content,
          isSelect: ele1.isSelect,
          sort: ele1.sort,
          status: ele1.status
        });
      }
    }
    multipleChoicePostList.forEach((ele, idx) => {
      formData.append(`multipleChoiceList[${idx}].id`, ele.id);
      formData.append(`multipleChoiceList[${idx}].mainId`, ele.mainId);
      formData.append(`multipleChoiceList[${idx}].multipleChoiceTopicId`, ele.multipleChoiceTopicId);
      formData.append(`multipleChoiceList[${idx}].multipleChoiceTopicName`, ele.multipleChoiceTopicName);
      formData.append(`multipleChoiceList[${idx}].multipleChoiceOptionID`, ele.multipleChoiceOptionID);
      formData.append(`multipleChoiceList[${idx}].multipleChoiceOptionName`, ele.multipleChoiceOptionName);
      formData.append(`multipleChoiceList[${idx}].otherContent`, ele.otherContent);
      formData.append(`multipleChoiceList[${idx}].isSelect`, ele.isSelect);
      formData.append(`multipleChoiceList[${idx}].sort`, ele.sort);
      formData.append(`multipleChoiceList[${idx}].status`, ele.status);
    });
    // 抽驗項目
    for (let i = 0; i < formContent.itemList.length; i++) {
      let ele = formContent.itemList[i];
      formData.append(`itemList[${i}].id`, props.formContent.id > 0 ? ele.id : 0);
      formData.append(`itemList[${i}].eqipQExamineMainId`, ele.eqipQExamineMainId);
      formData.append(`itemList[${i}].itemId`, props.formContent.id > 0 ? ele.itemId : ele.id);
      formData.append(`itemList[${i}].itemName`, ele.itemName);
      formData.append(`itemList[${i}].criterion`, ele.criterion || '');
      formData.append(`itemList[${i}].result`, ele.result);
      formData.append(`itemList[${i}].num`, ele.num || '');
      formData.append(`itemList[${i}].value`, ele.value || '');
      formData.append(`itemList[${i}].sort`, ele.sort);
      formData.append(`itemList[${i}].status`, ele.status);
    }
    // 照片上傳
    for (let i=0; i < formContent.photoList.length; i++) {
      let ele = formContent.photoList[i];
      formData.append(`photoList[${i}].id`, ele.id);
      formData.append(`photoList[${i}].projectFormId`, formContent.projectFormId);
      formData.append(`photoList[${i}].mainId`, ele.mainId);
      formData.append(`photoList[${i}].file`, ele.file);
      formData.append(`photoList[${i}].content`, ele.content || '');
      formData.append(`photoList[${i}].isiOS`, ele.isiOS);
      formData.append(`photoList[${i}].lastModifyDate`, ele.lastModifyDate);
      formData.append(`photoList[${i}].status`, ele.status);
    }
    try {
      let mainId = await FormAPI.ModifyEQForm(formData);
      notifySuccess({Msg: '儲存成功 (材料/設備品質抽驗紀錄表)', cbfunc: ''});
      setIsSaving(false);
      if (formContent.itemList.filter(ele => Number(ele.result) === 2).length > 0) {
        await handleAbnormalSave(mainId);
        setIsSaving(false);
      }
      if (!isSubmit) {
        // 儲存完關閉頁籤，並再打開
        let newFormList = await setFormTabListPromise(props.formTabList.filter(ele => ele._uuid !== props.uuid));
        let newFormContent = await GetEQFormInfo(mainId);
        let editForm = {
          id: newFormContent.id,
          _uuid: tools._UUID(),
          formName: newFormContent.formId,
          moduleId: 1, // 材料設備品質: 1
          formContent: newFormContent,
          abnormalForm: await GetAbnormalInfo({ projectId, abnormalId: newFormContent.abnormalId || 0 })
        };
        let data = await setFormTabListPromise([...newFormList, editForm]);
        openFormTab(data, editForm._uuid);
      }
    } catch (error) {
      // console.log(error.response.data);
      notifyFailed({Msg: '發生錯誤，表單儲存失敗 (材料/設備品質抽驗紀錄表)', isReload: false});
      if (error.response.data === "此表單編號已存在。") {notifyFailed({ Msg: error.response.data, isReload: false });}
      setIsSaving(false);
    }
  };

  const openFormTab = (list, uuid) => {
    let tabToOpen = list.find(ele => ele._uuid === uuid);
    if (tabToOpen) props.setFormTabSelected(tabToOpen);
  };

  const setFormTabListPromise = (formList) => {
    return new Promise(resolve => {
      props.setFormTabList(formList);
      resolve(formList);
    });
  };

  // 異常表單儲存
  const handleAbnormalSave = async(mainId) => {
    let formData = new FormData();
    formData.append(`id`, abnormalForm.id || 0);
    formData.append(`projectId`, projectId);
    formData.append(`projectFormId`, formContent.projectFormId);
    formData.append(`mainId`, mainId);
    formData.append(`docId`, abnormalForm.docId);
    formData.append(`vendor`, abnormalForm.vendor);
    formData.append(`ExamineDate`, abnormalForm.ExamineDate );
    formData.append(`improveDeadline`, abnormalForm.improveDeadline);
    formData.append(`flawNote`, abnormalForm.flawNote);
    formData.append(`formStatus`, abnormalForm.formStatus);
    formData.append(`status`, abnormalForm.formStatus === 2 ? 0 : abnormalForm.status);
    // 照片上傳
    for (let i=0; i < abnormalForm.photoList.length; i++) {
      let ele = abnormalForm.photoList[i];
      formData.append(`photoList[${i}].id`, ele.id);
      formData.append(`photoList[${i}].projectFormId`, formContent.projectFormId);
      formData.append(`photoList[${i}].mainId`, mainId);
      formData.append(`photoList[${i}].file`, ele.file);
      formData.append(`photoList[${i}].content`, ele.content || '');
      formData.append(`photoList[${i}].isiOS`, ele.isiOS);
      formData.append(`photoList[${i}].lastModifyDate`, ele.lastModifyDate);
      formData.append(`photoList[${i}].status`, ele.status);
    }
    try {
      await FormAPI.ModifyANForm(formData);
      notifySuccess({Msg: '儲存成功 (異常(不合格)通知暨改善表)', cbfunc: ''});
    } catch (error) {
      notifyFailed({Msg: '發生錯誤，表單儲存失敗 (異常(不合格)通知暨改善表)', isReload: false});
    }
  };

  const handleStampChange = (e) => {
    setFormContent({
      ...formContent,
      userStamp: formContent.userStampList.find(ele => ele.userStampID === Number(e.target.value)) || {}
    });
  };

  const handleSignOff = async (action) => {
    // 檢查網路狀態
    if (!window.navigator.onLine) {
      notifyFailed({Msg: '網路未連線', isReload: false});
      return;
    }

    // 檢查必填欄位
    let formValidate = FormValidate();
    if (!formValidate && action!=2) {
      notifyFailed({Msg: '請檢查「材料/設備品質抽驗紀錄表」是否有未填項目', isReload: false});
      return;
    }
    // 若有不合格抽驗項目，需將異常表單資料填完
    if (formContent.itemList.filter(ele => Number(ele.result) === 2).length > 0) {
      let abnormalValidate = AbnormalValidate();
      if (!abnormalValidate) {
        notifyFailed({Msg: '請檢查「異常(不合格)通知暨改善表」是否有未填項目', isReload: false});
        return;
      }
      // 若有異常表單，異常表單之formStatus需為2才能送出
      if (abnormalForm.formStatus !== 2) {
        notifyFailed({Msg: '請確認已完成「異常(不合格)通知暨改善表」流程', isReload: false});
        return;
      }
    }

    // 要送出簽核需有影像章
    if (!formContent.userStamp.userStampID) {
      if(action===1 || action===0){
        notifyFailed({Msg: '請選擇影像章', isReload: false});
        return;
      }
    }

    // 只有在帳號為填寫表單本人，且表單狀態為草稿時時，可以送出審核同時儲存表單
    if (formContent.signOffList[0].userName === userInfo.userName && formContent.formStatus === 0) {
      await handleSave({ isSubmit: true });
    }

    setIsSaving(true);
    let modifyFormSignOff = {
      id: formContent.signOffList.find(ele => ele.signOffTag === true).id,
      userStampID :formContent.userStamp.userStampID,
      action,
      module: 1
    };
    try {
      await FormAPI.PostSignOff(modifyFormSignOff);
      notifySuccess({Msg: '送出成功', cbfunc: ''});
      setIsSaving(false);
      await setFormTabListPromise(props.formTabList.filter(ele => ele._uuid !== props.uuid));
      // 開啟列表
      props.setIsListOn(true);
      GetProjectEQFormList();
      GetProjectEQFormNumList();
      props.GetModuleList({ isModuleId: true });
      props.GetProjectFormList();
    } catch (error) {
      notifyFailed({Msg: '發生錯誤，簽核送出失敗', isReload: false});
      setIsSaving(false);
    }
  };

  // 取得材料/設備品質抽驗紀錄表 清單(取得送出表單模組及表單類型，並顯示狀態為審核中)
  async function GetProjectEQFormList() {
    try {
      let data = await FormAPI.GetProjectEQFormList({ projectFormId: formContent.projectFormId, formStatus: 1, page: 1 });
      data.formMainEQSimpleList.forEach(ele => {
        ele._uuid = tools._UUID();
      });
      props.setProjectEQFormList(data.formMainEQSimpleList);
      props.setTotalPages(data.pageInfo.pages);
      props.setFormStatus(1);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }

  // 取得設備功能運轉測試表 各狀態筆數清單
  const GetProjectEQFormNumList = async () => {
    try {
      let data = await FormAPI.GetProjectEQFormNumList({ projectFormId: formContent.projectFormId });
      props.setFormStatusNum(data);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  };

  // 匯出PDF
  const ExportPDF = async() => {
    await CreateEQPdf();
    // await CreatePhotoPdf();
  };
  const CreateEQPdf = async() => {
    try {
      let data = await DocumentAPI.CreateEQPdf(formContent.id);
      tools.AjaxFileDownload({ rs: data, filename: `材料/設備品質抽驗紀錄表 - ${formContent.formId}`, fileType: 'pdf'});
    } catch (error) {
      notifyFailed({Msg: '發生錯誤，匯出文件失敗', isReload: false});
    }
  };

  // const CreatePhotoPdf = async() => {
  //   try {
  //     let data = await DocumentAPI.CreatePhotoPdf({ projectFormId: formContent.projectFormId ,mainId: formContent.id });
  //     tools.AjaxFileDownload({ rs: data, filename: `材料/設備品質抽驗紀錄表照片 - ${formContent.formId}`, fileType: 'pdf'});
  //   } catch (error) {
  //     notifyFailed({Msg: '發生錯誤，匯出文件失敗', isReload: false});
  //   }
  // };

  // 還原表單
  const handleRestoreForm = async() => {
    try {
      await FormAPI.RestoreForm({
        id: formContent.id,
        module: 1
      });
      notifySuccess({Msg: '表單還原成功', cbfunc: ''});
      // 還原後關閉頁籤
      props.setFormTabList(props.formTabList.filter(ele => ele._uuid !== props.uuid));
      props.setFormStatus(0);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤，表單還原失敗', isReload: false});
    }
  };


  return (
    <>
      <div className="sharebox">
        {
          formContent.formStatus === 3 && (<div className="btn_box"><button className="btnL btnBlue" onClick={handleRestoreForm}>還原此表單</button></div>)
        }
        {
          formContent.formStatus === 2 && userInfo.projectRoles.find(pr => pr.projectID === projectId)?.roleName === '管理者' && (
            <div className="btn_box"><a className="btnL btnBlue" href="#ReturnReason">退回重新審核</a></div>
          )
        }
        <FormTitle>材料/設備品質抽驗紀錄表</FormTitle>

        <div className="form form_loose" style={{ paddingBottom: '10px', }}>
          {/* 一行 */}
          <div className="form_half">
            <div className="form_title">
              表單編號：
            </div>
            <div className="form_input">
              {
                formContent.editable ?
                  <input type="text" name="formId" value={formContent.formId} onChange={handleInputChange}/> :
                  <p>{formContent.formId}</p>
              }
            </div>
          </div>
          <div className="form_half">
            <div className="form_title">
              工程名稱：
            </div>
            <div className="form_input">
              <p>{formContent.projectName}</p>
            </div>
          </div>
          {/* 一行 */}
          <div className="form_half">
            <div className="form_title">
              材料 / 設備名稱：
            </div>
            <div className="form_input">
              {
                formContent.editable ?
                  <input type="text" name="eqipName" value={formContent.eqipName} onChange={handleInputChange}/> :
                  <p>{formContent.eqipName}</p>
              }
            </div>
          </div>
          <div className="form_half">
            <div className="form_title">
              檢查日期：
            </div>
            <div className="form_input">
              {
                formContent.editable ?
                  <DateInput type="date" name="examineDate" value={formContent.examineDate} onChange={handleInputChange}/> :
                  <p>{formContent.examineDate ? moment(formContent.examineDate).format('YYYY-MM-DD') : ''}</p>
              }
            </div>
          </div>
          {/* 一行 */}
          <div className="form_13quarters">
            {
              formContent.multipleChoiceList.filter(el => el.id === 2).map(ele => (
                <div key={ele.id}>
                  <div className="form_title">
                    {ele.topicName}：
                  </div>
                  <div className="form_input">
                    {
                      ele.optionList.map(ele1 => (
                        <label key={ele1.id}>
                          <input type="checkbox" name={ele1.optionName} checked={ele1.isSelect} onChange={(evt) => handleCheckChange(evt, ele1)} disabled={!formContent.editable}/>
                          {ele1.optionName} {ele1.hasContent && (<input type="text" value={ele1.content} name="content" onChange={(evt) => handleOtherContentChange(evt, ele1)} disabled={(!formContent.editable || !ele1.isSelect)}/>)}
                        </label>
                      ))
                    }
                  </div>
                </div>
              ))
            }
            {/* <div className="form_title">
              應提出證明文件：
            </div> */}
            {/* <div className="form_input">
              <label>
                <input type="checkbox" name="" />
                  出廠證明書
              </label>
              <label>
                <input type="checkbox" name="" />
                  性能曲線圖
              </label>
              <label>
                <input type="checkbox" name="" />
                  其他：<input type="text" name="" />
              </label>
            </div> */}
          </div>
          {/* 一行 */}
          <div className="form_13quarters">
            <div className="form_title">
              地點：
            </div>
            <div className="form_input">
              {
                formContent.editable ?
                  <input type="text" name="location" value={formContent.location} onChange={handleInputChange}/> :
                  <p>{formContent.location}</p>
              }
            </div>
          </div>
          {/* 一行 */}
          <div className="form_13quarters">
            <div className="form_title">
              圖說：
            </div>
            <div className="form_input">
              <VersionSelect value={formContent.ekphrasisID} onChange={handleEkphrasisChange} disabled={!formContent.editable}>
                <option value=''>請選擇圖說</option>
                {
                  formContent.ekphrasisList.map(ele => (
                    <optgroup key={ele.id} label={ele.categoryName}>
                      {
                        ele.ekphrasisList.map(ele1 => (
                          <option key={ele1.id} value={ele1.id}>{ele1.ekphrasisName}</option>
                        ))
                      }
                    </optgroup>
                  ))
                }
              </VersionSelect>
              {
                // 有選擇圖說才能查看
                (formContent.ekphrasisID > 0) && <BtnToViewDraw className="btnS btnOrange" onClick={handleOpenEkphrasis}>點擊查看圖說</BtnToViewDraw>
              }
            </div>
            <div id="popup" style={LightBox_style}>
              <div id="popupContent">
                <div className="popupCancelBtn">
                  <button className="btnL btnRed" onClick={() => setisLightBoxOpen(prev => !prev)}>
                    <i className="fas fa-times"></i>
                  </button>
                </div>
                <iframe width="100%" height="" className="pdfIframe" ref={pdfRef}></iframe>
              </div>
            </div>
          </div>
          {/* 一行 */}
          <div className="form_13quarters">
            {
              formContent.multipleChoiceList.filter(el => el.id === 3).map(ele => (
                <div key={ele.id}>
                  <div className="form_title">
                    {ele.topicName}：
                  </div>
                  <div className="form_input">
                    {
                      ele.optionList.map(ele1 => (
                        <label key={ele1.id}>
                          <input type="checkbox" name={ele1.optionName} checked={ele1.isSelect} onChange={(evt) => handleCheckChange(evt, ele1)} disabled={!formContent.editable}/>
                          {ele1.optionName} {ele1.hasContent && (<input type="text" value={ele1.content} name="content" onChange={(evt) => handleOtherContentChange(evt, ele1)} disabled={(!formContent.editable || !ele1.isSelect)}/>)}
                        </label>
                      ))
                    }
                  </div>
                </div>
              ))
            }
            {/* <div className="form_title">
              查驗方式：
            </div> */}
            {/* <div className="form_input">
              <label>
                <input type="checkbox" name="" />
                  工廠廠內檢驗
              </label>
              <label>
                <input type="checkbox" name="" />
                  型錄樣品檢驗
              </label>
              <label>
                <input type="checkbox" name="" />
                  工地現場查驗
              </label>
              <label>
                <input type="checkbox" name="" />
                  材料實驗室檢驗
              </label>
              <label>
                <input type="checkbox" name="" />
                  書面審核
              </label>
              <label>
                <input type="checkbox" name="" />
                  其他：<input type="text" name="" />
              </label>
            </div> */}
          </div>
        </div>

        {/* <FakeHr /> */}

        <TableBox>
          <div className="table">
            <table>
              <tbody>
                <tr>
                  <td>抽驗項目</td>
                  <td>抽驗標準</td>
                  <td>抽驗數量</td>
                  <td>抽驗值</td>
                  <ResultTd>抽驗<MobileBr />結果</ResultTd>
                </tr>
                {
                  formContent.itemList.map((ele, idx) => (
                    <tr key={ele.id}>
                      <td>
                        {ele.itemName}
                      </td>
                      <td>
                        {
                          formContent.editable ?
                            <SpotCheckDescTextarea
                              name="criterion"
                              id=""
                              cols=""
                              rows="6"
                              value={ele.criterion}
                              onChange={(evt) => handleItemInputChange(evt, ele, idx)}
                              ref={(el) => (criterionRef.current[idx] = el)}
                              onFocus={(evt) => getCursorPos('criterion', ele, idx, evt)}
                            ></SpotCheckDescTextarea> :
                            <>{ele.criterion || ''}</>
                        }
                        {/* {ele.criterion || ''} */}
                      </td>
                      <td>
                        {
                          formContent.editable ?
                            <SpotCheckDescTextarea
                              name="num"
                              id=""
                              cols=""
                              rows="6"
                              value={ele.num}
                              onChange={(evt) => handleItemInputChange(evt, ele, idx)}
                              ref={(el) => (numRef.current[idx] = el)}
                              onFocus={(evt) => getCursorPos('num', ele, idx, evt)}
                            ></SpotCheckDescTextarea> :
                            <>{ele.num}</>
                        }
                      </td>
                      <td>
                        {
                          formContent.editable ?
                            <SpotCheckDescTextarea
                              name="value"
                              id=""
                              cols=""
                              rows="6"
                              value={ele.value}
                              onChange={(evt) => handleItemInputChange(evt, ele, idx)}
                              ref={(el) => (valueRef.current[idx] = el)}
                              onFocus={(evt) => getCursorPos('value', ele, idx, evt)}
                            ></SpotCheckDescTextarea> :
                            <>{ele.value}</>
                        }
                      </td>
                      <td style={{ textAlign:'left', }}>
                        <label><input type="radio" name={"result"+formContent._uuid+ele.id} value={1} checked={Number(ele.result) === 1} onChange={(evt) => handleItemCheckChange(evt, ele)} disabled={!formContent.editable}/> 合格</label> <br />
                        <label><input type="radio" name={"result"+formContent._uuid+ele.id} value={2} checked={Number(ele.result) === 2} onChange={(evt) => handleItemCheckChange(evt, ele)} disabled={!formContent.editable}/> 不合格</label> <br/>
                        <label><input type="radio" name={"result"+formContent._uuid+ele.id} value={0} checked={Number(ele.result) === 0} onChange={(evt) => handleItemCheckChange(evt, ele)} disabled={!formContent.editable}/> 無此檢查項目</label> <br/>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </TableBox>
        {
          formContent.editable && (
            <div>
              <Keyboard>
                {
                  keyboardSignList.map((ele, idx) => (
                    <button key={idx} className="btnWhite" onClick={(evt) => InsertMark(evt, editingCell.row, editingCell.col)} value={ele}>{ele}</button>
                  ))
                }
              </Keyboard>
            </div>
          )
        }

        <FakeHr />

        <div className="uploadfiles">
          {
            formContent.editable && (<button className="btnL btnWhite" style={{ margin: '0 10px', }} onClick={handleCreatePhoto}>新增 材料／設備品質抽驗紀錄照片</button>)
          }
          <br />
          {
            formContent.photoList.filter(el => el.status !== 3).map(ele => (
              <PictureUpload key={ele._uuid} img={ele} setImg={handleImgUpload} deleteImg={handleImgDelete} editable={formContent.editable}/>
            ))
          }
        </div>

        <FakeHr />
        {/* 異常component */}
        <AttachErrorNotify formContent={formContent} abnormalForm={abnormalForm} setAbnormalForm={setAbnormalForm}/>

        <FakeHr />
        {
          ((formContent.signOffList && formContent.signOffList.length > 0 && formContent.signOffList[0].userName === userInfo.userName && formContent.editable)
            || formContent.status === 1)
            && (
              <div className="btn_box">
                <button className="btnL btnBlue" onClick={() => handleSave({ isSubmit: false })} disabled={isSaving}>
                  {
                    isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '儲存表單'
                  }
                </button>
              </div>
            )
        }

        <FakeHr />

        {
          props.formContent.id > 0 ?
            (
              <>
                <FormTitle>表單簽核</FormTitle>

                <TableBox>
                  <div className="signArea">
                    {
                      formContent.signOffList.map(ele => (
                        <div className="approver" key={tools._UUID()}>
                          <div className="approverTitle">
                            <div className="approverTitleInner">
                              <p>{ele.jobTitle} - {ele.name_zhtw}</p>
                              {
                                userInfo.userName === ele.userName && formContent.userStampList && ele.signOffTag ?
                                  (
                                    <SignetSelect onChange={handleStampChange} value={formContent.userStamp.userStampID}>
                                      <option value=''>選擇影像章</option>
                                      {
                                        formContent.userStampList.map(ele1 => (
                                          <option value={ele1.userStampID} key={ele1.userStampID}>{ele1.stampName}</option>
                                        ))
                                      }
                                    </SignetSelect>
                                  ) :
                                  null
                              }
                            </div>
                          </div>
                          <div className="approverSign">
                            {
                              ele.sign ? <img src={ele.sign} key={ele.id}/> : (
                                userInfo.userName === ele.userName && formContent.userStampList && ele.signOffTag ? (formContent.userStamp.userStampID ? <img src={formContent.userStamp.stampUrl} key={formContent.userStamp.userStampID}/> : <div>請選擇影像章</div>) :
                                  <div>尚未簽核</div>
                              )
                            }
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </TableBox>
                <div className="btn_box">
                  {/* 當有影像章列表(表示目前流程到此帳號)才可以編輯及送出簽核 */}
                  {
                    (formContent.signOffList.findIndex(ele => ele.signOffTag) === 0 && formContent.userStampList) && (
                      <button className="btnL btnBlue" onClick={() => handleSignOff(1)} disabled={isSaving}>
                        {
                          isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '儲存並送出審核'
                        }
                      </button>
                    )
                  }
                  {
                    (formContent.signOffList.findIndex(ele => ele.signOffTag) !== 0 &&
                      formContent.signOffList.findIndex(ele => ele.signOffTag) !== formContent.signOffList.length-1 &&
                      formContent.userStampList)&& (
                      <>
                        <button className="btnL btnBlue" onClick={() => handleSignOff(1)} disabled={isSaving}>
                          {
                            isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '核准'
                          }
                        </button>
                        <button className="btnL btnRed" onClick={() => handleSignOff(2)} disabled={isSaving}>
                          {
                            isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '退回'
                          }
                        </button>
                      </>
                    )
                  }
                  {
                    (formContent.signOffList.findIndex(ele => ele.signOffTag) === formContent.signOffList.length-1 && formContent.userStampList) && (
                      <>
                        <button className="btnL btnOrange" onClick={() => handleSignOff(0)} disabled={isSaving}>
                          {
                            isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '決行'
                          }
                        </button>
                        <button className="btnL btnRed" onClick={() => handleSignOff(2)} disabled={isSaving}>
                          {
                            isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '退回'
                          }
                        </button>
                      </>
                    )
                  }
                </div>
              </>
            ) : null
        }
        {
          formContent.formStatus === 2 && (<div className="btn_box"><button className="btnL btnBlue" onClick={ExportPDF}>匯出PDF</button></div>)
        }
        {
          <>
            <FormTitle>歷程紀錄</FormTitle> <br />

            <HistoricalRecord>
              {formContent.historyRecordList.map(ele =>(
                <p key={tools._UUID()}>
                  <span> {moment(ele.createDate).format('YYYY-MM-DD HH:mm:ss') } </span>由<span> {ele.createID} </span>退件，理由：<span> {ele.note} </span>
                </p>
              ))}
            </HistoricalRecord>
          </>
        }

        {
          formContent.formStatus === 2 && userInfo.projectRoles.find(pr => pr.projectID === projectId)?.roleName === '管理者' && (
            <>
              <FakeHr />

              <InputReturnReason>
                <a name="ReturnReason" id="ReturnReason"></a>
                <p>請輸入退回理由</p>
                <textarea name="returnNote" id="" cols="" rows="6" onChange={handleInputChange}></textarea>

                <div className="btnbox">
                  <div className="btn_box"><button className="btnL btnBlue" onClick={handleReturnSignOff}>確認退回</button></div>
                </div>
              </InputReturnReason>
            </>
          )
        }

      </div>
    </>
  );

};
export default QualityCheckRecord;