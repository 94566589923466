// info from https://github.com/d3/d3-scale-chromatic
// ^interpolate: continous (given number in [0, 1])
// ^scheme: discrete (given number from 3 to 11)
const colorSchemes = {
  categorical: {
    schemeCategory10: "schemeCategory10",
    schemeAccent: "schemeAccent",
    schemeDark2: "schemeDark2",
    schemePaired: "schemePaired",
    schemePastel1: "schemePastel1",
    schemePastel2: "schemePastel2",
    schemeSet1: "schemeSet1",
    schemeSet2: "schemeSet2",
    schemeSet3: "schemeSet3",
    schemeTableau10: "schemeTableau10",
  },
  diverging: {
    interpolateBrBG: "interpolateBrBG",
    schemeBrBG: "schemeBrBG",
    interpolatePRGn: "interpolatePRGn",
    schemePRGn: "schemePRGn",
    interpolatePiYG: "interpolatePiYG",
    schemePiYG: "schemePiYG",
    interpolatePuOr: "interpolatePuOr",
    schemePuOr: "schemePuOr",
    interpolateRdBu: "interpolateRdBu",
    schemeRdBu: "schemeRdBu",
    interpolateRdGy: "interpolateRdGy",
    schemeRdGy: "schemeRdGy",
    interpolateRdYlBu: "interpolateRdYlBu",
    schemeRdYlBu: "schemeRdYlBu",
    interpolateRdYlGn: "interpolateRdYlGn",
    schemeRdYlGn: "schemeRdYlGn",
    interpolateSpectral: "interpolateSpectral",
    schemeSpectral: "schemeSpectral",
  },
  sequentialMulti: {
    interpolateBuGn: "interpolateBuGn",
    schemeBuGn: "schemeBuGn",
    interpolateBuPu: "interpolateBuPu",
    schemeBuPu: "schemeBuPu",
    interpolateGnBu: "interpolateGnBu",
    schemeGnBu: "schemeGnBu",
    interpolateOrRd: "interpolateOrRd",
    schemeOrRd: "schemeOrRd",
    interpolatePuBuGn: "interpolatePuBuGn",
    schemePuBuGn: "schemePuBuGn",
    interpolatePuBu: "interpolatePuBu",
    schemePuBu: "schemePuBu",
    interpolatePuRd: "interpolatePuRd",
    schemePuRd: "schemePuRd",
    interpolateRdPu: "interpolateRdPu",
    schemeRdPu: "schemeRdPu",
    interpolateYlGnBu: "interpolateYlGnBu",
    schemeYlGnBu: "schemeYlGnBu",
    interpolateYlGn: "interpolateYlGn",
    schemeYlGn: "schemeYlGn",
    interpolateYlOrBr: "interpolateYlOrBr",
    schemeYlOrBr: "schemeYlOrBr",
    interpolateYlOrRd: "interpolateYlOrRd",
    schemeYlOrRd: "schemeYlOrRd",
    interpolateCividis: "interpolateCividis",
    interpolateCubehelixDefault: "interpolateCubehelixDefault",
    interpolateRainbow: "interpolateRainbow",
    interpolateWarm: "interpolateWarm",
    interpolateCool: "interpolateCool",
    interpolateSinebow: "interpolateSinebow",
    interpolateTurbo: "interpolateTurbo",
    interpolateViridis: "interpolateViridis",
    interpolateMagma: "interpolateMagma",
    interpolateInferno: "interpolateInferno",
    interpolatePlasma: "interpolatePlasma",
  },
  sequentialSingle: {
    interpolateBlues: "interpolateBlues",
    schemeBlues: "schemeBlues",
    interpolateGreens: "interpolateGreens",
    schemeGreens: "schemeGreens",
    interpolateGreys: "interpolateGreys",
    schemeGreys: "schemeGreys",
    interpolatePurples: "interpolatePurples",
    schemePurples: "schemePurples",
    interpolateReds: "interpolateReds",
    schemeReds: "schemeReds",
    interpolateOranges: "interpolateOranges",
    schemeOranges: "schemeOranges",
  }
};

export default colorSchemes;