
import FormList from './FormList';
import FormDetail from './FormDetail';
import FormTest from './FormTest';
import ProjectMaterialManage from './ProjectMaterialManage';
import FormItemManage from './FormItemManage';
import DrawingManage from './DrawingManage';
import ApprovalProceduresManage from './ApprovalProceduresManage';
import ImplementsManage from './ImplementsManage';
import { Route, useRouteMatch } from 'react-router-dom';

const Project = () => {

  let { path } = useRouteMatch();

  return (
    <>
      <Route path={`${path}/form-list/:moduleId?/:mainId?`} component={FormList}></Route>
      <Route path={`${path}/form-detail`} component={FormDetail}></Route>
      <Route path={`${path}/form-test`} component={FormTest}></Route>
      <Route path={`${path}/project-material-manage/:moduleId?/:projectFormId?`} component={ProjectMaterialManage}></Route>
      <Route path={`${path}/form-item-manage`} component={FormItemManage}></Route>
      <div className="main">
        <Route path={`${path}/drawing-manage`} component={DrawingManage}></Route>
        <Route path={`${path}/approval-procedures-manage`} component={ApprovalProceduresManage}></Route>
        <Route path={`${path}/implements-manage`} component={ImplementsManage}></Route>
      </div>
    </>
  );

};
export default Project;