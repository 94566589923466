import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useLoginStateContext } from './LoginStateProvider';
// import Auth from './../Auth';
import Skeleton from 'react-loading-skeleton';

const PrivateRoute = ({component: Component, ...rest}) => {
  const loginStateContext = useLoginStateContext();
  if (loginStateContext.isLoading) {
    return <Skeleton />;
  } else {
    if (loginStateContext.loginState) {
      return <Route {...rest} component={Component} />;
    } else {
      return <Redirect to="/login-page" />;
    }
    // const finalComponent = loginStateContext.loginState ? Component : Auth;
    // return (
    //   <Route {...rest} component={finalComponent} />
    // );
  }
};

export default PrivateRoute;