import React, { useContext, useEffect, useState } from "react";
import styled from '@emotion/styled';
import "./../../style/Form.scss";
import "./../../style/FormDetail.scss";
import { notifyFailed, notifySuccess, notifyWarning } from './../assets/toaster';
import { AccountAPI, FormAPI, DocumentAPI } from "../assets/api";

import PictureUpload from './../components/PictureUpload';
import tools from './../assets/tools';
import moment from "moment";

import { ProjectContext } from './../contexts/ProjectContext';

const FormTitle = styled.h1`
    font-size: 1.4rem;
    font-weight: 700;
    margin: 8px;
  `;

const VersionSelect = styled.select`
  border: 1px solid #999;
  border-radius: 3px;
  padding: 3px;
  margin: 0 3px 3px 3px;
  height: 31px;
  option{
    padding: 2px;
  }
  @media (min-width: 450px) {
    min-width: 12em;
    max-width: 100%;
  }
  @media (max-width: 450px) {
    width: 100%;
  }
`;

const DateInput = styled.input`
    padding: 0 4px;
    box-sizing: border-box;
    height: 32px;
    border-radius: 3px;
    border: 1px solid #999;
    // min-width: 5em;
    max-width: 18em;
    margin: 0 3px;
    @media (max-width: 450px) {
      width: 100%;
    }
  `;

const FakeHr = styled.div`
    width: 92%;
    height: 2px;
    background-color: #DDD;
    margin: 40px 4% 40px 4%;
  `;

const TableBox = styled.div`
    padding: 0 10px;
  `;

const FaultDescTextarea = styled.textarea`
    border: 1px solid #999;
    width: 100%;
    max-width: 1064px;
  `;

const SignetSelect = styled.select`
    border: 1px solid #999;
    border-radius: 3px;
    padding: 3px;
    margin: 0 3px;
    height: 30px;
    max-width: 87%;
    option{
      padding: 2px;
    }
  `;

const HistoricalRecord = styled.div`
  background-color: #EEE;
  padding: 10px 15px;
  margin: 10px;
`;

const InputReturnReason = styled.div`
  background-color: #EEE;
  padding: 20px ;
  margin: 10px;
  textarea{
    margin: 10px 0;
    width: 100%;
    border: 1px solid #BBB;
  }
  .btnbox{
    text-align: right;
    margin: 10px 0 0 0;
  }

`;

const ReplyFile = styled.a`
  text-decoration: none;
  color: #007da3;
`;



// 異常不合格通知改善表
const ErrorNotifyAndImprove = (props) => {
  // let projectId = 1; //TODO:動態取得projectId
  const { projectInfo } = useContext(ProjectContext);
  let projectId = projectInfo.id;

  const [isSaving, setIsSaving] = useState(false);
  const [isPDFExporting, setIsPDFExporting] = useState(false);


  const [abnormalForm, setAbnormalForm] = useState({
    ...props.abnormalForm,
    // projectFormId: formContent.projectFormId,
    // mainId:  props.formContent.id > 0 ? props.formContent.id : 0,
    docId: props.abnormalForm.docId || '',
    vendor: props.abnormalForm.vendor || '',
    ExamineDate: props.abnormalForm.examineDate ? moment(props.abnormalForm.examineDate).format('YYYY-MM-DD') : '',
    improveDeadline: props.abnormalForm.improveDeadline ? moment(props.abnormalForm.improveDeadline).format('YYYY-MM-DD') : '',
    flawNote: props.abnormalForm.flawNote || '',
    status: props.abnormalForm.id > 0 ? 2 : 1,
    photoList: props.abnormalForm.id > 0 ? props.abnormalForm.photoList.map(ele => ({...ele, _uuid: tools._UUID(), status: 2, isExist: true})) : [],
    userStamp: props.abnormalForm.id > 0 ? {} : {},
    editable: props.abnormalForm.id > 0 ? props.abnormalForm.formStatus === 0 : true,
  });

  const [abnormalTrack, setAbnormalTrack] = useState({
    id: props.abnormalForm.id,
    caseStatus: props.abnormalForm.caseStatus,
    abnormalCategoryID: props.abnormalForm.abnormalCategoryID, //0:列管中,1:已完成
    caseNote: props.abnormalForm.caseNote,
    improveDate: props.abnormalForm.improveDate ? moment(props.abnormalForm.improveDate).format('YYYY-MM-DD') : '',
    caseResponseFileUrl: props.abnormalForm.caseResponseFileUrl,
    file: null,
    status: 2
  });
  const [abnormalCategoryList, setAbnormalCategoryList] = useState([]);
  const [otherCategory, setOtherCategory] = useState('');
  const [projectFormList, setProjectFormList] = useState([]);

  function Photo ({_uuid=0, id=0, projectFormId, mainId, content, status=0}) {
    this._uuid = _uuid;
    this.id = id;
    this.projectFormId = projectFormId;
    this.mainId = mainId;
    this.file = null;
    this.photoUrl = null;
    this.content = content;
    this.status = status;
  }

  const handleProjectFormIdChange = (e) => {
    setAbnormalForm({
      ...abnormalForm,
      projectFormId: Number(e.target.value)
    });
  };

  const handleAbnormalFormInputChange = (e) => {
    setAbnormalForm({
      ...abnormalForm,
      [e.target.name]: e.target.value
    });
  };

  // 異常照片新增
  const handleCreateAbnormalPhoto = () => {
    setAbnormalForm({
      ...abnormalForm,
      photoList: [...abnormalForm.photoList, new Photo({ _uuid: tools._UUID(), id: 0, projectFormId: null, mainId: null, content: '', status: 1})]
    });
  };

  // 異常照片上傳
  const handleAbnormalImgUpload = (el) => {
    setAbnormalForm({
      ...abnormalForm,
      photoList: abnormalForm.photoList.map(ele => (ele._uuid === el._uuid ? el : ele))
    });
  };

  // 異常照片刪除
  const handleAbnormalImgDelete = (el) => {
    if (el.isExist) {
      setAbnormalForm({
        ...abnormalForm,
        photoList: abnormalForm.photoList.map(ele => (ele._uuid === el._uuid ? {...el, status: 3} : ele))
      });
    } else {
      setAbnormalForm({
        ...abnormalForm,
        photoList: abnormalForm.photoList.filter(ele => ele._uuid !== el._uuid)
      });
    }
  };

  const handleStampChange = (e) => {
    setAbnormalForm({
      ...abnormalForm,
      userStamp: abnormalForm.userStampList.find(ele => ele.userStampID === Number(e.target.value)) || {}
    });
  };
  const handleReturnSignOff = async () => {
    notifyWarning({ Msg: <ReturnConfirm /> });
  };

  const ReturnConfirm = ({ closeToast }) => (
    <div>
      確定退回表單？若有【異常(不合格)通知暨改善表】將會同時退回，並刪除【異常追蹤】內容
      <button className="btnS btnRed" onClick={() => ReturnSignOff()}>確定退回</button>
      <button className="btnS btnWhite" onClick={closeToast}>取消</button>
    </div>
  );
  // 退回表單
  const ReturnSignOff = async () => {
    if (!abnormalForm.returnNote) {
      notifyFailed({ Msg: '請確認是否有填寫退回理由。', isReload: false });
      return;
    }
    setIsSaving(true);
    ReturnSignOffCMS();
  };
  const ReturnSignOffCMS = async () =>{
    try{
      // 同時退回異常表單
      await FormAPI.ReturnSignOff({
        projectId: projectId,
        moduleId: 5,
        formId: abnormalForm.projectFormId || 0,
        mainId: abnormalForm.id,
        note: abnormalForm.returnNote || '',
        action: 3,
      });
      // 退回異常同時刪除異常追蹤
      let formData = new FormData();
      formData.append(`id`, abnormalForm.id);
      formData.append(`status`, 3);
      await FormAPI.ModifyAbnormalTrack({ projectId, modifyAbnormalTrack: formData });
      notifySuccess({ Msg: '表單(包含異常表單)退回成功', cbfunc: '' });
      setIsSaving(false);

      // 退回後關閉頁籤
      props.setFormTabList(props.formTabList.filter(ele => ele._uuid !== props.uuid));
      props.setFormStatus(0);
    } catch (error){
      setIsSaving(false);
      notifyFailed({ Msg: '主要查驗表格已在結案狀態，表單無法退回，請至查驗表單退回表單。', isReload: false });
    }
  };
  // 檢查異常表單欄位必填
  const AbnormalValidate = () => {
    let isValid = true;
    if (!abnormalForm.docId ||
      !abnormalForm.ExamineDate ||
      !abnormalForm.vendor ||
      !abnormalForm.improveDeadline ||
      !abnormalForm.flawNote ||
      !abnormalForm.projectFormId ||
      abnormalForm.photoList.filter(ele => ele.status !== 3).length === 0
    ) {
      isValid = false;
    }

    abnormalForm.photoList.filter(el => el.status !== 3).forEach(ele => {
      if (!ele.content) {
        isValid = false;
        return isValid;
      }
      if (!ele.content.trim()) {
        isValid = false;
      }
    });
    return isValid;
  };

  const handleSave = async({ isSubmit }) => {
    // 檢查網路狀態
    if (!window.navigator.onLine) {
      notifyFailed({Msg: '網路未連線', isReload: false});
      return;
    }

    setIsSaving(true);
    let formData = new FormData();
    formData.append(`id`, abnormalForm.id || 0);
    formData.append(`projectId`, projectId);
    formData.append(`projectFormId`, abnormalForm.projectFormId || '');
    formData.append(`docId`, abnormalForm.docId);
    formData.append(`vendor`, abnormalForm.vendor);
    formData.append(`ExamineDate`, abnormalForm.ExamineDate );
    formData.append(`improveDeadline`, abnormalForm.improveDeadline);
    formData.append(`flawNote`, abnormalForm.flawNote);
    formData.append(`formStatus`, abnormalForm.formStatus);
    formData.append(`status`, abnormalForm.formStatus === 2 ? 0 : abnormalForm.status);
    // 照片上傳
    for (let i=0; i < abnormalForm.photoList.length; i++) {
      let ele = abnormalForm.photoList[i];
      formData.append(`photoList[${i}].id`, ele.id);
      formData.append(`photoList[${i}].file`, ele.file);
      formData.append(`photoList[${i}].content`, ele.content || '');
      formData.append(`photoList[${i}].isiOS`, ele.isiOS);
      formData.append(`photoList[${i}].lastModifyDate`, ele.lastModifyDate);
      formData.append(`photoList[${i}].status`, ele.status);
    }
    try {
      let abnormalId = await FormAPI.ModifyANForm(formData);
      notifySuccess({Msg: '儲存成功 (異常(不合格)通知暨改善表)', cbfunc: ''});
      setIsSaving(false);
      if (!isSubmit) {
        // 儲存完關閉頁籤，並再打開
        let newFormList = await setFormTabListPromise(props.formTabList.filter(ele => ele._uuid !== props.uuid));
        let newAbnormalForm = await GetAbnormalInfo({ projectId, abnormalId: abnormalId || 0 });
        let editForm = {
          id: newAbnormalForm.id,
          _uuid: tools._UUID(),
          formName: newAbnormalForm.docId,
          moduleId: 5, // 異常: 5
          abnormalForm: newAbnormalForm
        };
        let data = await setFormTabListPromise([...newFormList, editForm]);
        openFormTab(data, editForm._uuid);
      }
    } catch (error) {
      notifyFailed({Msg: '發生錯誤，表單儲存失敗 (異常(不合格)通知暨改善表)', isReload: false});
      if (error.response.data === "此表單編號已存在。") {notifyFailed({ Msg: error.response.data, isReload: false });}
      setIsSaving(false);
    }
  };

  const openFormTab = (list, uuid) => {
    let tabToOpen = list.find(ele => ele._uuid === uuid);
    if (tabToOpen) props.setFormTabSelected(tabToOpen);
  };

  const setFormTabListPromise = (formList) => {
    return new Promise(resolve => {
      props.setFormTabList(formList);
      resolve(formList);
    });
  };

  // 取得異常表資料
  async function GetAbnormalInfo({projectId, abnormalId}) {
    try {
      let data = await FormAPI.GetAbnormalInfo({projectId, abnormalId});
      return data;
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }

  const handleSignOff = async (action) => {
    // 檢查網路狀態
    if (!window.navigator.onLine) {
      notifyFailed({Msg: '網路未連線', isReload: false});
      return;
    }

    // 異常表單資料欄位必填
    let abnormalValidate = AbnormalValidate();
    if (!abnormalValidate && action!==2) {
      notifyFailed({Msg: '請檢查「異常(不合格)通知暨改善表」是否有未填項目', isReload: false});
      return;
    }

    // 要送出簽核需有影像章
    if (!abnormalForm.userStamp.userStampID) {
      if(action===1 || action===0){
        notifyFailed({Msg: '請選擇影像章', isReload: false});
        return;
      }
    }

    // 只有在帳號為填寫表單本人，且表單狀態為草稿時時，可以送出審核同時儲存表單
    if (abnormalForm.signOffList[0].userName === userInfo.userName && abnormalForm.formStatus === 0) {
      await handleSave({ isSubmit: true });
    }

    setIsSaving(true);
    let modifyFormSignOff = {
      id: abnormalForm.signOffList.find(ele => ele.signOffTag === true).id,
      userStampID :abnormalForm.userStamp.userStampID,
      action,
      module: 5
    };
    try {
      await FormAPI.PostSignOff(modifyFormSignOff);
      notifySuccess({Msg: '送出成功', cbfunc: ''});
      setIsSaving(false);
      await setFormTabListPromise(props.formTabList.filter(ele => ele._uuid !== props.uuid));
      // 開啟列表
      props.setIsListOn(true);
      props.setModuleSelected({
        id: 5,
        moduleName: '異常(不合格)通知暨改善表'
      });
      PostAbnormalList(1);
      PostAbnormalNumList(1);
      props.GetModuleList({ isModuleId: true });
      props.GetProjectFormList();
    } catch (error) {
      notifyFailed({Msg: '發生錯誤，簽核送出失敗', isReload: false});
      setIsSaving(false);
    }
  };

  // 取得異常表 清單
  async function PostAbnormalList(formStatus) {
    try {
      let data = await FormAPI.PostAbnormalList({
        projectId: projectId,
        docId: '',
        vendor: '',
        caseStatus: '',
        formStatus,
        noticeSDate: null,
        noticeEDate: null,
        improveSDeadline: null,
        improveEDeadline: null,
        improveSDate: null,
        improveEDate: null,
        page: 1,
      });
      data.abnormalList.forEach(ele => {
        ele._uuid = tools._UUID();
      });
      props.setAbnormalList(data.abnormalList);
      props.setTotalPages(data.pageInfo.pages);
      props.setFormStatus(1);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }

  // 取得異常表 各狀態筆數清單
  const PostAbnormalNumList = async (formStatus) => {
    try {
      let data = await FormAPI.PostAbnormalNumList({
        projectId: projectId,
        docId: "",
        vendor: "",
        caseStatus: '',
        formStatus,
        noticeSDate: null,
        noticeEDate: null,
        improveSDeadline: null,
        improveEDeadline: null,
        improveSDate: null,
        improveEDate: null,
        page: 1
      });
      props.setFormStatusNum(data);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  };

  // user info to check sigon off process
  const [userInfo, setUserInfo] = useState({
    'userName': '',
    'name_ZHTW': '',
    'name_EN': '',
    'email': '',
    'phoneNumber': '',
    'projectRoles': []
  });

  async function GetUserInfo() {
    try {
      let data = await AccountAPI.GetUserInfo();
      setUserInfo(data);
    } catch (error) {
      notifyFailed({Msg: '無法取得使用者資訊', isReload: false});
    }
  }

  useEffect(async () => {
    await GetUserInfo();
    await GetAbnormalCategoryList();
    await GetProjectFormListForAN(projectId);
  }, []);

  async function GetAbnormalCategoryList() {
    try {
      let data = await FormAPI.GetAbnormalCategoryList({ projectId });
      setAbnormalCategoryList(data);
    } catch (error) {
      notifyFailed({Msg: '無法取得異常追蹤樣態', isReload: false});
    }
  }

  async function GetProjectFormListForAN(projectId) {
    try {
      let data = await FormAPI.GetProjectFormListForAN(projectId);
      setProjectFormList(data);
    } catch (error) {
      notifyFailed({Msg: '無法取得表單列表', isReload: false});
    }
  }

  // 還原表單
  const handleRestoreForm = async() => {
    try {
      await FormAPI.RestoreForm({
        id: abnormalForm.id,
        module: 5
      });
      notifySuccess({Msg: '表單還原成功', cbfunc: ''});
      // 還原後關閉頁籤
      props.setFormTabList(props.formTabList.filter(ele => ele._uuid !== props.uuid));
      props.setFormStatus(0);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤，表單還原失敗', isReload: false});
    }
  };

  const handleTrackCaseStatusChange = (e) => {
    setAbnormalTrack({
      ...abnormalTrack,
      caseStatus: Number(e.target.value)
    });
  };

  const handleAbnormalCategoryChange = (e) => {
    setAbnormalTrack({
      ...abnormalTrack,
      abnormalCategoryID: Number(e.target.value)
    });
  };

  const handleOtherCategoryChange = (e) => {
    setOtherCategory(e.target.value);
  };

  const handleAbnormalTrackInputChange = (e) => {
    setAbnormalTrack({
      ...abnormalTrack,
      [e.target.name]: e.target.value
    });
  };

  const handleCreateCategory = async() => {
    let modifyAbnormalCategory = {
      id: 0,
      projectId,
      categoryName: otherCategory,
      enable: true,
      status: 1
    };
    try {
      await FormAPI.ModifyAbnormalCategory({ modifyAbnormalCategory });
      setOtherCategory('');
      await GetAbnormalCategoryList();
    } catch (error) {
      notifyFailed({ Msg: '新增異常/不合格樣態發生錯誤', isReload: false});
    }
  };

  const handleUploadTrackFile = (e) => {
    if (e.target.files && e.target.files[0]){
      setAbnormalTrack({
        ...abnormalTrack,
        file: e.target.files[0]
      });
    }
  };

  const handleTrackSave = async() => {
    if (Number(abnormalTrack.caseStatus) === 1 && (
      !abnormalTrack.abnormalCategoryID || (!abnormalTrack.caseResponseFileUrl && !abnormalTrack.file) || !abnormalTrack.improveDate
    )) {
      notifyFailed({ Msg: '請確認樣態分類、完成日期、案件回覆檔案' });
      return;
    }
    setIsSaving(true);
    let formData = new FormData();
    formData.append(`id`, abnormalForm.id || 0);
    formData.append(`caseStatus`, abnormalTrack.caseStatus);
    formData.append(`abnormalCategoryID`, abnormalTrack.abnormalCategoryID || '');
    formData.append(`caseNote`, abnormalTrack.caseNote || '');
    formData.append(`improveDate`, abnormalTrack.improveDate || '');
    formData.append(`file`, abnormalTrack.file);
    formData.append(`status`, 2);
    try {
      await FormAPI.ModifyAbnormalTrack({ projectId, modifyAbnormalTrack: formData });
      setIsSaving(false);
      notifySuccess({ Msg: '儲存成功', cbfunc: '' });
      // 儲存完關閉頁籤，並再打開
      let newFormList = await setFormTabListPromise(props.formTabList.filter(ele => ele._uuid !== props.uuid));
      let newAbnormalForm = await GetAbnormalInfo({ projectId, abnormalId: abnormalForm.id || 0 });
      let editForm = {
        id: newAbnormalForm.id,
        _uuid: tools._UUID(),
        formName: newAbnormalForm.docId,
        moduleId: 5, // 異常: 5
        abnormalForm: newAbnormalForm
      };
      let data = await setFormTabListPromise([...newFormList, editForm]);
      openFormTab(data, editForm._uuid);
    } catch (error) {
      notifyFailed({ Msg: '儲存異常/不合格追蹤發生錯誤', isReload: false});
      setIsSaving(false);
    }
  };

  // 匯出PDF
  const ExportPDF = async() => {
    setIsPDFExporting(true);
    await CreateANPdf();
    // await CreateANPhotoPdf();
    setIsPDFExporting(false);
  };
  const CreateANPdf = async() => {
    try {
      let data = await DocumentAPI.CreateANPdf(abnormalForm.id);
      tools.AjaxFileDownload({ rs: data, filename: `異常(不合格) 通知暨改善表- ${abnormalForm.docId}`, fileType: 'pdf'});
    } catch (error) {
      notifyFailed({Msg: '發生錯誤，匯出文件失敗', isReload: false});
    }
  };

  // const CreateANPhotoPdf = async() => {
  //   try {
  //     let data = await DocumentAPI.CreateANPhotoPdf({ mainId: abnormalForm.id });
  //     tools.AjaxFileDownload({ rs: data, filename: `異常(不合格) 通知暨改善表照片 - ${abnormalForm.docId}`, fileType: 'pdf'});
  //   } catch (error) {
  //     notifyFailed({Msg: '發生錯誤，匯出文件失敗', isReload: false});
  //   }
  // };

  return (
    <>
      <div className="sharebox">
        {
          abnormalForm.formStatus === 3 && (<div className="btn_box"><button className="btnL btnBlue" onClick={handleRestoreForm}>還原此表單</button></div>)
        }
        {
          abnormalForm.formStatus === 2 && userInfo.projectRoles.find(pr => pr.projectID === projectId)?.roleName === '管理者' && (
            <div className="btn_box"><a className="btnL btnBlue" href="#ReturnReason">退回重新審核</a></div>
          )
        }
        <FormTitle>異常(不合格)通知暨改善表</FormTitle>

        <div className="form form_loose" style={{ paddingBottom: '10px', }}>
          {/* 一行 */}
          <div className="form_half">
            <div className="form_title">
              工程名稱：
            </div>
            <div className="form_input">
              <p>{abnormalForm.projectName}</p>
            </div>
          </div>
          <div className="form_half">
            <div className="form_title">
              監造單位：
            </div>
            <div className="form_input">
              <p>{abnormalForm.supervision}</p>
            </div>
          </div>
          {/* 一行 */}
          <div className="form_13quarters">
            <div className="form_title">表單類型：</div>
            <div className="form_input">
              <VersionSelect value={abnormalForm.projectFormId} onChange={handleProjectFormIdChange} disabled={!abnormalForm.editable}>
                <option value=''>請選擇表單</option>
                {
                  projectFormList.map(ele => (
                    <optgroup key={ele.id} label={ele.moduleName}>
                      {
                        ele.projectFormList.map(ele1 => (
                          <option key={ele1.id} value={ele1.id}>{ele1.formName}</option>
                        ))
                      }
                    </optgroup>
                  ))
                }
              </VersionSelect>
            </div>
          </div>
          {/* 一行 */}
          <div className="form_half">
            <div className="form_title">
              文件編號：
            </div>
            <div className="form_input">
              {
                abnormalForm.editable ?
                  (<input type="text" name="docId" value={abnormalForm.docId} onChange={handleAbnormalFormInputChange}/>) :
                  (<p>{abnormalForm.docId}</p>)
              }
            </div>
          </div>
          <div className="form_half">
            <div className="form_title">
              查驗日期：
            </div>
            <div className="form_input">
              {
                abnormalForm.editable ?
                  (<DateInput type="date" name="ExamineDate" value={abnormalForm.ExamineDate} onChange={handleAbnormalFormInputChange}/>) :
                  (<p>{abnormalForm.ExamineDate}</p>)
              }
            </div>
          </div>
          {/* 一行 */}
          <div className="form_half">
            <div className="form_title">
              承攬廠商：
            </div>
            <div className="form_input">
              {
                abnormalForm.editable ?
                  (<input type="text" name="vendor" value={abnormalForm.vendor} onChange={handleAbnormalFormInputChange}/>):
                  (<p>{abnormalForm.vendor}</p>)
              }
            </div>
          </div>
          <div className="form_half">
            <div className="form_title">
              改善完成期限：
            </div>
            <div className="form_input">
              {
                abnormalForm.editable ?
                  (<DateInput type="date" name="improveDeadline" value={abnormalForm.improveDeadline} onChange={handleAbnormalFormInputChange}/>):
                  (<p>{abnormalForm.improveDeadline}</p>)
              }
            </div>
          </div>
          {/* 一行 */}
          <div className="form_13quarters">
            <div className="form_title">
              說明缺失具體情形：
            </div>
            <div className="form_input">
              {
                abnormalForm.editable ?
                  (<FaultDescTextarea name="flawNote" id="" cols="" rows="6" value={abnormalForm.flawNote} onChange={handleAbnormalFormInputChange}></FaultDescTextarea>):
                  (<p>{abnormalForm.flawNote}</p>)
              }
            </div>
          </div>
        </div>

        <FakeHr />

        <div className="uploadfiles">
          <FormTitle>異常(不合格)改善紀錄照片</FormTitle>
          {
            abnormalForm.editable && (<button className="btnL btnWhite" style={{ margin: '0 10px', }} onClick={handleCreateAbnormalPhoto}>新增異常(不合格)改善紀錄照片</button>)
          }
          <br />
          {
            abnormalForm.photoList.filter(el => el.status !== 3).map(ele => (
              <PictureUpload key={ele._uuid} img={ele} setImg={handleAbnormalImgUpload} deleteImg={handleAbnormalImgDelete} editable={abnormalForm.editable}/>
            ))
          }
        </div>

        <FakeHr />

        <div className="btn_box">
          {
            ((abnormalForm.signOffList && abnormalForm.signOffList.length > 0 && abnormalForm.signOffList[0].userName === userInfo.userName && abnormalForm.editable)
            || abnormalForm.status === 1)
            && (
              <div className="btn_box">
                <button className="btnL btnBlue" onClick={() => handleSave({ isSubmit: false })} disabled={isSaving}>
                  {
                    isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '儲存表單'
                  }
                </button>
              </div>
            )
          }

        </div>

        {
          abnormalForm.signOffList && abnormalForm.formStatus !== 3 && (<FormTitle>表單簽核</FormTitle>)
        }

        <TableBox>
          <div className="signArea">
            {
              abnormalForm.signOffList && abnormalForm.formStatus !== 3 && abnormalForm.signOffList.map(ele => (
                <div className="approver" key={tools._UUID()}>
                  <div className="approverTitle">
                    <div className="approverTitleInner">
                      <p>{ele.jobTitle} - {ele.name_zhtw}</p>
                      {
                        userInfo.userName === ele.userName && abnormalForm.userStampList && ele.signOffTag ?
                          (
                            <SignetSelect onChange={handleStampChange} value={abnormalForm.userStamp.userStampID}>
                              <option value=''>選擇影像章</option>
                              {
                                abnormalForm.userStampList.map(ele1 => (
                                  <option value={ele1.userStampID} key={ele1.userStampID}>{ele1.stampName}</option>
                                ))
                              }
                            </SignetSelect>
                          ) :
                          null
                      }
                    </div>
                  </div>
                  <div className="approverSign">
                    {
                      ele.sign ? <img src={ele.sign} key={ele.id}/> : (
                        userInfo.userName === ele.userName && abnormalForm.userStampList && ele.signOffTag ? (abnormalForm.userStamp.userStampID ? <img src={abnormalForm.userStamp.stampUrl} key={abnormalForm.userStamp.userStampID}/> : <div>請選擇影像章</div>) :
                          <div>尚未簽核</div>
                      )
                    }
                  </div>
                </div>
              ))
            }
          </div>
        </TableBox>
        <div className="btn_box">
          {/* 當有影像章列表(表示目前流程到此帳號)才可以編輯及送出簽核 */}
          {
            (abnormalForm.signOffList && abnormalForm.formStatus !== 3 && abnormalForm.signOffList.findIndex(ele => ele.signOffTag) === 0 && abnormalForm.userStampList) && (
              <button className="btnL btnBlue" onClick={() => handleSignOff(1)} disabled={isSaving}>
                {
                  isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '儲存並送出審核'
                }
              </button>
            )
          }
          {
            (abnormalForm.signOffList && abnormalForm.formStatus !== 3 &&
              abnormalForm.signOffList.findIndex(ele => ele.signOffTag) !== 0 &&
              abnormalForm.signOffList.findIndex(ele => ele.signOffTag) !== abnormalForm.signOffList.length-1 &&
              abnormalForm.userStampList)&& (
              <>
                <button className="btnL btnBlue" onClick={() => handleSignOff(1)} disabled={isSaving}>
                  {
                    isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '核准'
                  }
                </button>
                <button className="btnL btnRed" onClick={() => handleSignOff(2)} disabled={isSaving}>
                  {
                    isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '退回'
                  }
                </button>
              </>
            )
          }
          {
            (abnormalForm.signOffList && abnormalForm.formStatus !== 3 &&
              abnormalForm.signOffList.findIndex(ele => ele.signOffTag) === abnormalForm.signOffList.length-1 && abnormalForm.userStampList) && (
              <>
                <button className="btnL btnOrange" onClick={() => handleSignOff(0)} disabled={isSaving}>
                  {
                    isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '決行'
                  }
                </button>
                <button className="btnL btnRed" onClick={() => handleSignOff(2)} disabled={isSaving}>
                  {
                    isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '退回'
                  }
                </button>
              </>
            )
          }
        </div>
        {
          abnormalForm.formStatus === 2 && (<div className="btn_box">
            {
              <button className="btnL btnBlue" onClick={ExportPDF}>
                {
                  isPDFExporting ?
                    <div>
                  匯出中，請稍後...
                      <i className="fas fa-circle-notch fa-spin"></i>
                    </div> : '產出改善表'
                }
              </button>
            }
          </div>)
        }

        <FakeHr />

        {
          abnormalForm.formStatus === 2 && (
            <>
              <FormTitle>完成追蹤項目</FormTitle>

              <div className="form form_loose">
                {/* 一行 */}
                <div className="form_13quarters">
                  <div className="form_title">
                    案件狀態：
                  </div>
                  <div className="form_input">
                    <label>
                      <input type="radio" name={`casestatus-${props.uuid}-0`} value={0} checked={Number(abnormalTrack.caseStatus) === 0} onChange={handleTrackCaseStatusChange}/>
                        列管中
                    </label>
                    <label>
                      <input type="radio" name={`casestatus-${props.uuid}-1`} value={1} checked={Number(abnormalTrack.caseStatus) === 1} onChange={handleTrackCaseStatusChange}/>
                        已完成
                    </label>
                  </div>
                </div>
                {/* 一行 */}
                <div className="form_13quarters">
                  <div className="form_title">
                    不合格樣態分類：
                  </div>
                  <div className="form_input">
                    <SignetSelect style={{margin:'5px 3px',}} value={abnormalTrack.abnormalCategoryID} onChange={handleAbnormalCategoryChange}>
                      <option key={0} value={0}>請選擇</option>
                      {
                        abnormalCategoryList.map(ele => (
                          <option key={ele.id} value={ele.id}>{ele.categoryName}</option>
                        ))
                      }
                    </SignetSelect>
                    <input type="text" placeholder="新增其它" style={{ height: '30px', }} value={otherCategory} onChange={handleOtherCategoryChange} />
                    <button className="btnS btnWhite" onClick={handleCreateCategory} style={{
                      position: 'relative',
                      top: '-2px',
                    }}>新增</button>
                  </div>
                </div>
                {/* 一行 */}
                <div className="form_13quarters">
                  <div className="form_title">
                    案件備註：
                  </div>
                  <div className="form_input">
                    <FaultDescTextarea name="caseNote" id="" cols="" rows="6" value={abnormalTrack.caseNote} onChange={handleAbnormalTrackInputChange}></FaultDescTextarea>
                  </div>
                </div>
                {/* 一行 */}
                { // 已完成才需上傳案件回覆檔案
                  Number(abnormalTrack.caseStatus) === 1 && (
                    <>
                      <div className="form_13quarters">
                        <div className="form_title">
                          完成日期：
                        </div>

                        <div className="form_input">
                          <DateInput type="date" name="improveDate" value={abnormalTrack.improveDate} onChange={handleAbnormalTrackInputChange}/>
                        </div>
                      </div>
                      <div className="form_13quarters">
                        <div className="form_title">
                          案件回覆：
                        </div>

                        <div className="form_input">
                          {
                            abnormalForm.caseResponseFileUrl ? (
                              <ReplyFile href={abnormalTrack.caseResponseFileUrl} target='_blank' rel='noreferrer'>已上傳檔案</ReplyFile>
                            ) : '尚未上傳相關檔案'
                          }
                          <br />
                          <input type="file" onChange={handleUploadTrackFile} style={{ marginTop: '5px' }}/>
                        </div>
                      </div>
                    </>
                  )
                }
              </div>
              <FakeHr />
              <div className="btn_box">
                <button className="btnL btnBlue" onClick={handleTrackSave} disabled={isSaving}>
                  {
                    isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '儲存'
                  }
                </button>
              </div>
            </>
          )
        }
        <FakeHr />
        {
          <>
            <FormTitle>歷程紀錄</FormTitle> <br />

            <HistoricalRecord>
              {abnormalForm.historyRecordList.map(ele =>(
                <p key={tools._UUID()}>
                  <span> {moment(ele.createDate).format('YYYY-MM-DD HH:mm:ss') } </span>由<span> {ele.createID} </span>退件，理由：<span> {ele.note} </span>
                </p>
              ))}
            </HistoricalRecord>
          </>
        }
        {
          abnormalForm.formStatus === 2 && userInfo.projectRoles.find(pr => pr.projectID === projectId)?.roleName === '管理者' && (
            <>
              <FakeHr />

              <InputReturnReason>
                <a name="ReturnReason" id="ReturnReason"></a>
                <p>請輸入退回理由</p>
                <textarea name="returnNote" id="" cols="" rows="6" onChange={handleAbnormalFormInputChange}></textarea>

                <div className="btnbox">
                  {/* <button className="btnL btnBlue">確認退回</button> */}
                  <div className="btn_box">
                    <button className="btnL btnBlue" onClick={handleReturnSignOff} disabled={isSaving}>
                      {
                        isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '確認退回'
                      }
                    </button>
                  </div>
                </div>
              </InputReturnReason>
            </>
          )
        }
      </div>
    </>
  );

};
export default ErrorNotifyAndImprove;