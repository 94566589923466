import moment from "moment";

const range = len => {
  const arr = [];
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }
  return arr;
};

const newItem = (d) => {

  return {
    // actor: "Johnny Depp",
    projectID: d.projectID,
    // projectShortName: d.projectNum + '／' + d.projectShortName,
    projectShortName: <p><p>{d.projectNum + '／'}</p><p style={{ width: '6rem', overflow: 'hidden', textOverflow: 'ellipsis', display: 'inline-block', whiteSpace: 'nowrap' }} title={d.projectShortName}>{d.projectShortName}</p></p> ,
    // titleAgency: d.titleAgency,
    titleAgency: <span style={{ width: '5.5rem', overflow: 'hidden', textOverflow: 'ellipsis', display: 'inline-block', whiteSpace: 'nowrap' }} title={d.titleAgency}>{d.titleAgency}</span>,
    stage: d.stage,
    scheduledProgress: d.scheduledProgress,
    actualProgress: d.actualProgress,
    progress: d.progress > 1
      ? '落後'
      : d.progress > 0
        ? '超前'
        : '及時',
    companyEstimating: d.companyEstimating,
    amountProgress: d.amountProgress,
    contractStartDate: [
      ...d.contractStartDate.map(ele =>
        moment(ele).format('YYYY-MM-DD') + '\n'
      )
    ],
    completedDate: [
      d.completedDate.map(ele =>
        moment(ele).format('YYYY-MM-DD') + '\n'
      )
    ],
  };
};

export default function makeData(frontPage, ...lens) {
  // console.log(frontPage);
  // console.log(lens);
  const makeDataLevel = (depth = 0) => {
    const len = lens[depth];
    return range(len).map((idx) => {
      return {
        ...newItem(frontPage[idx]),
        subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined,
      };
    });
  };

  return makeDataLevel();
}








// const range = len => {
//   const arr = [];
//   for (let i = 0; i < len; i++) {
//     arr.push(i);
//   }
//   return arr;
// };

// export default function makeData(props) {

//   const newItem = () => {
//     return {
//       actor: "Johnny Depp",
//       // actor: props.frontPage.map(ele => (ele.projectShortName)),
//       // movies: [
//       //   {
//       //     name: "Pirates of the Carribean 1"
//       //   },
//       //   {
//       //     name: "Pirates of the Carribean 2"
//       //   },
//       // ]
//     };
//   };

//   const makeDataLevel = (depth = 0) => {
//     const len = props.length[depth];
//     return range(len).map(() => {
//       return {
//         ...newItem(),
//         subRows: props.length[depth + 1] ? makeDataLevel(depth + 1) : undefined,
//       };
//     });
//   };

//   return makeDataLevel();
// }










// import { useState, useEffect } from 'react';
// import { StatisticAPI } from './../assets/api';
// import { notifyFailed } from './../assets/toaster';

// const range = len => {
//   const arr = [];
//   for (let i = 0; i < len; i++) {
//     arr.push(i);
//   }
//   return arr;
// };

// const newItem = () => {

//   const [frontPage, setFrontPage] = useState([]);

//   async function GetFrontPage() {
//     try {
//       let data = await StatisticAPI.GetFrontPage();
//       setFrontPage(data);
//     } catch (error) {
//       notifyFailed({ Msg: '發生錯誤', isReload: false });
//     }
//     console.log(frontPage);
//   }

//   useEffect(() => {
//     GetFrontPage();
//   }, []);

//   // const statusChance = Math.random();
//   return {
//     // status:
//     //   statusChance > 0.66
//     //     ? 'relationship'
//     //     : statusChance > 0.33
//     //       ? 'complicated'
//     //       : 'single',
//     // testA: '很多字',
//     // testB: Math.floor(Math.random() * 30),
//     // actor: "Johnny Depp",
//     actor: frontPage.map(ele => (ele.projectShortName)),
//     // movies: [
//     //   {
//     //     name: "Pirates of the Carribean 1"
//     //   },
//     //   {
//     //     name: "Pirates of the Carribean 2"
//     //   },
//     // ]
//   };
// };

// export default function makeData(...lens) {

//   const makeDataLevel = (depth = 0) => {
//     const len = lens[depth];
//     return range(len).map(() => {
//       // console.log(d);
//       return {
//         ...newItem(),
//         subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined,
//       };
//     });
//   };

//   return makeDataLevel();
// }
