import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.scss';
import { BrowserRouter as Router } from "react-router-dom";
import App from './js/App';
import LoginStateProvider from './js/routes/LoginStateProvider';
import ProjectContextProvider from './js/contexts/ProjectContext';
import ThemeContextProvider from './js/contexts/ThemeContext';
import ScrollToTop from "./js/assets/customHooks/ScrollToTop";


ReactDOM.render((
  <Router>
    <ScrollToTop>
      <LoginStateProvider>
        <ThemeContextProvider>
          <ProjectContextProvider>
            <App />
          </ProjectContextProvider>
        </ThemeContextProvider>
      </LoginStateProvider>
    </ScrollToTop>
  </Router>),
document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
