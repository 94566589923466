import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import { ProjectAPI } from "./../assets/api";
import styled from '@emotion/styled';
import { notifyFailed } from './../assets/toaster';
import PictureUpload from './../components/PictureUpload';

const TxtInput = styled.input`
    padding: 0 4px;
    box-sizing: border-box;
    height: 34px;
    border-radius: 3px;
    border: 1px solid #999;
    min-width: 15em;
  `;
const FilterTable = styled.div`
    margin: 32px 0;
    .queryMenu_btnbox{
      margin: 16px 0 8px 0;
    }
  `;


const ProjectPhotoDetail = () => {
  const { projectId } = useParams();
  const [photoList, setPhotoList] = useState([]);
  const [photoSearch, setPhotoSearch] = useState({
    projectId: projectId,
    content: "",
    examineSDate: "",
    examineEDate: ""
  });
  const PostProjectPhotoList = async(photoSearch) => {
    try{
      let data = await ProjectAPI.PostProjectPhotoList(photoSearch);
      if (data) setPhotoList(data);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  };
  useEffect(async ()=>{
    await PostProjectPhotoList(photoSearch);
  }, []);

  const handlePhotoSearchChange = (e) => {
    setPhotoSearch({
      ...photoSearch,
      [e.target.name]: e.target.value
    });
  };

  const handleSearch = async() => {
    await PostProjectPhotoList(photoSearch);
  };

  return (
    <>
      <div className="bumper_rail"></div>
      <div className="commontitle">
        <h1>工程美照</h1>
      </div>
      <FilterTable className="filter_table">
        <div className="">
          報表月份：
          <TxtInput type="month" style={{ minWidth: '5em', margin: '4px 0' }} name='examineSDate' onChange={handlePhotoSearchChange} />
          <span style={{ margin: '0 3px', }}>-</span>
          <TxtInput type="month" style={{ minWidth: '5em', margin: '4px 0' }} name='examineEDate' onChange={handlePhotoSearchChange} />
        </div>
        <br />
        <div className="">
          照片說明：
          <TxtInput type="text" name="content" onChange={handlePhotoSearchChange} />
        </div>
        <br />
        <div className="queryMenu_btnbox" style={{width: '100%', textAlign: 'right', }}>
          <button className="btnL btnBlue" onClick={handleSearch} >搜尋</button>
        </div>
      </FilterTable>
      <div className="uploadfiles">
        {
          photoList.filter(el => el.status !== 3).map(ele => (
            <PictureUpload key={ele._uuid} img={ele} />
          ))
        }
      </div>
    </>
  );
};
export default ProjectPhotoDetail;