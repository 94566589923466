import QualityCheckRecord from './QualityCheckRecord'; // 材料/設備品質抽驗紀錄表
import ConstructionCheckRecord from './ConstructionCheckRecord'; // 施工抽查表
import OperationCheckRecord from './OperationCheckRecord'; // 設備功能運轉測試
import ErrorNotifyAndImprove from './ErrorNotifyAndImprove'; // 異常(不合格)通知暨改善表
import SafetyHealthCheckRecord from './SafetyHealthCheckRecord'; // 一般安全衛生查驗表
import SuperviseDailyReport from './SuperviseDailyReport'; // 監督日報表
import WorkerAndImplements from './WorkerAndImplements'; // 每日出工與機具填寫
import SuperviseMonthlyReport from './SuperviseMonthlyReport'; //專案工作月報表
import styled from '@emotion/styled';
import { useEffect } from 'react';
import { ReactComponent as NullValueIcon } from "./../../images/NullValue_Icon.svg";

const IconCancel = styled.span`
  margin-left: 10px;
  font-size: 14px;
  position: relative;
  top: -2px;
  color: #999;
  &:hover{
    // color: #f55753CC;
    color: #666;
  }
`;

const NullValueBox = styled.div`
  text-align: center;
  padding: 48px 0 40px 0;
  p{
    color: #A1A1A1;
    margin: 8px 0;
    letter-spacing: 1px;
  }
`;

const FloatPointCoat = styled.a`
    color:#333;
`;

const FloatPoint = styled.div`
    border: 1px solid #AAA;
    background: #EEE8;
    position: fixed;
    bottom: 2em;
    right: 2em;
    border-radius: 50%;
    width: 3.5em;
    height: 3.5em;
    text-align: center;
    span{
      position: relative;
      top: 0.35em;
    }
`;

const FormDetail = (props) => {

  const handleFormTabSelect = (tab) => {
    props.setFormTabSelected(tab);
  };

  const handleTabCancel = (tab) => {
    props.setFormTabList(props.formTabList.filter(ele => ele._uuid !== tab._uuid));
  };

  useEffect(() => {
    if (props.formTabList.length === 0) props.setFormTabSelected({});
  }, [props.formTabList]);

  return (
    <>
      {/* <div className="scroll-page" id="Top"></div> */}
      <div className="main">
        {/* 分頁頁籤區 */}
        <div className="tabbox">
          {
            props.formTabList.map((ele, idx) => (
              <div
                key={ele._uuid}
                className={`liDiv ${props.formTabSelected._uuid === ele._uuid ? `active_tooth` : ''}`}
                style={{ fontStyle: ele.id > 0 ? '' : 'italic' }}
                onClick={() => handleFormTabSelect(ele)}>
                  [{idx+1}] {ele.formName}<IconCancel onClick={() => handleTabCancel(ele)}><i className="fas fa-times"></i></IconCancel>
              </div>
            ))
          }
        </div>

        {/* 表單顯示區 */}
        {
          Object.entries(props.formTabSelected).length > 0 ?

            props.formTabList.map(ele => {
              switch (ele.moduleId) {
              // 材料/設備品質抽驗紀錄表
              case 1:
                return (
                  <div key={ele._uuid} style={{ display: props.formTabSelected._uuid === ele._uuid ? 'block' : 'none' }}>
                    <QualityCheckRecord
                      setIsListOn={props.setIsListOn}
                      uuid={ele._uuid}
                      formContent={ele.formContent}
                      abnormalForm={ele.abnormalForm}
                      formTabList={props.formTabList}
                      setFormTabList={props.setFormTabList}
                      setFormStatus={props.setFormStatus}
                      setFormTabSelected={props.setFormTabSelected}
                      setTotalPages={props.setTotalPages}
                      setFormStatusNum={props.setFormStatusNum}
                      setProjectEQFormList={props.setProjectEQFormList}
                      GetModuleList={({ isModuleId }) => props.GetModuleList({ isModuleId })}
                      GetProjectFormList={props.GetProjectFormList}
                    />
                  </div>
                );
              // 施工抽查紀錄表
              case 2:
                return (
                  <div key={ele._uuid} style={{ display: props.formTabSelected._uuid === ele._uuid ? 'block' : 'none' }}>
                    <ConstructionCheckRecord
                      setIsListOn={props.setIsListOn}
                      uuid={ele._uuid}
                      formContent={ele.formContent}
                      abnormalForm={ele.abnormalForm}
                      formTabList={props.formTabList}
                      setFormTabList={props.setFormTabList}
                      setFormStatus={props.setFormStatus}
                      setFormTabSelected={props.setFormTabSelected}
                      setTotalPages={props.setTotalPages}
                      setFormStatusNum={props.setFormStatusNum}
                      setProjectCEFormList={props.setProjectCEFormList}
                      GetModuleList={({ isModuleId }) => props.GetModuleList({ isModuleId })}
                      GetProjectFormList={props.GetProjectFormList}
                    />
                  </div>
                );
              // 設備功能運轉測試表
              case 3:
                return (
                  <div key={ele._uuid} style={{ display: props.formTabSelected._uuid === ele._uuid ? 'block' : 'none' }}>
                    <OperationCheckRecord
                      setIsListOn={props.setIsListOn}
                      uuid={ele._uuid}
                      formContent={ele.formContent}
                      abnormalForm={ele.abnormalForm}
                      formTabList={props.formTabList}
                      setFormTabList={props.setFormTabList}
                      setFormStatus={props.setFormStatus}
                      setFormTabSelected={props.setFormTabSelected}
                      setTotalPages={props.setTotalPages}
                      setFormStatusNum={props.setFormStatusNum}
                      setProjectEFFormList={props.setProjectEFFormList}
                      GetModuleList={({ isModuleId }) => props.GetModuleList({ isModuleId })}
                      GetProjectFormList={props.GetProjectFormList}
                    />
                  </div>
                );
              // 一般安全衛生查驗表: SafetyHealthCheckRecord
              case 4:
                return (
                  <div key={ele._uuid} style={{ display: props.formTabSelected._uuid === ele._uuid ? 'block' : 'none' }}>
                    <SafetyHealthCheckRecord
                      setIsListOn={props.setIsListOn}
                      uuid={ele._uuid}
                      formContent={ele.formContent}
                      abnormalForm={ele.abnormalForm}
                      formTabList={props.formTabList}
                      setFormTabList={props.setFormTabList}
                      setFormStatus={props.setFormStatus}
                      setFormTabSelected={props.setFormTabSelected}
                      setTotalPages={props.setTotalPages}
                      setFormStatusNum={props.setFormStatusNum}
                      setProjectHSFormList={props.setProjectHSFormList}
                      setProjectHSIsNCO={props.setProjectHSIsNCO}
                      GetModuleList={({ isModuleId }) => props.GetModuleList({ isModuleId })}
                      GetProjectFormList={props.GetProjectFormList}
                    />
                  </div>
                );
              // 設備功能運轉測試紀錄表: OperationCheckRecord
              // 異常(不合格)通知暨改善表: ErrorNotifyAndImprove
              case 5:
                return (
                  <div key={ele._uuid} style={{ display: props.formTabSelected._uuid === ele._uuid ? 'block' : 'none' }}>
                    <ErrorNotifyAndImprove
                      moduleSelected={props.moduleSelected} // 選擇之模組
                      setModuleSelected={props.setModuleSelected} // 設定選擇模組
                      setIsListOn={props.setIsListOn}
                      uuid={ele._uuid}
                      formTabList={props.formTabList}
                      setFormTabList={props.setFormTabList}
                      abnormalForm={ele.abnormalForm}
                      setAbnormalList={props.setAbnormalList}
                      setFormTabSelected={props.setFormTabSelected}
                      setFormStatus={props.setFormStatus}
                      setTotalPages={props.setTotalPages}
                      setFormStatusNum={props.setFormStatusNum}
                      GetModuleList={({ isModuleId }) => props.GetModuleList({ isModuleId })}
                      GetProjectFormList={props.GetProjectFormList}
                    />
                  </div>
                );
              // 監督日報表
              case 6:
                return (
                  <div key={ele._uuid} style={{ display: props.formTabSelected._uuid === ele._uuid ? 'block' : 'none' }}>
                    <SuperviseDailyReport
                      setIsListOn={props.setIsListOn}
                      uuid={ele._uuid}
                      formContent={ele.formContent}
                      formTabList={props.formTabList}
                      setFormTabList={props.setFormTabList}
                      setFormStatus={props.setFormStatus}
                      setFormTabSelected={props.setFormTabSelected}
                      setSupervisionList={props.setSupervisionList}
                      setTotalPages={props.setTotalPages}
                      setFormStatusNum={props.setFormStatusNum}
                      GetModuleList={({ isModuleId }) => props.GetModuleList({ isModuleId })}
                      GetProjectFormList={props.GetProjectFormList}
                    />
                  </div>
                );
              // 每日出工與機具填寫
              case 7:
                return (
                  <div key={ele._uuid} style={{ display: props.formTabSelected._uuid === ele._uuid ? 'block' : 'none' }}>
                    <WorkerAndImplements
                      setIsListOn={props.setIsListOn}
                      uuid={ele._uuid}
                      formContent={ele.formContent}
                      formTabList={props.formTabList}
                      setFormTabList={props.setFormTabList}
                      setFormStatus={props.setFormStatus}
                      setFormTabSelected={props.setFormTabSelected}
                      // setSupervisionList={props.setSupervisionList}
                      setToolsList={props.setToolsList}
                      setToolsTotalList={props.setToolsTotalList}
                      setTotalPages={props.setTotalPages}
                      setFormStatusNum={props.setFormStatusNum}
                      GetModuleList={({ isModuleId }) => props.GetModuleList({ isModuleId })}
                      GetProjectFormList={props.GetProjectFormList}
                    />
                  </div>
                );
              // 專案工作月報表
              case 8:
                return (
                  <div key={ele._uuid} style={{ display: props.formTabSelected._uuid === ele._uuid ? 'block' : 'none' }}>
                    <SuperviseMonthlyReport
                      setIsListOn={props.setIsListOn}
                      uuid={ele._uuid}
                      formContent={ele.formContent}
                      formTabList={props.formTabList}
                      setFormTabList={props.setFormTabList}
                      setFormStatus={props.setFormStatus}
                      setFormTabSelected={props.setFormTabSelected}
                      setSupervisionMonthlyList={props.setSupervisionMonthlyList}
                      setTotalPages={props.setTotalPages}
                      setFormStatusNum={props.setFormStatusNum}
                      GetModuleList={({ isModuleId }) => props.GetModuleList({ isModuleId })}
                      GetProjectFormList={props.GetProjectFormList}
                    />
                  </div>
                );
              default:
                break;
              }
            }) :
            <NullValueBox>
              <NullValueIcon />
              <p>請選擇表單</p>
            </NullValueBox>
        }

        <FloatPointCoat href="#Top"><FloatPoint><span>回到<br />頂部</span></FloatPoint></FloatPointCoat>
      </div>
    </>
  );

};
export default FormDetail;