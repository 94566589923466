import axios from "axios";

const API = axios.create({
  baseURL: window.location.origin + "/api"
});
API.defaults.headers.common["Content-Type"] = "application/json; charset=utf-8";
API.defaults.withCredentials = true;
API.CancelToken = axios.CancelToken;
API.isCancel = axios.isCancel;

// 前端本機設定
if (window.location.href.indexOf("localhost") > 0) {
  API.defaults.baseURL = "https://localhost:44326/api";
}

export default API;