import './../../style/Manage.scss';
import DashboardMain from './DashboardMain';
import DashboardDetail from './DashboardDetail';
import DashboardAbnormal from './DashboardAbnormal';
import DashboardNewIndex from './DashboardNewIndex';
import DashboardNewAbout from './DashboardNewAbout';
import DashboardNewProgress from './DashboardNewProgress';
import DashboardNewConstruction from './DashboardNewConstruction';
import DashboardNewMaterial from './DashboardNewMaterial';
import DashboardNewAbnormal from './DashboardNewAbnormal';
import DashboardNewHSAbnormal from './DashboardNewHSAbnormal';
import DashboardNewHealthSafe from './DashboardNewHealthSafe';
import DashboardNewPersonnel from './DashboardNewPersonnel';
import D3Demo from './D3Demo';
import { Route, useRouteMatch } from 'react-router-dom';

const Manage = () => {
  let { path } = useRouteMatch();

  return (
    <>
      {/* <Route path={`${path}/project-material-manage`} component={FormList}></Route> */}
      <div className="main">
        <Route path={`${path}/dashboard-main`} component={DashboardMain}></Route>
        <Route path={`${path}/dashboard-detail`} component={DashboardDetail}></Route>
        <Route path={`${path}/dashboard-abnormal`} component={DashboardAbnormal}></Route>
        <Route path={`${path}/dashboard-new-index`} component={DashboardNewIndex}></Route>
        <Route path={`${path}/dashboard-new-about`} component={DashboardNewAbout}></Route>
        <Route path={`${path}/dashboard-new-progress`} component={DashboardNewProgress}></Route>
        <Route path={`${path}/dashboard-new-construction`} component={DashboardNewConstruction}></Route>
        <Route path={`${path}/dashboard-new-material`} component={DashboardNewMaterial}></Route>
        <Route path={`${path}/dashboard-new-abnormal`} component={DashboardNewAbnormal}></Route>
        <Route path={`${path}/dashboard-new-hsabnormal`} component={DashboardNewHSAbnormal}></Route>
        <Route path={`${path}/dashboard-new-healthsafe`} component={DashboardNewHealthSafe}></Route>
        <Route path={`${path}/dashboard-new-personnel`} component={DashboardNewPersonnel}></Route>
        <Route path={`${path}/d3-demo`} component={D3Demo}></Route>
      </div>
    </>
  );
};
export default Manage;