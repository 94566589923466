import { useState, useEffect, useContext, useRef } from 'react';
import styled from '@emotion/styled';
import tools from '../assets/tools';
import moment from 'moment';
import { Link } from "react-router-dom";
import { SwitchMode } from './SwitchMode';
import { ThemeContext } from './../contexts/ThemeContext';
import { D3PieChart, D3Swatches , D3StackedHorizontalBarChart } from '../assets/d3components';
import { StatisticAPI, HelperAPI } from './../assets/api';
// import colorSchemes from './../assets/d3components/config/scale-chromatic';
import { useLocalStorage } from './useLocalStorage';
import { ChartDownloadButton } from './DownloadImageButton';
import { ExportToExcelButton } from './ExportToExcel';
import { useIsFirstRender } from './../assets/customHooks/useIsFirstRender';

const TableBlock = styled.div`
color: ${props => props.isDarkMode ? '#e6e6e6' : '#0c0c0c'};
background-color: ${props => props.isDarkMode ? '#444' : '#ffffff'};
border: 1px solid ${props => props.isDarkMode ? 'none' : '#7b7979'};
margin: 5px 10px 0;
.table {
 display: flex;
 width: 100%;
 .caption {
   width: 15%;
   min-width: 80px;
   position: relative;
   .captionItem{
     width: 100%;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     text-align: center;
     span {
       width: 100%;
       border-right: 1px solid ${props => props.isDarkMode ? '#e6e6e6' : '#7b7979'};
       &:first-of-type {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
         border-bottom: 1px solid ${props => props.isDarkMode ? '#e6e6e6' : '#7b7979'};
       }
       h4 {
       padding: 10px 5px;
       }
       &:not(:first-of-type){
         &:hover {
          background-color: ${props => props.isDarkMode ? '#555555' : '#ccc'};
           cursor: pointer;
         }
       }
     }
   }
 }
 .scrollContainer{
  width: 100%;
  overflow: auto;
  display: flex;
  .scrollBar {
    width: 100%;
    min-width: ${props => props.hsExamineDataList.length * 120 }px;
    .itemTitleBox {
      border-bottom: 1px solid ${props => props.isDarkMode ? '#e6e6e6' : '#7b7979'};
      .itemTitleGroup {
         width: 100%;
         display: flex;
         flex-wrap: nowrap;
         align-items: center;
         .itemTitle {
           width: ${props => (props.hsExamineDataList.length * 100) / props.hsExamineDataList.length }px;
           height: 80px;
           display: flex;
           align-items: center;
           justify-content: center;
           padding: 0 10px;
           position: relative;
           span {
            display: inline-block;
            font-size: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
           }
           &:hover {
             background-color: ${props => props.isDarkMode ? '#555555' : '#ccc'};
             cursor: pointer;
           }
         }
      }
    }
    .checkContent {
       display: flex;
       justify-content: space-between;
       align-items: center;
       .itemContentGroup {
         width: 100%;
         display: flex;
         flex-wrap: nowrap;
         align-items: center;
         .itemContent {
          width: ${props => (props.hsExamineDataList.length * 120) / props.hsExamineDataList.length }px;
          height: 41.6px;
          span {
            display: inline-block;
            width: 100%;
            text-align: center;
            padding: 10px 0;
           }
           &:hover {
            background-color: ${props => props.isDarkMode ? '#555555' : '#ccc'};
            cursor: pointer;
           }
         }
       }
    }
  }
}
}
@media (max-width: 768px) {
 margin: 10px;
}
`;

const Display = styled.div`
display: flex;
margin: 0 10px;
@media (max-width: 1280px) {
 flex-direction: column;
 justify-content: center;
}
@media (max-width: 768px) {
  margin: 0 10px;
}
`;

const ChartContainer = styled.div`
width: 70%;
display: flex;
justify-content: center;
margin-top: 10px;
@media (max-width: 1280px) {
 width: 100%;
 margin-right: 10px;
}
@media (max-width: 768px) {
 flex-direction: column;
 margin: 0px;
}
`;

const ChartBg = styled.div`
width: 70%;
margin-right: 10px;
border: 1px solid ${props => props.isDarkMode ? 'none' : '#7b7979'};
background-color: ${props => props.isDarkMode ? '#444' : '#ffffff'};
position: relative;
@media (max-width: 768px) {
  width: 100%;
  margin-bottom: 10px;
}
.chart {
  position: relative;
  .chartTitle {
    width: 100%;
    height: 50px;
    font-size: 20px;
    padding: 30px 0 10px;
    text-align: center;
    font-weight: bold;
    position: absolute;
    color: ${props => props.isDarkMode ? '#ffffff' : '#0c0c0c'};
    background-color: ${props => props.isDarkMode ? '#444' : '#ffffff'};
  }
}
`;

const PieChart = styled.div`
width: 30%;
padding: 0 5px;
color: ${props => props.isDarkMode ? '#e6e6e6' : '#0c0c0c'};
border: 1px solid ${props => props.isDarkMode ? 'none' : '#7b7979'};
background-color: ${props => props.isDarkMode ? '#444' : '#ffffff'};
position: relative;
.ChartWrap {
  background-color: ${props => props.isDarkMode ? '#444' : '#ffffff'};
  span {
    margin-left: 5px;
    font-size: 14px;
    display: inline-block;
  }
}
@media (max-width: 768px) {
  width: 100%;
  padding: 0;
}
`;

const TableDisplay = styled.div`
width: 30%;
margin: 10px 0 10px 10px;
@media (max-width: 1280px) {
  width: 100%;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
}
`;

const EvenTable = styled.div`
width: 100%;
height: 50%;
color: ${props => props.isDarkMode ? '#e6e6e6' : '#0c0c0c'};
background-color: ${props => props.isDarkMode ? '#444' : '#ffffff'};
border: 1px solid ${props => props.isDarkMode ? 'none' : '#7b7979'};
h1 {
  color: #f28e2b;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 5px 0;
}
.table {
  width: 100%;
  .thead {
    .tr {
      display: flex;
      color: #0c0c0c;
      background-color: #e6e6e6;
      span {
        display: inline-block;
        width: 100%;
        padding: 5px 0;
        margin-left: 10px;
        &:first-of-type {
          width: 50%;
        }
      }
    }
  }
  .tbody {
    height: 210px;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 20px #ccc;
    }
    ::-webkit-scrollbar-thumb {
      background: #bcbcbc;
    }
    .tr {
      display: flex;
      border-bottom: 1px solid ${props => props.isDarkMode ? '#e6e6e6' : '#999'};
      &:hover {
        background-color: ${props => props.isDarkMode ? '#555555' : '#ccc'};
        cursor: pointer;
      }
      .td {
        width: 100%;
        padding: 10px 0;
        span {
          display: inline-block;
          margin-left: 10px;
        }
        &:first-of-type {
          width: 50%;
        }
      }
    }
  }
}
`;

const CheckTable = styled.div`
width: 100%;
height: 50%;
margin: 10px 0;
color: ${props => props.isDarkMode ? '#e6e6e6' : '#0c0c0c'};
background-color: ${props => props.isDarkMode ? '#444' : '#ffffff'};
border: 1px solid ${props => props.isDarkMode ? 'none' : '#7b7979'};
h1 {
  color: #f28e2b;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 5px 0;
}
.table {
  .thead {
    .tr {
      display: flex;
      color: #0c0c0c;
      background-color: #e6e6e6;
      span {
        width: 50%;
        margin: 0 10px;
        padding: 5px 0;
      }
    }
  }
  .tbody {
    height: 250px;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 20px #ccc;
    }
    ::-webkit-scrollbar-thumb {
      background: #bcbcbc;
    }
    .tr {
      display: flex;
      align-items: center;
      border-bottom: 1px solid ${props => props.isDarkMode ? '#e6e6e6' : '#999'};
      .td {
        width: 50%;
        margin: 10px;
        &:not(:first-of-type) {
          margin-left: 20px;
        }
      }
      .inputRadio {
        display: flex;
        flex-direction: column;
      }
      &:hover {
        background-color: ${props => props.isDarkMode ? '#555' : '#ccc'};
        cursor: pointer;
        .chkeckBox {
          border: 1px solid #0c0c0c;
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  height: 100%;
  margin: 0;
  margin-top: 10px;
}
`;

const LinkBtn = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
margin: 10px 0;
.fakeBtn{
  width: 50%;
  padding: 10px 5px;
  color: ${props => props.isDarkMode ? '#e6e6e6' : '#0c0c0c'};
  background-color: ${props => props.isDarkMode ? '#444' : '#fff'};
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  &:first-of-type {
    margin-right: 10px;
  }
  &.fakeActive {
    border: 1px solid #F28E2B;
    color: ${props => props.isDarkMode ? '#F28E2B' : '#fff'};
    background-color: ${props => props.isDarkMode ? '#444' : '#F28E2B'};
    cursor: unset;
  }
}
`;

const NoDataText = styled.div`
width: 100%;
font-size: 20px;
display: flex;
align-items: center;
justify-content: center;
color: ${props => props.isDarkMode ? '#e6e6e6' : '#55555'};
`;

const TooltipArea = styled.div`
width: 70%;
position: absolute;
top: 90%;
left: 70%;
padding: 5px;
font-size: 14px;
color: #444;
border: #444;
background-color: #fff;
`;

const ExportButton = styled.div`
width: 100%;
height: 20px;
border-right: 1px solid #e6e6e6;
span {
  display: inline-block;
  position: absolute;
  bottom: -5px;
  right: 50%;
  left: 50%;
  transform: translateX(50px);
}
`;

const colorTheme = {
  light: {
    titleTextColor: "#black",
    xValueColor: "black",
    yValueColor: "black",
    backgroundColor: "#ffffff",
    color: "#2f2f2f"
  },
  dark: {
    titleTextColor: "white",
    xValueColor: "white",
    yValueColor: "white",
    backgroundColor: "#444",
    color: "white"
  }
};

const DashboardMain = () => {
  const isFirstRender = useIsFirstRender();
  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark' ? true : false;

  const [projectList, setProjectList] = useState([]); //工程項目清單
  const [projectId, setProjectId] = useLocalStorage("projectId", projectList.length ? projectList[0].id : 0);
  const [hsExamineDataList, setHsExamineDataList] = useState([]); //檢查結果
  const [examineStatistic, setExamineStatistic] = useState(""); //職安衛稽查統計
  const [examineStatisticTotal, setExamineStatisticTotal] = useState([]); //合格統計
  const [occupationalAccidentList, setOccupationalAccidentList] = useState([]); //職災事件
  const [laborInspectionList, setLaborInspectionList] = useState([]); //勞動檢查
  
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipText, setTooltipText] = useState("");
  const lastTooltipRef = useRef(null);

  const [stackedHorizontalBarChart, setStackedHorizontalBarChart] = useState({
    chartId: "examineStatisticChartSvg",
    downloadAreaId: "examineStatisticChart",
    titleText: "職安衛稽查統計",
    xAttr: 'num',
    yAttr: 'name',
    zAttr: 'checkItem',
    yLabel: '',
    marginLeft: 150,
    marginRight: 10,
    marginTop: 60,
    // height: 800,
    // maxHeight: 500,
    paddingLeft: 10,
    colorCustom: ['#4e79a7', '#f28e2c',],
    colorInterpolate: "",
    ...colorTheme[isDarkMode ? "dark" : "light"]
  });
  const [pieChartOption, setPieChartOption] = useState({
    chartId: "examineStatisticTotalChartSvg",
    downloadAreaId: "examineStatisticTotalChart",
    name: 'name',
    value: 'total',
    percentage: 'rate',
    titleText: "",
    paddingBottom: 10,
    colorCustom: ['#4e79a7', '#f28e2c',],
    ...colorTheme[isDarkMode ? "dark" : "light"]
  });
  const checkItems = ["合格","不合格"];

  //取得工程項目清單
  const GetStaticProjectList = async () => {
    try {
      let data = await HelperAPI.GetStaticProjectList();
      setProjectList(data.projectList);
      return data.projectList;
    } catch (error) {
      console.log(error);
    }
  };

  //取得職業安全衛生
  const GetHSExamineRecord = async (projectId) => {
    try {
      let data = await StatisticAPI.GetHSExamineRecord(projectId);
      const newHsExamineDataList = data.hsExamineDataList.map( d => (
        {
          formName: d.formName,
          hasImprovedNum: d.hasImprovedNum,
          qualifiedNum: d.qualifiedNum,
          unqualifiedNum: d.unqualifiedNum,
          hasNotImprovedNum: d.hasNotImprovedNum,
        }
      ));
      setHsExamineDataList(newHsExamineDataList);

      const examineStatisticData = data.hsExamineDataList.map( d => (
        {
          name : d.formName,
          合格 : d.qualifiedNum,
          不合格: d.unqualifiedNum,
        }
      ));
      const newExamineData = checkItems.flatMap(checkItem => examineStatisticData.map(
        d => (
          {
            name: d.name,
            checkItem,
            num: d[checkItem]
          }
        )));
      setExamineStatistic(newExamineData);

      const qualifiedTotal = newExamineData.reduce((acc, cur) => {
        const result = acc.find(ele => ele.name === cur.checkItem);
        if (result) {
          result.total += cur.num;
        } else {
          acc.push({
            name: cur.checkItem,
            total: cur.num,
            rate: 0
          });
        }
        return acc;
      }, []);
      qualifiedTotal.forEach(item => {
        item.rate = (item.total / qualifiedTotal.reduce((acc, cur) => acc + cur.total, 0) * 100).toFixed(2);
      });
      setExamineStatisticTotal(qualifiedTotal);

      const occupationalAccidentData = data.occupationalAccidentList;
      const newOccupationalAccidentData = occupationalAccidentData.map((ele) => {
        return {
          ...ele,
          fillInMonth: moment(ele.fillInMonth).format('YYYY年M月'),
        };
      });
      setOccupationalAccidentList(newOccupationalAccidentData );

      const laborInspectionListData = data.laborInspectionDataList;
      const newLaborInspectionListData = laborInspectionListData.map((ele) => {
        return {
          ...ele,
          inspectionDate: moment(ele.inspectionDate).format('YYYY-MM-DD'),
        };
      });
      setLaborInspectionList(newLaborInspectionListData);

    } catch (error) {
      console.log(error);
    }
  };

  const handleProjectSelect = (e) => {
    setProjectId(e.target.value);
  };

  const handleMouseOver = (e, text) => {
    setTooltipText(text);
    setShowTooltip(true);
    if (lastTooltipRef.current) {
      const tooltip = lastTooltipRef.current;
      tooltip.style.left = `${ -10 }px`;
    }
  };

  const handleMouseOut = () => {
    setShowTooltip(false);
  };

  useEffect(async() => {
    let staticProjectList = await GetStaticProjectList();
    if (projectId === 0) {
      setProjectId(staticProjectList[0].id);
    } else {
      GetHSExamineRecord(projectId);
    }
  },[]);

  useEffect(() => {
    if (!isFirstRender) {
      GetHSExamineRecord(projectId);
    }
  },[projectId]);

  useEffect(() => {
    setStackedHorizontalBarChart({
      ...stackedHorizontalBarChart,
      ...colorTheme[isDarkMode ? "dark" : "light"]
    });

    setPieChartOption({
      ...pieChartOption,
      ...colorTheme[isDarkMode ? "dark" : "light"]
    });
  }, [isDarkMode]);

  function Tooltip({ text, index }) {
    return <TooltipArea ref={index === hsExamineDataList.length - 1 ? lastTooltipRef : null}>{text}</TooltipArea>;
  }

  return (
    <>
      {/* 深色模式切換按鈕 */}
      <SwitchMode/>
      <div className="containerBox" style={{backgroundColor: isDarkMode ? '#666666' : '#F1F1F1' }}>
        <section className="dashboardContent">
          <div className="dashboardUpArea">
            <LinkBtn isDarkMode={isDarkMode}>
              <Link to={{ pathname: `dashboard-new-healthsafe` }} className="fakeBtn fakeActive" >職業安全衛生</Link>
              <Link to={{ pathname: `dashboard-new-hsabnormal` }} className="fakeBtn">職安衛缺失改善追蹤</Link>
            </LinkBtn>
            <div className="projectWrap" style={{backgroundColor: isDarkMode ? '#555555' : '#ffffff'}}>
              <h3 style={{color: isDarkMode ? '#e6e6e6' : '#0c0c0c'}}>專案名稱</h3>
              <div className="dropdownBox">
                <select
                  className="dropdownMenu"
                  onChange={(e) => handleProjectSelect(e)}
                  value={projectId}
                >
                  {
                    projectList.map(ele => (
                      <option key={ele.id} value={ele.id}>{ele.projectName}</option>
                    ))
                  }
                </select>
              </div>
            </div>
          </div>
          <TableBlock isDarkMode={isDarkMode} hsExamineDataList={hsExamineDataList}>
            <div className="table" id="hsExamineTableId">
              <div className="caption">
                <div className="captionItem" id="tableThId">
                  <span><h4>檢查結果</h4></span>
                  <span><h4>合格</h4></span>
                  <span><h4>不合格</h4></span>
                  <span><h4>已完成</h4></span>
                  <span><h4>列管中</h4></span>
                </div>
                <ExportButton>
                  <span><ExportToExcelButton tableId ="hsExamineTableId" tableName="職業安全衛生"/></span>
                </ExportButton>
              </div>
              <div className="scrollContainer">
                {
                  hsExamineDataList.length > 0 ?
                    <div className="scrollBar">
                      <div className="itemTitleBox">
                        <div className="itemTitleGroup" id="tableTdId">
                          {hsExamineDataList.map((ele, index) => (
                            <div 
                              className="itemTitle" 
                              key={tools._UUID()} 
                              onMouseOver={(e) => handleMouseOver(e, ele.formName)}
                              onMouseOut={handleMouseOut}
                            >
                              <span>{ele.formName}</span>
                              {showTooltip && tooltipText === ele.formName && (
                                <Tooltip text={tooltipText} index={index}/>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="checkContent">
                        <div className="itemContentGroup" id="tableTdId">
                          {hsExamineDataList.map(ele => (
                            <div className="itemContent" key={tools._UUID()}><span>{ele.qualifiedNum}</span></div>
                          ))}
                        </div>
                      </div>
                      <div className="checkContent">
                        <div className="itemContentGroup">
                          {hsExamineDataList.map(ele => (
                            <div className="itemContent" key={tools._UUID()}><span>{ele.unqualifiedNum}</span></div>
                          ))}
                        </div>
                      </div>
                      <div className="checkContent">
                        <div className="itemContentGroup">
                          {hsExamineDataList.map(ele => (
                            <div className="itemContent" key={tools._UUID()}><span>{ele.hasImprovedNum}</span></div>
                          ))}
                        </div>
                      </div>
                      <div className="checkContent">
                        <div className="itemContentGroup">
                          {hsExamineDataList.map(ele => (
                            <div className="itemContent" key={tools._UUID()}><span>{ele.hasNotImprovedNum}</span></div>
                          ))}
                        </div>
                      </div>
                    </div>
                    : <NoDataText isDarkMode={isDarkMode}>當前專案無資料</NoDataText>
                }
              </div>
            </div>
          </TableBlock>
          <Display>
            <ChartContainer>
              <ChartBg isDarkMode={isDarkMode} >
                {
                  examineStatistic && examineStatistic.length > 0 ?
                    <div className='chart' id={stackedHorizontalBarChart.downloadAreaId}>
                      <div className='chartTitle'>職安衛稽查統計</div>
                      <D3StackedHorizontalBarChart data={examineStatistic} group={checkItems} option={stackedHorizontalBarChart}/>
                      <D3Swatches group={examineStatisticTotal.map(p => p.name)} option={stackedHorizontalBarChart}/>
                    </div>
                    : <NoDataText isDarkMode={isDarkMode}>當前專案無資料</NoDataText>
                }
                <ChartDownloadButton
                  chartId={stackedHorizontalBarChart.chartId}
                  downloadAreaId={stackedHorizontalBarChart.downloadAreaId}
                  chartName={stackedHorizontalBarChart.titleText}
                  dataLength={examineStatistic.length/2}
                />
                {/* <ChartDownloadBox>
                  <button className="chartDownloadBtn" onClick={() => downloadImage(stackedHorizontalBarChart.chartId, stackedHorizontalBarChart.downloadAreaId, stackedHorizontalBarChart.titleText, examineStatistic.length) }>Download</button>
                </ChartDownloadBox> */}
              </ChartBg>
              <PieChart isDarkMode={isDarkMode}>
                {
                  examineStatisticTotal && examineStatisticTotal.length > 0 ? 
                    <div className="ChartWrap" id={pieChartOption.downloadAreaId}>
                      <D3PieChart data={examineStatisticTotal} option={pieChartOption}/> 
                      <span>檢查結果</span>
                      <D3Swatches group={examineStatisticTotal.map(p => p.name)} option={pieChartOption}/>
                    </div>
                    : <NoDataText isDarkMode={isDarkMode}>當前專案無資料</NoDataText>
                }
                <ChartDownloadButton
                  chartId={pieChartOption.chartId}
                  downloadAreaId={pieChartOption.downloadAreaId}
                  chartName={pieChartOption.titleText}
                />
                {/* <ChartDownloadBox>
                  <button className="chartDownloadBtn" onClick={() => downloadImage(pieChartOption.chartId, pieChartOption.downloadAreaId, pieChartOption.titleText)}>Download</button>
                </ChartDownloadBox> */}
              </PieChart>
            </ChartContainer>
            <TableDisplay>
              <EvenTable isDarkMode={isDarkMode}>
                <h1>職災事件</h1>
                <div className="table">
                  <div className="thead">
                    <div className="tr">
                      <span>填報月份月</span>
                      <span>職災事件說明</span>
                    </div>
                  </div>
                  <div className="tbody">
                    {
                      occupationalAccidentList && occupationalAccidentList.length > 0 ?
                        occupationalAccidentList.map(ele => (
                          <div className="tr" key={tools._UUID()}>
                            <div className="td"><span>{ele.fillInMonth}</span></div>
                            <div className="td"><span>{ele.occupationalAccidentNote}</span></div>
                          </div>
                        ))
                        : <NoDataText isDarkMode={isDarkMode}>當前專案無資料</NoDataText>
                    }
                  </div>
                </div>
              </EvenTable>
              <CheckTable isDarkMode={isDarkMode}>
                <h1>勞動檢查</h1>
                <div className="table">
                  <div className="thead">
                    <div className="tr">
                      <span>檢查日期</span>
                      <span>是否罰款</span>
                      <span>罰款金額</span>
                    </div>
                  </div>
                  <div className="tbody">
                    {
                      laborInspectionList && laborInspectionList.length > 0 ?
                        laborInspectionList.map(ele => (
                          <div className="tr" key={tools._UUID()}>
                            <div className="td">
                              <span>{ele.inspectionDate}</span>
                            </div>
                            <div className="td inputRadio">
                              <label><input type="radio" value={ele.isFine} checked={ele.isFine === true} readOnly/> 是</label>
                              <label><input type="radio" value={ele.isFine} checked={ele.isFine === false} readOnly/> 否</label>
                            </div>
                            <div className="td">
                              <span>{ele.amount.toLocaleString()}</span>
                            </div>
                          </div>
                        ))
                        : <NoDataText isDarkMode={isDarkMode}>當前專案無資料</NoDataText>
                    }
                  </div>
                </div>
              </CheckTable>
            </TableDisplay>
          </Display>
        </section>
      </div>
    </>
  );
};
export default DashboardMain;