import { Route, useRouteMatch } from 'react-router-dom';
import MonthlyReport from "./MonthlyReport";
import ProjectPhoto from "./ProjectPhoto";
import MonthlyReportDetail from "./MonthlyReportDetail";
import ProjectPhotoDetail from "./ProjectPhotoDetail";

const CmgMgt = () => {
  let { path } = useRouteMatch();
  return (
    <>
      <div className="main">
        <Route path={`${path}/monthly-report`} component={MonthlyReport}></Route>
        <Route path={`${path}/monthly-detail/:projectId/:mainId`} component={MonthlyReportDetail}></Route>
        <Route path={`${path}/project-photo`} component={ProjectPhoto}></Route>
        <Route path={`${path}/project-photo-detail/:projectId`} component={ProjectPhotoDetail}></Route>
      </div>
    </>
  );
};

export default CmgMgt;