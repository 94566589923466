import styled from '@emotion/styled';
import { useState, useEffect, useContext } from 'react';
// import './../../style/DrawingManage.scss';

import { EkphrasisAPI } from './../assets/api';
import { notifyFailed, notifySuccess, notifyWarning } from './../assets/toaster';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';
import tools from './../assets/tools';

import { ReactComponent as NullValueIcon } from "./../../images/NullValue_Icon.svg";
import DragAndDrop from './../components/DragAndDrop';
import { ProjectContext } from './../contexts/ProjectContext';
import Pagination from './../assets/Pagination';

const SelectArea = styled.div`
  display: inline-block;
  width: 33%;
  margin-right: 2%;
  margin-bottom: 20px;
  min-width: 260px;
  border: 1px solid #CCC;
  box-sizing: border-box;
  padding: 2px 1px;
  @media (max-width:1085px) {
    width: 100%;
  }
`;

const TableArea = styled.div`
  display: inline-block;
  width: 65%;
  border: 1px solid #DDD;
  box-sizing: border-box;
  vertical-align: top;
  padding: 10px;
  @media (max-width:1085px) {
    width: 100%;
  }
`;

const OptionDiv = styled.div`
  font-Size: 16px;
  padding: 5px 8px;
  box-sizing: border-box;
  margin-bottom: 4px;
  box-shadow: 0 1px 2px 0px #CCC;
  min-height: 30px;
  :hover{
    background-color: #FDF1E6;
    cursor: pointer;
  }
`;

const CreateListDiv = styled.div`
  text-align: center;
  input{
    border: 1px solid #AAA;
    background-color: #FDFDFD;
    width: 100%;
    margin-bottom: 4px;
    height: 28px;
    padding: 0 5px;
    box-sizing: border-box;
  }
  button{
    opacity: 0.9;
  }
`;

const VersionSelect = styled.select`
  border: 1px solid #7B7979;
  border-radius: 3px;
  min-width: 10em;
  padding: 3px;
  margin: 0 10px 10px 0 ;
  height: 34px;
  option{
    padding: 2px;
  }
`;

const BtnBox = styled.div`
  display: inline-block;
  position: relative;
  top: -1px;
  button{
    height: 34px;
    margin: 0 10px 0 0;
  }
`;

const TxtInput = styled.input`
  width: 90%;
  max-width: 22em;
  height: 28px;
  border-radius: 3px;
  border: 1px solid #AAA;
  padding: 5px;
  box-sizing: border-box;
`;

const EditIcon = styled.span`
  color: #BBB;
  font-size: 13px;
  position: relative;
  top: -8px;
  left: 4px;
  margin-right: 3px;
  :hover{
    color: #888;
    cursor: pointer;
  }
`;

const PreviewIcon = styled.span`
  color: #BBB;
  font-size: 16px;
  position: relative;
  top: -6px;
  left: 5px;
  :hover{
    color: #888;
    cursor: pointer;
  }
`;

const IuputFileBox = styled.div`
  text-align: center;
`;

const InputFileLabel = styled.label`
  cursor: pointer;
  border-radius: 4px;
`;

const IuputFile = styled.input`
  border: 1px solid #AAA;
  padding: 5px;
  background-color: #FAFAFA;
`;

const NullValueBox = styled.div`
  text-align: center;
  padding: 48px 0 40px 0;
  p{
    color: #A1A1A1;
    margin: 8px 0;
    letter-spacing: 1px;
  }
`;

const IuputVersionBox = styled.div`
  display: inline-block;
  span{
    color: #666;
    position: relative;
    right: 24px;
    bottom: 3px;
    font-size: 12px;
    background-color: #EEE;
    width: 18px;
    height: 18px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    &:hover{
      background-color: #DDD;
    }
  }
  button{
    position: relative;
    right: -5px;
    bottom: 1px;
  }
`;
const IuputVersion = styled.input`
  border: 1px dashed #7B7979;
  border-radius: 3px;
  padding: 2px 5px;
  height: 28px;
  max-width: 8rem;
`;


const DrawingManage = () => {
  // let projectid = 1; //TODO: 動態帶projectId
  const { projectInfo } = useContext(ProjectContext);
  let projectid = projectInfo.id;

  const [categoryList, setCategoryList] = useState([]);
  const [selectCategory, setSelectCategory] = useState({});
  const [editionList, setEditionList] = useState([]);
  const [ekphrasisList, setEkphrasisList] = useState([]);
  const [origEkphrasisList, setOrigEkphrasisList] = useState([]);
  const [selectEdition, setSelectEdition] = useState(0);
  const [methodButton, setMethodButton] = useState(null);
  const [editionName, setEditionName] = useState('');
  const [isEditionCreating, setIsEditionCreating] = useState(false);

  const [uploadFiles, setUploadFiles] = useState([]);

  const [isSaving, setIsSaving] = useState(false);

  const [totalPages, setTotalPages] = useState(1); // 要到的資料總頁數
  const [currentPage, setCurrentPage] = useState(1); // 目前所在頁

  async function GetEkphrasisCategoryList(projectid) {
    try {
      let data = await EkphrasisAPI.GetEkphrasisCategoryList(projectid);
      data.forEach(ele => {
        ele._uuid = tools._UUID();
        ele.isExist = true; // Is this item already existed in database
        ele.isSelect = false;
      });
      setCategoryList(data);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }

  async function GetEkphrasisEditionList(categoryid) {
    try {
      let data = await EkphrasisAPI.GetEkphrasisEditionList(categoryid);
      data.forEach(ele => {
        ele._uuid = tools._UUID();
        ele.isExist = true; // Is this item already existed in database
      });
      setEditionList(data);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }

  async function SelectEkphrasisCategory(selectItem) {
    setSelectCategory(selectItem);
    setSelectEdition(0);
    setMethodButton(null);
    try {
      await GetEkphrasisEditionList(selectItem.id);
      setEkphrasisList([]);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }

  async function handleEkphrasisEditionSelect(e) {
    setSelectEdition(e.target.value);
    let slctEdition = editionList.find(ele => ele.id === Number(e.target.value)) || {};
    if (Number(slctEdition.editionStatus) === 2) {
      setMethodButton(`發佈`);
    } else if (Number(slctEdition.editionStatus) === 1) {
      setMethodButton(`停用`);
    } else if (Number(slctEdition.editionStatus) === 0) {
      setMethodButton(`重新發佈`);
    } else {
      setMethodButton(null);
    }
    if (Object.entries(slctEdition).length > 0) { //if edition is selected
      await GetEkphrasisList(e.target.value);
    } else {
      setEkphrasisList([]);
    }
    setUploadFiles([]);
  }

  async function GetEkphrasisList(editionid) {
    try {
      let data = await EkphrasisAPI.GetEkphrasisList({ ekphrasisEditionId:editionid, page: 1});
      data.ekphrasisList.forEach(ele => {
        ele._uuid = tools._UUID();
        ele.enable = true;
        ele.status = 0;
        ele.isExist = true; // Is this item already existed in database
        ele.isEditing = false; // Is this item being editted
      });
      setEkphrasisList(data.ekphrasisList);
      setOrigEkphrasisList(data.ekphrasisList);
      setTotalPages(data.pageInfo.pages);
      setCurrentPage(1);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }

  function handleCreateCategory() {
    setCategoryList([...categoryList, new Category({ _uuid: tools._UUID(), projectId: projectid, status: 1 })]);
  }

  function handleCategoryNameChange(e, el) {
    setCategoryList(categoryList.map(ele => (ele._uuid === el._uuid ? {...ele, categoryName: e.target.value} : ele)));
  }

  // 新增圖說大類名稱，並直接存進資料庫，重新get category
  async function handleCreateCategoryName(createCategory) {
    if (!createCategory.categoryName) {
      notifyFailed({Msg: '請填寫圖說名稱', isReload: false});
    } else {
      try {
        await EkphrasisAPI.ModifyEkphrasisCategory(createCategory);
        notifySuccess({Msg: '儲存成功', cbfunc: ''});
        await GetEkphrasisCategoryList(projectid);
        setSelectCategory({});
        setEditionList([]);
        setEkphrasisList([]);
        setUploadFiles([]);
      } catch (error) {
        notifyFailed({Msg: '發生錯誤', isReload: false});
      }
    }
  }

  function handleCancelCreateCategoryName(cancelCategory) {
    setCategoryList(categoryList.filter(ele => ele._uuid !== cancelCategory._uuid));
  }

  async function handleEditionCreate() {
    let editionCreate = {
      id: 0,
      ekphrasisCategoryId: selectCategory.id,
      edition: editionName,
      status: 1
    };
    if (!editionName) {
      notifyFailed({Msg: '請填寫版次名稱', isReload: false});
    } else {
      try {
        await EkphrasisAPI.ModifyEkphrasisEdition(editionCreate);
        notifySuccess({Msg: '儲存版次成功', cbfunc: ''});
        await GetEkphrasisEditionList(selectCategory.id);
        setIsEditionCreating(false);
        setEditionName('');
      } catch (error) {
        notifyFailed({Msg: '發生錯誤', isReload: false});
      }
    }
  }

  // 版次發佈、停用、重新發佈
  async function handleEditionPublish() {
    let editionStatus;
    let slctEdition = editionList.find(ele => ele.id === Number(selectEdition)) || {};
    if (Number(slctEdition.editionStatus) === 2) {
      editionStatus = 1;
    } else if (Number(slctEdition.editionStatus) === 1) {
      editionStatus = 0;
    } else if (Number(slctEdition.editionStatus) === 0) {
      editionStatus = 1;
    }
    let editionUpdate = {
      id: selectEdition,
      ekphrasisCategoryId: selectCategory.id,
      editionStatus,
      status: 2
    };
    try {
      await EkphrasisAPI.ModifyEkphrasisEdition(editionUpdate);
      notifySuccess({Msg: '版次更新成功', cbfunc: ''});
      await GetEkphrasisEditionList(selectCategory.id);
      if (Number(slctEdition.editionStatus) === 2) {
        setMethodButton(`停用`);
      } else if (Number(slctEdition.editionStatus) === 1) {
        setMethodButton(`重新發佈`);
      } else if (Number(slctEdition.editionStatus) === 0) {
        setMethodButton(`停用`);
      } else {
        setMethodButton(null);
      }
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }

  function handleEditEkphrasisName(e, el) {
    setEkphrasisList(ekphrasisList.map(ele => (ele._uuid === el._uuid ? {...ele, ekphrasisName: e.target.value} : ele)));
  }

  function handleEkphrasisNameUpdate(el) {
    setEkphrasisList(ekphrasisList.map(ele => (ele._uuid === el._uuid ? {...ele, status: 2, isEditing: true} : ele)));
  }

  function handleCancelEkphrasisNameUpdate(el) {
    setEkphrasisList(ekphrasisList.map(ele => (ele._uuid === el._uuid ? origEkphrasisList.find(ele1 => ele1._uuid === el._uuid) : ele)));
  }

  const handleEkphrasisDelete = (el) => {
    notifyWarning({ Msg: <DeleteConfirm el={el}/> });
  };

  const DeleteConfirm = ({ closeToast, el }) => (
    <div>
      刪除圖說？
      <button className="btnS btnRed" onClick={() => DeleteEkphrasis(el)}>確定刪除</button>
      <button className="btnS btnWhite" onClick={closeToast}>取消</button>
    </div>
  );

  const DeleteEkphrasis = (el) => {
    setEkphrasisList(ekphrasisList.map(ele => (ele._uuid === el._uuid ? {...ele, status: 3} : ele)));
  };

  // upload by input button
  function handleEkphrasisUpload(e) {
    if (e.target.files && e.target.files){
      let filelist = [...e.target.files];
      if (filelist.find(ele => tools.GetFileExtension(ele.name) !== 'pdf')) {
        notifyFailed({Msg: '請檢查檔案上傳副檔名。', isReload: false});
      } else {
        let fileUpload = filelist.map(ele => {
          return {
            createDate: tools.GetFormatNowDate('YYYY-MM-DDTHH:mm:ss'),
            createId: '',
            ekphrasisEdtionID: selectEdition,
            ekphrasisName: ele.name.substring(0, ele.name.lastIndexOf('.')),
            fileUrl: '',
            id: 0,
            name_zhtw: '',
            file: ele,
            status: 1,
            enable: true,
            _uuid: tools._UUID()
          };
        });
        setUploadFiles([...uploadFiles, ...fileUpload]);
      }
    }
  }

  // upload by drag n drop
  function handleDrop(f) {
    let fileList = [...uploadFiles];
    let extCheck = 0;
    for (let i = 0; i < f.length; i++) {
      if (tools.GetFileExtension(f[i].name) !== 'pdf') {
        extCheck += 1;
      }
    }
    if (extCheck > 0) {
      notifyFailed({Msg: '請檢查檔案上傳副檔名。', isReload: false});
    } else {
      for (let i = 0; i < f.length; i++) {
        if (!f[i].name) return;
        fileList.push({
          createDate: tools.GetFormatNowDate('YYYY-MM-DDTHH:mm:ss'),
          createId: '',
          ekphrasisEdtionID: selectEdition,
          ekphrasisName: f[i].name.substring(0, f[i].name.lastIndexOf('.')),
          fileUrl: '',
          id: 0,
          name_zhtw: '',
          file: f[i],
          status: 1,
          enable: true,
          _uuid: tools._UUID()
        });
      }
      setUploadFiles(fileList);
    }
  }

  // rename ekphrasis
  function handleEditUploadEkphrasisName(e, el) {
    setUploadFiles(uploadFiles.map(ele => (ele._uuid === el._uuid ? {...ele, ekphrasisName: e.target.value} : ele)));
  }

  // delete upload ekphrasis
  const handleUploadEkphrasisDelete = (el) => {
    notifyWarning({ Msg: <DeleteConfirmUpload el={el}/> });
  };

  const DeleteConfirmUpload = ({ closeToast, el }) => (
    <div>
      刪除上傳圖說？
      <button className="btnS btnRed" onClick={() => DeleteUploadEkphrasis(el)}>確定刪除</button>
      <button className="btnS btnWhite" onClick={closeToast}>取消</button>
    </div>
  );

  const DeleteUploadEkphrasis = (el) => {
    setUploadFiles(uploadFiles.filter(ele => ele._uuid !== el._uuid));
  };

  // submit ekphrasis modify
  const ModifyEkphrasis = async () => {
    setIsSaving(true);
    let formData = new FormData();
    let modifyEkphrasisList = ekphrasisList.concat(uploadFiles);
    for (let i = 0; i < modifyEkphrasisList.length; i++) {
      let ele = modifyEkphrasisList[i];
      formData.append(`modifyEkphrasisList[${i}].id`, ele.id);
      formData.append(`modifyEkphrasisList[${i}].ekphrasisEdtionID`, ele.ekphrasisEdtionID);
      formData.append(`modifyEkphrasisList[${i}].ekphrasisName`, ele.ekphrasisName);
      formData.append(`modifyEkphrasisList[${i}].file`, ele.status === 1 ? ele.file : '');
      formData.append(`modifyEkphrasisList[${i}].status`, ele.status);
      formData.append(`modifyEkphrasisList[${i}].enable`, ele.enable);
    }
    try {
      await EkphrasisAPI.ModifyEkphrasis(formData);
      notifySuccess({Msg: '儲存成功', cbfunc: ''});
      await GetEkphrasisList(selectEdition);
      setUploadFiles([]);
      setIsSaving(false);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
      setIsSaving(false);
    }
  };

  // 取得目前選擇版次狀態
  function EditionStatus(slctEdtn) {
    let slctEdition = editionList.find(ele => ele.id === Number(slctEdtn));
    if (slctEdition) {
      return Number(slctEdition.editionStatus);
    }
    return null;
  }

  useEffect(() => {
    GetEkphrasisCategoryList(projectid);
  }, []);

  function Category ({_uuid=0, id=0, projectId=0, categoryName='', status=0}) {
    this._uuid = _uuid;
    this.id = id;
    this.projectId = projectId;
    this.categoryName = categoryName;
    this.status = status;
  }

  return (
    <>
      <div className="bumper_rail"></div>
      <div className="commontitle">
        <h1>圖面管理</h1>
      </div>
      <div className="sharebox" style={{ padding: '20px 15px', }}>
        <SelectArea>
          {
            categoryList.map(ele => (
              ele.isExist ?
                <OptionDiv key={ele._uuid} style={{ backgroundColor: selectCategory._uuid === ele._uuid ? '#E3E3E3' : '' }} onClick={() => SelectEkphrasisCategory(ele)}>{ele.categoryName}</OptionDiv> :
                <OptionDiv key={ele._uuid}>
                  <CreateListDiv>
                    <input type="text" placeholder="新增圖說名稱" value={ele.categoryName} onChange={(evt) => handleCategoryNameChange(evt, ele)}/>
                    <button className="btnM btnBlue" onClick={() => handleCreateCategoryName(ele)} style={{ marginTop: '1px' }}>新增</button>
                    <button className="btnM btnRed" onClick={() => handleCancelCreateCategoryName(ele)} style={{ marginTop: '1px' }}>取消</button>
                  </CreateListDiv>
                </OptionDiv>
            ))
          }
          {
            categoryList.filter(ele => !ele.isExist).length !== 1 ?
              <button className="btnM btnWhite" style={{ width: 'calc(100% - 6px)', margin:'3px', }} onClick={handleCreateCategory}>+</button> :
              null
          }
        </SelectArea>
        <TableArea>
          {
            Object.entries(selectCategory).length > 0 ?
              ( <div>
                {/* <VersionSelect name="" id="" onChange={(evt) => handleEkphrasisEditionSelect(evt, ele)}> */}
                <VersionSelect name="" id="" onChange={handleEkphrasisEditionSelect} value={selectEdition}>
                  <option value=''>選擇版次</option>
                  {
                    editionList.map(ele => (<option key={ele._uuid} value={ele.id}>{ele.edition}</option>))
                  }
                </VersionSelect>
                <BtnBox>
                  {methodButton && <button className="btnM btnWhite" onClick={handleEditionPublish}>{methodButton}</button>}
                  <button className="btnM btnWhite" onClick={() => setIsEditionCreating(true)}>新增版次</button>
                </BtnBox>
                {
                  isEditionCreating ?
                    <IuputVersionBox>
                      <IuputVersion placeholder="新增版次名稱" value={editionName} onChange={(e)=> setEditionName(e.target.value)}/>
                      {/* <span><i className="fas fa-times"></i></span> */}
                      <button className="btnM btnBlue" onClick={handleEditionCreate}>確認</button>
                      <button className="btnM btnRed" onClick={() => setIsEditionCreating(false)}>取消</button>
                    </IuputVersionBox> :
                    null
                }
                {
                  Number(selectEdition) > 0 ?
                    <>
                      <div className="table">
                        <table>
                          <tbody>
                            <tr>
                              <td>檔案名稱</td>
                              <td>上傳者</td>
                              <td>上傳時間</td>
                              <td></td>
                            </tr>
                            {
                              ekphrasisList.filter(el => el.status !== 3).map(ele => (
                                <tr key={ele._uuid}>
                                  <td>
                                    {
                                      ele.isEditing ?
                                        <>
                                          <TxtInput value={ele.ekphrasisName} onChange={(evt) => handleEditEkphrasisName(evt, ele)}/>
                                          <button className="btnS btnRed" onClick={() => handleCancelEkphrasisNameUpdate(ele)}>取消</button>
                                        </> :
                                        <>
                                          {ele.ekphrasisName}
                                          {
                                            EditionStatus(selectEdition) === 2 ?
                                              <EditIcon title="編輯檔案名稱" onClick={() => handleEkphrasisNameUpdate(ele)}><i className="far fa-edit"></i></EditIcon> :
                                              null
                                          }
                                          <PreviewIcon title="預覽圖樣"><a href={ele.fileUrl} target="_blank" rel="noreferrer noopener" style={{ color: '#BBB' }}><i className="far fa-image"></i></a></PreviewIcon>
                                        </>
                                    }
                                  </td>
                                  <td>{ele.createId}</td>
                                  <td>{moment(ele.createDate).format('YYYY-MM-DD')}</td>
                                  <td>
                                    {
                                      EditionStatus(selectEdition) === 2 ?
                                        <button className="btnM btnRed" onClick={() => handleEkphrasisDelete(ele)}>刪除</button> :
                                        null
                                    }
                                  </td>
                                </tr>
                              ))
                            }
                            {
                              uploadFiles.map(ele => (
                                <tr key={ele._uuid}>
                                  <td>
                                    <TxtInput value={ele.ekphrasisName} onChange={(evt) => handleEditUploadEkphrasisName(evt, ele)}/>
                                  </td>
                                  <td>{ele.createId}</td>
                                  <td>{moment(ele.createDate).format('YYYY-MM-DD')}</td>
                                  <td>
                                    <button className="btnM btnRed" onClick={() => handleUploadEkphrasisDelete(ele)}>刪除</button>
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                      {
                        EditionStatus(selectEdition) === 2 ?
                          <>
                            <IuputFileBox>
                              <DragAndDrop handleDrop={handleDrop} acceptExt={['pdf']}>
                                <div style={{height: 250, marginTop: '1em'}}>
                                  <InputFileLabel className="btnM btnWhite">
                                    <IuputFile type="file" multiple="multiple" accept='.pdf' onChange={handleEkphrasisUpload} style={{ display: 'none'}}/>
                                    <i className="far fa-image"></i> 上傳圖說
                                  </InputFileLabel>
                                ，或拖曳檔案至此上傳(僅限pdf檔案)。
                                </div>
                              </DragAndDrop>
                            </IuputFileBox>
                            <div style={{ textAlign: 'right', margin:'40px 0', }}>
                              <button className="btnL btnBlue" onClick={ModifyEkphrasis} disabled={isSaving}>
                                {
                                  isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '儲存'
                                }
                              </button>
                            </div>
                          </> :
                          null
                      }
                      {/* <div style={{ background: '#E3E3E3', }}>(pagination)(1)(2)(3)</div> */}
                      <Pagination
                        setTotalPages={setTotalPages}
                        totalPages={totalPages}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        setList={setEkphrasisList}
                        apiFunc={'GetEkphrasisList'}
                        ekphrasisEditionId={selectEdition}
                      />
                    </> :
                    <NullValueBox>
                      <NullValueIcon />
                      <p>請選擇版次</p>
                    </NullValueBox>
                }
              </div>) :
              <NullValueBox>
                <NullValueIcon />
                <p>請選擇圖說</p>
              </NullValueBox>
          }
        </TableArea>
      </div>
      <ToastContainer />
    </>
  );
};
export default DrawingManage;