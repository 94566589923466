import React, { useEffect, useRef, useState, useContext } from "react";
import styled from '@emotion/styled';
import "./../../style/Form.scss";
import "./../../style/FormDetail.scss";
import moment from 'moment';
import { notifyFailed, notifySuccess, notifyWarning } from './../assets/toaster';

import tools from './../assets/tools';
import AttachErrorNotify from "./AttachErrorNotify/AttachErrirNotify";
import { AccountAPI, FormAPI, DocumentAPI } from "../assets/api";
import { ProjectContext } from './../contexts/ProjectContext';


const FormTitle = styled.h1`
  font-size: 1.4rem;
  font-weight: 700;
  margin: 8px;
`;

const DateInput = styled.input`
  padding: 0 4px;
  box-sizing: border-box;
  height: 32px;
  border-radius: 3px;
  border: 1px solid #999;
  // min-width: 5em;
  max-width: 18em;
  margin: 0 3px;
  @media (max-width: 450px) {
    width: 100%;
  }
`;

const FakeHr = styled.div`
  width: 92%;
  height: 2px;
  background-color: #DDD;
  margin: 40px 4% 40px 4%;
`;

const TableBox = styled.div`
  padding: 0 10px;
`;

const SpotCheckDescTextarea = styled.textarea`
  border: 1px solid #999;
  width: 100%;
  // @media (max-width: 768px) {
  //   width: 12rem;
  // }
  // @media (max-width: 450px) {
  //   width: 6rem;
  // }
`;


const SignetSelect = styled.select`
  border: 1px solid #999;
  border-radius: 3px;
  padding: 3px;
  margin: 0 3px;
  height: 30px;
  max-width: 87%;
  option{
    padding: 2px;
  }
`;

const Keyboard = styled.div`
  width: 50%;
  padding: 10px 6px;
  border-radius: 10px;
  border: 1px solid #AAA;
  margin: 0 10px;
  box-sizing: border-box;
  button{
    width: 36px;
    height: 32px;
    box-shadow: 0 1px 2px 0px #ccc;
    border: 1px solid #7B797999;
    margin: 3px;
    &:active {
      transform: translateY(1px);
      box-shadow: 0 0px 2px 0px #ccc;
    }
  }
  @media (max-width: 450px) {
    width: calc(100% - 20px);
  }
`;

const HistoricalRecord = styled.div`
  background-color: #EEE;
  padding: 10px 15px;
  margin: 10px;
`;

const InputReturnReason = styled.div`
  background-color: #EEE;
  padding: 20px ;
  margin: 10px;
  textarea{
    margin: 10px 0;
    width: 100%;
    border: 1px solid #BBB;
  }
  .btnbox{
    text-align: right;
    margin: 10px 0 0 0;
  }
`;


// 安衛表單
const SafetyHealthCheckRecord = (props) => {
  // let projectId = 1; //TODO:動態取得projectId
  const { projectInfo } = useContext(ProjectContext);
  let projectId = projectInfo.id;

  const [isSaving, setIsSaving] = useState(false);
  const [isPDFExporting, setIsPDFExporting] = useState(false);

  const [formContent, setFormContent] = useState({
    ...props.formContent,
    itemList: props.formContent.itemList.map(ele => {
      return props.formContent.id > 0 ?
        ({
          ...ele,
          note: ele.note || '',
          hsExamineMainID: props.formContent.id,
          itemNameCursorPos: 0,
          criterionCursorPos: 0,
          noteCursorPos: 0,
          status: 2
        }) :
        ({
          ...ele,
          hsExamineMainID: 0,
          result: 1,
          note: '',
          itemNameCursorPos: 0,
          criterionCursorPos: 0,
          noteCursorPos: 0,
          status: 1
        });
    }),
    _uuid: tools._UUID(),
    vendor: props.formContent.id > 0 ? props.formContent.vendor : '',
    formId: props.formContent.formId,
    position: props.formContent.id > 0 ? props.formContent.position || '' : '',
    location: props.formContent.id > 0 ? props.formContent.location || '' : '',
    examineDate: props.formContent.id > 0 ? (props.formContent.examineDate ? moment(props.formContent.examineDate).format('YYYY-MM-DD') : '') : '',
    formStatus: props.formContent.id > 0 ? props.formContent.formStatus : 0, // 表單狀態,0:草稿,1:審核中,2:已結案,3:已刪除,4:真刪除
    userStamp: props.formContent.id > 0 ? {} : {},
    status: props.formContent.id > 0 ? 2 : 1,
    editable: props.formContent.id > 0 ? props.formContent.formStatus === 0 : true,
  });

  const [abnormalForm, setAbnormalForm] = useState({
    ...props.abnormalForm,
    projectFormId: formContent.projectFormId,
    mainId:  props.formContent.id > 0 ? props.formContent.id : 0,
    docId: props.abnormalForm.docId || '',
    vendor: props.abnormalForm.vendor || '',
    ExamineDate: props.abnormalForm.examineDate ? moment(props.abnormalForm.examineDate).format('YYYY-MM-DD') : '',
    improveDeadline: props.abnormalForm.improveDeadline ? moment(props.abnormalForm.improveDeadline).format('YYYY-MM-DD') : '',
    flawNote: props.abnormalForm.flawNote || '',
    status: props.abnormalForm.id > 0 ? 2 : 1,
    photoList: props.abnormalForm.id > 0 ? props.abnormalForm.photoList.map(ele => ({...ele, _uuid: tools._UUID(), status: 2, mainId: props.formContent.id, isExist: true})) : [],
    editable: props.abnormalForm.id > 0 ? props.abnormalForm.formStatus === 0 : true
  });

  const handleInputChange = (e) => {
    setFormContent({
      ...formContent,
      [e.target.name]: e.target.value
    });
  };

  const handleItemInputChange = (e, el, ix) => {
    setFormContent({
      ...formContent,
      itemList: formContent.itemList.map(ele => (
        ele.id === el.id ? {...ele, [e.target.name]: e.target.value, noteCursorPos: noteRef.current[ix].selectionStart} : ele
      ))
    });
  };

  const handleItemCheckChange = (e, el) => {
    setFormContent({
      ...formContent,
      itemList: formContent.itemList.map(ele => (ele.id === el.id ? {...ele, result: e.target.value} : ele))
    });
  };

  const keyboardSignList = ['□', '■' ,'△' ,'▲' ,'○' ,'◉' ,'℃' ,'Ω' ,'φ' ,'☑', '×', '%', 'ψ', '‰', '±', '≧', 'mm', 'cm', 'cm²', 'm', 'm³'];

  const [editingCell, setEditingCell] = useState({});
  const noteRef = useRef([]);

  const getCursorPos = (name, el, ix, e) => {
    setEditingCell({
      row: ix,
      col: name
    });
    switch (name) {
    case 'note':
      noteRef.current[ix].onmouseup = function () {
        setFormContent({
          ...formContent,
          itemList: formContent.itemList.map(ele => (
            ele.id === el.id ? {...ele, [e.target.name]: e.target.value, noteCursorPos: noteRef.current[ix].selectionStart} : ele
          ))
        });
      };
      break;
    default:
      break;
    }
  };

  const InsertMark = (e, ix, name) => {
    switch (name) {
    case 'note':
      if (noteRef.current[ix]) {
        let prefix = noteRef.current[ix].value.substring(0, formContent.itemList[ix].noteCursorPos);
        let suffix = noteRef.current[ix].value.substring(formContent.itemList[ix].noteCursorPos);
        setFormContent({
          ...formContent,
          itemList: formContent.itemList.map((ele, idx) => (
            idx === ix ? {...ele, note: prefix + e.target.value + suffix} : ele
          ))
        });
      }
      break;
    default:
      break;
    }
  };

  const handleSave = async({ isSubmit }) => {
    // 檢查網路狀態
    if (!window.navigator.onLine) {
      notifyFailed({Msg: '網路未連線', isReload: false});
      return;
    }

    if (!formContent.formId) {
      notifyFailed({Msg: '請填寫「安衛表單」表單編號', isReload: false});
      return;
    }
    setIsSaving(true);
    let formData = new FormData();
    formData.append(`id`, formContent.id || 0);
    formData.append(`projectFormId`, formContent.projectFormId);
    formData.append(`vendor`, formContent.vendor);
    formData.append(`formId`, formContent.formId);
    formData.append(`position`, formContent.position);
    formData.append(`location`, formContent.location);
    formData.append(`examineDate`, formContent.examineDate);
    formData.append(`formStatus`, formContent.formStatus);
    formData.append(`status`, formContent.status);
    // 抽驗項目
    for (let i = 0; i < formContent.itemList.length; i++) {
      let ele = formContent.itemList[i];
      formData.append(`itemList[${i}].id`, props.formContent.id > 0 ? ele.id : 0);
      formData.append(`itemList[${i}].hsExamineMainID`, ele.hsExamineMainID);
      formData.append(`itemList[${i}].itemId`, props.formContent.id > 0 ? ele.itemId : ele.id);
      formData.append(`itemList[${i}].itemName`, ele.itemName);
      formData.append(`itemList[${i}].result`, ele.result);
      formData.append(`itemList[${i}].note`, ele.note || '');
      formData.append(`itemList[${i}].hsItemCategoryID`, ele.hsItemCategoryID);
      formData.append(`itemList[${i}].sort`, ele.sort);
      formData.append(`itemList[${i}].status`, ele.status);
    }
    try {
      let mainId = await FormAPI.ModifyHSForm(formData);
      notifySuccess({Msg: '儲存成功 (安衛表單)', cbfunc: ''});
      setIsSaving(false);
      if (formContent.itemList.filter(ele => Number(ele.result) === 2).length > 0) {
        await handleAbnormalSave(mainId);
        setIsSaving(false);
      }
      if (!isSubmit) {
        // 儲存完關閉頁籤，並再打開
        let newFormList = await setFormTabListPromise(props.formTabList.filter(ele => ele._uuid !== props.uuid));
        let newFormContent = await GetHSExamineInfo(mainId);
        let editForm = {
          id: newFormContent.id,
          _uuid: tools._UUID(),
          formName: newFormContent.formId,
          moduleId: 4, // 安衛: 4
          formContent: newFormContent,
          abnormalForm: await GetAbnormalInfo({ projectId, abnormalId: newFormContent.abnormalId || 0 })
        };
        let data = await setFormTabListPromise([...newFormList, editForm]);
        openFormTab(data, editForm._uuid);
      }
    } catch (error) {
      notifyFailed({Msg: '發生錯誤，表單儲存失敗 (安衛表單)', isReload: false});
      if (error.response.data === "此表單編號已存在。") {notifyFailed({ Msg: error.response.data, isReload: false });}
      setIsSaving(false);
    }
  };

  const openFormTab = (list, uuid) => {
    let tabToOpen = list.find(ele => ele._uuid === uuid);
    if (tabToOpen) props.setFormTabSelected(tabToOpen);
  };

  const setFormTabListPromise = (formList) => {
    return new Promise(resolve => {
      props.setFormTabList(formList);
      resolve(formList);
    });
  };

  // 異常表單儲存
  const handleAbnormalSave = async(mainId) => {
    let formData = new FormData();
    formData.append(`id`, abnormalForm.id || 0);
    formData.append(`projectId`, projectId);
    formData.append(`projectFormId`, formContent.projectFormId);
    formData.append(`mainId`, mainId);
    formData.append(`docId`, abnormalForm.docId);
    formData.append(`vendor`, abnormalForm.vendor);
    formData.append(`ExamineDate`, abnormalForm.ExamineDate );
    formData.append(`improveDeadline`, abnormalForm.improveDeadline);
    formData.append(`flawNote`, abnormalForm.flawNote);
    formData.append(`formStatus`, abnormalForm.formStatus);
    formData.append(`status`, abnormalForm.formStatus === 2 ? 0 : abnormalForm.status);
    // 照片上傳
    for (let i=0; i < abnormalForm.photoList.length; i++) {
      let ele = abnormalForm.photoList[i];
      formData.append(`photoList[${i}].id`, ele.id);
      formData.append(`photoList[${i}].projectFormId`, formContent.projectFormId);
      formData.append(`photoList[${i}].mainId`, mainId);
      formData.append(`photoList[${i}].file`, ele.file);
      formData.append(`photoList[${i}].content`, ele.content || '');
      formData.append(`photoList[${i}].isiOS`, ele.isiOS);
      formData.append(`photoList[${i}].lastModifyDate`, ele.lastModifyDate);
      formData.append(`photoList[${i}].status`, ele.status);
    }
    try {
      await FormAPI.ModifyANForm(formData);
      notifySuccess({Msg: '儲存成功 (異常(不合格)通知暨改善表)', cbfunc: ''});
    } catch (error) {
      notifyFailed({Msg: '發生錯誤，表單儲存失敗 (異常(不合格)通知暨改善表)', isReload: false});
    }
  };

  // 檢查欄位必填
  const FormValidate = () => {
    let isValid = true;
    if (!formContent.vendor ||
        !formContent.formId ||
        !formContent.position ||
        !formContent.location ||
        !formContent.examineDate
    ) {
      isValid = false;
    }

    // 若抽查結果為'不合格'，則其他欄位為必填
    formContent.itemList.forEach(ele => {
      if (Number(ele.result) === 2 && (!ele.note.trim())) {
        isValid = false;
      }
    });

    // 若抽查結果沒有不合格，則檢查是否有照片 => 安衛表單沒有照片
    // if (formContent.itemList.filter(ele => Number(ele.result) === 2).length === 0) {
    //   if (formContent.photoList.filter(ele => ele.status !== 3).length === 0) {
    //     return false;
    //   }
    // }
    return isValid;
  };

  // 檢查異常表單欄位必填
  const AbnormalValidate = () => {
    let isValid = true;
    if (!abnormalForm.docId ||
      !abnormalForm.ExamineDate ||
      !abnormalForm.vendor ||
      !abnormalForm.improveDeadline ||
      !abnormalForm.flawNote ||
      abnormalForm.photoList.filter(ele => ele.status !== 3).length === 0
    ) {
      isValid = false;
    }

    abnormalForm.photoList.filter(el => el.status !== 3).forEach(ele => {
      if (!ele.content) {
        isValid = false;
      }
    });
    return isValid;
  };

  async function GetHSExamineInfo(hsExamineId) {
    try {
      let data = await FormAPI.GetHSExamineInfo(hsExamineId);
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  // 取得異常表資料
  async function GetAbnormalInfo({projectId, abnormalId}) {
    try {
      let data = await FormAPI.GetAbnormalInfo({projectId, abnormalId});
      return data;
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }

  const handleStampChange = (e) => {
    setFormContent({
      ...formContent,
      userStamp: formContent.userStampList.find(ele => ele.userStampID === Number(e.target.value)) || {}
    });
  };

  const handleSignOff = async (action) => {
    // 檢查網路狀態
    if (!window.navigator.onLine) {
      notifyFailed({Msg: '網路未連線', isReload: false});
      return;
    }

    // 檢查必填欄位
    let formValidate = FormValidate();
    if (!formValidate && action!=2) {
      notifyFailed({Msg: '請檢查「施工抽查紀錄表」是否有未填項目', isReload: false});
      return;
    }
    // 若有不合格抽驗項目，需將異常表單資料填完
    if (formContent.itemList.filter(ele => Number(ele.result) === 2).length > 0) {
      let abnormalValidate = AbnormalValidate();
      if (!abnormalValidate) {
        notifyFailed({Msg: '請檢查「異常(不合格)通知暨改善表」是否有未填項目', isReload: false});
        return;
      }
      // 若有異常表單，異常表單之formStatus需為2才能送出
      if (abnormalForm.formStatus !== 2) {
        notifyFailed({Msg: '請確認已完成「異常(不合格)通知暨改善表」流程', isReload: false});
        return;
      }
    }

    // 要送出簽核需有影像章
    if (!formContent.userStamp.userStampID) {
      if(action===1 || action===0){
        notifyFailed({Msg: '請選擇影像章', isReload: false});
        return;
      }
    }

    // 只有在帳號為填寫表單本人，且表單狀態為草稿時時，可以送出審核同時儲存表單
    if (formContent.signOffList[0].userName === userInfo.userName && formContent.formStatus === 0) {
      await handleSave({ isSubmit: true });
    }

    setIsSaving(true);
    let modifyFormSignOff = {
      id: formContent.signOffList.find(ele => ele.signOffTag === true).id,
      userStampID :formContent.userStamp.userStampID,
      action,
      module: 4 // 安衛: 4
    };
    try {
      await FormAPI.PostSignOff(modifyFormSignOff);
      notifySuccess({Msg: '送出成功', cbfunc: ''});
      setIsSaving(false);
      await setFormTabListPromise(props.formTabList.filter(ele => ele._uuid !== props.uuid));
      // 開啟列表
      props.setIsListOn(true);
      PostProjectHSFormList();
      PostProjectHSFormNumList();
      props.GetModuleList({ isModuleId: true });
      props.GetProjectFormList();
    } catch (error) {
      notifyFailed({Msg: '發生錯誤，簽核送出失敗', isReload: false});
      setIsSaving(false);
    }
  };
  const handleReturnSignOff = async () => {
    notifyWarning({ Msg: <ReturnConfirm /> });
  };

  const ReturnConfirm = ({ closeToast }) => (
    <div>
      確定退回表單？若有【異常(不合格)通知暨改善表】將會同時退回，並刪除【異常追蹤】內容
      <button className="btnS btnRed" onClick={() => ReturnSignOff()}>確定退回</button>
      <button className="btnS btnWhite" onClick={closeToast}>取消</button>
    </div>
  );

  const ReturnSignOff = async () => {
    if (!formContent.returnNote) {
      notifyFailed({ Msg: '請確認是否有填寫退回理由。', isReload: false });
      return;
    }
    setIsSaving(true);
    try {
      console.log({
        projectId: projectId,
        moduleId: 4,
        formId: formContent.projectFormId,
        mainId: formContent.id,
        note: formContent.returnNote || '',
        action: 3,
      });
      await FormAPI.ReturnSignOff({
        projectId: projectId,
        moduleId: 4,
        formId: formContent.projectFormId,
        mainId: formContent.id,
        note: formContent.returnNote || '',
        action: 3,
      });

      // 若有異常表單
      if (abnormalForm.id > 0) {
        // 同時退回異常表單
        await FormAPI.ReturnSignOff({
          projectId: projectId,
          moduleId: 5,
          formId: formContent.projectFormId,
          mainId: abnormalForm.id,
          note: formContent.returnNote || '',
          action: 3,
        });

        // 退回異常同時刪除異常追蹤
        let formData = new FormData();
        formData.append(`id`, abnormalForm.id);
        formData.append(`status`, 3);
        await FormAPI.ModifyAbnormalTrack({ projectId, modifyAbnormalTrack: formData });
        notifySuccess({ Msg: '表單(包含異常表單)退回成功', cbfunc: '' });
      }
      setIsSaving(false);

      // 退回後關閉頁籤
      props.setFormTabList(props.formTabList.filter(ele => ele._uuid !== props.uuid));
      props.setFormStatus(0);
    } catch (error) {
      setIsSaving(false);
      notifyFailed({ Msg: '發生錯誤，表單退回失敗，請確認是否有填寫退回理由。', isReload: false });
    }
  };
  // 取得安衛表單 清單(取得送出表單模組及表單類型，並顯示狀態為審核中)
  async function PostProjectHSFormList() {
    try {
      let searchVM = {
        formId: "",
        vendor: "",
        location: "",
        position: "",
        examineSDate: "",
        examineEDate: "",
        page: 1,
        projectFormId: formContent.projectFormId,
        formStatus: 1
      };
      let data = await FormAPI.PostProjectHSFormList(searchVM);
      data.formMainHSSimpleList.forEach(ele => {
        ele._uuid = tools._UUID();
      });
      props.setProjectHSFormList(data.formMainHSSimpleList);
      props.setProjectHSIsNCO(data.isNCO);
      props.setTotalPages(data.pageInfo.pages);
      props.setFormStatus(1);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }

  // 取得安衛表單 各狀態筆數清單
  const PostProjectHSFormNumList = async () => {
    try {
      let searchVM = {
        formId: "",
        vendor: "",
        location: "",
        position: "",
        examineSDate: "",
        examineEDate: "",
        page: 1,
        projectFormId: formContent.projectFormId,
        formStatus: 1
      };
      let data = await FormAPI.PostProjectHSFormNumList(searchVM);
      props.setFormStatusNum(data);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  };

  // user info to check sigon off process
  const [userInfo, setUserInfo] = useState({
    'userName': '',
    'name_ZHTW': '',
    'name_EN': '',
    'email': '',
    'phoneNumber': '',
    'projectRoles': []
  });

  async function GetUserInfo() {
    try {
      let data = await AccountAPI.GetUserInfo();
      setUserInfo(data);
    } catch (error) {
      notifyFailed({Msg: '無法取得使用者資訊', isReload: false});
    }
  }

  useEffect(() => {
    GetUserInfo();
  }, []);

  // 還原表單
  const handleRestoreForm = async() => {
    try {
      await FormAPI.RestoreForm({
        id: formContent.id,
        module: 4
      });
      notifySuccess({Msg: '表單還原成功', cbfunc: ''});
      // 還原後關閉頁籤
      props.setFormTabList(props.formTabList.filter(ele => ele._uuid !== props.uuid));
      props.setFormStatus(0);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤，表單還原失敗', isReload: false});
    }
  };

  // 匯出PDF
  const ExportPDF = async() => {
    setIsPDFExporting(true);
    await CreateHSPdf();
    setIsPDFExporting(false);
  };
  const CreateHSPdf = async() => {
    try {
      let data = await DocumentAPI.CreateHSPdf(formContent.id);
      tools.AjaxFileDownload({ rs: data, filename: `安衛表單 - ${formContent.formId}`, fileType: 'pdf'});
    } catch (error) {
      notifyFailed({Msg: '發生錯誤，匯出文件失敗', isReload: false});
    }
  };

  return (
    <>
      <div className="sharebox">
        {
          formContent.formStatus === 3 && (<div className="btn_box"><button className="btnL btnBlue" onClick={handleRestoreForm}>還原此表單</button></div>)
        }
        {
          formContent.formStatus === 2 && userInfo.projectRoles.find(pr => pr.projectID === projectId)?.roleName === '管理者' && (
            <div className="btn_box"><a className="btnL btnBlue" href="#ReturnReason">退回重新審核</a></div>
          )
        }
        <FormTitle>{formContent.projectFormName}</FormTitle>

        <div className="form form_loose" style={{ paddingBottom: '10px', }}>
          {/* 一行 */}
          <div className="form_half">
            <div className="form_title">
              表單編號：
            </div>
            <div className="form_input">
              {
                formContent.editable ?
                  <input type="text" name="formId" value={formContent.formId} onChange={handleInputChange}/> :
                  <p>{formContent.formId}</p>
              }
            </div>
          </div>
          <div className="form_half">
            <div className="form_title">
              工程名稱：
            </div>
            <div className="form_input">
              <p>{formContent.projectName}</p>
            </div>
          </div>
          {/* 一行 */}
          <div className="form_half">
            <div className="form_title">
              承攬廠商：
            </div>
            <div className="form_input">
              {
                formContent.editable ?
                  <input type="text" name="vendor" value={formContent.vendor} onChange={handleInputChange}/> :
                  <p>{formContent.vendor}</p>
              }
            </div>
          </div>
          <div className="form_half">
            <div className="form_title">
              檢查日期：
            </div>
            {
              formContent.editable ?
                <div className="form_input">
                  <DateInput type="date" name="examineDate" value={formContent.examineDate} onChange={handleInputChange}/>
                </div> :
                <div className="form_input">
                  <p>{moment(formContent.examineDate).format('YYYY-MM-DD')}</p>
                </div>
            }
          </div>
          {/* 一行 */}
          <div className="form_half">
            <div className="form_title">
              檢查地點：
            </div>
            <div className="form_input">
              {
                formContent.editable ?
                  <input type="text" name="position" value={formContent.position} onChange={handleInputChange}/> :
                  <p>{formContent.position}</p>
              }
            </div>
          </div>
          <div className="form_half">
            <div className="form_title">
              檢查位置：
            </div>
            <div className="form_input">
              {
                formContent.editable ?
                  <input type="text" name="location" value={formContent.location} onChange={handleInputChange}/> :
                  <p>{formContent.location}</p>
              }
            </div>
          </div>
        </div>

        {/* <FakeHr /> */}

        <TableBox>
          <div className="table">
            <table>
              <tbody>
                <tr>
                  <td>項次</td>
                  <td>檢查項目</td>
                  <td>檢查結果</td>
                  <td>缺失情形及說明事項</td>
                </tr>
                {
                  formContent.itemList.map((ele, idx) => (
                    <tr key={ele.id}>
                      <td>{idx+1}</td>
                      <td>
                        {ele.itemName}
                      </td>
                      <td style={{ textAlign:'left', }}>
                        <label><input type="radio" name={"result"+formContent._uuid+ele.id} value={1} checked={Number(ele.result) === 1} onChange={(evt) => handleItemCheckChange(evt, ele)} disabled={!formContent.editable}/> 合格</label> <br />
                        <label><input type="radio" name={"result"+formContent._uuid+ele.id} value={2} checked={Number(ele.result) === 2} onChange={(evt) => handleItemCheckChange(evt, ele)} disabled={!formContent.editable}/> 不合格</label> <br/>
                        <label><input type="radio" name={"result"+formContent._uuid+ele.id} value={0} checked={Number(ele.result) === 0} onChange={(evt) => handleItemCheckChange(evt, ele)} disabled={!formContent.editable}/> 本次無抽查</label>
                      </td>
                      <td>
                        {
                          formContent.editable ?
                            <SpotCheckDescTextarea
                              name="note"
                              id=""
                              cols=""
                              rows="6"
                              value={ele.note}
                              onChange={(evt) => handleItemInputChange(evt, ele, idx)}
                              ref={(el) => (noteRef.current[idx] = el)}
                              onFocus={(evt) => getCursorPos('note', ele, idx, evt)}
                            ></SpotCheckDescTextarea> :
                            <>{ele.note}</>
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </TableBox>
        {
          formContent.editable && (
            <div>
              <Keyboard>
                {
                  keyboardSignList.map((ele, idx) => (
                    <button key={idx} className="btnWhite" onClick={(evt) => InsertMark(evt, editingCell.row, editingCell.col)} value={ele}>{ele}</button>
                  ))
                }
              </Keyboard>
            </div>
          )
        }

        <FakeHr />

        <AttachErrorNotify formContent={formContent} abnormalForm={abnormalForm} setAbnormalForm={setAbnormalForm}/>

        <FakeHr />

        {
          ((formContent.signOffList && formContent.signOffList.length > 0 && formContent.signOffList[0].userName === userInfo.userName && formContent.editable)
            || formContent.status === 1)
            && (
              <div className="btn_box">
                <button className="btnL btnBlue" onClick={() => handleSave({ isSubmit: false })} disabled={isSaving}>
                  {
                    isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '儲存表單'
                  }
                </button>
              </div>
            )
        }


        {
          props.formContent.id > 0 && formContent.formStatus !== 3 ?
            (
              <>
                <FakeHr />
                <FormTitle>表單簽核</FormTitle>

                <TableBox>
                  <div className="signArea">
                    {
                      formContent.signOffList.map(ele => (
                        <div className="approver" key={tools._UUID()}>
                          <div className="approverTitle">
                            <div className="approverTitleInner">
                              <p>{ele.jobTitle} - {ele.name_zhtw}</p>
                              {
                                userInfo.userName === ele.userName && formContent.userStampList && ele.signOffTag ?
                                  (
                                    <SignetSelect onChange={handleStampChange} value={formContent.userStamp.userStampID}>
                                      <option value=''>選擇影像章</option>
                                      {
                                        formContent.userStampList.map(ele1 => (
                                          <option value={ele1.userStampID} key={ele1.userStampID}>{ele1.stampName}</option>
                                        ))
                                      }
                                    </SignetSelect>
                                  ) :
                                  null
                              }
                            </div>
                          </div>
                          <div className="approverSign">
                            {
                              ele.sign ? <img src={ele.sign} key={ele.id}/> : (
                                userInfo.userName === ele.userName && formContent.userStampList && ele.signOffTag ? (formContent.userStamp.userStampID ? <img src={formContent.userStamp.stampUrl} key={formContent.userStamp.userStampID}/> : <div>請選擇影像章</div>) :
                                  <div>尚未簽核</div>
                              )
                            }
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </TableBox>
                <div className="btn_box">
                  {/* 當有影像章列表(表示目前流程到此帳號)才可以編輯及送出簽核 */}
                  {
                    (formContent.signOffList.findIndex(ele => ele.signOffTag) === 0 && formContent.userStampList) && (
                      <button className="btnL btnBlue" onClick={() => handleSignOff(1)} disabled={isSaving}>
                        {
                          isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '儲存並送出審核'
                        }
                      </button>
                    )
                  }
                  {
                    (formContent.signOffList.findIndex(ele => ele.signOffTag) !== 0 &&
                      formContent.signOffList.findIndex(ele => ele.signOffTag) !== formContent.signOffList.length-1 &&
                      formContent.userStampList)&& (
                      <>
                        <button className="btnL btnBlue" onClick={() => handleSignOff(1)} disabled={isSaving}>
                          {
                            isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '核准'
                          }
                        </button>
                        <button className="btnL btnRed" onClick={() => handleSignOff(2)} disabled={isSaving}>
                          {
                            isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '退回'
                          }
                        </button>
                      </>
                    )
                  }
                  {
                    (formContent.signOffList.findIndex(ele => ele.signOffTag) === formContent.signOffList.length-1 && formContent.userStampList) && (
                      <>
                        <button className="btnL btnOrange" onClick={() => handleSignOff(0)} disabled={isSaving}>
                          {
                            isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '決行'
                          }
                        </button>
                        <button className="btnL btnRed" onClick={() => handleSignOff(2)} disabled={isSaving}>
                          {
                            isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '退回'
                          }
                        </button>
                      </>
                    )
                  }
                </div>
              </>
            ) : null
        }
        {
          formContent.formStatus === 2 && (<div className="btn_box">
            {
              <button className="btnL btnBlue" onClick={ExportPDF}>
                {
                  isPDFExporting ?
                    <div>
                  匯出中，請稍後...
                      <i className="fas fa-circle-notch fa-spin"></i>
                    </div> : '匯出PDF'
                }
              </button>
            }
          </div>)
        }
        {
          <>
            <FormTitle>歷程紀錄</FormTitle> <br />

            <HistoricalRecord>
              {formContent.historyRecordList.map(ele =>(
                <p key={tools._UUID()}>
                  <span> {moment(ele.createDate).format('YYYY-MM-DD HH:mm:ss') } </span>由<span> {ele.createID} </span>退件，理由：<span> {ele.note} </span>
                </p>
              ))}
            </HistoricalRecord>
          </>
        }

        {
          formContent.formStatus === 2 && userInfo.projectRoles.find(pr => pr.projectID === projectId)?.roleName === '管理者' && (
            <>
              <FakeHr />

              <InputReturnReason>
                <a name="ReturnReason" id="ReturnReason"></a>
                <p>請輸入退回理由</p>
                <textarea name="returnNote" id="" cols="" rows="6" onChange={handleInputChange}></textarea>

                <div className="btnbox">
                  <div className="btn_box"><button className="btnL btnBlue" onClick={handleReturnSignOff}>確認退回</button></div>
                </div>
              </InputReturnReason>
            </>
          )
        }

        {/* <FormTitle>表單簽核</FormTitle>

        <TableBox>
          <div className="signArea">
            <div className="approver">
              <div className="approverTitle">
                <div className="approverTitleInner">
                  <p>監造工程師</p>
                  <SignetSelect>
                    <option value="">小明小明</option>
                  </SignetSelect>
                </div>
              </div>
              <div className="approverSign">
                <img src={ReverImage} />
              </div>
            </div>
            <div className="approver">
              <div className="approverTitle">
                <div className="approverTitleInner">
                  <p>監造主任</p>
                  <SignetSelect>
                    <option value="">小華小華小華小華小華小華小華小華小華小華小華小華小華小華小華</option>
                  </SignetSelect>
                </div>
              </div>
              <div className="approverSign"></div>
            </div>
          </div>
        </TableBox>
        <div className="btn_box">
          <button className="btnL btnBlue">送出審核</button>
        </div> */}


      </div>
    </>
  );

};

export default SafetyHealthCheckRecord;