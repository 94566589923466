import API from "./ApiConfig";

const StatisticAPI = {
  // 取得統計報總覽
  GetFrontPage: () => {
    return new Promise((resolve, reject) => {
      API.get(`/Statistic/GetFrontPage/`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  //單日出公機具資料
  GetDailyPersonnelImplements: ( projectId, date ) => {
    return new Promise((resolve, reject) => {
      API.get(`/Statistic/GetDailyPersonnelImplements/${projectId}/${date}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得範圍出公機具資料
  GetRangePersonnelImplements: ( projectId, sdate, edate ) => {
    return new Promise((resolve, reject) => {
      API.get(`/Statistic/GetRangePersonnelImplements/${projectId}/${sdate}/${edate}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得工程進度
  GetProgress: (projectId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Statistic/GetProgress/${projectId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得工程概述
  GetOverView: (projectId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Statistic/GetOverView/${projectId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // 施工品質查驗紀錄API
  GetCEFormRecord: (projectId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Statistic/GetCEFormRecord/${projectId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 材料設備查驗紀錄API
  GetEQFormRecord: (projectId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Statistic/GetEQFormRecord/${projectId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  
  // 取得職業安全衛生
  GetHSExamineRecord: (projectId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Statistic/GetHSExamineRecord/${projectId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  //
  GetAbnormalImproveRecord: (projectId, isHSE) => {
    return new Promise((resolve, reject) => {
      API.get(`/Statistic/GetAbnormalImproveRecord/${projectId}?isHSE=${isHSE}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // 取得專案人員
  GetLatestPersonnelRecord: (projectId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Statistic/GetLatestPersonnelRecord/${projectId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },




};

export default StatisticAPI;