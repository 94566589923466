export const themeReducer = (state, action) => {
  let initTheme = 'dark'; // TODO: should detect browser's setting
  switch (action.type) {
  case 'SET_THEME':
    return action.theme;
  case 'RESET':
    return initTheme;
  default:
    return state;
  }
};