import styled from '@emotion/styled';
import { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ProjectAPI, AccountAPI, HelperAPI } from "./../assets/api";
import tools from './../assets/tools';
import XLSX from 'xlsx';
import { notifyFailed, notifySuccess } from './../assets/toaster';
import { ToastContainer } from 'react-toastify';

import moment from 'moment';
import Select from 'react-select';
import Progress_Template from './../../assets/Progress_Template.xlsx';


const TxtInput = styled.input`
  width: 100%;
  max-width: 390px;
  height: 28px;
  border-radius: 3px;
  border: 1px solid #999;
  padding: 0 5px;
  box-sizing: border-box;
`;
const InlineInput = styled.input`
  width: calc(100% - 2.5em);
  margin: 0 0 10px 5px;
  height: 28px;
  border-radius: 3px;
  border: 1px solid #999;
  padding: 0 5px;
  box-sizing: border-box;
`;

const CkboxInput = styled.input`
  margin-right: 3px;
`;
const CoverPreview = styled.div`
  display: block;
  width: 360px;
  height: 240px;
  background-color: #F1F1F1;
  margin: 10px 0;
  @media (max-width: 580px) {
    width: 98%;
  }
  @media (max-width: 450px) {
    width: 99%;
    background-color: #FFF;
    height: 180px;
  }
`;
const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 100%
`;
const NormalSelect = styled.select`
  border: 1px solid #888;
  padding: 2px 4px;
  border-radius: 4px;
`;
const RWDbr = styled.br`
  display: none;
  @media (max-width: 450px) {
    display: inline;
  }
`;

const RelatedFile = styled.a`
  text-decoration: none;
  color: #007da3;
`;

const ProgressImportDiv = styled.div`
  display: inline-block;
  border: 1px solid #999;
  padding: 0.75rem 10px !important;
  margin: 0 0 10px 0;
`;

const ExcelTable = styled.table`
  tr{
    td{
      padding: 0.1rem 4px;
    }
  }
`;

const CreateContractItem = (props) => {
  return (
    <tr>
      <td>
        <label>
          <CkboxInput type="checkbox" checked={props.item.isShow} onChange={props.handleItemisShowChange} />
        </label>
      </td>
      <td>
        <TxtInput type="date" value={props.item.changeContractDate} onChange={props.handleItemCCDChange} />
      </td>
      <td>
        <TxtInput type="text" style={{ minWidth: '3em' }} value={props.item.stageContract} onChange={props.handleItemStageChange} />
      </td>
      <td>
        <TxtInput type="date" value={props.item.contractStartDate} onChange={props.handleItemCSDChange} />
      </td>
      <td>
        <TxtInput type="text" style={{ minWidth: '3em' }} value={props.item.amount} onChange={props.handleItemAmountChange} />
      </td>
      <td>
        <TxtInput type="date" value={props.item.completedDate} onChange={props.handleItemCompletedDChange} />
      </td>
      <td>
        <TxtInput type="date" value={props.item.acturalCompletedDate} onChange={props.handleItemACDChange} />
      </td>
      <td>
        <TxtInput type="text" style={{ minWidth: '3em' }} value={props.item.contractDuration} onChange={props.handleItemDurationChange} />
      </td>
      <td>
        <TxtInput type="text" style={{ minWidth: '3em' }} value={props.item.extensionDays} onChange={props.handleItemextensionChange} />
      </td>
      <td>
        <button className="btnM btnRed" onClick={() => props.handleDeleteContractIItem(props.item)}>刪除</button>
      </td>
    </tr>
  );
};

const CreateSupTabItem = (props) => {
  return (
    <tr>
      <td>
        <TxtInput type="date" value={props.item.changeContractDate} onChange={props.handleItemchangeContractDateChange} />
      </td>
      <td>
        <TxtInput type="text" style={{ minWidth: '3em' }} value={props.item.amount} onChange={props.handleItemSupAmountChange} />
      </td>
      <td>
        <button className="btnM btnRed" onClick={() => props.handleDeleteSupTaIItem(props.item)}>刪除</button>
      </td>
    </tr>
  );
};

const ProjectEdit = () => {
  const { projectid } = useParams();
  const history = useHistory();
  const [pageStatus, setPageStatus] = useState(0);
  const [project, setProject] = useState({
    'id': 0,
    'projectName': '',
    'projectNum': '',
    'projectShortName': '',
    'titleAgency': '',
    'projectManageVendor': '',
    'supervision': '',
    'coverImage': '',
    'roleList': [],
    'prjectStatus': 1,
    'executionSDate': '',
    'executionEDate': '',
    'completedFile': '',
    'completedFileName': '',
    'projectContractForm': [], //完工日期與契約金額
    'jointContract': false, //聯合承攬
    'jointContractTitle': '', //聯合承攬匯出表單表頭
    'projectSupervisionContractForm': [], //監造契約金額
    'supervisionStamp': '', //工地監造章
    'contract': '', //承攬廠商
    'projectScheduledForm': [], //工程預定進度上傳
    'lat': 0, //地理座標(Lat)
    'lng': 0, //地理座標(Lng)

  });
  const [helper, setHelper] = useState({
    userList: [],
    roleList: []
  });
  // 專案封面照片
  const [imageUpload, setImageUpload] = useState({
    formDataId: 0,
    file: null
  });
  // 工地監造章
  const [supStampUpload, setSupStampUpload] = useState({
    formDataId: 0,
    file: null
  });
  const [fileUpload, setFileUpload] = useState({
    formDataId: 0,
    file: null
  });

  const [isSaving, setIsSaving] = useState(false);

  const importTemplateData = useRef([]);
  const uploadTemplateData = useRef(null);

  function ContractIItem({ _uuid = 0, projectId = Number(projectid) ? Number(projectid) : 0,
    isShow = true, changeContractDate = '', stageContract = '', contractStartDate = '',
    amount = 0, completedDate = '', acturalCompletedDate = '', contractDuration = 0,
    extensionDays = 0, status = 0, id = 0 }) {
    this._uuid = _uuid;
    this.projectId = projectId;
    this.status = status;
    this.isShow = isShow;
    this.changeContractDate = changeContractDate;
    this.stageContract = stageContract;
    this.contractStartDate = contractStartDate;
    this.amount = amount;
    this.completedDate = completedDate;
    this.acturalCompletedDate = acturalCompletedDate;
    this.contractDuration = contractDuration;
    this.extensionDays = extensionDays;
    this.id = id;
  }

  function SupTabItem({ _uuid = 0, projectId = Number(projectid) ? Number(projectid) : 0,
    changeContractDate = '', amount = 0, status = 0, id = 0 }) {
    this._uuid = _uuid;
    this.projectId = projectId;
    this.status = status;
    this.changeContractDate = changeContractDate;
    this.amount = amount;
    this.id = id;
  }

  function Role ({_uuid=0, projectId=Number(projectid) ? Number(projectid) : 0, roleName='', userName='', status=0}) {
    this._uuid = _uuid;
    this.projectId = projectId;
    this.roleName = roleName;
    this.userName = userName;
    this.status = status;
  }

  async function GetProjectInfo() {
    try {
      let data = await ProjectAPI.GetProjectInfo(projectid);
      data.executionSDate = data.executionSDate ? moment(data.executionSDate).format('YYYY-MM-DD') : '';
      data.executionEDate = data.executionEDate ? moment(data.executionEDate).format('YYYY-MM-DD') : '';
      data.completedFileName = data.completedFile ? data.completedFile.split('/')[data.completedFile.split('/').length-1] : '';
      data.roleList.forEach(ele => {
        ele._uuid = tools._UUID();
        ele.status = 0;
        ele.isExist = true; // Is this item already existed in database
      });
      data.projectContractForm.forEach(ele => {
        ele._uuid = tools._UUID();
        ele.status = 0;
        ele.isExist = true; // Is this item already existed in database
        ele.changeContractDate = ele.changeContractDate ? moment(ele.changeContractDate).format('YYYY-MM-DD') : '';
        ele.contractStartDate = ele.contractStartDate ? moment(ele.contractStartDate).format('YYYY-MM-DD') : '';
        ele.completedDate = ele.completedDate ? moment(ele.completedDate).format('YYYY-MM-DD') : '';
        ele.acturalCompletedDate = ele.acturalCompletedDate ? moment(ele.acturalCompletedDate).format('YYYY-MM-DD') : '';
      });
      data.projectSupervisionContractForm.forEach(ele => {
        ele._uuid = tools._UUID();
        ele.status = 0;
        ele.isExist = true; // Is this item already existed in database
        ele.changeContractDate = ele.changeContractDate ? moment(ele.changeContractDate).format('YYYY-MM-DD') : '';
      });
      data.projectScheduledForm.forEach(ele => {
        ele._uuid = tools._UUID();
        ele.status = 0;
        ele.isExist = true; // Is this item already existed in database
        ele.dateTime = ele.dateTime ? moment(ele.dateTime).format('YYYY-MM-DD') : '';
      });
      setProject(data);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }

  async function GetUserList() {
    try {
      let data = await AccountAPI.GetUserList({ keyword: '' });
      return data.filter(ele => ele.enable);
    } catch (error) {
      console.log(error);
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }

  async function GetRoleList() {
    try {
      let data = await HelperAPI.GetRoleList();
      return data;
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }

  const handleNameChange = (e) => {
    setProject({...project, projectName: e.target.value});
  };

  const handleNumChange = (e) => {
    setProject({ ...project, projectNum: e.target.value });
  };

  const handleShortNameChange = (e) => {
    setProject({ ...project, projectShortName: e.target.value });
  };

  const handleContractChange = (e) => {
    setProject({ ...project, contract: e.target.value });
  };

  const handleLatChange = (e) => {
    setProject({ ...project, lat: e.target.value });
  };

  const handleLngChange = (e) => {
    setProject({ ...project, lng: e.target.value });
  };

  const handletitleAgencyChange = (e) => {
    setProject({...project, titleAgency: e.target.value});
  };

  const handleprojectManageVendorChange = (e) => {
    setProject({ ...project, projectManageVendor: e.target.value });
  };

  const handlejointContractTitleChange = (e) => {
    setProject({ ...project, jointContractTitle: e.target.value });
  };

  // preview image 專案封面照片
  const handleImageUpload = (e) => {
    if (e.target.files && e.target.files[0]){
      let reader = new FileReader();
      reader.onload = function(e) {
        setProject({...project, coverImage: e.target.result});
      };
      reader.readAsDataURL(e.target.files[0]);
      setImageUpload({
        formDataId: Number(projectid) > 0 ? Number(projectid) : 0,
        file: e.target.files[0]
      });
    }
  };

  // preview image 工地監造章
  const handleSupStampUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = function (e) {
        setProject({ ...project, supervisionStamp: e.target.result });
      };
      reader.readAsDataURL(e.target.files[0]);
      setSupStampUpload({
        formDataId: Number(projectid) > 0 ? Number(projectid) : 0,
        file: e.target.files[0]
      });
    }
  };

  const handleFileUpload = (e) => {
    if (e.target.files && e.target.files[0]){
      setFileUpload({
        formDataId: Number(projectid) > 0 ? Number(projectid) : 0,
        file: e.target.files[0]
      });
    }
  };


  const handleTemplateUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = function (e) {
        // Parse data
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        // Get first worksheet
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        // Convert array of arrays
        importTemplateData.current = XLSX.utils.sheet_to_json(ws, { header: 1 });
      };
      reader.readAsBinaryString(e.target.files[0]);
    }
  };

  const handleTemplateImport = () => {
    let mappedData = importTemplateData.current.map(ele => {
      let DT = window.Common.ExcelDateToDate(ele[0]).Format('yyyy-MM-dd');
      return {
        id: 0,
        dateTime: DT,
        scheduledProgress: ele[1],
        status: 1,
        _uuid: tools._UUID(),
        // isExist: false
      };
    });
    let sliceMappedData = mappedData.slice(1, mappedData.length);

    const ExcelValidate = () => {
      let ExcelValid = true;
      sliceMappedData.forEach(ele => {
        if (ele.scheduledProgress > 100 || ele.scheduledProgress < 0) {
          ExcelValid = false;
        }
      });
      return ExcelValid;
    };

    let excelValidate = ExcelValidate();
    if (!excelValidate) {
      notifyFailed({ Msg: '預定進度(%) 需介於 0~100，小數點最多四位。', isReload: false });
      return;
    }

    setProject({
      ...project,
      projectScheduledForm: sliceMappedData
    });


  };

  const handleCreateContractIItem = () => {
    setProject({ ...project, projectContractForm: [...project.projectContractForm, new ContractIItem({ _uuid: tools._UUID(), status: 1 })] });
  };

  const handleDeleteContractIItem = (deleteContractIItem) => {
    if (deleteContractIItem.isExist) {
      setProject({ ...project, projectContractForm: project.projectContractForm.map(ele => (ele._uuid === deleteContractIItem._uuid ? { ...ele, status: 3 } : ele)) });
    } else {
      setProject({ ...project, projectContractForm: project.projectContractForm.filter(ele => ele._uuid !== deleteContractIItem._uuid) });
    }
  };

  const handleCreateSupTabItem = () => {
    setProject({ ...project, projectSupervisionContractForm: [...project.projectSupervisionContractForm, new SupTabItem({ _uuid: tools._UUID(), status: 1 })] });
  };

  const handleDeleteSupTaIItem = (deleteSupTabItem) => {
    if (deleteSupTabItem.isExist) {
      setProject({ ...project, projectSupervisionContractForm: project.projectSupervisionContractForm.map(ele => (ele._uuid === deleteSupTabItem._uuid ? { ...ele, status: 3 } : ele)) });
    } else {
      setProject({ ...project, projectSupervisionContractForm: project.projectSupervisionContractForm.filter(ele => ele._uuid !== deleteSupTabItem._uuid) });
    }
  };

  const handleItemShowChange = (e, el) => {
    setProject({ ...project, projectContractForm: project.projectContractForm.map(ele => (ele._uuid === el._uuid ? { ...ele, isShow: e.target.checked, status: 2 } : ele)) });
  };




  const handleCreateRole = () => {
    setProject({...project, roleList: [...project.roleList, new Role({_uuid: tools._UUID(), status: 1})]});
  };

  const handleDeleteRole = (deleteRole) => {
    // let deleteRole = project.roleList.find(ele => ele._uuid === _uuid);
    if (deleteRole.isExist) {
      setProject({...project, roleList: project.roleList.map(ele => (ele._uuid === deleteRole._uuid ? {...ele, status: 3} : ele))});
    } else {
      setProject({...project, roleList: project.roleList.filter(ele => ele._uuid !== deleteRole._uuid)});
    }
  };

  const handleUserSelect = (e, el) => {
    setProject({...project, roleList: project.roleList.map(ele => (ele._uuid === el._uuid ? {...ele, userName: e.value} : ele))});
  };

  const handleRoleSelect = (e, el) => {
    setProject({...project, roleList: project.roleList.map(ele => (ele._uuid === el._uuid ? {...ele, roleName: e.target.value} : ele))});
  };

  const handleStatusChange = (e) => {
    setProject({...project, [e.target.name]: Number(e.target.value)});
  };

  const handleJointChange = (e) => {
    setProject({ ...project, jointContract: e.target.checked ? true : false });
  };

  const handleSaveClick = async () => {
    setIsSaving(true);
    let formData = new FormData();
    formData.append('ProjectId', Number(projectid) > 0 ? Number(projectid) : 0);
    formData.append('ProjectName', project.projectName);
    formData.append('projectNum', project.projectNum);
    formData.append('projectShortName', project.projectShortName);
    formData.append('TitleAgency', project.titleAgency);
    formData.append('ProjectManageVendor', project.projectManageVendor || '');
    formData.append('CoverImageFile', imageUpload.file); //專案封面照片
    formData.append('SupervisionStamp', supStampUpload.file); //工地監造章
    formData.append('ProjectStatus', project.prjectStatus);
    formData.append('executionSDate', project.executionSDate);
    formData.append('executionEDate', project.executionEDate);
    formData.append('completedFile', fileUpload.file);
    formData.append('Status', pageStatus);
    formData.append('jointContract', project.jointContract || false); //聯合承攬
    formData.append('jointContractTitle', project.jointContractTitle || '');
    formData.append('contract', project.contract || ''); //承攬廠商
    formData.append('lat', project.lat || 0);
    formData.append('lng', project.lng || 0);
    for (let i = 0; i<project.roleList.length; i++) {
      formData.append(`ProjectUserList[${i}].UserName`, project.roleList[i].userName);
      formData.append(`ProjectUserList[${i}].ProjectId`, project.roleList[i].projectId);
      formData.append(`ProjectUserList[${i}].RoleName`, project.roleList[i].roleName);
      formData.append(`ProjectUserList[${i}].Status`, project.roleList[i].status);
    }
    // 完工日期與契約金額
    for (let i = 0; i < project.projectContractForm.length; i++) {
      formData.append(`projectContractForm[${i}].isShow`, project.projectContractForm[i].isShow);
      formData.append(`projectContractForm[${i}].changeContractDate`, project.projectContractForm[i].changeContractDate);
      formData.append(`projectContractForm[${i}].stageContract`, project.projectContractForm[i].stageContract || '');
      formData.append(`projectContractForm[${i}].contractStartDate`, project.projectContractForm[i].contractStartDate);
      formData.append(`projectContractForm[${i}].amount`, project.projectContractForm[i].amount);
      formData.append(`projectContractForm[${i}].completedDate`, project.projectContractForm[i].completedDate);
      formData.append(`projectContractForm[${i}].acturalCompletedDate`, project.projectContractForm[i].acturalCompletedDate);
      formData.append(`projectContractForm[${i}].contractDuration`, project.projectContractForm[i].contractDuration);
      formData.append(`projectContractForm[${i}].extensionDays`, project.projectContractForm[i].extensionDays);
      formData.append(`projectContractForm[${i}].status`, project.projectContractForm[i].status);
      formData.append(`projectContractForm[${i}].id`, project.projectContractForm[i].id);
    }
    // 監造契約金額
    for (let i = 0; i < project.projectSupervisionContractForm.length; i++) {
      formData.append(`projectSupervisionContractForm[${i}].changeContractDate`, project.projectSupervisionContractForm[i].changeContractDate);
      formData.append(`projectSupervisionContractForm[${i}].amount`, project.projectSupervisionContractForm[i].amount);
      formData.append(`projectSupervisionContractForm[${i}].status`, project.projectSupervisionContractForm[i].status);
      formData.append(`projectSupervisionContractForm[${i}].id`, project.projectSupervisionContractForm[i].id);
    }
    // 工程預定進度上傳
    for (let i = 0; i < project.projectScheduledForm.length; i++) {
      formData.append(`projectScheduledForm[${i}].dateTime`, project.projectScheduledForm[i].dateTime);
      formData.append(`projectScheduledForm[${i}].scheduledProgress`, project.projectScheduledForm[i].scheduledProgress);
      formData.append(`projectScheduledForm[${i}].status`, project.projectScheduledForm[i].status);
      formData.append(`projectScheduledForm[${i}].id`, project.projectScheduledForm[i].id);
    }

    try {
      await ProjectAPI.ModifyProject({ projectData: formData });
      if (pageStatus === 1) {
        uploadTemplateData.current = null;
        importTemplateData.current = [];
        notifySuccess({Msg: '儲存成功', cbfunc: () => history.push("/manage/project-manage")});
        setIsSaving(false);
      } else if (pageStatus === 2) {
        uploadTemplateData.current = null;
        importTemplateData.current = [];
        notifySuccess({Msg: '儲存成功', cbfunc: () => window.location.reload()});
        setIsSaving(false);
      }

    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
      setIsSaving(false);
    }
    // await ProjectAPI.ModifyProject(projectData);
  };

  useEffect(async () => {
    if (projectid !== 'create') {
      GetProjectInfo();
      setPageStatus(2);
      uploadTemplateData.current = null;
      importTemplateData.current = [];
    } else if (projectid === 'create') {
      setPageStatus(1);
    }
    let roleList = await GetRoleList();
    let userList = await GetUserList();
    setHelper({...helper, roleList, userList});
  }, []);

  const handleItemisShowChange = (e, el) => {
    setProject({ ...project,
      projectContractForm: project.projectContractForm.map(ele => (ele._uuid === el._uuid ? { ...ele, isShow: e.target.checked } : ele))
    });
  };
  const handleItemCCDChange = (e, el) => {
    setProject({
      ...project,
      projectContractForm: project.projectContractForm.map(ele => (ele._uuid === el._uuid ? { ...ele, changeContractDate: e.target.value } : ele))
    });
  };
  const handleItemStageChange = (e, el) => {
    setProject({
      ...project,
      projectContractForm: project.projectContractForm.map(ele => (ele._uuid === el._uuid ? { ...ele, stageContract: e.target.value } : ele))
    });
  };

  const handleItemCSDChange = (e, el) => {
    setProject({
      ...project,
      projectContractForm: project.projectContractForm.map(ele => (ele._uuid === el._uuid ? { ...ele, contractStartDate: e.target.value } : ele))
    });
  };
  const handleItemAmountChange = (e, el) => {
    setProject({
      ...project,
      projectContractForm: project.projectContractForm.map(ele => (ele._uuid === el._uuid ? { ...ele, amount: e.target.value } : ele))
    });
  };
  const handleItemCompletedDChange = (e, el) => {
    setProject({
      ...project,
      projectContractForm: project.projectContractForm.map(ele => (ele._uuid === el._uuid ? { ...ele, completedDate: e.target.value } : ele))
    });
  };

  const handleItemACDChange = (e, el) => {
    setProject({
      ...project,
      projectContractForm: project.projectContractForm.map(ele => (ele._uuid === el._uuid ? { ...ele, acturalCompletedDate: e.target.value } : ele))
    });
  };
  const handleItemDurationChange = (e, el) => {
    setProject({
      ...project,
      projectContractForm: project.projectContractForm.map(ele => (ele._uuid === el._uuid ? { ...ele, contractDuration: e.target.value } : ele))
    });
  };
  const handleItemextensionChange = (e, el) => {
    setProject({
      ...project,
      projectContractForm: project.projectContractForm.map(ele => (ele._uuid === el._uuid ? { ...ele, extensionDays: e.target.value } : ele))
    });
  };
  //
  const handleItemchangeContractDateChange = (e, el) => {
    setProject({
      ...project,
      projectSupervisionContractForm: project.projectSupervisionContractForm.map(ele => (ele._uuid === el._uuid ? { ...ele, changeContractDate: e.target.value } : ele))
    });
  };
  const handleItemSupAmountChange = (e, el) => {
    setProject({
      ...project,
      projectSupervisionContractForm: project.projectSupervisionContractForm.map(ele => (ele._uuid === el._uuid ? { ...ele, amount: e.target.value } : ele))
    });
  };

  const CreateRoleSelect = (props) => {

    const RoleOptions = [];
    for (let i = 0; i < props.helper.userList.length; i++) {
      RoleOptions.push(
        {
          "value": props.helper.userList[i].userName ,
          "key": props.helper.userList[i].userName,
          "label": props.helper.userList[i].realName + " (" + props.helper.userList[i].userName + ")",
        }
      );
    }

    return (
      <tr>
        <td></td>
        <td className="forReactSelect">
          <Select
            placeholder="選擇人員"
            onChange={(event) => handleUserSelect(event, props.item)}
            value={
              RoleOptions.filter(ele => ele.value === props.item.userName)
            }
            options={RoleOptions} />
        </td>
        <td>
          <NormalSelect onChange={(event) => handleRoleSelect(event, props.item)} value={props.item.roleName}>
            <option value=''>選擇專案角色</option>
            {
              props.helper.roleList.map(ele => {
                return (
                  <option key={ele.roleId} value={ele.roleName}>{ele.roleName_zhtw}</option>
                );
              })
            }
          </NormalSelect>
        </td>
        <td>
          <button className="btnM btnRed" onClick={() => handleDeleteRole(props.item)}>刪除</button>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="bumper_rail"></div>
      <div className="commontitle">
        <h1>專案明細</h1>
      </div>

      <div className="sharebox">
        <div className="form form_loose">

          <div className="form_13quarters">
            <div className="form_title">專案名稱：</div>
            <div className="form_input">
              <label>
                <TxtInput value={project.projectName} onChange={handleNameChange}/>
              </label>
            </div>
          </div>
          {<div className="form_13quarters">
            <div className="form_title">專案案號：</div>
            <div className="form_input">
              <label>
                <TxtInput value={project.projectNum} onChange={handleNumChange} />
              </label>
            </div>
          </div>}
          <div className="form_13quarters">
            <div className="form_title">工程簡稱：</div>
            <div className="form_input">
              <label>
                <TxtInput value={project.projectShortName} onChange={handleShortNameChange} />
              </label>
            </div>
          </div>
          <div className="form_13quarters">
            <div className="form_title">承攬廠商：</div>
            <div className="form_input">
              <label>
                <TxtInput value={project.contract} onChange={handleContractChange} />
              </label>
            </div>
          </div>

          <div className="form_13quarters">
            <div className="form_title">完工日期與契約金額：</div>
            <div className="form_input">
              <div className="table" style={{ overflow: "auto", }}>
                <table style={{ margin: "0 0", minWidth: "400px", }}>
                  <tbody>
                    <tr>
                      <td>報告顯示</td>
                      <td>契約變更生效日</td>
                      <td>分段履約</td>
                      <td>契約起始日期</td>
                      <td>契約變更後金額</td>
                      <td>完工日期</td>
                      <td>實際完工日期</td>
                      <td>契約工期</td>
                      <td>展延天數</td>
                      <td><button className="btnM btnBlue" onClick={handleCreateContractIItem}>新增</button></td>
                    </tr>
                    {
                      project.projectContractForm.filter(el => el.status !== 3).map((ele) => {
                        return (
                          ele.isExist ?
                            <tr key={ele._uuid}>
                              <td>
                                <input type="checkbox" checked={ele.isShow === true} onChange={(evt) => handleItemShowChange(evt, ele)} />
                              </td>
                              <td>{ele.changeContractDate}</td>
                              <td>{ele.stageContract}</td>
                              <td>{ele.contractStartDate}</td>
                              <td>{ele.amount}</td>
                              <td>{ele.completedDate}</td>
                              <td>{ele.acturalCompletedDate}</td>
                              <td>{ele.contractDuration}</td>
                              <td>{ele.extensionDays}</td>
                              <td>
                                <button className="btnM btnRed" onClick={() => handleDeleteContractIItem(ele)}>刪除</button>
                              </td>
                            </tr> :
                            <CreateContractItem
                              item={ele}
                              key={ele._uuid}
                              handleItemisShowChange={(evt) => handleItemisShowChange(evt, ele)}
                              handleItemCCDChange={(evt) => handleItemCCDChange(evt, ele)}
                              handleItemStageChange={(evt) => handleItemStageChange(evt, ele)}
                              handleItemCSDChange={(evt) => handleItemCSDChange(evt, ele)}
                              handleItemAmountChange={(evt) => handleItemAmountChange(evt, ele)}
                              handleItemCompletedDChange={(evt) => handleItemCompletedDChange(evt, ele)}
                              handleItemACDChange={(evt) => handleItemACDChange(evt, ele)}
                              handleItemDurationChange={(evt) => handleItemDurationChange(evt, ele)}
                              handleItemextensionChange={(evt) => handleItemextensionChange(evt, ele)}
                              handleDeleteContractIItem={(evt) => handleDeleteContractIItem(evt, ele)}
                            />
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="form_13quarters">
            <div className="form_title">主辦機關：</div>
            <div className="form_input">
              <label>
                <TxtInput value={project.titleAgency} onChange={handletitleAgencyChange}/>
              </label>
            </div>
          </div>
          {<div className="form_13quarters">
            <div className="form_title">專案管理廠商：</div>
            <div className="form_input">
              <label>
                <TxtInput value={project.projectManageVendor} onChange={handleprojectManageVendorChange} />
              </label>
            </div>
          </div>}

          <div className="form_13quarters">
            <div className="form_title">聯合承攬：</div>
            <div className="form_input">
              <label>
                <input type="checkbox" checked={project.jointContract === true} onChange={handleJointChange} />
                <span>註：若為聯合承攬專案，匯出文件時不會帶入Stantec Logo</span>
              </label>
            </div>
          </div>

          {
            project.jointContract === true && (
              <>
                <div className="form_13quarters">
                  <div className="form_title">聯合承攬匯出表單表頭：</div>
                  <div className="form_input">
                    <label>
                      <TxtInput value={project.jointContractTitle} onChange={handlejointContractTitleChange} />
                    </label>
                  </div>
                </div>
              </>
            )
          }

          <div className="form_13quarters">
            <div className="form_title">監造契約金額：</div>
            <div className="form_input">
              <div className="table" style={{ overflow: "auto", }}>
                <table style={{ margin: "0 0", maxWidth: "800px", }}>
                  <tbody>
                    <tr>
                      <td>監造契約變更生效日</td>
                      <td>監造契約變更後金額</td>
                      <td><button className="btnM btnBlue" onClick={handleCreateSupTabItem}>新增</button></td>
                    </tr>
                    {
                      project.projectSupervisionContractForm.filter(el => el.status !== 3).map((ele) => {
                        return (
                          ele.isExist ?
                            <tr key={ele._uuid}>
                              <td>{ele.changeContractDate}</td>
                              <td>{ele.amount}</td>
                              <td>
                                <button className="btnM btnRed" onClick={() => handleDeleteSupTaIItem(ele)}>刪除</button>
                              </td>
                            </tr> :
                            <CreateSupTabItem
                              item={ele}
                              key={ele._uuid}
                              handleItemchangeContractDateChange={(evt) => handleItemchangeContractDateChange(evt, ele)}
                              handleItemSupAmountChange={(evt) => handleItemSupAmountChange(evt, ele)}
                              handleDeleteSupTaIItem={(evt) => handleDeleteSupTaIItem(evt, ele)}
                            />
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="form_13quarters">
            <div className="form_title">工地監造章：</div>
            <div className="form_input">
              <CoverPreview>
                <ImagePreview src={project.supervisionStamp} />
              </CoverPreview>
              <input type="file" accept="image" name="supervisionStamp" onChange={handleSupStampUpload} />
            </div>
          </div>

          <div className="form_13quarters">
            <div className="form_title">專案封面照片：</div>
            <div className="form_input">
              <CoverPreview>
                <ImagePreview src={project.coverImage} />
              </CoverPreview>
              <input type="file" accept="image" name="CoverImage" onChange={handleImageUpload} />
            </div>
          </div>

          <div className="form_13quarters">
            <div className="form_title">專案成員：</div>
            <div className="form_input">
              <div className="table table_ordered">
                <table style={{ maxWidth: "800px", margin:"0 0", }}>
                  <tbody>
                    <tr className="tr_indark">
                      <td style={{ minWidth:"18px", }}></td>
                      <td>姓名</td>
                      <td>專案<RWDbr />角色</td>
                      <td><button className="btnM btnBlue" onClick={handleCreateRole}>新增</button></td>
                    </tr>
                    {
                      project.roleList.filter(el => el.status !== 3).map((ele, idx) => {
                        return (
                          ele.isExist ?
                            <tr key={ele._uuid}>
                              <td>{idx+1}</td>
                              <td>{ele.userName_zhtw} ({ele.userName})</td>
                              <td>{ele.roleName_zhtw}</td>
                              <td>
                                <button className="btnM btnRed" onClick={() => handleDeleteRole(ele)}>刪除</button>
                              </td>
                            </tr> :
                            <CreateRoleSelect helper={helper} item={ele} key={ele._uuid}/>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="form_13quarters">
            <div className="form_title">專案狀態：</div>
            <div className="form_input">
              <label><input type="radio" name="prjectStatus" checked={project.prjectStatus === 1} value={1} onChange={handleStatusChange}/> 進行中</label>
              <label><input type="radio" name="prjectStatus" checked={project.prjectStatus === 2} value={2} onChange={handleStatusChange}/> 驗收作業中</label>
              <label><input type="radio" name="prjectStatus" checked={project.prjectStatus === 3} value={3} onChange={handleStatusChange}/> 已結案</label>
            </div>
          </div>
          <div className="form_13quarters">
            <div className="form_title">相關檔案上傳：</div>
            <div className="form_input">
              {
                project.completedFile ? (
                  <RelatedFile href={project.completedFile} target='_blank' rel='noreferrer'>{project.completedFileName}</RelatedFile>
                ) : '尚未上傳相關檔案'
              }
              <br />
              <input type="file" name="CompletedFile" onChange={handleFileUpload} style={{ marginTop: '5px', }} />
            </div>
          </div>
          <div className="form_13quarters">
            <div className="form_title">工程預定進度上傳：</div>
            <div className="form_input">
              <ProgressImportDiv>
                <input type="file" name="" accept=".xlsx" ref={uploadTemplateData} onChange={handleTemplateUpload} />
                <button className="btnM btnWhite"><a href={Progress_Template} style={{ textDecoration: 'none', color: 'inherit' }}>下載匯入範例檔</a></button>
                <button className="btnM btnBlue" onClick={handleTemplateImport}>批次匯入</button>
              </ProgressImportDiv>


              <p>已上傳工程預定進度：</p>
              <div className="table" style={{ overflow: "auto", maxHeight: "600px" }}>
                <ExcelTable style={{ margin: "0 0", maxWidth: "800px", }}>
                  <tbody>
                    <tr>
                      <td>日期</td>
                      <td>預定進度 (%)</td>
                    </tr>
                    {
                      project.projectScheduledForm.filter(el => el.status !== 3).map((ele) => {
                        return (
                          <tr key={ele._uuid}>
                            <td>{ele.dateTime}</td>
                            <td>{ele.scheduledProgress}</td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </ExcelTable>
              </div>

            </div>
          </div>


          <div className="form_13quarters">
            <div className="form_title">工地中心地理座標：</div>
            <div className="form_input">
              <label>
                Lat :<InlineInput value={project.lat} onChange={handleLatChange} />
              </label>
              <label>
                Lng:<InlineInput value={project.lng} onChange={handleLngChange} />
              </label>
            </div>
          </div>


          <div className="btn_box">
            <button className="btnL btnBlue" onClick={handleSaveClick} disabled={isSaving}>
              {
                isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '存檔'
              }
            </button>
          </div>

        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ProjectEdit;