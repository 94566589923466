import { Link } from "react-router-dom";
import styled from '@emotion/styled';

import moment from 'moment';
import { notifySuccess, notifyFailed, notifyWarning } from './../../assets/toaster';

import { FormAPI, DocumentAPI } from './../../assets/api';
import tools from './../../assets/tools';
import { useContext } from "react";
import { ProjectContext } from './../../contexts/ProjectContext';

import Pagination, { goFirstPage } from '../../assets/Pagination';
import { ReactComponent as NullValueIcon } from "./../../../images/NullValue_Icon.svg";

const NullValueBox = styled.div`
  text-align: center;
  padding: 48px 0 40px 0;
  p{
    color: #A1A1A1;
    margin: 8px 0;
    letter-spacing: 1px;
  }
`;

const ProjectFormListNoFilter = (props) => {
  // const FormTitle = {
  //   1: '材料 / 設備 品質抽驗紀錄列表',
  //   2: '施工抽查紀錄列表',
  //   3: '設備功能運轉測試紀錄列表',
  //   4: '一般安全衛生查驗表列表',
  //   5: '異常(不合格)通知暨改善表'
  // };

  const formInfo = {
    1: { formTitle: '材料 / 設備 品質抽驗紀錄列表', apiFunc: 'GetProjectEQFormList', setList: props.setProjectEQFormList },
    2: { formTitle: '施工抽查紀錄列表', apiFunc: 'GetProjectCEFormList', setList: props.setProjectCEFormList },
    3: { formTitle: '設備功能運轉測試紀錄列表', apiFunc: 'GetProjectEFFormList', setList: props.setProjectEFFormList },
  };

  // let projectId = 1; //TODO: 動態取得
  const { projectInfo } = useContext(ProjectContext);
  let projectId = projectInfo.id;

  // const [formStatus, setFormStatus] = useState(0);

  const handleFormToUpdate = async (form) => {
    let formContent;
    switch (props.moduleSelected.id) {
    case 1: // 1: 材料設備品質表
      formContent = await GetEQFormInfo(form.id);
      break;
    case 2: // 2: 施工抽查表
      formContent = await GetConstructExamineInfo(form.id);
      break;
    case 3: // 3: 設備功能運轉測試紀錄列表
      formContent = await GetEFFormInfo(form.id);
      break;
    default:
      break;
    }
    let editForm = {
      id: form.id,
      _uuid: tools._UUID(),
      formName: form.formId,
      moduleId: props.projectFormSelected.moduleId,
      formContent,
      abnormalForm: await GetAbnormalInfo({ projectId, abnormalId: form.abnormalId || 0 })
    };

    let selectedTab = props.formTabList.find(ele => ele.id === form.id && ele.moduleId === props.projectFormSelected.moduleId);
    if (selectedTab) {
      // props.setFormTabSelected(selectedTab);
      let data = await setFormTabListPromise([...props.formTabList.filter(ele => ele._uuid !== selectedTab._uuid), editForm]);
      openFormTab(data, editForm._uuid);
    } else {
      let data = await setFormTabListPromise([...props.formTabList, editForm]);
      openFormTab(data, editForm._uuid);
    }
    props.setIsListOn(false);
  };

  const openFormTab = (list, uuid) => {
    let tabToOpen = list.find(ele => ele._uuid === uuid);
    if (tabToOpen) props.setFormTabSelected(tabToOpen);
  };

  const setFormTabListPromise = (formList) => {
    return new Promise(resolve => {
      props.setFormTabList(formList);
      resolve(formList);
    });
  };

  const setFormStatusPromise = (status) => {
    return new Promise(resolve => {
      props.setFormStatus(status);
      resolve(status);
    });
  };

  const handleFormStatusClick = async (status, moduleId) => {
    let newFormStatus = await setFormStatusPromise(status);
    try {
      switch (moduleId) {
      case 1: {
        props.setProjectEQFormList([]);
        let data = await FormAPI.GetProjectEQFormList({ projectFormId: props.projectFormSelected.id, formStatus: newFormStatus, page: 1 });
        data.formMainEQSimpleList.forEach(ele => {
          ele._uuid = tools._UUID();
        });
        props.setProjectEQFormList(data.formMainEQSimpleList);
        props.setTotalPages(data.pageInfo.pages);
        props.setCurrentPage(1);
      }
        break;
      case 2: {
        props.setProjectCEFormList([]);
        let data = await FormAPI.GetProjectCEFormList({ projectFormId: props.projectFormSelected.id, formStatus: newFormStatus, page: 1 });
        data.formMainCESimpleList.forEach(ele => {
          ele._uuid = tools._UUID();
        });
        props.setProjectCEFormList(data.formMainCESimpleList);
        props.setTotalPages(data.pages);
        props.setCurrentPage(1);
      }
        break;
      case 3: {
        props.setProjectEFFormList([]);
        let data = await FormAPI.GetProjectEFFormList({ projectFormId: props.projectFormSelected.id, formStatus: newFormStatus, page: 1 });
        data.formMainEFSimpleList.forEach(ele => {
          ele._uuid = tools._UUID();
        });
        props.setProjectEFFormList(data.formMainEFSimpleList);
        props.setTotalPages(data.pageInfo.pages);
        props.setCurrentPage(1);
      }
        break;
      default:
        break;
      }
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  };

  const handleGoAbnormalForm = async (form) => {
    let selectedTab = props.formTabList.find(ele => ele.id === form.id && ele.moduleId === 5);
    if (selectedTab) {
      props.setFormTabSelected(selectedTab);
    } else {
      let abnormalForm = await GetAbnormalInfo({ projectId, abnormalId: form.abnormalId });
      let editForm = {
        id: form.id,
        _uuid: tools._UUID(),
        formName: abnormalForm.docId,
        moduleId: 5,
        abnormalForm
      };
      let data = await setFormTabListPromise([...props.formTabList, editForm]);
      openFormTab(data, editForm._uuid);
    }
    props.setIsListOn(false);
  };

  const handleDeleteForm = async (deleteForm, moduleId) => {
    notifyWarning({ Msg: <DeleteConfirm deleteForm={deleteForm} moduleId={moduleId}/> });
  };

  const DeleteConfirm = ({ closeToast, deleteForm, moduleId }) => (
    <div>
      刪除表單？
      <button className="btnS btnRed" onClick={() => DeleteItem(deleteForm, moduleId)}>確定刪除</button>
      <button className="btnS btnWhite" onClick={closeToast}>取消</button>
    </div>
  );

  const DeleteItem = async (deleteForm, moduleId) => {
    try {
      await FormAPI.DeleteForm({
        id: deleteForm.id,
        module: moduleId
      });
      notifySuccess({ Msg: '刪除成功', cbfunc: '' });
      switch (moduleId) {
      case 1:
        await props.GetProjectEQFormNumList();
        await goFirstPage({ setCurrentPage: props.setCurrentPage, apiFunc: 'GetProjectEQFormList', projectFormSelectedId: props.projectFormSelected.id, formStatus: props.formStatus, setList: props.setProjectEQFormList, setTotalPages: props.setTotalPages });
        break;
      case 2:
        await props.GetProjectCEFormNumList();
        await goFirstPage({ setCurrentPage: props.setCurrentPage, apiFunc: 'GetProjectCEFormList', projectFormSelectedId: props.projectFormSelected.id, formStatus: props.formStatus, setList: props.setProjectCEFormList, setTotalPages: props.setTotalPages });
        break;
      case 3:
        await props.GetProjectEFFormNumList();
        await goFirstPage({ setCurrentPage: props.setCurrentPage, apiFunc: 'GetProjectEFFormList', projectFormSelectedId: props.projectFormSelected.id, formStatus: props.formStatus, setList: props.setProjectEFFormList, setTotalPages: props.setTotalPages });
        break;
      default:
        break;
      }
    } catch (error) {
      notifyFailed({ Msg: error.response.data, isReload: false });
    }
  };

  // 取得材料設備品質表單
  async function GetEQFormInfo(eqipQId) {
    try {
      let data = await FormAPI.GetEQFormInfo(eqipQId);
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  // 取得施工抽查表單
  async function GetConstructExamineInfo(constructExamineId) {
    try {
      let data = await FormAPI.GetConstructExamineInfo(constructExamineId);
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  // 取得設備功能運轉測試表單
  async function GetEFFormInfo(equipFuncId) {
    try {
      let data = await FormAPI.GetEFFormInfo(equipFuncId);
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  // 取得異常表單
  async function GetAbnormalInfo({ projectId, abnormalId }) {
    try {
      let data = await FormAPI.GetAbnormalInfo({ projectId, abnormalId });
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }


  // 匯出PDF
  const ExportPDF = async (expoForm) => {
    const setListItemTrue = async (setList, list) => {
      setList(list.map(ele => {
        return (
          ele._uuid === expoForm._uuid ? {
            ...ele,
            IsPDFExporting: true
          } : ele
        );
      }));
    };
    const setListItemFalse = async (setList, list) => {
      setList(list.map(ele => {
        return (
          ele._uuid === expoForm._uuid ? {
            ...ele,
            IsPDFExporting: false
          } : ele
        );
      }));
    };
    switch (props.moduleSelected.id) {
    case 1:
      setListItemTrue(props.setProjectEQFormList, props.projectEQFormList);
      await CreatePdf(expoForm);
      setListItemFalse(props.setProjectEQFormList, props.projectEQFormList);
      break;
    case 2:
      setListItemTrue(props.setProjectCEFormList, props.projectCEFormList);
      await CreatePdf(expoForm);
      setListItemFalse(props.setProjectCEFormList, props.projectCEFormList);
      break;
    case 3:
      setListItemTrue(props.setProjectEFFormList, props.projectEFFormList);
      await CreatePdf(expoForm);
      setListItemFalse(props.setProjectEFFormList, props.projectEFFormList);
      break;
    default:
      break;
    }
  };
  const CreatePdf = async (expoForm) => {
    switch (props.moduleSelected.id) {
    case 1: // 材料設備品質
      try {
        let data = await DocumentAPI.CreateEQPdf(expoForm.id);
        tools.AjaxFileDownload({ rs: data, filename: `材料/設備品質抽驗紀錄表 - ${expoForm.formId || ''}`, fileType: 'pdf' });
      } catch (error) {
        notifyFailed({ Msg: '發生錯誤，匯出文件失敗', isReload: false });
      }
      break;
    case 2: // 施工抽查
      try {
        let data = await DocumentAPI.CreateCEPdf(expoForm.id);
        tools.AjaxFileDownload({ rs: data, filename: `施工抽查紀錄表 - ${expoForm.formId || ''}`, fileType: 'pdf' });
      } catch (error) {
        notifyFailed({ Msg: '發生錯誤，匯出文件失敗', isReload: false });
      }
      break;
    case 3: // 設備功能
      try {
        let data = await DocumentAPI.CreateEFPdf(expoForm.id);
        tools.AjaxFileDownload({ rs: data, filename: `設備功能運轉測試表 - ${expoForm.formId || ''}`, fileType: 'pdf' });
      } catch (error) {
        notifyFailed({ Msg: '發生錯誤，匯出文件失敗', isReload: false });
      }
      break;
    default:
      break;
    }
  };

  return (
    <>
      {
        ((props.moduleSelected.id !== 5 && !props.projectFormSelected.id) || props.isListClick === false) ?
          <NullValueBox>
            <NullValueIcon />
            <p>請選擇專案表單，並點擊查看列表</p>
          </NullValueBox> :
          <div>
            <div className="commontitle">
              <h1>{formInfo[props.moduleSelected.id].formTitle}</h1>
            </div>

            <div className="statusbar">
              <button className={Number(props.formStatus) === 0 ? "btnM btnGrey" : "btnM btnWhite"} value={0} onClick={() => handleFormStatusClick(0, props.moduleSelected.id)}>草稿 <span>({props.formStatusNum.numDraft})</span> </button>
              <button className={Number(props.formStatus) === 1 ? "btnM btnGrey" : "btnM btnWhite"} value={1} onClick={() => handleFormStatusClick(1, props.moduleSelected.id)}>審核中 <span>({props.formStatusNum.numAudit})</span> </button>
              <button className={Number(props.formStatus) === 2 ? "btnM btnGrey" : "btnM btnWhite"} value={2} onClick={() => handleFormStatusClick(2, props.moduleSelected.id)}>已結案 <span>({props.formStatusNum.numClosed})</span> </button>
              <button className={Number(props.formStatus) === 3 ? "btnM btnGrey" : "btnM btnWhite"} value={3} onClick={() => handleFormStatusClick(3, props.moduleSelected.id)}>已刪除 <span>({props.formStatusNum.numDelete})</span> </button>
            </div>

            {/* 材料 / 設備 品質抽驗紀錄列表 */}
            {
              Number(props.moduleSelected.id) === 1 && (
                <div>
                  {
                    props.projectEQFormList.length > 0 ? (
                      <div className="table">
                        <table>
                          <tbody>
                            <tr>
                              <td>檢查日期</td>
                              <td>表單編號</td>
                              <td>材料 / 設備名稱</td>
                              <td>安裝地點</td>
                              <td>合格 / 不合格</td>
                              {
                                props.formStatus === 0 && (<td>刪除</td>)
                              }
                              {
                                props.formStatus === 2 && <td>匯出PDF</td>
                              }
                            </tr>
                            {
                              props.projectEQFormList.map(ele => (
                              // <tr key={ele._uuid} style={{ backgroundColor: ele.isNeedSign ? 'antiquewhite': '' }}>
                                <tr key={ele._uuid} className={ele.isNeedSign ? 'NeedSign' : ''}>
                                  <td>{ele.examineDate ? moment(ele.examineDate).format('YYYY-MM-DD') : ''}</td>
                                  <td>
                                    <Link to={{ pathname: `form-list/${props.moduleSelected.id}/${ele.id}` }} onClick={() => handleFormToUpdate(ele)} className="nowrap">{ele.formId}</Link>
                                    {
                                      ele.isNeedSign && (<span className='icon_needsign'><i className="fas fa-pen-square"></i></span>)
                                    }
                                    {
                                      ele.abnormalId > 0 && (<span className="icon_abnormal" title="異常(不合格)通知暨改善表" onClick={() => handleGoAbnormalForm(ele)}><i className="fas fa-exclamation-circle"></i></span>)
                                    }
                                  </td>
                                  <td>{ele.eqipName}</td>
                                  <td>{ele.location}</td>
                                  <td>{ele.isQualified ? '合格' : '不合格'}</td>
                                  {
                                    props.formStatus === 0 && (<td><button className="btnS btnRed" onClick={() => handleDeleteForm(ele, 1)}><i className="far fa-trash-alt"></i></button></td>)
                                  }
                                  {
                                    props.formStatus === 2 &&
                                    (<td>
                                      <button className="btnS btnBlue" onClick={() => ExportPDF(ele)}>
                                        {
                                          ele.IsPDFExporting === true ?
                                            <div>
                                              匯出中，請稍後...
                                              <i className="fas fa-circle-notch fa-spin"></i>
                                            </div> : '匯出PDF'
                                        }
                                      </button>
                                    </td>)
                                  }
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>) :
                      <NullValueBox>
                        <NullValueIcon />
                        <p>暫無紀錄</p>
                      </NullValueBox>
                  }
                </div>
              )
            }


            {/* 施工抽查紀錄列表 */}
            {
              Number(props.moduleSelected.id) === 2 && (
                <div>
                  {
                    props.projectCEFormList.length > 0 ?
                      (<div className="table">
                        <table>
                          <tbody>
                            <tr>
                              <td>檢查日期</td>
                              <td>表單編號</td>
                              <td>檢查位置</td>
                              <td>施工流程</td>
                              <td>合格 / 不合格</td>
                              {
                                props.formStatus === 0 && (<td>刪除</td>)
                              }
                              {
                                props.formStatus === 2 && <td>匯出PDF</td>
                              }
                            </tr>
                            {
                              props.projectCEFormList.map(ele => (
                                // <tr key={ele._uuid} style={{ backgroundColor: ele.isNeedSign ? 'antiquewhite': '' }}>
                                <tr key={ele._uuid} className={ele.isNeedSign ? 'NeedSign' : ''}>
                                  <td>{ele.examineSDate ? moment(ele.examineSDate).format('YYYY-MM-DD') : ''}</td>
                                  <td>
                                    {/* <a onClick={() => handleFormToUpdate(ele)} href='#!'>{ele.formId}</a> */}
                                    {
                                      ele.isRandom && (<i className="fas fa-inbox" style={{ color: "#8D8B8B", marginRight: "4px", }}></i>)
                                    }
                                    <Link to={{ pathname: `form-list/${props.moduleSelected.id}/${ele.id}` }} onClick={() => handleFormToUpdate(ele)} className="nowrap">{ele.formId}</Link>
                                    {
                                      ele.isNeedSign && (<span className='icon_needsign'><i className="fas fa-pen-square"></i></span>)
                                    }
                                    {
                                      ele.abnormalId > 0 && (<span className="icon_abnormal" title="異常(不合格)通知暨改善表" onClick={() => handleGoAbnormalForm(ele)}><i className="fas fa-exclamation-circle"></i></span>)
                                    }
                                  </td>
                                  <td>{ele.location}</td>
                                  <td>{ele.constructionProcess}</td>
                                  <td>{ele.isQualified ? '合格' : '不合格'}</td>
                                  {
                                    props.formStatus === 0 && (<td><button className="btnS btnRed" onClick={() => handleDeleteForm(ele, 2)}><i className="far fa-trash-alt"></i></button></td>)
                                  }
                                  {
                                    props.formStatus === 2 &&
                                    (<td>
                                      <button className="btnS btnBlue" onClick={() => ExportPDF(ele)}>
                                        {
                                          ele.IsPDFExporting === true ?
                                            <div>
                                              匯出中，請稍後...
                                              <i className="fas fa-circle-notch fa-spin"></i>
                                            </div> : '匯出PDF'
                                        }
                                      </button>
                                    </td>)
                                  }
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>) :
                      <NullValueBox>
                        <NullValueIcon />
                        <p>暫無紀錄</p>
                      </NullValueBox>
                  }
                </div>
              )
            }

            {/* 設備功能運轉測試紀錄列表 */}
            {
              Number(props.moduleSelected.id) === 3 && (
                <div>
                  {
                    props.projectEFFormList.length > 0 ?
                      (<div className="table">
                        <table>
                          <tbody>
                            <tr>
                              <td>抽驗日期</td>
                              <td>表單編號</td>
                              <td>分項工程名稱</td>
                              <td>抽驗位置</td>
                              <td>測試流程</td>
                              <td>合格 / 不合格</td>
                              {
                                props.formStatus === 0 && (<td>刪除</td>)
                              }
                              {
                                props.formStatus === 2 && <td>匯出PDF</td>
                              }
                            </tr>
                            {
                              props.projectEFFormList.map(ele => (
                                // <tr key={ele._uuid} style={{ backgroundColor: ele.isNeedSign ? 'antiquewhite': '' }}>
                                <tr key={ele._uuid} className={ele.isNeedSign ? 'NeedSign' : ''}>
                                  <td>{ele.examineDate ? moment(ele.examineDate).format('YYYY-MM-DD') : ''}</td>
                                  <td>
                                    <Link to={{ pathname: `form-list/${props.moduleSelected.id}/${ele.id}` }} onClick={() => handleFormToUpdate(ele)} className="nowrap">{ele.formId}</Link>
                                    {
                                      ele.isNeedSign && (<span className='icon_needsign'><i className="fas fa-pen-square"></i></span>)
                                    }
                                    {
                                      ele.abnormalId > 0 && (<span className="icon_abnormal" title="異常(不合格)通知暨改善表" onClick={() => handleGoAbnormalForm(ele)}><i className="fas fa-exclamation-circle"></i></span>)
                                    }
                                  </td>
                                  <td>{ele.subProjectName}</td>
                                  <td>{ele.location}</td>
                                  <td>{ele.strProcedure}</td>
                                  <td>{ele.isQualified ? '合格' : '不合格'}</td>
                                  {
                                    props.formStatus === 0 && (<td><button className="btnS btnRed" onClick={() => handleDeleteForm(ele, 3)}><i className="far fa-trash-alt"></i></button></td>)
                                  }
                                  {
                                    props.formStatus === 2 &&
                                    (<td>
                                      <button className="btnS btnBlue" onClick={() => ExportPDF(ele)}>
                                        {
                                          ele.IsPDFExporting === true ?
                                            <div>
                                              匯出中，請稍後...
                                              <i className="fas fa-circle-notch fa-spin"></i>
                                            </div> : '匯出PDF'
                                        }
                                      </button>
                                    </td>)
                                  }
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>) :
                      <NullValueBox>
                        <NullValueIcon />
                        <p>暫無紀錄</p>
                      </NullValueBox>
                  }
                </div>
              )
            }

            <Pagination
              formStatus={props.formStatus}
              setFormStatus={props.setFormStatus}
              totalPages={props.totalPages}
              setTotalPages={props.setTotalPages}
              currentPage={props.currentPage}
              setCurrentPage={props.setCurrentPage}
              projectFormSelected={props.projectFormSelected}
              apiFunc={formInfo[props.moduleSelected.id].apiFunc}
              setList={formInfo[props.moduleSelected.id].setList}
              pagesModuleId={props.pagesModuleId}
            />
          </div>
      }
    </>
  );
};

export default ProjectFormListNoFilter;