import API from "./ApiConfig";

const AccountAPI = {
  // 取得使用者(自己)資料
  GetUserInfo: (username) => {
    let path = username ? `/Account/GetUserInfo/${username}` : `/Account/GetUserInfo`;
    return new Promise((resolve, reject) => {
      API.get(path)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得使用者(自己)資料(分頁用)
  GetUserInfoByPage: ({page, username }) => {
    return new Promise((resolve, reject) => {
      API.get(`/Account/GetUserInfoByPage/${page}/${username}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 新增使用者資料
  Register: (accountRegister) => {
    return new Promise((resolve, reject) => {
      API.post(`/Account/Register`, accountRegister)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 修改使用者資料
  Modify: (accountModify) => {
    return new Promise((resolve, reject) => {
      API.post(`/Account/Modify`, accountModify)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // (Admin)使用者列表
  GetUserList: ({ keyword }) => {
    return new Promise((resolve, reject) => {
      API.get(`/Account/GetUserList/${keyword}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // (Admin)使用者列表(分頁)
  GetUserListBypage: ({ page, keyword }) => {
    return new Promise((resolve, reject) => {
      API.get(`/Account/GetUserListBypage/${page}/${keyword}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetUserStampList: ({ page }) => {
    return new Promise((resolve, reject) => {
      API.get(`/Account/GetUserStampList/${page}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetUserStamp: (userStampID) => {
    return new Promise((resolve, reject) => {
      API.get(`/Account/GetUserStamp/${userStampID}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  ModifyUserStamp: ({ userStamp }) => {
    return new Promise((resolve, reject) => {
      API.post(`/Account/ModifyUserStamp`, userStamp)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export default AccountAPI;