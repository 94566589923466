import { useState, useContext } from 'react';

import { ToastContainer } from 'react-toastify';

import QueryPanel from './QueryPanel';
import ProjectFormListNoFilter from './ProjectFormList/ProjectFormListNoFilter';
import ProjectFormListFilter from './ProjectFormList/ProjectFormListFilter';
import FormDetail from './FormDetail';
import { ProjectContext } from './../contexts/ProjectContext';

import { FormAPI, ProjectAPI, SupervisionAPI } from './../assets/api';
import { notifyFailed } from './../assets/toaster';
import tools from './../assets/tools';

// import styled from '@emotion/styled';
// const FloatPointCoat = styled.a`
//     color:#333;
// `;
// const FloatPoint = styled.div`
//     border: 1px solid #AAA;
//     background: #EEE8;
//     position: fixed;
//     bottom: 2em;
//     right: 2em;
//     border-radius: 50%;
//     width: 3.5em;
//     height: 3.5em;
//     text-align: center;
//     span{
//       position: relative;
//       top: 0.35em;
//     }
// `;

const FormList = () => {
  // let projectId = 1; //TODO: 動態帶projectId
  const { projectInfo } = useContext(ProjectContext);
  let projectId = projectInfo.id;
  const [moduleList, setModuleList] = useState([]);
  const [projectFormList, setProjectFormList] =useState([]);
  // 目前選取module
  const [moduleSelected, setModuleSelected] = useState({
    id: '',
    moduleName: ''
  });

  const [projectFormSelected, setProjectFormSelected] = useState({});

  const [formStatus, setFormStatus] = useState(0); // 選擇表單狀態
  const [formStatusNum, setFormStatusNum] = useState([]); // 表單各狀態數量

  const [isListOn, setIsListOn] = useState(true); // 是否顯示列表
  const [isListClick, setIsListClick] = useState(false); // 是否點擊[查看列表]
  const [formTabList, setFormTabList] = useState([]); // 開啟分頁頁籤
  const [formTabSelected, setFormTabSelected] = useState({}); // 被選中的分頁頁籤

  const [totalPages, setTotalPages] = useState(1); // 要到的資料總頁數
  const [currentPage, setCurrentPage] = useState(1); // 目前所在頁
  const [pagesModuleId, setPagesModuleId] = useState('');

  const [toolsNum, setToolsNum] = useState(0); // 出工機具狀態數量
  const [toolsTotalNum, setToolsTotalNum] = useState(0); // 出工機具狀態數量
  const [toolsDeleteNum, setToolsDeleteNum] = useState(0); // 出工機具狀態數量

  // 安衛搜尋
  const [searchHS, setSearchHS] = useState({
    projectFormId: 0,
    formId: "",
    vendor: "",
    location: "",
    position: "",
    examineSDate: "",
    examineEDate: "",
    formStatus: 0,
    page: 1
  });

  // 異常搜尋
  const [abnormalSearch, setAbnormalSearch] = useState({
    projectId: projectId,
    docId: "",
    vendor: "",
    caseStatus: '',
    formStatus: 0,
    noticeSDate: null,
    noticeEDate: null,
    improveSDeadline: null,
    improveEDeadline: null,
    improveSDate: null,
    improveEDate: null,
    page: 1
  });

  // 整治工作監督日報表
  const [supervisionSearch, setSupervisionSearch] = useState({
    projectId: projectId,
    formID: "",
    examineSDate: "",
    examineEDate: "",
    formStatus: 0,
    page: 1
  });

  // 取得每日出工機具 清單
  const [toolsSearch, setToolsSearch] = useState({
    projectId: projectId,
    // formID: "",
    fillinSDate: "",
    fillinEDate: "",
    formStatus: 0,
    page: 1
  });

  // 取得月報清單
  const [monthlySearch, setMonthlySearch] = useState({
    projectId: projectId,
    examineSDate: "",
    examineEDate: "",
    formStatus: 0,
    page: 1
  });

  // 各式表單列表
  const [projectEQFormList, setProjectEQFormList] = useState([]); // 材料設備品質表單 列表
  const [projectCEFormList, setProjectCEFormList] = useState([]); // 施工抽查表單 列表
  const [projectEFFormList, setProjectEFFormList] = useState([]); // 設備功能運轉測試表 列表
  const [projectHSFormList, setProjectHSFormList] = useState([]); // 安衛表單 列表
  const [projectHSIsNCO, setProjectHSIsNCO] = useState(false); // 安衛表單 是否為新工處
  const [abnormalList, setAbnormalList] = useState([]); // 異常表單 列表
  const [supervisionList, setSupervisionList] = useState([]); // 公共工程 監督日報表 列表
  const [toolsList, setToolsList] = useState([]); // 每日出工機具 列表
  const [toolsTotalList, setToolsTotalList] = useState([]); // 每日出工機具 日統計 列表
  const [supervisionMonthlyList, setSupervisionMonthlyList] = useState([]); // 月報 列表


  // 取得module列表
  async function GetModuleList({ isModuleId }) {
    try {
      let data = await ProjectAPI.GetModuleListWithNum({ projectId });
      setModuleList(data);
      if (!isModuleId) {
        setModuleSelected(data[0]);
      }
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }

  // 取得專案特定模組表單
  async function GetProjectFormList() {
    try {
      let data = await ProjectAPI.GetProjectFormListWithNum({ projectId, moduleId: moduleSelected.id });
      setProjectFormList(data);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }

  // 取得材料設備品質 各狀態筆數清單
  const GetProjectEQFormNumList = async () => {
    try {
      let data = await FormAPI.GetProjectEQFormNumList({ projectFormId: projectFormSelected.id });
      setFormStatusNum(data);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  };

  // 取得材料設備品質 清單
  async function GetProjectEQFormList({ status = null }) {
    try {
      let data = await FormAPI.GetProjectEQFormList({ projectFormId: projectFormSelected.id, formStatus: status === null ? formStatus : status, page: 1 });
      data.formMainEQSimpleList.forEach(ele => {
        ele._uuid = tools._UUID();
      });
      setProjectEQFormList(data.formMainEQSimpleList);
      setPagesModuleId(projectFormSelected.id);
      setTotalPages(data.pageInfo.pages);
      setCurrentPage(1);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }

  // 取得施工抽查表 各狀態筆數清單
  const GetProjectCEFormNumList = async () => {
    try {
      let data = await FormAPI.GetProjectCEFormNumList({ projectFormId: projectFormSelected.id });
      setFormStatusNum(data);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  };

  // 取得施工抽查表 清單
  async function GetProjectCEFormList({ status = null }) {
    try {
      let data = await FormAPI.GetProjectCEFormList({ projectFormId: projectFormSelected.id, formStatus: status === null ? formStatus : status, page: 1 });
      data.formMainCESimpleList.forEach(ele => {
        ele._uuid = tools._UUID();
      });
      setProjectCEFormList(data.formMainCESimpleList);
      setPagesModuleId(projectFormSelected.id);
      // 不要懷疑，只有施工抽查表不用data.pageInfo.pages
      setTotalPages(data.pages);
      setCurrentPage(1);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }

  // 取得設備功能運轉測試表 各狀態筆數清單
  const GetProjectEFFormNumList = async () => {
    try {
      let data = await FormAPI.GetProjectEFFormNumList({ projectFormId: projectFormSelected.id });
      setFormStatusNum(data);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  };

  // 取得設備功能運轉測試表 清單
  async function GetProjectEFFormList({ status = null }) {
    try {
      let data = await FormAPI.GetProjectEFFormList({ projectFormId: projectFormSelected.id, formStatus: status === null ? formStatus : status, page: 1 });
      data.formMainEFSimpleList.forEach(ele => {
        ele._uuid = tools._UUID();
      });
      setProjectEFFormList(data.formMainEFSimpleList);
      setPagesModuleId(projectFormSelected.id);
      setTotalPages(data.pageInfo.pages);
      setCurrentPage(1);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }


  // 取得安衛表單 清單
  async function PostProjectHSFormList(status = null) {
    setProjectHSFormList([]);
    try {
      let searchVM = {
        ...searchHS,
        projectFormId: projectFormSelected.id,
        formStatus: status === null ? formStatus : status
      };
      let data = await FormAPI.PostProjectHSFormList(searchVM);
      data.formMainHSSimpleList.forEach(ele => {
        ele._uuid = tools._UUID();
      });
      setProjectHSFormList(data.formMainHSSimpleList);
      setPagesModuleId(projectFormSelected.id);
      setProjectHSIsNCO(data.isNCO);
      setTotalPages(data.pageInfo.pages);
      setCurrentPage(1);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }

  // 取得安衛表單 各狀態筆數清單
  const PostProjectHSFormNumList = async (status) => {
    try {
      let searchVM = {
        ...searchHS,
        projectFormId: projectFormSelected.id,
        formStatus: status
      };
      let data = await FormAPI.PostProjectHSFormNumList(searchVM);
      setFormStatusNum(data);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  };

  // 取得異常表 清單
  async function PostAbnormalList(status = null) {
    setAbnormalList([]);
    try {
      let data = await FormAPI.PostAbnormalList({
        ...abnormalSearch,
        formStatus: status === null ? formStatus : status,
        caseStatus: abnormalSearch.caseStatus !== '' ? Number(abnormalSearch.caseStatus) : ''
      });
      data.abnormalList.forEach(ele => {
        ele._uuid = tools._UUID();
      });
      setAbnormalList(data.abnormalList);
      setPagesModuleId(projectFormSelected.id);
      setTotalPages(data.pageInfo.pages);
      setCurrentPage(1);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }

  // 取得異常表 各狀態筆數清單
  const PostAbnormalNumList = async (status) => {
    try {
      let data = await FormAPI.PostAbnormalNumList({
        ...abnormalSearch,
        formStatus: status,
        caseStatus: abnormalSearch.caseStatus !== '' ? Number(abnormalSearch.caseStatus) : ''
      });
      setFormStatusNum(data);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  };

  // 取得 監督日報表 清單
  async function PostSupervisionFormList(status = null) {
    setSupervisionList([]);
    try {
      let data = await SupervisionAPI.PostSupervisionFormList({
        ...supervisionSearch,
        formStatus: status === null ? formStatus : status,
      });
      data.supervisionDailyList.forEach(ele => {
        ele._uuid = tools._UUID();
        ele.IsPDFExporting = false;
      });
      setSupervisionList(data.supervisionDailyList);
      setPagesModuleId(projectFormSelected.id);
      setTotalPages(data.pageInfo.pages);
      setCurrentPage(1);
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  // 取得 監督日報表 各狀態筆數清單
  const PostDailyNumList = async (status) => {
    try {
      let data = await SupervisionAPI.PostDailyNumList({
        ...supervisionSearch,
        formStatus: status,
      });
      setFormStatusNum(data);
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  };

  // 取得每日出工機具 清單
  async function GetDPIList(status) {
    setToolsList([]);
    try {
      let data = await ProjectAPI.GetDPIList({
        ...toolsSearch,
        formStatus: status,
      });
      data.formMainDPISimpleList.forEach(ele => {
        ele._uuid = tools._UUID();
        // ele.IsPDFExporting = false;
      });
      setToolsList(data.formMainDPISimpleList);
      // setPagesModuleId(projectFormSelected.id);
      // if (status === 0) {
      //   setToolsNum(data.totalNum);
      // }
      // if (status === 1) {
      //   setToolsDeleteNum(data.totalNum);
      // }
      setTotalPages(data.pages);
      setCurrentPage(1);
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤22', isReload: false });
    }
  }

  // 取得每日出工機具 日統計 清單
  async function GetDPITotalList(status) {
    setToolsTotalList([]);
    try {
      let data = await ProjectAPI.GetDPITotalList({
        ...toolsSearch,
        formStatus: status,
      });
      data.formMainDPITotalListSimpleList.forEach(ele => {
        ele._uuid = tools._UUID();
      });
      setToolsTotalList(data.formMainDPITotalListSimpleList);
      // setToolsTotalNum(data.totalNum);
      setTotalPages(data.pages);
      setCurrentPage(1);
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤33', isReload: false });
    }
  }

  // 取得 取得每日出工機具 各狀態筆數清單
  const PostDPINumList = async () => {
    try {
      let data = await ProjectAPI.PostDPINumList({
        ...toolsSearch,
      });
      // console.log(data);
      setToolsNum(data.numList);
      setToolsTotalNum(data.numTotal);
      setToolsDeleteNum(data.numDelete);
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  };

  // 取得 月報表 清單
  async function PostMonthlyFormList(status = null) {
    setSupervisionMonthlyList([]);
    try {
      let data = await SupervisionAPI.PostMonthlyFormList({
        ...monthlySearch,
        formStatus: status === null ? formStatus : status,
      });
      data.supervisionMonthlyList.forEach(ele => {
        ele._uuid = tools._UUID();
        ele.IsPDFExporting = false;
      });
      setSupervisionMonthlyList(data.supervisionMonthlyList);
      setTotalPages(data.pageInfo.pages);
      setCurrentPage(1);
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  // 取得 月報表 各狀態筆數清單
  const PostMonthlyNumList = async (status) => {
    try {
      let data = await SupervisionAPI.PostMonthlyNumList({
        ...monthlySearch,
        formStatus: status,
      });
      setFormStatusNum(data);
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  };


  return (
    <>
      <div className="scroll-page" id="Top"></div>
      <QueryPanel
        isListOn={isListOn}
        setIsListOn={setIsListOn}
        setIsListClick={setIsListClick}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        setFormStatus={setFormStatus}
        moduleList={moduleList}
        GetModuleList={({ isModuleId }) => GetModuleList({ isModuleId })}
        projectFormList={projectFormList}
        GetProjectFormList={GetProjectFormList}
        moduleSelected={moduleSelected} // 選擇之模組
        setModuleSelected={setModuleSelected} // 設定選擇模組
        projectFormSelected={projectFormSelected} // 模組表單選擇
        setProjectFormSelected={setProjectFormSelected} // 設定模組表單選擇
        GetProjectEQFormList={GetProjectEQFormList} // 材料設備品質列表api
        GetProjectEQFormNumList={GetProjectEQFormNumList} // 材料設備品質各狀態數量api
        GetProjectCEFormList={GetProjectCEFormList} // 施工抽查列表api
        GetProjectCEFormNumList={GetProjectCEFormNumList} // 施工抽查各狀態數量api
        GetProjectEFFormList={GetProjectEFFormList} // 設備功能運轉測試表列表api
        GetProjectEFFormNumList={GetProjectEFFormNumList} // 設備功能運轉測試表各狀態數量api
        PostProjectHSFormList={(status) => PostProjectHSFormList(status)} // 安衛表單api
        PostProjectHSFormNumList={(status) => PostProjectHSFormNumList(status)} // 安衛表單各狀態數量api
        PostAbnormalList={(status) => PostAbnormalList(status)} // 異常表單api
        PostAbnormalNumList={(status) => PostAbnormalNumList(status)} // 異常表單各狀態數量api
        PostSupervisionFormList={(status) => PostSupervisionFormList(status)} // 監造日報表 api
        GetDPIList={(status) => GetDPIList(status)} // 每日出工機具 api
        GetDPITotalList={(status) => GetDPITotalList(status)} // 每日出工機具 日統計 api
        PostDailyNumList={(status) => PostDailyNumList(status)} // 監造日報表 各狀態數量api
        PostDPINumList={() => PostDPINumList()} // 出工機具 各狀態數量api
        PostMonthlyFormList={(status) => PostMonthlyFormList(status)}//月報api
        PostMonthlyNumList={(status) => PostMonthlyNumList(status)} //月報各狀態數量api
        formTabList={formTabList} // 開啟表單頁籤
        setFormTabList={setFormTabList} // 設定開啟表單頁籤
        setFormTabSelected={setFormTabSelected} // 設定選擇頁籤
        toolsSearch={toolsSearch}
      />
      <div className="main" style={{ display: isListOn ? "block" : "none" }}>
        {
          moduleSelected.id > 0 ?
            ((Number(moduleSelected.id) === 1 || Number(moduleSelected.id) === 2 || Number(moduleSelected.id) === 3) ?
              <ProjectFormListNoFilter
                moduleSelected={moduleSelected}
                formStatus={formStatus}
                setFormStatus={setFormStatus}
                formStatusNum={formStatusNum}
                GetProjectEQFormList={GetProjectEQFormList}
                GetProjectEQFormNumList={GetProjectEQFormNumList}
                projectEQFormList={projectEQFormList}
                setProjectEQFormList={setProjectEQFormList}
                GetProjectCEFormList={GetProjectCEFormList}
                GetProjectCEFormNumList={GetProjectCEFormNumList}
                projectCEFormList={projectCEFormList}
                setProjectCEFormList={setProjectCEFormList}
                GetProjectEFFormList={GetProjectEFFormList} // 設備功能運轉測試表列表api
                GetProjectEFFormNumList={GetProjectEFFormNumList} // 設備功能運轉測試表各狀態數量api
                projectEFFormList={projectEFFormList}
                setProjectEFFormList={setProjectEFFormList}
                formTabList={formTabList}
                setFormTabList={setFormTabList}
                setFormTabSelected={setFormTabSelected}
                projectFormSelected={projectFormSelected}
                setIsListOn={setIsListOn}
                isListClick={isListClick}
                setTotalPages={setTotalPages}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                pagesModuleId={pagesModuleId}
              /> :
              <ProjectFormListFilter
                moduleSelected={moduleSelected}
                projectFormSelected={projectFormSelected}
                formStatus={formStatus}
                setFormStatus={setFormStatus}
                formStatusNum={formStatusNum}
                formTabList={formTabList}
                setFormTabList={setFormTabList}
                setFormTabSelected={setFormTabSelected}
                setIsListOn={setIsListOn}
                isListClick={isListClick}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                pagesModuleId={pagesModuleId}
                searchHS={searchHS}
                setSearchHS={setSearchHS}
                PostProjectHSFormNumList={(status) => PostProjectHSFormNumList(status)} // 安衛表單各狀態數量api
                PostProjectHSFormList={(status) => PostProjectHSFormList(status)} // 安衛表單api
                projectHSFormList={projectHSFormList}
                setProjectHSFormList={setProjectHSFormList}
                projectHSIsNCO={projectHSIsNCO}
                setProjectHSIsNCO={setProjectHSIsNCO}
                PostAbnormalList={(status) => PostAbnormalList(status)} // 異常表單api
                PostAbnormalNumList={(status) => PostAbnormalNumList(status)} // 異常表單各狀態數量api
                abnormalSearch={abnormalSearch}
                setAbnormalSearch={setAbnormalSearch}
                abnormalList={abnormalList}
                setAbnormalList={setAbnormalList}
                PostSupervisionFormList={(status) => PostSupervisionFormList(status)} // 監造日報表api
                GetDPIList={(status) => GetDPIList(status)} // 每日出工機具 api
                GetDPITotalList={(status) => GetDPITotalList(status)} // 每日出工機具 日統計 api
                PostDailyNumList={(status) => PostDailyNumList(status)} // 監造日報表
                PostDPINumList={() => PostDPINumList()} // 出工機具 各狀態數量api
                supervisionSearch={supervisionSearch}
                setSupervisionSearch={setSupervisionSearch}
                supervisionList={supervisionList}
                setSupervisionList={setSupervisionList}
                monthlySearch={monthlySearch}
                setMonthlySearch={setMonthlySearch}
                supervisionMonthlyList={supervisionMonthlyList}
                setSupervisionMonthlyList={setSupervisionMonthlyList}
                PostMonthlyFormList={(status) => PostMonthlyFormList(status)}
                PostMonthlyNumList={() => PostMonthlyNumList()}
                toolsSearch={toolsSearch}
                setToolsSearch={setToolsSearch}
                toolsList={toolsList}
                setToolsList={setToolsList}
                toolsTotalList={toolsTotalList}
                setToolsTotalList={setToolsTotalList}
                toolsNum={toolsNum}
                toolsTotalNum={toolsTotalNum}
                toolsDeleteNum={toolsDeleteNum}
              />) :
            null
        }
      </div>
      <div style={{ display: isListOn ? "none" : "block" }}>
        <FormDetail
          GetModuleList={({ isModuleId }) => GetModuleList({ isModuleId })}
          GetProjectFormList={GetProjectFormList}
          moduleSelected={moduleSelected} // 選擇之模組
          setModuleSelected={setModuleSelected} // 設定選擇模組
          formTabList={formTabList}
          setFormTabList={setFormTabList}
          formTabSelected={formTabSelected}
          setFormTabSelected={setFormTabSelected}
          setFormStatus={setFormStatus}
          setIsListOn={setIsListOn}
          setProjectEQFormList={setProjectEQFormList}
          setProjectCEFormList={setProjectCEFormList}
          setProjectEFFormList={setProjectEFFormList}
          setProjectHSFormList={setProjectHSFormList}
          setProjectHSIsNCO={setProjectHSIsNCO}
          setAbnormalList={setAbnormalList}
          setSupervisionList={setSupervisionList}
          setSupervisionMonthlyList={setSupervisionMonthlyList}
          setTotalPages={setTotalPages}
          setFormStatusNum={setFormStatusNum}
        />
      </div>
      <ToastContainer />
      {/* <FloatPointCoat href="#Top"><FloatPoint><span>電梯<br />上樓</span></FloatPoint></FloatPointCoat> */}
    </>
  );
};
export default FormList;