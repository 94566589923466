import './../../style/Manage.scss';
import FormList from './FormList';
// import FormItemManage from './FormItemManage';
import UserManage from './UserManage';
import UserDetail from './UserDetail';
import ProjectManage from './ProjectManage';
import ProjectsGroupManage from './ProjectsGroupManage';
import WeatherManage from './WeatherManage';
import ProjectEdit from './ProjectEdit';
import ProjectGroupEdit from './ProjectGroupEdit';
import { Route, useRouteMatch } from 'react-router-dom';
// import { useLocation } from 'react-router-dom';

const Manage = () => {

  // const location = useLocation();

  let { path } = useRouteMatch();

  // const RouteArea = (props) => {

  //   const { isExist, addr, compo } = props;

  //   if (addr === location.pathname.split("/")[2]) {
  //     return (
  //       <>
  //         <div className="nav" style={{ display: isExist === true ? 'block' : 'none', }} >
  //           <div className="queryMenu">
  //             <select size="4">
  //               <option >異常(不合格)通知暨改善表</option>
  //               <option>異常(不合格)通知暨改善表</option>
  //               <option>異常(不合格)通知暨改善表</option>
  //               <option>異常(不合格)通知暨改善表</option>
  //               <option>異常(不合格)通知暨改善表異常(不合格)通知暨改善表異常(不合格)通知暨改善表</option>
  //               <option>異常(不合格)通知暨改善表異常(不合格)通知暨改善表</option>
  //               <option>異常(不合格)通知暨改善表異常(不合格)通知暨改善表</option>
  //               <option>異常(不合格)通知暨改善表異常(不合格)通知暨改善表</option>
  //             </select>
  //             <select size="4">
  //               <option >OXOX施工抽查紀錄表</option>
  //               <option>OXOX施工抽查紀錄表OXOX施工抽查紀錄表</option>
  //               <option>OXOX施工抽查紀錄表</option>
  //               <option>OXOX施工抽查紀錄表OXOX施工抽查紀錄表</option>
  //             </select>
  //             <div className="queryMenu_btnbox">
  //               <button className="btnM btnGrey">查看列表</button>
  //               <button className="btnM btnWhite">新增表單</button>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="main">
  //           <Switch>
  //             <Route path={`${path}/${addr}`} component={compo}></Route>
  //           </Switch>
  //         </div>
  //       </>
  //     );
  //   } else {
  //     return(<></>);
  //   }

  // };

  // return (
  //   <>
  //     <RouteArea isExist={true} addr={"list"} compo={ManageList}></RouteArea>
  //     <RouteArea isExist={false} addr={"user-manage"} compo={UserManage}></RouteArea>
  //     <RouteArea isExist={false} addr={"user-detail"} compo={UserDetail}></RouteArea>
  //   </>
  // );

  return (
    <>
      <Route path={`${path}/project-material-manage`} component={FormList}></Route>
      {/* <Route path={`${path}/form-item-manage/:moduleid/:formid`} component={FormItemManage}></Route> */}
      <div className="main">
        <Route path={`${path}/user-manage`} component={UserManage}></Route>
        <Route path={`${path}/user-detail/:username`} component={UserDetail}></Route>
        <Route path={`${path}/project-manage`} component={ProjectManage}></Route>
        <Route path={`${path}/project-group-manage`} component={ProjectsGroupManage}></Route>
        <Route path={`${path}/project-edit/:projectid`} component={ProjectEdit}></Route>
        <Route path={`${path}/project-group-edit/:projectid`} component={ProjectGroupEdit}></Route>
        <Route path={`${path}/weather-manage`} component={WeatherManage}></Route>
      </div>
    </>
  );











  // const Panel = (props) => {

  //   const { isExist } = props;

  //   if (isExist) {
  //     return (
  //       <>
  //         <div className="nav">
  //           <div className="queryMenu">
  //             <select size="4">
  //               <option >異常(不合格)通知暨改善表</option>
  //               <option>異常(不合格)通知暨改善表</option>
  //               <option>異常(不合格)通知暨改善表</option>
  //               <option>異常(不合格)通知暨改善表</option>
  //               <option>異常(不合格)通知暨改善表異常(不合格)通知暨改善表異常(不合格)通知暨改善表</option>
  //               <option>異常(不合格)通知暨改善表異常(不合格)通知暨改善表</option>
  //               <option>異常(不合格)通知暨改善表異常(不合格)通知暨改善表</option>
  //               <option>異常(不合格)通知暨改善表異常(不合格)通知暨改善表</option>
  //             </select>
  //             <select size="4">
  //               <option >OXOX施工抽查紀錄表</option>
  //               <option>OXOX施工抽查紀錄表OXOX施工抽查紀錄表</option>
  //               <option>OXOX施工抽查紀錄表</option>
  //               <option>OXOX施工抽查紀錄表OXOX施工抽查紀錄表</option>
  //             </select>
  //             <div className="queryMenu_btnbox">
  //               <button className="btnM btnGrey">查看列表</button>
  //               <button className="btnM btnWhite">新增表單</button>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="main">
  //           <Switch>
  //             <Route path={`${path}/list`} component={ManageList}></Route>
  //           </Switch>
  //         </div>
  //       </>
  //     );
  //   } else {
  //     return (
  //       <>
  //         <div className="main">
  //           <Switch>
  //             <Route path={`${path}/user-manage`} component={UserManage}></Route>
  //           </Switch>
  //         </div>
  //       </>
  //     );
  //   }
  // };

  // return (
  //   <>
  //     <Panel isExist={true}></Panel>
  //     <Panel isExist={false}></Panel>
  //   </>
  // );












  // return (
  //   <>
  //     <div className="nav">
  //       <div className="queryMenu">
  //         <select size="4">
  //           <option >異常(不合格)通知暨改善表</option>
  //           <option>異常(不合格)通知暨改善表</option>
  //           <option>異常(不合格)通知暨改善表</option>
  //           <option>異常(不合格)通知暨改善表</option>
  //           <option>異常(不合格)通知暨改善表異常(不合格)通知暨改善表異常(不合格)通知暨改善表</option>
  //           <option>異常(不合格)通知暨改善表異常(不合格)通知暨改善表</option>
  //           <option>異常(不合格)通知暨改善表異常(不合格)通知暨改善表</option>
  //           <option>異常(不合格)通知暨改善表異常(不合格)通知暨改善表</option>
  //         </select>
  //         <select size="4">
  //           <option >OXOX施工抽查紀錄表</option>
  //           <option>OXOX施工抽查紀錄表OXOX施工抽查紀錄表</option>
  //           <option>OXOX施工抽查紀錄表</option>
  //           <option>OXOX施工抽查紀錄表OXOX施工抽查紀錄表</option>
  //         </select>
  //         <div className="queryMenu_btnbox">
  //           <button className="btnM btnGrey">查看列表</button>
  //           <button className="btnM btnWhite">新增表單</button>
  //         </div>
  //       </div>
  //     </div>

  //     <div className="main">
  //       <Switch>
  //         <Route path={`${path}/list`} component={ManageList}></Route>
  //       </Switch>
  //     </div>
  //   </>
  // );
};
export default Manage;