import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const notifySuccess = ({ Msg, cbfunc }) => toast.success(Msg, {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  onClose: cbfunc
});

export const notifyFailed = ({Msg, isReload}) => toast.error(Msg, {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  onClose: () => {
    isReload ? window.location.reload() : '';
  }
});

export const notifyWarning = ({Msg}) => toast.warn(Msg, {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
});

