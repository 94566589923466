import TableauDashboard from './TableauDashboard';

const DashboardDetail = () => {
  const url = 'https://bi3.stantec.com.tw/views/CMS_new/Dashboard_detail?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link';
  return (
    <>
      <TableauDashboard url={url}/>
    </>
  );
};
export default DashboardDetail;