import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import StackedBarChartGenerator from "./Generators/StackedBarChartGenerator";
import styled from '@emotion/styled';

/* ref: https://medium.com/@jeffbutsch/using-d3-in-react-with-hooks-4a6c61f1d102 */
/* Component */
const ScrollDiv = styled.div`
  ::-webkit-scrollbar {
    height: 3px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #A7A5A6;
  }
  ::-webkit-scrollbar-track {
    background-color: #555;
  }
`;

export const D3StackedBarChart = (props) => {
  /* The useRef Hook creates a variable that "holds on" to a value across rendering
     passes. In this case it will hold our component's SVG DOM element. It's
     initialized null and React will assign it later (see the return statement) */
  const d3Container = useRef(null);
  const dataLength = props.data.length / 2;
  const [width, setWidth] = useState(500);
  // construct colors
  let colors = [];
  if (props.option.colorInterpolate) colors = d3.quantize(t => d3[props.option.colorInterpolate](t * 0.8 + 0.1), props.group.length);
  if (props.option.colorScheme) colors = d3[props.option.colorScheme][props.group.length];
  if (props.option.colorCustom) {
    if (props.option.colorCustom.length === 0) {
      console.error('No color array detected');
      return;
    }
    colors = props.option.colorCustom;
  }

  /* The useEffect Hook is for running side effects outside of React,
     for instance inserting elements into the DOM using D3 */
  useEffect(
    () => {
      window.addEventListener("resize", RenderChart, false);
      const xDomain = d3.groupSort(props.data, D => d3.sum(D, d => -d[props.option.yAttr]), d => d[props.option.xAttr]);

      function RenderChart() {
        if (props.data && d3Container.current) {
          const d3Div = d3.select(d3Container.current);
          // remove last svg
          d3Div.select('svg').remove();
          // get d3Container width
          // let d3DivWidth = parseInt(d3Div.style("width"), 10);
          let d3DivWidth = d3Div.node().parentNode.clientWidth;
          setWidth(d3DivWidth);
          let chart = StackedBarChartGenerator(props.data, {
            x: d => d[props.option.xAttr],
            y: d => d[props.option.yAttr],
            z: d => d[props.option.zAttr],
            xDomain: xDomain,
            yLabel: props.option.yLabel,
            zDomain: props.group,
            colors: colors,
            // width,
            width: xDomain.length < 5 ? d3DivWidth : d3DivWidth + xDomain.length * 22.5,
            height: props.option.height || 500,
            chartId: props.option.chartId,
            marginRight: props.option.marginRight,
            stringTranslator: props.option.stringTranslator,
            titleAltColor: props.option.titleAltColor,
            xValueColor: props.option.xValueColor || "black",
            yValueColor: props.option.yValueColor || "black",
            backgroundColor: props.option.backgroundColor || "#555555",
            titleTextColor:  props.option.titleTextColor || "black",
            titleText: props.option.titleText,
            textLengthX: props.option.textLengthX || 6,
          });

          d3Div.node().append(chart);
        }
      }
      RenderChart();
    },

    /*
        useEffect has a dependency array (below). It's a list of dependency
        variables for this useEffect block. The block will run after mount
        and whenever any of these variables change. We still have to check
        if the variables are valid, but we do not have to compare old props
        to next props to decide whether to rerender.
        [props.data, d3Container.current]
    */
    [props.data, props.group, props.option]);

  return (
    <ScrollDiv
      className="d3_div" 
      id={props.option.chartId} 
      ref={d3Container} 
      style={{ 
        backgroundColor: props.option.backgroundColor, 
        ...(dataLength > 5 ? {
          maxWidth: `${width}px`,
          overflow: 'auto'
        } : {})
      }}>
    </ScrollDiv>
  );
};