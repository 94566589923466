import { createContext, useEffect, useReducer } from 'react';
import { themeReducer } from './../reducers/themeReducer';

export const ThemeContext = createContext();

const ThemeContextProvider = (props) => {
  const [theme, dispatch] = useReducer(themeReducer, 'dark', () => {
    const localData = window.localStorage.getItem('theme');
    return localData ?? 'dark';
  });

  useEffect(() => {
    window.localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, dispatch }}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;