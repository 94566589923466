// import QueryPanel from './QueryPanel';
import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';
// import { useHistory } from "react-router-dom";
import { useIsFirstRender } from './../assets/customHooks/useIsFirstRender';
import { TemplateAPI, HelperAPI } from './../assets/api';
import XLSX from 'xlsx';
import tools from './../assets/tools';

import { notifyFailed, notifySuccess, notifyWarning } from './../assets/toaster';
// import { ToastContainer } from 'react-toastify';

import Form_Item_Template from './../../assets/Form_Item_Template.xlsx';

const FakeHr = styled.div`
    width: 100%;
    height: 2px;
    background-color: #CCC;
    margin: 20px 0 35px 0;
  `;

const TxtInput = styled.textarea`
  width: 100%;
  max-width: 22em;
  // height: 28px;
  border-radius: 3px;
  border: 1px solid #AAA;
  padding: 5px;
  box-sizing: border-box;
`;

const CkboxInput = styled.input`
  margin-right: 3px;
`;

const ImportTitle = styled.h2`
    margin-top: 5px;
    font-weight: 600;
`;

const CreateItem = (props) => {
  return (
    <tr>
      <td><TxtInput value={props.item.sort} onChange={props.handleItemSortChange}/></td>
      <td><TxtInput value={props.item.itemName} onChange={props.handleItemNameChange}/></td>
      {
        props.moduleId !== 4 && (
          <td><TxtInput value={props.item.criterion} onChange={props.handleItemCriterionChange}/></td>
        )
      }
      {
        (props.moduleId === 4 && props.templateForm.isNCO) && (
          <td>
            <select name="" id="" value={props.item.hsItemCategoryID} onChange={props.handleHSCategoryChange}>
              <option value="0">請選擇</option>
              {
                props.hsItemCategoryList.filter(el => el.unitTypeID === props.templateForm.unitTypeID).map(ele => (
                  <option key={ele.id} value={ele.id}>{ele.hsItemCategory}</option>
                ))
              }
            </select>
          </td>
        )
      }
      <td>
        <label>
          <CkboxInput type="checkbox" checked={props.item.enable} onChange={props.handleItemEnableChange}/>啟用
        </label>
      </td>
      <td>
        <button className="btnM btnRed" onClick={props.handleItemDelete}>刪除</button>
      </td>
    </tr>
  );
};

const FormItemManage = (props) => {
  // const { moduleid, formid } = useParams();
  // const history = useHistory();
  const isFirstRender = useIsFirstRender();
  // const [formList, setFormList] = useState([]);
  // const [moduleSelected, setModuleSelected] = useState({
  //   id: '',
  //   moduleName: ''
  // });
  const [ pageStatus, setPageStatus] = useState(0);
  const [templateForm, setTemplateForm] = useState({
    id: 0,
    projectFormID: '',
    moduleID: '',
    formName: '',
    enable: true,
    isNCO: false,
    unitTypeID: 0,
    itemList: []
  });
  const [originalTemplateForm, setOriginalTemplateForm] = useState({});
  const [parsedData, setParsedData] = useState([]);

  const [hsItemCategoryList, setHSItemCategoryList] = useState([]);
  const [unitTypeList, setUnitTypeList] = useState([]);
  const [isReplaceExist, setIsReplaceExist] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  const importTemplateData = useRef([]);
  const uploadTemplateData = useRef(null);

  function Item ({_uuid=0, id=0, sort='', itemName='', criterion='', hsItemCategoryID=0, enable=true, status=0}) {
    this._uuid = _uuid;
    this.id = id;
    this.sort = sort;
    this.itemName = itemName;
    this.criterion = criterion;
    this.hsItemCategoryID = hsItemCategoryID;
    this.enable = enable;
    this.status = status;
  }
  async function GetHSItemCategoryList() {
    try {
      let data = await HelperAPI.GetHSItemCategoryList(true);
      setHSItemCategoryList(data);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }
  async function GetUnitTypeList() {
    try {
      let data = await HelperAPI.GetUnitTypeList(true);
      setUnitTypeList(data);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤(無法取得安衛單位)', isReload: false});
    }
  }

  async function GetTemplateForm(formid) {
    try {
      let data = await TemplateAPI.GetTemplateForm(formid);
      if (data.itemList) {
        data.itemList.forEach(ele => {
          ele._uuid = tools._UUID();
          ele.status = 0;
          ele.isExist = true; // Is this item already existed in database
          ele.isEditing = false; // Is this item being editted
        });
        setTemplateForm(data);
        setOriginalTemplateForm(data);
      }
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }

  async function GetTemplateFormList({ isClear }) {
    let moduleId = isClear ? '' : props.moduleSelected.id;
    try {
      let data = await TemplateAPI.GetTemplateFormList({ moduleId: moduleId, page: 1 });
      if (data) props.setFormList(data.formManageList);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: false});
    }
  }

  const SaveValidate = (form) => {
    if (!form.formName) {
      notifyFailed({Msg: '請填寫表單別名', isReload: false});
      return false;
    }
    if (form.moduleId === 4 && form.isNCO && !form.unitTypeID) {
      notifyFailed({Msg: '若為其他單位表單，請選擇單位名稱', isReload: false});
      return false;
    }
    if (form.templateItemList.filter(ele => ele.sort === `` || isNaN(ele.sort) || !ele.itemName).length > 0) {
      notifyFailed({Msg: '請確認顯示順序及抽驗項目', isReload: false});
      return false;
    }
    if (form.templateItemList.length === 0 || form.templateItemList.filter(ele => ele.status !== 3).length === 0) {
      notifyFailed({ Msg: '項目數量不可為0', isReload: false });
      return false;
    }
    return true;
  };

  const ModifyTemplateForm = async () => {
    setIsSaving(true);
    let templateFormModify = {
      id: templateForm.id,
      moduleId: templateForm.moduleID || props.moduleId,
      formName: templateForm.formName,
      enable: templateForm.enable,
      isNCO: templateForm.isNCO,
      unitTypeID: templateForm.unitTypeID,
      templateItemList: templateForm.itemList.concat(parsedData),
      status: pageStatus
    };
    let validate = SaveValidate(templateFormModify);
    if (validate) {
      try {
        await TemplateAPI.ModifyTemplateForm({ templateFormModify });
        if (pageStatus === 1) {
          notifySuccess({Msg: '儲存成功', cbfunc: () => {
            GetTemplateFormList({ isClear: false });
            props.setIsListOn(true);
            setIsSaving(false);
          }});
        } else if (pageStatus === 2) {
          notifySuccess({Msg: '儲存成功', cbfunc: () => GetTemplateForm(props.formId)});
          setIsSaving(false);
        }
        setTemplateForm({
          id: 0,
          projectFormID: '',
          moduleID: '',
          formName: '',
          enable: true,
          isNCO: false,
          unitTypeID: 0,
          itemList: []
        });
        uploadTemplateData.current.value = null;
        importTemplateData.current = [];
        setParsedData([]);
      } catch (error) {
        notifyFailed({Msg: error.response.data || '發生錯誤', isReload: false});
        setIsSaving(false);
      }
    } else {
      setIsSaving(false);
    }
  };

  useEffect(async() => {
    if (props.formId !== 'create') {
      if (!isFirstRender) GetTemplateForm(props.formId);
      setPageStatus(2);
    } else if (props.formId === 'create') {
      setTemplateForm({
        id: 0,
        projectFormID: '',
        moduleID: '',
        formName: '',
        enable: true,
        isNCO: false,
        unitTypeID: 0,
        itemList: []
      });
      setPageStatus(1);
    }
    await GetHSItemCategoryList();
    await GetUnitTypeList();
    uploadTemplateData.current.value = null;
    importTemplateData.current = [];
    setParsedData([]);
  }, [props.formId, props.moduleSelected.id]);

  const handleFormNameChange = (e) => {
    setTemplateForm({...templateForm, formName: e.target.value});
  };

  const handleFormEnableChange = (e) => {
    setTemplateForm({...templateForm, [e.target.name]: e.target.checked});
  };

  const handleItemCreate = () => {
    setTemplateForm({...templateForm, itemList: [...templateForm.itemList, new Item({_uuid: tools._UUID(), status: 1})]});
  };

  const handleItemUpdate = (updateItem) => {
    setTemplateForm({...templateForm, itemList: templateForm.itemList.map(ele => (ele._uuid === updateItem._uuid ? {...ele, status: 2, isEditing: true} : ele))});
  };

  const handleCancelItemUpdate = (updateItem) => {
    setTemplateForm({...templateForm, itemList: templateForm.itemList.map(ele => (ele._uuid === updateItem._uuid ? originalTemplateForm.itemList.find(ele1 => ele1._uuid === updateItem._uuid) : ele))});
  };

  const handleItemDelete = (deleteItem) => {
    notifyWarning({ Msg: <DeleteConfirm deleteItem={deleteItem}/> });
  };

  const DeleteConfirm = ({ closeToast, deleteItem }) => (
    <div>
      刪除表單項目？
      <button className="btnS btnRed" onClick={() => DeleteItem(deleteItem)}>確定刪除</button>
      <button className="btnS btnWhite" onClick={closeToast}>取消</button>
    </div>
  );

  const DeleteItem = async (deleteItem) => {
    if (deleteItem.isExist) {
      setTemplateForm({...templateForm, itemList: templateForm.itemList.map(ele => (ele._uuid === deleteItem._uuid ? {...ele, status: 3} : ele))});
    } else {
      setTemplateForm({...templateForm, itemList: templateForm.itemList.filter(ele => ele._uuid !== deleteItem._uuid)});
    }
  };

  const handleTemplateUpload = (e) => {
    if (e.target.files && e.target.files[0]){
      let reader = new FileReader();
      reader.onload = function(e) {
        // Parse data
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {type:'binary'});
        // Get first worksheet
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        // Convert array of arrays
        importTemplateData.current = XLSX.utils.sheet_to_json(ws, {header:1});
      };
      reader.readAsBinaryString(e.target.files[0]);
    }
  };

  const handleTemplateImport = () => {
    let mappedData = importTemplateData.current.map(ele => {
      return {
        id: 0,
        sort: !ele[1]? 1 : ele[1],
        itemName: ele[2],
        criterion: ele[3],
        hsItemCategoryID: props.moduleId === 4 ? 0 : '',
        enable: true,
        status: 1,
        _uuid: tools._UUID()
      };
    });
    // 是否刪除既有項目
    if (isReplaceExist) {
      const handleItemDeleteAll = () => {
        notifyWarning({ Msg: <DeleteConfirm/> });
      };

      const DeleteConfirm = ({ closeToast }) => (
        <div>
          刪除/停用既有表單項目？
          <button className="btnS btnRed" onClick={DeleteItemAll}>確定刪除</button>
          <button className="btnS btnWhite" onClick={closeToast}>取消</button>
        </div>
      );

      const DeleteItemAll = async () => {
        const deletePromise = new Promise((resolve) => {
          // 先刪除不存在DB的
          let newItemList = templateForm.itemList.filter(ele => ele.isExist);
          // 設定每個item status = 3
          newItemList = newItemList.map(ele => {
            return {...ele, status: 3};
          });
          setTemplateForm({...templateForm, itemList: newItemList});
          resolve(true);
        });
        deletePromise.then(() => {
          setParsedData(mappedData.slice(1, mappedData.length));
        });
      };

      handleItemDeleteAll();
    } else {
      setParsedData(mappedData.slice(1, mappedData.length));
    }
  };

  const handleParsedItemDelete = (deleteItem) => {
    setParsedData(parsedData.filter(ele => ele._uuid !== deleteItem._uuid));
  };

  const handleItemSortChange = (e, el) => {
    setTemplateForm({...templateForm, itemList: templateForm.itemList.map(ele => (ele._uuid === el._uuid ? {...ele, sort: e.target.value.replace(/\D/, '')} : ele))});
  };

  const handleItemNameChange = (e, el) => {
    setTemplateForm({...templateForm, itemList: templateForm.itemList.map(ele => (ele._uuid === el._uuid ? {...ele, itemName: e.target.value} : ele))});
  };

  const handleItemCriterionChange = (e, el) => {
    setTemplateForm({...templateForm, itemList: templateForm.itemList.map(ele => (ele._uuid === el._uuid ? {...ele, criterion: e.target.value} : ele))});
  };

  const handleHSCategoryChange = (e, el) => {
    setTemplateForm({...templateForm, itemList: templateForm.itemList.map(ele => (ele._uuid === el._uuid ? {...ele, hsItemCategoryID: Number(e.target.value)} : ele))});
  };

  const handleItemEnableChange = (e, el) => {
    setTemplateForm({...templateForm, itemList: templateForm.itemList.map(ele => (ele._uuid === el._uuid ? {...ele, enable: e.target.checked} : ele))});
  };

  const handleParsedItemSortChange = (e, el) => {
    setParsedData(parsedData.map(ele => (ele._uuid === el._uuid ? {...ele, sort: e.target.value} : ele)));
  };

  const handleParsedItemNameChange = (e, el) => {
    setParsedData(parsedData.map(ele => (ele._uuid === el._uuid ? {...ele, itemName: e.target.value} : ele)));
  };

  const handleParsedItemCriterionChange = (e, el) => {
    setParsedData(parsedData.map(ele => (ele._uuid === el._uuid ? {...ele, criterion: e.target.value} : ele)));
  };

  const handleParsedHSCategoryChange = (e, el) => {
    setParsedData(parsedData.map(ele => (ele._uuid === el._uuid ? {...ele, hsItemCategoryID: Number(e.target.value)} : ele)));
  };

  const handleParsedItemEnableChange = (e, el) => {
    setParsedData(parsedData.map(ele => (ele._uuid === el._uuid ? {...ele, enable: e.target.checked} : ele)));
  };

  const handleNCOUnitTypeChange = (e) => {
    setTemplateForm({...templateForm, unitTypeID: Number(e.target.value)});
  };

  return (
    <>
      {/* <QueryPanel formList={formList} setFormList={setFormList} moduleSelected={moduleSelected} setModuleSelected={setModuleSelected} /> */}
      <div className="main" style={{ maxWidth: '1450px', }}>
        <div className="commontitle">
          <h1>項目管理</h1>
        </div>

        <div className="filter_table">
          <div className="">
            <input type="text" name="" placeholder="表單別名" value={templateForm.formName} onChange={handleFormNameChange}/>
          </div>
          <div>
            <label><input type="checkbox" name="enable" checked={templateForm.enable} onChange={handleFormEnableChange}/>啟用</label>
          </div>
          {
            props.moduleId === 4 && (
              <div>
                <label><input type="checkbox" name="isNCO" checked={templateForm.isNCO} onChange={handleFormEnableChange}/>是否為其他單位表單</label>
                { templateForm.isNCO && (
                  <select name="UnitType" id="" value={templateForm.unitTypeID} onChange={(evt) => handleNCOUnitTypeChange(evt)}>
                    <option value="0">請選擇</option>
                    {
                      unitTypeList.map(ele1 => (
                        <option key={ele1.unitTypeID} value={ele1.unitTypeID}>{ele1.unit}</option>
                      ))
                    }
                  </select>
                )
                }
              </div>
            )
          }
          <br />
          <ImportTitle>匯入項目</ImportTitle>
          <div className="">
            <input type="file" name="" accept=".xlsx" ref={uploadTemplateData} onChange={handleTemplateUpload}/>
            <div className="filter_table_btn_main">
              <button className="btnM btnBlue" onClick={handleTemplateImport}>批次匯入</button>
              <button className="btnM btnOrange"><a href={Form_Item_Template} style={{ textDecoration: 'none', color: 'inherit' }}>下載匯入範例檔</a></button>
            </div>
            <div style={{display: 'block'}}>
              <label>
                <CkboxInput type="checkbox" checked={isReplaceExist} onChange={() => {setIsReplaceExist(!isReplaceExist);}}/>是否在匯入時刪除/停用既有項目
              </label>
            </div>
          </div>
        </div>

        <FakeHr />

        <div className="table">
          <table>
            <tbody>
              <tr>
                <td>顯示順序</td>
                <td>{props.moduleId === 4 ? '檢查項目' : '抽驗項目'}</td>
                {
                  props.moduleId !== 4 && (<td>抽驗標準</td>)
                }
                {
                  (props.moduleId === 4 && templateForm.isNCO) && (<td>項目分類</td>)
                }
                <td>啟用</td>
                <td><button className="btnM btnWhite" onClick={handleItemCreate}>新增</button></td>
              </tr>
              {
                templateForm.itemList.filter(el => el.status !== 3).map((ele) => {
                  return (
                    ele.isExist ?
                      <tr key={ele._uuid}>
                        { ele.isEditing ?
                          (
                            <>
                              <td><TxtInput value={ele.sort || ''} onChange={(evt) => handleItemSortChange(evt, ele)}/></td>
                              <td><TxtInput value={ele.itemName || ''} onChange={(evt) => handleItemNameChange(evt, ele)}/></td>
                              {
                                props.moduleId !== 4 && (
                                  <td><TxtInput value={ele.criterion || ''} onChange={(evt) => handleItemCriterionChange(evt, ele)}/></td>
                                )
                              }
                              {
                                (props.moduleId === 4 && templateForm.isNCO) && (
                                  <td>
                                    <select name="hsItemCategory" id="" value={ele.hsItemCategoryID} onChange={(evt) => handleHSCategoryChange(evt, ele)}>
                                      <option value="0">請選擇</option>
                                      {
                                        hsItemCategoryList.filter(el => el.unitTypeID === templateForm.unitTypeID).map(ele1 => (
                                          <option key={ele1.id} value={ele1.id}>{ele1.hsItemCategory}</option>
                                        ))
                                      }
                                    </select>
                                  </td>
                                )
                              }
                              <td>
                                <label>
                                  <CkboxInput type="checkbox" checked={ele.enable} onChange={(evt) => handleItemEnableChange(evt, ele)}/>啟用
                                </label>
                              </td>
                            </>
                          ) :
                          (
                            <>
                              <td>{ele.sort}</td>
                              <td>{ele.itemName}</td>
                              {
                                props.moduleId !== 4 && (<td>{ele.criterion}</td>)
                              }
                              {
                                (props.moduleId === 4 && templateForm.isNCO) && (
                                  <td>{hsItemCategoryList.find(ele1 => ele1.id === ele.hsItemCategoryID) ? hsItemCategoryList.find(ele1 => ele1.id === ele.hsItemCategoryID).hsItemCategory : ''}</td>
                                )
                              }
                              <td>
                                <label>
                                  <CkboxInput type="checkbox" disabled checked={ele.enable}/>啟用
                                </label>
                              </td>
                            </>
                          )
                        }

                        <td>
                          {
                            ele.isEditing ?
                              <button className="btnM btnBlue" onClick={() => handleCancelItemUpdate(ele)}>取消</button> :
                              <button className="btnM btnBlue" onClick={() => handleItemUpdate(ele)}>修改</button>
                          }
                          <button className="btnM btnRed" onClick={() => handleItemDelete(ele)}>刪除</button>
                        </td>
                      </tr> :
                      <CreateItem
                        moduleId={props.moduleId}
                        templateForm={templateForm}
                        item={ele}
                        key={ele._uuid}
                        hsItemCategoryList={hsItemCategoryList}
                        handleItemSortChange={(evt) => handleItemSortChange(evt, ele)}
                        handleItemNameChange={(evt) => handleItemNameChange(evt, ele)}
                        handleItemCriterionChange={(evt) => handleItemCriterionChange(evt, ele)}
                        handleItemEnableChange={(evt) => handleItemEnableChange(evt, ele)}
                        handleHSCategoryChange={(evt) => handleHSCategoryChange(evt, ele)}
                        handleItemDelete={() => handleItemDelete(ele)}
                      />
                  );
                })
              }
              {
                parsedData.map((ele) => {
                  return (
                    <tr key={ele._uuid}>
                      <td><TxtInput value={ele.sort || ''} onChange={(evt) => handleParsedItemSortChange(evt, ele)}/></td>
                      <td><TxtInput value={ele.itemName || ''} onChange={(evt) => handleParsedItemNameChange(evt, ele)}/></td>
                      {
                        props.moduleId !== 4 && (
                          <td><TxtInput value={ele.criterion || ''} onChange={(evt) => handleParsedItemCriterionChange(evt, ele)}/></td>
                        )
                      }
                      {
                        (props.moduleId === 4 && templateForm.isNCO) && (
                          <td>
                            <select name="hsItemCategory" id="" value={ele.hsItemCategoryID} onChange={(evt) => handleParsedHSCategoryChange(evt, ele)}>
                              <option value="0">請選擇</option>
                              {
                                hsItemCategoryList.filter(el => el.unitTypeID === templateForm.unitTypeID).map(ele1 => (
                                  <option key={ele1.id} value={ele1.id}>{ele1.hsItemCategory}</option>
                                ))
                              }
                            </select>
                          </td>
                        )
                      }
                      <td>
                        <label>
                          <CkboxInput type="checkbox" checked={ele.enable} onChange={(evt) => handleParsedItemEnableChange(evt, ele)}/>啟用
                        </label>
                      </td>
                      <td>
                        <button className="btnM btnRed" onClick={() => handleParsedItemDelete(ele)}>刪除</button>
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>

        <div className="btn_box">
          <button className="btnL btnBlue" onClick={ModifyTemplateForm} disabled={isSaving}>
            {
              isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '存檔'
            }
          </button>
        </div>

      </div>
      {/* <ToastContainer /> */}
    </>
  );
};
export default FormItemManage;