// import TableauDashboard from './TableauDashboard';
// import tools from '../assets/tools';
import { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { Link } from "react-router-dom";

import { D3BarChart } from '../assets/d3components/BarChart';
import { D3PieChart } from '../assets/d3components/PieChart';
import { D3Swatches } from '../assets/d3components';
// import colorSchemes from './../assets/d3components/config/scale-chromatic';
// import alphabat from "../assets/d3components/data/alphabat.json";
// import population from "../assets/d3components/data/population.json";
// import states from "../assets/d3components/data/states.json";

import { SwitchMode } from './SwitchMode';
import { ThemeContext } from './../contexts/ThemeContext';
import { StatisticAPI, HelperAPI } from './../assets/api';
import { useLocalStorage } from './useLocalStorage';
import { ChartDownloadButton } from './DownloadImageButton';
import { ExportToExcelButton } from './ExportToExcel';
import { useIsFirstRender } from './../assets/customHooks/useIsFirstRender';

const HalfRight = styled.div`
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  .btnBox{
    display: flex;
    justify-content: space-between;
    margin: 5px 0 0 0;
    .fakeBtn{
      text-decoration: none;
      width:32%;
      text-align: center;
      padding: 10px 0;
      color: ${props => props.isDarkMode ? '#e6e6e6' : '#0c0c0c'};
      background-color: ${props => props.isDarkMode ? '#444' : '#fff'};
      display: flex;
      justify-content: center;
      align-items: center;
      &.fakeActive {
        border: 1px solid #F28E2B;
        color: ${props => props.isDarkMode ? '#F28E2B' : '#fff'};
        background-color: ${props => props.isDarkMode ? '#444' : '#F28E2B'};
        cursor: unset;
      }
    }
  }
`;
const Bigboard = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
div{
  box-sizing: border-box;
}
.halfLeft{
  // border:1px solid #F00;
  padding: 10px 10px 0px 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  .divFlow{
    // border:1px solid #000;
    margin: 0 0 10px 0;
    padding: 10px;
    &.conTable{
      width: calc(60% - 5px);
      position: relative;
      .topScrollbarDiv{
        margin-bottom: 20px;
        max-height: 565px;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background-color: #DDD;
        }
      }
      .belowScrollbarDiv{
        margin-bottom: 20px;
        max-height: 280px;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background-color: #DDD;
        }
      }
      @media (max-width: 1024px) {
        width: 100%;
      }
    }
    &.conHorbar{
      width: calc(40% - 5px);
      position: relative;
      @media (max-width: 1024px) {
        width: 100%;
      }
    }
    &.conPie{
      width: calc(40% - 5px);
      @media (max-width: 1024px) {
        width: 100%;
      }
    }
  }
}
.halfRight{
  // border:1px solid #00F;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  .btnBox{
    display: flex;
    justify-content: space-between;
    margin: 10px 0 0 0;
    .fakeBtn{
      text-decoration: none;
      width:32%;
      text-align: center;
      padding: 10px 0;
      color:#FFF;
      display: flex;
      justify-content: center;
      align-items: center;
      &.fakeActive{
        border: 1px solid #F28E2B;
        color:#F28E2B;
        cursor: unset;
      }
    }
  }
}
`;
const FromArea = styled.div`
background-color: ${props => props.isDarkMode ? '#444' : '#FFF'};
  table {
    width: 100%;
    color:  ${props => props.isDarkMode ? '#fff' : '#000'};
    text-align: center;
    margin: 1rem 0;
    font-size: 14px;
    table-Layout: fixed;
    tr {
      /* box-shadow: 0 0 2px 0 #CCC; */
      background-color: ${props => props.isDarkMode ? '#444' : '#FFF'};
      border-top: 2px solid #cccccc86;
      &:not(:first-of-type):hover {
        background-color: ${props => props.isDarkMode ? '#555' : '#ECECEC'};
      }
      &:first-of-type {
        position: sticky;
        top: 0;
        background-color: ${props => props.isDarkMode ? '#444' : '#FFF'};
      }
      &:last-of-type {
        border-bottom: 2px solid #cccccc86;
      }
    }
    td {
      padding: 1rem 4px;
      overflow-wrap: anywhere;
      vertical-align: middle;
      max-width: 360px;
      a {
        text-decoration: none;
        color: #007DA3;
      }
    }
  }
`;
const FromTitle = styled.div`
  width: 100%;
  height: 80px;
  color:  ${props => props.isDarkMode ? '#E6E6E6' : '#000'};
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.isDarkMode ? '#444' : '#fff'};
`;

const ChartTitle = styled.div`
  width: 100%;
  height: 80px;
  color: ${props => props.isDarkMode ? '#E6E6E6' : '#444'};;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.isDarkMode ? '#444' : '#FFF'};
  position: absolute;
  left: 0;
  top: 0;
`;

const colorTheme = {
  light: {
    titleTextColor: "black",
    xValueColor: "black",
    yValueColor: "black",
    backgroundColor: "#FFF",
    color: "#65BAAA",
    // titleAltColor: "white",
  },
  dark: {
    titleTextColor: "white",
    xValueColor: "white",
    yValueColor: "white",
    backgroundColor: "#444",
    color: "#222",
    // titleAltColor: "#222",
  }
};

const DashboardMain = () => {
  const isFirstRender = useIsFirstRender();
  // const url = tools.CheckEnv() === 'prod' ?
  //   'https://bi3.stantec.com.tw/views/DashboardProd/sheet50?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link' :
  //   'https://bi3.stantec.com.tw/views/DashboardNew/sheet34_1?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link';

  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark' ? true : false;
  const tableClass = isDarkMode ? "table_dark" : "table_light";

  const [projectList, setProjectList] = useState([]); //專案清單
  const [projectId, setProjectId] = useLocalStorage("projectId", projectList.length ? projectList[0].id : 0);
  // const [projectName, setProjectName] = useState();


  const [abnormalImproveDataList, setAbnormalImproveDataList] = useState([]); //
  // const [abnormalCategoryNumList, setAbnormalCategoryNumList] = useState([]); //
  const [abnormalModuleImproveDataList, setAbnormalModuleImproveDataList] = useState([]); //
  const [piedata, setPiedata] = useState([]); //
  const [bardata, setBardata] = useState([]); //



  //取得專案下拉清單
  const GetStaticProjectList = async () => {
    try {
      let data = await HelperAPI.GetStaticProjectList();
      setProjectList(data.projectList);
      return data.projectList;
    } catch (error) {
      console.log(error);
    }
  };

  const [barChartOption, setBarChartOption] = useState({
    chartId: 'barChartSvg',
    downloadAreaId: "abnormalImproveBarChart",
    titleText: "缺失樣態",
    titleTextColor: isDarkMode ? "white" : "black",
    xAttr: 'frequency',
    yAttr: 'letter',
    yLabel: '↑ Daily close ($)',
    xFormat: '',
    xLabel: "Frequency →",
    color: isDarkMode ? "#222" : "#65BAAA",
    titleAltColor: isDarkMode ? "#white" : "black",
    xValueColor: isDarkMode ? "white" : "black",
    yValueColor: isDarkMode ? "white" : "black",
    backgroundColor: isDarkMode ? "#444" : "white",
    marginLeft: 150,
    marginRight: 10,
    // colorInterpolate: colorSchemes.categorical.schemeDark2,
    // colorScheme: colorSchemes.sequentialMulti.interpolateWarm,
    colorCustom: ["#4e79a7"],
  });
  const [pieChartOption, setPieChartOption] = useState({
    chartId: 'pieChartSvg',
    downloadAreaId: "abnormalCategoryNumPieChart",
    titleText: "",
    name: 'formName',
    value: 'num',
    percentage: 'rate',
    backgroundColor: isDarkMode ? "#444" : "white",
    // colorCustom: ['#EAD156', '#0A326A',],
    // colorInterpolate: colorSchemes.diverging.interpolateRdGy,
    colorCustom: ["#4e79a7", "#f28e2c", "#e15759", "#76b7b2", "#59a14f", "#edc949", "#af7aa1", "#ff9da7", "#9c755f", "#bab0ab",],
  });
  const PieLegendOption = {
    // colorInterpolate: colorSchemes.diverging.interpolateRdGy,
    colorCustom: ["#4e79a7", "#f28e2c", "#e15759", "#76b7b2", "#59a14f", "#edc949", "#af7aa1", "#ff9da7", "#9c755f", "#bab0ab",],
    titleText: "",
    titleTextColor: "blue",
    backgroundColor: isDarkMode ? "#444" : "white",
    paddingBottom: 10,
  };


  const handleProjectSelect = (e) => {
    let value = projectList.find(ele => ele.id === Number(e.target.value));
    setProjectId(value.id);
    // setProjectName(value.projectName);
  };
  const GetAbnormalImproveRecord = async (projectId) => {
    try {
      let data = await StatisticAPI.GetAbnormalImproveRecord(projectId, false);
      setAbnormalImproveDataList(data.abnormalImproveDataList);
      setAbnormalModuleImproveDataList(data.abnormalModuleImproveDataList);
      setPiedata(data.abnormalImproveDataList);
      const bdata = data.abnormalCategoryNumList.map(ele => ({ "letter": ele.abnormalCategoryName, "frequency": ele.num }));
      setBardata(bdata);

      // console.log(eqFormDataList);
      // const sdata = isQualified.flatMap(qualified => data.eqFormDataList.map(d => ({ state: d.projectFormCategoryName, age: qualified, population: d[qualified] })));
      // setStackBarData(sdata);

    } catch (error) {
      console.log(error);
    }
  };

  // const ages = ["<10", "10-19", "20-29", "30-39", "40-49", "50-59", "60-69", "70-79", "≥80"];
  // const stateages = ages.flatMap(age => states.map(d => ({ state: d.name, age, population: d[age] })));

  useEffect(() => {
    // console.log(isDarkMode);
    setBarChartOption({
      ...barChartOption,
      ...colorTheme[isDarkMode ? "dark" : "light"]
    });
    setPieChartOption({
      ...pieChartOption,
      ...colorTheme[isDarkMode ? "dark" : "light"]
    });
  }, [isDarkMode]);

  useEffect(async () => {
    let staticProjectList = await GetStaticProjectList();
    if (projectId === 0) {
      setProjectId(staticProjectList[0].id);
    } else {
      GetAbnormalImproveRecord(projectId);
    }
  }, []);

  useEffect(() => {
    if (!isFirstRender) {
      GetAbnormalImproveRecord(projectId);
    }
  }, [projectId]);

  return (
    <>
      <SwitchMode />
      <div className="containerBox" style={{ backgroundColor: isDarkMode ? '#666' : '#F1F1F1' }}>
        <section className="dashboardContent">
          <HalfRight isDarkMode={isDarkMode}>
            <div className="btnBox">
              <Link to={{ pathname: `dashboard-new-construction` }} className="fakeBtn">施工品質查驗紀錄</Link>
              <Link to={{ pathname: `dashboard-new-material` }} className="fakeBtn">材料設備品質查驗</Link>
              <Link to={{ pathname: `dashboard-new-abnormal` }} className="fakeBtn fakeActive" >品質缺失改善追蹤</Link>
            </div>
          </HalfRight>

          <div className="dashboardUpArea">
            <div className="projectWrap" style={{ backgroundColor: isDarkMode ? '#555' : '#DDD' }}>
              <h3 style={{ color: isDarkMode ? '#e6e6e6' : '#000' }}>專案名稱</h3>
              <div className="dropdownBox">
                <select
                  className="dropdownMenu" style={{ backgroundColor: isDarkMode ? '#BCBCBC' : '#FFF' }}
                  onChange={(e) => handleProjectSelect(e)}
                  value={projectId}
                >
                  {
                    projectList.map(ele => (
                      <option key={ele.id} value={ele.id}>{ele.projectName}</option>
                    ))
                  }
                </select>
              </div>
            </div>
          </div>

          <Bigboard>
            <div className="halfLeft">
              <div className="divFlow conTable" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF', position: 'relative' }}>
                <div id="abnormalStatusTable" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF' }}>
                  <FromTitle isDarkMode={isDarkMode}>案件狀態</FromTitle>
                  <FromArea id="abnormalStatusTitleTableId" className="topScrollbarDiv" isDarkMode={isDarkMode}>
                    <table>
                      <tbody>
                        <tr>
                          <td>工項</td>
                          <td>缺失件數</td>
                          <td>已完成改善(數量)</td>
                          <td>列管中</td>
                          <td>已完成改善(率)</td>
                        </tr>
                        {
                          abnormalImproveDataList.map((ele, idx) => {
                            return (
                              <tr key={idx}>
                                <td>{ele.formName}</td>
                                <td>{ele.num}</td>
                                <td>{ele.hasImprovedNum}</td>
                                <td>{ele.hasNotImprovedNum}</td>
                                <td>{ele.hasImprovedRate}%</td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                  </FromArea>
                </div>
                <ExportToExcelButton tableId ="abnormalStatusTitleTableId" tableName="品質缺失改善追蹤_案件狀態"/>
                {/* 下載為PNG */}
                {/* <ChartDownloadButton
                  downloadAreaId = "abnormalStatusTable"
                  hsTitleTableId = "abnormalStatusTitleTableId"
                  chartName = "案件狀態"
                /> */}
              </div>

              <div className="divFlow conPie" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF', position: 'relative' }}>
                <div id={pieChartOption.downloadAreaId} className="chartBlock" style={{ color: isDarkMode ? '#FFF' : '#000' }}>
                  {
                    piedata.length > 0 ?
                      <D3PieChart data={piedata} option={pieChartOption} />
                      : <p style={{ textAlign: 'center' }}>當前專案無資料</p>
                  }
                  {
                    piedata.length > 0 ?
                      <D3Swatches group={piedata.map(p => p.formName)} option={PieLegendOption} />
                      : ""
                  }
                </div>
                <ChartDownloadButton
                  chartId={pieChartOption.chartId}
                  downloadAreaId={pieChartOption.downloadAreaId}
                  chartName={pieChartOption.titleText}
                />
                {/* <DownloadBtnBox>
                  <button className="chartDownloadBtn" onClick={() => downloadImage(pieChartOption.chartId, pieChartOption.downloadAreaId, pieChartOption.titleText)}>Download</button>
                </DownloadBtnBox> */}
              </div>

              <div className="divFlow conHorbar" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF' }}>
                <div id={barChartOption.downloadAreaId} className="chartBlock" style={{ color: isDarkMode ? '#FFF' : '#000' }}>
                  <ChartTitle isDarkMode={isDarkMode}>缺失樣態</ChartTitle>
                  {
                    bardata.length > 0 ?
                      <D3BarChart data={bardata} option={barChartOption} />
                      : <p style={{ textAlign: 'center', marginTop: '80px' }}>當前專案無資料</p>
                  }
                </div>
                <ChartDownloadButton
                  chartId={barChartOption.chartId}
                  downloadAreaId={barChartOption.downloadAreaId}
                  chartName={barChartOption.titleText}
                  dataLength={bardata.length}
                />
                {/* <DownloadBtnBox>
                  <button className="chartDownloadBtn" onClick={() => downloadImage(barChartOption.chartId, barChartOption.downloadAreaId ,barChartOption.titleText, bardata.length )}>Download</button>
                </DownloadBtnBox> */}
              </div>

              <div className="divFlow conTable" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF', position: 'relative' }}>
                <div id="formStatisticsTable" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF' }}>
                  <FromTitle isDarkMode={isDarkMode}>表單類型統計</FromTitle>
                  <div className={`${tableClass} belowScrollbarDiv`} id="formStatisticsTableTableTitleId">
                    <table>
                      <tbody>
                        <tr>
                          <td>表單類型</td>
                          <td>缺失件數</td>
                          <td>已完成改善(數量)</td>
                          <td>列管中</td>
                          <td>已完成改善(率)</td>
                        </tr>
                        {
                          abnormalModuleImproveDataList.map((ele, idx) => {
                            return (
                              <tr key={idx}>
                                <td>{ele.moduleName}</td>
                                <td>{ele.num}</td>
                                <td>{ele.hasImprovedNum}</td>
                                <td>{ele.hasNotImprovedNum}</td>
                                <td>{ele.hasImprovedRate}%</td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                <ExportToExcelButton tableId ="formStatisticsTableTableTitleId" tableName="品質缺失改善追蹤_表單類型統計"/>
                {/* 下載為PNG */}
                {/* <ChartDownloadButton
                  downloadAreaId = "formStatisticsTable"
                  hsTitleTableId = "formStatisticsTableTableTitleId"
                  chartName = "表單類型統計"
                /> */}
              </div>

            </div>
            {/* <div className="halfRight">
              <div className="btnBox">
                <Link to={{ pathname: `dashboard-new-construction` }} className="fakeBtn" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF', color: isDarkMode ? '#FFF' : '#666' }}>施工品質查驗</Link>
                <Link to={{ pathname: `dashboard-new-material` }} className="fakeBtn" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF', color: isDarkMode ? '#FFF' : '#666' }}>材料設備品質查驗</Link>
                <Link to={{ pathname: `dashboard-new-abnormal` }} className="fakeBtn fakeActive" style={{ backgroundColor: isDarkMode ? '#444' : '#FFF' }}>品質缺失改善追蹤</Link>
              </div>
            </div> */}
          </Bigboard>
        </section>
      </div>

      <br /><br /><br />
      {/* <TableauDashboard url={url}/> */}
    </>
  );
};
export default DashboardMain;