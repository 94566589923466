import * as d3 from 'd3';
// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/horizontal-bar-chart
export default function BarChart(data, {
  x = d => d, // given d in data, returns the (quantitative) x-value
  y = (d, i) => i, // given d in data, returns the (ordinal) y-value
  title, // given d in data, returns the title text
  marginTop = 55, // the top margin, in pixels
  marginRight = 3, // the right margin, in pixels
  marginBottom = 10, // the bottom margin, in pixels
  marginLeft = 30, // the left margin, in pixels
  width = 640, // the outer width of the chart, in pixels
  height, // outer height, in pixels
  xType = d3.scaleLinear, // type of x-scale
  xDomain, // [xmin, xmax]
  xRange = [marginLeft, width - marginRight], // [left, right]
  xFormat, // a format specifier string for the x-axis
  xLabel, // a label for the x-axis
  yPadding = 0.1, // amount of y-range to reserve to separate bars
  yDomain, // an array of (ordinal) y-values
  yRange, // [top, bottom]
  color = "currentColor", // bar fill color
  colorScheme = undefined,
  titleColor = "white", // title fill color when atop bar
  // titleAltColor, // title fill color outside the top bar
  xValueColor,
  yValueColor,
  backgroundColor,
  titleText,
  titleTextColor,
  chartId,
  maxHeight = 500,
  colorCustom,
  // xAxisUnit,
} = {}) {
  // Compute values.
  const X = d3.map(data, x);
  const Y = d3.map(data, y);

  // Compute default domains, and unique the y-domain.
  if (xDomain === undefined) xDomain = [0, d3.max(X)];
  if (yDomain === undefined) yDomain = Y;
  yDomain = new d3.InternSet(yDomain);

  // Omit any data not present in the y-domain.
  const I = d3.range(X.length).filter(i => yDomain.has(Y[i]));

  // Compute the default height.
  if (height === undefined) height = Math.ceil((yDomain.size + yPadding) * 25) + marginTop + marginBottom;
  if (yRange === undefined) yRange = [marginTop, height - marginBottom];

  // Construct scales and axes.
  const xScale = xType(xDomain, xRange);
  const yScale = d3.scaleBand(yDomain, yRange).padding(yPadding);
  const xAxis = d3.axisBottom(xScale).ticks(width/80);
  // const xAxis = xAxisUnit ? d3.axisBottom(xScale).ticks(width / 80).tickFormat(d => d + xAxisUnit) : d3.axisBottom(xScale).ticks(width / 80);
  const yAxis = d3.axisLeft(yScale).tickSizeOuter(0);

  // Compute titles.
  if (title === undefined) {
    const formatValue = xScale.tickFormat(100, xFormat);
    title = i => `${formatValue(X[i])}`;
  } else {
    const O = d3.map(data, d => d);
    const T = title;
    title = i => T(O[i], i, data);
  }

  const svg = d3.create("svg")
    .attr("id", chartId)
    .attr("width", width + 50)
    .attr("height", height + 70)
    .attr("viewBox", [0, 0, width, height])
    .attr("style", "max-width: 100%;")
    .style("background-color", backgroundColor);

  const titleY = 23;
  svg.append("g")
    // .style("margin", "0 0 50 0")
    .attr("transform", "translate(0, -5)")
    .call(g => g.append('text')
      .attr("x", "50%")
      .attr("y", titleY)
      .attr("fill", titleTextColor)
      .style("font-size", "20")
      .style("font-weight", "bold")
      .style("text-anchor", "middle")
      .text(titleText));

  let colorScale;
  // Chose a default color scheme based on cardinality.
  if (colorScheme) {
    let colors = d3.quantize(t => d3[colorScheme](t * 0.8 + 0.1), yDomain.size);
    colorScale = d3.scaleOrdinal(yDomain, colors);
  }
  if (colorCustom) {
    if (colorCustom.length === 0) {
      console.error('No color array detected');
      return;
    }
    colorScale = d3.scaleOrdinal(yDomain, colorCustom);
  }

  const barsGroup = svg.append("g")
    .selectAll("rect")
    .data(I)
    .join("g");

  barsGroup.append("rect")
    .attr("fill", colorScheme || colorCustom ? d => colorScale(Y[d]) : color)
    .attr("x", xScale(0))
    .attr("y", i => yScale(Y[i]) + 10)
    .attr("width", i => xScale(X[i]) - xScale(0))
    .attr("height", 40)
    .on("pointerenter", function() {
      d3.select(this).style("opacity", 0.5);
    })
    .on("pointerleave", function() {
      d3.select(this).style("opacity", 1);
    })
    .style("cursor", "pointer");

  barsGroup.append("title")
    .text(title);

  svg.append("g")
    .attr("fill", titleColor)
    .attr("text-anchor", "end")
    .attr("font-family", "sans-serif")
    .attr("font-size", 10)
    .selectAll("text")
    .data(I)
    .join("text")
    .attr("x", i => xScale(X[i]))
    .attr("y", i => yScale(Y[i]) + yScale.bandwidth() / 2)
    .attr("dy", "0.35em")
    .attr("dx", -4)
    .text(title)
    .call(text => text.filter(i => xScale(X[i]) - xScale(0) < 40) // short bars
      // .attr("dx", +4)
      .attr("dx", -2)
      .attr("fill", titleColor)
      // .attr("text-anchor", "start"));
      .attr("text-anchor", "end"));

  svg.append("g")
    .attr("transform", `translate(${marginLeft},0)`)
    .call(yAxis)
    .selectAll("text")
    .style("font-size", "14")
    .attr("text-anchor", "start")
    .attr("x", -130)
    .style("color", yValueColor);

  let YTooltipText;
  if (YTooltipText === undefined) {
    // const formatValue = xScale.tickFormat(100, xFormat);
    YTooltipText = i => Y[i];
  } else {
    const O = d3.map(data, d => d);
    YTooltipText = i => title(O[i], i, data);
  }

  svg.append("g")
    .attr("fill", "white")
    .style("opacity", 0)
    .selectAll("rect")
    .data(I)
    .join("rect")
    .attr("x", 0)
    .attr("y", i => yScale(Y[i]))
    .attr("width", marginLeft - 10)
    .attr("height", yScale.bandwidth())
    .style("cursor", "pointer")
    .append("title")
    .text(YTooltipText);

  // x-axis
  let x_axis_svg = svg.append("g")
    .attr("id", "d3XLine")
    .attr("transform", `translate(0, ${maxHeight - titleY - marginTop})`);
  x_axis_svg.append("rect")
    .attr("fill", backgroundColor)
    .attr("x", 0)
    .attr("y", 0)
    .attr("width", width)
    .attr("height", marginTop);
  let x_axis = x_axis_svg.call(xAxis);
  x_axis.selectAll("text")
    .style("color", xValueColor)
    .call(g => g.select(".domain").remove())
    .call(g => g.selectAll(".tick line").clone()
      .attr("y2", height - marginTop - marginBottom)
      .attr("stroke-opacity", 0.1))
    .call(g => g.append("text")
      .attr("x", width - marginRight)
      .attr("y", 0)
      .attr("fill", "currentColor")
      .attr("text-anchor", "end")
      .text(xLabel));

  return {
    chart: svg.node(),
    x_axis
  };
}