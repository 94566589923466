import API from "./ApiConfig";

const ProjectAPI = {
  // 取得專案資料
  GetProjectList: () => {
    return new Promise((resolve, reject) => {
      API.get(`/Projects/GetProjectList`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得專案管理列表
  GetProjectManageList: ({ page, keyword }) => {
    return new Promise((resolve, reject) => {
      API.get(`/Projects/GetProjectManageList/${page}/${keyword}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 專案資料
  GetProjectInfo: (id) => {
    return new Promise((resolve, reject) => {
      API.get(`/Projects/GetProjectInfo/${id}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 新增/修改專案
  ModifyProject: ({ projectData }) => {
    return new Promise((resolve, reject) => {
      API.post(`/Projects/ModifyProject`, projectData)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 刪除專案
  DeleteProject: (projectData) => {
    return new Promise((resolve, reject) => {
      API.post(`/Projects/DeleteProject`, projectData)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得專案表單列表
  GetProjectFormList: ({ projectId, moduleId, page }) => {
    return new Promise((resolve, reject) => {
      API.get(`/Projects/GetProjectFormList/${projectId}/${page}/${moduleId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得專案表單資料
  GetProjectForm: (projectFormId ) => {
    return new Promise((resolve, reject) => {
      API.get(`/Projects/GetProjectForm/${projectFormId }`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 新增/修改專案表單
  ModifyProjectForm: ({ projectFormModify }) => {
    return new Promise((resolve, reject) => {
      API.post(`/Projects/ModifyProjectForm`, projectFormModify)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得專案表單資料
  GetProjectSignOffList: (projectId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Projects/GetProjectSignOffList/${projectId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 專案新增/修改簽核流程
  ModifyProjectSignoff: ({ projectSignoffModify }) => {
    return new Promise((resolve, reject) => {
      API.post(`/Projects/ModifyProjectSignoff`, projectSignoffModify)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 表單分類下拉選單(依project)
  GetProjectFormCategoryList: ({ projectId }) => {
    return new Promise((resolve, reject) => {
      API.get(`/Projects/GetProjectFormCategoryList/${projectId }`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 新增/修改表單分類
  ModifyProjectFormCategory: ({ projectFormCategoryModify }) => {
    return new Promise((resolve, reject) => {
      API.post(`/Projects/ModifyProjectFormCategory`, projectFormCategoryModify)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 模組列表(待簽數量)
  GetModuleListWithNum: ({ projectId }) => {
    return new Promise((resolve, reject) => {
      API.get(`/Projects/GetModuleListWithNum/${projectId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得專案表單列表(待簽數量)
  GetProjectFormListWithNum: ({ projectId, moduleId }) => {
    return new Promise((resolve, reject) => {
      API.get(`/Projects/GetProjectFormListWithNum/${projectId}/${moduleId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得晴雨表
  GetWeatherForm: () => {
    return new Promise((resolve, reject) => {
      API.get(`/Projects/GetWeatherForm/`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 新增/修改 晴雨表
  ModifyWeatherForm: (weatherModify) => {
    return new Promise((resolve, reject) => {
      API.post(`/Projects/ModifyWeatherForm`, weatherModify)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得 工種與機具管理
  GetPositionToolsManageInfo: (projectId, type) => {
    return new Promise((resolve, reject) => {
      API.get(`/Projects/GetPositionToolsManageInfo/${projectId}/${type}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 新增/修改工種與機具管理
  ModifyPositionToolsManage: ({ projectId, ToolsModify }) => {
    return new Promise((resolve, reject) => {
      API.post(`/Projects/ModifyPositionToolsManage/${projectId}`, ToolsModify)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得每日出工機具 清單
  GetDPIList: (GetToolsForm) => {
    return new Promise((resolve, reject) => {
      API.post(`/Projects/GetDPIList`, GetToolsForm)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得每日出工機具 日統計 清單
  GetDPITotalList: (GetDailyToolsForm) => {
    return new Promise((resolve, reject) => {
      API.post(`/Projects/GetDPITotalList`, GetDailyToolsForm)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // 取得 取得每日出工機具 表單資料
  GetDailyPersonnelImplements: ({ projectId, toolsInfo }) => {
    return new Promise((resolve, reject) => {
      API.post(`/Projects/GetDailyPersonnelImplements/${projectId}`, toolsInfo)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  // 取得 取得每日出工機具 日報表 表單資料
  GetDailyPersonnelImplementsTotal: (projectId, fillinDate) => {
    return new Promise((resolve, reject) => {
      API.get(`/Projects/GetDailyPersonnelImplementsTotal/${projectId}/${fillinDate}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 新增/修改 取得每日出工機具
  ModifyDailyPersonnelImplements: ({ projectId, implementsModify }) => {
    return new Promise((resolve, reject) => {
      API.post(`/Projects/ModifyDailyPersonnelImplements/${projectId}`, implementsModify)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // 取得 每日出工機具 各狀態數量
  PostDPINumList: (toolsSearch) => {
    return new Promise((resolve, reject) => {
      API.post(`/Projects/PostDPINumList/`, toolsSearch)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 專案美照列表API
  PostProjectPhotoList : (photoSearch) => {
    return new Promise((resolve, reject) => {
      API.post(`/Projects/PostProjectPhotoList`, photoSearch)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 刪除每日出工機具
  DeleteDailyPersonnelImplements: ({ projectId, ItemDelete }) => {
    return new Promise((resolve, reject) => {
      API.post(`/Projects/DeleteDailyPersonnelImplements/${projectId}`, ItemDelete)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 還原每日出工機具
  RestoreDailyPersonnelImplements: ({ projectId, ItemRestore }) => {
    return new Promise((resolve, reject) => {
      API.post(`/Projects/RestoreDailyPersonnelImplements/${projectId}`, ItemRestore)
        .then(rs=>{
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default ProjectAPI;