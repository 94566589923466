// import "./../../style/Form.scss";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from '@emotion/styled';
import { notifyFailed } from './../assets/toaster';
import tools from './../assets/tools';
import { AccountAPI } from './../assets/api';
import Pagination from './../assets/Pagination';

const CreateLink = styled(Link)`
  padding: 10px 12px;
  font-size: 16px;
  min-width: 4rem;
  color: #000 !important;
  @media (max-width: 450px) {
    padding: 6px 8px;
    font-size: 14px;
    min-width: 2rem;
  }
`;

const UserManage = () => {
  const [totalPages, setTotalPages] = useState(1); // 要到的資料總頁數
  const [currentPage, setCurrentPage] = useState(1); // 目前所在頁

  const [userList, setUserList] = useState([]);
  const [keyword, setKeyword] = useState('');

  const GetUserList = async(keyword) => {
    try {
      let data = await AccountAPI.GetUserListBypage({ page: 1, keyword: keyword });
      if (data) setUserList(data.manageUserList);
      // setUserList(data.manageUserList);
      setTotalPages(data.pageInfo.pages);
      setCurrentPage(1);
    } catch (error) {
      notifyFailed({Msg: '發生錯誤', isReload: true});
    }
  };

  useEffect(async ()=> {
    await GetUserList('');
  }, []);

  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleKeywordKeydown = async(e) => {
    if (e.key === 'Enter') {
      await GetUserList(keyword);
    }
  };

  const handleSearch = async() => {
    await GetUserList(keyword);
  };

  return (
    <>
      <div className="bumper_rail"></div>
      <div className="commontitle">
        <h1>使用者設定</h1>
      </div>
      <div className="filter_table">
        <div className="filter_table_input">
          <div className="">姓名：</div>
          <div className=""><input type="text" name="" value={keyword} onChange={handleKeywordChange} onKeyDown={handleKeywordKeydown}/></div>
        </div>
        <div className="filter_table_btn"><button className="btnM btnBlue" onClick={handleSearch}>搜尋</button></div>
      </div>

      <div className="table">
        <table>
          <tbody>
            <tr>
              <td>項次</td>
              <td>姓名</td>
              <td>Email</td>
              <td>啟用</td>
              <td><CreateLink to="user-detail/create" className="a_fakebtn btnWhite">新增</CreateLink></td>
            </tr>
            {
              userList.map((ele, idx) => (
                <tr key={tools._UUID()}>
                  <td>{idx+1}</td>
                  <td>{ele.realName}</td>
                  <td>{ele.email}</td>
                  <td>{ele.enable ? '是' : '否'}</td>
                  <td><Link to={{ pathname: `user-detail/${ele.userName}` }} className="nowrap">編輯</Link></td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>

      <Pagination
        setTotalPages={setTotalPages}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        keyword={keyword}
        setList={setUserList}
        apiFunc={'GetUserList'}
      />
    </>
  );
};
export default UserManage;