// import './App.css';
import React from "react";
import Layout from './layout/Layout';
import Auth from './Auth';
import ProjectsList from "./project/ProjectsList";
import Project from "./project/Project";
import CmgMgt from './cmgmgt/CmgMgt';
import Manage from './manage/Manage';
import Report from './report/Report';
// import ManageNoBoard from './manage/ManageNoBoard';
import Account from './account/Account';
import PrivateRoute from './routes/PrivateRoute';

import { Route } from "react-router-dom";
// import { AuthAPI } from "./assets/api";

function App() {
  return (
    <div className="container">
      <div>
        <Layout />
        <Route component={Auth} path="/login-page" exact />
        <PrivateRoute path="/projects-list" component={ProjectsList} />
        <PrivateRoute path="/project" component={Project} />
        <PrivateRoute path="/cmgmgt" component={CmgMgt} />
        <PrivateRoute path="/manage" component={Manage} />
        <PrivateRoute path="/report" component={Report} />
        {/* <PrivateRoute path="/manage-simple" component={ManageNoBoard} /> */}
        <PrivateRoute path="/account" component={Account} />
      </div>
    </div>
  );
}

export default App;
