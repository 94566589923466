import React, { useState, useContext } from "react";
import styled from '@emotion/styled';
import tools from "../assets/tools";
import "./../../style/Form.scss";
import "./../../style/FormDetail.scss";
import { notifyFailed, notifyWarning, notifySuccess } from '../assets/toaster';
//AccountAPI, , DocumentAPI, SupervisionAPI, ProjectAPI
import { FormAPI, SupervisionAPI } from "../assets/api";

import { ProjectContext } from '../contexts/ProjectContext';
import moment from "moment";
import PictureUpload from './../components/PictureUpload';

const FormTitle = styled.h1`
  font-size: 1.4rem;
  font-weight: 700;
  margin: 8px;
`;

const DateInput = styled.input`
  padding: 0 4px;
  box-sizing: border-box;
  height: 32px;
  border-radius: 3px;
  border: 1px solid #999;
  // min-width: 5em;
  max-width: 18em;
  margin: 0 3px;
  @media (max-width: 450px) {
    width: 100%;
  }
`;

const FakeHr = styled.div`
  width: 92%;
  height: 2px;
  background-color: #DDD;
  margin: 40px 4% 40px 4%;
`;

const NoteDiv = styled.div`
  div{
    display: inline-block;
    padding: 3px;
  }
  .form_title{
    width: 11rem;
    text-align: right;
    vertical-align: top;
    padding: 5px 0px 5px 10px;
    @media (max-width: 1025px) {
      width: 100%;
      text-align: left;
    }
  }
  .form_input{
    width: calc(100% - 18rem);
    text-align: left;
    vertical-align: middle;
    overflow: auto;
    input[type="text"] {
      height: 28px;
      border-radius: 3px;
      border: 1px solid #999;
    }
    input[type="number"] {
      height: 28px;
      border-radius: 3px;
      border: 1px solid #999;
    }
    input[type="radio"] {
      margin: 0px 3px 8px 0px;
    }
    padding: 5px 0px 8px 12px;
  }
  .form_input_table{
    width: calc(100%);
    text-align: left;
    vertical-align: middle;
    overflow: auto;
    input[type="text"] {
      height: 28px;
      border-radius: 3px;
      border: 1px solid #999;
    }
    input[type="number"] {
      height: 28px;
      border-radius: 3px;
      border: 1px solid #999;
    }
    input[type="radio"] {
      margin: 0px 3px 8px 0px;
    }
    padding: 5px 0px 8px 12px;
  }
  table{
      width: 99%;
      text-align: center;
      box-shadow: 0 1px 3px 0px #aaaa;
      tr{
        box-shadow: 0 1px 2px 0px #CCCC;
        &:first-of-type{
          background-color: #E3E3E3;
          box-shadow: 0 1px 2px 0px #BBBB;
          font-size: 1.1rem;
        }
        &:not(:first-of-type):hover{
          background-color: #CAC19025;
        }
      }
      td{
        padding: 1rem 10px;
        overflow-wrap: anywhere;
        vertical-align: middle;
        input[type="text"] {
          height: 28px;
          width: 100px;
          border-radius: 3px;
          border: 1px solid #999;
        }
        input[type="number"] {
          height: 28px;
          width: 80px;
          border-radius: 3px;
          border: 1px solid #999;
        }
        input[type="radio"] {
          margin: 0px 3px 8px 0px;
        }
      }
    }
    @media (max-width: 1025px) {
      width: calc(100% - 20px);
    }
`;

const HistoricalRecord = styled.div`
  background-color: #EEE;
  padding: 10px 15px;
  margin: 10px;
`;


const FullTextarea = styled.textarea`
  width: calc(100% - 40px);
  margin: 0 10px;
  border: 1px solid #CCC;
`;
const SuperviseMonthlyReport = (props) => {

  const { projectInfo } = useContext(ProjectContext);
  const [isSaving, setIsSaving] = useState(false);

  let projectId = projectInfo.id;

  console.log(props.formContent);
  const [formContent, setFormContent] = useState({
    ...props.formContent,
    _uuid: tools._UUID(),
    id:props.formContent.id,
    projectId,
    scheduleNote: props.formContent.scheduleNote || '',
    fillInMonth: props.formContent.fillInMonth ? moment(props.formContent.fillInMonth).format('YYYY-MM'):'', //填報月份
    scheduledProgress: props.formContent.scheduledProgress ? Number(props.formContent.scheduledProgress) : '無資料', //累計至本月預定進度(%)
    actualProgress: props.formContent.actualProgress ? Number(props.formContent.actualProgress) : '無資料', //累計至本月實際進度 (%)
    schedule: props.formContent.schedule == true ? 1 : 0, //進度
    projectContractList: props.formContent.projectContractList.map(ele=>{ //工程專案資訊 唯讀
      return ({
        ...ele,
        _uuid: tools._UUID(),
        stageContract: ele.stageContract ? ele.stageContract :'',
        contractStartDate: ele.contractStartDate ? moment(ele.contractStartDate).format('YYYY-MM-DD'):'',
        completedDate: ele.completedDate ? moment(ele.completedDate).format('YYYY-MM-DD'):'',
      });
    }),
    projectExecutionNote: props.formContent.projectExecutionNote || '', //工程執行現況概述
    supervisionMonthlyFineList: props.formContent.supervisionMonthlyFineList.map(ele =>{ //監造罰款情形說明
      return props.formContent.id > 0 ?
        ({
          ...ele,
          _uuid:tools._UUID(),
          mainID:props.formContent.id,
          id:ele.id,
          date: ele.date ? moment(ele.date).format('YYYY-MM-DD'):'',
          reason: ele.reason,
          amount: ele.amount,
          status: 2,
          isExist: true
        }) :
        ({
          ...ele,
          _uuid: tools._UUID(),
          status: 1,
          isExist: false
        });
    }),
    supervisionMonthlyInspectionList: props.formContent.supervisionMonthlyInspectionList.map(ele =>{ //上級督導查核說明
      return props.formContent.id > 0 ?
        ({
          ...ele,
          _uuid: tools._UUID(),
          mainID: props.formContent.id,
          id:ele.id,
          date: ele.date ? moment(ele.date).format('YYYY-MM-DD') : '',
          organizer: ele.organizer || '',
          item: ele.item || '',
          grade: ele.grade || '',
          score: ele.score || '',
          isDeduct: ele.isDeduct == true ? 1 : 0,
          deducePoint: ele.deducePoint || '',
          amout: ele.amount || '',
          status: 2,
          isExist: true
        }) :
        ({
          ...ele,
          _uuid:tools._UUID(),
          date: '',
          organizer: '',
          item: '',
          grade: '',
          score: '',
          isDeduct: '',
          deducePoint: '',
          amout: '',
          status: 1,
          isExist: false
        });
    }),
    designChangeNote: props.formContent.designChangeNote, //變更設計情形說明
    supervisionContractAmount: props.formContent.supervisionContractAmount || '', //監造合約金額
    contractRequestPaymentCondition: props.formContent.contractRequestPaymentCondition || '', //監造費契約請款條件
    contractPaymentWork: props.formContent.contractPaymentWork==true ? 1 : 0, //監造費用請款作業 0:異常 1:正常
    lastMonthRequestPaymentTotal: props.formContent.lastMonthRequestPaymentTotal || 0, //上月累計請款金額
    requestPaymentAmount: props.formContent.requestPaymentAmount || 0, //本月請款金額
    requestPaymentTotal: Number(props.formContent.requestPaymentAmount) + Number(props.formContent.lastMonthRequestPaymentTotal) || 0, //本月累計請款金額
    requestPaymentSchedule: props.formContent.requestPaymentSchedule, //監造費請款進度
    companyEstimating: props.formContent.companyEstimating || 0, //廠商估驗進度
    normalOrAbnormal: props.formContent.normalOrAbnormal, //正常或異常原因
    supervisionMonthlyPersonnelList: props.formContent.supervisionMonthlyPersonnelList.map(ele=>{ //人員異動情形
      return props.formContent.id > 0 ?
        ({
          ...ele,
          _uuid: tools._UUID(),
          id:ele.id,
          mainID: props.formContent.id,
          jobTitle: ele.jobTitle,
          name: ele.name,
          report: ele.report == true ? 1 : 0,
          execution: ele.execution == true ? 1 : 0,
          cooperation: ele.cooperation,
          isSupport: ele.isSupport == true ? 1 : 0,
          note: ele.note,
          status: 2,
          isExist: true
        }) :
        ({
          ...ele,
          _uuid: tools._UUID(),
          jobTitle: '',
          name: '',
          report: '',
          execution: '',
          cooperation: '',
          isSupport: '',
          note: '',
          status: 1,
          isExist: false
        });
    }),
    contract: props.formContent.contract, //契約規定
    extraService: props.formContent.extraService, //業主要求額外服務
    awardCategory: props.formContent.awardCategory, //欲參獎類別(含時程說明)
    labourInspection: props.formContent.labourInspection == true ? 1 : 0, //是否有勞檢
    supervisionMonthlyLaborInspectionList: props.formContent.supervisionMonthlyLaborInspectionList.map(ele=>{ //勞檢清單
      return props.formContent.id > 0 ?
        ({
          ...ele,
          _uuid: tools._UUID(),
          mainID: props.formContent.id,
          id:ele.id,
          date: ele.date ? moment(ele.date).format('YYYY-MM-DD') : '',
          isFine: ele.isFine == true ? 1: 0,
          amount: ele.amount,
          status: 2,
          isExist: true
        }) :
        ({
          ...ele,
          _uuid: tools._UUID(),
          date: '',
          isFine: '',
          amount: '',
          status: 1,
          isExist: false
        });
    }),
    occupationalAccident: props.formContent.occupationalAccident == true ? 1: 0, //本月是否有職災事件
    occupationalAccidentNote: props.formContent.occupationalAccidentNote, //職災事件說明
    supervisionMonthlyPhotoList: props.formContent.supervisionMonthlyPhotoList.map(ele => { //照片
      return props.formContent.id > 0 ?
        ({
          ...ele,
          _uuid: tools._UUID(),
          mainID: props.formContent.id,
          id:ele.id,
          projectId: ele.projectId,
          monthlyID: ele.monthlyID,
          isiOS: ele.isiOS,
          status: 2,
          isExist: true
        }) :
        ({
          ...ele,
          _uuid: tools._UUID(),
          status: 1,
          isExist: false
        });
    }),
    formStatus: props.formContent.id >0 ? props.formContent.formStatus : 0,
    status: props.formContent.id ===0 ? 1:2,
    historyRecordList: props.formContent.historyRecordList.map(ele=>{
      return ({
        ...ele,
        _uuid:tools._UUID(),
        note: ele.note,
        createID: ele.createID,
        createDate: ele.createDate
      });
    })
  });

  const handleInputChange = (e) => {
    setFormContent({
      ...formContent,
      [e.target.name]: e.target.value
    });
  };

  const handleRadioInputChange = (e) =>{
    setFormContent({
      ...formContent,
      [e.target.name]: Number(e.target.value)
    });
  };

  const handleTotalSumChange = (e) => {
    let sum = Number(formContent.lastMonthRequestPaymentTotal) + Number(e.target.value);
    setFormContent({
      ...formContent,
      requestPaymentAmount: Number(e.target.value),
      requestPaymentTotal: sum
    });
  };

  //處理監造罰款情形說明
  function MonthlyFine({_uuid=0, id=0, mainID, reason, amount=0, status=0, isExist=false}) {
    this._uuid = _uuid;
    this.id = id;
    this.mainID = mainID;
    this.reason = reason;
    this.amount = amount;
    this.status = status;
    this.isExist = isExist;
  }

  const handleSupervisionMonthlyFineCreate = () => {
    setFormContent({
      ...formContent,
      supervisionMonthlyFineList: [...formContent.supervisionMonthlyFineList, new MonthlyFine({ _uuid: tools._UUID(), id: 0, mainID: 0, date:'', reason:'', amount: 0, status: 1})]
    });
  };

  const handleSupervisionMonthlyFineInputChange = (e, el) => {
    setFormContent({
      ...formContent,
      supervisionMonthlyFineList: formContent.supervisionMonthlyFineList.map(ele => (
        ele._uuid === el._uuid ? { ...ele, [e.target.name]: e.target.value} : ele
      ))
    });
  };

  const handleSupervisionMonthlyFineDelete = (deleteItem) => {
    notifyWarning({ Msg: <DeleteSupervisionMonthlyFineConfirm deleteItem={deleteItem} /> });
  };

  const DeleteSupervisionMonthlyFineConfirm = ({closeToast, deleteItem}) => (
    <div>
      刪除監造罰款紀錄？
      <button className="btnS btnRed" onClick={() => DeleteSupervisionMonthlyFine(deleteItem)}>確定刪除</button>
      <button className="btnS btnWhite" onClick={closeToast}>取消</button>
    </div>
  );

  const DeleteSupervisionMonthlyFine = (deleteItem) => {
    if(deleteItem.isExsit){
      setFormContent({ ...formContent, supervisionMonthlyFineList: formContent.supervisionMonthlyFineList.map(ele => (ele._uuid === deleteItem._uuid ? {...ele, status:3} : ele))});
    } else {
      setFormContent({ ...formContent, supervisionMonthlyFineList: formContent.supervisionMonthlyFineList.filter(ele => ele._uuid !== deleteItem._uuid) });
    }
  };

  const handleUpdateMonthlyData = async () => {
    try {
      let monthlyData = await SupervisionAPI.GetLastDailyData(formContent.projectId, formContent.fillInMonth);
      setFormContent({
        ...formContent,
        scheduledProgress: monthlyData.scheduledProgress,
        actualProgress: monthlyData.actualProgress,
        schedule: monthlyData.schedule == true ? 1 : 0,
        lastMonthRequestPaymentTotal: monthlyData.lastMonthRequestPaymentTotal,
        supervisionMonthlyPersonnelList: monthlyData.supervisionMonthlyPersonnelList.map(ele => (
          {
            ...ele,
            id:0,
            status:1,
            _uuid:tools._UUID(),
            isExist: false,
            report: ele.report == true ? 1 : 0,
            execution: ele.execution == true ? 1 : 0,
            isSupport: ele.isSupport == true ? 1 : 0,
          }
        )),
        contract:monthlyData.contract,
      });
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤，無法取得上月資料', isReload: false });
    }
  };

  //處理上級督導查核說明
  function MonthlyInspection({_uuid=0, id=0, mainID, date='', organizer='', item='', grade='', score=0, isDeduct=0, deductPoint=0, amount=0, status=0, isExist=false}){
    this._uuid = _uuid;
    this.id = id;
    this.mainID = mainID;
    this.date = date;
    this.organizer = organizer;
    this.item = item;
    this.grade = grade;
    this.score = score;
    this.isDeduct = isDeduct;
    this.deducePoint = deductPoint;
    this.amount = amount;
    this.status = status;
    this.isExist = isExist;
  }

  const handleSupervisionMonthlyInspectionCreate = () => {
    setFormContent({...formContent, supervisionMonthlyInspectionList: [...formContent.supervisionMonthlyInspectionList, new MonthlyInspection({ _uuid: tools._UUID(), status:1})]});
  };

  const handleSupervisionMonthlyInspectionInputChange = (e, el) =>{
    setFormContent({
      ...formContent,
      supervisionMonthlyInspectionList: formContent.supervisionMonthlyInspectionList.map(ele =>(
        ele._uuid === el._uuid ? {...ele, [e.target.name]: e.target.value} : ele
      ))
    });
  };

  const handleSupervisionMonthlyInspectionItemRadioChange = (e, el) => {
    setFormContent({
      ...formContent,
      supervisionMonthlyInspectionList: formContent.supervisionMonthlyInspectionList.map(ele => (ele._uuid===el._uuid ? { ...ele, isDeduct: Number(e.target.value)} : ele))
    });
  };

  const handleSupervisionMonthlyInspectionDelete = (deleteItem) => {
    notifyWarning({ Msg: <DeleteMonthlyInspectionConfirm deleteItem={deleteItem} />});
  };

  const DeleteMonthlyInspectionConfirm = ({ closeToast, deleteItem}) => (
    <div>
      刪除上級督導查核紀錄？
      <button className="btnS btnRed" onClick={() => DeleteMonthlyInspection(deleteItem)}>確定刪除</button>
      <button className="btnS btnWhite" onClick={closeToast}>取消</button>
    </div>
  );

  const DeleteMonthlyInspection = (deleteItem) => {
    if(deleteItem.isExist){
      setFormContent({...formContent, supervisionMonthlyInspectionList: formContent.supervisionMonthlyInspectionList.map(ele => (ele._uuid === deleteItem._uuid ? {...ele, status:3} : ele))});
    } else {
      setFormContent({ ...formContent, supervisionMonthlyInspectionList: formContent.supervisionMonthlyInspectionList.filter(ele => (ele._uuid !== deleteItem._uuid))});
    }
  };

  // 處理人員異動情形
  function MonthlyPersonnel({_uuid=0,id=0, mainID, jobTitle='', name='', report=1, execution=1, cooperation='', isSupport=0, note='', status=0, isExist=false}){
    this._uuid = _uuid;
    this.id = id;
    this.mainID = mainID;
    this.jobTitle = jobTitle;
    this.name = name;
    this.report = report;
    this.execution = execution;
    this.cooperation = cooperation;
    this.isSupport = isSupport;
    this.note = note;
    this.status = status;
    this.isExist = isExist;
  }

  const handleSupervisionMonthlyPersonnelCreate = () =>{
    setFormContent({ ...formContent, supervisionMonthlyPersonnelList: [ ...formContent.supervisionMonthlyPersonnelList, new MonthlyPersonnel({_uuid:tools._UUID(), status:1})]});
  };

  const handleSupervisionMonthlyPersonnelInputChange = (e, el) =>{
    setFormContent({
      ...formContent,
      supervisionMonthlyPersonnelList: formContent.supervisionMonthlyPersonnelList.map(ele => (
        ele._uuid===el._uuid ? { ...ele, [e.target.name]: e.target.value} : ele
      ))
    });
  };

  const handleSupervisionMonthlyPersonnelItemRadioChange = (e, el) => {
    let targetname = e.target.name.replace(el._uuid,'');
    setFormContent({
      ...formContent,
      supervisionMonthlyPersonnelList: formContent.supervisionMonthlyPersonnelList.map(ele => (ele._uuid===el._uuid ? { ...ele, [targetname]: Number(e.target.value)} : ele))
    });
  };


  const handleMonthlyPersonnelDelete = (deleteItem) => {
    notifyWarning({ Msg: <DeleteMonthlyPersonnelConfirm deleteItem={deleteItem} />});
  };

  const DeleteMonthlyPersonnelConfirm = ({closeToast, deleteItem}) => (
    <div>
      刪除人員異動紀錄？
      <button className="btnS btnRed" onClick={() => DeleteMonthlyPersonnel(deleteItem)}>確定刪除</button>
      <button className="btnS btnWhite" onClick={closeToast}>取消</button>
    </div>
  );

  const DeleteMonthlyPersonnel = (deleteItem) => {
    if(deleteItem.isExist) {
      setFormContent({ ...formContent, supervisionMonthlyPersonnelList: formContent.supervisionMonthlyPersonnelList.map(ele => ele._uuid===deleteItem._uuid ? { ...ele, status:3 } : ele)});
    } else {
      setFormContent({ ...formContent, supervisionMonthlyPersonnelList: formContent.supervisionMonthlyPersonnelList.filter(ele => ele._uuid!==deleteItem._uuid)});
    }
  };

  const handlelabourInspectionChange = (e) => {
    setFormContent({
      ...formContent,
      labourInspection: Number(e.target.value),
      supervisionMonthlyLaborInspectionList: Number(e.target.value) === 1 ? formContent.supervisionMonthlyLaborInspectionList : formContent.supervisionMonthlyLaborInspectionList.filter(ele => ele.isExist).map(el => ({ ...el, status: 3}))
    });
  };

  //勞動檢查
  function MonthlyLabourInspection({ _uuid=0, id=0, mainID, date='', isFine = 0, amount = 0, status=0, isExist=false}){
    this._uuid = _uuid;
    this.id = id;
    this.mainID = mainID;
    this.date = date;
    this.isFine = isFine;
    this.amount = amount;
    this.status = status;
    this.isExist = isExist;
  }

  const handleMonthlyLabourInspectionCreate = () => {
    setFormContent({...formContent, supervisionMonthlyLaborInspectionList: [...formContent.supervisionMonthlyLaborInspectionList, new MonthlyLabourInspection({ _uuid:tools._UUID(), status:1})]});
  };

  const handleMonthlyLabourInspectionInputChange = (e, el) => {
    setFormContent({
      ...formContent,
      supervisionMonthlyLaborInspectionList: formContent.supervisionMonthlyLaborInspectionList.map(ele => ele._uuid === el._uuid ? { ...ele, [e.target.name]: e.target.value} : ele)
    });
  };

  const handleMonthlyLabourInspectionRadioChange = (e, el) => {
    let targetname = e.target.name.replace(el._uuid,'');
    setFormContent({
      ...formContent,
      supervisionMonthlyLaborInspectionList: formContent.supervisionMonthlyLaborInspectionList.map(ele => ele._uuid === el._uuid ? { ...ele, [targetname]: Number(e.target.value)} : ele)
    });
  };

  const handleMonthlyLabourInspectionDelete = (deleteItem) => {
    notifyWarning({ Msg: <DeleteMonthlyLabourInspectionConfirm deleteItem={deleteItem} /> });
  };

  const DeleteMonthlyLabourInspectionConfirm = ({ closeToast, deleteItem }) => (
    <div>
      刪除勞動檢查紀錄？
      <button className="btnS btnRed" onClick={() => DeleteMonthlyLabourInspection(deleteItem)}>確定刪除</button>
      <button className="btnS btnWhite" onClick={closeToast}>取消</button>
    </div>
  );

  const DeleteMonthlyLabourInspection = (deleteItem) => {
    if (deleteItem.isExist){
      setFormContent({ ...formContent, supervisionMonthlyLaborInspectionList: formContent.supervisionMonthlyLaborInspectionList.map(ele => ele._uuid === deleteItem._uuid ? { ...ele, status:3} : ele)});
    } else {
      setFormContent({ ...formContent, supervisionMonthlyLaborInspectionList: formContent.supervisionMonthlyLaborInspectionList.filter(ele => ele._uuid!==deleteItem._uuid)});
    }
  };

  //照片處理

  function Photo ({_uuid=0, id=0, projectID, monthlyID, content, status=0}) {
    this._uuid = _uuid;
    this.id = id;
    this.projectID = projectID;
    this.monthlyID = monthlyID;
    this.content = null;
    this.photoUrl = null;
    this.content = content;
    this.status = status;
  }

  const handleCreatePhoto = () => {
    setFormContent({
      ...formContent,
      supervisionMonthlyPhotoList: [...formContent.supervisionMonthlyPhotoList, new Photo({ _uuid: tools._UUID(), id: 0, projectID: 0, monthlyID: 0, content: '', status: 1})]
    });
  };

  const handleImgUpload = (el) => {
    setFormContent({
      ...formContent,
      supervisionMonthlyPhotoList: formContent.supervisionMonthlyPhotoList.map(ele => (ele._uuid === el._uuid ? el : ele))
    });
  };

  const handleImgDelete = (el) => {
    if (el.isExist) {
      setFormContent({
        ...formContent,
        supervisionMonthlyPhotoList: formContent.supervisionMonthlyPhotoList.map(ele => (ele._uuid === el._uuid ? {...el, status: 3} : ele))
      });
    } else {
      setFormContent({
        ...formContent,
        supervisionMonthlyPhotoList: formContent.supervisionMonthlyPhotoList.filter(ele => ele._uuid !== el._uuid)
      });
    }
  };

  const setFormTabListPromise = (formList) => {
    return new Promise(resolve => {
      props.setFormTabList(formList);
      resolve(formList);
    });
  };

  async function GetSupervisionMonthlyInfo(projectId,supervisionId){
    try {
      let data = await SupervisionAPI.GetSupervisionMonthlyInfo(projectId, supervisionId);
      return data;
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤', isReload: false });
    }
  }

  // 還原表單
  const handleRestoreForm = async() => {
    try {
      await FormAPI.RestoreForm({
        id: formContent.id,
        module: 8
      });
      notifySuccess({Msg: '表單還原成功', cbfunc: ''});
      // 還原後關閉頁籤
      props.setFormTabList(props.formTabList.filter(ele => ele._uuid !== props.uuid));
      props.setFormStatus(0);
    } catch (error) {
      if (error.response.data === "當月份日報已存在。") {notifyFailed({ Msg: error.response.data, isReload: false }); return;}
      notifyFailed({Msg: '發生錯誤，表單還原失敗', isReload: false});
    }
  };

  const openFormTab = (list, uuid) => {
    let tabToOpen = list.find(ele => ele._uuid === uuid);
    if (tabToOpen) props.setFormTabSelected(tabToOpen);
  };

  //必填欄位檢查
  const formValidate = () => {
    const invalidMsg = [
      {
        'col': 'projectExecutionNote',
        'errMsg':'請填工程執行現況概述。'
      },
      {
        'col': 'fillInMonth',
        'errMsg':'請填寫填報月份。'
      },
      {
        'col': 'contract',
        'errMsg':'請填寫契約規定。'
      },
    ];
    const errMsg = [];
    invalidMsg.forEach(m => {
      if (!formContent[m.col]) errMsg.push(m.errMsg);
    });
    if(formContent.supervisionMonthlyPersonnelList.filter(ele=> ele.status!=3).length==0) errMsg.push("請填寫目前專案人員。");
    return errMsg;
  };

  const handleSave = async() => {
    // 檢查網路狀態
    if (!window.navigator.onLine) {
      notifyFailed({Msg: '網路未連線', isReload: false});
      return;
    }
    let errMsg = formValidate();
    if (errMsg.length > 0) {
      let Msg = <div>{errMsg.map((e, idx) => {
        return <span key={idx}>{e}<br /></span>;
      })}</div>;
      notifyFailed({ Msg, isReload: false });
      return;
    }
    setIsSaving(true);
    let formData = new FormData();
    formData.append(`id`, formContent.id);
    formData.append(`ProjectID`, formContent.projectID || 0);
    formData.append(`FillInMonth`, `${formContent.fillInMonth}-1`);
    formData.append(`ScheduleNote`, formContent.scheduleNote || '');
    formData.append(`ProjectExecutionNote`, formContent.projectExecutionNote);
    formData.append(`DesignChangeNote`, formContent.designChangeNote || '');
    formData.append(`ContractRequestPaymentCondition`, formContent.contractRequestPaymentCondition || '');
    formData.append(`ContractPaymentWork`, formContent.contractPaymentWork ===1 ? true : false);
    formData.append(`RequestPaymentAmount`, formContent.requestPaymentAmount);
    formData.append(`CompanyEstimating`, formContent.companyEstimating);
    formData.append(`NormalOrAbnormal`, formContent.normalOrAbnormal || '');
    formData.append(`Contract`, formContent.contract);
    formData.append(`ExtraService`, formContent.extraService || '');
    formData.append(`AwardCategory`, formContent.awardCategory || '');
    formData.append(`LabourInspection`, formContent.labourInspection === 1 ? true : false);
    formData.append(`OccupationalAccident`, formContent.occupationalAccident === 1 ? true : false);
    formData.append(`OccupationalAccidentNote`, formContent.occupationalAccidentNote || '');
    formData.append(`FormStatus`, formContent.formStatus);
    formData.append(`status`, formContent.status);

    // 照片上傳
    for (let i=0; i < formContent.supervisionMonthlyPhotoList.length; i++) {
      let ele = formContent.supervisionMonthlyPhotoList[i];
      formData.append(`supervisionMonthlyPhotoList[${i}].id`, ele.id);
      formData.append(`supervisionMonthlyPhotoList[${i}].ProjectID`, formContent.projectId);
      formData.append(`supervisionMonthlyPhotoList[${i}].MonthlyID`, formContent.id);
      formData.append(`supervisionMonthlyPhotoList[${i}].content`, ele.content || '');
      formData.append(`supervisionMonthlyPhotoList[${i}].isiOS`, ele.isiOS);
      formData.append(`supervisionMonthlyPhotoList[${i}].file`, ele.file);
      formData.append(`supervisionMonthlyPhotoList[${i}].status`, ele.status);
    }

    //監造月報監造罰款情形
    for (let i=0; i < formContent.supervisionMonthlyFineList.length; i++) {
      let ele = formContent.supervisionMonthlyFineList[i];
      formData.append(`supervisionMonthlyFineList[${i}].MainID`, formContent.id);
      formData.append(`supervisionMonthlyFineList[${i}].id`, ele.id);
      formData.append(`supervisionMonthlyFineList[${i}].Date`, ele.date);
      formData.append(`supervisionMonthlyFineList[${i}].Reason`, ele.reason || '');
      formData.append(`supervisionMonthlyFineList[${i}].Amount`, ele.amount);
      formData.append(`supervisionMonthlyFineList[${i}].status`, ele.status);
    }

    //監造月報上級督導查核說明
    for (let i = 0; i< formContent.supervisionMonthlyInspectionList.length; i++) {
      let ele = formContent.supervisionMonthlyInspectionList[i];
      formData.append(`supervisionMonthlyInspectionList[${i}].id`, ele.id);
      formData.append(`supervisionMonthlyInspectionList[${i}].MainID`, formContent.id);
      formData.append(`supervisionMonthlyInspectionList[${i}].Date`, ele.date);
      formData.append(`supervisionMonthlyInspectionList[${i}].Organizer`, ele.organizer);
      formData.append(`supervisionMonthlyInspectionList[${i}].Item`, ele.item);
      formData.append(`supervisionMonthlyInspectionList[${i}].Grade`, ele.grade);
      formData.append(`supervisionMonthlyInspectionList[${i}].Score`, ele.score);
      formData.append(`supervisionMonthlyInspectionList[${i}].IsDeduct`, ele.isDeduct === 1 ? true : false);
      formData.append(`supervisionMonthlyInspectionList[${i}].DeductPoint`, ele.isDeduct === 1 ? ele.deductPoint : '');
      formData.append(`supervisionMonthlyInspectionList[${i}].Amount`, ele.amount);
      formData.append(`supervisionMonthlyInspectionList[${i}].status`, ele.status);
    }

    //監造月報人員異動
    for (let i = 0; i < formContent.supervisionMonthlyPersonnelList.length; i++) {
      let ele = formContent.supervisionMonthlyPersonnelList[i];
      formData.append(`supervisionMonthlyPersonnelList[${i}].id`, ele.id);
      formData.append(`supervisionMonthlyPersonnelList[${i}].MainID`, formContent.id);
      formData.append(`supervisionMonthlyPersonnelList[${i}].JobTitle`, ele.jobTitle);
      formData.append(`supervisionMonthlyPersonnelList[${i}].Name`, ele.name);
      formData.append(`supervisionMonthlyPersonnelList[${i}].Report`, ele.report === 1 ? true : false);
      formData.append(`supervisionMonthlyPersonnelList[${i}].Execution`, ele.execution === 1 ? true : false);
      formData.append(`supervisionMonthlyPersonnelList[${i}].Cooperation`, ele.cooperation || '');
      formData.append(`supervisionMonthlyPersonnelList[${i}].IsSupport`, ele.isSupport === 1 ? true : false);
      formData.append(`supervisionMonthlyPersonnelList[${i}].Note`, ele.note || '');
      formData.append(`supervisionMonthlyPersonnelList[${i}].status`, ele.status);
    }

    //監造月報勞動檢查
    for (let i = 0; i < formContent.supervisionMonthlyLaborInspectionList.length; i++) {
      let ele = formContent.supervisionMonthlyLaborInspectionList[i];
      formData.append(`supervisionMonthlyLaborInspectionList[${i}].id`, ele.id);
      formData.append(`supervisionMonthlyLaborInspectionList[${i}].MainID`, formContent.id);
      formData.append(`supervisionMonthlyLaborInspectionList[${i}].Date`, ele.date);
      formData.append(`supervisionMonthlyLaborInspectionList[${i}].IsFine`, ele.isFine === 1 ? true : false);
      formData.append(`supervisionMonthlyLaborInspectionList[${i}].Amount`, ele.amount);
      formData.append(`supervisionMonthlyLaborInspectionList[${i}].status`, ele.status);
    }

    try {
      let mainID = await SupervisionAPI.ModifySupervisionMonthly(formData);
      notifySuccess({Msg: '儲存成功 (專案工作月報表)', cbfunc: ''});
      setIsSaving(false);
      //重開表單
      let newFormList = await setFormTabListPromise(props.formTabList.filter(ele => ele._uuid !== props.uuid));

      let newFormContent = await GetSupervisionMonthlyInfo(projectId,mainID.id);
      let editForm = {
        id:newFormContent.id,
        _uuid:tools._UUID(),
        formName: `專案工作月報表 - ${newFormContent.fillInMonth}`,
        moduleId:8,
        formContent: newFormContent
      };
      let data = await setFormTabListPromise([...newFormList, editForm]);
      openFormTab(data, editForm._uuid);
    } catch (error) {
      notifyFailed({ Msg: '發生錯誤，表單儲存失敗 (專案工作月報表)', isReload: false });
      setIsSaving(false);
    }
  };

  return (
    <>
      <div className="sharebox">
        <FormTitle> 專案工作月報表 </FormTitle>
        {
          formContent.formStatus === 3 && (<div className="btn_box"><button className="btnL btnBlue" onClick={handleRestoreForm}>還原此表單</button></div>)
        }
        <div className="form form_loose" style={{paddingBottom: '10px',}}>
          <div className="form_half">
            <div className="form_title">
              工程名稱：
            </div>
            <div className="form_input">
              <p>{formContent.projectName}</p>
            </div>
          </div>
          <div className="form_half">
            <div className="form_title">
              填報月份：
            </div>
            <div className="form_input">
              <DateInput type="month" name="fillInMonth" value={formContent.fillInMonth} onChange={handleInputChange} />
            </div>
          </div>
          <div className="btnbox">
            <div className="btn_box"><button className="btnL btnBlue" onClick={handleUpdateMonthlyData} disabled={formContent.formStatus>0}>帶入月份資料</button></div>
          </div>
        </div>
        <FakeHr />
        <FormTitle>壹、工程專案進度</FormTitle>
        <NoteDiv>
          <div className="form_title">
            工程專案資訊：
          </div>
          <div className="form_input">
            <table>
              <tbody>
                <tr>
                  <td>分段履約</td>
                  <td>工程開工日期</td>
                  <td>預計完工日期</td>
                  <td>總工期</td>
                </tr>
                {
                  formContent.projectContractList.map(ele=>(
                    <tr key={ele._uuid}>
                      <td><span>{ele.stageContract}</span></td>
                      <td><span>{ele.contractStartDate}</span></td>
                      <td><span>{ele.completedDate}</span></td>
                      <td><span>{ele.contractDuration}</span></td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          <div className="form_title">
            累計至本月預定進度(%)：
          </div>
          <div className="form_input">
            <p>{formContent.scheduledProgress}</p>
          </div>
          <div className="form_title">
            累計至本月實際進度 (%)：
          </div>
          <div className="form_input">
            <p>{formContent.actualProgress}</p>
          </div>
          <div className="form_title">
            進度(%)：
          </div>
          <div className="form_input">
            <label><input type="radio" name={`schedule${formContent._uuid}`} value={1} checked={formContent.schedule===1} readOnly />超前</label>
            <label><input type="radio" name={`schedule${formContent._uuid}`} value={0} checked={formContent.schedule===0} readOnly />落後</label>
          </div>
          <div className="form_title">
            超前或落後原因：
          </div>
          <div className="form_input">
            <FullTextarea name="scheduleNote" value={formContent.scheduleNote || ''} rows="8" onChange={handleInputChange}></FullTextarea>
          </div>
        </NoteDiv>

        <FakeHr />

        <FormTitle>貳、工程現況重點說明</FormTitle>

        <NoteDiv>
          <div className="form_title">
            工程執行現況概述：
          </div>
          <div className="form_input">
            <FullTextarea rows="8" name="projectExecutionNote" value={formContent.projectExecutionNote || ''} onChange={handleInputChange}></FullTextarea>
          </div>
          <div className="form_title">
            監造罰款情形說明：
          </div>
          <div className="form_input">
            <table>
              <tbody>
                <tr>
                  <td>日期</td>
                  <td>原因</td>
                  <td>罰款金額</td>
                  <td>
                    {
                      formContent.formStatus === 0 && (
                        <button className="btnM btnWhite" onClick={handleSupervisionMonthlyFineCreate} >新增</button>
                      )
                    }
                  </td>
                </tr>
                {
                  formContent.supervisionMonthlyFineList.map(ele=>(
                    <tr key={ele._uuid}>
                      <td>
                        {
                          <DateInput type="date" name="date" value={ele.date} onChange={(evt) => handleSupervisionMonthlyFineInputChange(evt, ele)} />
                        }
                      </td>
                      <td>
                        {
                          <input type="text" name="reason" value={ele.reason} onChange={(evt) => handleSupervisionMonthlyFineInputChange(evt, ele)} />
                        }
                      </td>
                      <td>
                        {
                          <input type="number" name="amount" value={ele.amount} onChange={(evt) => handleSupervisionMonthlyFineInputChange(evt, ele)} />
                        }
                      </td>
                      <td>
                        {
                          (formContent.formStatus===0) && (ele.id !== 0 || ele.isExist === false) && <button className="btnS btnRed" onClick={()=> handleSupervisionMonthlyFineDelete(ele)}><i className="far fa-trash-alt"></i></button>
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          <div className="form_title">
            上級督導查核說明：
          </div>
          <div className="form_input_table">
            <table>
              <tbody>
                <tr>
                  <td>日期</td>
                  <td>主管單位</td>
                  <td>查核項目</td>
                  <td>查核級等</td>
                  <td>查核分數</td>
                  <td>是否扣點</td>
                  <td>罰款金額</td>
                  <td>
                    {
                      formContent.formStatus === 0 && (
                        <button className="btnM btnWhite" onClick={handleSupervisionMonthlyInspectionCreate}>新增</button>
                      )
                    }
                  </td>
                </tr>
                {
                  formContent.supervisionMonthlyInspectionList.map(ele=>(
                    <tr key={ele._uuid}>
                      <td>
                        {
                          <DateInput type="date" name="date" value={ele.date} onChange={(evt) => handleSupervisionMonthlyInspectionInputChange(evt, ele)} />
                        }
                      </td>
                      <td>
                        {
                          <input type="text" name="organizer" value={ele.organizer} onChange={(evt) => handleSupervisionMonthlyInspectionInputChange(evt, ele)} />
                        }
                      </td>
                      <td>
                        {
                          <input type="text" name="item" value={ele.item} onChange={(evt) => handleSupervisionMonthlyInspectionInputChange(evt, ele)} />
                        }
                      </td>
                      <td>
                        {
                          <input type="text" name="grade" value={ele.grade} onChange={(evt) => handleSupervisionMonthlyInspectionInputChange(evt, ele)} />
                        }
                      </td>
                      <td>
                        {
                          <input type="text" name="score" value={ele.score} onChange={(evt) => handleSupervisionMonthlyInspectionInputChange(evt, ele)} />
                        }
                      </td>
                      <td style={{ textAlign:'left',}} className="nowrap">
                        <label><input type="radio" name={`isDeduct${formContent._uuid+ele._uuid}`} value={0} checked={ele.isDeduct===0} onChange={(evt) => handleSupervisionMonthlyInspectionItemRadioChange(evt, ele)} />否</label>
                        <label><input type="radio" name={`isDeduct${formContent._uuid+ele._uuid}`} value={1} checked={ele.isDeduct===1} onChange={(evt) => handleSupervisionMonthlyInspectionItemRadioChange(evt, ele)} />是</label>
                        {
                          <input type="number" name="deductPoint" value={ele.isDeduct===0? '' : ele.deductPoint} disabled={ele.isDeduct===0} onChange={(evt) => handleSupervisionMonthlyInspectionInputChange(evt, ele)} />
                        }
                      </td>
                      <td>
                        {
                          <input type="number" name="amount" value={ele.amount} onChange={(evt) => handleSupervisionMonthlyInspectionInputChange(evt, ele)}/>
                        }
                      </td>
                      <td>{(formContent.formStatus===0) && (ele.id !== 0 || ele.isExist === false) && <button className="btnS btnRed" onClick={() => handleSupervisionMonthlyInspectionDelete(ele)}><i className="far fa-trash-alt"></i></button>}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          <div className="form_title">
            變更設計情形說明：
          </div>
          <div className="form_input">
            <FullTextarea name="designChangeNote" value={formContent.designChangeNote || ''} rows="8" onChange={handleInputChange}></FullTextarea>
          </div>
        </NoteDiv>

        <FakeHr />

        <FormTitle>參、監造費用請款說明(含稅)</FormTitle>

        <NoteDiv>
          <div className="form_title">
            監造合約金額：
          </div>
          <div className="form_input">
            <p>{formContent.supervisionContractAmount}</p>
          </div>
          <div className="form_title">
            監造費契約請款條件：
          </div>
          <div className="form_input">
            {
              <input type="text" name="contractRequestPaymentCondition" value={formContent.contractRequestPaymentCondition} onChange={handleInputChange} />
            }
          </div>
          <div className="form_title">
            監造費用請款作業：
          </div>
          <div className="form_input">
            <label><input type="radio" name="contractPaymentWork" value={1} checked={formContent.contractPaymentWork===1} onChange={handleRadioInputChange} />正常</label>
            <label><input type="radio" name="contractPaymentWork" value={0} checked={formContent.contractPaymentWork===0} onChange={handleRadioInputChange} />異常</label>
          </div>
          <div className="form_title">
            上月累計請款金額：
          </div>
          <div className="form_input">
            <p>{formContent.lastMonthRequestPaymentTotal}</p>
          </div>
          <div className="form_title">
            本月請款金額：
          </div>
          <div className="form_input">
            {
              <input type="number" name="requestPaymentAmount" value={formContent.requestPaymentAmount || 0} onChange={handleTotalSumChange} />
            }
          </div>
          <div className="form_title">
            本月累計請款金額：
          </div>
          <div className="form_input">
            <p>{formContent.requestPaymentTotal}</p>
          </div>
          <div className="form_title">
            監造費請款進度(%)：
          </div>
          <div className="form_input">
            <p>{formContent.supervisionContractAmount!==0 ? Math.round(formContent.requestPaymentTotal/formContent.supervisionContractAmount * 10000) / 100 : '未填寫監造合約金額無法計算'}</p>
          </div>
          <div className="form_title">
            廠商估驗進度(%)：
          </div>
          <div className="form_input">
            {
              <input type="number" name="companyEstimating" value={formContent.companyEstimating} onChange={handleInputChange} />
            }
          </div>
          <div className="form_title">
            正常或異常原因：
          </div>
          <div className="form_input">
            <FullTextarea name="normalOrAbnormal" value={formContent.normalOrAbnormal} rows="8" onChange={handleInputChange}></FullTextarea>
          </div>
        </NoteDiv>

        <FakeHr />

        <FormTitle>肆、人員異動情形</FormTitle>
        <NoteDiv>
          <div className="form_title">
            人員異動情形 ：
          </div>
          <div className="form_input_table">
            <table>
              <tbody>
                <tr>
                  <td>職稱</td>
                  <td>姓名</td>
                  <td>機關報核</td>
                  <td>現場執行</td>
                  <td>協力商</td>
                  <td>增派</td>
                  <td>備註</td>
                  <td>
                    {
                      formContent.formStatus === 0 && (
                        <button className="btnM btnWhite" onClick={handleSupervisionMonthlyPersonnelCreate} >新增</button>
                      )
                    }
                  </td>
                </tr>
                {
                  formContent.supervisionMonthlyPersonnelList.filter(el => el.status !== 3).map(ele => (
                    <tr key={ele._uuid}>
                      <td>
                        {
                          <input type="text" value={ele.jobTitle} name="jobTitle" onChange={(evt) => handleSupervisionMonthlyPersonnelInputChange(evt, ele)} />
                        }
                      </td>
                      <td>
                        {
                          <input type="text" value={ele.name} name="name" onChange={(evt) => handleSupervisionMonthlyPersonnelInputChange(evt, ele)} />
                        }
                      </td>
                      <td>
                        <label><input type="radio" name={`report${ele._uuid}`} value={1} checked={ele.report===1} onChange={(evt) => handleSupervisionMonthlyPersonnelItemRadioChange(evt, ele)} />是</label>
                        <label><input type="radio" name={`report${ele._uuid}`} value={0} checked={ele.report===0} onChange={(evt) => handleSupervisionMonthlyPersonnelItemRadioChange(evt, ele)} />否</label>
                      </td>
                      <td>
                        <label><input type="radio" name={`execution${ele._uuid}`} value={1} checked={ele.execution===1} onChange={(evt) => handleSupervisionMonthlyPersonnelItemRadioChange(evt, ele)} />是</label>
                        <label><input type="radio" name={`execution${ele._uuid}`} value={0} checked={ele.execution===0} onChange={(evt) => handleSupervisionMonthlyPersonnelItemRadioChange(evt, ele)} />否</label>
                      </td>
                      <td>
                        {
                          <input type="text" value={ele.cooperation} name="cooperation" onChange={(evt) => handleSupervisionMonthlyPersonnelInputChange(evt, ele)} />
                        }
                      </td>
                      <td>
                        <label><input type="radio" name={`isSupport${ele._uuid}`} value={1} checked={ele.isSupport===1} onChange={(evt) => handleSupervisionMonthlyPersonnelItemRadioChange(evt, ele)} />是</label>
                        <label><input type="radio" name={`isSupport${ele._uuid}`} value={0} checked={ele.isSupport===0} onChange={(evt) => handleSupervisionMonthlyPersonnelItemRadioChange(evt, ele)} />否</label>
                      </td>
                      <td>
                        {
                          <input type="text" value={ele.note} name="note" onChange={(evt) => handleSupervisionMonthlyPersonnelInputChange(evt, ele)} />
                        }
                      </td>
                      <td>
                        {
                          (formContent.formStatus===0) && (ele.id !== 0 || ele.isExist ===false) && <button className="btnS btnRed" onClick={() => handleMonthlyPersonnelDelete(ele)}><i className="far fa-trash-alt"></i></button>
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          <div className="form_title">
            契約規定：
          </div>
          <div className="form_input">
            <FullTextarea name="contract" value={formContent.contract} rows="8" onChange={handleInputChange}></FullTextarea>
          </div>
        </NoteDiv>

        <FakeHr />

        <FormTitle>伍、業主要求額外服務及欲參獎類別</FormTitle>

        <NoteDiv>
          <div className="form_title">
            業主要求額外服務 :
          </div>
          <div className="form_input">
            <FullTextarea name="extraService" value={formContent.extraService} rows="8" onChange={handleInputChange}></FullTextarea>
          </div>
          <div className="form_title">
            欲參獎類別(含時程說明):
          </div>
          <div className="form_input">
            <FullTextarea name="awardCategory" value={formContent.awardCategory} rows="8" onChange={handleInputChange}></FullTextarea>
          </div>
        </NoteDiv>

        <FakeHr />

        <FormTitle>陸、職業安全衛生</FormTitle>

        <NoteDiv>
          <div className="form_title">
            本月份是否有勞動檢查單位勞動檢查:
          </div>
          <div className="form_input">
            <label><input type="radio" name="labourInspection" value={1} checked={formContent.labourInspection===1} onChange={handlelabourInspectionChange} />是</label>
            <label><input type="radio" name="labourInspection" value={0} checked={formContent.labourInspection===0} onChange={handlelabourInspectionChange} />否</label>
            {
              (formContent.labourInspection===1)
              && (
                <table>
                  <tbody>
                    <tr>
                      <td>勞檢日期</td>
                      <td>是否罰款</td>
                      <td>金額</td>
                      <td>
                        {
                          formContent.formStatus === 0 && (
                            <button className="btnM btnWhite" onClick={handleMonthlyLabourInspectionCreate} >新增</button>
                          )
                        }
                      </td>
                    </tr>
                    {
                      formContent.supervisionMonthlyLaborInspectionList && formContent.supervisionMonthlyLaborInspectionList.filter(el => el.status!==3).map(ele => (
                        <tr key={ele._uuid}>
                          <td><DateInput type="date" name="date" value={ele.date} onChange={(evt) => handleMonthlyLabourInspectionInputChange(evt, ele)}></DateInput></td>
                          <td>
                            <label><input type="radio" name={`isFine${ele._uuid}`} value={1} checked={ele.isFine===1} onChange={(evt) => handleMonthlyLabourInspectionRadioChange(evt, ele)} />是</label>
                            <label><input type="radio" name={`isFine${ele._uuid}`} value={0} checked={ele.isFine===0} onChange={(evt) => handleMonthlyLabourInspectionRadioChange(evt, ele)} />否</label>
                          </td>
                          <td><input type="number" name="amount" value={ele.amount} onChange={(evt) => handleMonthlyLabourInspectionInputChange(evt, ele)} /></td>
                          <td>{(formContent.formStatus===0) && (ele.id !== 0 || ele.isExist ===false) && <button className="btnS btnRed" onClick={() => handleMonthlyLabourInspectionDelete(ele)}><i className="far fa-trash-alt"></i></button>}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              )
            }

          </div>
          <div className="form_title">
            本月是否有職災事件:
          </div>
          <div className="form_input">
            <label><input type="radio" name="occupationalAccident" value={1} checked={formContent.occupationalAccident===1} onChange={handleRadioInputChange} />是</label>
            <label><input type="radio" name="occupationalAccident" value={0} checked={formContent.occupationalAccident===0} onChange={handleRadioInputChange} />否</label>
          </div>
          <div className="form_title">
            職災事件說明:
          </div>
          <div className="form_input">
            <FullTextarea name="occupationalAccidentNote" value={formContent.occupationalAccidentNote} rows="8" onChange={handleInputChange}></FullTextarea>
          </div>
        </NoteDiv>
        <FakeHr />
        <FormTitle>柒、工程美照上傳</FormTitle>
        <div className="uploadfiles">
          {
            <button className="btnL btnWhite" style={{ margin: '0 10px', }} onClick={handleCreatePhoto} disabled={formContent.formStatus>0}>新增工程美照</button>
          }
          <br />
          {
            formContent.supervisionMonthlyPhotoList.filter(el => el.status !== 3).map(ele => (
              <PictureUpload key={ele._uuid} img={ele} setImg={handleImgUpload} deleteImg={handleImgDelete} editable={true}/>
            ))
          }
        </div>
        {
          formContent.formStatus===0 && (
            <div className="btn_box">
              <button className="btnL btnBlue" onClick={()=> handleSave()} disabled={isSaving}>
                {
                  isSaving ? <i className="fas fa-circle-notch fa-spin"></i> : '儲存'
                }
              </button>
            </div>
          )
        }
        {
          <>
            <FormTitle>歷程紀錄</FormTitle> <br />

            <HistoricalRecord>
              {formContent.historyRecordList?.map(ele => (
                <p key={tools._UUID()}>
                  <span> {moment(ele.createDate).format('YYYY-MM-DD HH:mm:ss')} </span>由<span> {ele.createID} </span>編輯。
                </p>
              ))}
            </HistoricalRecord>
          </>
        }
      </div>
    </>
  );
};

export default SuperviseMonthlyReport;