import '../../style/Dashboard.scss';
import { D3BarChart, D3LineChart, D3MultiLineChart, D3PieChart, D3StackedBarChart, D3Swatches, D3SankeyChart, D3StackedHorizontalBarChart } from '../assets/d3components';
import colorSchemes from "../assets/d3components/config/scale-chromatic";
import alphabat from "../assets/d3components/data/alphabat.json";
import aapl from "../assets/d3components/data/aapl.json";
import unemployment from "../assets/d3components/data/unemployment.json";
import population from "../assets/d3components/data/population.json";
import ages from "../assets/d3components/data/ages.json";
import states from "../assets/d3components/data/states.json";
import energy from "../assets/d3components/data/energy.json";


const DashboardRebuild = () => {
  const lineChartOption = {
    xAttr: 'date',
    yAttr: 'close',
    yLabel: '↑ Daily close ($)',
    titleText: "標題標題",
    titleTextColor: "blue",
  };

  const barChartOption = {
    xAttr: 'frequency',
    yAttr: 'letter',
    yLabel: '↑ Daily close ($)',
    xFormat: '%',
    xLabel: "Frequency →",
    titleText: "標題標題",
    titleTextColor: "blue",
    colorScheme: colorSchemes.sequentialMulti.interpolateRainbow,
    maxHeight: 450
  };

  const multiLineChartOption = {
    xAttr: 'date',
    yAttr: 'unemployment',
    zAttr: 'division',
    yLabel: '↑ Unemployment (%)',
    titleText: "標題標題",
    titleTextColor: "blue",
    colorScheme: colorSchemes.sequentialSingle.interpolateOranges
  };
  const pieChartOption = {
    name: 'name',
    value: 'value',
    percentage: 'percentage',
    titleText: "標題標題",
    titleTextColor: "blue",
  };
  const StackedBarChartOption = {
    xAttr: 'state',
    yAttr: 'population',
    zAttr: 'age',
    yLabel: '↑ Population (millions)',
    colorScheme: colorSchemes.diverging.schemeSpectral,
    titleText: "標題標題",
    titleTextColor: "blue",
  };
  const StackedHOrizontalBarChartOption = {
    xAttr: 'population',
    yAttr: 'state',
    zAttr: 'age',
    yLabel: 'Population (millions) →',
    colorCustom: ['#111', '#222', '#333', '#444', '#555', '#666', '#777', '#888', '#999'],
    titleText: "標題標題",
    titleTextColor: "blue",
  };
  const multiLineLegendOption = {
    colorInterpolate: colorSchemes.sequentialSingle.interpolateOranges,
    titleText: "標題標題",
    titleTextColor: "blue",
  };
  const PieLegendOption = {
    colorInterpolate: colorSchemes.diverging.interpolateSpectral,
    titleText: "標題標題",
    titleTextColor: "blue",
  };
  const StackedBarLegendOption = {
    colorScheme: colorSchemes.diverging.schemeSpectral,
    titleText: "標題標題",
    titleTextColor: "blue",
  };
  const SankeyOption = {
    titleText: "標題標題",
    titleTextColor: "blue",
  };
  

  const stateages = ages.flatMap(age => states.map(d => ({state: d.name, age, population: d[age]})));

  return (
    <>
      <D3LineChart data={aapl} option={lineChartOption} />
      <D3BarChart data={alphabat} option={barChartOption}/>

      <D3MultiLineChart data={unemployment} option={multiLineChartOption} />
      <D3Swatches group={[...new Set(unemployment.map(p => p.division))]} option={multiLineLegendOption}/>

      <D3PieChart data={population} option={pieChartOption} />
      <D3Swatches group={population.map(p => p.name)} option={PieLegendOption}/>

      <D3SankeyChart data={energy} option={SankeyOption}/>

      <D3StackedBarChart data={stateages} group={ages} option={StackedBarChartOption} />
      <D3Swatches group={ages} option={StackedBarLegendOption}/>

      
      <D3StackedHorizontalBarChart data={stateages} group={ages} option={StackedHOrizontalBarChartOption} />

      
    </>
  );
};
export default DashboardRebuild;