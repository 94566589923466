import API from "./ApiConfig";

const TemplateAPI = {
  // 取得系統範本表單列表
  GetTemplateFormList: ({moduleId, page}) => {
    return new Promise((resolve, reject) => {
      // let apiPath = moduleId ? `/Template/GetTemplateFormList/${page}/${moduleId}` :`/Template/GetTemplateFormList/1`;
      // API.get(apiPath)
      API.get(`/Template/GetTemplateFormList/${page}/${moduleId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 取得系統範本施工抽查表(X) 已停用
  GetTemplateFormCE: (templateFormId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Template/GetTemplateFormCE/${templateFormId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  //
  GetTemplateForm: (templateFormId) => {
    return new Promise((resolve, reject) => {
      API.get(`/Template/GetTemplateForm/${templateFormId}`)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 新增/修改系統範本表單API
  ModifyTemplateForm: ({ templateFormModify }) => {
    return new Promise((resolve, reject) => {
      API.post(`/Template/ModifyTemplateForm`, templateFormModify)
        .then(rs => {
          resolve(rs.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default TemplateAPI;