import React, { useState } from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import tools from './../assets/tools';

import DefaultNoImage from '../../images/image-regular.svg';

const SingleFileShadow = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 10px;
  display: none;
`;

const PictureUpload = (props) =>{
  const [dateTimeOriginal, setDateTimeOriginal] =useState('');
  const handlePhotoUpload = async (e) => {
    if (e.target.files && e.target.files[0]){
      let reader = new FileReader();
      let file = e.target.files[0];
      reader.onloadend = async function() {
        let lastModifiedDate = new Date(file.lastModified);
        let lastModifiedDateFormat = moment(lastModifiedDate).format('YYYY-MM-DD hh:mm:ss');
        setDateTimeOriginal(lastModifiedDateFormat);
        props.setImg({...props.img, photoUrl: reader.result, file: file, lastModifyDate: lastModifiedDateFormat, isiOS: tools.CheckIsIOS()});
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDescChange = (e) => {
    props.setImg({...props.img, content: e.target.value});
  };

  const handleDeleteImg = () => {
    props.deleteImg(props.img);
  };

  return (
    <div className="singlefile">
      {
        props.img.photoUrl ?
          (<div className="singlefileImg flexImg">
            <img src={props.img.photoUrl} />
          </div>) :
          (<div className="singlefileImg noImg">
            <img src={DefaultNoImage} />
          </div>)
      }
      {
        props.editable && <input type="file" name="" accept="image/*" onChange={handlePhotoUpload}/>
      }
      <input type="text" name="" placeholder="照片說明" value={props.img.content || ''} onChange={handleDescChange} disabled={!props.editable}/>
      {
        props.img.file && <div>拍攝日期:{dateTimeOriginal}</div>
      }
      {
        props.editable && <button className="btnM btnRed" onClick={handleDeleteImg}><i className="fas fa-times"></i></button>
      }
      <SingleFileShadow className="SingleFileShadow" />
    </div>
  );
};

export default PictureUpload;